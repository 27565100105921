import { BrowserRouter as Router } from "react-router-dom"
import { useRegisterSW } from "virtual:pwa-register/react"

import WideScreenMessage from "@/components/under-construction/no-desktop-screen.tsx"
import ModalPortal from "@/features/modals/modal-portal/modal-portal.tsx"
import { GeneralRoutes } from "@/routing/general-routes.tsx"
import ToastConfig from "@/utils/toast-config/toasts-config.tsx"

const App = () => {
  useRegisterSW({ immediate: true })

  return (
    <>
      <WideScreenMessage />
      <ToastConfig />
      <Router>
        <ModalPortal />
        <GeneralRoutes />
      </Router>
    </>
  )
}

export default App
