import React from "react"
import { useNavigate } from "react-router-dom"
import BaseModal from "@/features/modals/base-modal/base-modal.tsx"

interface IModalConfirmNavigationProps {
  navigateTo?: string | number
  title: string
  content?: string
  onBackText?: string
  onBackClick?: () => void | undefined
  onActionText?: string
  onActionClick: () => void | undefined
}

const ModalConfirmNavigation: React.FC<IModalConfirmNavigationProps> = ({
  navigateTo,
  title,
  content,
  onBackText,
  onActionText,
  onActionClick,
  onBackClick,
}) => {
  const navigate = useNavigate()

  const handleActionClick = () => {
    if (navigateTo) {
      navigate(navigateTo as string)
    } else if (onActionClick) {
      return onActionClick()
    }
  }

  return (
    <BaseModal
      title={title}
      content={content}
      onActionText={onActionText}
      onActionClick={handleActionClick}
      onBackText={onBackText}
      onBackClick={onBackClick}
    />
  )
}

export default ModalConfirmNavigation
