import {
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import MultiStepProgressBar from "@/components/multi-step-progress-bar/multi-step-progress-bar.tsx"
import styles from "@/features/gestational-calendar/components/gestation-calendar-card/gestation-calendar-card.module.css"
import { type GestationCalendar } from "@/features/gestational-calendar/types/calendar-types.ts"
import {
  getNextActivity,
  getPrevActivity,
} from "@/features/gestational-calendar/utils/gestational-calendar-utils.ts"

interface GestationCalendarCardBottomAfterBirthProps {
  gestationCalendar: GestationCalendar
}

const GestationCalendarCardBottomAfterBirth: React.FC<
  GestationCalendarCardBottomAfterBirthProps
> = ({ gestationCalendar }) => {
  const { t } = useTranslation()

  const nextActivity = getNextActivity(gestationCalendar)
  const prevActivity = getPrevActivity(gestationCalendar)

  const differenceInDaysFromToday = differenceInCalendarDays(
    new Date(nextActivity.event_date),
    new Date(),
  )

  const differenceInMinutesFromPrevActivity = differenceInMinutes(
    new Date(nextActivity.event_date),
    new Date(prevActivity.event_date),
  )

  const differenceInHoursFromToday = differenceInHours(
    new Date(nextActivity.event_date),
    new Date(),
  )

  const differenceInMinutesFromToday = differenceInMinutes(
    new Date(nextActivity.event_date),
    new Date(),
  )

  const isMoreThanOneDay = differenceInDaysFromToday > 1
  const isMoreThanOneHour = differenceInMinutesFromToday > 60

  const RenderTimeDifference = () => {
    if (isMoreThanOneDay) {
      return (
        <div className={styles.daysUntil}>
          <span className={styles.daysCount}>{differenceInDaysFromToday}</span>
          <span className={styles.daysText}>{t("daysToNextTask")}</span>
        </div>
      )
    } else if (isMoreThanOneHour) {
      return (
        <div className={styles.daysUntil}>
          <span className={styles.daysCount}>{differenceInHoursFromToday}</span>
          <span className={styles.daysText}>{t("hoursToNextTask")}</span>
        </div>
      )
    }
    return (
      <div className={styles.daysUntil}>
        <span className={styles.daysCount}>{differenceInMinutesFromToday}</span>
        <span className={styles.daysText}>{t("minutesToNextTask")}</span>
      </div>
    )
  }

  return (
    <div className={styles.gestationalCalendarCardTopContentBottom}>
      <RenderTimeDifference />
      <MultiStepProgressBar
        colorScheme={"purple"}
        steps={differenceInMinutesFromPrevActivity}
        currentStep={
          differenceInMinutesFromPrevActivity - differenceInMinutesFromToday
        }
      />
    </div>
  )
}

export default GestationCalendarCardBottomAfterBirth
