import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const CloudsIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="633.307 415.155 38 38"
    >
      <path
        d="M671.307 433.477a8.865 8.865 0 0 0-7.056-8.637 12.206 12.206 0 0 0-23.886 0 8.865 8.865 0 0 0-7.057 8.637 8.76 8.76 0 0 0 2.292 5.903 8.12 8.12 0 0 0 5.85 13.775h14.928a8.11 8.11 0 0 0 7.613-10.995 8.828 8.828 0 0 0 7.316-8.683Zm-14.929 16.963H641.45a5.418 5.418 0 0 1-.914-10.76l.9-.154.198-.89a7.459 7.459 0 0 1 14.561 0l.198.89.9.153a5.418 5.418 0 0 1-.915 10.761Zm6.107-10.857h-.075a8.083 8.083 0 0 0-3.794-2.384 10.172 10.172 0 0 0-19.405 0c-.483.141-.951.326-1.4.553a6.032 6.032 0 0 1-1.79-4.275 6.13 6.13 0 0 1 5.624-6.082l1.107-.087.134-1.102a9.491 9.491 0 0 1 18.842 0l.134 1.102 1.107.087a6.104 6.104 0 0 1-.484 12.189Z"
        fill="var(--blue)"
        fillRule="evenodd"
        data-name="Path 1522"
      />
    </svg>
  )
}

export default CloudsIcon
