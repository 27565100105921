import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import AddNoteIcon from "@/assets/icons/misc/add-note-icon.tsx"
import ArrowRight from "@/assets/icons/misc/arrow-right.tsx"

interface WidgetHeaderProps {
  title: string
  navigateTo?: string
  addItemGoTo?: () => void
}

const WidgetHeader: React.FC<WidgetHeaderProps> = ({
  title,
  navigateTo,
  addItemGoTo,
}) => {
  const { t } = useTranslation()
  const onClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    if (!navigateTo) {
      event.preventDefault()
    }
  }

  return (
    <div className="flex justify-between">
      <Link
        to={navigateTo || "#"}
        className={"flex cursor-pointer items-center gap-1"}
        onClick={onClick}
      >
        <p className="text-lg font-bold lg:text-xl">{t(title)}</p>
        {navigateTo && <ArrowRight className={"h-4 w-4"} />}
      </Link>
      {addItemGoTo && (
        <button onClick={addItemGoTo}>
          <AddNoteIcon />
        </button>
      )}
    </div>
  )
}

export default WidgetHeader
