import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import DrawerContentWrapper from "@/components/drawer-content-wrapper/drawer-content-wrapper.tsx"
import GenericDrawerSelectionRow from "@/components/generic-drawer-selection-row/generic-drawer-selection-row.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import { type TaskCategory } from "@/features/tasks/api/task-categories-api.ts"

interface TaskCategorySelectDrawerContentProps {
  taskCategories: TaskCategory[]
  currentTaskCategory: TaskCategory | undefined
  onTaskCategorySelect: (taskCategory: string | null) => void
  setIsAddCategoryViewOpen: ({
    isOpen,
    taskCategoryId,
  }: {
    isOpen: boolean
    taskCategoryId: string | null
  }) => void
}

const TaskCategorySelectDrawerContent: React.FC<
  TaskCategorySelectDrawerContentProps
> = ({
  taskCategories,
  currentTaskCategory,
  onTaskCategorySelect,
  setIsAddCategoryViewOpen,
}) => {
  const { t } = useTranslation()

  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    currentTaskCategory?.id || null,
  )

  const handleTaskCategorySelect = (taskCategory: TaskCategory) => {
    const taskCategoryId = taskCategory.id
    if (selectedCategory === taskCategoryId) {
      setSelectedCategory(null)
    } else {
      setSelectedCategory(taskCategoryId)
    }
  }

  return (
    <>
      <GenericTypeDrawerHeader
        title={t("selectTaskCategory")}
        onSaveClick={() => onTaskCategorySelect(selectedCategory)}
      />
      <DrawerContentWrapper>
        {taskCategories.map(
          (taskCategory, index) =>
            index !== -1 && (
              <GenericDrawerSelectionRow
                key={taskCategory.id}
                isSelected={taskCategory.id === selectedCategory}
                onClick={() => handleTaskCategorySelect(taskCategory)}
                entity={taskCategory}
                onLongClick={() =>
                  taskCategory.farm &&
                  setIsAddCategoryViewOpen({
                    isOpen: true,
                    taskCategoryId: taskCategory.id,
                  })
                }
              />
            ),
        )}
      </DrawerContentWrapper>
    </>
  )
}

export default TaskCategorySelectDrawerContent
