import React from "react"
import { useTranslation } from "react-i18next"
import ChooseSomethingIcon from "@/assets/icons/finance/choose-something-icon.tsx"
import styles from "@/components/shadcn/drawer/custom-drawer.module.css"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"

interface GenericTriggerProps<T> {
  currentValue: T | undefined
  onClick?: () => void
  displayText: (
    currentValue: T | undefined,
    t: (key: string) => string,
  ) => string
  displayIcon?: (currentValue: T | undefined) => JSX.Element | null
}

/**
 * Generic trigger component that displays the selected value and an icon.
 *
 * @param currentValue - The current value of the trigger
 * @param onClick - The function to be called when the trigger is clicked
 * @param displayText - The function that returns the text to be displayed in the trigger
 * @param displayIcon - The function that returns the icon to be displayed in the trigger
 */
const GenericSelectTrigger = React.forwardRef<
  HTMLDivElement,
  GenericTriggerProps<any>
>(({ onClick, currentValue, displayText, displayIcon }, ref) => {
  const { t } = useTranslation()

  return (
    <div className={styles["task-card-item"]} ref={ref} onClick={onClick}>
      {currentValue === undefined && <RequiredIndicator />}
      <div className={styles["task-card-header"]}>
        <div className={styles["icon-wrapper"]}>
          {displayIcon ? (
            displayIcon(currentValue)
          ) : (
            <ChooseSomethingIcon size={30} />
          )}
          <div>
            <p>{displayText(currentValue, t)}</p>
          </div>
        </div>
      </div>
    </div>
  )
})

GenericSelectTrigger.displayName = "GenericSelectTrigger"

export default GenericSelectTrigger
