import {
  type Address,
  type AppPackage,
  type FarmProfile,
  type FarmProfileSummary,
  FarmType,
  type UserProfile,
} from "./types.ts"

export const initialAddress: Address = {
  street: "",
  city: "",
  state: "",
  country: "",
  extra: "",
  latitude: 0,
  longitude: 0,
}

export const initialAppPackage: AppPackage = {
  name: "",
  description: "",
  is_assistant_enabled: false,
  is_finance_journal_enabled: false,
  is_gestational_calendar_enabled: false,
  farm_type: FarmType.MAMMALS,
}

export const initialFarmProfile: FarmProfile = {
  id: "",
  name: "",
  description: "",
  legal_name: "",
  tin: "",
  picture: "",
  created_at: "",
  updated_at: "",
  address: initialAddress,
  app_package: initialAppPackage,
}

export const initialSummaryFarmProfile: FarmProfileSummary = {
  id: "",
  name: "",
  picture: "",
}

export const initialUserProfile: UserProfile = {
  id: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  farm: initialSummaryFarmProfile,
  picture: "",
}
