import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type Notification,
  type NotificationPreference,
  type NotificationType,
  type WebPushSubscription,
} from "@/features/notifications/types/notifications.ts"

const NOTIFICATIONS_BASE_URL = "notifications/"
const WEB_PUSH_SUBSCRIBE_URL = "webpush/subscribe/"
const NOTIFICATIONS_PREFERENCE_URL = "notification-preferences/"

export const notificationsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<Notification[], void>({
      query: () => ({
        url: NOTIFICATIONS_BASE_URL,
      }),
      providesTags: (result) => {
        if (!result) {
          return [{ type: TagType.Notifications, id: "LIST" }]
        }

        const notificationTags = result.map(({ id }) => ({
          type: TagType.Notifications,
          id: id,
        }))

        return [
          ...notificationTags,
          { type: TagType.Notifications, id: "LIST" },
        ]
      },
    }),
    getNotificationById: builder.query<Notification, string>({
      query: (id) => ({
        url: NOTIFICATIONS_BASE_URL + `${id}/`,
      }),
      providesTags: (_result, _error, id) => [
        { type: TagType.Notifications, id },
      ],
    }),
    readNotification: builder.mutation<Notification, string>({
      query: (id) => {
        return {
          url: NOTIFICATIONS_BASE_URL + `${id}/` + "read/",
          method: "POST",
        }
      },
      invalidatesTags: (_result, _error, id) => [
        { type: TagType.Notifications, id },
      ],
    }),
    readAllNotifications: builder.mutation<void, void>({
      query: () => ({
        url: `${NOTIFICATIONS_BASE_URL}read-all/`,
        method: "POST",
      }),
      invalidatesTags: () => [{ type: TagType.Notifications, id: "LIST" }],
    }),
    getNotificationsPreference: builder.query<
      Record<NotificationType, NotificationPreference>,
      void
    >({
      query: () => ({
        url: NOTIFICATIONS_PREFERENCE_URL,
      }),
      transformResponse: (
        response: NotificationPreference[],
      ): Record<NotificationType, NotificationPreference> => {
        return response.reduce(
          (acc, preference) => {
            acc[preference.notification_type] = preference
            return acc
          },
          {} as Record<NotificationType, NotificationPreference>,
        )
      },
      providesTags: () => [{ type: TagType.NotificationPreferences }],
    }),
    updateNotificationPreference: builder.mutation<
      NotificationPreference,
      Partial<NotificationPreference>
    >({
      query: (preference) => ({
        url: NOTIFICATIONS_PREFERENCE_URL + `${preference.id}/`,
        method: "PATCH",
        body: preference,
      }),
      invalidatesTags: () => [
        {
          type: TagType.NotificationPreferences,
        },
      ],
    }),
    subscribeToWebPush: builder.mutation<
      WebPushSubscription,
      WebPushSubscription
    >({
      query: (subscription) => ({
        url: WEB_PUSH_SUBSCRIBE_URL,
        method: "POST",
        body: subscription,
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useReadAllNotificationsMutation,
  useGetNotificationsQuery,
  useGetNotificationByIdQuery,
  useReadNotificationMutation,
  useSubscribeToWebPushMutation,
  useGetNotificationsPreferenceQuery,
  useUpdateNotificationPreferenceMutation,
} = notificationsApi
