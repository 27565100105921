import React from "react"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const SmallPlus: React.FC<GeneralSvgProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || 15.575}
    height={props.size || 15.575}
    viewBox="0 0 15.575 15.575"
    {...props}
  >
    <path
      id="Icon_awesome-plus-circle"
      data-name="Icon awesome-plus-circle"
      d="M8.35.563A7.787,7.787,0,1,0,16.137,8.35,7.786,7.786,0,0,0,8.35.563Zm4.522,8.667a.378.378,0,0,1-.377.377H9.606v2.889a.378.378,0,0,1-.377.377H7.471a.378.378,0,0,1-.377-.377V9.606H4.2a.378.378,0,0,1-.377-.377V7.471A.378.378,0,0,1,4.2,7.094H7.094V4.2a.378.378,0,0,1,.377-.377H9.229a.378.378,0,0,1,.377.377V7.094h2.889a.378.378,0,0,1,.377.377Z"
      transform="translate(-0.563 -0.563)"
      fill="var(--brown)"
    />
  </svg>
)

export default SmallPlus
