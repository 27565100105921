import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./empty-chat-init.module.css"
import EmptyChatBubble from "@/features/assistant/components/chat-bubble/empty-chat-bubble.tsx"

interface EmptyChatInitProps {
  threadId: string
}

const EmptyChatInit: React.FC<EmptyChatInitProps> = ({ threadId }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.messagesContent}>
      <div className={styles.welcome}>
        <i>{t("welcomeToAssistant")}</i>
        <i>{t("startConversation")}</i>
      </div>
      <EmptyChatBubble message={t("generalHealthStatus")} threadId={threadId} />
      <EmptyChatBubble
        message={t("immediateMedicalAttention")}
        threadId={threadId}
      />
      <EmptyChatBubble message={t("optimizeFeeding")} threadId={threadId} />
      <EmptyChatBubble message={t("closeToCalving")} threadId={threadId} />
      <EmptyChatBubble
        message={t("milkProductionChange")}
        threadId={threadId}
      />
      <EmptyChatBubble message={t("fertilityProblems")} threadId={threadId} />
    </div>
  )
}

export default EmptyChatInit
