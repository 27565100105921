import React from "react"
import { Outlet } from "react-router-dom"
import BottomMenu from "@/components/bottom-menu/bottom-menu.tsx"
import style from "@/layouts/layouts.module.css"

interface StandardLayoutProps {}

/**
 * Standard layout for mobile devices.
 * Accounts for the bottom menu which is always visible.
 * NonScrollable, if you want to achieve scroll over the <Outlet/> do that in the component itself.
 * This avoids any unnecessary scrollbars.
 */

const StandardLayoutMobile: React.FC<StandardLayoutProps> = () => {
  return (
    <div className={style.standardLayout}>
      <Outlet />
      <BottomMenu />
    </div>
  )
}

export default StandardLayoutMobile
