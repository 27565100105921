import React from "react"

const TreatmentsCountIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="20 591 30 30"
    >
      <g data-name="Group 4354">
        <path
          d="M27 591h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H27a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="pink"
          fillRule="evenodd"
          data-name="Rectangle 1962"
        />
        <g data-name="icon tratament">
          <g data-name="Path 1006">
            <path
              d="M38.464 605.872a3.475 3.475 0 0 0-3.476 3.467 3.475 3.475 0 0 0 3.476 3.467 3.475 3.475 0 0 0 3.476-3.467 3.475 3.475 0 0 0-3.476-3.467Zm0 6.259a2.798 2.798 0 0 1-2.8-2.792 2.798 2.798 0 0 1 5.599 0 2.798 2.798 0 0 1-2.8 2.792Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="M38.464 605.872a3.475 3.475 0 0 0-3.476 3.467 3.475 3.475 0 0 0 3.476 3.467 3.475 3.475 0 0 0 3.476-3.467 3.475 3.475 0 0 0-3.476-3.467Zm0 6.259a2.798 2.798 0 0 1-2.8-2.792 2.798 2.798 0 0 1 5.599 0 2.798 2.798 0 0 1-2.8 2.792Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".4"
              stroke="#fff"
              fill="transparent"
            />
          </g>
          <g data-name="Path 1007">
            <path
              d="M39.522 607.627a.339.339 0 0 0-.466.11l-1.761 2.85a.337.337 0 0 0 .288.515.339.339 0 0 0 .288-.16l1.761-2.85a.337.337 0 0 0-.11-.465Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="M39.522 607.627a.339.339 0 0 0-.466.11l-1.761 2.85a.337.337 0 0 0 .288.515.339.339 0 0 0 .288-.16l1.761-2.85a.337.337 0 0 0-.11-.465Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke="#fff"
              fill="transparent"
            />
          </g>
          <g data-name="Path 1008">
            <path
              d="m28.796 610.425-.051-.032a1.23 1.23 0 0 1-.551-.773 1.228 1.228 0 0 1 .157-.935.339.339 0 0 0-.574-.358 1.897 1.897 0 0 0-.243 1.445c.115.498.418.922.852 1.193l.051.032a.337.337 0 0 0 .36-.572Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="m28.796 610.425-.051-.032a1.23 1.23 0 0 1-.551-.773 1.228 1.228 0 0 1 .157-.935.339.339 0 0 0-.574-.358 1.897 1.897 0 0 0-.243 1.445c.115.498.418.922.852 1.193l.051.032a.337.337 0 0 0 .36-.572Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke="#fff"
              fill="transparent"
            />
          </g>
          <g data-name="Path 1009">
            <path
              d="m35.705 599.3.052.033c.58.362.757 1.128.394 1.707a.337.337 0 0 0 .287.517.339.339 0 0 0 .287-.158 1.914 1.914 0 0 0-.609-2.639l-.051-.032a.337.337 0 1 0-.36.573Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="m35.705 599.3.052.033c.58.362.757 1.128.394 1.707a.337.337 0 0 0 .287.517.339.339 0 0 0 .287-.158 1.914 1.914 0 0 0-.609-2.639l-.051-.032a.337.337 0 1 0-.36.573Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke="#fff"
              fill="transparent"
            />
          </g>
          <g data-name="Path 1010">
            <path
              d="m35.124 606.656 2.821-4.496a3.32 3.32 0 0 0 .426-2.528 3.328 3.328 0 0 0-1.491-2.089l-.052-.032A3.357 3.357 0 0 0 35.05 597a3.345 3.345 0 0 0-2.85 1.573l-5.642 8.992a3.32 3.32 0 0 0-.426 2.528c.2.873.73 1.614 1.49 2.089l.053.032c.535.334 1.15.511 1.779.511a3.345 3.345 0 0 0 2.85-1.573l2.82-4.496Zm-2.35-7.725a2.67 2.67 0 0 1 2.275-1.256c.502 0 .993.142 1.42.408l.052.033c.607.379 1.03.97 1.19 1.667.16.696.04 1.413-.34 2.019l-2.642 4.21-4.597-2.87 2.641-4.21Zm-1.045 11.863a2.67 2.67 0 0 1-2.276 1.256c-.502 0-.993-.141-1.42-.408l-.052-.032a2.657 2.657 0 0 1-1.19-1.668 2.651 2.651 0 0 1 .34-2.019l2.641-4.21 4.598 2.871-2.641 4.21Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="M35.124 606.656s0 0 0 0l2.821-4.496a3.32 3.32 0 0 0 .426-2.528 3.328 3.328 0 0 0-1.491-2.089l-.052-.032A3.357 3.357 0 0 0 35.05 597a3.345 3.345 0 0 0-2.85 1.573l-5.642 8.992a3.32 3.32 0 0 0-.426 2.528c.2.873.73 1.614 1.49 2.089l.053.032c.535.334 1.15.511 1.779.511a3.345 3.345 0 0 0 2.85-1.573l2.82-4.496s0 0 0 0Zm-2.35-7.725a2.67 2.67 0 0 1 2.275-1.256c.502 0 .993.142 1.42.408l.052.033c.607.379 1.03.97 1.19 1.667.16.696.04 1.413-.34 2.019l-2.642 4.21-4.597-2.87 2.641-4.21Zm-1.045 11.863a2.67 2.67 0 0 1-2.276 1.256c-.502 0-.993-.141-1.42-.408l-.052-.032a2.657 2.657 0 0 1-1.19-1.668 2.651 2.651 0 0 1 .34-2.019l2.641-4.21 4.598 2.871-2.641 4.21Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".4"
              stroke="#fff"
              fill="transparent"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default TreatmentsCountIcon
