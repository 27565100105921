import queryString from "query-string"
import { useState, useEffect } from "react"
import { useGetMyFarmQuery } from "@/features/settings-account/api/account-api.ts"
import { initialFarmProfile } from "@/features/settings-account/utils/initialStates.ts"

interface WeatherParams {
  latitude: number
  longitude: number
  daily: string[]
  hourly: string[]
}

export interface WeeklyWeatherData {
  minTemperatures: number[]
  maxTemperatures: number[]
  cloudCoverages: number[]
}

const calculateDailyCloudCoverage = (hourlyData: number[]): number[] => {
  return hourlyData.reduce((acc, curr, index) => {
    const dayIndex = Math.floor(index / 24)
    acc[dayIndex] = (acc[dayIndex] || 0) + curr / 24
    return acc
  }, [] as number[])
}

const fetchWeatherData = async (params: WeatherParams) => {
  const queryStringified = queryString.stringify(params, {
    arrayFormat: "comma",
  })
  const response = await fetch(
    `https://api.open-meteo.com/v1/forecast?${queryStringified}`,
  )
  if (!response.ok) {
    throw new Error("Failed to fetch weather data")
  }
  return response.json()
}

const useWeekDailyWeather = () => {
  const [weeklyData, setWeeklyData] = useState<WeeklyWeatherData>({
    minTemperatures: [],
    maxTemperatures: [],
    cloudCoverages: [],
  })
  const [error, setError] = useState<boolean>()

  const { data: farmProfile = initialFarmProfile } = useGetMyFarmQuery()
  const latitude = farmProfile?.address?.latitude
  const longitude = farmProfile?.address?.longitude

  useEffect(() => {
    fetchWeatherData({
      latitude: latitude,
      longitude: longitude,
      daily: ["temperature_2m_min", "temperature_2m_max"],
      hourly: ["cloud_cover"],
    })
      .then((data: any) =>
        setWeeklyData({
          minTemperatures: data.daily.temperature_2m_min,
          maxTemperatures: data.daily.temperature_2m_max,
          cloudCoverages: calculateDailyCloudCoverage(data.hourly.cloud_cover),
        }),
      )
      .catch(() => setError(true))
  }, [latitude, longitude])

  return { weeklyData, error }
}

export default useWeekDailyWeather
