import { skipToken } from "@reduxjs/toolkit/query"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteGestationCalendarMutation,
  useGetGestationCalendarByIdQuery,
} from "@/features/gestational-calendar/api/gestation-calendar-api.ts"
import GestationCalendarDetails from "@/features/gestational-calendar/components/gestation-calendar-details/gestation-calendar-details.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  resetGestationalCalendarDraft,
  setGestationalCalendarDraft,
} from "@/redux/slices/gestational-calendars-draft-slice.ts"
import { ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH } from "@/utils/constants/routes.ts"

const CalendarView = () => {
  const { t } = useTranslation()
  const { calendarId } = useParams()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { openModal } = useModal()
  const { handleBackNavigation } = useNavigationFallback()
  const [deleteCalendarMutation] = useDeleteGestationCalendarMutation()
  const {
    data: calendar,
    isLoading,
    isFetching,
  } = useGetGestationCalendarByIdQuery(calendarId ?? skipToken)

  const onBackClick = () => {
    dispatch(resetGestationalCalendarDraft({}))
    handleBackNavigation()
  }

  useEffect(() => {
    if (calendar) {
      dispatch(setGestationalCalendarDraft(calendar))
    }

    return () => {
      dispatch(resetGestationalCalendarDraft({}))
    }
  }, [calendar, dispatch])

  const deleteCalendar = (delete_animal_events: boolean) => {
    calendarId &&
      toast
        .promise(
          deleteCalendarMutation({
            gestationCalendarId: calendarId,
            delete_animal_events: delete_animal_events,
          }).unwrap(),
          {
            pending: t("deletingCalendar"),
            success: t("calendarDeletedSuccessfully"),
            error: t("calendarDeleteError"),
          },
        )
        .then(() => {
          dispatch(resetGestationalCalendarDraft({}))
          navigate(ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH)
        })
  }

  const handleDeleteEvents = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteAssocAnimalEvents"),
      onActionText: t("yes"),
      onBackText: t("no"),
      onActionClick: () => deleteCalendar(true),
      onBackClick: () => deleteCalendar(false),
    })
  }

  const handleDeleteCalendar = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteCalendarConfirmation"),
      content: t("calendarWillBeDeleted"),
      onActionClick: handleDeleteEvents,
    })
  }

  return (
    <>
      <EditorTopBar
        text={t("gestationalCalendar")}
        isModified={false}
        onBackClick={onBackClick}
      />
      <PageContentWrapperLarge>
        {isLoading || (isFetching && <LoadingAnimation />)}
        {!isLoading && !isFetching && calendar && (
          <>
            <GestationCalendarDetails gestationalCalendar={calendar} />
            <DeleteButton
              text={t("deleteCalendar")}
              onClick={handleDeleteCalendar}
              style={{
                marginBlock: "50px",
              }}
            />
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default CalendarView
