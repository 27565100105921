import React, { useCallback, useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import styles from "./new-note-view.module.css"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoteTopBar from "@/components/top-bars/note-top-bar/note-top-bar.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { checkNoteChanges } from "@/features/notes"
import { usePostNoteMutation } from "@/features/notes/api/notes-api.ts"
import NoteEditor, {
  type EditorHandles,
} from "@/features/notes/components/editor/note-editor.tsx"
import useInitializeDraftNote from "@/features/notes/hooks/use-initialize-draft-note.ts"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  deleteNotesDraft,
  initialNoteState,
} from "@/redux/slices/notes-draft-slice.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"
import { NEW_ENTITY } from "@/utils/types/misc-types.ts"

export const NewNoteView: React.FC = () => {
  const { handleBackNavigation } = useNavigationFallback()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const editorRef = useRef<EditorHandles | null>(null)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [postNote] = usePostNoteMutation()
  const { noteDraft } = useInitializeDraftNote()
  const { openModal } = useModal()

  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      dispatch(deleteNotesDraft(NEW_ENTITY))
    }, DELETE_ENTRY_TIMEOUT)
  }, [dispatch])

  const hasChanges =
    !!noteDraft && checkNoteChanges(initialNoteState, noteDraft)

  useEffect(() => {
    const checkUndoRedoAvailability = () => {
      if (editorRef.current) {
        setCanUndo(editorRef.current.canUndo())
        setCanRedo(editorRef.current.canRedo())
      }
    }

    const intervalId = setInterval(checkUndoRedoAvailability, 100)

    return () => clearInterval(intervalId)
  }, [])

  useEffect(() => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("continueWhereYouLeftOff"),
        content: t("youHaveUnsavedChanges"),
        onBackText: t("discard"),
        onActionText: t("continue"),
        onBackClick: () => {
          deleteDraft()
        },
      })
    }
    // Run on initial render only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDraft, openModal, t])

  const handlePostNote = () => {
    toast
      .promise(postNote(noteDraft).unwrap(), {
        pending: t("postingNote"),
        success: t("notePosted"),
        error: t("notePostError"),
      })
      .then(() => {
        handleBackNavigation()
        deleteDraft()
      })
  }

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          handleBackNavigation()
          deleteDraft()
        },
      })
    } else {
      handleBackNavigation()
      deleteDraft()
    }
  }

  return (
    <div className={styles.editorBody}>
      <NoteTopBar
        onBack={handleBackClick}
        onSave={handlePostNote}
        isNew={true}
        onUndo={() => editorRef.current?.undo()}
        onRedo={() => editorRef.current?.redo()}
        canUndo={canUndo}
        canRedo={canRedo}
        hasChanges={hasChanges}
      />
      {noteDraft ? (
        <NoteEditor ref={editorRef} noteId={NEW_ENTITY} draftNote={noteDraft} />
      ) : (
        <LoadingAnimation />
      )}
    </div>
  )
}
