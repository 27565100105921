import React from "react"
import styles from "./search-bar.module.css"
import SearchIcon from "@/assets/icons/misc/search-icon.tsx"
import { type SearchBarProps } from "@/components/search-bar/search-bar.tsx"

const SearchBarGeneral: React.FC<SearchBarProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <div className={styles.searchBarGeneral}>
      <input
        type="text"
        placeholder={placeholder}
        className={styles.searchInput}
        value={value}
        onChange={onChange}
      />
      <SearchIcon className={styles.searchImage} alt="search" />
    </div>
  )
}

export default SearchBarGeneral
