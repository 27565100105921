import queryString from "query-string"
import { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useGetAllAnimalEventsQuery } from "@/features/animal-events/api/animal-events-api.ts"
import { type AnimalEventsFilterSelections } from "@/features/animal-events/types/animal-events-constants.ts"

const initialFilterSelections: AnimalEventsFilterSelections = {
  searchQuery: "",
  selectedAnimal: "",
  type: "",
}

const useAnimalEventsFilter = () => {
  const navigate = useNavigate()
  const { data } = useGetAllAnimalEventsQuery()
  const query = new URLSearchParams(useLocation().search)

  const type = query.get("eventType") || ""
  const searchQuery = query.get("searchQuery") || ""
  const selectedAnimal = query.get("selectedAnimal") || ""

  const initialFilter: AnimalEventsFilterSelections = {
    searchQuery,
    selectedAnimal,
    type,
  }

  const [selections, setSelections] = useState<AnimalEventsFilterSelections>(
    initialFilter || initialFilterSelections,
  )

  const updateURL = useCallback(() => {
    const queryParams = {
      searchQuery: selections.searchQuery,
      selectedAnimal: selections.selectedAnimal,
      eventType: selections.type,
    }
    const searchString = queryString.stringify(queryParams)
    navigate({ search: searchString }, { replace: true })
  }, [selections, navigate])

  useEffect(() => {
    updateURL()
  }, [selections, updateURL])

  const handleSelectedAnimal = (selectedAnimal: string) => {
    setSelections((prev) => ({
      ...prev,
      selectedAnimal,
    }))
  }

  const handleSearch = (searchQuery: string) => {
    setSelections((prev) => ({
      ...prev,
      searchQuery,
    }))
  }

  const handleEventType = (type: string | undefined) => {
    setSelections((prev) => ({
      ...prev,
      type,
    }))
  }

  const filteredAnimalEvents = data?.filter((event) => {
    const matchesAnimal =
      selections.selectedAnimal === "" ||
      event.animal === selections.selectedAnimal

    const matchesType = !selections.type || event.type === selections.type

    const matchesSearchQuery =
      !selections.searchQuery ||
      event?.description?.includes(selections.searchQuery) ||
      event.notes?.includes(selections.searchQuery)

    return matchesAnimal && matchesType && matchesSearchQuery
  })

  return {
    selections,
    filteredAnimalEvents,
    handleSearch,
    handleSelectedAnimal,
    handleEventType,
    count: data?.length,
  }
}

export default useAnimalEventsFilter
