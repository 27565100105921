import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const TaskCategoryIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="26 292 30 30"
    >
      <g data-name="Group 2288">
        <path
          d="M33 292h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H33a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="var(--menu-financial-journal-color)"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <g data-name="Group 2147">
          <path
            d="M45.339 305.264h1.735c1.736 0 2.603-.867 2.603-2.603v-1.735c0-1.736-.867-2.604-2.603-2.604H45.34c-1.736 0-2.604.868-2.604 2.604v1.735c0 1.736.868 2.603 2.604 2.603Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.3"
            stroke="#fff"
            fill="transparent"
            data-name="Path 1242"
          />
          <path
            d="M34.926 315.678h1.735c1.736 0 2.603-.868 2.603-2.604v-1.735c0-1.736-.867-2.604-2.603-2.604h-1.735c-1.736 0-2.604.868-2.604 2.604v1.735c0 1.736.868 2.604 2.604 2.604Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.3"
            stroke="#fff"
            fill="transparent"
            data-name="Path 1243"
          />
          <path
            d="M35.793 305.264a3.471 3.471 0 1 0 0-6.942 3.471 3.471 0 0 0 0 6.942Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.3"
            stroke="#fff"
            fill="transparent"
            data-name="Path 1244"
          />
          <path
            d="M46.206 315.678a3.471 3.471 0 1 0 0-6.943 3.471 3.471 0 0 0 0 6.943Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.3"
            stroke="#fff"
            fill="transparent"
            data-name="Path 1245"
          />
        </g>
      </g>
    </svg>
  )
}

export default TaskCategoryIcon
