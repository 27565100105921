import React from "react"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const TikTokIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || "34"}
      height={props.size || "34"}
      viewBox="0 0 31.491 35.168"
      {...props}
    >
      <path
        id="tik-tok-brand-assets-video-socialnetwork-oneline-svgrepo-com"
        d="M32.894,11.134C30.245,11.252,24.8,9.79,24.226,3H19.169V22.8c0,5.658-4.456,8.081-7.424,5.931C7.976,26,10.5,20.329,15.2,21.036V15.024C11.585,15.024,4,16.439,4,25.28,4,36.6,16.306,36.243,19.169,34.751c4.659-2.427,5.779-5.056,5.779-10.886v-9.9a24.852,24.852,0,0,0,7.946,2.122"
        transform="translate(-2.75 -1.75)"
        fill="none"
        stroke={props.color || "var(--brown)"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2.5"
      />
    </svg>
  )
}

export default TikTokIcon
