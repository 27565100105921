import { useState, useEffect } from "react"
import { TOP_BAR_HEIGHT } from "@/utils/constants/size-constants.ts"

const useViewportHeight = () => {
  const [height, setHeight] = useState(
    window.visualViewport
      ? window.visualViewport.height - TOP_BAR_HEIGHT
      : window.innerHeight - TOP_BAR_HEIGHT,
  )

  const resizeHandler = () => {
    if (window.visualViewport) {
      setHeight(window.visualViewport.height - TOP_BAR_HEIGHT)
    }
  }

  useEffect(() => {
    if (window.visualViewport) {
      window.visualViewport.addEventListener("resize", resizeHandler)
      return () => {
        window.visualViewport?.removeEventListener("resize", resizeHandler)
      }
    }
  }, [])

  return height
}

export default useViewportHeight
