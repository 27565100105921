import React, { type ChangeEvent, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import PageContentWrapperSmall from "@/components/page-content-wrappers/page-content-wrapper-small.tsx"
import SearchBar from "@/components/search-bar/search-bar.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import NotesGroup from "@/features/notes/components/notes-group/notes-group.tsx"
import { useSearchNotes } from "@/features/notes/hooks/use-search-notes.ts"
import { NEW_NOTE_SCREEN_PATH } from "@/utils/constants/routes.ts"
import {
  groupItemsByTimeStampWithField,
  initialItemsTimeGroups,
  type ItemsTimeGroups,
} from "@/utils/helpers/time-grouping.ts"

/**
 * NotesPage component for displaying a list of notes grouped by month and year.
 * Allows searching notes by title or content.
 */
export const NotesPage: React.FC = () => {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>("")
  const filteredNotes = useSearchNotes(searchQuery)
  const [sortedNotes, setSortedNotes] = useState<ItemsTimeGroups>(
    initialItemsTimeGroups,
  )

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  useEffect(() => {
    setSortedNotes(groupItemsByTimeStampWithField(filteredNotes, "updated_at"))
  }, [filteredNotes])

  return (
    <>
      <MobileTopBar title={t("notes")} navigateTo={NEW_NOTE_SCREEN_PATH} />
      <PageContentWrapperSmall>
        <SearchBar
          value={searchQuery}
          onChange={handleSearchInputChange}
          placeholder={t("searchNote")}
        />

        <NotesGroup notes={sortedNotes} />
      </PageContentWrapperSmall>
    </>
  )
}
