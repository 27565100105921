import { WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const PlusIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      width={props.size || 12}
      height={props.size || 12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.66654 5.33333H11.3332C11.51 5.33333 11.6796 5.40357 11.8046 5.5286C11.9296 5.65362 11.9999 5.82319 11.9999 6C11.9999 6.17681 11.9296 6.34638 11.8046 6.4714C11.6796 6.59643 11.51 6.66667 11.3332 6.66667H6.66654V11.3333C6.66654 11.5101 6.59631 11.6797 6.47128 11.8047C6.34626 11.9298 6.17669 12 5.99988 12C5.82307 12 5.6535 11.9298 5.52847 11.8047C5.40345 11.6797 5.33321 11.5101 5.33321 11.3333V6.66667H0.666544C0.489733 6.66667 0.320164 6.59643 0.19514 6.4714C0.0701159 6.34638 -0.00012207 6.17681 -0.00012207 6C-0.00012207 5.82319 0.0701159 5.65362 0.19514 5.5286C0.320164 5.40357 0.489733 5.33333 0.666544 5.33333H5.33321V0.666666C5.33321 0.489855 5.40345 0.320286 5.52847 0.195262C5.6535 0.070238 5.82307 0 5.99988 0C6.17669 0 6.34626 0.070238 6.47128 0.195262C6.59631 0.320286 6.66654 0.489855 6.66654 0.666666V5.33333Z"
        fill={props.color || WHITE}
      />
    </svg>
  )
}

export default PlusIcon
