import React, { useEffect, useRef, useState } from "react"
import styles from "./widget-card.module.css"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"

interface WidgetCardProps {
  title: string
  children: React.ReactNode
  navigateTo?: string
  addItemGoTo?: () => void
  customStyles?: React.CSSProperties
}

const WidgetCard: React.FC<WidgetCardProps> = ({
  title,
  children,
  navigateTo,
  addItemGoTo,
  customStyles,
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (contentRef.current) {
      setIsOverflowing(
        contentRef.current.scrollHeight > contentRef.current.clientHeight - 30,
      )
    }
  }, [contentRef])

  return (
    <div
      className={
        isOverflowing
          ? styles["widget-card-overflowing"]
          : styles["widget-card"]
      }
      style={customStyles}
    >
      <WidgetHeader
        title={title}
        navigateTo={navigateTo}
        addItemGoTo={addItemGoTo}
      />
      <div className={styles["widget-content"]} ref={contentRef}>
        {children}
      </div>
    </div>
  )
}

export default WidgetCard
