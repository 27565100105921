import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import styles from "./events-editor.module.css"
import UserIcon from "@/assets/icons/account/user-icon.tsx"
import DetailIcon from "@/assets/icons/farm/details-icon.tsx"
import NoteThumbnailIcon from "@/assets/icons/notes/note-thumbnail-icon.tsx"
import CustomSelectDropdown from "@/components/custom-select/custom-select-dropdown.tsx"
import GenericDatePicker from "@/components/generic-date-picker/generic-date-picker.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import AnimalEventsTypeDrawer from "@/features/animal-events/components/animal-event-type-drawer/animal-event-type-drawer.tsx"
import MeasurementEventEditor from "@/features/animal-events/components/measurement-event-editor/measurement-event-editor.tsx"
import MovementEventEditor from "@/features/animal-events/components/movement-event-editor/movement-event-editor.tsx"
import TreatmentEventEditor from "@/features/animal-events/components/treatment-event-editor/treatment-event-editor.tsx"
import useCheckEventType from "@/features/animal-events/hooks/use-check-event-type.ts"
import useMapEventTypesToParents from "@/features/animal-events/hooks/use-map-event-types-to-parents.ts"
import { MainAnimalEventType } from "@/features/animal-events/types/animal-events.ts"
import AssociatedAnimalsList from "@/features/farm/components/associated-animals-list/associated-animals-list.tsx"
import DrawerAnimals from "@/features/farm/components/drawer-animals/drawer-animals.tsx"
import { type GestationCalendar } from "@/features/gestational-calendar/types/calendar-types.ts"
import { FP_QUERY_PARAMS } from "@/features/gestational-calendar/utils/gestation-calendar-consts.ts"
import { getDateInterval } from "@/features/gestational-calendar/utils/gestational-calendar-utils.ts"
import {
  useGetProfileQuery,
  useGetUsersForFarmQuery,
} from "@/features/settings-account/api/account-api.ts"
import DrawerAnimalsAssocTrigger from "@/features/tasks/components/drawer-animals-assoc-trigger/drawer-animals-assoc-trigger.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  type AnimalEventDraft,
  setDraftEventAnimal,
  setDraftEventDate,
  setDraftEventDescription,
  setDraftEventGestationCalendarEntry,
  setDraftEventNotes,
  setDraftEventType,
  setDraftEventUser,
} from "@/redux/slices/events-draft-slice.ts"

interface EventsEditorProps {
  eventDraft: AnimalEventDraft
  gestationCalendar?: GestationCalendar
}

const AnimalEventEditor: React.FC<EventsEditorProps> = ({
  eventDraft,
  gestationCalendar,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const [searchParams] = useSearchParams()
  const eventTypeId = searchParams.get(FP_QUERY_PARAMS.EVENT_TYPE_ID)
  const gestationCalendarEntryId = searchParams.get(
    FP_QUERY_PARAMS.GESTATION_CALENDAR_ENTRY_ID,
  )
  const animalId = searchParams.get(FP_QUERY_PARAMS.ANIMAL_ID)

  const { data: myProfile } = useGetProfileQuery()
  const { data: farmUsers } = useGetUsersForFarmQuery()
  const farmUsersOptions = farmUsers?.map((user) => ({
    label: user.first_name + " " + user.last_name,
    value: user.id,
  }))

  useEffect(() => {
    if (gestationCalendarEntryId) {
      dispatch(setDraftEventGestationCalendarEntry(gestationCalendarEntryId))
    }
  }, [dispatch, gestationCalendarEntryId])

  useEffect(() => {
    if (myProfile && !eventDraft.user) {
      dispatch(setDraftEventUser(myProfile.id))
    }
  }, [myProfile, dispatch, eventDraft.user])

  const gestationCalendarEntry = gestationCalendar?.entries.find(
    (entry) => entry.id === gestationCalendarEntryId,
  )

  const isDateChangeable = () => {
    if (!gestationCalendarEntry) return true
    return !!gestationCalendarEntry.event.interval_duration
  }

  const parentEventTypes = useMapEventTypesToParents()

  const checkEventType = useCheckEventType()

  const isMovementEvent = checkEventType(
    eventDraft.type,
    MainAnimalEventType.Movement,
  )

  const isMeasurementEvent = checkEventType(
    eventDraft.type,
    MainAnimalEventType.Measurement,
  )

  const isTreatmentEvent = checkEventType(
    eventDraft.type,
    MainAnimalEventType.Treatment,
  )

  const setEventDate = (date: string) => {
    dispatch(setDraftEventDate(date))
  }

  const setEventUser = (userId: string) => {
    dispatch(setDraftEventUser(userId))
  }

  const setEventType = (eventType: string | undefined) => {
    dispatch(setDraftEventType(eventType))
  }

  const handleAnimalsChange = (animalIds: string[]) => {
    dispatch(setDraftEventAnimal(animalIds[0]))
  }

  const handleNotesChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setDraftEventNotes(event.target.value))
  }

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    dispatch(setDraftEventDescription(event.target.value))
  }

  if (!eventDraft) {
    return <LoadingAnimation />
  }

  const currentAnimal = eventDraft.animal ? [eventDraft.animal] : []

  const dateInterval = isDateChangeable()
    ? getDateInterval(gestationCalendarEntry, true)
    : undefined

  return (
    <>
      <div className={styles.eventInfo}>{t("generalEventDetails")}</div>
      <OverflowMenuItem isDisabled={!!animalId}>
        <DrawerAnimals
          currentValue={currentAnimal}
          onSaveClick={handleAnimalsChange}
          TriggerComponent={DrawerAnimalsAssocTrigger}
        />
        {eventDraft.animal && (
          <AssociatedAnimalsList
            animals={Array.of(eventDraft.animal)}
            onAnimalsChange={handleAnimalsChange}
          />
        )}
      </OverflowMenuItem>
      <OverflowMenuItem isDisabled={!!eventTypeId}>
        {parentEventTypes && (
          <AnimalEventsTypeDrawer
            selectedValue={eventDraft.type}
            onEventSelect={setEventType}
            eventTypesMap={parentEventTypes}
            fallbackText={"selectEventType"}
          />
        )}
      </OverflowMenuItem>
      <OverflowMenuItem skeletonHeight={54} isLoading={!farmUsersOptions}>
        <CustomSelectDropdown
          placeholder={"selectUser"}
          options={farmUsersOptions || []}
          onSelectionChange={setEventUser}
          currentSelection={eventDraft.user}
          icon={<UserIcon size={30} />}
        />
      </OverflowMenuItem>
      <GenericDatePicker
        isDisabled={!isDateChangeable()}
        value={eventDraft.date}
        onChange={setEventDate}
        dateInterval={dateInterval}
      />
      {isMeasurementEvent && <MeasurementEventEditor draftEvent={eventDraft} />}
      {isTreatmentEvent && <TreatmentEventEditor draftEvent={eventDraft} />}
      {isMovementEvent && <MovementEventEditor draftEvent={eventDraft} />}
      <div className={styles.eventInfo}>{t("description")}</div>
      <TextAreaFieldWrapperWithIcon
        icon={<DetailIcon />}
        name={t("details")}
        isReadOnly={false}
        value={eventDraft.description}
        onChange={handleDescriptionChange}
      />
      <TextAreaFieldWrapperWithIcon
        icon={<NoteThumbnailIcon size={30} />}
        name={t("otherObservations")}
        isReadOnly={false}
        value={eventDraft.notes}
        onChange={handleNotesChange}
      />
    </>
  )
}

export default AnimalEventEditor
