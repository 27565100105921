import {
  differenceInCalendarDays,
  endOfMonth,
  endOfYear,
  format,
  isFuture,
  isPast,
  isToday,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from "date-fns"
import { t } from "i18next"
import { TIME_PERIOD } from "@/features/finance/types/finance-constants.ts"

export function getAgeFromDateOfBirth(
  dob: string,
  returnType: "string" | "number" = "number",
) {
  const birthDate = new Date(dob)
  const currentDate = new Date()

  let years = currentDate.getFullYear() - birthDate.getFullYear()
  let months = currentDate.getMonth() - birthDate.getMonth()

  if (
    months < 0 ||
    (months === 0 && currentDate.getDate() < birthDate.getDate())
  ) {
    years--
    months = 12 + months
  }

  if (returnType === "string") {
    return `${t("age")}: ${years} ${t("years")} ${months} ${t("months")}`
  } else {
    return years
  }
}

export function formatDate(dateString: string) {
  const date = new Date(dateString)
  if (isNaN(date.getTime())) {
    throw new Error("Invalid Date")
  }
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }
  return date.toLocaleDateString("ro-RO", options)
}

// changed to ro-Ro since Tudor said that at least for demo period all should be in ro
export function formatDateTimeToRo(dateString: string | undefined): string {
  const date = new Date(dateString || "")
  if (isNaN(date.getTime())) {
    return ""
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }

  return date.toLocaleDateString("ro-RO", options)
}

export function elapsedTime(date: string): string {
  const now = new Date()
  const past = new Date(date)
  const diff = Math.floor((now.getTime() - past.getTime()) / 1000)

  const seconds = diff % 60
  const minutes = Math.floor(diff / 60) % 60
  const hours = Math.floor(diff / 3600) % 24
  const days = Math.floor(diff / 86400)

  if (days > 0) {
    return `${days}d`
  } else if (hours > 0) {
    return `${hours}h`
  } else if (minutes > 0) {
    return `${minutes}m`
  } else {
    return `${seconds}s`
  }
}

export function getAppVersionDateFormat(date: string) {
  const localDate: Date = new Date(date)
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }
  return localDate.toLocaleString("en-GB", options)
}

export const getLastWeekStartAndEndDates = () => {
  const today = new Date()
  const lastWeekStart = subDays(today, 7)
  const lastWeekEnd = today

  const formattedLastWeekStart = format(lastWeekStart, "dd.MM.yyyy")
  const formattedLastWeekEnd = format(lastWeekEnd, "dd.MM.yyyy")

  return { start: formattedLastWeekStart, end: formattedLastWeekEnd }
}

export const getLastMonthStartAndEndDates = () => {
  const today = new Date()
  const lastMonthStart = startOfMonth(subMonths(today, 1))
  const lastMonthEnd = endOfMonth(subMonths(today, 1))

  const formattedLastMonthStart = format(lastMonthStart, "dd.MM.yyyy")
  const formattedLastMonthEnd = format(lastMonthEnd, "dd.MM.yyyy")

  return { start: formattedLastMonthStart, end: formattedLastMonthEnd }
}

export const getLastYearStartAndEndDates = () => {
  const today = new Date()
  const lastYear = subYears(today, 1)
  const lastYearStart = startOfYear(lastYear)
  const lastYearEnd = endOfYear(lastYear)

  const formattedLastYearStart = format(lastYearStart, "dd.MM.yyyy")
  const formattedLastYearEnd = format(lastYearEnd, "dd.MM.yyyy")

  return { start: formattedLastYearStart, end: formattedLastYearEnd }
}

export const getTimePeriod = (timePeriod: string) => {
  switch (timePeriod) {
    case TIME_PERIOD.PERIOD_LAST_WEEK:
      return getLastWeekStartAndEndDates()
    case TIME_PERIOD.PERIOD_LAST_MONTH:
      return getLastMonthStartAndEndDates()
    case TIME_PERIOD.PERIOD_LAST_YEAR:
      return getLastYearStartAndEndDates()
    default:
      return { start: "", end: "" }
  }
}

export const stringifyTimePeriod = (timePeriod: string) => {
  const { start, end } = getTimePeriod(timePeriod)
  return `${start} - ${end}`
}

export const stringifyCustomTimePeriod = (
  startDate: Date | undefined,
  endDate: Date | undefined,
) => {
  if (startDate && endDate) {
    return `${format(startDate, "dd-MM-yyyy")} - ${format(endDate, "dd-MM-yyyy")}`
  }
}

export const getDayElapsedPercentageBetweenPastDateAndFutureDate = (
  startDate: string,
  endDate: string,
) => {
  if (isPast(endDate) && isPast(startDate)) {
    return
  }

  if (isFuture(endDate) && isFuture(startDate)) {
    return
  }

  if (isToday(endDate) && isToday(startDate)) {
    return
  }

  const timeInDaysBetweenDates = differenceInCalendarDays(
    new Date(endDate),
    new Date(startDate),
  )

  if (timeInDaysBetweenDates === 0) {
    return 100
  }

  const timeElapsed = differenceInCalendarDays(new Date(), new Date(startDate))

  return Math.round(
    ((timeInDaysBetweenDates - timeElapsed) / timeInDaysBetweenDates) * 100,
  )
}
