import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./transaction-category-drawer-content.module.css"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/shadcn/tabs/tabs.tsx"
import { useGetTransactionCategoriesQuery } from "@/features/finance/api/transaction-categories-api.ts"
import AddFinanceCategoryCard from "@/features/finance/components/finance-category-card/add-transaction-category-card.tsx"
import FinanceCategoryCard from "@/features/finance/components/finance-category-card/finance-category-card.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import { TRANSACTION_CATEGORY_TYPE } from "@/features/finance/types/finance-constants.ts"
import { type TransactionCategory } from "@/features/finance/types/transaction.ts"

interface TransactionCategoryDrawerContentProps {
  setIsAddCategoryViewOpen: ({
    isOpen,
    transactionCategoryId,
  }: {
    isOpen: boolean
    transactionCategoryId: string | null
  }) => void
  isOutflow: boolean
  setIsOutflow: (isOutflow: boolean) => void
  selectedCategory: TransactionCategory | undefined
  onCategorySelect: (category: TransactionCategory) => void
}

const TransactionCategoryDrawerContent: React.FC<
  TransactionCategoryDrawerContentProps
> = ({
  setIsAddCategoryViewOpen,
  setIsOutflow,
  isOutflow,
  onCategorySelect,
  selectedCategory,
}) => {
  const { t } = useTranslation()
  const { data: transactionCategories = [] } =
    useGetTransactionCategoriesQuery()

  const transactionCategoriesIncome = Object.values(
    transactionCategories,
  ).filter((category) => !category.is_outflow)

  const transactionCategoriesExpenses = Object.values(
    transactionCategories,
  ).filter((category) => category.is_outflow)

  const selectCategory = (category: TransactionCategory) => {
    onCategorySelect(category)
  }

  const currentType = isOutflow
    ? TRANSACTION_CATEGORY_TYPE.EXPENSES
    : TRANSACTION_CATEGORY_TYPE.INCOMES

  if (!transactionCategories) {
    return <LoadingAnimation />
  }

  return (
    <>
      <GenericTypeDrawerHeader title={t("selectTransactionCategory")} />
      <Tabs
        className={styles.tabsWrapper}
        onValueChange={(value) =>
          setIsOutflow(value === TRANSACTION_CATEGORY_TYPE.EXPENSES)
        }
        defaultValue={currentType}
      >
        <TabsList className={styles.tabsList}>
          <TabsTrigger
            className={styles.tabsTrigger}
            value={TRANSACTION_CATEGORY_TYPE.EXPENSES}
          >
            <p className={styles.info}>
              {t(TRANSACTION_CATEGORY_TYPE.EXPENSES)}
            </p>
          </TabsTrigger>
          <TabsTrigger
            className={styles.tabsTrigger}
            value={TRANSACTION_CATEGORY_TYPE.INCOMES}
          >
            <p className={styles.info}>
              {t(TRANSACTION_CATEGORY_TYPE.INCOMES)}
            </p>
          </TabsTrigger>
        </TabsList>
        <div className={styles.tabsContentWrapper}>
          <TabsContent value={TRANSACTION_CATEGORY_TYPE.INCOMES}>
            <div className={styles.tabsContent}>
              {transactionCategoriesIncome.map((category) => (
                <FinanceCategoryCard
                  key={category.id}
                  category={category}
                  isSelected={selectedCategory?.id === category.id}
                  onClick={() => selectCategory(category)}
                  onLongClick={() =>
                    category.farm &&
                    setIsAddCategoryViewOpen({
                      isOpen: true,
                      transactionCategoryId: category.id,
                    })
                  }
                />
              ))}
              <AddFinanceCategoryCard
                onClick={() =>
                  setIsAddCategoryViewOpen({
                    isOpen: true,
                    transactionCategoryId: null,
                  })
                }
              />
            </div>
          </TabsContent>
          <TabsContent value={TRANSACTION_CATEGORY_TYPE.EXPENSES}>
            <div className={styles.tabsContent}>
              {transactionCategoriesExpenses.map((category) => (
                <FinanceCategoryCard
                  key={category.id}
                  category={category}
                  isSelected={selectedCategory?.id === category.id}
                  onClick={() => selectCategory(category)}
                  onLongClick={() =>
                    category.farm &&
                    setIsAddCategoryViewOpen({
                      isOpen: true,
                      transactionCategoryId: category.id,
                    })
                  }
                />
              ))}
              <AddFinanceCategoryCard
                onClick={() =>
                  setIsAddCategoryViewOpen({
                    isOpen: true,
                    transactionCategoryId: null,
                  })
                }
              />
            </div>
          </TabsContent>
        </div>
      </Tabs>
    </>
  )
}

export default TransactionCategoryDrawerContent
