import * as React from "react"
import styles from "./index.module.css"

interface SelectButtonProps extends React.ComponentPropsWithoutRef<"button"> {
  children?: React.ReactNode
  style?: React.CSSProperties
}

const SelectPill = React.forwardRef<HTMLButtonElement, SelectButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <button className={styles.selectPill} ref={ref} {...props}>
        {children}
      </button>
    )
  },
)

SelectPill.displayName = "SelectPill"

export { SelectPill }
