import isEqual from "lodash.isequal"
import { useCallback, useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import {
  useDeleteTaskMutation,
  useUpdateTaskMutation,
} from "@/features/tasks/api/tasks-api.ts"
import TaskEditor from "@/features/tasks/components/task-editor/task-editor.tsx"
import useGetTaskDraft from "@/features/tasks/hooks/use-get-task-draft.ts"
import { validateTask } from "@/features/tasks/types/task-schema.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { deleteTaskDraft } from "@/redux/slices/task-draft-slice.ts"
import { TASKS_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

export const TaskView = () => {
  const { t } = useTranslation()
  const { taskId } = useParams<{ taskId: string }>()
  const { data, taskDraft, isLoading } = useGetTaskDraft(taskId)
  const { openModal } = useModal()
  const [updateTask] = useUpdateTaskMutation()
  const [deleteTaskMutation] = useDeleteTaskMutation()
  const navigate = useNavigate()
  const { handleBackNavigation } = useNavigationFallback()
  const dispatch = useDispatch()
  const hasChanges = !!taskDraft && !!data && !isEqual(data, taskDraft)

  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      dispatch(deleteTaskDraft(taskId as string))
    }, DELETE_ENTRY_TIMEOUT)
  }, [dispatch, taskId])

  const handleUpdateTask = async () => {
    if (!(await validateTask(taskDraft))) {
      return
    }
    if (data && taskId) {
      const diffDate = getObjectDifference(data, taskDraft)

      toast
        .promise(updateTask({ id: taskId, ...diffDate }).unwrap(), {
          pending: t("updatingTask"),
          success: t("taskUpdated"),
          error: t("errorUpdatingTask"),
        })
        .then(() => {
          navigate(TASKS_SCREEN_PATH, {
            replace: true,
          })
          deleteDraft()
        })
    }
  }

  useLayoutEffect(() => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("continueWhereYouLeftOff"),
        content: t("youHaveUnsavedChanges"),
        onBackText: t("discard"),
        onActionText: t("continue"),
        onBackClick: () => {
          deleteDraft()
        },
      })
    }
    // Run this only on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDraft, openModal, t])

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          handleBackNavigation()
          deleteDraft()
        },
      })
    } else {
      handleBackNavigation()
      deleteDraft()
    }
  }

  const deleteTask = () => {
    if (!taskId) return
    toast
      .promise(deleteTaskMutation(taskId).unwrap(), {
        pending: t("deletingTask"),
        success: t("taskDeleted"),
        error: t("errorDeletingTask"),
      })
      .then(() => {
        navigate(TASKS_SCREEN_PATH, {
          replace: true,
        })
        deleteDraft()
      })
  }

  const handleDeleteTask = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteTaskConfirmation"),
      content: t("taskWillBeDeleted"),
      onActionClick: deleteTask,
    })
  }

  return (
    <>
      <EditorTopBar
        text={"myTask"}
        onSave={handleUpdateTask}
        onBackClick={handleBackClick}
        isModified={hasChanges}
      />
      {isLoading || !taskId || !taskDraft ? (
        <LoadingAnimation />
      ) : (
        <PageContentWrapperLarge hasPadding={false}>
          <TaskEditor taskId={taskId} draftTask={taskDraft} />

          <DeleteButton
            text={t("deleteTask")}
            onClick={handleDeleteTask}
            style={{
              marginBlock: "50px",
            }}
          />
        </PageContentWrapperLarge>
      )}
    </>
  )
}
