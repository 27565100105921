import React from "react"

interface DrawerContentWrapperProps {
  children: React.ReactNode
}

const DrawerContentWrapper: React.FC<DrawerContentWrapperProps> = ({
  children,
}) => {
  return (
    <div
      style={{
        overflowX: "hidden",
        overflowY: "auto",
        height: "100%",
      }}
    >
      {children}
    </div>
  )
}

export default DrawerContentWrapper
