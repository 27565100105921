import { skipToken } from "@reduxjs/toolkit/query"
import { formatISO } from "date-fns"
import isEqual from "lodash.isequal"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateAnimalEventMutation } from "@/features/animal-events/api/animal-events-api.ts"
import { useGetAllAnimalEventTypesQuery } from "@/features/animal-events/api/event-types-api.ts"
import AnimalEventEditor from "@/features/animal-events/components/animal-event-editor/animal-event-editor.tsx"
import { useGetEventDataTypeObject } from "@/features/animal-events/hooks/use-get-event-data-type-object.ts"
import useInitializeEventDraft from "@/features/animal-events/hooks/use-initialize-event-draft.ts"
import { validateAnimalEvent } from "@/features/animal-events/types/animal-events-schema.tsx"
import {
  useGetGestationCalendarByIdQuery,
  useUpdateGestationCalendarMutation,
} from "@/features/gestational-calendar/api/gestation-calendar-api.ts"
import {
  GESTATION_CALENDAR_CONSTS,
  FP_QUERY_PARAMS,
} from "@/features/gestational-calendar/utils/gestation-calendar-consts.ts"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { initialAnimalEventState } from "@/redux/slices/events-draft-slice.ts"

const NewAnimalEventView = () => {
  const { t } = useTranslation()
  const { handleBackNavigation } = useNavigationFallback()
  const { eventDraft } = useInitializeEventDraft()

  const [createAnimalEvent] = useCreateAnimalEventMutation()
  const [updateGestationCalendar] = useUpdateGestationCalendarMutation()

  const [searchParams] = useSearchParams()
  const gestationCalendarId = searchParams.get(
    FP_QUERY_PARAMS.GESTATION_CALENDAR_ID,
  )
  const eventTypeId = searchParams.get(FP_QUERY_PARAMS.EVENT_TYPE_ID)

  const { data: allAnimalEvents } = useGetAllAnimalEventTypesQuery()
  const { data: gestationCalendar } = useGetGestationCalendarByIdQuery(
    gestationCalendarId ? gestationCalendarId : skipToken,
  )

  const paramEventType = eventTypeId && allAnimalEvents?.[eventTypeId]
  const hasChanges = !isEqual(eventDraft, initialAnimalEventState)
  const eventDataTypeObject = useGetEventDataTypeObject(eventDraft.type)

  const sideAction = () => {
    if (
      paramEventType &&
      paramEventType?.name === GESTATION_CALENDAR_CONSTS.Birth
    ) {
      gestationCalendar &&
        updateGestationCalendar({
          ...gestationCalendar,
          calving_date: formatISO(eventDraft.date),
        })
    }
  }

  const handleSave = async () => {
    if (await validateAnimalEvent(eventDraft, eventDataTypeObject)) {
      toast
        .promise(createAnimalEvent(eventDraft).unwrap(), {
          pending: t("savingEvent"),
          success: t("eventSaved"),
          error: t("errorSavingEvent"),
        })
        .then(() => {
          sideAction()
          handleBackNavigation()
        })
    }
  }

  return (
    <>
      <EditorTopBar
        text={"addEvent"}
        onSave={handleSave}
        isModified={hasChanges}
        onBackClick={handleBackNavigation}
      />
      <PageContentWrapperLarge hasPadding={false}>
        {!eventDraft ? (
          <LoadingAnimation />
        ) : (
          <AnimalEventEditor
            gestationCalendar={gestationCalendar}
            eventDraft={eventDraft}
          />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default NewAnimalEventView
