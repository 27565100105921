import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const ThreeDotsIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="5"
      viewBox="380.95 41 23 5"
    >
      <g data-name="Group 1268">
        <path
          d="M392.4 46a2.5 2.5 0 1 1 .1 0z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Ellipse 294"
        />
        <path
          d="M401.4 46a2.5 2.5 0 1 1 .1 0z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Ellipse 295"
        />
        <path
          d="M383.4 46a2.5 2.5 0 1 1 .1 0z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Ellipse 296"
        />
      </g>
    </svg>
  )
}

export default ThreeDotsIcon
