export const TRANSACTION_CATEGORY_TYPE = {
  INCOMES: "INCOMES",
  EXPENSES: "EXPENSES",
}

export const CURRENCY_ENUM = {
  RON: "RON",
  EUR: "EUR",
  USD: "USD",
}

export const TIME_PERIOD = {
  PERIOD_ALL_TIME: "all_time",
  PERIOD_LAST_MONTH: "last_month",
  PERIOD_LAST_WEEK: "last_week",
  PERIOD_LAST_YEAR: "last_year",
  PERIOD_CUSTOM: "custom",
}

export const timePeriodOptions = Object.entries(TIME_PERIOD).map(
  ([key, value]) => ({
    value: value,
    label: key,
  }),
)
