import React from "react"

const LactationCountIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="20 556 30 30"
    >
      <g data-name="Group 4352">
        <path
          d="M27 556h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H27a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="var(--grey)"
          fillRule="evenodd"
          data-name="Rectangle 1961"
        />
        <g data-name="noun-udder-5798817">
          <path
            d="M27.664 562.99a.617.617 0 0 0-.618.615 7.96 7.96 0 0 0 1.532 4.705l-.665 1.15a1.847 1.847 0 0 0 .678 2.525c.886.51 2.02.207 2.532-.677l.321-.557a7.985 7.985 0 0 0 1.78.65v.831c0 1.02.83 1.849 1.854 1.849a1.852 1.852 0 0 0 1.853-1.849v-.832a7.996 7.996 0 0 0 1.78-.65l.322.558a1.857 1.857 0 0 0 2.532.677 1.847 1.847 0 0 0 .678-2.526l-.665-1.149a7.96 7.96 0 0 0 1.531-4.705.617.617 0 0 0-.618-.616H27.664Zm.646 1.232h13.536a6.74 6.74 0 0 1-1.49 3.651.613.613 0 0 0-.055.696l.871 1.507c.17.294.07.67-.225.84a.618.618 0 0 1-.844-.224l-.627-1.084a.616.616 0 0 0-.858-.217 6.76 6.76 0 0 1-2.407.899.616.616 0 0 0-.515.608v1.334a.617.617 0 0 1-1.236 0v-1.334a.616.616 0 0 0-.515-.608 6.756 6.756 0 0 1-2.407-.899.616.616 0 0 0-.858.217l-.628 1.084a.618.618 0 0 1-1.069-.616l.871-1.507a.613.613 0 0 0-.055-.696 6.74 6.74 0 0 1-1.49-3.651Zm3.06 9.859a.62.62 0 0 0-.502.259s-.64.894-1.024 1.7c-.202.424-.326.833-.326 1.122 0 1.02.83 1.849 1.853 1.849a1.852 1.852 0 0 0 1.853-1.85c0-.288-.124-.697-.326-1.122-.385-.805-1.024-1.7-1.024-1.7a.62.62 0 0 0-.503-.258Zm7.414 0a.62.62 0 0 0-.502.259s-.64.894-1.024 1.7c-.203.424-.327.833-.327 1.122 0 1.02.83 1.849 1.853 1.849a1.852 1.852 0 0 0 1.854-1.85c0-.288-.124-.697-.327-1.122-.384-.805-1.024-1.7-1.024-1.7a.62.62 0 0 0-.503-.258Zm-7.413 1.741c.086.141.174.292.257.446.181.334.36.668.36.894a.617.617 0 0 1-1.235 0c0-.226.18-.56.36-.894.084-.154.172-.305.258-.446Zm7.413 0c.086.141.175.292.257.446.181.334.361.668.361.894a.617.617 0 0 1-1.235 0c0-.226.18-.56.36-.894.083-.154.172-.305.257-.446Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 1634"
          />
        </g>
      </g>
    </svg>
  )
}

export default LactationCountIcon
