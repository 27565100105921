import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import junica from "@/assets/images/junica.webp"
import matura from "@/assets/images/matura.webp"
import vitel from "@/assets/images/vitel.webp"
import useGetCowsAgeIntervals from "@/features/farm/hooks/use-get-cows-age-intervals.ts"
import AnimalGroupCard from "@/features/home/components/animal-group-card/animal-group-card.tsx"
import HorizontalScrollComponentWrapperFlexible from "@/features/home/components/horizontal-scroll-component-wrapper-flexible/horizontal-scroll-component-wrapper-flexible.tsx"
import {
  FARM_SCREEN_PATH,
  NEW_ANIMAL_SCREEN_PATH,
} from "@/utils/constants/routes"

const FarmSummaryWidget = () => {
  const { t } = useTranslation()
  const { calfs, heifers, cows, cowsNumber } = useGetCowsAgeIntervals()
  const navigate = useNavigate()
  const addItemGoTo = () => {
    navigate(NEW_ANIMAL_SCREEN_PATH)
  }

  return (
    <HorizontalScrollComponentWrapperFlexible
      isFlexible={true}
      title={t("myFarm")}
      navigateTo={FARM_SCREEN_PATH}
      addItemGoTo={addItemGoTo}
    >
      <AnimalGroupCard
        imgSrc={vitel}
        animals={calfs}
        animalsLength={cowsNumber}
        backgroundColor={"#edcdaf"}
        title={t("calf")}
      />
      <AnimalGroupCard
        imgSrc={junica}
        animals={heifers}
        animalsLength={cowsNumber}
        backgroundColor={"#b98662"}
        title={t("veal")}
      />
      <AnimalGroupCard
        imgSrc={matura}
        animals={cows}
        animalsLength={cowsNumber}
        backgroundColor={"#a7674c"}
        title={t("cow")}
      />
    </HorizontalScrollComponentWrapperFlexible>
  )
}

export default FarmSummaryWidget
