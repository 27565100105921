import { createSlice } from "@reduxjs/toolkit"
import { countAges, countGenders, countStatuses } from "@/features/farm"

interface AnimalsState {
  statuses: { [key: string]: number }
  genders: { [key: string]: number }
  ages: { [key: string]: number }
  species: { [key: string]: number }
}

const initialState: AnimalsState = {
  statuses: {},
  genders: {},
  ages: {},
  species: {},
}

export const AnimalsFilterSlice = createSlice({
  name: "animalsFilter",
  initialState: initialState,
  reducers: {
    setAnimalsFilter: (state, action) => {
      state.statuses = countStatuses(action.payload)
      state.genders = countGenders(action.payload)
      state.ages = countAges(action.payload)
    },
  },
})

export const { setAnimalsFilter } = AnimalsFilterSlice.actions

export default AnimalsFilterSlice.reducer
