import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"

import styles from "./task-card.module.css"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import PendingCircle from "@/components/pending-circle/pending-circle.tsx"

import { useGetAnimalsQuery } from "@/features/farm/api/animals-api.ts"
import { getTaskCardStyle } from "@/features/tasks"
import { useToggleTaskStatusMutation } from "@/features/tasks/api/tasks-api.ts"
import TaskCardPillAnimalInfo from "@/features/tasks/components/task-card-pills/task-card-pill-animal-info.tsx"
import TaskCardPillCategoryInfo from "@/features/tasks/components/task-card-pills/task-card-pill-category-info.tsx"
import { useGetTaskCategory } from "@/features/tasks/hooks/use-get-task-category.ts"
import { useGetTaskDateHour } from "@/features/tasks/hooks/use-get-task-date-hour.ts"
import { type Task } from "@/features/tasks/types/task.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setSelectedTasksIds } from "@/redux/slices/task-selection-slice.ts"
import { BROWN, DONE_GREEN } from "@/utils/constants/colors-constants.ts"
import { TASKS_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { cn } from "@/utils/styles"

interface TaskCardProps {
  task: Task
}

const TaskCard: React.FC<TaskCardProps> = ({ task }) => {
  const { t } = useTranslation()
  const { formattedDate, formattedHour } = useGetTaskDateHour(task)
  const { data = [] } = useGetAnimalsQuery()
  const [toggleTaskStatus] = useToggleTaskStatusMutation()
  const dispatch = useAppDispatch()
  const selectedTasks = useAppSelector(
    (state) => state.taskSelectionSlice.selectedTasksIds,
  )
  const isSelectionEnabled = useAppSelector(
    (state) => state.taskSelectionSlice.isSelectionEnabled,
  )
  const isSelected = (task: Task) => {
    return selectedTasks.includes(task.id)
  }
  const taskStyleClass = getTaskCardStyle(task, isSelected(task))

  const taskCategory = useGetTaskCategory({ task })
  const taskAnimals = data?.filter((animal) =>
    task.animals?.includes(animal.id),
  )

  const handleToggleTaskStatus = (
    task: Task,
    event: React.MouseEvent<HTMLDivElement>,
  ) => {
    if (isSelectionEnabled) {
      handleTaskSelection(event)
      event.stopPropagation()
      return
    }
    event.preventDefault()
    toast.promise(toggleTaskStatus(task).unwrap(), {
      pending: t("updatingTask"),
      success: t("taskUpdated"),
      error: t("errorUpdatingTask"),
    })
  }

  const handleTaskSelection = (
    event: React.MouseEvent<HTMLAnchorElement | HTMLDivElement>,
  ) => {
    if (!isSelectionEnabled) return
    event.preventDefault()
    if (isSelected(task)) {
      dispatch(
        setSelectedTasksIds(selectedTasks.filter((id) => id !== task.id)),
      )
    } else {
      dispatch(setSelectedTasksIds([...selectedTasks, task.id]))
    }
  }

  const TaskStatus = () => {
    return (
      <div
        className={styles.taskStatus}
        onClick={(event) => handleToggleTaskStatus(task, event)}
      >
        {!task.is_done ? (
          <PendingCircle className={taskStyleClass} />
        ) : (
          <CircleTickIcon fill={DONE_GREEN} size={24} />
        )}
      </div>
    )
  }

  const TaskStatusSelected = () => {
    return (
      <div className={styles.taskStatus}>
        <CircleTickIcon fill={BROWN} size={24} />
      </div>
    )
  }

  return (
    <Link to={`${TASKS_SCREEN_PATH}/${task.id}`} onClick={handleTaskSelection}>
      <div
        className={cn(
          taskStyleClass,
          "border-[rgba(112, 128, 144, 0.18)] mb-2 flex h-[95px] gap-2 rounded-2xl border shadow-md focus:outline-none",
          "hover:shadow-md",
          "focus:shadow-md",
          "active:shadow-md",
        )}
      >
        {isSelected(task) ? <TaskStatusSelected /> : <TaskStatus />}
        <div className="w-full">
          <div className={styles.taskTitle}>
            <p>{task.title}</p>
            {Object.hasOwn(task, "hasChanges") && (
              <i className={styles.changesWarning}>
                {t("unsavedChangesIndicator")}
              </i>
            )}
          </div>
          <div className="flex items-center justify-between py-2 pr-2">
            <div className={styles.dateTime}>
              {formattedDate && formattedHour && (
                <>
                  <p>{formattedDate}</p>
                  <p className={styles.cardHour}>{formattedHour}</p>
                </>
              )}
            </div>
            <div className="flex gap-2">
              {taskCategory && (
                <TaskCardPillCategoryInfo taskCategory={taskCategory} />
              )}
              {taskAnimals && taskAnimals.length > 0 && (
                <TaskCardPillAnimalInfo animals={taskAnimals} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default TaskCard
