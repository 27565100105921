import React from "react"
import { BROWN, GREY } from "@/utils/constants/colors-constants.ts"

interface FemaleIconProps {
  is_active?: boolean
}

const FemaleIcon: React.FC<FemaleIconProps> = ({ is_active }) => {
  const fillColor = is_active ? BROWN : GREY

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.359"
      height="22.634"
      viewBox="238.822 441.683 15.359 22.634"
    >
      <g data-name="Icon ion-female-outline">
        <path
          d="M254.18 449.362a7.68 7.68 0 1 1-15.358 0 7.68 7.68 0 0 1 15.359 0Z"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke={fillColor}
          fill="transparent"
          data-name="Path 1283"
        />
        <path
          d="M246.502 457.042v7.275m2.93-3.234h-5.86"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke={fillColor}
          fill="transparent"
          data-name="Path 1284"
        />
      </g>
    </svg>
  )
}

export default FemaleIcon
