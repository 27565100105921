import React, { type ReactElement } from "react"
import { Link } from "react-router-dom"
import styles from "./menu-item.module.css"

interface MenuItemProps {
  isActive: boolean
  navigateTo: string
  label: string
  alt: string
  children: ReactElement<{ alt?: string }>
  isOpen?: boolean
}

export default function MenuItem(props: MenuItemProps) {
  return (
    <Link
      className={
        props.isActive ? styles["menu-item-active"] : styles["menu-item"]
      }
      to={props.navigateTo}
    >
      <div className={styles["item-flex"]}>
        <div className={styles["menu-icon"]}>
          {React.cloneElement(props.children, { alt: props.alt })}
        </div>
        {props.isOpen && (
          <div
            className={
              props.isActive
                ? styles["menu-button-active"]
                : styles["menu-button"]
            }
          >
            {props.label}
          </div>
        )}
      </div>
      {props.isActive && !props.isOpen && (
        <div className={styles["active-bar"]} />
      )}
    </Link>
  )
}
