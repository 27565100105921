import React from "react"
import { BROWN, GREY } from "@/utils/constants/colors-constants.ts"

interface MeatIconProps {
  is_active?: boolean
}

const MeatIcon: React.FC<MeatIconProps> = ({ is_active = false }) => {
  const strokeColor = is_active ? BROWN : GREY

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.46"
      height="17.378"
      viewBox="5849.269 -1165.689 21.46 17.378"
    >
      <g data-name="CompositeLayer">
        <g data-name="Path 1290">
          <path
            d="M5870.728-1156.43c0-2.486-1.138-4.819-3.205-6.57-2.048-1.734-4.767-2.69-7.656-2.69-3.086 0-5.675.59-7.486 1.706-.998.614-1.77 1.39-2.296 2.305-.47.818-.742 1.75-.816 2.78-.003.09.052 3.376.052 3.376 0 1.427.323 2.262 1.173 2.755.748.434 1.764.502 2.94.58 1.638.108 3.676.242 5.806 1.42 2.427 1.343 4.547 2.144 6.303 2.382.369.05.72.075 1.053.075 1.405 0 2.456-.472 3.226-1.327.99-1.1.906-3.8.906-6.792Zm-17.925-6.872c1.684-1.037 4.127-1.585 7.064-1.585 5.546 0 10.057 3.793 10.057 8.457 0 1.43-.35 2.596-1.013 3.37-.669.782-1.641 1.178-2.891 1.178-1.625 0-3.683-.694-5.952-2.006-2.15-1.243-4.346-1.462-6.11-1.637-2.709-.27-3.906-.486-3.906-2.803 0-2.176.926-3.85 2.751-4.974Zm16.402 13.15c-.724.866-1.92 1.192-3.554.971-1.657-.224-3.682-.994-6.021-2.288-2.287-1.265-4.425-1.406-6.143-1.52-2.287-.15-3.435-.227-3.435-2.594v-.297c.846.856 2.27.998 3.825 1.153 1.776.176 3.789.377 5.788 1.533 2.425 1.403 4.563 2.114 6.354 2.114 1.475 0 2.687-.504 3.503-1.459.138-.16.262-.333.377-.515-.048 1.71-.2 2.313-.694 2.902Z"
            fill={is_active ? BROWN : GREY}
            fillRule="evenodd"
          />
          <path
            d="M5870.728-1156.43c0-2.486-1.138-4.819-3.205-6.57-2.048-1.734-4.767-2.69-7.656-2.69-3.086 0-5.675.59-7.486 1.706-.998.614-1.77 1.39-2.296 2.305-.47.818-.742 1.75-.816 2.78-.003.09.052 3.376.052 3.376 0 1.427.323 2.262 1.173 2.755.748.434 1.764.502 2.94.58 1.638.108 3.676.242 5.806 1.42 2.427 1.343 4.547 2.144 6.303 2.382.369.05.72.075 1.053.075 1.405 0 2.456-.472 3.226-1.327.99-1.1.906-3.8.906-6.792Zm-17.925-6.872c1.684-1.037 4.127-1.585 7.064-1.585 5.546 0 10.057 3.793 10.057 8.457 0 1.43-.35 2.596-1.013 3.37-.669.782-1.641 1.178-2.891 1.178h0c-1.625 0-3.683-.694-5.952-2.006-2.15-1.243-4.346-1.462-6.11-1.637-2.709-.27-3.906-.486-3.906-2.803 0-2.176.926-3.85 2.751-4.974Zm16.402 13.15c-.724.866-1.92 1.192-3.554.971-1.657-.224-3.682-.994-6.021-2.288-2.287-1.265-4.425-1.406-6.143-1.52-2.287-.15-3.435-.227-3.435-2.594v-.297c.846.856 2.27.998 3.825 1.153 1.776.176 3.789.377 5.788 1.533 2.425 1.403 4.563 2.114 6.354 2.114h0c1.475 0 2.687-.504 3.503-1.459.138-.16.262-.333.377-.515-.048 1.71-.2 2.313-.694 2.902Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth=".4"
            stroke={strokeColor}
            fill="transparent"
          />
        </g>
        <g data-name="Path 1291">
          <path
            d="M5855.955-1158.744c1.157 0 2.099-.763 2.099-1.7 0-.938-.942-1.7-2.099-1.7-1.157 0-2.098.762-2.098 1.7 0 .937.941 1.7 2.098 1.7Zm0-2.727c.786 0 1.426.46 1.426 1.026 0 .567-.64 1.027-1.426 1.027-.772 0-1.425-.47-1.425-1.027 0-.556.652-1.026 1.425-1.026Z"
            fill={strokeColor}
            fillRule="evenodd"
          />
          <path
            d="M5855.955-1158.744c1.157 0 2.099-.763 2.099-1.7 0-.938-.942-1.7-2.099-1.7-1.157 0-2.098.762-2.098 1.7 0 .937.941 1.7 2.098 1.7Zm0-2.727c.786 0 1.426.46 1.426 1.026 0 .567-.64 1.027-1.426 1.027-.772 0-1.425-.47-1.425-1.027 0-.556.652-1.026 1.425-1.026Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth=".4"
            stroke={strokeColor}
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default MeatIcon
