import React from "react"

const RecommendIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      {...props}
    >
      <g id="页面-1" transform="translate(0 24) rotate(-90)">
        <g id="System" transform="translate(-672 -96)">
          <g id="entrance_line" transform="translate(672 96)">
            <path
              id="MingCute"
              d="M24,0V24H0V0ZM12.594,23.258l-.012,0-.071.035-.019,0h0l-.015,0-.071-.035a.021.021,0,0,0-.024.005l0,.011-.017.427.005.02.011.012.1.074.015,0h0l.012,0,.1-.074.013-.016h0l0-.017-.017-.427a.021.021,0,0,0-.016-.018Zm.265-.112-.014,0-.185.092-.01.01h0l0,.011.018.429,0,.013h0l.009.007.2.093a.025.025,0,0,0,.028-.008l0-.014-.034-.615a.025.025,0,0,0-.019-.021Zm-.715,0a.023.023,0,0,0-.027.005l-.006.014-.034.615a.023.023,0,0,0,.017.023h.016l.2-.093.009-.008h0l0-.012.018-.429,0-.013h0l-.009-.009Z"
              fill="none"
            />
            <path
              id="形状"
              d="M12,3a1,1,0,0,1,.117,1.993L12,5H7a1,1,0,0,0-.993.883L6,6V18a1,1,0,0,0,.883.993L7,19h4.5a1,1,0,0,1,.117,1.993L11.5,21H7a3,3,0,0,1-2.995-2.824L4,18V6A3,3,0,0,1,6.824,3.005L7,3Zm5.707,5.464,2.828,2.828a1,1,0,0,1,0,1.414l-2.828,2.828a1,1,0,0,1-1.414-1.414L17.414,13H12a1,1,0,0,1,0-2h5.414L16.293,9.879a1,1,0,0,1,1.414-1.414Z"
              fill="#414141"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default RecommendIcon
