import React from "react"
import { Outlet } from "react-router-dom"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import useFarmPermissions from "@/features/settings-account/hooks/use-farm-permissions.tsx"

const FarmPermissions: React.FC = () => {
  const { isLoading } = useFarmPermissions()

  return isLoading ? <LoadingAnimation /> : <Outlet />
}

export default FarmPermissions
