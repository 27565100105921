import React from "react"

interface AddCircleProps {
  size?: string | number
}

const RemoveCircleIcon: React.FC<AddCircleProps> = ({ size = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 1066">
        <path
          d="M25.612 25.612c5.85-5.85 5.85-15.373 0-21.224-5.85-5.85-15.373-5.85-21.224 0-5.85 5.85-5.85 15.373 0 21.224 5.85 5.85 15.373 5.85 21.224 0ZM5.447 5.447c5.269-5.27 13.837-5.27 19.106 0 5.27 5.269 5.27 13.837 0 19.106-5.269 5.27-13.837 5.27-19.106 0-5.27-5.269-5.263-13.837 0-19.106Z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Path 801"
        />
        <path
          d="M9 14.5h12a1 1 0 1 1 0 2H9a1 1 0 1 1 0-2z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Path 802"
        />
      </g>
    </svg>
  )
}

export default RemoveCircleIcon
