import React from "react"
import { DARK_GREY } from "@/utils/constants/colors-constants"

interface BackArrowProps extends React.SVGProps<SVGSVGElement> {
  color?: string
}

const BackIcon: React.FC<BackArrowProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="17"
      viewBox="2.952 -3.553 13.01 19.515"
      {...props}
    >
      <g data-name="arrow_down-[#338]">
        <path
          d="M15.832-2.967c-.48-.38-1.256-.38-1.734 0L3.971 5.096c-.958.761-.958 1.997 0 2.758l10.201 8.123c.473.376 1.24.381 1.72.01.49-.38.494-1.005.012-1.39L6.57 7.165c-.48-.381-.48-.999 0-1.38l9.262-7.374c.477-.38.477-.997 0-1.378"
          fill={props.color || DARK_GREY}
          fillRule="evenodd"
        />
        <path
          d="M15.832-2.967h0c-.48-.38-1.256-.38-1.734 0L3.971 5.096c-.958.761-.958 1.997 0 2.758l10.201 8.123c.473.376 1.24.381 1.72.01.49-.38.494-1.005.012-1.39L6.57 7.165c-.48-.381-.48-.999 0-1.38l9.262-7.374c.477-.38.477-.997 0-1.378"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth=".6"
          stroke="#fff"
          fill="transparent"
        />
      </g>
    </svg>
  )
}

export default BackIcon
