import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./animal-card.module.css"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import {
  type Animal,
  getCurrentAnimalImage,
  getGenderIcon,
} from "@/features/farm"
import { getAgeFromDateOfBirth } from "@/utils/helpers/time-utils.ts"

interface AnimalCardProps {
  animal: Animal
}

const AnimalCard: React.FC<AnimalCardProps> = ({ animal }) => {
  const { t } = useTranslation()

  return (
    <div className={styles["card-content"]}>
      <div className={styles["animal-card-flex"]}>
        <GenericPicture
          src={getCurrentAnimalImage(animal)}
          alt={animal.name || animal.ain}
          size={80}
          borderRadius={15}
          style={{ aspectRatio: "1/1" }}
        />
        <div className={styles["card-text-wrapper"]}>
          <div className={styles["header"]}>
            <div className={styles["card-title"]}>{animal.name}</div>
            <div className={styles["gender-icon"]}>
              {getGenderIcon(animal.gender)}
            </div>
          </div>
          <div className={styles.bottom}>
            <div className={styles.left}>
              <div
                className={styles["muted-text"]}
              >{`${t("crotal")}: ${animal.ain}`}</div>

              <div className={styles["muted-text"]}>
                {getAgeFromDateOfBirth(animal.birth_date, "string")}
              </div>

              <div className={styles["muted-text"]}>
                Status: {animal.statuses?.map((status) => t(status)).join(", ")}
              </div>
            </div>
            {Object.hasOwn(animal, "hasChanges") && (
              <i className={styles.changesWarning}>
                {t("unsavedChangesIndicator")}
              </i>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AnimalCard
