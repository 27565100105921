export enum GESTATION_CALENDAR_CONSTS {
  Birth = "Birth",
  Fatarea = "Fǎtarea",
}

export enum FP_QUERY_PARAMS {
  ANIMAL_ID = "animalId",
  GESTATION_CALENDAR_ID = "gestationCalendarId",
  GESTATION_CALENDAR_ENTRY_ID = "gestationCalendarEntryId",
  EVENT_TYPE_ID = "eventTypeId",
}
