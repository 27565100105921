import { Route } from "react-router-dom"
import { NoteEditorView } from "@/features/notes"
import { NewNoteView } from "@/features/notes/routes/new-note-view/new-note-view.tsx"
import NotesLayout from "@/features/notes/routes/notes-layout/notes-layout.tsx"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import { StandardLayout } from "@/layouts/standard-layout/standard-layout.tsx"
import {
  NEW_NOTE_SCREEN_PATH,
  NOTE_SCREEN_PATH,
  NOTES_VIEW_PATH,
} from "@/utils/constants/routes.ts"

export const NotesRoutes = (
  <>
    <Route element={<StandardLayout />}>
      <Route
        key={NOTES_VIEW_PATH}
        path={NOTES_VIEW_PATH}
        element={<NotesLayout />}
      />
    </Route>
    <Route element={<NoMenuLayout />}>
      <Route
        key={`${NEW_NOTE_SCREEN_PATH}/:animalId`}
        path={`${NEW_NOTE_SCREEN_PATH}/:animalId`}
        element={<NewNoteView />}
      />
      <Route
        key={NEW_NOTE_SCREEN_PATH}
        path={NEW_NOTE_SCREEN_PATH}
        element={<NewNoteView />}
      />
      <Route
        key={NOTE_SCREEN_PATH}
        path={`${NOTE_SCREEN_PATH}/:noteId`}
        element={<NoteEditorView />}
      />
    </Route>
  </>
)
