import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./base-modal.module.css"
import useModal from "@/features/modals/hooks/use-modal.ts"

interface IBaseModalProps {
  title: string
  content?: string
  onBackText?: string
  onBackClick?: () => void
  onActionText?: string
  onActionClick: () => void
}

const BaseModal: React.FC<IBaseModalProps> = ({
  onActionClick,
  title,
  content,
  onActionText,
  onBackText,
  onBackClick,
}) => {
  const { t } = useTranslation()
  const { closeModal } = useModal()

  const handleActionClick = () => {
    closeModal()
    onActionClick()
  }

  const getActionText = () => {
    return onActionText || t("confirm")
  }

  const getBackText = () => {
    return onBackText || t("back")
  }

  const handleBackClick = () => {
    if (onBackClick) {
      const result = onBackClick()
      closeModal()
      return result
    } else {
      return closeModal()
    }
  }

  const handleOutsideClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    closeModal()
  }

  return (
    <div className={styles.backOverlay} onClick={handleOutsideClick}>
      <div
        className={styles.confirmationCard}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.backConfirmation}>{title}</div>
        <div className={styles.center}>{content}</div>
        <div className={styles.buttons}>
          <div className={styles.cancel} onClick={handleBackClick}>
            {getBackText()}
          </div>
          <div className={styles.confirm} onClick={handleActionClick}>
            {getActionText()}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BaseModal
