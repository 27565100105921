import React from "react"
import AssociatedNoteCard from "@/features/notes/components/associated-notes-list/associated-note-card.tsx"
import AssociatedList from "@/features/tasks/components/associated-list/associated-list.tsx"

interface AssociatedNotesListProps {
  notes: string[]
  onNotesChange: (noteIds: string[]) => void
}

const AssociatedNotesList: React.FC<AssociatedNotesListProps> = ({
  notes,
  onNotesChange,
}) => {
  return (
    <AssociatedList
      items={notes}
      onItemsChange={onNotesChange}
      renderItem={(note, onDelete) => (
        <AssociatedNoteCard note={note} onDelete={onDelete} key={note} />
      )}
      seeMoreText="seeOtherNotesAssociated"
      hideMoreText="hideOtherNotesAssociated"
    />
  )
}

export default AssociatedNotesList
