import React from "react"
import { useTranslation } from "react-i18next"
import GenericItemsSubGroup from "@/components/grouping/generic-items-sub-group.tsx"
import { type ItemsTimeGroups } from "@/utils/helpers/time-grouping.ts"

interface GenericItemsGroupByTimeSpanProps {
  items: ItemsTimeGroups
}

const GenericItemsGroupByTimeSpan: React.FC<
  GenericItemsGroupByTimeSpanProps
> = ({ items }) => {
  const { t } = useTranslation()

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {items.todayItems.length > 0 && (
        <GenericItemsSubGroup items={items.todayItems} title={t("today")} />
      )}

      {items.thisWeekItems.length > 0 && (
        <GenericItemsSubGroup
          items={items.thisWeekItems}
          title={t("thisWeek")}
        />
      )}

      {items.thisMonthItems.length > 0 && (
        <GenericItemsSubGroup
          items={items.thisMonthItems}
          title={t("thisMonth")}
        />
      )}

      {items.pastMonthItems.length > 0 && (
        <GenericItemsSubGroup
          items={items.pastMonthItems}
          title={t("lastMonth")}
        />
      )}

      {items.thisYearItems.length > 0 && (
        <GenericItemsSubGroup
          items={items.thisYearItems}
          title={t("thisYear")}
        />
      )}

      {items.pastYearItems.length > 0 && (
        <GenericItemsSubGroup
          items={items.pastYearItems}
          title={t("lastYear")}
        />
      )}

      {items.pastYearsItems.length > 0 && (
        <GenericItemsSubGroup
          items={items.pastYearsItems}
          title={t("pastYears")}
        />
      )}
    </div>
  )
}

export default GenericItemsGroupByTimeSpan
