import { Route } from "react-router-dom"
import { AnimalProfile, AnimalView } from "@/features/farm"
import FarmLayout from "@/features/farm/routes/farm-layout/farm-layout.tsx"
import { NewAnimalView } from "@/features/farm/routes/new-animal-view/new-animal-view.tsx"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import { StandardLayout } from "@/layouts/standard-layout/standard-layout.tsx"
import {
  ANIMAL_PROFILE_PATH,
  ANIMAL_SCREEN_PATH,
  FARM_SCREEN_PATH,
  NEW_ANIMAL_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

export const FarmRoutes = (
  <>
    <Route element={<StandardLayout />}>
      <Route
        key={FARM_SCREEN_PATH}
        path={FARM_SCREEN_PATH}
        element={<FarmLayout />}
      />
    </Route>
    <Route element={<NoMenuLayout />}>
      <Route
        key={NEW_ANIMAL_SCREEN_PATH}
        path={NEW_ANIMAL_SCREEN_PATH}
        element={<NewAnimalView />}
      />
      <Route
        key={ANIMAL_SCREEN_PATH + "1"}
        path={`${ANIMAL_SCREEN_PATH}/:animalId`}
        element={<AnimalView />}
      />
      <Route
        key={ANIMAL_PROFILE_PATH}
        path={`${ANIMAL_PROFILE_PATH}/:animalId`}
        element={<AnimalProfile />}
      />
    </Route>
  </>
)
