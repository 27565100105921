import TasksLayoutMobile from "@/features/tasks/routes/task-layout/task-layout-mobile/task-layout-mobile.tsx"
import TasksLayoutDesktop from "@/features/tasks/routes/task-layout/tasks-layout-desktop/tasks-layout-desktop.tsx"
import useIsDesktop from "@/hooks/use-is-desktop.ts"

const TaskLayout = () => {
  const isDesktop = useIsDesktop()

  return isDesktop ? <TasksLayoutDesktop /> : <TasksLayoutMobile />
}

export default TaskLayout
