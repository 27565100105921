import React from "react"
import styles from "./notes-by-time-span.module.css"
import DateSeparator from "@/features/notes/components/date-separator/date-separator.tsx"
import NoteCard from "@/features/notes/components/note-card/note-card.tsx"
import { type Note } from "@/features/notes/types/note.ts"

interface NotesByTimeSpanProps {
  notes: Note[]
  subTitle?: string
  enableSeparator?: boolean
  title?: string
}

const NotesByTimeSpan: React.FC<NotesByTimeSpanProps> = ({
  notes,
  subTitle,
  enableSeparator = false,
  title,
}) => {
  return (
    <div>
      <div className={styles["sub-title-updated"]}>{title || ""}</div>
      <div className={title ? styles["card"] : ""}>
        {enableSeparator && <DateSeparator monthYear={subTitle || ""} />}
        {notes.map((note, index) => (
          <NoteCard
            key={note.id}
            note={note}
            hasBorder={index !== notes.length - 1}
          />
        ))}
      </div>
    </div>
  )
}

export default NotesByTimeSpan
