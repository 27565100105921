import { createSelector } from "@reduxjs/toolkit"
import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Species } from "@/features/farm"

export const SPECIES_BASE_URL = "species/"

export const speciesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSpecies: builder.query<Record<string, Species>, void>({
      query: () => SPECIES_BASE_URL,
      transformResponse: (response: Species[]) => {
        return response.reduce<Record<string, Species>>((acc, species) => {
          return { ...acc, [species.id]: species }
        }, {})
      },
      providesTags: (result) => {
        return result
          ? [
              { type: TagType.Species, id: "LIST" },
              ...Object.keys(result).map((id) => ({
                type: TagType.Species,
                id,
              })),
            ]
          : [{ type: TagType.Species, id: "LIST" }]
      },
    }),
    getSpeciesById: builder.query<Species, string>({
      query: (id) => SPECIES_BASE_URL + `${id}/`,
      providesTags: () => [{ type: TagType.Species }],
    }),
  }),
})

export const selectSpeciesResult = speciesApi.endpoints.getSpecies.select()

export const selectSpecies = createSelector(selectSpeciesResult, (result) => {
  return result?.data ?? []
})

export const { useGetSpeciesQuery, useGetSpeciesByIdQuery } = speciesApi
