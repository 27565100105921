import { useTranslation } from "react-i18next"
import styles from "@/features/settings-account/routes/settings-routes.module.css"
import { getAppVersionDateFormat } from "@/utils/helpers/time-utils"

const buildDate = getAppVersionDateFormat(import.meta.env.VITE_BUILD_DATE)

const AppVersionCard = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.header}>
        <div className={styles.cardItemName}>{t("appVersion")}</div>
        <div className={styles.cardItemVersion}>{buildDate}</div>
      </div>
    </>
  )
}

export default AppVersionCard
