import React from "react"
import { GREY } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const CowIconSmall: React.FC<GeneralSvgProps> = ({ ...props }) => {
  const fillColor = props.fill ? props.fill : GREY

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="44.944"
      height="29.484"
      viewBox="15.411 15.945 44.944 29.484"
    >
      <g data-name="Group 1308">
        <path
          d="M58.822 22.286c.714 2.721.219 5.485 0 8.223-.168 2.142-.042 4.292-.344 6.434-.168 1.2.302 2.335 1.092 3.637.134-2.033.613-3.73.226-5.544-.613-2.839-.126-5.67.27-8.5.26-1.84.42-3.696.15-5.56-.336-2.31-2.049-3.965-4.384-4.158-1.327-.11-2.629.118-3.948.227-4.737.403-9.457.714-14.228.126-3.62-.445-7.265.008-10.869.52-.36.051-.722.093-1.083.143-.118-.554.756-.915.227-1.377-.437-.386-1.059-.218-1.537.008-.815.387-1.512.454-2.26-.168-.663-.554-1.444-.394-2.066.067-.983.74-1.957.639-3.024.244-.47-.176-1.1-.596-1.47-.034-.378.563-.033 1.21.37 1.706.202.252.52.403.74.638.352.37 1.209-.135 1.225.512.034 1.428 1.126 2.764.152 4.309-1.32 2.1-.992 2.637 1.503 2.965.554.076 1.05-.176 1.529-.412.63-.31 1.024-.134 1.444.437 1.042 1.411 1.504 3.016 1.84 4.679.378 1.856 1.083 3.401 3.192 3.78.335.058.554.218.537.554-.126 2.091.95 4.124.302 6.257-.176.588-.235 1.218-.638 1.722-.495.63-.176.831.454.924 1.344.193 2.015-.378 1.923-1.739-.176-2.545.302-5.014.79-7.492.025-.143.15-.269.226-.403.084.067.227.117.244.201.68 2.42 2.427 4.561 1.865 7.3-.143.68-.202 1.352-.773 1.898-.571.546-.37.915.437.99 1.562.152 1.982-.335 1.973-1.94-.008-3.006.06-6.013.429-9.004.1-.831.52-1.083 1.277-.797 1.15.445 2.3.915 3.443 1.377 1.31.53 2.638.571 3.998.185.437-.126.865-.781 1.319-.303.613.639 1.554 1.008 1.722 2.033.05.294-.101.764.378.781.411.017.252-.445.36-.68.119-.235.278-.437.572-.361.286.075.336.327.378.58.059.31.017.797.403.806.454.016.26-.504.387-.782.436-.957 1.495-.94 2.276-1.209.462-.16.537.79.596 1.285.302 2.427-.462 4.628-1.529 6.753-.16.31-.596.63-.319.974.378.47 1 .311 1.52.278.337-.017.63-.034.773-.555.454-1.62 1.042-3.2 1.588-4.796a1.75 1.75 0 0 1 .353-.63c-.026 1.697.243 3.444-.857 4.947-.286.387-.21.513.26.63 1.554.404 2.461-.37 2.41-1.957-.066-2.351-.108-4.712.48-7.021.134-.53.008-.975-.227-1.42-.53-1-.53-2.016-.252-3.116.764-3.049 2.058-5.955 2.175-9.172Z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="Path 914"
        />
      </g>
    </svg>
  )
}

export default CowIconSmall
