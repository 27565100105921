import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "../../../notes/components/notes-group/notes-group.module.css"
import { type Task } from "@/features/tasks"
import TasksByTimeSpan from "@/features/tasks/components/tasks-by-time-span/tasks-by-time-span.tsx"
import {
  groupItemsByTimeStampWithField,
  initialItemsTimeGroups,
  type ItemsTimeGroups,
} from "@/utils/helpers/time-grouping.ts"

interface TaskTimeGroupProp {
  tasks: Task[]
}

const TaskTimeGroup: React.FC<TaskTimeGroupProp> = ({ tasks }) => {
  const { t } = useTranslation()
  const [sortedTasks, setSortedTasks] = useState<ItemsTimeGroups>(
    initialItemsTimeGroups,
  )

  useEffect(() => {
    if (tasks.length > 0) {
      setSortedTasks(groupItemsByTimeStampWithField(tasks, "due_date"))
    }
  }, [tasks])

  return (
    <div className={styles["notes-group-wrapper"]}>
      {sortedTasks.todayItems.length > 0 && (
        <TasksByTimeSpan
          tasks={sortedTasks.todayItems as Task[]}
          title={t("today")}
        />
      )}

      {sortedTasks.thisWeekItems.length > 0 && (
        <TasksByTimeSpan
          tasks={sortedTasks.thisWeekItems as Task[]}
          title={t("thisWeek")}
        />
      )}

      {sortedTasks.thisMonthItems.length > 0 && (
        <TasksByTimeSpan
          tasks={sortedTasks.thisMonthItems as Task[]}
          title={t("thisMonth")}
        />
      )}

      {sortedTasks.pastMonthItems.length > 0 && (
        <TasksByTimeSpan
          tasks={sortedTasks.pastMonthItems as Task[]}
          title={t("lastMonth")}
        />
      )}

      {sortedTasks.thisYearItems.length > 0 && (
        <TasksByTimeSpan
          tasks={sortedTasks.thisYearItems as Task[]}
          title={t("thisYear")}
        />
      )}

      {sortedTasks.pastYearItems.length > 0 && (
        <TasksByTimeSpan
          tasks={sortedTasks.pastYearItems as Task[]}
          title={t("lastYear")}
        />
      )}

      {sortedTasks.pastYearsItems.length > 0 && (
        <TasksByTimeSpan
          tasks={sortedTasks.pastYearsItems as Task[]}
          title={t("pastYears")}
        />
      )}

      {tasks.length === 0 && (
        <div className={styles["total-tasks"]}>{t("noNewTasks")}</div>
      )}
    </div>
  )
}

export default TaskTimeGroup
