import { format } from "date-fns"
import React from "react"
import styles from "./chat-bubble.module.css"
import ChatBubble from "@/features/assistant/components/chat-bubble/chat-bubble.tsx"
import { type AssistantMessage } from "@/features/assistant/types/assistant.ts"

interface ChatBubbleWrapperProps {
  message: AssistantMessage
}

const ChatBubbleWrapper: React.FC<ChatBubbleWrapperProps> = ({ message }) => {
  const gpt_message = message.gpt_response
  const user_message = message.user_message

  const timestamp = format(new Date(message.created_at), "HH:mm")

  return (
    <div className={styles.chatBubbleTupleWrapper}>
      <ChatBubble timestamp={timestamp} isUser={true} message={user_message} />
      <ChatBubble timestamp={timestamp} isUser={false} message={gpt_message} />
    </div>
  )
}

export default ChatBubbleWrapper
