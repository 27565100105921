import React from "react"
import Switch from "@/components/shadcn/switch/switch.tsx"
import styles from "@/features/tasks/components/reminder-options/reminder-options.module.css"

interface BooleanSwitchRowProps {
  label: string
  isChecked: boolean
  setChecked: (checked: boolean) => void
}

/**
 * @param label description of the switch, what it is for
 * @param isChecked the current checked state
 * @param setChecked function to set the checked state
 */
export const BooleanSwitchRow: React.FC<BooleanSwitchRowProps> = ({
  label,
  isChecked,
  setChecked,
}) => {
  const handleToggle = (value: boolean) => {
    setChecked(value)
  }

  return (
    <div className={styles["reminder-rows"]}>
      <div className={styles["label"]}>{label}</div>
      <Switch checked={isChecked} onCheckedChange={handleToggle} />
    </div>
  )
}
