import React, {
  createContext,
  useContext,
  useState,
  type ReactNode,
} from "react"

interface NotesContextProps {
  isContextEnabled: boolean | null
  setIsContextEnabled: (value: boolean) => void
  selectedNotes: string[] | null
  setSelectedNotes: (notes: string[]) => void
}

const defaultNotesContext: NotesContextProps = {
  isContextEnabled: null,
  setIsContextEnabled: () => {},
  selectedNotes: [],
  setSelectedNotes: () => {},
}

const NotesContext = createContext<NotesContextProps | undefined>(undefined)

export const NotesProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [selectedNotes, setSelectedNotes] = useState<string[] | null>(null)
  const [isContextEnabled, setIsContextEnabled] = useState<boolean>(false)

  return (
    <NotesContext.Provider
      value={{
        selectedNotes,
        setSelectedNotes,
        isContextEnabled,
        setIsContextEnabled,
      }}
    >
      {children}
    </NotesContext.Provider>
  )
}

export const useNotesContext = (): NotesContextProps => {
  const context = useContext(NotesContext)
  return context ?? defaultNotesContext
}
