import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import EventsWidget from "@/features/animal-events/components/events-widget/events-widget.tsx"
import {
  type Animal,
  ANIMAL_GENDERS,
  getCurrentAnimalImage,
  isRuminant,
} from "@/features/farm"
import { AnimalDetails } from "@/features/farm/components/animal-details/animal-details.tsx"
import AnimalProfileStatusWidget from "@/features/farm/components/animal-profile-status-widget/animal-profile-status-widget"
import { RuminantDetails } from "@/features/farm/components/ruminant-details/ruminant-details.tsx"
import GestationCalendarWidget from "@/features/gestational-calendar/components/gestation-calendar-widget/gestation-calendar-widget.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import NotesWidget from "@/features/notes/components/notes-widget/notes-widget.tsx"

interface AnimalProfileEditorProps {
  selectedAnimal: Animal
}

const AnimalProfileEditor: React.FC<AnimalProfileEditorProps> = ({
  selectedAnimal,
}) => {
  return (
    <>
      <GenericPicture
        src={getCurrentAnimalImage(selectedAnimal)}
        alt={"animal"}
        borderRadius={0}
        style={{
          width: "100%",
          height: "250px",
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
      />
      {selectedAnimal && isRuminant(selectedAnimal) && (
        <AnimalProfileStatusWidget animal={selectedAnimal} />
      )}
      {selectedAnimal &&
        isRuminant(selectedAnimal) &&
        selectedAnimal.gender === ANIMAL_GENDERS.FEMALE.label && (
          <RuminantDetails selectedAnimal={selectedAnimal} />
        )}
      <AnimalDetails selectedAnimal={selectedAnimal} />
      <EventsWidget animalId={selectedAnimal.id} />
      {selectedAnimal.gender === ANIMAL_GENDERS.FEMALE.label && (
        <GestationCalendarWidget animalId={selectedAnimal.id} />
      )}
      <TasksWidget tasks={selectedAnimal.tasks} animalId={selectedAnimal.id} />
      <NotesWidget notes={selectedAnimal.notes} animalId={selectedAnimal.id} />
    </>
  )
}

export default AnimalProfileEditor
