import React from "react"
import Switch from "@/components/shadcn/switch/switch.tsx"
import styles from "@/features/tasks/components/reminder-options/reminder-options.module.css"

interface ReminderRowProps {
  label: string
  reminder: number | null
  reminderValue: number
  setReminder: (reminders: number | null) => void
}

/**
 * @param label description of the reminder, what it is for
 * @param reminder the current reminder value
 * @param reminderValue the value of the reminder
 * @param setReminder function to set the reminder
 * @constructor
 */
export const SwitchRowValue: React.FC<ReminderRowProps> = ({
  label,
  reminder,
  reminderValue,
  setReminder,
}) => {
  const handleToggle = (value: boolean, reminderValue: number) => {
    if (value) {
      setReminder(reminderValue)
    } else {
      setReminder(null)
    }
  }

  return (
    <div className={styles["reminder-rows"]}>
      <div className={styles["label"]}>{label}</div>
      <Switch
        checked={reminder === reminderValue}
        onCheckedChange={(value) => handleToggle(value, reminderValue)}
      />
    </div>
  )
}
