import { useNavigate } from "react-router-dom"
import styles from "./empty-content-create-item-button.module.css"
import BigPlusIcon from "@/assets/icons/misc/big-plus-icon.tsx"

interface WidgetAddItemProps {
  navigateTo: string
}

const EmptyContentCreateItemButton: React.FC<WidgetAddItemProps> = ({
  navigateTo,
}) => {
  const navigate = useNavigate()

  return (
    <div
      className={styles["big-plus-icon"]}
      onClick={() => navigate(navigateTo)}
    >
      <BigPlusIcon />
    </div>
  )
}

export default EmptyContentCreateItemButton
