import useCheckEventType from "@/features/animal-events/hooks/use-check-event-type.ts"
import { MainAnimalEventType } from "@/features/animal-events/types/animal-events.ts"

/**
 *  Hook to return an object with booleans for each event type
 *  used to determine which event editor to show or what schema to use
 *
 * @param eventDraftId
 */
export const useGetEventDataTypeObject = (eventDraftId: string | undefined) => {
  const checkEventType = useCheckEventType()

  const isMovementEvent = checkEventType(
    eventDraftId,
    MainAnimalEventType.Movement,
  )

  const isMeasurementEvent = checkEventType(
    eventDraftId,
    MainAnimalEventType.Measurement,
  )

  const isTreatmentEvent = checkEventType(
    eventDraftId,
    MainAnimalEventType.Treatment,
  )

  return {
    isMovementEvent,
    isMeasurementEvent,
    isTreatmentEvent,
  }
}
