import React from "react"

const AinIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="36 330 30 30"
      {...props}
    >
      <g data-name="Group 2463">
        <path
          d="M43 330h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H43a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="var(--done-green)"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <path
          d="M47.13 341.13h.008m2.766-3.246h-2.173c-1.347 0-2.02 0-2.534.262-.453.23-.82.598-1.051 1.05-.262.515-.262 1.188-.262 2.535v2.173c0 .589 0 .883.066 1.16.06.245.156.48.288.694.149.243.357.45.773.867l3.767 3.767c.952.952 1.428 1.428 1.977 1.607a2.404 2.404 0 0 0 1.486 0c.549-.179 1.025-.655 1.977-1.607l2.174-2.174c.952-.952 1.428-1.428 1.607-1.977a2.404 2.404 0 0 0 0-1.486c-.179-.549-.655-1.025-1.607-1.977l-3.767-3.767c-.416-.416-.624-.624-.867-.773a2.405 2.405 0 0 0-.695-.288c-.276-.066-.57-.066-1.159-.066Zm-2.374 3.246a.4.4 0 1 1-.801 0 .4.4 0 0 1 .801 0Z"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.4"
          stroke="#fff"
          fill="transparent"
          data-name="tag-svgrepo-com"
        />
      </g>
    </svg>
  )
}

export default AinIcon
