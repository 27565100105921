import queryString from "query-string"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ChooseSomethingIcon from "@/assets/icons/finance/choose-something-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { type TransactionCategorySummary } from "@/features/finance/api/finance-summary-api.ts"
import TransactionCardSkeleton from "@/features/finance/components/transaction-card-skeleton/transaction-card-skeleton.tsx"
import { FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH } from "@/utils/constants/routes.ts"

interface SummaryCategoryCardProps {
  data: TransactionCategorySummary
  percent: number
}

const SummaryCategoryCard: React.FC<SummaryCategoryCardProps> = ({
  data,
  percent,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  /**
   * Redirects to the transaction history page with the selected category and type
   */
  const handleClick = () => {
    const queryParams: Record<string, string> = {
      selectedCategory: data.category.id,
      ...(data.category.is_outflow
        ? { expenses: "true" }
        : { incomes: "true" }),
    }
    const searchString = queryString.stringify(queryParams)
    navigate({
      search: searchString,
      pathname: FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH,
    })
  }

  return (
    <TransactionCardSkeleton
      icon={
        data.category.picture ? (
          <GenericPicture
            src={data.category.picture}
            alt=""
            size={40}
            borderRadius={data.category.farm ? 8 : 0}
            contain={!data.category.farm}
          />
        ) : (
          <ChooseSomethingIcon height={40} width={40} />
        )
      }
      onClick={handleClick}
      topRightText={`${data.total} Ron`}
      bottomRightText={`${percent}%`}
      bottomLeftText={`${data.transaction_count}
              ${
                Number(data.transaction_count) === 1
                  ? t("transaction")
                  : t("transactions")
              }`}
      topLeftText={t(data.category.name)}
      showArrow={true}
    />
  )
}

export default SummaryCategoryCard
