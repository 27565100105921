import { DARK_GREY } from "@/utils/constants/colors-constants"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const ViewPasswordIcon: React.FC<GeneralSvgProps> = (props) => (
  <svg
    {...props}
    width={props.size || 20}
    height={props.size || 20}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      strokeLinecap="round"
      strokeLinejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12"
        stroke={props?.color || DARK_GREY}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12"
        stroke={props?.color || DARK_GREY}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <circle
        cx="12"
        cy="12"
        r="3"
        stroke={props?.color || DARK_GREY}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></circle>
    </g>
  </svg>
)

export default ViewPasswordIcon
