import React from "react"
import { NotesProvider } from "@/context/notes-context.tsx"
import DrawerNotes from "@/features/notes/components/drawer-notes/drawer-notes.tsx"
import DrawerNotesAssocTrigger from "@/features/tasks/components/drawer-notes-assoc-trigger/drawer-notes-assoc-trigger.tsx"

interface DrawerNotesContextWrapper {
  currentValue: string[]
  onSaveClick: (note: string[]) => void
}

const DrawerNotesContextWrapper: React.FC<DrawerNotesContextWrapper> = ({
  currentValue,
  onSaveClick,
}) => {
  return (
    <NotesProvider>
      <DrawerNotes
        currentValue={currentValue}
        onSaveClick={onSaveClick}
        TriggerComponent={DrawerNotesAssocTrigger}
      />
    </NotesProvider>
  )
}

export default DrawerNotesContextWrapper
