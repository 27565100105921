import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuGestationCalendarIconMobile: React.FC<GeneralSvgProps> = (props) => {
  const color = props.color || "var(--menu-gestation-period-calendar-color)"

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="141 507.862 60 60"
    >
      <g data-name="Group 4433">
        <path
          d="M141 537.812a30 30 0 1 1 0 .1z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Ellipse 513"
        />
        <g data-name="Group 2767">
          <g data-name="calendar-svgrepo-com (2)">
            <path
              d="M177.576 526.867h-12.662v.738c0 .408-.354.739-.791.739-.438 0-.792-.33-.792-.739v-.738h-2.374c-1.311 0-2.374.992-2.374 2.216v3.693h25.324v-3.693c0-1.224-1.063-2.216-2.374-2.216h-2.374v.738c0 .408-.354.739-.791.739-.437 0-.792-.33-.792-.739v-.738Zm1.583-1.478h2.374c2.185 0 3.957 1.654 3.957 3.694v19.215c0 2.04-1.772 3.693-3.957 3.693h-20.576c-2.185 0-3.957-1.653-3.957-3.693v-19.215c0-2.04 1.772-3.694 3.957-3.694h2.374v-.738c0-.408.354-.739.792-.739.437 0 .79.33.79.739v.738h12.663v-.738c0-.408.355-.739.792-.739.437 0 .791.33.791.739v.738Zm4.748 8.864h-25.324v14.045c0 1.224 1.063 2.216 2.374 2.216h20.576c1.311 0 2.374-.992 2.374-2.216v-14.045Z"
              fill={color}
              fillRule="evenodd"
            />
            <path
              d="M177.576 526.867h-12.662v.738c0 .408-.354.739-.791.739-.438 0-.792-.33-.792-.739v-.738h-2.374c-1.311 0-2.374.992-2.374 2.216v3.693h25.324v-3.693c0-1.224-1.063-2.216-2.374-2.216h-2.374v.738c0 .408-.354.739-.791.739-.437 0-.792-.33-.792-.739v-.738Zm1.583-1.478h2.374c2.185 0 3.957 1.654 3.957 3.694v19.215c0 2.04-1.772 3.693-3.957 3.693h-20.576c-2.185 0-3.957-1.653-3.957-3.693v-19.215c0-2.04 1.772-3.694 3.957-3.694h2.374v-.738c0-.408.354-.739.792-.739.437 0 .79.33.79.739v.738h12.663v-.738c0-.408.355-.739.792-.739.437 0 .791.33.791.739v.738Zm4.748 8.864h-25.324v14.045c0 1.224 1.063 2.216 2.374 2.216h20.576c1.311 0 2.374-.992 2.374-2.216v-14.045h0Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1423">
            <path
              d="M163.707 537.251c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              fill={color}
              fillRule="evenodd"
            />
            <path
              d="M163.707 537.251c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1426">
            <path
              d="M163.706 543.243c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45.859 0 1.554-.65 1.554-1.45 0-.802-.696-1.45-1.554-1.45Z"
              fill={color}
              fillRule="evenodd"
            />
            <path
              d="M163.706 543.243c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45.859 0 1.554-.65 1.554-1.45 0-.802-.696-1.45-1.554-1.45Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1427">
            <path
              d="M171.245 543.243c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              fill={color}
              fillRule="evenodd"
            />
            <path
              d="M171.245 543.243c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1428">
            <path
              d="M178.784 543.243c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              fill={color}
              fillRule="evenodd"
            />
            <path
              d="M178.784 543.243c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1424">
            <path
              d="M171.245 537.251c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              fill={color}
              fillRule="evenodd"
            />
            <path
              d="M171.245 537.251c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1425">
            <path
              d="M178.784 537.251c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              fill={color}
              fillRule="evenodd"
            />
            <path
              d="M178.784 537.251c-.858 0-1.553.648-1.553 1.45 0 .8.695 1.45 1.553 1.45s1.553-.65 1.553-1.45c0-.802-.695-1.45-1.553-1.45Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke={color}
              fill="transparent"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MenuGestationCalendarIconMobile
