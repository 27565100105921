import React from "react"

const BigPlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="2787.327 -611.172 65.345 65.345"
    {...props}
  >
    <g data-name="CompositeLayer">
      <path
        d="M2820-611.172c18.044 0 32.672 14.628 32.672 32.672 0 18.044-14.628 32.672-32.672 32.672-18.045 0-32.673-14.628-32.673-32.672 0-18.044 14.628-32.672 32.673-32.672Z"
        fill="#e4ded9"
        fillRule="evenodd"
        data-name="Path 1259"
      />
      <path
        d="M2804.607-578.324h30.464m-15.232-15.232v30.464"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth="2"
        stroke="#fff"
        fill="transparent"
        data-name="plus-large-svgrepo-com"
      />
    </g>
  </svg>
)

export default BigPlusIcon
