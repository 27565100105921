import queryString from "query-string"
import { useMemo, useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { type NotificationFilter } from "@/components/filter-tab/notifications-filter-tab/notifications-filter-tab.tsx"
import {
  NOTIFICATION_CATEGORIES_ENUM,
  NOTIFICATION_READ_ENUM,
} from "@/features/notifications/types/notifications-enum.ts"
import { type Notification } from "@/features/notifications/types/notifications.ts"

export const useNotificationFilter = (initialNotifications: Notification[]) => {
  const location = useLocation()
  const navigate = useNavigate()

  const initialFilter = useMemo(() => {
    const parsed = queryString.parse(location.search)
    return {
      notificationCategory:
        parsed.category || NOTIFICATION_CATEGORIES_ENUM.ALL_NOTIFICATIONS,
      notificationReadStatus:
        parsed.read_status || NOTIFICATION_READ_ENUM.UNREAD,
    }
  }, [location.search])

  const [notificationFilter, setNotificationFilter] = useState(initialFilter)

  useEffect(() => {
    const query = queryString.stringify({
      category: notificationFilter.notificationCategory,
      read_status: notificationFilter.notificationReadStatus,
    })
    navigate(`?${query}`, { replace: true })
  }, [notificationFilter, navigate])

  const filteredNotifications = useMemo(() => {
    const categoryFiltered = initialNotifications.filter((notification) => {
      return (
        notificationFilter.notificationCategory ===
          NOTIFICATION_CATEGORIES_ENUM.ALL_NOTIFICATIONS ||
        notification.notification_type ===
          notificationFilter.notificationCategory
      )
    })

    return categoryFiltered.filter((notification) => {
      if (
        notificationFilter.notificationReadStatus ===
        NOTIFICATION_READ_ENUM.UNREAD
      ) {
        return notification.read_at === null
      }
      return notification.read_at !== null
    })
  }, [notificationFilter, initialNotifications])

  const handleFilterChange = (filter: NotificationFilter) => {
    setNotificationFilter(filter)
  }

  return {
    notificationFilter,
    handleFilterChange,
    filteredNotifications,
  }
}
