import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuNotificationBellIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="348.096 507.864 60 60"
    >
      <g data-name="Group 4428">
        <path
          d="M348.096 537.814a30 30 0 1 1 0 .1z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Ellipse 514"
        />
        <g data-name="Group 2771">
          <g data-name="Path 1395">
            <path
              d="M389.871 544a1.203 1.203 0 0 0-.396-.53c.014-.34.023-.683.023-1.03 0-7.619-3.383-14.071-8.055-16.284.07-.264.11-.537.11-.82 0-1.89-1.641-3.422-3.666-3.422s-3.667 1.532-3.667 3.421c0 .284.041.557.11.82-4.672 2.214-8.054 8.666-8.054 16.286 0 .346.009.689.023 1.03-.176.14-.32.315-.397.529l-1.737 4.848c-.282.785.345 1.597 1.232 1.597h8.469c.516 1.617 2.12 2.797 4.02 2.797 1.902 0 3.505-1.18 4.022-2.797h8.468c.888 0 1.514-.812 1.233-1.597L389.87 544Zm-14.987-16.827.866-.41-.233-.88a2.12 2.12 0 0 1-.075-.548c0-1.257 1.097-2.28 2.445-2.28 1.348 0 2.444 1.023 2.444 2.28 0 .178-.024.357-.075.548l-.232.88.866.41c4.348 2.06 7.385 8.338 7.385 15.268 0 .234-.003.47-.01.71h-20.756c-.007-.24-.011-.476-.011-.71 0-6.93 3.037-13.209 7.386-15.268Zm3.003 24.95c-1.227 0-2.284-.691-2.748-1.678h5.495c-.463.987-1.52 1.678-2.747 1.678Zm12.57-2.836c-.03.039-.065.039-.08.039H365.396c-.015 0-.051 0-.081-.04a.08.08 0 0 1-.014-.083l1.737-4.848a.1.1 0 0 1 .095-.064h21.505a.1.1 0 0 1 .095.064l1.737 4.848a.08.08 0 0 1-.013.084Z"
              fill="var(--menu-notifications-color)"
              fillRule="evenodd"
            />
            <path
              d="M389.871 544a1.203 1.203 0 0 0-.396-.53c.014-.34.023-.683.023-1.03 0-7.619-3.383-14.071-8.055-16.284.07-.264.11-.537.11-.82 0-1.89-1.641-3.422-3.666-3.422s-3.667 1.532-3.667 3.421c0 .284.041.557.11.82-4.672 2.214-8.054 8.666-8.054 16.286 0 .346.009.689.023 1.03-.176.14-.32.315-.397.529l-1.737 4.848c-.282.785.345 1.597 1.232 1.597h8.469c.516 1.617 2.12 2.797 4.02 2.797 1.902 0 3.505-1.18 4.022-2.797h8.468c.888 0 1.514-.812 1.233-1.597L389.87 544Zm-14.987-16.827.866-.41-.233-.88a2.12 2.12 0 0 1-.075-.548c0-1.257 1.097-2.28 2.445-2.28 1.348 0 2.444 1.023 2.444 2.28 0 .178-.024.357-.075.548l-.232.88.866.41c4.348 2.06 7.385 8.338 7.385 15.268 0 .234-.003.47-.01.71h-20.756c-.007-.24-.011-.476-.011-.71 0-6.93 3.037-13.209 7.386-15.268Zm3.003 24.95c-1.227 0-2.284-.691-2.748-1.678h5.495c-.463.987-1.52 1.678-2.747 1.678Zm12.57-2.836c-.03.039-.065.039-.08.039H365.396c-.015 0-.051 0-.081-.04a.08.08 0 0 1-.014-.083l1.737-4.848a.1.1 0 0 1 .095-.064h21.505a.1.1 0 0 1 .095.064l1.737 4.848a.08.08 0 0 1-.013.084Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="1.3"
              stroke="var(--menu-notifications-color)"
              fill="transparent"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MenuNotificationBellIcon
