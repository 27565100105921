import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./tasks-by-time-span.module.css"
import DateSeparator from "@/features/notes/components/date-separator/date-separator.tsx"
import { type Task } from "@/features/tasks"
import TaskCard from "@/features/tasks/components/task-card/task-card.tsx"

interface TasksByTimeSpanProps {
  tasks: Task[]
  subTitle?: string
  enableSeparator?: boolean
  title?: string
}

const TasksByTimeSpan: React.FC<TasksByTimeSpanProps> = ({
  tasks,
  subTitle,
  enableSeparator = false,
  title,
}) => {
  const { t } = useTranslation()
  const translatedSubTitle = subTitle ? t(subTitle) : ""
  const translatedTitle = title ? t(title) : ""

  return (
    <>
      {tasks.length > 5 && (
        <div className={styles["sub-title-updated"]}>{translatedTitle}</div>
      )}
      {enableSeparator && <DateSeparator monthYear={translatedSubTitle} />}
      {tasks.map((task) => (
        <TaskCard key={task.id} task={task} />
      ))}
    </>
  )
}

export default TasksByTimeSpan
