import { Navigate, Route } from "react-router-dom"
import { Home } from "@/features/home"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import { StandardLayout } from "@/layouts/standard-layout/standard-layout.tsx"
import { HOME_SCREEN_PATH } from "@/utils/constants/routes.ts"

export const HomeRoutes = (
  <>
    <Route element={<NoMenuLayout />}>
      <Route
        key={HOME_SCREEN_PATH}
        path="/"
        element={<Navigate replace to={HOME_SCREEN_PATH} />}
      />
    </Route>
    <Route element={<StandardLayout />}>
      <Route
        key={HOME_SCREEN_PATH + "1"}
        path={HOME_SCREEN_PATH}
        element={<Home />}
      />
    </Route>
  </>
)
