import { useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./calendar-activity-timeline.module.css"
import ArrowDown from "@/assets/icons/misc/arrow-down.tsx"
import EmptyCalendarIcon from "@/assets/icons/misc/empty-calendar-icon.tsx"
import TimelineTuple from "@/components/timeline/timeline-tuple/timeline-tuple.tsx"
import CalendarActivityTimelineSection from "@/features/gestational-calendar/components/calendar-activity-timeline-section/calendar-activity-timeline-section.tsx"
import CalendarActivityTimelineSummary from "@/features/gestational-calendar/components/calendar-activity-timeline-summary/calendar-activity-timeline-summary.tsx"
import {
  CalendarActivityState,
  type GestationCalendar,
} from "@/features/gestational-calendar/types/calendar-types.ts"
import { checkIsCalendarModified } from "@/features/gestational-calendar/utils/gestational-calendar-utils.ts"

interface CalendarActivityTimelineProps {
  gestationalCalendar: GestationCalendar
}

const CalendarActivityTimeline: React.FC<CalendarActivityTimelineProps> = ({
  gestationalCalendar,
}) => {
  const { t } = useTranslation()

  const wasCalvingDateConfirmed = checkIsCalendarModified(gestationalCalendar)
  const [isBeforeCalvingViewOpen, setIsBeforeCalvingViewOpen] = useState(false)

  const calvingIndex = gestationalCalendar.entries.findIndex(
    (entry) => entry.event.is_calving_relative && entry.event.time_offset === 0,
  )

  const beforeCalvingActivities = gestationalCalendar.entries.filter(
    (activity) => !activity.event.is_calving_relative,
  )
  const afterCalvingActivities = gestationalCalendar.entries.filter(
    (activity) => activity.event.is_calving_relative,
  )

  const newBeforeCalvingIndexes = beforeCalvingActivities.map((activity) =>
    gestationalCalendar.entries.findIndex((entry) => entry === activity),
  )

  const newAfterCalvingIndexes = afterCalvingActivities.map((activity) =>
    gestationalCalendar.entries.findIndex((entry) => entry === activity),
  )

  return (
    <>
      <div className={styles.title}>
        <EmptyCalendarIcon /> {t("activityCalendar")}
      </div>
      {wasCalvingDateConfirmed && !isBeforeCalvingViewOpen ? (
        <CalendarActivityTimelineSummary
          onClick={() => setIsBeforeCalvingViewOpen(true)}
        />
      ) : (
        <CalendarActivityTimelineSection
          gestationCalendar={gestationalCalendar}
          indexRange={newBeforeCalvingIndexes}
          forcedActivityState={
            wasCalvingDateConfirmed ? CalendarActivityState.DONE : undefined
          }
        />
      )}
      {wasCalvingDateConfirmed && isBeforeCalvingViewOpen && (
        <div
          className={styles.hideDetailsWrapper}
          onClick={() => setIsBeforeCalvingViewOpen(false)}
        >
          <button
            onClick={() => setIsBeforeCalvingViewOpen(false)}
            className={styles.hideDetailsButton}
          >
            <ArrowDown isDown={false} active={false} />
            {t("hideEvents")}
          </button>
          <span className={styles.divider} />
        </div>
      )}
      <TimelineTuple
        gestationalCalendar={gestationalCalendar}
        index={calvingIndex}
        isOpen={true}
        forcedActivityState={
          wasCalvingDateConfirmed ? CalendarActivityState.DONE : undefined
        }
      />
      <CalendarActivityTimelineSection
        gestationCalendar={gestationalCalendar}
        indexRange={newAfterCalvingIndexes.splice(
          1,
          newBeforeCalvingIndexes.length,
        )}
      />
    </>
  )
}

export default CalendarActivityTimeline
