import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import styles from "./animal-event-type-drawer.module.css"
import ArrowDown from "@/assets/icons/misc/arrow-down.tsx"
import GenericDrawerSelectionRow from "@/components/generic-drawer-selection-row/generic-drawer-selection-row.tsx"
import { type AnimalEventType } from "@/features/animal-events/types/animal-events.ts"

interface AnimalEventsTypeDropdownProps {
  selectedEvent: string | undefined
  onClick: (eventTypeId: string | undefined) => void
  isOpen: boolean
  onOpen: () => void
  eventTypes: [string, AnimalEventType[]]
  onViewChange: (entityId: string) => void
}

const AnimalEventsTypeDropdown: React.FC<AnimalEventsTypeDropdownProps> = ({
  selectedEvent,
  onClick,
  eventTypes,
  isOpen,
  onOpen,
  onViewChange,
}) => {
  const { t } = useTranslation()

  const isAnySelected = eventTypes[1].some((event) => {
    return event.id === selectedEvent
  })

  // This is used when we open the drawer,
  // if the dropdown contains the selected choice this will be opened by default,
  // This only happens if a prev selection is made
  useEffect(() => {
    if (isAnySelected) {
      onOpen()
    }
  }, [])

  const selectEventType = (eventTypeId: string) => {
    if (eventTypeId === selectedEvent) {
      onClick(undefined)
    } else {
      onClick(eventTypeId)
    }
  }

  const handleOnLongClick = (eventType: AnimalEventType) => {
    eventType.farm && onViewChange(eventType.id)
  }

  return (
    <>
      <div className={styles.dropdownTrigger} onClick={onOpen}>
        <div className={styles.titleWrapper}>
          <p className={styles.title}>{t(eventTypes[0])}</p>
          {isAnySelected && <div className={styles.selectedIndicator} />}
        </div>
        {isOpen ? <ArrowDown isDown={false} /> : <ArrowDown />}
      </div>
      {isOpen && (
        <div className={styles.optionsWrapper}>
          {eventTypes[1].map((event, index) => (
            <GenericDrawerSelectionRow
              key={index}
              isSelected={selectedEvent === event.id}
              onClick={() => selectEventType(event.id)}
              entity={event}
              onLongClick={() => handleOnLongClick(event)}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default AnimalEventsTypeDropdown
