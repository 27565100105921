import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import {
  useCreateMedicationTypeMutation,
  useDeleteMedicationTypeMutation,
  useGetAllMedicationTypesQuery,
  useUpdateMedicationTypeMutation,
} from "@/features/animal-events/api/medication-types-api.ts"
import { type AnimalEventType } from "@/features/animal-events/types/animal-events.ts"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import styles from "@/features/finance/components/add-transaction-category-form/add-transaction-category-form.module.css"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

interface AddMedicationTypeFormProps {
  onBackClick: () => void
  medicationId: string | null
}

const initialEventType: Partial<AnimalEventType> = {
  name: "",
  picture: null,
}

const AddMedicationTypeForm: React.FC<AddMedicationTypeFormProps> = ({
  onBackClick,
  medicationId,
}) => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const { data } = useGetAllMedicationTypesQuery()
  const medicationType = medicationId && data?.[medicationId]

  const [createMedicationType] = useCreateMedicationTypeMutation()
  const [updateMedicationType] = useUpdateMedicationTypeMutation()
  const [deleteMedicationType] = useDeleteMedicationTypeMutation()
  const [newMedicationType, setNewMedicationType] = useState(
    medicationType || initialEventType,
  )

  const handlePictureChange = (imgFile: string | null) => {
    setNewMedicationType({ ...newMedicationType, picture: imgFile })
  }

  const handleSaveEventType = () => {
    toast
      .promise(createMedicationType(newMedicationType).unwrap(), {
        pending: t("savingMedicationType"),
        success: t("medicationTypeSaved"),
        error: t("errorCreatingMedicationType"),
      })
      .then(() => {
        onBackClick()
      })
  }

  const diff =
    medicationType && getObjectDifference(medicationType, newMedicationType)

  const handleUpdateEventType = () => {
    if (medicationType) {
      toast
        .promise(
          updateMedicationType({
            ...diff,
            id: medicationType.id,
          }).unwrap(),
          {
            pending: t("updatingMedicationType"),
            success: t("medicationTypeUpdated"),
            error: t("errorUpdatingMedicationType"),
          },
        )
        .then(() => {
          onBackClick()
        })
    }
  }

  const handleDeleteEventType = () => {
    if (!medicationType) {
      return
    }

    openModal(MODALS.BASE_MODAL, {
      title: t("deleteMedicationTypeConfirmation"),
      content: t("medicationTypeWillBeDeleted"),
      onActionClick: () => {
        toast
          .promise(deleteMedicationType(medicationType?.id).unwrap(), {
            pending: t("deletingMedicationType"),
            success: t("medicationTypeDeleted"),
            error: t("errorDeletingMedicationType"),
          })
          .then(() => {
            onBackClick()
          })
      },
    })
  }

  const handleEventTypeMutation = () => {
    if (medicationType) {
      handleUpdateEventType()
    } else {
      handleSaveEventType()
    }
  }

  return (
    <div className={styles.drawerFinanceContentWrapper}>
      <GenericTypeDrawerHeader
        title={medicationType ? t(medicationType.name) : t("addMedicationType")}
        onBackClick={onBackClick}
        {...((diff && Object.keys(diff).length > 0) || !medicationType
          ? { onSaveClick: handleEventTypeMutation }
          : {})}
        closeOnSave={false}
      />
      <div className={styles.formBody}>
        <OverflowMenuItem>
          <input
            type="text"
            placeholder={t("medicationName")}
            className={styles.input}
            value={newMedicationType.name}
            onChange={(e) =>
              setNewMedicationType({
                ...newMedicationType,
                name: e.target.value,
              })
            }
            required
            maxLength={100}
          />
          {newMedicationType.name === "" && <RequiredIndicator />}
        </OverflowMenuItem>
        <AttachImageFieldBase64
          picture={newMedicationType.picture as string}
          onChoosePicture={handlePictureChange}
        />
      </div>
      {medicationType && (
        <DeleteButton
          text={t("deleteEventType")}
          onClick={handleDeleteEventType}
        />
      )}
    </div>
  )
}

export default AddMedicationTypeForm
