import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuTasksIconMobile: React.FC<GeneralSvgProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="348 72.316 60 60"
    >
      <g data-name="Group 4431">
        <path
          d="M348 102.266a30 30 0 1 1 0 .1z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Ellipse 509"
        />
        <g data-name="Group 2769">
          <path
            d="M371.691 95.391a1.671 1.671 0 1 1-3.343 0 1.671 1.671 0 0 1 3.343 0Z"
            fill="var(--menu-tasks-color)"
            fillRule="evenodd"
            data-name="Path 804"
          />
          <path
            d="M370.02 104.081a1.671 1.671 0 1 0 0-3.342 1.671 1.671 0 0 0 0 3.342Z"
            fill="var(--menu-tasks-color)"
            fillRule="evenodd"
            data-name="Path 805"
          />
          <path
            d="M370.02 111.101a1.671 1.671 0 1 0 0-3.342 1.671 1.671 0 0 0 0 3.342Z"
            fill="var(--menu-tasks-color)"
            fillRule="evenodd"
            data-name="Path 806"
          />
          <path
            d="M374.699 94.388a1.003 1.003 0 0 0 0 2.006h8.69a1.003 1.003 0 0 0 0-2.006h-8.69Z"
            fill="var(--menu-tasks-color)"
            fillRule="evenodd"
            data-name="Path 807"
          />
          <path
            d="M373.696 102.41c0-.553.45-1.002 1.003-1.002h8.69a1.003 1.003 0 0 1 0 2.005h-8.69a1.003 1.003 0 0 1-1.003-1.003Z"
            fill="var(--menu-tasks-color)"
            fillRule="evenodd"
            data-name="Path 808"
          />
          <path
            d="M374.699 108.427a1.003 1.003 0 0 0 0 2.006h8.69a1.003 1.003 0 0 0 0-2.006h-8.69Z"
            fill="var(--menu-tasks-color)"
            fillRule="evenodd"
            data-name="Path 809"
          />
          <path
            d="m392.121 110.14-8.022 8.021a1.002 1.002 0 0 1-1.418 0l-4.009-4.008a1.003 1.003 0 0 1 1.419-1.418l3.299 3.3 7.313-7.314a1.003 1.003 0 1 1 1.418 1.418Z"
            fill="var(--menu-tasks-color)"
            fillRule="evenodd"
            data-name="Path 810"
          />
          <path
            d="M363 90.712c0-2.4 1.945-4.346 4.345-4.346h18.05c2.4 0 4.345 1.946 4.345 4.346v16.216a3.013 3.013 0 0 0-.455.375l-1.55 1.55V90.711a2.34 2.34 0 0 0-2.34-2.34h-18.05a2.34 2.34 0 0 0-2.34 2.34v23.397a2.34 2.34 0 0 0 2.34 2.34h10.787l2.006 2.005h-12.793A4.345 4.345 0 0 1 363 114.11V90.712Z"
            fill="var(--menu-tasks-color)"
            fillRule="evenodd"
            data-name="Path 803"
          />
        </g>
      </g>
    </svg>
  )
}

export default MenuTasksIconMobile
