import isEmpty from "lodash/isEmpty"
import React from "react"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import { NOTIFICATION_BLACK } from "@/utils/constants/colors-constants.ts"

interface CustomTriggerProps {
  currentValue: string | undefined
  onClick?: () => void
  displayText: () => string
}

const GenericButtonTrigger = React.forwardRef<
  HTMLButtonElement,
  CustomTriggerProps
>(({ currentValue, onClick, displayText }, ref) => {
  const selectedStyle = {
    color: "white",
    backgroundColor: NOTIFICATION_BLACK,
    border: "none",
    paddingInline: "15px",
  }

  const unSelectedStyle = {
    paddingInline: "15px",
  }

  const isSelected = () => {
    return !isEmpty(currentValue)
  }

  return (
    <SelectPill
      style={isSelected() ? selectedStyle : unSelectedStyle}
      ref={ref}
      onClick={onClick}
    >
      {displayText()}
    </SelectPill>
  )
})

GenericButtonTrigger.displayName = "GenericButtonTrigger"

export default GenericButtonTrigger
