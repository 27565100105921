import { format } from "date-fns"
import { type Task } from "@/features/tasks"

export const useGetTaskDateHour = (task: Task) => {
  if (!task.due_date) {
    const formattedDate = ""
    const formattedHour = ""

    return { formattedDate, formattedHour }
  }
  const date = new Date(task.due_date)
  const formattedDate = format(date, "dd.MM.yyyy")
  const formattedHour = format(date, "HH:mm")

  return { formattedDate, formattedHour }
}
