import React from "react"
import styles from "./timeline-tag.module.css"
import TickIcon from "@/assets/icons/misc/tick-icon.tsx"
import { CalendarActivityState } from "@/features/gestational-calendar/types/calendar-types.ts"
import { BROWN, CALVING_COLOR } from "@/utils/constants/colors-constants.ts"

interface TimeLineTagPRops {
  activityState: CalendarActivityState
  isAfterCalving?: boolean
}

const TimelineTag: React.FC<TimeLineTagPRops> = ({
  activityState,
  isAfterCalving,
}) => {
  const getTagWrapperBackgroundColor = () => {
    if (activityState === CalendarActivityState.DONE && !isAfterCalving) {
      return BROWN
    } else if (activityState === CalendarActivityState.DONE && isAfterCalving) {
      return CALVING_COLOR
    }
  }

  const getTagWrapperBorderColor = () => {
    if (
      (activityState === CalendarActivityState.DONE ||
        activityState === CalendarActivityState.CURRENT) &&
      !isAfterCalving
    ) {
      return BROWN
    } else if (
      (activityState === CalendarActivityState.DONE ||
        activityState === CalendarActivityState.CURRENT) &&
      isAfterCalving
    ) {
      return CALVING_COLOR
    }
  }

  return (
    <div
      className={styles.tagWrapper}
      style={{
        backgroundColor: getTagWrapperBackgroundColor(),
        borderColor: getTagWrapperBorderColor(),
      }}
    >
      {activityState === CalendarActivityState.CURRENT && (
        <div
          className={styles.dot}
          data-is-after-calving={isAfterCalving}
        ></div>
      )}
      {activityState === CalendarActivityState.DONE && <TickIcon />}
    </div>
  )
}

export default TimelineTag
