import React, { useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import ObjectNotFoundSvg from "@/assets/icons/error-handling/object-not-found.svg"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import PendingCircle from "@/components/pending-circle/pending-circle.tsx"
import styles from "@/components/shadcn/drawer/custom-drawer.module.css"
import DrawerTopBar from "@/components/shadcn/drawer/drawer-top-bar.tsx"
import { type Animal, ANIMAL_GENDERS, type AnimalDraft } from "@/features/farm"
import AnimalCard from "@/features/farm/components/animal-card/animal-card.tsx"
import { BROWN } from "@/utils/constants/colors-constants.ts"

interface DrawerContentProps {
  offspring: AnimalDraft
  onSaveClick: (motherId: string) => void
  allAnimals: Animal[]
  isMother?: boolean
}

const DrawerSelectMotherContent: React.FC<DrawerContentProps> = ({
  offspring,
  onSaveClick,
  allAnimals,
  isMother,
}) => {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>("")

  const filteredAnimals = useMemo(() => {
    const allowedKeys = ["ain", "name"]
    return allAnimals.filter((animal) => {
      const canBeMother =
        animal.breed === offspring?.breed &&
        animal.gender ===
          (isMother
            ? ANIMAL_GENDERS.FEMALE.label
            : ANIMAL_GENDERS.MALE.label) &&
        animal.ain !== offspring.ain

      const matchesSearch = Object.entries(animal).some(
        ([key, value]) =>
          allowedKeys.includes(key) &&
          typeof value === "string" &&
          value.toLowerCase().includes(searchQuery.toLowerCase()),
      )

      return canBeMother && matchesSearch
    })
  }, [allAnimals, isMother, offspring.ain, offspring?.breed, searchQuery])

  const isSelected = (parentId: string) =>
    isMother ? offspring.mother === parentId : offspring.father === parentId

  const handleAnimalSelection = (motherId: string) => {
    onSaveClick(isSelected(motherId) ? "" : motherId)
  }

  return (
    <>
      <DrawerTopBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        placeholder={t("searchAnimal")}
      />
      <div className={styles["slide-up-content-wrapper"]}>
        <div className={styles["slide-up-content"]}>
          {filteredAnimals.length > 0 ? (
            filteredAnimals.map((animal) => (
              <div key={animal.id}>
                <div
                  className={
                    isSelected(animal.id)
                      ? styles["animal-card-selected"]
                      : styles["animal-card"]
                  }
                  onClick={() => handleAnimalSelection(animal.id)}
                >
                  {isSelected(animal.id) ? (
                    <CircleTickIcon fill={BROWN} size={24} />
                  ) : (
                    <PendingCircle />
                  )}
                  <AnimalCard animal={animal} />
                </div>
                <div className={styles["separator"]}></div>
              </div>
            ))
          ) : (
            <div className={styles.noTransactions}>
              <img
                src={ObjectNotFoundSvg}
                alt=""
                className={styles.notFoundImg}
              />
              <h3>{t("noAnimalsFound")}</h3>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default DrawerSelectMotherContent
