import isEmpty from "lodash/isEmpty"
import React from "react"
import { useTranslation } from "react-i18next"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import { useGetAnimalsQuery } from "@/features/farm/api/animals-api.ts"
import { NOTIFICATION_BLACK } from "@/utils/constants/colors-constants.ts"

interface CustomTriggerProps {
  currentValue: string[] | string
  onClick: () => void
}

const DrawerAnimalsFilterTrigger = React.forwardRef<
  HTMLButtonElement,
  CustomTriggerProps
>(({ currentValue, onClick }, ref) => {
  const { t } = useTranslation()
  const { data: allAnimals } = useGetAnimalsQuery()

  const selectedStyle = {
    color: "white",
    backgroundColor: NOTIFICATION_BLACK,
    border: "none",
    paddingInline: "15px",
  }

  const unSelectedStyle = {
    paddingInline: "15px",
  }

  const isSelected = () => {
    return !isEmpty(currentValue)
  }

  function getAssociatedAnimalsInfo() {
    if (typeof currentValue === "string") {
      return (
        allAnimals?.find((animal) => animal.id === currentValue)?.name ||
        t("oneAnimalSelected")
      )
    }
    if (currentValue?.length === 0) {
      return t("allAnimals")
    } else if (currentValue?.length === 1) {
      return t("oneAnimalSelected")
    } else {
      return `${currentValue?.length} ` + t("animalsSelected")
    }
  }

  return (
    <SelectPill
      style={isSelected() ? selectedStyle : unSelectedStyle}
      ref={ref}
      onClick={onClick}
    >
      {getAssociatedAnimalsInfo()}
    </SelectPill>
  )
})

DrawerAnimalsFilterTrigger.displayName = "DrawerAnimalsFilterTrigger"

export default DrawerAnimalsFilterTrigger
