import { t } from "i18next"
import { useState } from "react"
import { useLocation } from "react-router-dom"
import styles from "./side-menu.module.css"
import FarmIcon from "@/assets/icons/farm/farm-icon.tsx"
import CollapseMenuLeftIcon from "@/assets/icons/menu/collapse-menu-left-icon.tsx"
import CollapseMenuRightIcon from "@/assets/icons/menu/collapse-menu-right-icon.tsx"
import default_logo_icon from "@/assets/icons/menu/default-logo-icon.png"
import MenuCalendarIcon from "@/assets/icons/menu/menu-calendar-icon.tsx"
import MenuDashboardIcon from "@/assets/icons/menu/menu-dashboard-icon.tsx"
import MenuMedKitIcon from "@/assets/icons/menu/menu-med-kit-icon.tsx"
import MenuNotesIcon from "@/assets/icons/menu/menu-notes-icon.tsx"
import MenuSettingsIcon from "@/assets/icons/menu/menu-settings-icon.tsx"
import MenuTasksIcon from "@/assets/icons/menu/menu-tasks-icon.tsx"
import MenuVoiceAssistentIcon from "@/assets/icons/menu/menu-voice-assistent-icon.tsx"
import NotificationMenuIcon from "@/assets/icons/misc/notification-icon.tsx"
import MenuItem from "@/components/menu-item/menu-item"
import { useGetAnimalsQuery } from "@/features/farm/api/animals-api.ts"
import { useGetAnimalsCount } from "@/features/home/hooks/use-get-animals-count.ts"
import { useGetProfileQuery } from "@/features/settings-account/api/account-api.ts"
import {
  FARM_SCREEN_PATH,
  HOME_SCREEN_PATH,
  NOTIFICATIONS_PATH,
  SETTINGS_SCREEN_PATH,
  TASKS_SCREEN_PATH,
} from "@/utils/constants/routes"

function SideMenu() {
  const location = useLocation()
  const [menuOpen, setMenuOpen] = useState(true)
  const { animalsCountString } = useGetAnimalsCount()
  const { data: animals = [] } = useGetAnimalsQuery()
  const { data: profile } = useGetProfileQuery()

  return (
    <div
      className={menuOpen ? styles["side-menu"] : styles["side-menu-closed"]}
    >
      <div className={menuOpen ? styles["top-bar"] : styles["top-bar-closed"]}>
        <div className={styles["dashboard-icon"]}>
          <img
            src={default_logo_icon}
            alt={"farmLogo"}
            className={menuOpen ? styles["full-icon"] : styles["small-icon"]}
          />
        </div>
        <div className={menuOpen ? styles["farm-name"] : styles["hide"]}>
          {profile?.farm?.name}
        </div>
        <div className={menuOpen ? styles["animal-count"] : styles["hide"]}>
          {animalsCountString}
        </div>
      </div>

      <div className={styles["menu-items"]}>
        <MenuItem
          isActive={location.pathname.includes(HOME_SCREEN_PATH)}
          navigateTo={HOME_SCREEN_PATH}
          label={menuOpen ? t("dashboard") : ""}
          alt={t("dashboard")}
          isOpen={menuOpen}
        >
          <MenuDashboardIcon
            is_active={location.pathname.includes(HOME_SCREEN_PATH)}
          />
        </MenuItem>
        <MenuItem
          isActive={location.pathname.includes(FARM_SCREEN_PATH)}
          navigateTo={`${FARM_SCREEN_PATH}/${animals[0]?.id}`}
          label={menuOpen ? t("farmTab") : ""}
          alt={t("farmTab")}
          isOpen={menuOpen}
        >
          <FarmIcon is_active={location.pathname.includes(FARM_SCREEN_PATH)} />
        </MenuItem>
        <MenuItem
          isActive={location.pathname.includes(TASKS_SCREEN_PATH)}
          navigateTo={TASKS_SCREEN_PATH}
          label={menuOpen ? t("tasks") : ""}
          alt={t("tasks")}
          isOpen={menuOpen}
        >
          <MenuTasksIcon
            is_active={location.pathname.includes(TASKS_SCREEN_PATH)}
          />
        </MenuItem>
        <MenuItem
          isActive={location.pathname.includes(NOTIFICATIONS_PATH)}
          navigateTo={NOTIFICATIONS_PATH}
          label={menuOpen ? t("notifications") : ""}
          alt={t("notifications")}
          isOpen={menuOpen}
        >
          <NotificationMenuIcon
            is_active={location.pathname.includes(NOTIFICATIONS_PATH)}
          />
        </MenuItem>
        <MenuItem
          isActive={location.pathname.includes(NOTIFICATIONS_PATH)}
          navigateTo={NOTIFICATIONS_PATH}
          label={menuOpen ? t("notes") : ""}
          alt={t("notes")}
          isOpen={menuOpen}
        >
          <MenuNotesIcon
            is_active={location.pathname.includes(NOTIFICATIONS_PATH)}
          />
        </MenuItem>
        <MenuItem
          isActive={location.pathname.includes(NOTIFICATIONS_PATH)}
          navigateTo={NOTIFICATIONS_PATH}
          label={menuOpen ? t("gestationPeriodCalendar") : ""}
          alt={t("gestationPeriodCalendar")}
          isOpen={menuOpen}
        >
          <MenuCalendarIcon
            is_active={location.pathname.includes(NOTIFICATIONS_PATH)}
          />
        </MenuItem>
        <MenuItem
          isActive={location.pathname.includes(NOTIFICATIONS_PATH)}
          navigateTo={NOTIFICATIONS_PATH}
          label={menuOpen ? t("contactSpecialist") : ""}
          alt={t("contactSpecialist")}
          isOpen={menuOpen}
        >
          <MenuMedKitIcon
            is_active={location.pathname.includes(NOTIFICATIONS_PATH)}
          />
        </MenuItem>
        <MenuItem
          isActive={location.pathname.includes(NOTIFICATIONS_PATH)}
          navigateTo={NOTIFICATIONS_PATH}
          label={menuOpen ? t("voiceAssistant") : ""}
          alt={t("voiceAssistant")}
          isOpen={menuOpen}
        >
          <MenuVoiceAssistentIcon
            is_active={location.pathname.includes(NOTIFICATIONS_PATH)}
          />
        </MenuItem>
        <MenuItem
          isActive={location.pathname.includes(SETTINGS_SCREEN_PATH)}
          navigateTo={SETTINGS_SCREEN_PATH}
          label={menuOpen ? t("settings") : ""}
          alt={t("settings")}
          isOpen={menuOpen}
        >
          <MenuSettingsIcon
            is_active={location.pathname.includes(SETTINGS_SCREEN_PATH)}
          />
        </MenuItem>
      </div>

      <div
        className={styles["open-close-menu"]}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        {menuOpen ? <CollapseMenuRightIcon /> : <CollapseMenuLeftIcon />}
      </div>
    </div>
  )
}

export default SideMenu
