import isEqual from "lodash.isequal"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"
import { type FarmProfile } from "@/features/settings-account"
import { useUpdateMyFarmProfileMutation } from "@/features/settings-account/api/account-api.ts"
import useGetDraftFarmProfile from "@/features/settings-account/hooks/use-get-draft-farm-profile.ts"
import { useGoBackToSettingsHandler } from "@/features/settings-account/hooks/use-go-back-to-settings-handler.tsx"
import FarmEditor from "@/features/settings-account/routes/farm-profile/farm-editor.tsx"
import { farmProfileSchema } from "@/features/settings-account/routes/farm-profile/farm-profile-schema.ts"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

const FarmProfilePage: React.FC = () => {
  const { t } = useTranslation()
  const { handleBackNavigation } = useNavigationFallback()
  const { data, isLoading, draftFarmProfile } = useGetDraftFarmProfile()
  const [updateFarmProfile] = useUpdateMyFarmProfileMutation()

  const validateFarmProfile = async (farmProfile: Partial<FarmProfile>) => {
    try {
      await farmProfileSchema.validate(farmProfile, { abortEarly: false })
      return true
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        toast.error(<CustomErrorToast errors={error.errors} />)
      }
      return false
    }
  }

  const saveChanges = async () => {
    let farmProfileUpdate: Partial<FarmProfile> = {
      name: draftFarmProfile.name,
      description: draftFarmProfile.description,
    }
    if (draftFarmProfile.picture) {
      farmProfileUpdate = {
        ...farmProfileUpdate,
        picture: draftFarmProfile.picture,
      }
    }

    if (await validateFarmProfile(farmProfileUpdate)) {
      toast
        .promise(
          updateFarmProfile({
            id: draftFarmProfile.id,
            farmProfileUpdate,
          }).unwrap(),
          {
            pending: t("processing"),
            success: t("farmProfileUpdated"),
            error: t("errorOccurred"),
          },
        )
        .then(() => {
          handleBackNavigation()
        })
    }
  }

  const hasChanges = !!data && !isEqual(data, draftFarmProfile)

  const { handleBackClick } = useGoBackToSettingsHandler(hasChanges)

  return (
    <>
      <SettingsTopBar
        title={t("farmProfile")}
        onSaveChanges={saveChanges}
        onBackClick={handleBackClick}
        isModified={hasChanges}
      />
      {isLoading && !data ? <LoadingAnimation /> : <FarmEditor />}
    </>
  )
}

export default FarmProfilePage
