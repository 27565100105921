import React, { useEffect, useState } from "react"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import DatePicker from "@/components/task-card-date/date-picker/date-picker.tsx"
import TimePicker from "@/components/task-card-date/time-picker/time-picker.tsx"
import { useAppSelector } from "@/redux/hooks.ts"

interface TaskCardDateProps {
  taskId: string
}

const TaskCardDate: React.FC<TaskCardDateProps> = ({ taskId }) => {
  const draftTaskDueDate = useAppSelector(
    (state) => state.taskDraftSlice.drafts[taskId]?.due_date,
  )
  const [isEnabled, setIsEnabled] = useState<boolean>(false)

  useEffect(() => {
    if (draftTaskDueDate) {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
  }, [draftTaskDueDate])

  const [isOpened, setIsOpened] = useState(false)

  const handleEnableChange = (enabled: boolean) => {
    setIsEnabled(enabled)
  }

  const handleDateOpenChange = (opened: boolean) => {
    setIsOpened(opened)
  }

  return (
    <OverflowMenuItem>
      <DatePicker
        value={draftTaskDueDate}
        isOpened={isOpened}
        onOpenChange={handleDateOpenChange}
        isEnabled={isEnabled}
        onEnableChange={handleEnableChange}
        key={draftTaskDueDate}
        taskId={taskId}
      />
      <div
        style={{
          width: "calc(100% + 16px)",
          height: "0.5px",
          backgroundColor: "#e0e0e0",
        }}
      ></div>
      <TimePicker
        onOpenChange={handleDateOpenChange}
        isEnabled={isEnabled}
        taskId={taskId}
      />
    </OverflowMenuItem>
  )
}

export default TaskCardDate
