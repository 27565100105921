import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import styles from "./editor-page.module.css"
import NoteOverlay from "./note-overlay.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoteTopBar from "@/components/top-bars/note-top-bar/note-top-bar.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { checkNoteChanges, shareNote } from "@/features/notes"
import { useUpdateNoteMutation } from "@/features/notes/api/notes-api.ts"
import NoteEditor, {
  type EditorHandles,
} from "@/features/notes/components/editor/note-editor.tsx"
import useGetDraftNote from "@/features/notes/hooks/use-get-draft-note.ts"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import { deleteNotesDraft } from "@/redux/slices/notes-draft-slice.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"

export const NoteEditorView: React.FC = () => {
  const { handleBackNavigation } = useNavigationFallback()
  const { t } = useTranslation()
  const { noteId } = useParams<{ noteId: string }>() || ""
  const editorRef = useRef<EditorHandles | null>(null)
  const [isNoteOverlayOpen, setIsNoteOverlayOpen] = useState(false)
  const [canUndo, setCanUndo] = useState(false)
  const [canRedo, setCanRedo] = useState(false)
  const [updateNote] = useUpdateNoteMutation()
  const { draftNote, data, isLoading } = useGetDraftNote(noteId)
  const dispatch = useAppDispatch()
  const { openModal } = useModal()

  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      dispatch(deleteNotesDraft(noteId as string))
    }, DELETE_ENTRY_TIMEOUT)
  }, [dispatch, noteId])

  const hasChanges = !!draftNote && !!data && checkNoteChanges(data, draftNote)

  useEffect(() => {
    const checkUndoRedoAvailability = () => {
      if (editorRef.current) {
        setCanUndo(editorRef.current.canUndo())
        setCanRedo(editorRef.current.canRedo())
      }
    }

    const intervalId = setInterval(checkUndoRedoAvailability, 100)

    return () => clearInterval(intervalId)
  }, [])

  useLayoutEffect(() => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("continueWhereYouLeftOff"),
        content: t("youHaveUnsavedChanges"),
        onBackText: t("discard"),
        onActionText: t("continue"),
        onBackClick: () => {
          deleteDraft()
        },
      })
    }
    // Run on initial render only
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDraft, openModal, t])

  const handleUpdateNote = () => {
    if (!draftNote || !noteId) {
      return
    }
    toast
      .promise(updateNote(draftNote).unwrap(), {
        pending: t("updatingNote"),
        success: t("noteUpdated"),
        error: t("noteUpdateError"),
      })
      .then(() => {
        handleBackNavigation()
        deleteDraft()
      })
  }

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          handleBackNavigation()
          deleteDraft()
        },
      })
    } else {
      handleBackNavigation()
      deleteDraft()
    }
  }

  const handleShare = async () => {
    await shareNote(draftNote)
  }

  return (
    <div className={styles.editorBody}>
      <NoteTopBar
        onBack={handleBackClick}
        onSave={handleUpdateNote}
        onToggleOverlay={() => setIsNoteOverlayOpen((prev) => !prev)}
        isNew={false}
        hasChanges={hasChanges}
        onUndo={() => editorRef.current?.undo()}
        onRedo={() => editorRef.current?.redo()}
        canUndo={canUndo}
        canRedo={canRedo}
      />

      {isLoading || !noteId || !draftNote ? (
        <LoadingAnimation />
      ) : (
        <>
          <NoteEditor
            ref={editorRef}
            isNewNote={false}
            noteId={noteId}
            draftNote={draftNote}
          />

          {isNoteOverlayOpen && (
            <NoteOverlay
              onClose={() => setIsNoteOverlayOpen(false)}
              onShare={handleShare}
            />
          )}
        </>
      )}
    </div>
  )
}
