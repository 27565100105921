import { apiSlice, TagType } from "@/features/api/apiSlice.ts"

const TASK_CATEGORIES_BASE_URL = "task-categories/"

export interface TaskCategory {
  name: string
  id: string
  picture: string | null
  farm: string | null
}

export const tasksCategoriesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTaskCategories: builder.query<TaskCategory[], void>({
      query: () => ({
        url: TASK_CATEGORIES_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.TaskCategories, id: "LIST" }],
    }),
    createTaskCategory: builder.mutation<TaskCategory, Partial<TaskCategory>>({
      query: (taskCategory) => {
        return {
          url: TASK_CATEGORIES_BASE_URL,
          method: "POST",
          body: taskCategory,
        }
      },
      invalidatesTags: [{ type: TagType.TaskCategories, id: "LIST" }],
    }),
    updateTaskCategory: builder.mutation<
      TaskCategory,
      Partial<TaskCategory> & { id: string }
    >({
      query: ({ id, ...patch }) => ({
        url: `${TASK_CATEGORIES_BASE_URL}${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: [{ type: TagType.TaskCategories, id: "LIST" }],
    }),
    deleteTaskCategory: builder.mutation<void, string>({
      query: (categoryId) => ({
        url: `${TASK_CATEGORIES_BASE_URL}${categoryId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TagType.TaskCategories, id: "LIST" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTaskCategoriesQuery,
  useCreateTaskCategoryMutation,
  useDeleteTaskCategoryMutation,
  useUpdateTaskCategoryMutation,
} = tasksCategoriesApi
