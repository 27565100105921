import React from "react"
import { useTranslation } from "react-i18next"
import AddNoteIcon from "@/assets/icons/misc/add-note-icon.tsx"
import styles from "@/features/finance/components/finance-category-card/finance-category-card.module.css"
import { GREY } from "@/utils/constants/colors-constants.ts"

interface AddFinanceCategoryCardProps {
  onClick?: () => void
}

const AddFinanceCategoryCard: React.FC<AddFinanceCategoryCardProps> = ({
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.financeCategoryCardWrapper} onClick={onClick}>
      <AddNoteIcon color={GREY} size={50} />
      <p className={styles.categoryName}>{t("ADD_CATEGORY")}</p>
    </div>
  )
}

export default AddFinanceCategoryCard
