import { getAnimalsByAgeRange } from "@/features/farm"
import { useGetAnimalsQuery } from "@/features/farm/api/animals-api.ts"

export default function useGetCowsAgeIntervals() {
  const { data: animals = [] } = useGetAnimalsQuery()
  const cattle = animals.filter(
    (animal) => animal?.species_details?.name === "Cow",
  )

  const calfs = getAnimalsByAgeRange(cattle, 0, 2)
  const heifers = getAnimalsByAgeRange(cattle, 2, 3)
  const cows = getAnimalsByAgeRange(cattle, 3, 100)

  return {
    calfs,
    heifers,
    cows,
    animalsLength: animals.length,
    cowsNumber: cattle.length,
  }
}
