import { useTranslation } from "react-i18next"
import styles from "./gestation-calendar-accordion.module.css"
import GestationCalendarActionButton from "@/features/gestational-calendar/components/gestation-calendar-action-button/gestation-calendar-action-button.tsx"
import ArrowDown from "@/assets/icons/misc/arrow-down.tsx"

interface AccordionProps {
  onClick: () => void
}

const GestationCalendarAccordionSummary: React.FC<AccordionProps> = ({
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.accordionWrapperPrimary} data-is-done={true}>
      <div className={styles.accordionTrigger}>
        <div className={styles.triggerContent}>
          <div className={styles.title}>{"Perioada anterioarǎ fǎtǎrii"}</div>
        </div>
      </div>
      <div className={styles.accordionContentWrapper} data-is-opened={true}>
        <div className={styles.accordionContent}>
          {"Perioada anterioarǎ fǎtǎrii a fost completatǎ cu succes !"}
          <GestationCalendarActionButton
            onClick={onClick}
            text={t("seeEvents")}
            icon={<ArrowDown />}
          />
        </div>
      </div>
    </div>
  )
}

export default GestationCalendarAccordionSummary
