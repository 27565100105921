import { toast } from "react-toastify"
import * as Yup from "yup"
import { type Animal } from "@/features/farm"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

export const animalSchema = Yup.object().shape({
  ain: Yup.string().required("ainRequired"),
  gender: Yup.string().required("genderRequired"),
  birth_date: Yup.date()
    .nullable()
    .transform((_value, originalValue) =>
      originalValue === null ||
      originalValue === "" ||
      isNaN(new Date(originalValue).getTime())
        ? null
        : new Date(originalValue),
    )
    .max(new Date(), "birthDateCannotBeInTheFuture")
    .required("birthDateRequired"),
  entry_date: Yup.date()
    .nullable()
    .transform((_value, originalValue) =>
      originalValue === null ||
      originalValue === "" ||
      isNaN(new Date(originalValue).getTime())
        ? null
        : new Date(originalValue),
    )
    .max(new Date(), "entryDateCannotBeInTheFuture")
    .required("entryDateRequired")
    .when("birth_date", {
      is: (birth_date: Date | null) => birth_date !== null,
      then: (schema) =>
        schema.min(Yup.ref("birth_date"), "entryDateMustBeLaterThanBirthDate"),
    }),
  entry_way: Yup.string().required("entryWayIsRequired"),
  intended_purpose: Yup.string().required("intendedPurposeIsRequired"),
  species: Yup.string().required("speciesRequired"),
  breed: Yup.string().required("breedIsRequired"),
})

export const validateAnimal = async (animal: Partial<Animal> = {}) => {
  try {
    await animalSchema.validate(animal, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
