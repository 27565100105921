import { skipToken } from "@reduxjs/toolkit/query"
import { useLayoutEffect } from "react"
import { useGetAnimalByIdQuery } from "@/features/farm/api/animals-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setAnimalDraft } from "@/redux/slices/animal-draft-slice.ts"

const useGetAnimalDraft = (animalId?: string) => {
  const { data, isLoading } = useGetAnimalByIdQuery(animalId || skipToken)
  const dispatch = useAppDispatch()

  const draftAnimal = useAppSelector(
    (state) => state.animalDraftSlice.drafts[animalId || ""],
  )

  useLayoutEffect(() => {
    if (data && animalId && !draftAnimal) {
      dispatch(setAnimalDraft({ id: animalId, draft: data }))
    }
  }, [animalId, data, dispatch, draftAnimal])

  return { draftAnimal, data, isLoading }
}

export default useGetAnimalDraft
