import React from "react"
import { useNavigate } from "react-router-dom"
import { useGetEventsForAnimalQuery } from "@/features/animal-events/api/animal-events-api.ts"
import EventTable from "@/features/animal-events/components/events-table/events-table.tsx"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import styles from "@/features/home/components/tasks-widget/tasks-widget.module.css"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import {
  ANIMAL_EVENT_SCREEN_PATH,
  NEW_ANIMAL_EVENT_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface EventsWidgetProps {
  animalId: string
}

const EventsWidget: React.FC<EventsWidgetProps> = ({ animalId }) => {
  const navigate = useNavigate()
  const navigationString =
    NEW_ANIMAL_EVENT_SCREEN_PATH + (animalId ? `?animalId=${animalId}` : "")
  const { data: events } = useGetEventsForAnimalQuery(animalId)

  const addItemGoTo = () => {
    navigate(navigationString)
  }

  return (
    <WidgetCard
      title={"events"}
      navigateTo={ANIMAL_EVENT_SCREEN_PATH + "?selectedAnimal=" + animalId}
      {...(events && events.length > 0 && { addItemGoTo })}
    >
      {events && events.length > 0 && <EventTable events={events} />}
      {!events ||
        (events.length === 0 && (
          <div className={styles["tasks-flex-desktop"]}>
            <EmptyContentCreateItemButton navigateTo={navigationString} />
          </div>
        ))}
    </WidgetCard>
  )
}

export default EventsWidget
