import TimelineTuple from "@/components/timeline/timeline-tuple/timeline-tuple.tsx"
import {
  type CalendarActivityState,
  type GestationCalendar,
} from "@/features/gestational-calendar/types/calendar-types.ts"

interface CalendarActivityTimelineSectionProps {
  gestationCalendar: GestationCalendar
  indexRange: number[]
  forcedActivityState?: CalendarActivityState
}

const CalendarActivityTimelineSection: React.FC<
  CalendarActivityTimelineSectionProps
> = ({ gestationCalendar, indexRange, forcedActivityState }) => {
  const lastIndex = indexRange[indexRange.length - 1]

  return (
    <div>
      {indexRange.map((activityIndex, i) => {
        return (
          <TimelineTuple
            key={i}
            gestationalCalendar={gestationCalendar}
            index={activityIndex}
            lastIndex={lastIndex}
            forcedActivityState={forcedActivityState}
          />
        )
      })}
    </div>
  )
}

export default CalendarActivityTimelineSection
