import styles from "./generic-items-sub-group.module.css"
import AnimalEventsCard from "@/features/animal-events/components/animal-events-card/animal-events-card.tsx"
import TransactionCard from "@/features/finance/components/transaction-card/transaction-card.tsx"
import { type ItemsArray } from "@/utils/helpers/time-grouping.ts"

interface GenericItemsSubGroupProps {
  items: ItemsArray
  title: string
}

const GenericItemsSubGroup: React.FC<GenericItemsSubGroupProps> = ({
  items,
  title,
}) => {
  return (
    <div>
      <div className={styles.title}>{title}</div>
      {items.map((item, key) => {
        if ("animal" in item) {
          return <AnimalEventsCard animalEvent={item} key={key} />
        }
        if ("currency" in item) {
          return <TransactionCard transaction={item} key={key} />
        }
      })}
    </div>
  )
}

export default GenericItemsSubGroup
