import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuCowIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="141 72.467 60 60"
    >
      <g data-name="Group 4430">
        <path
          d="M141 102.417a30 30 0 1 1 0 .1z"
          fill="var(--white)"
          fillRule="evenodd"
          data-name="Ellipse 507"
        />
        <g data-name="Group 2765">
          <path
            d="M186.907 106.009c0-3.015-1.664-5.682-4.28-7.074 1.784-2.319 3.092-5.102 3.092-8.348 0-1.16-.25-1.536-.343-2.608 0 0-1.366-2.967-3.558-.34-1.427 5.566-3.35 8.745-9.295 8.745h-2.14c-5.943 0-7.964-3.322-9.271-8.888 0 0-2.14-1.995-3.257-.29.124-.223.05-.111-.016.034-.613 1.36-.65 2.187-.65 3.347 0 3.246 1.307 6.03 3.09 8.464-2.615 1.392-4.279 3.943-4.279 6.958 1.902 0 3.685-.696 5.112-1.74l1.307 10.436c.357 2.783 2.734 4.755 5.587 4.755h6.895c2.853 0 5.23-2.088 5.587-4.755l1.308-10.436c1.426 1.16 3.21 1.74 5.111 1.74Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="2.5"
            stroke="var(--menu-my-farm-color)"
            fill="transparent"
            data-name="Path 1402"
          />
          <path
            d="M163.132 112.618h16.643"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="2.5"
            stroke="var(--menu-my-farm-color)"
            fill="transparent"
            data-name="Line 156"
          />
          <path
            d="M166.58 101.455c-.869 0-1.573.685-1.573 1.533 0 .846.704 1.534 1.573 1.534.868 0 1.572-.688 1.572-1.534 0-.848-.704-1.533-1.572-1.533Z"
            fill="var(--menu-my-farm-color)"
            fillRule="evenodd"
            data-name="Path 1421"
          />
          <path
            d="M176.327 101.455c-.868 0-1.572.685-1.572 1.533 0 .846.704 1.534 1.572 1.534.869 0 1.572-.688 1.572-1.534 0-.848-.703-1.533-1.572-1.533Z"
            fill="var(--menu-my-farm-color)"
            fillRule="evenodd"
            data-name="Path 1422"
          />
        </g>
      </g>
    </svg>
  )
}

export default MenuCowIcon
