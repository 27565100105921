import React, { forwardRef, useImperativeHandle, useRef } from "react"
import styles from "./page-wrapper-content.module.css"
import { useScrollRestoration } from "@/hooks/use-scroll-restoration.ts"

interface DrawerContentWrapperProps {
  children: React.ReactNode
  hasPadding?: boolean
  style?: React.CSSProperties
}

const PageContentWrapperLarge = forwardRef<
  HTMLDivElement,
  DrawerContentWrapperProps
>(({ children, hasPadding = true, style }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null)

  useScrollRestoration(internalRef)

  useImperativeHandle(ref, () => internalRef.current!)

  return (
    <div
      ref={internalRef}
      className={styles.scrollableContentLarge}
      style={{
        padding: hasPadding ? "16px" : 0,
        ...style,
      }}
    >
      {children}
    </div>
  )
})

export default PageContentWrapperLarge
