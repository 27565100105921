import { addDays, format } from "date-fns"
import { ro } from "date-fns/locale"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import HorizontalScrollComponentWrapperFlexible from "@/features/home/components/horizontal-scroll-component-wrapper-flexible/horizontal-scroll-component-wrapper-flexible.tsx"
import DayCard from "@/features/home/components/weather-widget/day-card.tsx"
import useWeekDailyWeather from "@/features/home/hooks/use-week-daily-weather"

export default function WeatherWidget() {
  const { t } = useTranslation()
  const { weeklyData, error } = useWeekDailyWeather()

  const orderedDays = useMemo(() => {
    return weeklyData.minTemperatures.map((_, index) => {
      const day = addDays(new Date(), index)
      return {
        date: day,
        dayKey: format(day, "yyyy-MM-dd"),
        minTemperature: weeklyData.minTemperatures[index],
        maxTemperature: weeklyData.maxTemperatures[index],
        cloudCoverage: weeklyData.cloudCoverages[index],
      }
    })
  }, [weeklyData])

  return (
    <HorizontalScrollComponentWrapperFlexible title={t("weather")}>
      {!error &&
        orderedDays.map((dayInfo) => (
          <DayCard
            key={dayInfo.dayKey}
            currentDate={dayInfo.date}
            currentDateName={format(dayInfo.date, "EEEE", { locale: ro })}
            minTemperature={dayInfo.minTemperature}
            cloudCoverage={dayInfo.cloudCoverage}
            maxTemperature={dayInfo.maxTemperature}
          />
        ))}
    </HorizontalScrollComponentWrapperFlexible>
  )
}
