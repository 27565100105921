import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./task-editor.module.css"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item"
import TaskCardDate from "@/components/task-card-date/task-card-date"
import AssociatedAnimalsList from "@/features/farm/components/associated-animals-list/associated-animals-list"
import DrawerAnimals from "@/features/farm/components/drawer-animals/drawer-animals"
import AssociatedNotesList from "@/features/notes/components/associated-notes-list/associated-notes-list"
import DrawerNotesContextWrapper from "@/features/notes/components/drawer-notes/drawer-notes-context-wrapper"
import { type TaskDraft } from "@/features/tasks"
import { useGetTaskCategoriesQuery } from "@/features/tasks/api/task-categories-api.ts"
import DrawerAnimalsAssocTrigger from "@/features/tasks/components/drawer-animals-assoc-trigger/drawer-animals-assoc-trigger"
import RemindersCard from "@/features/tasks/components/reminders-card/reminders-card"
import TaskCategorySelectDrawer from "@/features/tasks/components/task-category-select-drawer/task-category-select-drawer.tsx"
import { useAppDispatch } from "@/redux/hooks"
import {
  setDraftTaskTitle,
  setDraftTaskDescription,
  setDraftTaskCategory,
  setDraftTaskAnimals,
  setDraftTaskNotes,
  setDraftTaskReminder,
} from "@/redux/slices/task-draft-slice"
import { InputType } from "@/utils/enums/input-type-enum"
import { useParams } from "react-router-dom"

interface TaskEditorProps {
  taskId: string
  draftTask: TaskDraft
}

const TaskEditor: React.FC<TaskEditorProps> = ({ taskId, draftTask }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { data: taskCategories = [] } = useGetTaskCategoriesQuery()
  const { animalId } = useParams()

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDraftTaskTitle({ id: taskId, title: event.target.value }))
  }

  const handleDescriptionChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    dispatch(
      setDraftTaskDescription({ id: taskId, description: event.target.value }),
    )
  }

  const handleCategoryChange = (category: string | null) => {
    dispatch(setDraftTaskCategory({ id: taskId, category }))
  }

  const handleNotesChange = (noteIds: string[]) => {
    dispatch(setDraftTaskNotes({ id: taskId, notes: noteIds }))
  }

  const handleAnimalsChange = (animalIds: string[]) => {
    dispatch(setDraftTaskAnimals({ id: taskId, animals: animalIds }))
  }

  const handleReminderChange = (reminder: number | null) => {
    dispatch(setDraftTaskReminder({ id: taskId, reminder }))
  }

  return (
    <div className={styles.editorView}>
      <OverflowMenuItem>
        <input
          name={t("title")}
          alt={t("title")}
          className={styles.titleInput}
          type={InputType.TEXT}
          placeholder={t("title")}
          value={draftTask.title}
          readOnly={false}
          onChange={handleTitleChange}
        />
        <div className={styles.separator}></div>
        <textarea
          name={t("description")}
          aria-label={t("description")}
          className={styles.contentInput}
          placeholder={t("description")}
          rows={6}
          disabled={false}
          value={draftTask.description}
          onChange={handleDescriptionChange}
        />
      </OverflowMenuItem>

      <OverflowMenuItem>
        <TaskCategorySelectDrawer
          taskCategories={taskCategories}
          onTaskCategoryChange={handleCategoryChange}
          taskCategoryId={draftTask.category}
        />
      </OverflowMenuItem>

      <TaskCardDate taskId={taskId} />

      <OverflowMenuItem isDisabled={!draftTask.due_date}>
        <RemindersCard
          reminder={draftTask.reminder}
          onReminderChange={handleReminderChange}
        />
      </OverflowMenuItem>

      <OverflowMenuItem>
        <DrawerNotesContextWrapper
          currentValue={draftTask.notes}
          onSaveClick={handleNotesChange}
        />
        {draftTask.notes.length > 0 && (
          <AssociatedNotesList
            notes={draftTask.notes}
            onNotesChange={handleNotesChange}
          />
        )}
      </OverflowMenuItem>

      <OverflowMenuItem isDisabled={!!animalId}>
        <DrawerAnimals
          currentValue={draftTask.animals}
          onSaveClick={handleAnimalsChange}
          TriggerComponent={DrawerAnimalsAssocTrigger}
        />
        {draftTask.animals.length > 0 && (
          <AssociatedAnimalsList
            animals={draftTask.animals}
            onAnimalsChange={handleAnimalsChange}
          />
        )}
      </OverflowMenuItem>
    </div>
  )
}

export default TaskEditor
