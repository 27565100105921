import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const PartlySunny: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="480.534 410.183 45 36.817"
    >
      <g data-name="partly-sunny-outline-svgrepo-com">
        <path
          d="M516.071 430.103a6.996 6.996 0 0 0 2.399-7.738 7.053 7.053 0 0 0-6.531-4.836 7.058 7.058 0 0 0-6.768 4.503"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2.5"
          stroke="#f0a900"
          fill="transparent"
          data-name="Path 1521"
        />
        <path
          d="M522.91 424.793h3.149"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2.5"
          stroke="#f0a900"
          fill="transparent"
          data-name="Line 173"
        />
        <path
          d="M511.368 410.183v3.13"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2.5"
          stroke="#f0a900"
          fill="transparent"
          data-name="Line 174"
        />
        <path
          d="m501.784 414.615 1.997 1.985"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2.5"
          stroke="#f0a900"
          fill="transparent"
          data-name="Line 175"
        />
        <path
          d="m521.748 414.615-1.997 1.985"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2.5"
          stroke="#f0a900"
          fill="transparent"
          data-name="Line 176"
        />
        <path
          d="M490.117 433.213a1.414 1.414 0 0 0 1.181-1.162c1.067-6.443 5.79-10.318 11.293-10.318 5.11 0 8.524 3.312 9.898 6.82.18.458.59.787 1.077.867 4.411.715 8.081 3.644 8.081 8.737 0 5.211-4.288 8.843-9.528 8.843h-20.644c-4.367 0-7.94-2.167-7.94-6.948 0-4.253 3.411-6.336 6.582-6.839Z"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="2.5"
          stroke="var(--blue)"
          fill="transparent"
          data-name="Path 1520"
        />
      </g>
    </svg>
  )
}

export default PartlySunny
