import React, { type ChangeEvent, useEffect, useRef, useState } from "react"
import styles from "@/features/finance/components/amount-input/amount-input.module.css"

interface AmountInputProps {
  value: string
  currency: string
  is_outflow: boolean
  onCurrencyChange: (newCurrency: string) => void
  onChange: (newAmount: string) => void
}

function addDecimalPoint(numberString: string): string {
  if (numberString.length < 3) {
    return numberString.padStart(3, "0").replace(/(\d{2})$/, ".$1")
  }
  return numberString.replace(/(\d{2})$/, ".$1")
}

const AmountInput: React.FC<AmountInputProps> = ({
  value,
  onChange,
  is_outflow,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const [internalValue, setInternalValue] = useState(
    cleanTextAndKeepNumbersOnly(value),
  )

  useEffect(() => {
    setInternalValue(cleanTextAndKeepNumbersOnly(value))
  }, [value])

  function cleanTextAndKeepNumbersOnly(text: string) {
    return text.replace(/\D/g, "")
  }

  function formatNumber(number: string) {
    if (number.length <= 4 && number.startsWith("0")) {
      number = number.substring(1)
      const integerPart = number.slice(0, -2) || "0"
      const decimalPart = number.slice(-2).padStart(2, "0")
      return `${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalPart}`
    }

    const integerPart = number.slice(0, -2) || "0"
    const decimalPart = number.slice(-2).padStart(2, "0")
    return `${integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}.${decimalPart}`
  }

  function handleAmountChange(event: ChangeEvent<HTMLInputElement>) {
    const rawValue = cleanTextAndKeepNumbersOnly(event.target.value)
    setInternalValue(rawValue)
    onChange(addDecimalPoint(rawValue))
  }

  useEffect(() => {
    if (inputRef.current) {
      const { selectionStart, selectionEnd } = inputRef.current
      inputRef.current.value = formatNumber(internalValue)
      inputRef.current.setSelectionRange(selectionStart, selectionEnd)
    }
  }, [internalValue])

  const handleFocus = () => {
    inputRef.current?.focus()
  }

  return (
    <div onClick={handleFocus} className={styles.inputContainer}>
      {is_outflow && <p className={styles.minus}>-</p>}
      <div className={styles.inputWrapper}>
        <input
          type="text"
          value={formatNumber(internalValue)}
          className={styles.amountInput}
          onChange={handleAmountChange}
          inputMode="numeric"
          placeholder="0.00"
          ref={inputRef}
          maxLength={12}
          style={{
            minWidth: "2ch",
            textAlign: "right",
            width: `${formatNumber(internalValue).length}ch`,
          }}
        />
        <div className={styles.currency}>lei</div>
      </div>
    </div>
  )
}

export default AmountInput
