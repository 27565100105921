import React from "react"
import { useTranslation } from "react-i18next"
import AssocNoteIcon2 from "@/assets/icons/task/assoc-note-icon2.tsx"
import styles from "@/components/shadcn/drawer/custom-drawer.module.css"

interface CustomTriggerProps {
  currentValue: string[]
  onClick: () => void
}

const DrawerNotesAssocTrigger = React.forwardRef<
  HTMLDivElement,
  CustomTriggerProps
>(({ currentValue, onClick }, ref) => {
  const { t } = useTranslation()

  function getAssociatedNotesInfo() {
    if (currentValue?.length === 0) {
      return t("noNoteAssociated")
    } else if (currentValue?.length === 1) {
      return t("oneNoteAssociated")
    } else {
      return `${currentValue?.length} ` + t("notesAssociated")
    }
  }

  return (
    <div className={styles["task-card-item"]} ref={ref} onClick={onClick}>
      <div className={styles["task-card-header"]}>
        <div className={styles["icon-wrapper"]}>
          <AssocNoteIcon2 />
          <div>
            <p>{t("AssociateNote")}</p>
            <p className={styles["info"]}>{getAssociatedNotesInfo()}</p>
          </div>
        </div>
      </div>
    </div>
  )
})

DrawerNotesAssocTrigger.displayName = "DrawerNotesAssocTrigger"

export default DrawerNotesAssocTrigger
