import React from "react"

const PersonIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="29.276"
      viewBox="0 0 31 29.276"
      {...props}
    >
      <g id="person-svgrepo-com" transform="translate(-6.5 -7.847)">
        <path
          id="Path_774"
          data-name="Path 774"
          d="M23.509,22.2a6.289,6.289,0,1,0-6.229-6.288A6.257,6.257,0,0,0,23.509,22.2Zm8.009-6.288a8.009,8.009,0,1,1-8.009-8.068A8.036,8.036,0,0,1,31.518,15.915Z"
          transform="translate(-1.509)"
          fill="var(--brown)"
          fillRule="evenodd"
        />
        <path
          id="Path_775"
          data-name="Path 775"
          d="M16.941,29.73a.938.938,0,0,0-1.074-.531C11.214,30.38,6.5,32.79,6.5,36.415v2.254a1.752,1.752,0,0,0,1.722,1.78H35.778a1.752,1.752,0,0,0,1.722-1.78V36.415c0-3.625-4.714-6.035-9.368-7.216a.938.938,0,0,0-1.074.531l-3.5,2.67H20.441Zm11.276,1.328-4.092,3.121H19.874l-4.092-3.122a18.5,18.5,0,0,0-5.393,2.317c-1.57,1.073-2.167,2.114-2.167,3.04v2.254H35.778V36.415c0-.927-.6-1.967-2.167-3.04A18.5,18.5,0,0,0,28.218,31.058Z"
          transform="translate(0 -3.326)"
          fill="var(--brown)"
          fillRule="evenodd"
        />
      </g>
    </svg>
  )
}

export default PersonIcon
