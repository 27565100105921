import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import BirthdayIcon from "@/assets/icons/farm/birthday-icon.tsx"
import DateIcon from "@/assets/icons/task/date-icon.tsx"
import DateSelectCard from "@/components/task-card-date/date-select-card/date-select-card.tsx"
import { type AnimalDraft } from "@/features/farm"

interface AnimalDateSelectGroupProps {
  receivedAnimal: AnimalDraft
  handleBirthDateChange: (date: string) => void
  handleEntryDateChange: (date: string) => void
}

const AnimalDateSelectGroup: React.FC<AnimalDateSelectGroupProps> = ({
  receivedAnimal,
  handleBirthDateChange,
  handleEntryDateChange,
}) => {
  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState({
    birthDay: false,
    entryDate: false,
  })

  const handleOpenBirthDay = () => {
    setIsOpen({ ...isOpen, birthDay: !isOpen.birthDay, entryDate: false })
  }

  const handleOpenEntryDate = () => {
    setIsOpen({ ...isOpen, entryDate: !isOpen.entryDate, birthDay: false })
  }

  return (
    <>
      <DateSelectCard
        isOpen={isOpen.birthDay}
        setIsOpen={handleOpenBirthDay}
        isRequired={receivedAnimal.birth_date === ""}
        icon={<BirthdayIcon />}
        placeholder={t("birthDate")}
        info={receivedAnimal.birth_date}
        onChange={handleBirthDateChange}
      />

      <DateSelectCard
        isOpen={isOpen.entryDate}
        setIsOpen={handleOpenEntryDate}
        isRequired={receivedAnimal.entry_date === ""}
        icon={<DateIcon fill={"#C5B6A9"} />}
        placeholder={t("exploitEntryDate")}
        info={receivedAnimal.entry_date}
        onChange={handleEntryDateChange}
      />
    </>
  )
}

export default AnimalDateSelectGroup
