import { type Note } from "@/features/notes"

export const stripHtmlAndTruncate = (
  htmlContent: string,
  maxLength: number = 30,
) => {
  const strippedString = htmlContent.replace(/<[^>]*>?/gm, "")
  return strippedString.length > maxLength
    ? strippedString.substring(0, maxLength) + "..."
    : strippedString
}

export const extractNoteContentPlainText = (
  note: Note,
  length: number = 40,
) => {
  let content = note.content

  const matches = content.match(/<p>(.*?)<\/p>/g)
  if (matches && matches[1]) {
    const matchResult = matches[1].match(/<p>(.*?)<\/p>/)
    if (matchResult && matchResult[1].trim() !== "") {
      content = matchResult[1]
    } else {
      content = ""
    }
  }

  return stripHtmlAndTruncate(content, length)
}
