import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import styles from "../date-time-pickers.module.css"
import DateIcon from "@/assets/icons/task/date-icon.tsx"
import Calendar from "@/components/shadcn/calendar/calendar.tsx"
import Switch from "@/components/shadcn/switch/switch.tsx"
import {
  convertToUTCWithoutMilliseconds,
  roundDateToNearestInterval,
} from "@/components/task-card-date/picker-utils.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import { setDraftTaskDueDate } from "@/redux/slices/task-draft-slice.ts"

interface DatePickerProps {
  isOpened: boolean
  onOpenChange: (opened: boolean) => void
  isEnabled: boolean
  value: string | null
  onEnableChange: (enabled: boolean) => void
  taskId: string
}

const DatePicker: React.FC<DatePickerProps> = ({
  isOpened,
  onOpenChange,
  isEnabled,
  onEnableChange,
  value,
  taskId,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const selectedDate = value
    ? new Date(value)
    : roundDateToNearestInterval(new Date(Date.now()))

  const handleEnableChange = (checked: boolean) => {
    onOpenChange(checked)
    onEnableChange(checked)
  }

  const handleOpenPicker = () => {
    if (!isEnabled) return
    onOpenChange(!isOpened)
  }

  const handleDueDateChange = (newDate: Date | undefined) => {
    if (newDate) {
      newDate.setHours(selectedDate.getHours())
      newDate.setMinutes(selectedDate.getMinutes())
      newDate.setSeconds(0)
      newDate.setMilliseconds(0)

      const stringDate = convertToUTCWithoutMilliseconds(newDate)

      dispatch(setDraftTaskDueDate({ id: taskId, due_date: stringDate }))
    } else {
      dispatch(setDraftTaskDueDate({ id: taskId, due_date: null }))
    }
  }

  const handleSwitchClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    if (!isEnabled) {
      selectedDate.setSeconds(0)
      selectedDate.setMilliseconds(0)
      const stringDate = convertToUTCWithoutMilliseconds(selectedDate)

      dispatch(setDraftTaskDueDate({ id: taskId, due_date: stringDate }))
    }
  }

  return (
    <div className={styles["task-card-item"]}>
      <div className={styles["task-card-header"]} onClick={handleOpenPicker}>
        <div className={styles["icon-wrapper"]}>
          <DateIcon />
          <div>
            <p>{t("Data")}</p>
            {isEnabled && selectedDate && (
              <p className={styles["time-info"]}>
                {format(selectedDate, "dd.MM.yyyy")}
              </p>
            )}
          </div>
        </div>

        <Switch
          checked={isEnabled}
          onCheckedChange={handleEnableChange}
          onClick={handleSwitchClick}
        />
      </div>
      {isOpened && (
        <div className={styles["calendar-wrapper"]}>
          <Calendar
            mode={"single"}
            selected={selectedDate}
            defaultMonth={selectedDate}
            onSelect={handleDueDateChange}
          />
          <div className={styles["calendar-footer"]}>
            <button
              className={styles["calendar-button"]}
              onClick={() => onOpenChange(false)}
            >
              Ok
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default DatePicker
