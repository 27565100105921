import React from "react"
import ChooseSomethingIcon from "@/assets/icons/finance/choose-something-icon.tsx"
import GenericSelectTrigger from "@/components/drawer-triggers/generic-drawer-trigger.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { type TransactionCategory } from "@/features/finance/types/transaction.ts"

interface TransactionCategorySelectTriggerProps {
  currentValue: TransactionCategory | undefined
  onClick?: () => void
}

const TransactionCategorySelectTrigger = React.forwardRef<
  HTMLDivElement,
  TransactionCategorySelectTriggerProps
>(({ onClick, currentValue }, ref) => {
  const displayText = (
    currentValue: TransactionCategory | undefined,
    t: (key: string) => string,
  ) => {
    return currentValue && currentValue.name !== ""
      ? t(currentValue.name)
      : t("selectTransactionCategory")
  }

  const displayIcon = (currentValue: TransactionCategory | undefined) => {
    return currentValue?.picture ? (
      <GenericPicture
        src={currentValue.picture}
        alt={"category"}
        size={30}
        borderRadius={currentValue.farm ? 8 : 0}
      />
    ) : (
      <ChooseSomethingIcon size={30} />
    )
  }

  return (
    <GenericSelectTrigger
      ref={ref}
      onClick={onClick}
      currentValue={currentValue}
      displayText={displayText}
      displayIcon={displayIcon}
    />
  )
})

TransactionCategorySelectTrigger.displayName =
  "TransactionCategorySelectTrigger"

export default TransactionCategorySelectTrigger
