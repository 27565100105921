import { skipToken } from "@reduxjs/toolkit/query"
import { useLayoutEffect } from "react"
import { useGetAnimalEventByIdQuery } from "@/features/animal-events/api/animal-events-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setEventsDraft } from "@/redux/slices/events-draft-slice.ts"

const useGetDraftAnimalEvent = (animalEventId: string | undefined) => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetAnimalEventByIdQuery(
    animalEventId || skipToken,
  )

  const draftEvent = useAppSelector(
    (state) => state.animalEventsDraftSlice.draft,
  )

  useLayoutEffect(() => {
    if (data) {
      dispatch(setEventsDraft(data))
    }
  }, [data, dispatch])

  return { draftEvent, data, isLoading }
}

export default useGetDraftAnimalEvent
