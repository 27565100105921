import { skipToken } from "@reduxjs/toolkit/query"
import { useLayoutEffect } from "react"
import { useGetTransactionByIdQuery } from "@/features/finance/api/finance-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setTransactionDraft } from "@/redux/slices/transaction-draft-slice.ts"

/**
 * This hook ensures the synchronization of the draft transaction with the transaction from the API
 *
 * @param transactionId
 */
const useGetDraftTransaction = (transactionId: string | undefined) => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetTransactionByIdQuery(
    transactionId || skipToken,
  )
  const draftTransaction = useAppSelector(
    (state) => state.transactionSlice.draft,
  )

  useLayoutEffect(() => {
    if (data) {
      dispatch(setTransactionDraft(data))
    }
  }, [data, dispatch])

  return { draftTransaction, data, isLoading }
}

export default useGetDraftTransaction
