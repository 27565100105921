import styles from "./delete-button.module.css"
import BinIcon from "@/assets/icons/misc/bin-icon.tsx"

interface DeleteButtonProps {
  text: string
  onClick: () => void
  style?: React.CSSProperties
}

const DeleteButton: React.FC<DeleteButtonProps> = ({
  text,
  onClick,
  style,
}) => {
  return (
    <div
      onClick={onClick}
      className={styles["delete-flex"]}
      style={{ ...style }}
    >
      <BinIcon />
      <div className={styles["delete-text"]}>{text}</div>
    </div>
  )
}

export default DeleteButton
