import { useTranslation } from "react-i18next"
import styles from "../settings-routes.module.css"
import PaperPlaneIcon from "@/assets/icons/settings/paper-plane-icon.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"
import { WHITE } from "@/utils/constants/colors-constants.ts"

const SettingsContact = () => {
  const { t } = useTranslation()

  return (
    <>
      <SettingsTopBar title={t("contact")} />
      <div className={styles.settingsContent}>
        <div className={styles.card}>
          <div className={styles.cardItemName}>{t("subject")}</div>
          <textarea
            name={t("subject")}
            className={styles.titleInput}
            placeholder={t("subject")}
            value={""}
            rows={3}
            onChange={() => {}}
          />

          <div className={styles.cardItemName}>{t("message")}</div>
          <textarea
            name={t("message")}
            className={styles.titleInput}
            placeholder={t("message")}
            value={""}
            rows={6}
            onChange={() => {}}
          />
        </div>
        <button className={styles.sendButton}>
          <PaperPlaneIcon size={24} color={WHITE} />
          {t("send")}
        </button>
      </div>
    </>
  )
}

export default SettingsContact
