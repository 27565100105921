import React from "react"
import styles from "./task-card-pill.module.css"
import CowHeadIcon from "@/assets/icons/farm/cow-head-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { type Animal, getCurrentAnimalImage } from "@/features/farm"
import { LIGHT_GREY } from "@/utils/constants/colors-constants.ts"

interface TaskCardPillAnimalInfoProps {
  animals: Animal[]
}

const TaskCardPillAnimalInfo: React.FC<TaskCardPillAnimalInfoProps> = ({
  animals,
}) => {
  return (
    <>
      {animals.length > 1 ? (
        <div className={styles.cardPill}>
          <div className={styles.pillText}>{animals.length}</div>
          <CowHeadIcon fill={"transparent"} stroke={LIGHT_GREY} />
        </div>
      ) : (
        <div className={styles.cardPill}>
          <div className={styles.pillText}>{animals[0].ain.slice(-4)}</div>
          <GenericPicture
            src={getCurrentAnimalImage(animals[0])}
            alt={animals[0].ain}
            size={25}
            borderRadius={50}
          />
        </div>
      )}
    </>
  )
}

export default TaskCardPillAnimalInfo
