import React from "react"
import { BROWN, GREY } from "@/utils/constants/colors-constants.ts"

interface BasketIconProps {
  is_active: boolean
}

const BasketIcon: React.FC<BasketIconProps> = ({ is_active }) => {
  const fillColor = is_active ? BROWN : GREY

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.006"
      height="21.807"
      viewBox="241.292 713.072 21.006 21.807"
    >
      <g data-name="basket-alt-3-svgrepo-com">
        <path
          d="M241.292 713.072h2.064c.922 0 1.382 0 1.73.25.346.25.492.687.783 1.562l.721 2.162"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.3"
          stroke={fillColor}
          fill="transparent"
          data-name="Path 1299"
        />
        <path
          d="M259.837 730.293H247.32c-.194 0-.29 0-.364-.009a1.325 1.325 0 0 1-1.148-1.592c.015-.073.046-.165.107-.348h0c.068-.204.102-.306.14-.396a2.65 2.65 0 0 1 2.25-1.622c.097-.007.205-.007.42-.007h7.138"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.3"
          stroke={fillColor}
          fill="transparent"
          data-name="Path 1300"
        />
        <path
          d="M255.437 726.319h-4.028c-1.695 0-2.542 0-3.206-.438-.663-.437-.997-1.216-1.665-2.774l-.223-.521c-1.072-2.502-1.609-3.753-1.02-4.647.59-.893 1.95-.893 4.672-.893h6.996c3.045 0 4.568 0 5.142.99.574.99-.181 2.312-1.692 4.956l-.376.657c-.744 1.303-1.116 1.954-1.733 2.312-.617.358-1.367.358-2.867.358Z"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.3"
          stroke={fillColor}
          fill="transparent"
          data-name="Path 1301"
        />
        <path
          d="M257.85 733.504a1.325 1.325 0 1 1 0 .1z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="Ellipse 402"
        />
        <path
          d="M247.252 733.504a1.325 1.325 0 1 1 0 .1z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="Ellipse 403"
        />
      </g>
    </svg>
  )
}

export default BasketIcon
