import { createSlice, type PayloadAction } from "@reduxjs/toolkit"

type SliceSelectionSlice = {
  selectedTasksIds: string[]
  isSelectionEnabled?: boolean
}

const initialState: SliceSelectionSlice = {
  selectedTasksIds: [],
  isSelectionEnabled: false,
}

const taskSelectionSlice = createSlice({
  name: "taskSelection",
  initialState: initialState,
  reducers: {
    setSelectedTasksIds: (state, action: PayloadAction<string[]>) => {
      state.selectedTasksIds = action.payload
    },
    enableSelection: (state) => {
      state.isSelectionEnabled = true
    },
    disableSelection: (state) => {
      state.isSelectionEnabled = false
    },
  },
})

export default taskSelectionSlice.reducer

export const { setSelectedTasksIds, enableSelection, disableSelection } =
  taskSelectionSlice.actions
