import React, { useCallback } from "react"
import { useTranslation } from "react-i18next"
import styles from "../filter-tab.module.css"
import SearchBar from "@/components/search-bar/search-bar.tsx"
import AnimalEventsTypeDrawer, {
  DrawerTriggerTypes,
} from "@/features/animal-events/components/animal-event-type-drawer/animal-event-type-drawer.tsx"
import useMapEventTypesToParents from "@/features/animal-events/hooks/use-map-event-types-to-parents.ts"
import { type AnimalEventsFilterSelections } from "@/features/animal-events/types/animal-events-constants.ts"
import DrawerAnimals from "@/features/farm/components/drawer-animals/drawer-animals.tsx"
import DrawerAnimalsFilterTrigger from "@/features/tasks/components/drawer-animals-filter-trigger/drawer-animals-filter-trigger.tsx"

interface AnimalEventsFilterTabProps {
  handleSearch: (value: string) => void
  selections: AnimalEventsFilterSelections
  handleSelectedAnimal: (selectedAnimal: string) => void
  handleEventType: (eventType: string | undefined) => void
}

const AnimalEventsFilterTab: React.FC<AnimalEventsFilterTabProps> = ({
  selections,
  handleSelectedAnimal,
  handleEventType,
  handleSearch,
}) => {
  const { t } = useTranslation()

  const handleAnimalSelection = useCallback(
    (animalIDs: string[]) => {
      if (animalIDs && animalIDs.length > 0) {
        handleSelectedAnimal(animalIDs[0])
      } else {
        handleSelectedAnimal("")
      }
    },
    [handleSelectedAnimal],
  )

  const parentEventTypes = useMapEventTypesToParents()

  return (
    <>
      <SearchBar
        value={selections.searchQuery}
        onChange={(event) => handleSearch(event.target.value)}
        placeholder={t("searchAnimalEvent")}
      />
      <div className={styles["horizontal-scroll"]}>
        <div className={styles["filter-flex"]}>
          <DrawerAnimals
            currentValue={selections.selectedAnimal}
            onSaveClick={(animalIDs) => handleAnimalSelection(animalIDs)}
            TriggerComponent={DrawerAnimalsFilterTrigger}
            selectionType={"single"}
          />
          {parentEventTypes && (
            <AnimalEventsTypeDrawer
              selectedValue={selections.type}
              onEventSelect={handleEventType}
              eventTypesMap={parentEventTypes}
              fallbackText={"selectEventType"}
              triggerType={DrawerTriggerTypes.Button}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default AnimalEventsFilterTab
