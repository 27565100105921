import styles from "./farm-layout-desktop.module.css"
import { AnimalProfile, FarmTab } from "@/features/farm"

const FarmLayoutDesktop = () => {
  return (
    <div className={styles["flex"]}>
      <FarmTab />
      <AnimalProfile />
    </div>
  )
}

export default FarmLayoutDesktop
