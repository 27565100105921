import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { type Animal, type AnimalDraft, isRuminant } from "@/features/farm"
import { useCreateAnimalMutation } from "@/features/farm/api/animals-api.ts"
import { usePostRuminantMutation } from "@/features/farm/api/ruminants-api.ts"

export default function useCreateAnimal() {
  const { t } = useTranslation()
  const [createAnimalMutation] = useCreateAnimalMutation()
  const [postRuminant] = usePostRuminantMutation()

  const handleCreate = (
    promise: Promise<AnimalDraft>,
    onSuccess: () => void,
  ) => {
    toast
      .promise(promise, {
        pending: t("creatingAnimal"),
        success: t("animalCreated"),
        error: t("animalCreateError"),
      })
      .then(onSuccess)
  }

  const createAnimal = (animalData: Partial<Animal>, onSuccess: () => void) => {
    const promise = isRuminant(animalData)
      ? postRuminant(animalData).unwrap()
      : createAnimalMutation(animalData).unwrap()

    handleCreate(promise, onSuccess)
  }

  return { createAnimal }
}
