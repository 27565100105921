import { type Task } from "@/features/tasks"
import { useGetTaskCategoriesQuery } from "@/features/tasks/api/task-categories-api.ts"

interface UseGetTaskCategoryProps {
  task: Task
}

export const useGetTaskCategory = ({ task }: UseGetTaskCategoryProps) => {
  const { data: categories = [] } = useGetTaskCategoriesQuery()

  return categories.find((category) => category.id === task.category)
}
