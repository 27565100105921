import { BROWN } from "@/utils/constants/colors-constants"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const CircleTickIcon: React.FC<GeneralSvgProps> = (props) => {
  const viewBoxSize = 24
  const width = props.size || viewBoxSize
  const height = props.size || viewBoxSize

  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <circle
          cx="12"
          cy="12"
          r="11"
          stroke={props?.color || BROWN}
          strokeWidth="1.2"
        ></circle>
        <path
          d="M8.5 12.5L10.5 14.5L15.5 9.5"
          stroke={props?.color || BROWN}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  )
}

export default CircleTickIcon
