import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import styles from "./editor-page.module.css"
import DeleteIcon from "@/assets/icons/misc/delete-icon.tsx"
import ShareIcon from "@/assets/icons/misc/share-icon.tsx"
import OverlayItem from "@/components/overlay-item/overlay-item.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { useDeleteNoteMutation } from "@/features/notes/api/notes-api.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import { deleteNotesDraft } from "@/redux/slices/notes-draft-slice.ts"
import { NOTES_VIEW_PATH } from "@/utils/constants/routes.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"

interface NoteOverlayProps {
  onClose: () => void
  onShare: () => void
}

const NoteOverlay: React.FC<NoteOverlayProps> = ({ onClose, onShare }) => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const [deleteNoteMutation] = useDeleteNoteMutation()
  const navigate = useNavigate()
  const { noteId } = useParams<{ noteId: string }>()
  const dispatch = useAppDispatch()

  const deleteNote = () => {
    if (noteId) {
      toast
        .promise(deleteNoteMutation(noteId).unwrap(), {
          pending: t("deletingNote"),
          success: t("noteDeleted"),
          error: t("errorDeletingNote"),
        })
        .then(() => {
          navigate(NOTES_VIEW_PATH, { replace: true })
          setTimeout(() => {
            dispatch(deleteNotesDraft(noteId))
          }, DELETE_ENTRY_TIMEOUT)
        })
    }
  }

  const handleDelete = () => {
    openModal(MODALS.BASE_MODAL, {
      onActionClick: deleteNote,
      title: t("deleteNoteConfirmation"),
    })
  }

  return (
    <>
      <div className={styles.noteOverlay} onClick={onClose} />
      <div className={styles.overlayCard}>
        <OverlayItem onClick={onShare} label={"shareNote"} alt={"shareNote"}>
          <ShareIcon />
        </OverlayItem>

        <OverlayItem
          onClick={() => handleDelete()}
          label={"deleteNote"}
          alt={"deleteNote"}
        >
          <DeleteIcon />
        </OverlayItem>
      </div>
    </>
  )
}

export default NoteOverlay
