import { YELLOW } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const DateIcon: React.FC<GeneralSvgProps> = (props) => {
  const fillColor = props.fill ? props.fill : YELLOW

  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="16 10 30 30"
    >
      <g data-name="Group 2296">
        <path
          d="M23 10h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H23a7 7 0 0 1-7-7V17a7 7 0 0 1 7-7z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <g data-name="calendar-svgrepo-com (2)">
          <path
            d="M34.404 18.202h-6.808v.425a.425.425 0 0 1-.85 0v-.425h-1.277c-.705 0-1.277.571-1.277 1.276v2.128h13.616v-2.128c0-.705-.572-1.276-1.277-1.276h-1.276v.425a.426.426 0 0 1-.851 0v-.425Zm.85-.851h1.277c1.175 0 2.128.952 2.128 2.127v11.07a2.127 2.127 0 0 1-2.128 2.127H25.47a2.127 2.127 0 0 1-2.128-2.128V19.478c0-1.175.953-2.127 2.128-2.127h1.276v-.426a.425.425 0 0 1 .851 0v.426h6.808v-.426a.426.426 0 0 1 .85 0v.426Zm2.554 5.106H24.192v8.09c0 .705.572 1.277 1.277 1.277H36.53c.705 0 1.277-.572 1.277-1.277v-8.09Z"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="M34.404 18.202h-6.808v.425a.425.425 0 0 1-.85 0v-.425h-1.277c-.705 0-1.277.571-1.277 1.276v2.128h13.616v-2.128c0-.705-.572-1.276-1.277-1.276h-1.276v.425a.426.426 0 0 1-.851 0v-.425Zm.85-.851h1.277c1.175 0 2.128.952 2.128 2.127v11.07a2.127 2.127 0 0 1-2.128 2.127H25.47a2.127 2.127 0 0 1-2.128-2.128V19.478c0-1.175.953-2.127 2.128-2.127h1.276v-.426a.425.425 0 0 1 .851 0v.426h6.808v-.426a.426.426 0 0 1 .85 0v.426Zm2.554 5.106H24.192v8.09c0 .705.572 1.277 1.277 1.277H36.53c.705 0 1.277-.572 1.277-1.277v-8.09h0Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth=".3"
            stroke="#fff"
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default DateIcon
