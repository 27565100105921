import MobilePhoneIcon from "./mobile-phone-icon"
import styles from "./no-desktop-screen.module.css"
import useIsDesktop from "@/hooks/use-is-desktop"

const WideScreenMessage = () => {
  const isWideScreen = useIsDesktop()

  if (!isWideScreen) {
    return null
  }

  return (
    <div className={styles.wideScreenMessage}>
      <div className={styles.wideScreenMessageContent}>
        <MobilePhoneIcon />
        <div>
          Această aplicație este optimizată pentru utilizarea pe dispozitive
          mobile.
        </div>
        <div>Vă rugăm să accesați de pe un dispozitiv mobil.</div>
      </div>
    </div>
  )
}

export default WideScreenMessage
