import { useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "../settings-routes.module.css"
import FaqIcon from "@/assets/icons/settings/faq-icon.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"

const SettingsFaq = () => {
  const { t } = useTranslation()
  const [openFaqIndex, setOpenFaqIndex] = useState(null)

  const faqItems = [
    {
      question: t("whatIsFarmApp"),
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius consequat dictum iaculis ultricies eros. Nulla id nulla porta lacus, morbi lorem. Dolor sagittis arcu dignissim ut risus. Est nulla proin a vitae odio sit. Fringilla viverra id id sit viverra nec, accumsan. Et varius arcu, ipsum integer porttitor.",
    },
    {
      question: t("whatIsFarmApp"),
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius consequat dictum iaculis ultricies eros. Nulla id nulla porta lacus, morbi lorem. Dolor sagittis arcu dignissim ut risus. Est nulla proin a vitae odio sit. Fringilla viverra id id sit viverra nec, accumsan. Et varius arcu, ipsum integer porttitor.",
    },
    {
      question: t("whatIsFarmApp"),
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius consequat dictum iaculis ultricies eros. Nulla id nulla porta lacus, morbi lorem. Dolor sagittis arcu dignissim ut risus. Est nulla proin a vitae odio sit. Fringilla viverra id id sit viverra nec, accumsan. Et varius arcu, ipsum integer porttitor.",
    },
    {
      question: t("whatIsFarmApp"),
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius consequat dictum iaculis ultricies eros. Nulla id nulla porta lacus, morbi lorem. Dolor sagittis arcu dignissim ut risus. Est nulla proin a vitae odio sit. Fringilla viverra id id sit viverra nec, accumsan. Et varius arcu, ipsum integer porttitor.",
    },
    {
      question: t("whatIsFarmApp"),
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius consequat dictum iaculis ultricies eros. Nulla id nulla porta lacus, morbi lorem. Dolor sagittis arcu dignissim ut risus. Est nulla proin a vitae odio sit. Fringilla viverra id id sit viverra nec, accumsan. Et varius arcu, ipsum integer porttitor.",
    },
  ]

  const toggleFaq = (index: any) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index)
  }

  // @NOTE add GestationCalendarAccordion component from shadcn https://ui.shadcn.com/docs/components/accordion

  return (
    <>
      <SettingsTopBar title={t("faq")} />
      <div className={styles.settingsContent}>
        <div className={styles.imageCircleCenter}>
          <FaqIcon />
        </div>

        <div className={styles.card}>
          <div className={styles.cardTitleBrown}>{t("faq")}</div>

          {faqItems.map((item, index) => (
            <div key={index} onClick={() => toggleFaq(index)}>
              <div className={styles.faqQuestion}>{item.question}</div>
              <div
                className={`${styles.faqAnswer} ${
                  openFaqIndex === index
                    ? styles.faqAnswerExpanded
                    : styles.faqAnswerCollapsed
                }`}
              >
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default SettingsFaq
