import React from "react"
import AssociatedAnimalCard from "@/features/farm/components/associated-animals-list/associated-animal-card.tsx"
import AssociatedList from "@/features/tasks/components/associated-list/associated-list.tsx"

interface AssociatedAnimalsListProps {
  animals: string[]
  onAnimalsChange: (animalIds: string[]) => void
}

const AssociatedAnimalsList: React.FC<AssociatedAnimalsListProps> = ({
  animals,
  onAnimalsChange,
}) => {
  return (
    <AssociatedList
      items={animals}
      onItemsChange={onAnimalsChange}
      renderItem={(animal, onDelete) => (
        <AssociatedAnimalCard
          animal={animal}
          onDelete={onDelete}
          key={animal}
        />
      )}
      seeMoreText="seeOtherAnimalsAssociated"
      hideMoreText="hideOtherAnimalsAssociated"
    />
  )
}

export default AssociatedAnimalsList
