import {
  add,
  addSeconds,
  differenceInCalendarDays,
  format,
  isAfter,
  isBefore,
  isToday,
  parseISO,
} from "date-fns"
import {
  type CalendarActivity,
  type GestationCalendar,
} from "@/features/gestational-calendar/types/calendar-types.ts"
import { DateInterval } from "react-day-picker"

/**
 * this function displays the interval or the date of an activity from the
 * gestational calendar timeline according to the presence of an interval or not
 *
 * @param calendarActivity
 */
export const formatGestationalCalendarInterval = (
  calendarActivity: CalendarActivity,
) => {
  if (!calendarActivity.event.interval_duration) {
    return format(calendarActivity.event_date, "dd.MM.yyyy")
  } else {
    const endDate = parseISO(calendarActivity.event_date)
    const newEndDate = addSeconds(
      endDate,
      calendarActivity.event.interval_duration,
    )
    if (calendarActivity.event.interval_duration < 24 * 60 * 60) {
      return `${format(calendarActivity.event_date, "dd.MM.yyyy HH:mm")} - ${format(newEndDate, "HH:mm")}`
    }
    return `${format(calendarActivity.event_date, "dd.MM.yyyy")} - ${format(newEndDate, "dd.MM.yyyy")}`
  }
}

/**
 * ~ shorthand it tells us if the calving date was confirmed
 *
 * This function is used to determine if any changes were made to the calendar,
 * atm if the updated_at differs with more than ms it means that the calving was confirmed,
 * usually the created_at and updated_at have a small delay that usually comes up to some ms due to the way
 * in which the server handles the request, its perfectly normal
 *
 * @param gestationCalendar
 */
export const checkIsCalendarModified = (
  gestationCalendar: GestationCalendar,
) => {
  const calendarCreationDate = parseISO(gestationCalendar.created_at)
  const calendarUpdateDate = parseISO(gestationCalendar.updated_at)

  calendarCreationDate.setMilliseconds(0)
  calendarUpdateDate.setMilliseconds(0)

  return calendarCreationDate.getTime() !== calendarUpdateDate.getTime()
}

/**
 * left as function to help further changes
 *
 * @param entry
 * @param currentDate
 */
const getTimeDiff = (entry: CalendarActivity, currentDate: number) => {
  return new Date(entry.event_date).getTime() - currentDate
}

/**
 * returns the following activity from the gestational calendar timeline
 *
 * @param gestationCalendar
 */
export const getNextActivity = (gestationCalendar: GestationCalendar) => {
  const currentDate = new Date().getTime()

  const dates = gestationCalendar.entries
    .map((entry, index) => ({
      diff: getTimeDiff(entry, currentDate),
      index: index,
    }))
    .filter((date) => date.diff > 0)
    .sort((a, b) => a.diff - b.diff)

  return gestationCalendar.entries[dates[0].index]
}

export const getPrevActivity = (gestationCalendar: GestationCalendar) => {
  const currentDate = new Date().getTime()
  const dates = gestationCalendar.entries
    .map((entry, index) => ({
      diff: getTimeDiff(entry, currentDate),
      index: index,
    }))
    .filter((date) => date.diff < 0)
    .sort((a, b) => b.diff - a.diff)

  return gestationCalendar.entries[dates[0].index]
}

export const checkIfTodayOrIfInInterval = (
  calendarActivity: CalendarActivity,
) => {
  const now = new Date()
  const eventDate = new Date(calendarActivity.event_date)

  if (isToday(eventDate) || isAfter(now, eventDate)) {
    return true
  }

  if (calendarActivity.event.interval_duration) {
    const intervalDurationInSeconds = calendarActivity.event.interval_duration

    const endDate = add(eventDate, {
      seconds: intervalDurationInSeconds,
    })

    if (isAfter(now, eventDate) && isBefore(now, endDate)) {
      return true
    }
  }

  return false
}

export const differenceUntilEndOfInterval = (
  calendarActivity: CalendarActivity,
) => {
  const now = new Date()
  const eventDate = new Date(calendarActivity.event_date)

  if (calendarActivity.event.interval_duration) {
    const intervalDurationInSeconds = calendarActivity.event.interval_duration

    const endDate = add(eventDate, {
      seconds: intervalDurationInSeconds,
    })

    return differenceInCalendarDays(endDate, now)
  }
}

export const getGestationCalendarCalvingActivity = (
  gestationCalendar: GestationCalendar,
) => {
  return gestationCalendar.entries.find((entry) => {
    return entry.event.is_calving_relative && entry.event.time_offset === 0
  })
}

/**
 * function for getting the Date interval of an gestationCalendarEntry
 *
 * @param gestationCalendarEntry
 * @param takeTodayIntoAccount if true the interval will end on today if today isBefore the endDate
 */
export const getDateInterval = (
  gestationCalendarEntry: CalendarActivity | undefined,
  takeTodayIntoAccount?: boolean,
): DateInterval | undefined => {
  if (!gestationCalendarEntry) return

  const today = new Date()
  const eventDate = new Date(gestationCalendarEntry.event_date)
  const intervalDurationInSeconds =
    gestationCalendarEntry.event.interval_duration || 0
  const endDate = add(eventDate, {
    seconds: intervalDurationInSeconds,
  })

  if (takeTodayIntoAccount && isBefore(today, endDate)) {
    return {
      after: eventDate,
      before: today,
    }
  }

  return {
    after: eventDate,
    before: endDate,
  }
}
