import { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperAI from "@/components/page-content-wrappers/page-content-wrapper-ai.tsx"
import ScrollToBottomButton from "@/components/scroll-to-bottom/scroll-to-bottom.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import {
  useCreateAssistantMutation,
  useGetAllAssistantThreadsForUserQuery,
  usePostMessageToAssistantMutation,
} from "@/features/assistant/api/assistant-api.ts"
import ChatBottomInput from "@/features/assistant/components/chat-bottom-input/chat-bottom-input.tsx"
import ChatBubbleWrapper from "@/features/assistant/components/chat-bubble/chat-bubble-wrapper.tsx"
import EmptyChatInit from "@/features/assistant/components/empty-chat-init/empty-chat-init.tsx"

const AssistantPage = () => {
  const { t } = useTranslation()
  const { data: threads, isLoading } = useGetAllAssistantThreadsForUserQuery()
  const [createThread] = useCreateAssistantMutation()
  const [postMessageToAssistant] = usePostMessageToAssistantMutation()
  const [threadId, setThreadId] = useState<string | undefined>()
  const [messages, setMessages] = useState<any[]>([])
  const [showScrollButton, setShowScrollButton] = useState(false)

  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (!threadId && !isLoading && threads?.length === 0) {
      createThread()
        .unwrap()
        .then((res) => setThreadId(res.id))
    } else if (!threadId && !isLoading && threads && threads?.length > 0) {
      setThreadId(threads[0].id)
    }
  }, [threadId, isLoading, threads, createThread])

  useEffect(() => {
    if (threadId && threads) {
      const currentThread = threads.find((thread) => thread.id === threadId)
      setMessages(currentThread ? currentThread.messages : [])
    }
  }, [threadId, threads])

  const handleSendMessage = async (message: string) => {
    if (threadId) {
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          id: Date.now().toString(),
          user_message: message,
          gpt_response: "",
          created_at: new Date().toISOString(),
          updated_at: new Date().toISOString(),
        },
      ])
      setTimeout(scrollToBottom, 50)
      await postMessageToAssistant({ threadId, message })
        .unwrap()
        .then(() => {
          setTimeout(scrollToBottom, 100)
        })
    }
  }

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior: "smooth",
      })
    }
  }

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current
        setShowScrollButton(scrollTop + clientHeight < scrollHeight)
      }
    }

    const container = containerRef.current
    if (container) {
      container.addEventListener("scroll", handleScroll)
    }
    handleScroll()
    setTimeout(scrollToBottom, 100)

    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  return (
    <>
      <MobileTopBar title={t("intelligentAssistant")} />
      <PageContentWrapperAI ref={containerRef}>
        {threadId ? (
          <>
            {messages.length === 0 ? (
              <EmptyChatInit threadId={threadId} />
            ) : (
              <>
                {messages.map((message, index) => (
                  <ChatBubbleWrapper message={message} key={index} />
                ))}
              </>
            )}
          </>
        ) : (
          <LoadingAnimation />
        )}
      </PageContentWrapperAI>
      {showScrollButton && (
        <ScrollToBottomButton
          onClick={scrollToBottom}
          bottom={"var(--bottom-menu-height)"}
        />
      )}
      {threadId && <ChatBottomInput onSendMessage={handleSendMessage} />}
    </>
  )
}

export default AssistantPage
