import styles from "./tasks-layout-desktop.module.css"
import { TasksTab, TaskView } from "@/features/tasks"

const TasksLayoutDesktop = () => {
  return (
    <div className={styles["flex"]}>
      <TasksTab />
      <TaskView />
    </div>
  )
}

export default TasksLayoutDesktop
