import React from "react"
import styles from "./pending-circle.module.css"

interface PendingCircleProps {
  onClick?: () => void
  className?: string
}

const PendingCircle: React.FC<PendingCircleProps> = ({
  onClick,
  className,
}) => {
  const combinedClassName = className
    ? `${styles["pending-circle"]} ${className}`
    : styles["pending-circle"]

  return <div className={combinedClassName} onClick={onClick}></div>
}

export default PendingCircle
