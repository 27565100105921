import queryString from "query-string"
import { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useGetTransactionCategoriesQuery } from "@/features/finance/api/transaction-categories-api"
import { type Transaction } from "@/features/finance/types/transaction"

export interface TransactionFilterSelections {
  searchQuery: string
  selectedCategory: string
  expenses: boolean
  incomes: boolean
}

const initialFilterSelections: TransactionFilterSelections = {
  searchQuery: "",
  selectedCategory: "",
  expenses: false,
  incomes: false,
}

const useTransactionFilter = (
  transactions: Transaction[] | undefined,
  initialFilter?: TransactionFilterSelections,
) => {
  const navigate = useNavigate()
  const { data: transactionCategories } = useGetTransactionCategoriesQuery()

  const [selections, setSelections] = useState<TransactionFilterSelections>(
    initialFilter || initialFilterSelections,
  )

  const updateURL = useCallback(() => {
    const queryParams = {
      searchQuery: selections.searchQuery,
      selectedCategory: selections.selectedCategory,
      expenses: selections.expenses.toString(),
      incomes: selections.incomes.toString(),
    }
    const searchString = queryString.stringify(queryParams)
    navigate({ search: searchString }, { replace: true })
  }, [selections, navigate])

  useEffect(() => {
    updateURL()
  }, [selections, updateURL])

  const handleIncomeSelection = () => {
    setSelections((prev) => ({
      ...prev,
      selectedCategory: "allCategories",
      incomes: !prev.incomes,
      expenses: false,
    }))
  }

  const handleExpenseSelection = () => {
    setSelections((prev) => ({
      ...prev,
      selectedCategory: "allCategories",
      expenses: !prev.expenses,
      incomes: false,
    }))
  }

  const handleSelectedCategory = (selectedCategory: string) => {
    setSelections((prev) => ({
      ...prev,
      selectedCategory,
    }))
  }

  const handleSearch = (searchQuery: string) => {
    setSelections((prev) => ({
      ...prev,
      searchQuery,
    }))
  }

  const selectedCategories = Object.entries(transactionCategories ?? {})
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_key, value]) => {
      if (selections.expenses) return value.is_outflow
      if (selections.incomes) return !value.is_outflow
      return true
    })
    .map(([key, value]) => ({
      label: value.name,
      value: key,
    }))

  selectedCategories.unshift({ label: "allCategories", value: "allCategories" })

  const filteredTransactions = transactions?.filter((transaction) => {
    if (selections.searchQuery !== "") {
      const description = transaction.description
        .toLowerCase()
        .includes(selections.searchQuery.toLowerCase())

      const amount = transaction.amount
        .toLowerCase()
        .includes(selections.searchQuery.toLowerCase())

      return description || amount
    }

    if (selections.selectedCategory !== "allCategories") {
      return transaction.category == selections.selectedCategory
    }
    return selectedCategories.some(
      (category) => category.value == transaction.category,
    )
  })

  return {
    selections,
    handleIncomeSelection,
    handleExpenseSelection,
    handleSelectedCategory,
    handleSearch,
    selectedCategories,
    filteredTransactions,
  }
}

export default useTransactionFilter
