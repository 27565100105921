import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { type Animal, type AnimalDraft, isRuminant } from "@/features/farm"
import { useUpdateAnimalMutation } from "@/features/farm/api/animals-api"
import { useUpdateRuminantMutation } from "@/features/farm/api/ruminants-api.ts"

export default function useUpdateAnimal() {
  const { t } = useTranslation()
  const [updateRuminantMutation] = useUpdateRuminantMutation()
  const [updateAnimalMutation] = useUpdateAnimalMutation()

  const handleUpdate = (
    promise: Promise<AnimalDraft>,
    onSuccess: () => void,
  ) => {
    toast
      .promise(promise, {
        pending: t("updatingAnimal"),
        success: t("animalUpdated"),
        error: t("animalUpdateError"),
      })
      .then(onSuccess)
  }

  const updateAnimal = (animalData: Partial<Animal>, onSuccess: () => void) => {
    const promise = isRuminant(animalData)
      ? updateRuminantMutation(animalData).unwrap()
      : updateAnimalMutation(animalData).unwrap()

    handleUpdate(promise, onSuccess)
  }

  return { updateAnimal }
}
