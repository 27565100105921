import { toast } from "react-toastify"
import * as Yup from "yup"
import {
  type GenericAnimalEvent,
  type MainEventDataType,
} from "@/features/animal-events/types/animal-events.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

const movementDataSchema = Yup.object().shape({
  from_location: Yup.string().required("fromLocationRequired"),
  to_location: Yup.string().required("toLocationRequired"),
})

const measurementDataSchema = Yup.object().shape({
  value: Yup.string().required("valueRequired"),
})

const treatmentDataSchema = Yup.object().shape({
  medication: Yup.string().required("medicationRequired"),
  dosage: Yup.string().required("dosageRequired"),
})

export const getAnimalEventSchema = (eventDataType: MainEventDataType) => {
  const selectedEventDataSchema = Yup.lazy(() => {
    if (eventDataType.isMovementEvent) {
      return movementDataSchema
    } else if (eventDataType.isMeasurementEvent) {
      return measurementDataSchema
    } else if (eventDataType.isTreatmentEvent) {
      return treatmentDataSchema
    }
    return Yup.mixed().notRequired()
  })

  return Yup.object().shape({
    animal: Yup.string().required("animalRequired"),
    type: Yup.string().required("typeRequired"),
    date: Yup.date()
      .nullable()
      .transform((_value, originalValue) =>
        originalValue === null ||
        originalValue === "" ||
        isNaN(new Date(originalValue).getTime())
          ? null
          : new Date(originalValue),
      )
      .required("dateRequiredEvent"),
    event_data: selectedEventDataSchema,
  })
}

export const validateAnimalEvent = async (
  event: Partial<GenericAnimalEvent> = {},
  event_data_type: MainEventDataType,
) => {
  const animalEventSchema = getAnimalEventSchema(event_data_type)

  try {
    await animalEventSchema.validate(event, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
