import { useMemo } from "react"
import { useGetTransactionCategoriesQuery } from "@/features/finance/api/transaction-categories-api"

/**
 * This hook is used to get the category
 *
 * @param category
 */
const useGetTransactionCategoryById = (category: string | null | undefined) => {
  const { data: transactionCategories } = useGetTransactionCategoriesQuery()
  return useMemo(() => {
    if (!transactionCategories || !category) return undefined

    return transactionCategories[category]
  }, [transactionCategories, category])
}

export default useGetTransactionCategoryById
