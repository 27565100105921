import React from "react"
import { Outlet } from "react-router-dom"
import style from "../layouts.module.css"
import SideMenu from "@/components/side-menu/side-menu.tsx"

interface StandardLayoutDesktopProps {}

const StandardLayoutDesktop: React.FC<StandardLayoutDesktopProps> = () => {
  return (
    <>
      <div className={style["desktop-background"]} />
      <div className={style["standard-desktop-layout"]}>
        <div className={style["menu-and-content"]}>
          <SideMenu />
          <Outlet />
        </div>
      </div>
    </>
  )
}

export default StandardLayoutDesktop
