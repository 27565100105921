import React from "react"
import TimelineItem from "@/components/timeline/timeline-item/timeline-item.tsx"
import GestationCalendarAccordionSummary from "@/features/gestational-calendar/components/gestation-calendar-accordion/gestation-calendar-accordion-summary.tsx"
import { CalendarActivityState } from "@/features/gestational-calendar/types/calendar-types.ts"

interface CalendarActivityTimelineSummaryProps {
  onClick: () => void
}

const CalendarActivityTimelineSummary: React.FC<
  CalendarActivityTimelineSummaryProps
> = ({ onClick }) => {
  return (
    <TimelineItem
      activityState={CalendarActivityState.DONE}
      completionPercentage={100}
      isLast={true}
      isAfterCalving={false}
    >
      <GestationCalendarAccordionSummary onClick={onClick} />
    </TimelineItem>
  )
}

export default CalendarActivityTimelineSummary
