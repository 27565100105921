import { differenceInCalendarDays } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import MultiStepProgressBar from "@/components/multi-step-progress-bar/multi-step-progress-bar.tsx"
import styles from "@/features/gestational-calendar/components/gestation-calendar-card/gestation-calendar-card.module.css"
import { type GestationCalendar } from "@/features/gestational-calendar/types/calendar-types.ts"
import {
  checkIfTodayOrIfInInterval,
  differenceUntilEndOfInterval,
  getGestationCalendarCalvingActivity,
} from "@/features/gestational-calendar/utils/gestational-calendar-utils.ts"

interface GestationCalendarCardBottomUltiBirthProps {
  gestationCalendar: GestationCalendar
}

const GestationCalendarCardBottomUntilBirth: React.FC<
  GestationCalendarCardBottomUltiBirthProps
> = ({ gestationCalendar }) => {
  const { t } = useTranslation()
  const calvingDate = new Date(gestationCalendar.calving_date)

  const differenceInDaysFromToday = differenceInCalendarDays(
    calvingDate,
    new Date(),
  )

  const differenceInDaysFromInseminationDate = differenceInCalendarDays(
    calvingDate,
    new Date(gestationCalendar.insemination_date),
  )

  const calvingCalendarActivity =
    getGestationCalendarCalvingActivity(gestationCalendar)

  const textToDisplay = () => {
    if (
      calvingCalendarActivity &&
      checkIfTodayOrIfInInterval(calvingCalendarActivity)
    ) {
      return (
        <>
          <span className={styles.daysCount}>
            {differenceUntilEndOfInterval(calvingCalendarActivity)}
          </span>
          <span className={styles.daysText}>
            {t("daysUntilCalvingIntervalEnd")}
          </span>
        </>
      )
    } else
      return (
        <>
          <span className={styles.daysCount}>{differenceInDaysFromToday}</span>
          <span className={styles.daysText}>{t("daysToCalving")}</span>
        </>
      )
  }

  return (
    <div className={styles.gestationalCalendarCardTopContentBottom}>
      <div className={styles.daysUntil}>{textToDisplay()}</div>
      <MultiStepProgressBar
        steps={differenceInDaysFromInseminationDate}
        currentStep={
          differenceInDaysFromInseminationDate - differenceInDaysFromToday
        }
      />
    </div>
  )
}

export default GestationCalendarCardBottomUntilBirth
