const FaqIcon = ({ size = 41, color = "var(--brown)", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 41.141 41.141"
      {...props}
    >
      <g id="question-svgrepo-com" transform="translate(0 -0.001)">
        <g
          id="Group_1038"
          data-name="Group 1038"
          transform="translate(0 0.001)"
        >
          <g id="Group_1037" data-name="Group 1037">
            <path
              id="Path_791"
              data-name="Path 791"
              d="M33.047,4.217A20.57,20.57,0,0,0,0,20.571,20.434,20.434,0,0,0,6.038,35.129a.669.669,0,1,0,.946-.947A19.232,19.232,0,0,1,20.57,1.34,19.054,19.054,0,0,1,32.234,5.281a.669.669,0,1,0,.813-1.064Z"
              transform="translate(0 -0.001)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_1040"
          data-name="Group 1040"
          transform="translate(7.864 5.847)"
        >
          <g id="Group_1039" data-name="Group 1039">
            <path
              id="Path_792"
              data-name="Path 792"
              d="M125.137,72.95a.669.669,0,0,0-.948.945,19.232,19.232,0,0,1-25.247,28.9.669.669,0,0,0-.81,1.065,20.57,20.57,0,0,0,27-30.908Z"
              transform="translate(-97.868 -72.754)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_1042"
          data-name="Group 1042"
          transform="translate(18.651 28.294)"
        >
          <g id="Group_1041" data-name="Group 1041">
            <path
              id="Path_793"
              data-name="Path 793"
              d="M234.553,352.114a2.472,2.472,0,0,0-2.44,2.44v.65a2.442,2.442,0,0,0,2.44,2.44,2.386,2.386,0,0,0,2.44-2.44v-.65A2.414,2.414,0,0,0,234.553,352.114Zm1.1,3.09a1.065,1.065,0,0,1-1.1,1.1,1.1,1.1,0,0,1-1.1-1.1v-.65a1.13,1.13,0,0,1,1.1-1.1,1.077,1.077,0,0,1,1.1,1.1Z"
              transform="translate(-232.113 -352.114)"
              fill={color}
            />
          </g>
        </g>
        <g
          id="Group_1044"
          data-name="Group 1044"
          transform="translate(13.634 7.195)"
        >
          <g id="Group_1043" data-name="Group 1043">
            <path
              id="Path_794"
              data-name="Path 794"
              d="M185.42,97.789a8.273,8.273,0,0,0-15.41-4.167,2.435,2.435,0,0,0,.844,3.33l.011.006a2.48,2.48,0,0,0,1.247.34,2.444,2.444,0,0,0,2.127-1.235,3.333,3.333,0,0,1,2.888-1.652,3.377,3.377,0,0,1,3.449,3.377,3.418,3.418,0,0,1-3.449,3.377,2.5,2.5,0,0,0-2.44,2.44c0,.026,0,.052,0,.076s0,.047,0,.069v3.757a2.442,2.442,0,0,0,2.44,2.44,2.386,2.386,0,0,0,2.44-2.44v-1.836h0A8.219,8.219,0,0,0,185.42,97.789Zm-6.676,6.719a.669.669,0,0,0-.515.651v2.348a1.065,1.065,0,0,1-1.1,1.1,1.1,1.1,0,0,1-1.1-1.1v-3.6a.717.717,0,0,0,.036-.226.668.668,0,0,0-.028-.192,1.169,1.169,0,0,1,1.093-.981,4.758,4.758,0,0,0,4.788-4.716,4.649,4.649,0,0,0-1.408-3.356,4.806,4.806,0,0,0-3.38-1.36,4.673,4.673,0,0,0-4.046,2.319,1.117,1.117,0,0,1-.969.567,1.14,1.14,0,0,1-.567-.155,1.093,1.093,0,0,1-.378-1.51,6.924,6.924,0,1,1,7.577,10.213Z"
              transform="translate(-169.671 -89.533)"
              fill={color}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default FaqIcon
