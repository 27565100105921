import { useTranslation } from "react-i18next"

export const useGetWelcomeMessage = (name: string | undefined) => {
  const { t } = useTranslation()
  const hour = new Date().getHours()
  const nameGreet = ", " + name + "!"
  const emptyGreet = "!"
  const getGreet = name ? nameGreet : emptyGreet

  if (hour < 12) {
    return t("goodMorning") + getGreet
  } else if (hour < 17) {
    return t("goodAfternoon") + getGreet
  } else {
    return t("goodEvening") + getGreet
  }
}
