import { format } from "date-fns"
import React, { useRef } from "react"
import styles from "../date-time-pickers.module.css"
import Calendar from "@/components/shadcn/calendar/calendar.tsx"
import TaskCard from "@/components/task-card/overflow-menu-item.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"

interface DateSelectCardProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  icon: React.ReactNode
  placeholder: string
  info: string
  isRequired?: boolean
  onChange: (date: string) => void
}

const DateSelectCard: React.FC<DateSelectCardProps> = ({
  isOpen,
  setIsOpen,
  icon,
  placeholder,
  info,
  onChange,
  isRequired = false,
}) => {
  const calendarRef = useRef<HTMLDivElement>(null)
  const selectedDate = new Date(info)

  const handleSelect = (date: Date) => {
    onChange(format(date, "yyyy-MM-dd"))
    setIsOpen(false)
  }

  const handleOpenCalendar = () => {
    if (!isOpen) {
      calendarRef.current?.scrollIntoView({
        behavior: "smooth",
      })
    }
    setTimeout(() => {
      setIsOpen(!isOpen)
    }, 300)
  }

  return (
    <TaskCard>
      <div
        className={styles["task-card-header"]}
        onClick={handleOpenCalendar}
        ref={calendarRef}
      >
        <div className={styles["icon-wrapper"]}>
          {icon}
          <div>
            <p>{placeholder}</p>
            {selectedDate && (
              <p className={styles["time-info"]}>
                {format(selectedDate, "dd.MM.yyyy")}
              </p>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className={styles["calendar-wrapper"]}>
          <Calendar
            defaultMonth={selectedDate}
            mode={"single"}
            selected={selectedDate}
            captionLayout={"dropdown-buttons"}
            fromYear={2010}
            toYear={2030}
            onSelect={(date) => handleSelect(date as Date)}
          />
        </div>
      )}
      {isRequired && <RequiredIndicator />}
    </TaskCard>
  )
}

export default DateSelectCard
