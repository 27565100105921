import { t } from "i18next"
import { type ReactElement } from "react"
import { Link } from "react-router-dom"
import styles from "./bottom-menu-item.module.css"

interface BottomMenuItemProps {
  isActive: boolean
  to: string
  label: string
  alt: string
  children: ReactElement<{ alt?: string }>
}

export default function BottomMenuItem(props: BottomMenuItemProps) {
  return (
    <Link className={styles.menuItemWrapper} to={props.to}>
      {props.isActive && <div className={styles.activeBar} />}
      <div className={styles.menuItem}>
        <div className={styles.menuIcon}>{props.children}</div>
        <p className={styles.menuLabel} data-active={props.isActive}>
          {t(props.label)}
        </p>
      </div>
    </Link>
  )
}
