import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import styles from "../date-time-pickers.module.css"
import HourIcon from "@/assets/icons/task/hour-icon.tsx"
import {
  convertToUTCWithoutMilliseconds,
  initialTimeState,
  roundDateToNearestInterval,
} from "@/components/task-card-date/picker-utils.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setDraftTaskDueDate } from "@/redux/slices/task-draft-slice.ts"
import { InputType } from "@/utils/enums/input-type-enum.ts"

interface TimePickerProps {
  onOpenChange: (opened: boolean) => void
  isEnabled: boolean
  taskId: string
}

const TimePicker: React.FC<TimePickerProps> = ({
  onOpenChange,
  isEnabled,
  taskId,
}) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const draftTaskDueDate = useAppSelector(
    (state) => state.taskDraftSlice.drafts[taskId]?.due_date,
  )
  const workingDate = draftTaskDueDate
    ? new Date(draftTaskDueDate)
    : roundDateToNearestInterval(new Date(Date.now()))

  const timeDate = initialTimeState(workingDate)

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const [hours, minutes] = e.target.value.split(":")
    workingDate.setHours(Number(hours))
    workingDate.setMinutes(Number(minutes))
    const result = convertToUTCWithoutMilliseconds(workingDate)
    dispatch(setDraftTaskDueDate({ id: taskId, due_date: result }))
  }

  const handleDivClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    onOpenChange(false)
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.showPicker()
    }
  }

  return (
    <div className={styles["task-card-header"]} onClick={handleDivClick}>
      <div className={styles["icon-wrapper"]}>
        <HourIcon />
        <div>
          <p className={styles["title"]}>{t("Ora")}</p>
          {isEnabled && (
            <p className={styles["time-info"]}>
              {`${timeDate.hours}:${timeDate.minutes}`}
            </p>
          )}
        </div>
      </div>
      {isEnabled && (
        <div className={styles["time-wrapper"]}>
          <input
            type={InputType.TIME}
            value={`${timeDate.hours}:${timeDate.minutes}`}
            name="hours"
            className={styles["time-input"]}
            onChange={handleInputChange}
            ref={inputRef}
          />
        </div>
      )}
    </div>
  )
}

export default TimePicker
