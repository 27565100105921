import React, { useState, type FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import styles from "./login-view.module.css"
import LogoBrown from "@/assets/icons/logos/logo-brown.tsx"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import {
  HOME_SCREEN_PATH,
  LOGIN_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

const LostPassword: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [username, setUsername] = useState("")

  // To be implemented
  const handleSubmit = (event: FormEvent) => {
    event.preventDefault()
    navigate(HOME_SCREEN_PATH)
  }

  return (
    <div className={styles.loginWrapper}>
      <Link to={LOGIN_SCREEN_PATH} className={styles["go-back"]}>
        <BackIcon />
      </Link>
      <div className={styles.loginCard}>
        <LogoBrown width={150} height={150} />
        <div className={styles["help"]}>
          <h1>{t("lostYourPassword")}</h1>
          <h5>{t("dontWorryHappensToAllOfUs")}</h5>
          <h5>{t("enterEmailResetPassword")}</h5>
        </div>
        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.formGroup}>
            <input
              id="username"
              type="text"
              placeholder={"E-mail"}
              className={styles["input"]}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <button type="submit" className={styles["button"]}>
            {t("resetPassword")}
          </button>
        </form>
      </div>
    </div>
  )
}

export default LostPassword
