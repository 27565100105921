import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./navigation-buttons.module.css"
import SaveCalendarIcon from "@/assets/icons/gestational-calendar/save-calendar-icon.tsx"
import DeleteIcon from "@/assets/icons/misc/delete-icon.tsx"
import ShortBottomActionWrapper from "@/components/short-bottom-drawer/short-bottom-action-wrapper.tsx"

interface GenerateCalendarButtonProps {
  onSaveClick: () => void
  onDeleteClick: () => void
}

const GestationalCalendarDraftButtons: React.FC<
  GenerateCalendarButtonProps
> = ({ onSaveClick, onDeleteClick }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.buttonBottomWrapper}>
      <ShortBottomActionWrapper
        height={"fit-content"}
        customStyle={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingBottom: "10px",
        }}
      >
        <button className={styles.button} onClick={onSaveClick}>
          <SaveCalendarIcon />
          {t("saveCalendar")}
        </button>
        <button className={styles.deleteButton} onClick={onDeleteClick}>
          <DeleteIcon />
          {t("deleteCalendar")}
        </button>
      </ShortBottomActionWrapper>
    </div>
  )
}

export default GestationalCalendarDraftButtons
