import { NOTIFICATION_CATEGORIES_ENUM } from "./notifications-enum"
import DangerNotificationIcon from "@/assets/icons/notifications/danger-notification-icon.tsx"
import InfoNotificationIcon from "@/assets/icons/notifications/info-notification-icon.tsx"
import MandatoryNotificationIcon from "@/assets/icons/notifications/mandatory-notification-icon.tsx"
import ReminderNotificationIcon from "@/assets/icons/notifications/reminder-notification-icon.tsx"
import { type Notification } from "@/features/notifications/types/notifications.ts"
import {
  NOTIFICATION_BLACK,
  NOTIFICATION_BLUE,
  NOTIFICATION_RED,
  NOTIFICATION_ORANGE,
} from "@/utils/constants/colors-constants"

export const notificationCategories = Object.entries(
  NOTIFICATION_CATEGORIES_ENUM,
).map(([key, value]) => ({
  label: key,
  value: value,
}))

export const getNotificationColor = (
  notification: Notification | undefined,
) => {
  if (!notification) return ""
  const type = notification?.notification_type
  switch (type) {
    case NOTIFICATION_CATEGORIES_ENUM.MANDATORY:
      return NOTIFICATION_BLACK
    case NOTIFICATION_CATEGORIES_ENUM.ALERTS:
      return NOTIFICATION_RED
    case NOTIFICATION_CATEGORIES_ENUM.INFO:
      return NOTIFICATION_BLUE
    case NOTIFICATION_CATEGORIES_ENUM.REMINDERS:
      return NOTIFICATION_ORANGE
    default:
      return ""
  }
}

export const getNotificationIcon = (
  notification: Notification | undefined,
  size?: number,
  fill?: string,
) => {
  const type = notification?.notification_type

  switch (type) {
    case NOTIFICATION_CATEGORIES_ENUM.MANDATORY:
      return <MandatoryNotificationIcon size={size} fill={fill} />
    case NOTIFICATION_CATEGORIES_ENUM.ALERTS:
      return <DangerNotificationIcon size={size} fill={fill} />
    case NOTIFICATION_CATEGORIES_ENUM.INFO:
      return <InfoNotificationIcon size={size} fill={fill} />
    case NOTIFICATION_CATEGORIES_ENUM.REMINDERS:
      return <ReminderNotificationIcon size={size} fill={fill} />
    default:
      return ""
  }
}
