export function dataURLtoFile(dataurl: string, filename: string): File {
  const arr = dataurl.split(",")
  const mimeMatch = arr[0].match(/:(.*?);/)

  if (!mimeMatch) {
    throw new Error("Invalid data URL")
  }

  const mime = mimeMatch[1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

interface TextAndImages {
  text: string
  images: string[]
}

export function extractTextAndImages(html: string): TextAndImages {
  // Create a new div element to hold the HTML
  const tempDivElement = document.createElement("div")
  tempDivElement.innerHTML = html

  // Use the DOM to get text content, stripping out the HTML
  const text = tempDivElement.textContent || tempDivElement.innerText || ""

  // Extract image sources
  const images = Array.from(tempDivElement.querySelectorAll("img")).map(
    (img) => img.src,
  )

  // Return an object with both the text and the array of image sources
  return { text, images }
}

/**
 * This function is used in the context of patching data as it returns only the difference from the initial and modified object
 *
 * @param original
 * @param updated
 */
export const getObjectDifference = <T>(
  original: Partial<T>,
  updated: Partial<T>,
) => {
  return (Object.keys(updated) as (keyof T)[]).reduce((diff, key) => {
    if (original[key] !== updated[key]) {
      diff[key] = updated[key]
    }
    return diff
  }, {} as Partial<T>)
}
