import React from "react"
import styles from "./timeline-item.module.css"
import TimelineTag from "@/components/timeline/timeline-tag/timeline-tag.tsx"
import { CalendarActivityState } from "@/features/gestational-calendar/types/calendar-types.ts"
import { BROWN, CALVING_COLOR } from "@/utils/constants/colors-constants.ts"

interface TimeLineItemProps {
  activityState: CalendarActivityState
  completionPercentage?: number
  isLast: boolean
  isAfterCalving?: boolean
  children?: React.ReactNode
}

const TimelineItem: React.FC<TimeLineItemProps> = ({
  activityState,
  isLast,
  children,
  isAfterCalving,
  completionPercentage,
}) => {
  const coloredRodStyle = {
    height: completionPercentage && `${completionPercentage}%`,
  }

  const getRodBackgroundColor = () => {
    if (
      activityState === CalendarActivityState.DONE &&
      !completionPercentage &&
      !isAfterCalving
    ) {
      return BROWN
    } else if (
      activityState === CalendarActivityState.DONE &&
      !completionPercentage &&
      isAfterCalving
    ) {
      return CALVING_COLOR
    }
  }

  return (
    <div className={styles.timeLineItemWrapper}>
      <div className={styles.connectorWrapper}>
        <TimelineTag
          activityState={activityState}
          isAfterCalving={isAfterCalving}
        />
        {!isLast && (
          <div
            className={styles.rod}
            style={{
              backgroundColor: getRodBackgroundColor(),
              opacity: 1,
            }}
          >
            <div
              className={styles.coloredRod}
              data-is-after-calving={isAfterCalving}
              style={coloredRodStyle}
            ></div>
          </div>
        )}
      </div>
      <div className={styles.itemWrapper}>{children}</div>
    </div>
  )
}

export default TimelineItem
