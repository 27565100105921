import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./animal-events-card.module.css"
import { useGetAllAnimalEventTypesQuery } from "@/features/animal-events/api/event-types-api.ts"
import { type GenericAnimalEvent } from "@/features/animal-events/types/animal-events.ts"
import { getSpeciesIcon } from "@/features/farm"
import { useGetAnimalByIdQuery } from "@/features/farm/api/animals-api.ts"
import { ANIMAL_EVENT_SCREEN_PATH } from "@/utils/constants/routes.ts"

interface AnimalEventCardProps {
  animalEvent: GenericAnimalEvent
}

const AnimalEventsCard: React.FC<AnimalEventCardProps> = ({ animalEvent }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: eventTypes } = useGetAllAnimalEventTypesQuery()
  const { data: animal } = useGetAnimalByIdQuery(animalEvent.animal)
  const icon = animal && getSpeciesIcon(animal?.species_details?.name)

  const onClick = () => {
    navigate(ANIMAL_EVENT_SCREEN_PATH + `/${animalEvent.id}`)
  }

  return (
    <div className={styles.animalEventCardWrapper} onClick={onClick}>
      {eventTypes && animal && (
        <>
          <div className={styles.iconWrapper}>{icon}</div>
          <div className={styles.cardBody}>
            <div className={styles.top}>
              <p className={styles.eventType}>
                {t(eventTypes[animalEvent.type as string].name)}
              </p>

              <div className={styles.right}>
                {format(new Date(animalEvent.date), "dd.MM.yyyy")}
              </div>
            </div>
            <span className={styles.bottom}>
              <p className={styles.mutedText}>{t("crotal")}</p>
              <p>{animal.ain}</p>
            </span>
          </div>
        </>
      )}
    </div>
  )
}

export default AnimalEventsCard
