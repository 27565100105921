import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import React from "react"
import { Doughnut } from "react-chartjs-2"
import { useTranslation } from "react-i18next"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import { type TransactionCategorySummary } from "@/features/finance/api/finance-summary-api.ts"
import { WHITE } from "@/utils/constants/colors-constants.ts"

ChartJS.register(ArcElement, Tooltip, Legend)

interface DoughnutChartProps {
  data: TransactionCategorySummary[]
  total: string
}

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  cutout: "70%",
}

const MultipleElementsDoughnutChart: React.FC<DoughnutChartProps> = ({
  data,
  total,
}) => {
  const { t } = useTranslation()
  const someData = data.map(
    (item) => (Number(item.total) / Number(total)) * 100,
  )

  const someColors = data.map((item) => {
    return item.category.color
  })

  const workingData = {
    datasets: [
      {
        data: [...someData],
        backgroundColor: [...someColors],
        borderColor: WHITE,
        borderWidth: 2,
      },
    ],
  }

  return (
    <>
      {data.length > 0 ? (
        <div style={{ position: "relative", width: "50%" }}>
          <Doughnut data={workingData} options={options} />
        </div>
      ) : (
        <>
          <NoResultsFound message={t("notEnoughData")} />
        </>
      )}
    </>
  )
}

export default MultipleElementsDoughnutChart
