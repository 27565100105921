import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import AnimalEventsFilterTab from "@/components/filter-tab/animal-events/animal-events-filter-tab.tsx"
import GenericItemsGroupByTimeSpan from "@/components/grouping/generic-items-group-by-time-span.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import useAnimalEventsFilter from "@/features/animal-events/hooks/use-animal-events-filter.ts"
import CountNumber from "@/features/farm/components/count-number/count-number.tsx"
import { NEW_ANIMAL_EVENT_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { groupItemsByTimeStampWithField } from "@/utils/helpers/time-grouping.ts"

const AnimalEventsPage = () => {
  const { t } = useTranslation()

  const {
    selections,
    handleSearch,
    handleSelectedAnimal,
    handleEventType,
    filteredAnimalEvents,
    count,
  } = useAnimalEventsFilter()

  const groupedEvents = useMemo(() => {
    return filteredAnimalEvents
      ? groupItemsByTimeStampWithField(filteredAnimalEvents, "date")
      : null
  }, [filteredAnimalEvents])

  return (
    <>
      <MobileTopBar
        title={t("animalEvents")}
        navigateTo={NEW_ANIMAL_EVENT_SCREEN_PATH}
      />
      <PageContentWrapperLarge>
        <AnimalEventsFilterTab
          handleSearch={handleSearch}
          selections={selections}
          handleSelectedAnimal={handleSelectedAnimal}
          handleEventType={handleEventType}
        />
        <CountNumber count={count || 0} text={t("events")} />
        {groupedEvents ? (
          <>
            {filteredAnimalEvents && filteredAnimalEvents.length > 0 ? (
              <GenericItemsGroupByTimeSpan items={groupedEvents} />
            ) : (
              <NoResultsFound message={t("noResultsForAnimal")} />
            )}
          </>
        ) : (
          <LoadingAnimation />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default AnimalEventsPage
