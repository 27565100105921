import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "../task-card-item/task-card-item.module.css"
import ReminderIcon from "@/assets/icons/task/reminder-icon.tsx"
import Switch from "@/components/shadcn/switch/switch.tsx"
import { getReminderLabel } from "@/features/tasks"
import ReminderOptions from "@/features/tasks/components/reminder-options/reminder-options.tsx"

interface RemindersCardProps {
  reminder: number | null
  onReminderChange: (reminder: number | null) => void
}

const RemindersCard: React.FC<RemindersCardProps> = ({
  reminder,
  onReminderChange,
}) => {
  const { t } = useTranslation()
  const [isEnabled, setIsEnabled] = useState(false)
  const [isOpened, setIsOpened] = useState<boolean>(false)

  useEffect(() => {
    if (reminder) {
      setIsEnabled(true)
    } else {
      setIsEnabled(false)
    }
  }, [reminder])

  const handleReminderChange = (newReminders: number | null) => {
    onReminderChange(newReminders)
  }

  const handleSwitchClick = (checked: boolean) => {
    if (!checked) {
      onReminderChange(null)
    }
    setIsEnabled(checked)
  }

  const handleCardClick = () => {
    setIsOpened((prevState) => !prevState)
  }

  return (
    <div className={styles["task-card-item"]} onClick={handleCardClick}>
      <div className={styles["task-card-header"]}>
        <div className={styles["icon-wrapper-reminder"]}>
          <div className={styles["name-wrapper"]}>
            <ReminderIcon />
            <div className={styles["selection-info"]}>
              <p>{t("Memento")}</p>
              {reminder && (
                <p className={styles["selection-label"]}>
                  {t(getReminderLabel(reminder))}
                </p>
              )}
            </div>
          </div>
          <Switch checked={isEnabled} onCheckedChange={handleSwitchClick} />
        </div>
      </div>
      <div
        className={styles["task-card-content"]}
        onClick={(event) => event.stopPropagation()}
      >
        {isOpened && isEnabled && (
          <ReminderOptions
            reminder={reminder}
            setReminder={handleReminderChange}
          />
        )}
      </div>
    </div>
  )
}

export default RemindersCard
