import { useLayoutEffect } from "react"
import { useParams } from "react-router-dom"
import { type GestationCalendar } from "@/features/gestational-calendar/types/calendar-types.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { resetGestationalCalendarDraft } from "@/redux/slices/gestational-calendars-draft-slice.ts"

const useInitializeGestationCalendar = () => {
  const dispatch = useAppDispatch()
  const { animalId } = useParams()

  const gestationalCalendarDraft = useAppSelector(
    (state) => state.gestationalCalendarsDraftSlice.draft,
  )

  useLayoutEffect(() => {
    if (gestationalCalendarDraft.id) return

    const initialData: Partial<GestationCalendar> = {}
    if (animalId) {
      initialData.animal = animalId
    }

    if (Object.keys(initialData).length > 0) {
      dispatch(resetGestationalCalendarDraft(initialData))
    } else {
      dispatch(resetGestationalCalendarDraft({}))
    }
  }, [animalId, dispatch, gestationalCalendarDraft.id])

  return gestationalCalendarDraft
}

export default useInitializeGestationCalendar
