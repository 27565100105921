import { useLocation } from "react-router-dom"
import styles from "./bottom-menu.module.css"
import FarmIcon from "@/assets/icons/farm/farm-icon.tsx"
import HomeIcon from "@/assets/icons/misc/home-icon.tsx"
import NewMenuIcon from "@/assets/icons/misc/new-menu-icon.tsx"
import BottomMenuItem from "@/components/bottom-menu/bottom-menu-item/bottom-menu-item.tsx"
import NotificationsButton from "@/components/bottom-menu/notifications-button/notifications-button.tsx"
import {
  FARM_SCREEN_PATH,
  HOME_SCREEN_PATH,
  MENU_PATH,
} from "@/utils/constants/routes"

/**
 * A bottom menu component for navigating between different sections of the application.
 * It includes links to Home, Tasks, Farm, and Account sections.
 * Additionally, it provides an overlay menu for adding new task and accessing task-related features.
 * @returns {JSX.Element} The JSX element representing the BottomMenu component.
 */
function BottomMenu(): JSX.Element {
  const location = useLocation()

  return (
    <div className={styles.bottomMenuWrapper}>
      <div className={styles.bottomMenu}>
        <BottomMenuItem
          isActive={location.pathname.includes(HOME_SCREEN_PATH)}
          to={HOME_SCREEN_PATH}
          label="homeTab"
          alt="home"
        >
          <HomeIcon
            size={24}
            is_active={location.pathname.includes(HOME_SCREEN_PATH)}
          />
        </BottomMenuItem>

        <BottomMenuItem
          isActive={location.pathname.includes(FARM_SCREEN_PATH)}
          to={FARM_SCREEN_PATH}
          label="farmTab"
          alt="farm"
        >
          <FarmIcon
            size={24}
            is_active={location.pathname.includes(FARM_SCREEN_PATH)}
          />
        </BottomMenuItem>
        <NotificationsButton />
        <BottomMenuItem
          isActive={location.pathname.includes(MENU_PATH)}
          to={MENU_PATH}
          label="menu"
          alt="menu"
        >
          <NewMenuIcon
            size={24}
            is_active={location.pathname.includes(MENU_PATH)}
          />
        </BottomMenuItem>
      </div>
    </div>
  )
}

export default BottomMenu
