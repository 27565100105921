export const RO_TRANSLATIONS: Record<string, any> = {
  translation: {
    hello: "Salut!",
    featureNotYetImplemented:
      "Această funcționalitate nu este încă implementată.",
    yes: "Da",
    no: "Nu",

    // time

    yesterday: "Ieri",
    today: "Astăzi",
    tomorrow: "Mâine",
    thisWeek: "Săptămâna aceasta",
    thisMonth: "Luna aceasta",
    lastMonth: "Luna trecută",
    thisYear: "Anul acesta",
    lastYear: "Anul trecut",
    pastYears: "Anii trecuți",

    //Bottom Menu
    homeTab: "Acasă",
    tasksTab: "Tasks",
    addBottomMenu: "Adaugă",
    farmTab: "Ferma mea",
    notifications: "Notificǎri",
    notification: "Notificare",
    menu: "Meniu",
    accountTab: "Contul meu",

    //Home page

    // Header
    oneSecond: "Un moment...",

    // Weathger Widget
    weather: "Vremea",

    // Notes
    notes: "Notițe",
    addNote: "Adaugă o notiță",

    //Create note

    newNote: "Notiță nouă",
    myNote: "Notița mea",
    placeholderTitle: "Titlu",
    placeholderText: "Textul tǎu",
    deleteNote: "Șterge notiță",
    searchNote: "Caută notiță",
    shareNote: "Distribuie notițǎ",
    formatNote: "Formatare",
    notesBackConfirmation:
      "Ați făcut modificări. Sigur doriți să părăsiți fără a salva?",
    sureWantToLeave: "Sigur doriți să părăsiți fără a salva?",
    youMadeChanges: "Ați făcut modificări.",
    unsavedChanges: "Modificări nesalvate!",
    continueWhereYouLeftOff: "Continuă de unde ai rămas",
    youHaveUnsavedChanges:
      "Aveți modificări nesalvate din sesiunea anterioarǎ. Doriti sa continuati cu aceste modificari sau sa reununtati la ele?",
    youWillLoseChanges:
      "Veți pierde modificările nesalvate. Doriți să continuați?",
    backConfirmation: "Eşti sigur cǎ vrei sǎ te întorci la Notițe?",
    noteWillBeDeleted: "Notița în curs va fi ştearsǎ dacǎ da.",
    continue: "Continuǎ",
    leave: "Părăsește",
    discard: "Renunță",
    delete: "Șterge",
    stay: "Rămâi",
    save: "Salvează",
    goToNotes: "Mergi la notițe",
    seeAllNotes: "Vezi toate notițele",
    deleteNoteConfirmation: "Eşti sigur cǎ vrei sǎ ştergi Notița?",
    deletingNote: "Se şterge notița ...",
    noteDeleted: "Notița a fost ştearsǎ!",
    errorDeletingNote: "A apǎrut o eroare la ştergerea notiței!",
    updatingNote: "Se actualizeazǎ notița ...",
    noteUpdated: "Notița a fost actualizatǎ!",
    errorUpdatingNote: "A apǎrut o eroare la actualizarea notiței!",
    postingNote: "Se creeazǎ notița ...",
    notePosted: "Notița a fost creatǎ!",
    notePostError: "A apǎrut o eroare la crearea notiței!",
    noNoteAssociated: "Nicio notiță asociată",
    oneNoteAssociated: "O notiță asociată",
    notesAssociated: "notițe asociate",
    noteContentIsMandatory: "Continutul notitei este obligatoriu !",
    seeOtherNotesAssociated: "Vezi celelalte notițe asociate",
    hideOtherNotesAssociated: "Ascunde celelalte notițe asociate",
    draft: "schițǎ",
    unsavedChangesIndicator: "modificǎri nesalvate",
    notEnoughData: "Nu există suficiente date pentru a afișa graficul",
    notesWillBeSetAsDraft:
      "Notițele vor fi setate ca şi schițǎ.Puteți revenii ulterior pentru a salva modificǎrile.",
    noteWasLoadedWithPreviousData:
      "Notița a fost incarcatǎ cu datele anterioare. Doriti sa pastrati datele si sa continuati?",

    //Tasks

    taskWasLoadedWithPreviousData:
      "Sarcina a fost incarcatǎ cu datele anterioare. Doriti sa pastrati datele si sa continuati?",
    tasks: "Sarcini",
    dueTasks: "Sarcini Restante",
    currentTasks: "Sarcini Curente",
    futureTasks: "Sarcini Viitoare",
    searchTask: "Cautǎ Sarcinǎ",
    newTask: "Sarcinǎ Nouǎ",
    myTask: "Sarcina Mea",
    title: "Titlu",
    description: "Descriere",
    deleteTask: "Şterge Sarcinǎ",
    disinfestation: "Deparazitare",
    treatments: "Tratamente",
    status: "Status",
    category: "Categorie",
    ALL: "Toate Sarcinile",
    DONE: "Sarcini Finalizate",
    TODO: "Sarcini Curente",
    backConfirmationTasks: "Eşti sigur cǎ vrei sǎ te întorci la Sarcini?",
    backConfirmationSettings: "Eşti sigur cǎ vrei sǎ te întorci la Setări?",
    taskWillBeDeleted: "Sarcina în curs va fi ştearsǎ.",
    modificationsWillBeLost: "Modificările vor fi pierdute.",
    onTime: "La timp",
    before30: "30 min înainte",
    beforeHour: "1 orǎ înainte",
    beforeDay: "1 zi înainte",
    beforeWeek: "1 sǎptămânǎ înainte",
    daily: "Zilnic",
    weekly: "Sǎptǎmânal",
    monthly: "Lunar",
    yearly: "Anual",
    Date: "Data",
    Hour: "Ora",
    Reminder: "Reminder",
    Recurrence: "Recurență",
    AssociateNote: "Asociază Notiță",
    AssociateAnimal: "Asociază Animal",
    AssociateTask: "Asociază Sarcină",
    dashboardCurrentTasks: "Curente",
    dashboardFutureTasks: "Viitoare",
    dashboardFinalizedTasks: "Finalizate",
    dashboardOverdueTasks: "Restante",
    seeAllTasks: "Vezi toate sarcinile",
    deleteTaskConfirmation: "Eşti sigur cǎ vrei sǎ ştergi Sarcina?",
    allCategories: "Toate categoriile",
    noNewTasks: "Nu ai sarcini noi",
    updatingTask: "Se actualizeazǎ sarcina ...",
    taskUpdated: "Sarcina a fost actualizatǎ!",
    errorUpdatingTask: "A apǎrut o eroare la actualizarea sarcinii!",
    creatingTask: "Se creeazǎ sarcina ...",
    taskCreated: "Sarcina a fost creatǎ!",
    errorCreatingTask: "A apǎrut o eroare la crearea sarcinii!",
    deletingTask: "Se şterge sarcina ...",
    taskDeleted: "Sarcina a fost ştearsǎ!",
    errorDeletingTask: "A apǎrut o eroare la ştergerea sarcinii!",
    oneTaskAssociated: "O sarcină asociată",
    tasksAssociated: "sarcini asociate",
    taskNotFound: "Sarcina nu a fost găsită!",
    titleRequired: "Titlul este obligatoriu pentru a crea o sarcină",
    title5Characters: "Titlul trebuie să aibă cel puțin 5 caractere",
    title100Characters: "Titlul trebuie să aibă cel mult 100 de caractere",
    addCategory: "Adaugă Categorie",
    editCategory: "Editează Categoria",
    selectTaskCategory: "Selectează Categoria Sarcinii",
    taskWillBeSetAsDraft:
      "Sarcina va fi setată ca şi schițǎ. Puteți revenii ulterior pentru a salva modificǎrile.",
    taskSetAsDraft: "Sarcina a fost setată ca şi schițǎ.",
    addTask: "Adaugă Sarcină",

    //Farm

    animalWasLoadedWithPreviousData:
      "Animalul a fost incarcat cu datele anterioare.Doriti sa pastrati datele si sa continuati?",
    animalWillBeSetAsDraft:
      "Animalul va fi setat ca şi schițǎ.Puteți revenii ulterior pentru a salva modificǎrile.",
    ain: "Nr. Crotal",
    myFarm: "Ferma mea",
    searchAnimal: "Cautǎ animal: Nume, nr crotal, status",
    searchBreed: "Caută Rasă",
    newAnimal: "Adaugǎ Animal",
    myAnimal: "Animalul meu",
    backConfirmationAnimals: "Eşti sigur cǎ vrei sǎ te întorci la Ferma mea?",
    animalWillBeDeleted: "Animalul în curs va fi şters dacǎ da.",
    name: "Nume",
    surname: "Prenume",
    taskTitleError: "Titlul este obligatoriu pentru a crea o sarcinǎ",
    crotal: "Crotal",
    Male: "Mascul",
    Female: "Femelǎ",
    allGenders: "Ambele Sexe",
    sex: "Sex",
    registryEntryWay: "Modalitate intrare în Registru",
    Birth: "Fǎtare",
    Beef: "Carne",
    Meat: "Carne",
    Purchase: "Achiziție",
    Production: "Producție",
    Dairy: "Lapte",
    details: "Detalii",
    categories: "Categorii",
    deleteAnimal: "Şterge animal",
    birthDate: "Data naşterii",
    entryDate: "Data intrare în exploatare",
    ALL_ANIMALS: "Toate categoriile",
    ALL_GENDERS: "Toate sexele",
    Cow: "Bovine",
    Sheep: "Ovine",
    Pork: "Porcine",
    Goat: "Caprine",
    breed: "Rasa",
    exploitEntryDate: "Dată intrare în exploatare",
    calves_count: "Fătări",
    gestation_count: "Confirmǎri de gestație",
    medical_treatments_count: "Tratamente",
    insemination_count: "Montǎ / Înseminări",
    lactation_count: "Lactații",
    nameAnimalError: "* Numele animalului este obligatoriu",
    categoryAnimalError: "* Categoria animalului este obligatorie",
    breedAnimalError: "* Rasa animalului este obligatorie",
    crotalAnimalError: "* Nr crotal animalului este obligatoriu",
    sexAnimalError: "* Sexul animalului este obligatoriu",
    birthDateAnimalError: "* Data nașterii animalului este obligatorie",
    entryDateAnimalError: "* Data intrării în exploatare este obligatorie",
    registryEntryWayAnimalError:
      "* Modalitatea de intrare în registru este obligatorie",
    productionAnimalError: "* Tipul de producție este obligatoriu",
    detailsAnimalError: "* Detaliile animalului sunt obligatorii",
    exploitEntryDateAnimalError:
      "* Data intrării în exploatare este obligatorie",
    fieldError: "* Acest câmp este obligatoriu",
    age: "Vârsta",
    years: "ani",
    months: "luni",
    allAges: "Toate vârstele",
    age02cow: "Vițel",
    age23cow: "Junicǎ",
    age3plusCow: "Maturǎ",
    age510: "5 - 10 ani",
    species: "Specii",
    allSpecies: "Toate Speciile",
    calf: "Vițel",
    veal: "Junicǎ",
    mature: "Maturǎ",
    cow: "Vacǎ",
    ALL_SPECIES: "Toate Speciile",
    heads: "Bovine",
    addAnimal: "Adaugǎ animal",
    animalSummary: "Sumar Date Animal",
    animalMother: "Mama Animalului",
    animalFather: "Tatǎl Animalului",
    seeFarm: "Vezi ferma mea",
    deleteAnimalConfirmation: "Eşti sigur cǎ vrei sǎ ştergi animalul?",
    deletingAnimal: "Se şterge animalul ...",
    animalDeleted: "Animalul a fost şters!",
    errorDeletingAnimal: "A apǎrut o eroare la ştergerea animalului!",
    updatingAnimal: "Se actualizeazǎ animalul ...",
    animalUpdated: "Animalul a fost actualizat!",
    animalUpdateError: "A apǎrut o eroare la actualizarea animalului!",
    creatingAnimal: "Se creeazǎ animalul ...",
    animalCreated: "Animalul a fost creat!",
    animalCreateError: "A apǎrut o eroare la crearea animalului!",
    allAnimals: "Toate animalele",
    noAnimalAssociated: "Niciun animal asociat",
    oneAnimalAssociated: "Un animal asociat",
    oneAnimalSelected: "Un animal selectat",
    animalsAssociated: "animale asociate",
    seeOtherAnimalsAssociated: "Vezi celelalte animale asociate",
    hideOtherAnimalsAssociated: "Ascunde celelalte animale asociate",
    animalsSelected: "animale selectate",
    ainRequired: "Nr. Crotal este obligatoriu pentru a crea un animal",
    genderRequired: "Sexul este obligatoriu pentru a crea un animal",
    birthDateRequired: "Data nașterii este obligatorie pentru a crea un animal",
    entryDateRequired:
      "Data intrării în exploatare este obligatorie pentru a crea un animal",
    entryWayIsRequired: "Modalitatea de intrare în registru este obligatorie",
    intendedPurposeIsRequired: "Producția este obligatorie",
    speciesRequired: "Specia este obligatorie pentru a crea un animal",
    breedIsRequired: "Rasa este obligatorie pentru a crea un animal",
    entryDateMustBeLaterThanBirthDate:
      "Data intrării trebuie să fie ulterioară datei nașterii",
    birthDateCannotBeInTheFuture: "Data nașterii nu poate fi în viitor",
    entryDateCannotBeInTheFuture: "Data intrării nu poate fi în viitor",
    nameFarmRequired: "Numele fermei este obligatoriu!",
    nameMaxLength: "Numele trebuie să aibă cel mult 100 de caractere!",
    selectAnimalMother: "Selectează mama animalului",
    selectAnimalFather: "Selectează tatǎl animalului",

    //Animal statuses

    ALL_STATUSES: "Toate Statusurile",
    HEAT: "Călduri",
    GESTATING: "Gestante",
    LACTATING: "Lactație",
    ILL: "De investigat",
    HEALTHY: "Sănătoase",
    DRY: "Repaus Mamar",

    //Menu

    gestationPeriodCalendar: "Calendar Gestație",
    jurnal: "Jurnal",
    vocalAssistant: "Asistent Vocal",
    contactTheSpecialist: "Contactează Specialistul",
    contactSpecialist: "Contacteazǎ Specialistul",
    voiceAssistant: "Asistent Vocal",

    //Dashboard

    goodMorning: "Bunǎ dimineața",
    goodAfternoon: "Bunǎ ziua",
    goodEvening: "Bunǎ seara",
    Monday: "Luni",
    Tuesday: "Marți",
    Wednesday: "Miercuri",
    Thursday: "Joi",
    Friday: "Vineri",
    Saturday: "Sâmbătă",
    Sunday: "Duminică",
    january: "Ianuarie",
    february: "Februarie",
    march: "Martie",
    april: "Aprilie",
    may: "Mai",
    june: "Iunie",
    july: "Iulie",
    august: "August",
    september: "Septembrie",
    october: "Octombrie",
    november: "Noiembrie",
    december: "Decembrie",
    dashboard: "Dashboard",

    //Notifications

    allNotifications: "Toate notificǎrile",
    ALL_NOTIFICATIONS: "Toate notificǎrile",
    ALERTS: "Alerte",
    REMINDERS: "Remindere",
    INFO: "Informǎri",
    MANDATORY: "Obligatorii",
    allRead: "Toate",
    read: "Citite",
    unread: "Necitite",
    all: "Toate",
    seeAllNotifications: "Vezi toate notificǎrile",
    noNewNotifications: "Nu ai notificǎri noi",
    notificationWasRead: "Notificarea a fost cititǎ !",
    notificationsWereRead: "Notificǎrile au fost citite !",
    updateNotificationError: "A apǎrut o eroare la actualizarea notificǎrii !",
    updateNotificationsError:
      "A apǎrut o eroare la actualizarea notificǎrilor !",
    notificationError: "A apǎrut o eroare la notificare !",
    updatingNotification: "Se actualizeazǎ notificarea ...",
    updatingNotifications: "Se actualizeazǎ notificǎrile ...",
    followLink: "Urmeazǎ link",
    youSureYouWantToReadThemAll:
      "Sunteți sigur că doriți să marcați toate notificările ca fiind citite?",
    markAllAsRead: "Marcați toate ca citite",
    cancel: "Anulează",

    //Settings

    settings: "Setǎri",
    farmProfile: "Profil Fermǎ",
    companyInfo: "Datele Firmei",
    farmName: "Numele Fermei",
    farmNameRequired: "Numele Fermei este obligatoriu !",
    address: "Adresa",
    location: "Locație",
    selectLocation: "Locație (e.g., Clădirea A, Zona 4, Padoc 2)",
    setFarmImage: "Seteazǎ o nouǎ fotografie a fermei",
    takePhoto: "Fǎ o fotografie",
    choosePhoto: "Alege o fotografie",
    changePhoto: "Schimbǎ fotografia",
    deletePhoto: "Şterge fotografia",
    farmInfo: "Datele Firmei",
    addFarmName: "Numele Firmei",
    addCui: "CUI",
    accountSettings: "Setǎri Cont",
    myProfile: "Profilul Meu",
    resetPassword: "Reseteazǎ Parola",
    notificationPreferences: "Preferințe Notificǎri",
    email: "Adresa de E-mail",
    pushNotifications: "Notificǎri Push",
    editPhone: "Editeazǎ Telefonul",
    setUserImage: "Seteazǎ o nouǎ fotografie a profilului",
    inputCurrentPassword: "Introdu Parola Actualǎ",
    inputNewPassword: "Introdu Noua Parolǎ",
    confirmNewPassword: "Confirmǎ Noua Parolǎ",
    information: "Informații",
    tutorials: "Tutoriale",
    faq: "Întrebǎri frecvente",
    confidentiality: "Politica de Confidențialitate",
    termsAndServices: "Termene şi Servicii",
    appVersion: "Versiunea Aplicației",
    whatIsFarmApp: "Ce este FARM APP?",
    lastUpdate: "Ultima Actualizare",
    yourOpinion: "Pǎrerea ta",
    recommend: "Recomandǎ",
    likeUs: "Like us on Social Media",
    contactUs: "Contacteazǎ-ne",
    followUs: "Urmǎrește-ne",
    recommendUsing: "Recomandă aplicația folosind:",
    subject: "Subiect",
    message: "Mesaj",
    signOut: "Sign out",
    signOutConfirmation: "Eşti sigur cǎ vrei sǎ pǎrǎseşti aplicația?",
    deletePhotoConfirmation: "Eşti sigur cǎ vrei sǎ ştergi fotografia?",
    linkWillBeHere: "Link to our app will be here",
    farmProfileUpdated: "Profilul fermei a fost actualizat!",
    userProfileUpdated: "Profilul a fost actualizat!",
    errorOccurred: "A apǎrut o eroare!",
    processing: "Se proceseazǎ ...",
    contact: "Contact",
    lostPassword: "Am uitat parola",
    password: "Parola",
    lostYourPassword: "Ți-ai uitat parola?",
    dontWorryHappensToAllOfUs: "Nu te îngrijora, ni se întâmplă tuturor!",
    enterEmailResetPassword:
      "Introdu adresa de e-mail mai jos, și îți vom trimite un link de resetare a parolei.",
    wrongLoginCredentials: "Adresa de e-mail sau parola greșită!",
    login: "Autentificare",
    logingIn: "Se autentifică ...",
    back: "Înapoi",
    welcome: "Bine ai venit!",
    nameUserRequired: "Numele este obligatoriu !",
    lastNameRequired: "Prenumele este obligatoriu !",
    lastNameMaxLength: "Prenumele trebuie să aibă cel mult 100 de caractere",
    emailRequired: "Adresa de e-mail este obligatorie !",
    phoneNumberRequired: "Numărul de telefon este obligatoriu !",
    send: "Trimite",
    urlCopiedSuccess: "URL-ul a fost copiat cu succes!",

    // Gestation Period Calendar
    stepByStepGestationEvolution: "Evolutia sarcinii etapa cu etapa",
    computeProbableBirthDate: "Calculeaza data probabila a fatarii",
    testNow: "Testeazǎ acum",
    confirm: "Confirmǎ",
    underDevelopment: "Această funcționalitate este încă în dezvoltare",
    notAvailableInPackage: "Această funcționalitate nu este disponibilǎ",
    gestationalCalculator: "Calculator Gestațional",
    createNewCalendar: "Creează un nou calendar",
    generateCalendar: "Genereazǎ un calendar",
    saveCalendar: "Salveazǎ calendarul",
    calendarSavedSuccessfully: "Calendarul a fost salvat cu succes!",
    animalMustBeFemale:
      "Pentru a crea un calendar gestațional animalul trebuie să fie femelă",
    animalMustBeCow: "Calendarul gestațional este disponibil doar pentru vaci",
    loadingCalendarPreview: "Se încarcă previzualizarea calendarului ...",
    previewLoadedSuccessfully: "Previzualizarea a fost încărcată cu succes!",
    errorLoadingPreview: "A apărut o eroare la încărcarea previzualizării!",
    errorFetchingAnimal: "A apărut o eroare la preluarea animalului!",
    searchCalendar: "Caută calendar",
    selectAnimal: "Selecteaza Animalul",
    gestationalCalendar: "Calendar Gestațional",
    deleteCalendar: "Șterge Calendarul",
    deletingCalendar: "Se șterge calendarul ...",
    calendarDeletedSuccessfully: "Calendarul a fost șters cu succes!",
    calendarDeleteError: "A apărut o eroare la ștergerea calendarului!",
    calendarWillBeDeleted: "Calendarul în curs va fi șters.",
    deleteCalendarConfirmation: "Ești sigur că vrei să ștergi calendarul?",
    deleteAssocAnimalEvents:
      "Doreşti sǎ ştergi şi evenimentele asociate calendarului?",
    daysToCalving: "zile până la fătare",
    daysUntilCalvingIntervalEnd: "zile până la finalul intervalului",
    daysToNextTask: "zile pâna la urmǎtoarea sarcinǎ",
    hoursToNextTask: "ore pâna la urmǎtoarea sarcinǎ",
    minutesToNextTask: "minute pâna la urmǎtoarea sarcinǎ",
    nextActivity: "Urmǎtoarea Activitate",
    gestationalCalendarText:
      "Calculează data fătării și generează sarcini utile pentru perioada de gestație.",
    calendarPreviewLoadedSuccessfully:
      "Previzualizarea calendarului a fost încărcată cu succes!",

    // Finance Widget
    financialJournal: "Jurnal Financiar",
    searchTransaction: "Caută Tranzacție",
    addTransaction: "Adaugă Tranzacție",
    transaction: "Tranzacție",
    transactions: "Tranzacții",
    EXPENSES: "Cheltuieli",
    INCOMES: "Venituri",
    selectTransactionCategory: "Selectează categoria tranzacției",
    EU_FOUNDS: "Fonduri Europene",
    PROFIT: "Profit",
    DONATIONS: "Donații",
    SELLING_GOODS: "Vânzare Produse",
    EQUIPMENT_ACQUISITION: "Achiziție Echipamente",
    MAINTENANCE: "Întreținere",
    ANIMALS_FOOD: "Hrană Animale",
    TREATMENTS: "Tratamente",
    TRANSPORTATION: "Transport",
    VETERINARY: "Servicii Veterinare",
    SALARIES: "Salarii",
    SOFTWARE_ACQUISITION: "Achiziție Software",
    ADD_CATEGORY: "Adaugă Categorie",
    categoryName: "Numele Categoriei",
    loadingCategories: "Se încarcă categoriile ...",
    categorySaved: "Categoria a fost salvată!",
    errorCreatingCategory: "A apărut o eroare la crearea categoriei!",
    savingCategory: "Se salvază categoria ...",
    updatingCategory: "Se actualizează categoria ...",
    categoryUpdated: "Categoria a fost actualizată!",
    errorUpdatingCategory: "A apărut o eroare la actualizarea categoriei!",
    deleteCategory: "Șterge Categoria",
    deletingCategory: "Se șterge categoria ...",
    categoryDeleted: "Categoria a fost ștearsă!",
    errorDeletingCategory: "A apărut o eroare la ștergerea categoriei!",
    transactionHistory: "Istoric Tranzacții",
    savingTransaction: "Se salvează tranzacția ...",
    transactionSaved: "Tranzacția a fost salvată!",
    transactionSaveError: "A apărut o eroare la salvarea tranzacției!",
    deleteTransactionConfirmation: "Ești sigur că vrei să ștergi tranzacția?",
    deleteTransactionCategoryConfirmation:
      "Ești sigur că vrei să ștergi categoria?",
    transactionCategoryWillBeDeleted: "Categoria tranzacției va fi ștearsă.",
    transactionWillBeDeleted: "Tranzacția în curs va fi ștearsă.",
    deleteTransaction: "Șterge Tranzacția",
    transactionDeleted: "Tranzacția a fost ștearsă!",
    startDate: "Data de început",
    viewStats: "Vizualizeazǎ statisticile",
    endDate: "Data de final",
    PERIOD_LAST_WEEK: "Săptămâna trecută",
    PERIOD_LAST_MONTH: "Luna trecută",
    PERIOD_LAST_YEAR: "Anul trecut",
    PERIOD_CUSTOM: "Perioadă personalizată",
    PERIOD_ALL_TIME: "Toată perioada",
    noTransactionsFound: "Upsss, nu s-au găsit tranzacții ...",
    noAnimalsFound: "Upsss, nu s-au găsit animale ...",

    amountRequired: "Suma este obligatorie pentru a crea o tranzacție",
    dateRequired: "Data este obligatorie pentru a crea o tranzacție",
    categoryRequired: "Categoria este obligatorie pentru a crea o tranzacție",
    balance: "Balanțǎ",

    // Error boundary

    weAreSorry: "Ne cerem scuze...",
    anErrorOccurred: "A apărut o eroare.",
    reloadApp: "Reîncarcă aplicația",

    // Events
    events: "Evenimente",
    addEvent: "Adaugă Eveniment",
    confirmCalving: "Confirmarǎ Fătarea",
    calvingConfirmed: "Fǎtarea a fost confirmatǎ",
    selectEventType: "Selectează Tipul Evenimentului",
    selectMedication: "Selectează Medicamentul",
    Treatment: "Tratament",
    Measurement: "Măsurare",
    Movement: "Mișcare",
    Insemination: "Inseminare",
    NaturalMating: "Împerechere Naturală",
    PregnancyCheckPositive: "Verificare Sarcină Pozitivă",
    PregnancyCheckNegative: "Verificare Sarcină Negativă",
    HealthCheckPositive: "Control de Sănătate Pozitiv",
    HealthCheckNegative: "Control de Sănătate Negativ",
    HoofHealthCheck: "Control Sănătate Copită",
    HeatDetection: "Detectare Călduri",
    Abortion: "Avort",
    DryOff: "Oprire Lactație",
    Weaning: "Înțărcare",
    LactationStart: "Începere Lactație",
    LactationEnd: "Sfârșit Lactație",
    DietChange: "Schimbare Dietă",
    Shearing: "Tuns",
    HoofTrimming: "Tundere Copită",
    EarTagging: "Marcaj Ureche",
    Castration: "Castrare",
    Dehorning: "Decornare",
    VeterinaryVisit: "Vizită Veterinar",
    BehaviorObservation: "Observare Comportament",
    Transfer: "Transfer",
    EntryIntoQuarantine: "Intrare în carantină",
    ExitFromQuarantine: "Ieșire din carantină",
    Isolation: "Izolare",
    Vaccination: "Vaccinare",
    IllnessTreatment: "Tratament Boală",
    Deworming: "Deparazitare",
    InjuryTreatment: "Tratament Rănire",
    Surgery: "Chirurgie",
    PostSurgeryFollowUp: "Urmărire Post-Operatorie",
    SupplementAdministration: "Administrare Supliment",
    WeightCheck: "Verificare Greutate",
    BodyConditionScoring: "Evaluare Condiție Corporală",
    Other: "Altele",
    addEventType: "Adaugǎ un nou tip de eveniment",
    addMedicationType: "Adaugǎ un nou tratament",
    eventName: "Numele Evenimentului",
    medicationName: "Numele Tratamentului",
    deleteEventType: "Şterge tipul evenimentului",
    fromLocation: "De la Locația",
    toLocation: "Către Locația",

    HeightCheck: "Verificare Înălțime",
    weight: "Greutate",
    otherObservations: "Alte Observații",
    generalEventDetails: "Detalii Generale Eveniment",
    specificEventDetails: "Detalii Specifice Eveniment",
    value: "Valoare",
    savingEvent: "Se salvează evenimentul ...",
    eventSaved: "Evenimentul a fost salvat!",
    viewEvent: "Vizualizeazǎ evenimentul",
    errorSavingEvent: "A apărut o eroare la salvarea evenimentului!",
    dosage: "Dozaj",
    deleteAnimalEvent: "Șterge Eveniment",
    animalEvent: "Evenimentul Animalului",
    deletingAnimalEvent: "Se șterge evenimentul ...",
    animalEventDeleted: "Evenimentul a fost șters!",
    animalEventDeleteError: "A apărut o eroare la ștergerea evenimentului!",
    deleteAnimalEventConfirmation: "Ești sigur că vrei să ștergi Evenimentul?",
    eventWillBeDeleted: "Evenimentul în curs va fi șters dacă da.",
    updatingAnimalEvent: "Se actualizează evenimentul ...",
    animalEventUpdated: "Evenimentul a fost actualizat!",
    animalEventUpdateError: "A apărut o eroare la actualizarea evenimentului!",

    typeRequired: "Tipul este obligatoriu pentru a crea un eveniment",
    dateRequiredEvent: "Data este obligatorie pentru a crea un eveniment",
    animalRequired: "Animalul este obligatoriu pentru a crea un eveniment",
    fromLocationRequired:
      "Locația de plecare este obligatorie pentru a crea acest eveniment",
    toLocationRequired:
      "Locația de sosire este obligatorie pentru a crea acest eveniment",
    valueRequired: "Valoarea este obligatorie pentru a crea acest eveniment",
    dosageRequired: "Dozajul este obligatoriu pentru a crea acest eveniment",
    medicationRequired:
      "Medicamentul este obligatoriu pentru a crea acest eveniment",

    TreatmentEventType: "Evenimente de Tratament",
    MeasurementEventType: "Evenimente de Măsurare",
    MovementEventType: "Evenimente de Mișcare",
    ReproductiveEventType: "Evenimente de Reproducere",
    OtherEventType: "Alte Evenimente",
    searchAnimalEvent: "Caută Evenimentul Animalului",

    animalEvents: "Evenimentele Animalelor",
    savingEventType: "Se salvază tipul evenimentului ...",
    savingMedicationType: "Se salvează tipul de tratament ...",
    eventTypeSaved: "Tipul evenimentului a fost salvat!",
    medicationTypeSaved: "Tipul de tratament a fost salvat!",
    errorCreatingEventType:
      "A apărut o eroare la crearea tipului evenimentului",
    errorCreatingMedicationType:
      "A apărut o eroare la crearea tipului de tratament",
    updatingEventType: "Se actualizeazǎ tipul evenimentului",
    updatingMedicationType: "Se actualizeazǎ tipul de tratament",
    eventTypeUpdated: "Actualizare cu success!",
    medicationTypeUpdated: "Actualizare cu success!",
    errorUpdatingEventType:
      "A apărut o eroare la actualizarea tipului evenimentului!",
    errorUpdatingMedicationType:
      "A apărut o eroare la actualizarea tipului de tratament!",
    deleteEventTypeConfirmation:
      "Ești sigur că vrei să ștergi tipul evenimentului?",
    deleteMedicationTypeConfirmation:
      "Ești sigur că vrei să ștergi tipul de tratament?",
    eventTypeWillBeDeleted: "Tipul evenimentului va fi ștears.",
    medicationTypeWillBeDeleted: "Tipul de tratament va fi șters.",
    deletingEventType: "Se șterge tipul evenimentului ...",
    deletingMedicationType: "Se șterge tipul de tratament ...",
    eventTypeDeleted: "Tipul evenimentului a fost şters!",
    medicationTypeDeleted: "Tipul de tratament a fost şters!",
    errorDeletingEventType:
      "A apărut o eroare la ștergerea tipului evenimentului",
    errorDeletingMedicationType:
      "A apărut o eroare la ștergerea tipului de tratament",
    chooseEventTypeParent: "Selecteazǎ categoria evenimentului",

    // AI Assistant
    intelligentAssistant: "Asistent Inteligent",
    yourMessage: "Mesajul tău",
    generalHealthStatus:
      "Care este starea generală de sănătate a efectivului de bovine?",
    immediateMedicalAttention:
      "Care sunt vacile care necesită atenție medicală imediată?",
    optimizeFeeding:
      "Cum pot optimiza hrănirea pentru a crește producția de lapte?",
    closeToCalving: "Care sunt vacile care sunt aproape de fătare?",
    milkProductionChange: "Cum s-a schimbat producția de lapte în ultima lună?",
    fertilityProblems:
      "Ce vaci au probleme de fertilitate și ce măsuri pot ajuta?",
    welcomeToAssistant: "Bun venit la Asistentul Inteligent!",
    startConversation:
      "Începe conversația cu cateva dintre sugestiile noastre.",
    loading: "Se încarcă ...",
    UNDER_TREATMENT: "Sub Tratament",
    noResultsForAnimal:
      "Nu au fost gǎsite rezultate pentru animalul specificat.",
    askAssistant: "Întreabă Asistentul",
    virtualFarmAssistant: "Asistentul Virtual al Fermei",
    farmAdviceWithAI: "Sfaturi pentru fermă cu noul chat AI integrat",
    noAnimalsFoundForFilter: "Nu s-au găsit animale pentru filtrul selectat",
    gestationCalendars: "Calendare gestaționale",
    activityCalendar: "Calendar Activități",
    inseminationDate: "Data înseminării",
    possibleCalvingInterval: "Intervalul probabil fătării",
    calvingDate: "Data fătării",
    openCalendar: "Deschide Calendarul",
    seeEvents: "Aratǎ evenimentele",
    hideEvents: "Ascunde evenimentele",
    thisFeatureIsNotEnabled: "Acest modul nu este activ",
    buyFeature: "Achiziționeazǎ modulul",
  },
}
