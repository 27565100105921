import React, { useState } from "react"
import TransactionCategorySelectTrigger from "@/components/drawer-triggers/transaction-category-select-trigger.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import AddTransactionCategoryForm from "@/features/finance/components/add-transaction-category-form/add-transaction-category-form.tsx"
import TransactionCategoryDrawerContent from "@/features/finance/components/transaction-category-drawer-content/transaction-category-drawer-content.tsx"
import { type TransactionCategory } from "@/features/finance/types/transaction.ts"

interface TransactionCategoryDrawerProps {
  selectedCategory: TransactionCategory | undefined
  onCategorySelect: (category: TransactionCategory) => void
}

const TransactionCategoryDrawer: React.FC<TransactionCategoryDrawerProps> = ({
  selectedCategory,
  onCategorySelect,
}) => {
  const [isAddCategoryViewOpen, setIsAddCategoryViewOpen] = useState<{
    isOpen: boolean
    transactionCategoryId: string | null
  }>({
    isOpen: false,
    transactionCategoryId: null,
  })

  const [isOutflow, setIsOutflow] = useState(false)

  const handleBackClick = () => {
    setIsAddCategoryViewOpen({
      isOpen: false,
      transactionCategoryId: null,
    })
  }

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleCategorySelect = (category: TransactionCategory) => {
    onCategorySelect(category)
    setIsModalOpen(false)
  }

  return (
    <Drawer open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DrawerTrigger asChild>
        <TransactionCategorySelectTrigger currentValue={selectedCategory} />
      </DrawerTrigger>
      <DrawerContent
        style={{
          height: "85%",
          width: "100%",
        }}
      >
        {isAddCategoryViewOpen.isOpen ? (
          <AddTransactionCategoryForm
            isOutflow={isOutflow}
            onBackClick={handleBackClick}
            transactionId={isAddCategoryViewOpen.transactionCategoryId}
          />
        ) : (
          <TransactionCategoryDrawerContent
            isOutflow={isOutflow}
            setIsOutflow={setIsOutflow}
            setIsAddCategoryViewOpen={setIsAddCategoryViewOpen}
            onCategorySelect={handleCategorySelect}
            selectedCategory={selectedCategory}
          />
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default TransactionCategoryDrawer
