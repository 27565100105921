import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const HourIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="16 283 30 30"
    >
      <g data-name="Group 2297">
        <path
          d="M23 283h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H23a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="var(--error-color)"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <g data-name="time-svgrepo-com (1)">
          <path
            d="M30.607 307.39a8.996 8.996 0 1 0 0-17.993 8.996 8.996 0 0 0 0 17.993Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.4"
            stroke="#fff"
            fill="transparent"
            data-name="Path 1239"
          />
          <path
            d="M30.607 292.396v5.997"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.4"
            stroke="#fff"
            fill="transparent"
            data-name="Path 1240"
          />
          <path
            d="m34.845 302.632-4.238-4.239"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.4"
            stroke="#fff"
            fill="transparent"
            data-name="Path 1241"
          />
        </g>
      </g>
    </svg>
  )
}

export default HourIcon
