import styles from "./animal-card-wrapper.module.css"

interface AnimalCardWrapperProps {
  onClick?: () => void
  children: React.ReactNode
}

const AnimalCardWrapper: React.FC<AnimalCardWrapperProps> = ({
  onClick,
  children,
}) => {
  return (
    <div className={styles.animalCardWrapper} onClick={onClick}>
      {children}
    </div>
  )
}

export default AnimalCardWrapper
