import { BROWN, DARK_GREY } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuCalendarIcon: React.FC<GeneralSvgProps> = ({
  is_active,
  color,
  size,
}) => {
  const activeColor = is_active ? BROWN : DARK_GREY
  const fillColor = color || activeColor

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size || 30}
      height={size || 30}
      viewBox="154 497.912 35.501 37.488"
    >
      <g data-name="Group 2767">
        <g data-name="calendar-svgrepo-com (2)">
          <path
            d="M179.64 501.857h-15.778v.986a.986.986 0 0 1-1.973 0v-.986h-2.958a2.958 2.958 0 0 0-2.959 2.958v4.93h31.557v-4.93a2.958 2.958 0 0 0-2.958-2.958h-2.959v.986a.986.986 0 0 1-1.972 0v-.986Zm1.972-1.973h2.959a4.93 4.93 0 0 1 4.93 4.931v25.655a4.93 4.93 0 0 1-4.93 4.93h-25.64a4.93 4.93 0 0 1-4.931-4.93v-25.655a4.93 4.93 0 0 1 4.93-4.93h2.96v-.987a.986.986 0 0 1 1.972 0v.986h15.778v-.986a.986.986 0 0 1 1.972 0v.986Zm5.917 11.834h-31.557v18.752a2.958 2.958 0 0 0 2.959 2.958h25.64a2.959 2.959 0 0 0 2.958-2.958v-18.752Z"
            fill={fillColor}
            fillRule="evenodd"
          />
          <path
            d="M179.64 501.857h-15.778v.986a.986.986 0 0 1-1.973 0v-.986h-2.958a2.958 2.958 0 0 0-2.959 2.958v4.93h31.557v-4.93a2.958 2.958 0 0 0-2.958-2.958h-2.959v.986a.986.986 0 0 1-1.972 0v-.986Zm1.972-1.973h2.959a4.93 4.93 0 0 1 4.93 4.931v25.655a4.93 4.93 0 0 1-4.93 4.93h-25.64a4.93 4.93 0 0 1-4.931-4.93v-25.655a4.93 4.93 0 0 1 4.93-4.93h2.96v-.987a.986.986 0 0 1 1.972 0v.986h15.778v-.986a.986.986 0 0 1 1.972 0v.986Zm5.917 11.834h-31.557v18.752a2.958 2.958 0 0 0 2.959 2.958h25.64a2.959 2.959 0 0 0 2.958-2.958v-18.752h0Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            stroke={fillColor}
            fill="transparent"
          />
        </g>
        <path
          d="M162.358 515.721a1.936 1.936 0 1 0 .001 3.87 1.936 1.936 0 0 0-.001-3.87Z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="Path 1423"
        />
        <path
          d="M162.357 523.721a1.936 1.936 0 1 0 .001 3.87 1.936 1.936 0 0 0-.001-3.87Z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="Path 1426"
        />
        <path
          d="M171.75 523.721a1.936 1.936 0 1 0 .001 3.87 1.936 1.936 0 0 0 0-3.87Z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="Path 1427"
        />
        <path
          d="M181.145 523.721a1.936 1.936 0 1 0 .001 3.87 1.936 1.936 0 0 0-.001-3.87Z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="Path 1428"
        />
        <path
          d="M171.75 515.721a1.936 1.936 0 1 0 .001 3.87 1.936 1.936 0 0 0 0-3.87Z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="Path 1424"
        />
        <path
          d="M181.145 515.721a1.936 1.936 0 1 0 .001 3.87 1.936 1.936 0 0 0-.001-3.87Z"
          fill={fillColor}
          fillRule="evenodd"
          data-name="Path 1425"
        />
      </g>
    </svg>
  )
}

export default MenuCalendarIcon
