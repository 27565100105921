import DOMPurify from "dompurify"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { getNotificationIcon } from "../../types/notification-utils.tsx"
import styles from "./notification-view.module.css"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import {
  useGetNotificationByIdQuery,
  useReadNotificationMutation,
} from "@/features/notifications/api/notifications-api.ts"
import { NEW_TASK_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { BIG_ICON_SIZE } from "@/utils/constants/size-constants.ts"
import { formatDateTimeToRo } from "@/utils/helpers/time-utils.ts"

const NotificationView: React.FC = () => {
  const { notificationId: notificationId } = useParams<{
    notificationId: string
  }>()
  const { data: notification } = useGetNotificationByIdQuery(
    notificationId as string,
    {
      skip: !notificationId,
    },
  )
  const notificationIcon = getNotificationIcon(notification, BIG_ICON_SIZE)
  const [readNotificationMutation] = useReadNotificationMutation()
  const { t } = useTranslation()

  useEffect(() => {
    if (notification && notification.read_at === null)
      readNotificationMutation(notification.id)
  }, [notification, readNotificationMutation])

  const sanitizedMessage = DOMPurify.sanitize(notification?.message || "")

  return (
    <div className={styles.notificationView}>
      <MobileTopBar title={t("back")} navigateTo={NEW_TASK_SCREEN_PATH} />
      {notification ? (
        <div className={styles.viewContent}>
          <div className={styles.body}>
            <div className={styles["icon"]}>{notificationIcon}</div>
            <div className={styles.titleContent}>
              <div className={styles["title"]}>{notification?.title}</div>
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
              ></div>
            </div>
            {notification.picture_url && (
              <img
                src={notification.picture_url}
                alt=""
                className={styles.notificationPicture}
              />
            )}
            <div className={styles.timestamp}>
              {formatDateTimeToRo(notification?.created_at)}
            </div>
          </div>
          <div className={styles.notificationBottom}>
            {notification.action?.url && (
              <a
                href={notification.action.url as string}
                target="_blank"
                rel="noreferrer"
                className={styles.actionButton}
              >
                {t("followLink")}
              </a>
            )}
          </div>
        </div>
      ) : (
        <LoadingAnimation />
      )}
    </div>
  )
}

export default NotificationView
