import React from "react"
import { useTranslation } from "react-i18next"
import LocationIcon from "@/assets/icons/farm/location-icon.tsx"
import CustomSelectDropdown from "@/components/custom-select/custom-select-dropdown-input.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import styles from "@/features/animal-events/components/animal-event-editor/events-editor.module.css"
import useGetAnimalEventsLocation from "@/features/animal-events/hooks/use-get-animal-events-location.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  type AnimalEventDraft,
  setDraftEventSubDataFromLocation,
  setDraftEventSubDataToLocation,
} from "@/redux/slices/events-draft-slice.ts"

interface MovementEventEditorProps {
  draftEvent: AnimalEventDraft
}

const MovementEventEditor: React.FC<MovementEventEditorProps> = ({
  draftEvent,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { fromLocation, toLocation } = useGetAnimalEventsLocation()
  const fromLocationOptionArray = Array.from(fromLocation).map((location) => {
    return {
      value: location,
      label: location,
    }
  })

  const toLocationOptionArray = Array.from(toLocation).map((location) => {
    return {
      value: location,
      label: location,
    }
  })

  const handleFromLocationChange = (fromLocation: string) => {
    dispatch(setDraftEventSubDataFromLocation(fromLocation))
  }

  const handleToLocationChange = (toLocation: string) => {
    dispatch(setDraftEventSubDataToLocation(toLocation))
  }

  const isToLocationRequired =
    draftEvent.event_data?.to_location === undefined ||
    draftEvent.event_data?.to_location === ""

  const isFromLocationRequired =
    draftEvent.event_data?.from_location === undefined ||
    draftEvent.event_data?.from_location === ""

  return (
    <>
      <div className={styles.eventInfo}>{t("specificEventDetails")}</div>
      <OverflowMenuItem>
        <CustomSelectDropdown
          options={[...fromLocationOptionArray, ...toLocationOptionArray]}
          placeholder={t("fromLocation")}
          isRequired={isFromLocationRequired}
          currentSelection={draftEvent.event_data?.from_location}
          onSelectionChange={handleFromLocationChange}
          icon={<LocationIcon />}
        />
      </OverflowMenuItem>
      <OverflowMenuItem>
        <CustomSelectDropdown
          options={[...fromLocationOptionArray, ...toLocationOptionArray]}
          placeholder={t("toLocation")}
          isRequired={isToLocationRequired}
          currentSelection={draftEvent.event_data?.to_location}
          onSelectionChange={handleToLocationChange}
          icon={<LocationIcon />}
        />
      </OverflowMenuItem>
    </>
  )
}

export default MovementEventEditor
