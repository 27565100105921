import React from "react"

const PigHeadIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <div
    style={{
      height: "30px",
      width: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "var(--notification-yellow)",
      borderRadius: "5px",
    }}
  >
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 30 30"
    >
      <g id="SvgjsG1014" transform="translate(-3.75 -4.732)">
        <g
          id="Gradient_Line"
          data-name="Gradient Line"
          transform="translate(4 4.982)"
        >
          <path
            id="Path_1798"
            data-name="Path 1798"
            d="M21.468,20.189H16.532c-5.011.112-5,7.787,0,7.9h4.936C26.475,27.974,26.475,20.3,21.468,20.189Zm2.679,4.145a2.683,2.683,0,0,1-2.679,2.681H16.532c-3.584-.053-3.585-5.7,0-5.753h4.936a2.736,2.736,0,0,1,2.679,3.072Zm-7.213-1.766a1.57,1.57,0,1,0,1.569,1.57,1.569,1.569,0,0,0-1.569-1.57Zm0,2.068a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5Zm4.133-2.068a1.57,1.57,0,1,0,1.569,1.57,1.569,1.569,0,0,0-1.569-1.57Zm0,2.068a.5.5,0,1,1,.5-.5.5.5,0,0,1-.5.5ZM34,6.556a.536.536,0,0,0-.536-.536H28.758a5.532,5.532,0,0,0-3.522,1.332,9.413,9.413,0,0,0-12.482-.006A5.519,5.519,0,0,0,9.241,6.02H4.536A.536.536,0,0,0,4,6.556c0,.062,2.824,3.7,5.575,6.666l-1.5,5.246A4.659,4.659,0,0,0,4,23.1v1.753A7.784,7.784,0,0,0,8.919,32.09,27.793,27.793,0,0,0,19,33.982a28.232,28.232,0,0,0,9.954-1.84A7.855,7.855,0,0,0,34,24.857V23.1a4.659,4.659,0,0,0-4.079-4.635l-1.5-5.246A20.059,20.059,0,0,0,34,6.556ZM5.108,7.092H9.242a4.337,4.337,0,0,1,2.751,1.025,9.5,9.5,0,0,0-2.117,4.054A34.81,34.81,0,0,1,5.108,7.092ZM28.992,19.119a.537.537,0,0,0,.493.389,3.59,3.59,0,0,1,3.444,3.6v1.753a6.775,6.775,0,0,1-4.356,6.283,26.814,26.814,0,0,1-19.266-.051,6.705,6.705,0,0,1-4.234-6.233V23.1a3.59,3.59,0,0,1,3.444-3.6.537.537,0,0,0,.493-.389l2.015-7.065A8.189,8.189,0,0,1,14,7.708a8.335,8.335,0,0,1,12.975,4.347Zm-.867-6.948A9.514,9.514,0,0,0,26,8.124l.363-.262a4.108,4.108,0,0,1,2.4-.769h4.133C32.716,8.122,30.286,10.376,28.124,12.171Z"
            transform="translate(-4 -4.982)"
            fill="#fff"
            stroke="#fff"
            strokeWidth="0.5"
          />
        </g>
      </g>
    </svg>
  </div>
)

export default PigHeadIcon
