import React from "react"
import ReactDOM from "react-dom"
import { useSelector } from "react-redux"
import { MODAL_COMPONENTS } from "@/features/modals/types/modals.tsx"
import { type RootState } from "@/redux/store.ts"

const ModalPortal: React.FC = () => {
  const { modalType, modalProps } = useSelector(
    (state: RootState) => state.modal,
  )

  if (!modalType) {
    return null
  }

  const SpecificModal = MODAL_COMPONENTS[modalType]
  return ReactDOM.createPortal(
    <SpecificModal {...modalProps} />,
    document.getElementById("modal-root")!,
  )
}

export default ModalPortal
