import React from "react"
import styles from "./events-table.module.css"
import EventsTableRow from "@/features/animal-events/components/events-table/events-table-row.tsx"
import { type GenericAnimalEvent } from "@/features/animal-events/types/animal-events.ts"

interface EventTableProps {
  events: GenericAnimalEvent[]
}

const EventTable: React.FC<EventTableProps> = ({ events }) => {
  return (
    <table className={styles.tableContainer}>
      <thead className={styles.tableHeader}>
        <tr>
          <th className={styles.tableHeaderCell}>Tip</th>
          <th className={styles.tableHeaderCell}>Data</th>
        </tr>
      </thead>
      <tbody>
        {events.map((event, index) => (
          <EventsTableRow event={event} key={index} />
        ))}
      </tbody>
    </table>
  )
}

export default EventTable
