import React from "react"
import styles from "./assoc-entity-from-note-trigger.module.css"
import MenuTasksIcon from "@/assets/icons/menu/menu-tasks-icon.tsx"
import { CUSTOM_TOOLBAR_ICON_COLOR } from "@/utils/constants/colors-constants.ts"

interface CustomTriggerProps {
  currentValue: string[]
  onClick: () => void
}

const AssocTaskFromNoteTrigger = React.forwardRef<
  HTMLDivElement,
  CustomTriggerProps
>(({ currentValue, onClick }, ref) => {
  return (
    <div className={styles.overlayItem} onClick={onClick} ref={ref}>
      <div className={styles.overlayText}>
        {currentValue?.length > 0 && currentValue.length}
      </div>
      <div className={styles.cardIcon}>
        <MenuTasksIcon size={25} stroke={CUSTOM_TOOLBAR_ICON_COLOR} />
      </div>
    </div>
  )
})

AssocTaskFromNoteTrigger.displayName = "AssocTaskFromNoteTrigger"

export default AssocTaskFromNoteTrigger
