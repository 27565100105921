import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { ANIMAL_GENDERS } from "@/features/farm"
import { useLazyGetAnimalByIdQuery } from "@/features/farm/api/animals-api.ts"
import {
  useCreateGestationCalendarMutation,
  useDeleteGestationCalendarMutation,
} from "@/features/gestational-calendar/api/gestation-calendar-api.ts"
import GenerateCalendarForm from "@/features/gestational-calendar/components/generate-calendar-form/generate-calendar-form.tsx"
import GestationCalendarDetails from "@/features/gestational-calendar/components/gestation-calendar-details/gestation-calendar-details.tsx"
import GestationalCalendarDraftButtons from "@/features/gestational-calendar/components/navigation-buttons/gestational-calendar-draft-buttons.tsx"
import useInitializeGestationCalendar from "@/features/gestational-calendar/hooks/use-initialize-gestation-calendar.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  resetGestationalCalendarDraft,
  setGestationalCalendarDraft,
} from "@/redux/slices/gestational-calendars-draft-slice.ts"
import { ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH } from "@/utils/constants/routes.ts"

const NewCalendarView = () => {
  const gestationalCalendarDraft = useInitializeGestationCalendar()
  const [step, setStep] = useState(1)
  const { t } = useTranslation()
  const { openModal } = useModal()
  const navigate = useNavigate()

  // if user opened an already created calendar, skip directly to second step
  useEffect(() => {
    if (!gestationalCalendarDraft.id) {
      return
    } else {
      setStep(2)
      toast.success(t("calendarPreviewLoadedSuccessfully"))
    }
    // this is needed since if I include the gestationCalendar as a dep the effect will trigger when
    // the post happens as the state is updated with the response
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [createGestationCalendar, { isLoading }] =
    useCreateGestationCalendarMutation()
  const [getAnimalByIdMutation] = useLazyGetAnimalByIdQuery()
  const [deleteCalendarMutation] = useDeleteGestationCalendarMutation()
  const dispatch = useAppDispatch()
  const { handleBackNavigation } = useNavigationFallback()

  const handleCreateGestationCalendar = () => {
    getAnimalByIdMutation(gestationalCalendarDraft.animal)
      .unwrap()
      .then((animal) => {
        if (animal.gender !== ANIMAL_GENDERS.FEMALE.label) {
          toast.error(t("animalMustBeFemale"))
          return
        }
        if (animal?.species_details?.name !== "Cow") {
          toast.error(t("animalMustBeCow"))
          return
        }

        const createCalendarPromise = createGestationCalendar({
          animal: gestationalCalendarDraft.animal,
          insemination_date: gestationalCalendarDraft.insemination_date,
        })
          .unwrap()
          .then((response) => {
            setStep(2)
            dispatch(setGestationalCalendarDraft(response))
          })

        toast.promise(createCalendarPromise, {
          pending: t("loadingCalendarPreview"),
          success: t("previewLoadedSuccessfully"),
          error: t("errorLoadingPreview"),
        })
      })
      .catch(() => {
        toast.error(t("errorFetchingAnimal"))
      })
  }

  const handleSaveCalendar = () => {
    toast.success(t("calendarSavedSuccessfully"))
    dispatch(resetGestationalCalendarDraft({}))
    navigate(ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH)
  }

  const handleDeleteCalendar = () => {
    deleteCalendarMutation({
      gestationCalendarId: gestationalCalendarDraft.id,
      delete_animal_events: true,
    })
      .unwrap()
      .then(() => {
        toast.success(t("calendarDeletedSuccessfully"))
        handleBackNavigation()
        setTimeout(() => dispatch(resetGestationalCalendarDraft({})), 500)
      })
  }

  const onBackClick = () => {
    if (step === 1) {
      handleBackNavigation()
    } else {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          deleteCalendarMutation({
            gestationCalendarId: gestationalCalendarDraft.id,
            delete_animal_events: true,
          })
            .unwrap()
            .then(() => {
              handleBackNavigation()
              setTimeout(() => dispatch(resetGestationalCalendarDraft({})), 500)
            })
        },
      })
    }
  }

  return (
    <>
      <EditorTopBar
        isModified={false}
        text={t("createNewCalendar")}
        onBackClick={onBackClick}
      />
      {isLoading && <LoadingAnimation />}
      {step === 1 && !isLoading && (
        <PageContentWrapperLarge hasPadding={false}>
          <GenerateCalendarForm
            createGestationCalendar={handleCreateGestationCalendar}
            gestationCalendarDraft={gestationalCalendarDraft}
          />
        </PageContentWrapperLarge>
      )}
      {step === 2 && !isLoading && (
        <PageContentWrapperLarge>
          <GestationCalendarDetails
            gestationalCalendar={gestationalCalendarDraft}
          />
          <div
            style={{
              marginBottom: "100px",
            }}
          />
          <GestationalCalendarDraftButtons
            onSaveClick={handleSaveCalendar}
            onDeleteClick={handleDeleteCalendar}
          />
        </PageContentWrapperLarge>
      )}
    </>
  )
}

export default NewCalendarView
