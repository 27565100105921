import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { format } from "date-fns"
import { type AnimalDraft } from "@/features/farm"

type AnimalDraftSlice = {
  drafts: Record<string, AnimalDraft>
}

export const initialAnimalState: AnimalDraft = {
  is_healthy: true,
  is_under_treatment: false,
  name: "",
  picture: null,
  species: "",
  ain: "",
  gender: "",
  breed: "",
  description: "",
  location: "",
  birth_date: format(new Date(), "yyyy-MM-dd"),
  entry_date: format(new Date(), "yyyy-MM-dd"),
  entry_way: "",
  mother: "",
  father: "",
  tasks: [],
  notes: [],
  intended_purpose: "",
  medical_treatments_count: 0,
}

const initialState: AnimalDraftSlice = {
  drafts: {},
}

const animalDraftSlice = createSlice({
  name: "animalDraft",
  initialState,
  reducers: {
    setAnimalDraft: (
      state,
      action: PayloadAction<{ id: string; draft: AnimalDraft }>,
    ) => {
      state.drafts[action.payload.id] = action.payload.draft
    },
    setDraftAnimalName: (
      state,
      action: PayloadAction<{ id: string; name: string }>,
    ) => {
      state.drafts[action.payload.id].name = action.payload.name
    },
    setDraftAnimalSpecies: (
      state,
      action: PayloadAction<{ id: string; species: string }>,
    ) => {
      state.drafts[action.payload.id].species = action.payload.species
    },
    setDraftAnimalBreed: (
      state,
      action: PayloadAction<{ id: string; breed: string }>,
    ) => {
      state.drafts[action.payload.id].breed = action.payload.breed
    },
    setDraftAnimalGender: (
      state,
      action: PayloadAction<{ id: string; gender: string }>,
    ) => {
      state.drafts[action.payload.id].gender = action.payload.gender
    },
    setAnimalDraftAin: (
      state,
      action: PayloadAction<{ id: string; ain: string }>,
    ) => {
      state.drafts[action.payload.id].ain = action.payload.ain
    },
    setDraftAnimalBirthDate: (
      state,
      action: PayloadAction<{ id: string; birth_date: string }>,
    ) => {
      state.drafts[action.payload.id].birth_date = action.payload.birth_date
    },
    setDraftAnimalEntryDate: (
      state,
      action: PayloadAction<{ id: string; entry_date: string }>,
    ) => {
      state.drafts[action.payload.id].entry_date = action.payload.entry_date
    },
    setDraftAnimalEntryWay: (
      state,
      action: PayloadAction<{ id: string; entry_way: string }>,
    ) => {
      state.drafts[action.payload.id].entry_way = action.payload.entry_way
    },
    setDraftAnimalDescription: (
      state,
      action: PayloadAction<{ id: string; description: string }>,
    ) => {
      state.drafts[action.payload.id].description = action.payload.description
    },
    setDraftAnimalPicture: (
      state,
      action: PayloadAction<{ id: string; picture: string | null }>,
    ) => {
      state.drafts[action.payload.id].picture = action.payload.picture
    },
    setDraftAnimalLocation: (
      state,
      action: PayloadAction<{ id: string; location: string }>,
    ) => {
      state.drafts[action.payload.id].location = action.payload.location
    },
    setDraftAnimalMother: (
      state,
      action: PayloadAction<{ id: string; mother: string }>,
    ) => {
      state.drafts[action.payload.id].mother = action.payload.mother
    },
    setDraftAnimalFather: (
      state,
      action: PayloadAction<{ id: string; father: string }>,
    ) => {
      state.drafts[action.payload.id].father = action.payload.father
    },
    setDraftAnimalIntendedPurpose: (
      state,
      action: PayloadAction<{ id: string; intended_purpose: string }>,
    ) => {
      state.drafts[action.payload.id].intended_purpose =
        action.payload.intended_purpose
    },
    setDraftAnimalTreatmentCount: (
      state,
      action: PayloadAction<{ id: string; medical_treatments_count: number }>,
    ) => {
      state.drafts[action.payload.id].medical_treatments_count =
        action.payload.medical_treatments_count
    },
    resetAnimalDraft: (
      state,
      action: PayloadAction<{ id: string; draft?: Partial<AnimalDraft> }>,
    ) => {
      state.drafts[action.payload.id] = {
        ...initialAnimalState,
        ...action.payload.draft,
      }
    },
    deleteAnimalDraft: (state, action: PayloadAction<string>) => {
      delete state.drafts[action.payload]
    },
  },
})

export default animalDraftSlice.reducer

export const {
  setAnimalDraft,
  setDraftAnimalName,
  setAnimalDraftAin,
  setDraftAnimalSpecies,
  setDraftAnimalBreed,
  setDraftAnimalGender,
  setDraftAnimalBirthDate,
  setDraftAnimalEntryDate,
  setDraftAnimalEntryWay,
  setDraftAnimalDescription,
  setDraftAnimalPicture,
  setDraftAnimalLocation,
  setDraftAnimalMother,
  setDraftAnimalFather,
  resetAnimalDraft,
  deleteAnimalDraft,
  setDraftAnimalIntendedPurpose,
  setDraftAnimalTreatmentCount,
} = animalDraftSlice.actions
