import React, { forwardRef } from "react"
import styles from "./page-wrapper-content.module.css"

interface PageContentWrapperSmallProps {
  children: React.ReactNode
  hasPadding?: boolean
}

const PageContentWrapperAI = forwardRef<
  HTMLDivElement,
  PageContentWrapperSmallProps
>(({ children, hasPadding = true }, ref) => {
  return (
    <div
      ref={ref}
      className={styles.scrollableContentAI}
      style={{
        padding: hasPadding ? "16px" : 0,
      }}
    >
      {children}
    </div>
  )
})

export default PageContentWrapperAI
