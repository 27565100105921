import { toast } from "react-toastify"
import * as Yup from "yup"
import { type TaskDraft } from "@/features/tasks"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

export const taskSchema = Yup.object().shape({
  title: Yup.string().required("titleRequired").max(100, "title100Characters"),
})

export const validateTask = async (task: TaskDraft) => {
  try {
    await taskSchema.validate(task, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
