import { useParams } from "react-router-dom"
import styles from "./notes-layout-desktop.module.css"
import { NoteEditorView, NotesPage } from "@/features/notes"

const NotesLayoutDesktop = () => {
  // This will ensure noteId is either a string or undefined
  const { noteId } = useParams<{ noteId?: string }>()

  return (
    <div className={styles["flex"]}>
      <NotesPage />
      {/* Only provide key if noteId is not undefined */}
      <NoteEditorView key={noteId || undefined} />
    </div>
  )
}

export default NotesLayoutDesktop
