import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import styles from "../settings-routes.module.css"
import BackArrow from "@/assets/icons/misc/back-arrow.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import { WHITE } from "@/utils/constants/colors-constants.ts"
import { HOME_SCREEN_PATH } from "@/utils/constants/routes.ts"

const NotEnabled = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const handleBuyFeatureClick = () => {
    toast.warning(t("underDevelopment"))
  }

  return (
    <div className={styles.notPurchasedWrapper}>
      <NoResultsFound message={t("thisFeatureIsNotEnabled")}>
        <div className={styles.buttonsWrapper}>
          <button
            data-is-enabled={false}
            className={styles.actionButton}
            onClick={handleBuyFeatureClick}
          >
            {t("buyFeature")}
          </button>
          <button
            className={styles.actionButton}
            onClick={() => navigate(HOME_SCREEN_PATH)}
          >
            <BackArrow color={WHITE} />
            {t("back")}
          </button>
        </div>
      </NoResultsFound>
    </div>
  )
}

export default NotEnabled
