import { useLayoutEffect } from "react"
import { useGetMyFarmQuery } from "@/features/settings-account/api/account-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setFarmDraft } from "@/redux/slices/farm-profile-draft-slice.ts"

const useGetDraftFarmProfile = () => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetMyFarmQuery()

  const draftFarmProfile = useAppSelector(
    (state) => state.farmProfileSlice.draft,
  )

  useLayoutEffect(() => {
    if (data) {
      dispatch(setFarmDraft(data))
    }
  }, [data, dispatch])

  return { draftFarmProfile, data, isLoading }
}

export default useGetDraftFarmProfile
