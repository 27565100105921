import { useCallback, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import ArrowDown from "@/assets/icons/misc/arrow-down.tsx"
import TickIcon from "@/assets/icons/misc/tick-icon.tsx"
import styles from "@/components/shadcn/popover/popover.module.css"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/shadcn/popover.tsx"
import { BROWN } from "@/utils/constants/colors-constants.ts"
import { type Option } from "@/utils/types/misc-types.ts"

interface GenericPopoverProps<T = string> {
  options: Option<T>[]
  placeholder: string
  onSelectionChange: (selection: T) => void
  currentSelection?: T | null
  icon?: React.ReactNode
}

function CustomSelectDropdown<T = string>({
  options,
  placeholder,
  onSelectionChange,
  currentSelection,
  icon,
}: GenericPopoverProps<T>) {
  const getCurrentSelectedOption = useCallback(() => {
    if (currentSelection !== undefined) {
      return options.find((option) => option.value === currentSelection)
    } else {
      return options[0]
    }
  }, [currentSelection, options])

  const selectedOption = getCurrentSelectedOption()

  const { t } = useTranslation()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleSelectionChange = (selection: Option<T>) => {
    onSelectionChange(selection.value)
    setIsPopoverOpen(false)
  }

  const triggerRef = useRef<HTMLDivElement>(null)

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <div className={styles.trigger} ref={triggerRef}>
          <div className={styles.iconWrapper}>
            {icon}
            {t(selectedOption ? selectedOption.label : placeholder)}
          </div>
          <div className={styles.arrowWrapper} data-is-opened={isPopoverOpen}>
            <ArrowDown />
          </div>
        </div>
      </PopoverTrigger>
      <PopoverContent
        style={{
          width: triggerRef.current?.clientWidth,
        }}
      >
        {options &&
          options.map((option, index) => (
            <div
              key={index}
              className={styles.dropdownOption}
              onClick={() => handleSelectionChange(option)}
            >
              <div className={styles.tickWrapper}>
                {option.value === currentSelection && (
                  <TickIcon color={BROWN} />
                )}
              </div>
              <div>
                {t(option.label)}
                {option.count && (
                  <span className={styles.optionCount}>{option.count}</span>
                )}
              </div>
            </div>
          ))}
      </PopoverContent>
    </Popover>
  )
}

export default CustomSelectDropdown
