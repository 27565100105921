import isEqual from "lodash.isequal"
import React, { useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useCreateTransactionMutation } from "@/features/finance/api/finance-api.ts"
import TransactionEditor from "@/features/finance/components/transaction-editor/transaction-editor.tsx"
import { validateTransaction } from "@/features/finance/types/transaction-schema.tsx"
import { initialTransactionState } from "@/features/finance/types/transaction.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setTransactionDraft } from "@/redux/slices/transaction-draft-slice.ts"

const NewTransactionView: React.FC = () => {
  const dispatch = useAppDispatch()
  const { openModal } = useModal()
  const { handleBackNavigation } = useNavigationFallback()
  const { t } = useTranslation()

  useLayoutEffect(() => {
    dispatch(setTransactionDraft(initialTransactionState))
  }, [dispatch])

  const draftTransaction = useAppSelector(
    (state) => state.transactionSlice.draft,
  )
  const [postTransactionMutation] = useCreateTransactionMutation()

  const handleSave = async () => {
    if (await validateTransaction(draftTransaction)) {
      toast
        .promise(postTransactionMutation(draftTransaction).unwrap(), {
          pending: t("savingTransaction"),
          success: t("transactionSaved"),
          error: t("transactionSaveError"),
        })
        .then(() => handleBackNavigation())
    }
  }

  const hasChanges = !isEqual(initialTransactionState, draftTransaction)

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => handleBackNavigation(),
      })
    } else {
      handleBackNavigation()
    }
  }

  return (
    <>
      <EditorTopBar
        text={"addTransaction"}
        onSave={handleSave}
        onBackClick={handleBackClick}
        isModified={hasChanges}
      />
      <PageContentWrapperLarge hasPadding={false}>
        <TransactionEditor />
      </PageContentWrapperLarge>
    </>
  )
}

export default NewTransactionView
