import { type MouseEvent } from "react"
import { useNavigate } from "react-router-dom"
import styles from "../../../tasks/components/associated-list/associated-list.module.css"
import CloseCrossIcon from "@/assets/icons/misc/close-cross-icon.tsx"
import NoteThumbnailIcon from "@/assets/icons/notes/note-thumbnail-icon.tsx"
import { useGetNoteByIdQuery } from "@/features/notes/api/notes-api.ts"
import { NOTE_SCREEN_PATH } from "@/utils/constants/routes.ts"
import {
  extractNoteContentPlainText,
  stripHtmlAndTruncate,
} from "@/utils/helpers/string-utils.ts"

interface AssociatedAnimalRowProps {
  note: string
  onDelete: (animalId: string) => void
}

const AssociatedNoteCard: React.FC<AssociatedAnimalRowProps> = ({
  note,
  onDelete,
}) => {
  const navigate = useNavigate()
  const { data: workingNote } = useGetNoteByIdQuery(note)

  const handleNoteRemoval = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onDelete(note)
  }

  const handleNoteDetailsNavigation = () => {
    navigate(`${NOTE_SCREEN_PATH}/${note}`)
  }

  return (
    <div
      onClick={handleNoteDetailsNavigation}
      className={styles["associated-item-row"]}
    >
      <div className={styles["content-wrapper"]}>
        <div className={styles["note-icon"]}>
          <NoteThumbnailIcon size={35} />
        </div>
        <div className={styles["animal-info"]}>
          <p className={styles["animal-name"]}>
            {workingNote && stripHtmlAndTruncate(workingNote.title, 20)}
          </p>
          <span className={styles["animal-name"]}>
            {workingNote && extractNoteContentPlainText(workingNote, 20)}
          </span>
        </div>
      </div>
      <div className={styles["delete-icon"]} onClick={handleNoteRemoval}>
        <CloseCrossIcon />
      </div>
    </div>
  )
}

export default AssociatedNoteCard
