import React from "react"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const FacebookIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || "34"}
      height={props.size || "34"}
      viewBox="0 0 34 34"
      {...props}
    >
      <path
        id="facebook-svgrepo-com"
        d="M2,8.8A6.8,6.8,0,0,1,8.8,2H29.2A6.8,6.8,0,0,1,36,8.8V29.2A6.8,6.8,0,0,1,29.2,36H8.8A6.8,6.8,0,0,1,2,29.2ZM8.8,5.4A3.4,3.4,0,0,0,5.4,8.8V29.2a3.4,3.4,0,0,0,3.4,3.4H19V20.7H17.3a1.7,1.7,0,1,1,0-3.4H19V14.75A5.95,5.95,0,0,1,24.95,8.8h1.02a1.7,1.7,0,1,1,0,3.4H24.95a2.55,2.55,0,0,0-2.55,2.55V17.3h3.57a1.7,1.7,0,1,1,0,3.4H22.4V32.6h6.8a3.4,3.4,0,0,0,3.4-3.4V8.8a3.4,3.4,0,0,0-3.4-3.4Z"
        transform="translate(-2 -2)"
        fill={props.color || "var(--brown)"}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default FacebookIcon
