import styles from "./mobile-home-header.module.css"
import DashboardIcon from "@/assets/icons/misc/dashboard-icon.tsx"
import farmImg from "@/assets/images/farm.png"
import { useCurrentTemperature } from "@/features/home/hooks/use-current-temperature"
import { useGetAnimalsCount } from "@/features/home/hooks/use-get-animals-count.ts"
import { useGetWelcomeMessage } from "@/features/home/hooks/use-get-welcome-message.ts"
import { useGetProfileQuery } from "@/features/settings-account/api/account-api.ts"
import { initialUserProfile } from "@/features/settings-account/utils/initialStates.ts"

const MobileHomeHeader = () => {
  const { data: userProfile = initialUserProfile } = useGetProfileQuery()
  const { animalsCountString } = useGetAnimalsCount()
  const welcomeMessage = useGetWelcomeMessage(userProfile?.first_name)
  const temperature = useCurrentTemperature()

  return (
    <div className={styles["mobile-top-bar"]}>
      <img
        className={styles["background-img"]}
        src={farmImg}
        alt={"background-img"}
      />
      <div className={styles["top-bar"]}>
        <DashboardIcon />
        <div className={styles["farm-number"]}>
          <div className={styles["farm-name"]}>{userProfile?.farm?.name}</div>
          <div className={styles["animal-count"]}>{animalsCountString}</div>
        </div>
      </div>

      <div className={styles["welcome-title"]}>{welcomeMessage}</div>

      {temperature && (
        <div className={styles["degrees"]}>
          {" "}
          {`${Math.round(temperature)} °C`}
        </div>
      )}
    </div>
  )
}

export default MobileHomeHeader
