import queryString from "query-string"
import { useState, useEffect, useCallback } from "react"
import { useGetMyFarmQuery } from "@/features/settings-account/api/account-api.ts"
import { initialFarmProfile } from "@/features/settings-account/utils/initialStates.ts"

type WeatherData = {
  current_weather?: {
    temperature?: number
  }
}

export const useCurrentTemperature = (
  timezone: string = "auto",
): number | null => {
  const { data: farmProfile = initialFarmProfile } = useGetMyFarmQuery()

  const latitude = farmProfile?.address?.latitude
  const longitude = farmProfile?.address?.longitude

  const [temperature, setTemperature] = useState<number | null>(null)

  const fetchTemperature = useCallback(async () => {
    if (latitude == null || longitude == null) {
      console.warn("Latitude or longitude is missing.")
      return
    }

    const params = {
      latitude: latitude.toString(),
      longitude: longitude.toString(),
      current_weather: true,
      timezone: timezone,
    }
    const queryStringified = queryString.stringify(params)
    const url = `https://api.open-meteo.com/v1/forecast?${queryStringified}`

    try {
      const response = await fetch(url)
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
      const data: WeatherData = (await response.json()) as WeatherData
      setTemperature(data?.current_weather?.temperature ?? null)
    } catch (err) {
      console.error("Failed to fetch temperature:", err)
    }
  }, [latitude, longitude, timezone])

  useEffect(() => {
    fetchTemperature()
  }, [fetchTemperature])

  return temperature
}
