import React from "react"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const CancelIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="366 487 30 30"
    >
      <g data-name="Cancel">
        <path
          d="M385.878 497.122a.416.416 0 0 0-.59 0L381 501.411l-4.289-4.289a.416.416 0 1 0-.589.59l4.289 4.288-4.289 4.289a.416.416 0 1 0 .59.589l4.288-4.289 4.289 4.289a.415.415 0 0 0 .589 0 .416.416 0 0 0 0-.59L381.589 502l4.289-4.289a.416.416 0 0 0 0-.589Z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Path 780"
        />
        <path
          d="M381 487c-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15 8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15Zm0 29.063c-7.754 0-14.063-6.309-14.063-14.063 0-7.754 6.309-14.063 14.063-14.063 7.754 0 14.063 6.309 14.063 14.063 0 7.754-6.309 14.063-14.063 14.063Z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Path 781"
        />
      </g>
    </svg>
  )
}

export default CancelIcon
