import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useGetTaskCategoriesQuery } from "@/features/tasks/api/task-categories-api.ts"

export const useTaskCategoryOptions = () => {
  const { t } = useTranslation()
  const { data = [] } = useGetTaskCategoriesQuery()

  return useMemo(() => {
    return [
      { label: t("allCategories"), value: "" },
      ...data.map((category) => ({
        label: t(category.name),
        value: category.id,
      })),
    ]
  }, [data, t])
}
