import { Route } from "react-router-dom"
import AssistantPage from "@/features/assistant/routes/assistant-page/assistant-page.tsx"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import { AI_ASSISTANT_SCREEN_PATH } from "@/utils/constants/routes.ts"

const AssistantRoutes = (
  <Route element={<NoMenuLayout />}>
    <Route
      key={`${AI_ASSISTANT_SCREEN_PATH}`}
      path={`${AI_ASSISTANT_SCREEN_PATH}`}
      element={<AssistantPage />}
    />
  </Route>
)

export default AssistantRoutes
