import React from "react"
import styles from "./generic-drawer-selection-row.module.css"
import AddNoteIcon from "@/assets/icons/misc/add-note-icon.tsx"
import { GREY } from "@/utils/constants/colors-constants.ts"

interface AddItemGenericDrawerSelectionRowProps {
  onClick?: () => void
  text: string
}

const AddItemGenericDrawerSelectionRow: React.FC<
  AddItemGenericDrawerSelectionRowProps
> = ({ onClick, text }) => {
  return (
    <div
      className={styles.titleFlexCategory}
      onClick={onClick}
      style={{
        borderTop: "1px solid var(--light-grey)",
      }}
    >
      <AddNoteIcon size={30} color={GREY} />
      <p className={styles.title}>{text}</p>
    </div>
  )
}

export default AddItemGenericDrawerSelectionRow
