import { type ChangeEvent } from "react"
import styles from "./custom-number-input.module.css"
import { InputType } from "@/utils/enums/input-type-enum.ts"

interface CustomNumberInputProps {
  value: number
  onChange: (newValue: number) => void
  isReadOnly?: boolean
}

const CustomNumberInput: React.FC<CustomNumberInputProps> = ({
  value,
  onChange,
  isReadOnly,
}) => {
  const MIN_VALUE = 0
  const MAX_VALUE = 100

  const checkRange = (value: number) => {
    return value >= MIN_VALUE && value <= MAX_VALUE
  }

  const addValue = () => {
    if (checkRange(value + 1)) {
      onChange(value + 1)
    }
  }

  const subtractValue = () => {
    if (checkRange(value - 1)) {
      onChange(value - 1)
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10)
    if (!isNaN(newValue) && checkRange(newValue)) {
      onChange(newValue)
    }
  }

  return (
    <div className={styles.inputWrapper}>
      {!isReadOnly && (
        <button
          className={styles.counterButton}
          onClick={subtractValue}
          disabled={isReadOnly}
        >
          <span>-</span>
        </button>
      )}
      <input
        className={styles.customInput}
        readOnly={isReadOnly}
        type={InputType.NUMBER}
        inputMode="numeric"
        min={MIN_VALUE}
        max={MAX_VALUE}
        value={value}
        onChange={handleChange}
      />
      {!isReadOnly && (
        <button
          className={styles.counterButton}
          onClick={addValue}
          disabled={isReadOnly}
        >
          <span>+</span>
        </button>
      )}
    </div>
  )
}

export default CustomNumberInput
