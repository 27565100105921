import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import styles from "./menu-item-link.module.css"

interface MenuItemLinkProps {
  icon?: React.ReactNode
  isEnabled?: boolean
  text: string
  to: string
  color: string
  height: number
}

export const MenuItemLink = ({
  icon,
  text,
  to,
  color,
  height,
  isEnabled = true,
}: MenuItemLinkProps) => {
  const { t } = useTranslation()

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    if (!isEnabled) {
      event.preventDefault()
      toast.warning(t("notAvailableInPackage"))
    }
  }
  return (
    <Link
      to={to}
      onClick={handleClick}
      className={styles["menu-item-link"]}
      data-enabled={isEnabled}
      style={{
        backgroundColor: color,
        height: height,
      }}
    >
      <div className={styles["icon"]}>{icon}</div>
      <div className={styles["text"]}>{text}</div>
    </Link>
  )
}
