import { useRef } from "react"

/**
 *  useLongPress hook for handling long press event
 *
 * @param onLongPress - function to be called on long press
 */
const useLongPress = (onLongPress: () => void) => {
  const timeoutRef = useRef<number | null>(null)

  const handleMouseDown = () => {
    timeoutRef.current = window.setTimeout(() => {
      if (onLongPress) {
        onLongPress()
      }
    }, 1500)
  }

  const handleMouseUp = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  }

  return {
    onMouseDown: handleMouseDown,
    onMouseUp: handleMouseUp,
    onMouseLeave: handleMouseLeave,
    onTouchStart: handleMouseDown,
    onTouchEnd: handleMouseUp,
    onTouchCancel: handleMouseLeave,
  }
}

export default useLongPress
