import { Outlet } from "react-router-dom"
import useIsDesktop from "@/hooks/use-is-desktop.ts"
import StandardLayoutDesktop from "@/layouts/standard-layout/standard-layout-desktop.tsx"
import StandardLayoutMobile from "@/layouts/standard-layout/standard-layout-mobile.tsx"

export const StandardLayout = () => {
  const isDesktop = useIsDesktop()

  const LayoutComponent = isDesktop
    ? StandardLayoutDesktop
    : StandardLayoutMobile

  return (
    <LayoutComponent>
      <Outlet />
    </LayoutComponent>
  )
}
