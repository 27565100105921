import { useTranslation } from "react-i18next"
import styles from "../settings-routes.module.css"
import ReminderNotificationIcon from "@/assets/icons/notifications/reminder-notification-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"
import {
  useGetNotificationsPreferenceQuery,
  useUpdateNotificationPreferenceMutation,
} from "@/features/notifications/api/notifications-api.ts"
import { NOTIFICATION_CATEGORIES_ENUM } from "@/features/notifications/types/notifications-enum.ts"
import { type NotificationPreference } from "@/features/notifications/types/notifications.ts"
import { BooleanSwitchRow } from "@/features/tasks/components/reminder-options/switch-row-boolean.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"

const NotificationPreferences = () => {
  const { t } = useTranslation()
  const { handleBackNavigation } = useNavigationFallback()

  const { data, isLoading } = useGetNotificationsPreferenceQuery()
  const [updateNotificationPreferences] =
    useUpdateNotificationPreferenceMutation()

  const notificationPreferences = data as Record<
    NOTIFICATION_CATEGORIES_ENUM,
    NotificationPreference
  >

  const renderBooleanSwitchRow = (
    label: string,
    type: NOTIFICATION_CATEGORIES_ENUM,
    preferenceKey: keyof NotificationPreference,
  ) => (
    <BooleanSwitchRow
      label={label}
      isChecked={Boolean(notificationPreferences[type][preferenceKey])}
      setChecked={(checked) =>
        updateNotificationPreferences({
          id: notificationPreferences[type].id,
          [preferenceKey]: checked,
        })
      }
    />
  )

  return (
    <>
      <SettingsTopBar
        title={t("notificationPreferences")}
        onSaveChanges={handleBackNavigation}
      />
      <div className={styles.settingsContent}>
        {isLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            <div className={styles.imageSelectionContainer}>
              <div className={styles.imageCircleCenter}>
                <ReminderNotificationIcon size={40} />
              </div>
            </div>

            <div className={styles.card}>
              <div className={styles.cardTitleBrown}>E-mail</div>
              {renderBooleanSwitchRow(
                t("ALERTS"),
                NOTIFICATION_CATEGORIES_ENUM.ALERTS,
                "email_enabled",
              )}
              <div className={styles.shortSeparator} />
              {renderBooleanSwitchRow(
                t("REMINDERS"),
                NOTIFICATION_CATEGORIES_ENUM.REMINDERS,
                "email_enabled",
              )}
              <div className={styles.shortSeparator} />
              {renderBooleanSwitchRow(
                t("INFO"),
                NOTIFICATION_CATEGORIES_ENUM.INFO,
                "email_enabled",
              )}
            </div>

            <div className={styles.card}>
              <div className={styles.cardTitleBrown}>
                {t("pushNotifications")}
              </div>
              {renderBooleanSwitchRow(
                t("ALERTS"),
                NOTIFICATION_CATEGORIES_ENUM.ALERTS,
                "push_enabled",
              )}
              <div className={styles.shortSeparator} />
              {renderBooleanSwitchRow(
                t("REMINDERS"),
                NOTIFICATION_CATEGORIES_ENUM.REMINDERS,
                "push_enabled",
              )}
              <div className={styles.shortSeparator} />
              {renderBooleanSwitchRow(
                t("INFO"),
                NOTIFICATION_CATEGORIES_ENUM.INFO,
                "push_enabled",
              )}
            </div>

            {/*<div className={styles.card}>*/}
            {/*  <div className={styles.cardTitleBrown}>{t("SMS")}</div>*/}
            {/*  {renderBooleanSwitchRow(*/}
            {/*    t("ALERTS"),*/}
            {/*    NOTIFICATION_CATEGORIES_ENUM.ALERTS,*/}
            {/*    "sms_enabled",*/}
            {/*  )}*/}
            {/*  <div className={styles.shortSeparator} />*/}
            {/*  {renderBooleanSwitchRow(*/}
            {/*    t("REMINDERS"),*/}
            {/*    NOTIFICATION_CATEGORIES_ENUM.REMINDERS,*/}
            {/*    "sms_enabled",*/}
            {/*  )}*/}
            {/*  <div className={styles.shortSeparator} />*/}
            {/*  {renderBooleanSwitchRow(*/}
            {/*    t("INFO"),*/}
            {/*    NOTIFICATION_CATEGORIES_ENUM.INFO,*/}
            {/*    "sms_enabled",*/}
            {/*  )}*/}
            {/*</div>*/}
          </>
        )}
      </div>
    </>
  )
}

export default NotificationPreferences
