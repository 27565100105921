import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const ReminderIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="26 472 30 30"
    >
      <g data-name="Group 2291">
        <path
          d="M33 472h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H33a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="var(--brown)"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <g data-name="Path 824">
          <path
            d="M47.264 490.651a.682.682 0 0 0-.208-.3c.007-.195.012-.39.012-.586 0-4.332-1.768-8-4.21-9.258a1.97 1.97 0 0 0 .058-.467c0-1.074-.858-1.945-1.916-1.945-1.059 0-1.917.871-1.917 1.945 0 .161.022.317.058.467-2.442 1.258-4.21 4.926-4.21 9.258 0 .196.005.391.013.585a.682.682 0 0 0-.208.301l-.908 2.756a.688.688 0 0 0 .644.908h4.426c.27.92 1.108 1.59 2.102 1.59.993 0 1.831-.67 2.101-1.59h4.426a.688.688 0 0 0 .644-.908l-.907-2.756Zm-7.834-9.566.453-.233-.122-.5A1.289 1.289 0 0 1 41 478.743a1.289 1.289 0 0 1 1.24 1.607l-.122.5.452.234c2.273 1.17 3.86 4.74 3.86 8.68 0 .133-.002.267-.005.403H35.576c-.004-.136-.006-.27-.006-.403 0-3.94 1.588-7.51 3.86-8.68ZM41 495.269a1.569 1.569 0 0 1-1.436-.954h2.872a1.569 1.569 0 0 1-1.436.954Zm6.57-1.612c-.016.022-.035.022-.043.022H34.472c-.008 0-.027 0-.042-.022a.049.049 0 0 1-.008-.048l.908-2.756a.052.052 0 0 1 .05-.037h11.24c.022 0 .042.015.05.037l.907 2.756a.049.049 0 0 1-.007.048Z"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="M47.264 490.651a.682.682 0 0 0-.208-.3c.007-.195.012-.39.012-.586 0-4.332-1.768-8-4.21-9.258a1.97 1.97 0 0 0 .058-.467c0-1.074-.858-1.945-1.916-1.945-1.059 0-1.917.871-1.917 1.945 0 .161.022.317.058.467-2.442 1.258-4.21 4.926-4.21 9.258 0 .196.005.391.013.585a.682.682 0 0 0-.208.301l-.908 2.756a.688.688 0 0 0 .644.908h4.426c.27.92 1.108 1.59 2.102 1.59.993 0 1.831-.67 2.101-1.59h4.426a.688.688 0 0 0 .644-.908l-.907-2.756Zm-7.834-9.566.453-.233-.122-.5A1.289 1.289 0 0 1 41 478.743a1.289 1.289 0 0 1 1.24 1.607l-.122.5.452.234c2.273 1.17 3.86 4.74 3.86 8.68 0 .133-.002.267-.005.403H35.576c-.004-.136-.006-.27-.006-.403 0-3.94 1.588-7.51 3.86-8.68ZM41 495.269a1.569 1.569 0 0 1-1.436-.954h2.872a1.569 1.569 0 0 1-1.436.954Zm6.57-1.612c-.016.022-.035.022-.043.022H34.472c-.008 0-.027 0-.042-.022a.049.049 0 0 1-.008-.048l.908-2.756a.052.052 0 0 1 .05-.037h11.24c.022 0 .042.015.05.037l.907 2.756a.049.049 0 0 1-.007.048Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth=".5"
            stroke="#fff"
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default ReminderIcon
