import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import AddItemIcon from "@/assets/icons/misc/add-note-icon.tsx"
import BackArrow from "@/assets/icons/misc/back-arrow.tsx"
import commonStyles from "@/common.module.css"
import styles from "@/components/top-bars/top-bars-common.module.css"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"

interface MobileTopBarProps {
  navigateTo?: string
  title: string
  onIconClick?: () => void
  onBackClick?: () => void
  icon?: React.ReactNode
  titleInfo?: React.ReactNode
}

/**
 * General purpose navigation top-bar on mobile devices
 *
 * @param title route title
 * @param navigateTo route to navigate when pressing the navigate button
 * @param icon custom provided icon that will be placed in the right part
 * @param onIconClick what happens when you click on the custom provided icon
 * @param titleInfo a react node that has some info regarding the route, in notifications case its a notification count
 * @param onBackClick custom action that occurs on backClick
 * @constructor
 */
const MobileTopBar: React.FC<MobileTopBarProps> = ({
  title,
  navigateTo,
  onIconClick,
  icon,
  titleInfo,
  onBackClick,
}) => {
  const { handleBackNavigation } = useNavigationFallback()
  const { t } = useTranslation()

  const handleBackClick = () => {
    if (onBackClick) {
      onBackClick()
    } else {
      handleBackNavigation()
    }
  }

  return (
    <div className={styles.topBar}>
      <div className={styles.left}>
        <div className={styles.actionIcon} onClick={handleBackClick}>
          <BackArrow />
        </div>
        <div className={styles.title}>{title}</div>
        {titleInfo}
      </div>
      {onIconClick && icon && (
        <div onClick={onIconClick} className={styles.actionIcon}>
          {icon}
        </div>
      )}
      {navigateTo && (
        <Link className={styles.actionIcon} to={navigateTo}>
          <span className={commonStyles["sr-only"]}>{t("newAnimal")}</span>
          {icon ? icon : <AddItemIcon size={30} />}
        </Link>
      )}
    </div>
  )
}

export default MobileTopBar
