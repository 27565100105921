import React from "react"
import { Link } from "react-router-dom"
import styles from "./animal-group-card.module.css"
import { FARM_SCREEN_PATH } from "@/utils/constants/routes.ts"

interface AnimalGroupCardProps {
  imgSrc: string
  animals: any
  animalsLength: number
  backgroundColor: string
  title: string
}

const AnimalGroupCard: React.FC<AnimalGroupCardProps> = ({
  imgSrc,
  animals,
  animalsLength,
  backgroundColor,
  title,
}) => {
  return (
    <Link to={FARM_SCREEN_PATH}>
      <div className={styles.animalCard}>
        <div
          className={styles.cardBackground}
          style={{ backgroundColor: backgroundColor }}
        >
          <p className={styles.animalTitle}>{title}</p>
          <img src={imgSrc} alt={title} className={styles.animalImage} />
        </div>
        <div className={styles.animalDetails}>
          <div className={styles.animalInfo}>
            <div>
              <span className={styles.animalTotalHighlighted}>{animals}</span>
              <span className={styles.animalTotal}>/{animalsLength}</span>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default AnimalGroupCard
