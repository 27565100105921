import React, { type ReactElement } from "react"
import { useTranslation } from "react-i18next"
import styles from "./animal-profile-status-row.module.css"
import { type ANIMAL_STATUS_ENUM, getAnimalStatusColor } from "@/features/farm"
import { LIGHT_GREY, WHITE } from "@/utils/constants/colors-constants.ts"

interface AnimalProfileStatusRow {
  icon: ReactElement
  status: string
  isActive?: boolean
  onClick: () => void
}

const AnimalProfileStatusRow: React.FC<AnimalProfileStatusRow> = ({
  icon,
  status,
  isActive = false,
  onClick,
}) => {
  const { t } = useTranslation()
  const statusColor = getAnimalStatusColor(status as ANIMAL_STATUS_ENUM)

  return (
    <div
      className={styles.animalStatusRow}
      data-active={isActive}
      onClick={onClick}
    >
      <div>
        {React.cloneElement(icon, {
          size: 32,
          primary_color: WHITE,
          secondary_color: isActive ? statusColor : LIGHT_GREY,
        })}
      </div>
      <div
        className={styles.text}
        style={{
          color: isActive ? statusColor : "",
        }}
      >
        {t(status)}
      </div>
    </div>
  )
}

export default AnimalProfileStatusRow
