export interface GenericAnimalEvent {
  id: string
  animal: string
  user?: string
  type?: string
  date: string
  description?: string
  notes?: string | null
  event_data?: EventSubData
  gestation_calendar_entry?: string | null
  created_at: string
  updated_at: string
}

export interface MainEventDataType {
  isMovementEvent: boolean
  isMeasurementEvent: boolean
  isTreatmentEvent: boolean
  isReproductiveEvent?: boolean
  isOtherEvent?: boolean
}

export interface EventSubData {
  event?: string
  from_location?: string
  to_location?: string
  value?: string
  medication?: string
  dosage?: string
  administered_by?: string
}

export enum MainAnimalEventType {
  Movement = "Movement",
  Treatment = "Treatment",
  Measurement = "Measurement",
  Reproductive = "Reproductive",
  Other = "Other",
}

export enum EventTypeStatus {
  LactationStart = "LactationStart",
  DryOff = "DryOff",
  IllnessTreatment = "IllnessTreatment",
  PregnancyCheckPositive = "PregnancyCheckPositive",
  HeatDetection = "HeatDetection",
  HealthCheckPositive = "HealthCheckPositive",
}

export interface GenericType {
  id: string
  name: string
  picture: string | null
  farm: string | null
}

export interface AnimalEventType extends GenericType {
  created_at: string
  updated_at: string
  parent: string | null
  farm: string | null
}

export interface Medication extends GenericType {
  unit: string
  description: string
  created_at: string
  updated_at: string
  farm: string | null
}
