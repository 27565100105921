import { useMemo } from "react"
import { ALL_SPECIES } from "@/features/farm"
import { useGetSpeciesQuery } from "@/features/farm/api/species-api.ts"
import { useGetAnimalsCount } from "@/features/home/hooks/use-get-animals-count.ts"

export const useGetSpeciesOptions = () => {
  const { data } = useGetSpeciesQuery()
  const { animalCountMap } = useGetAnimalsCount()

  const species_breeds = useMemo(() => {
    return Object.values(data || {}).map(({ name, id }) => ({
      label: name,
      value: id,
      count: animalCountMap[name],
    }))
  }, [animalCountMap, data])

  return useMemo(
    () => [ALL_SPECIES, ...(species_breeds ?? [])],
    [species_breeds],
  )
}
