import isEqual from "lodash.isequal"
import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "@/components/shadcn/drawer/custom-drawer.module.css"
import DrawerTopBar from "@/components/shadcn/drawer/drawer-top-bar.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import { useNotesContext } from "@/context/notes-context.tsx"
import NotesGroup from "@/features/notes/components/notes-group/notes-group.tsx"
import { useSearchNotes } from "@/features/notes/hooks/use-search-notes.ts"
import {
  groupItemsByTimeStampWithField,
  initialItemsTimeGroups,
  type ItemsTimeGroups,
} from "@/utils/helpers/time-grouping.ts"

interface CombinedComponentProps {
  currentValue: string[]
  onSaveClick: (notes: string[]) => void
  TriggerComponent: React.FC<TriggerComponentProps>
}

interface TriggerComponentProps {
  currentValue: string[]
  onClick: () => void
}

const DrawerNotes: React.FC<CombinedComponentProps> = ({
  onSaveClick,
  currentValue,
  TriggerComponent,
}) => {
  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>("")
  const filteredNotes = useSearchNotes(searchQuery)
  const [sortedNotes, setSortedNotes] = useState<ItemsTimeGroups>(
    initialItemsTimeGroups,
  )
  const { setIsContextEnabled, selectedNotes, setSelectedNotes } =
    useNotesContext()

  useEffect(() => {
    setSelectedNotes(currentValue)
  }, [currentValue, setSelectedNotes])

  useEffect(() => {
    setIsContextEnabled(true)

    return () => {
      setIsContextEnabled(false)
    }
  }, [setIsContextEnabled])

  useEffect(() => {
    setSortedNotes(groupItemsByTimeStampWithField(filteredNotes, "updated_at"))
  }, [filteredNotes])

  useEffect(() => {
    if (selectedNotes && !isEqual(selectedNotes, currentValue)) {
      onSaveClick(selectedNotes)
    }
  }, [currentValue, onSaveClick, selectedNotes])

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <TriggerComponent currentValue={currentValue} onClick={() => {}} />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerTopBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={t("searchNote")}
        />
        <div className={styles["slide-up-content-wrapper"]}>
          <div className={styles["slide-up-content-with-padding"]}>
            <NotesGroup notes={sortedNotes} />
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerNotes
