import { useGetAllAnimalEventTypesQuery } from "@/features/animal-events/api/event-types-api.ts"
import { type MainAnimalEventType } from "@/features/animal-events/types/animal-events.ts"

/**
 * Hook to return a function that checks if the event type is the same as the given type
 * or if it is a parent of the given type.
 * @returns function that takes eventId and type and returns boolean
 */
const useCheckEventType = () => {
  const { data: eventTypes } = useGetAllAnimalEventTypesQuery()

  return (eventId: string | undefined, type: MainAnimalEventType) => {
    if (!eventId || !eventTypes) return false
    const eventType = eventTypes[eventId]
    const isType = eventType.name === type
    const isParentType =
      eventType.parent && eventTypes[eventType.parent].name === type

    return Boolean(isType || isParentType)
  }
}

export default useCheckEventType
