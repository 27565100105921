import { useLayoutEffect } from "react"
import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { resetNotesDraft } from "@/redux/slices/notes-draft-slice.ts"

/**
 * useInitializeDraftNote
 * Hook to initialize the note draft
 * If the noteDraft is not present, it will reset the noteDraft with default values
 * If the animalId is present, it will reset the noteDraft with the animalId
 * If the noteDraft is present, it will return the noteDraft
 */
const useInitializeDraftNote = () => {
  const dispatch = useAppDispatch()
  const { animalId } = useParams()

  const noteDraft = useAppSelector(
    (state) => state.notesDraftSlice.drafts["new"],
  )

  if (!noteDraft) {
    dispatch(resetNotesDraft({ id: "new" }))
  }

  useLayoutEffect(() => {
    const initialData = animalId ? { animals: [animalId] } : {}
    if (Object.keys(initialData).length > 0) {
      dispatch(resetNotesDraft({ id: "new", draft: initialData }))
    }
  }, [animalId, dispatch])

  return { noteDraft }
}

export default useInitializeDraftNote
