import React from "react"

const PasswordIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      id="lock-svgrepo-com_1_"
      data-name="lock-svgrepo-com (1)"
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="37.908"
      viewBox="0 0 27 37.908"
      {...props}
    >
      <g
        id="Group_1026"
        data-name="Group 1026"
        transform="translate(10.079 21.345)"
      >
        <g id="Group_1025" data-name="Group 1025" transform="translate(0)">
          <path
            id="Path_783"
            data-name="Path 783"
            d="M213.215,288.3a3.421,3.421,0,0,0-2.346,5.911v5.065a.611.611,0,0,0,.611.611h3.468a.611.611,0,0,0,.611-.611v-5.065a3.421,3.421,0,0,0-2.345-5.911Zm1.357,5.15a.611.611,0,0,0-.234.481v4.734h-2.246v-4.734a.612.612,0,0,0-.234-.481,2.2,2.2,0,1,1,2.713,0Z"
            transform="translate(-209.794 -288.297)"
            fill="var(--brown)"
          />
        </g>
      </g>
      <g id="Group_1028" data-name="Group 1028">
        <g id="Group_1027" data-name="Group 1027" transform="translate(0)">
          <path
            id="Path_784"
            data-name="Path 784"
            d="M98.064,14.9v-4a10.9,10.9,0,1,0-21.8,0v4a2.9,2.9,0,0,0-2.6,2.877v1.534a.611.611,0,0,0,1.223,0V17.777a1.671,1.671,0,0,1,1.669-1.669H97.772a1.671,1.671,0,0,1,1.669,1.669V30.289a.611.611,0,1,0,1.223,0V17.777A2.9,2.9,0,0,0,98.064,14.9Zm-15.686-.015V10.9a4.786,4.786,0,1,1,9.573,0v3.985Zm14.463,0H93.173V10.9a6.009,6.009,0,1,0-12.018,0v3.985H77.487V10.9a9.677,9.677,0,1,1,19.354,0Z"
            transform="translate(-73.664)"
            fill="var(--brown)"
          />
        </g>
      </g>
      <g id="Group_1030" data-name="Group 1030" transform="translate(0 21.145)">
        <g id="Group_1029" data-name="Group 1029" transform="translate(0)">
          <path
            id="Path_785"
            data-name="Path 785"
            d="M100.054,296.573a.611.611,0,0,0-.611.611v2.282a1.671,1.671,0,0,1-1.669,1.669H76.557a1.671,1.671,0,0,1-1.669-1.669v-13.26a.611.611,0,1,0-1.223,0v13.26a2.9,2.9,0,0,0,2.892,2.892H97.773a2.9,2.9,0,0,0,2.892-2.892v-2.282A.612.612,0,0,0,100.054,296.573Z"
            transform="translate(-73.665 -285.595)"
            fill="var(--brown)"
          />
        </g>
      </g>
    </svg>
  )
}

export default PasswordIcon
