import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type GestationCalendar } from "@/features/gestational-calendar/types/calendar-types.ts"

interface DeleteGestationCalendarRequest {
  delete_animal_events: boolean
  gestationCalendarId: string
}

const GESTATION_CALENDARS_BASE_URL = "gestation-calendars/"

export const gestationCalendarsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGestationCalendars: builder.query<GestationCalendar[], void>({
      query: () => ({
        url: GESTATION_CALENDARS_BASE_URL,
      }),
      providesTags: (result) =>
        result
          ? [
              { type: TagType.GestationCalendars, id: "LIST" },
              ...result.map(({ id }) => ({
                type: TagType.GestationCalendars,
                id,
              })),
            ]
          : [{ type: TagType.GestationCalendars, id: "LIST" }],
      transformResponse: (response: GestationCalendar[]) => {
        return response.map((gestationCalendar) => {
          return {
            ...gestationCalendar,
            entries: gestationCalendar.entries.sort(
              (a, b) =>
                new Date(a.event_date).getTime() -
                new Date(b.event_date).getTime(),
            ),
          }
        })
      },
    }),
    getGestationCalendarById: builder.query<GestationCalendar, string>({
      query: (id) => ({
        url: `${GESTATION_CALENDARS_BASE_URL}${id}/`,
      }),
      providesTags: (_result, _error, id) => [
        { type: TagType.GestationCalendars, id },
      ],
      transformResponse: (response: GestationCalendar) => {
        return {
          ...response,
          entries: response.entries.sort(
            (a, b) =>
              new Date(a.event_date).getTime() -
              new Date(b.event_date).getTime(),
          ),
        }
      },
    }),
    createGestationCalendar: builder.mutation<
      GestationCalendar,
      Partial<GestationCalendar>
    >({
      query: (gestationCalendar) => {
        return {
          url: GESTATION_CALENDARS_BASE_URL,
          method: "POST",
          body: gestationCalendar,
        }
      },
      invalidatesTags: [{ type: TagType.GestationCalendars, id: "LIST" }],
      transformResponse: (response: GestationCalendar) => {
        return {
          ...response,
          entries: response.entries.sort(
            (a, b) =>
              new Date(a.event_date).getTime() -
              new Date(b.event_date).getTime(),
          ),
        }
      },
    }),
    deleteGestationCalendar: builder.mutation<
      void,
      DeleteGestationCalendarRequest
    >({
      query: (request) => ({
        url: `${GESTATION_CALENDARS_BASE_URL}${request.gestationCalendarId}/?delete_animal_events=${request.delete_animal_events}`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, request) => {
        const tags = [{ type: TagType.GestationCalendars }]

        if (request.delete_animal_events) {
          tags.push({ type: TagType.AnimalEvents })
        }

        return tags
      },
    }),
    updateGestationCalendar: builder.mutation<
      GestationCalendar,
      { id: GestationCalendar["id"] } & Partial<GestationCalendar>
    >({
      query: (gestationCalendar) => {
        return {
          url: `${GESTATION_CALENDARS_BASE_URL}${gestationCalendar.id}/`,
          method: "PATCH",
          body: gestationCalendar,
        }
      },
      invalidatesTags: (_res, _err, { id }) => [
        { type: TagType.GestationCalendars, id: "LIST" },
        { type: TagType.GestationCalendars, id },
      ],
    }),
  }),
})

export const {
  useGetGestationCalendarsQuery,
  useGetGestationCalendarByIdQuery,
  useCreateGestationCalendarMutation,
  useDeleteGestationCalendarMutation,
  useUpdateGestationCalendarMutation,
} = gestationCalendarsApi
