import React from "react"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const AddEventIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      width={props.size || "17"}
      height={props.size || "17"}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 8.16665V6.36665C14.5 5.24654 14.5 4.68649 14.282 4.25867C14.0903 3.88234 13.7843 3.57638 13.408 3.38463C12.9802 3.16665 12.4201 3.16665 11.3 3.16665H5.7C4.5799 3.16665 4.01984 3.16665 3.59202 3.38463C3.21569 3.57638 2.90973 3.88234 2.71799 4.25867C2.5 4.68649 2.5 5.24654 2.5 6.36665V11.9666C2.5 13.0868 2.5 13.6468 2.71799 14.0746C2.90973 14.451 3.21569 14.7569 3.59202 14.9487C4.01984 15.1666 4.5799 15.1666 5.7 15.1666H8.83333M14.5 7.16665H2.5M11.1667 1.83331V4.49998M5.83333 1.83331V4.49998M12.5 14.5V10.5M10.5 12.5H14.5"
        stroke="#414141"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AddEventIcon
