import { ANIMAL_STATUS_ENUM } from "@/features/farm"
import AnimalStatusCard from "@/features/home/components/animal-status-card/animal-status-card.tsx"
import HorizontalScrollComponentWrapperFlexible from "@/features/home/components/horizontal-scroll-component-wrapper-flexible/horizontal-scroll-component-wrapper-flexible.tsx"
import useGetAllAnimalsStatuses from "@/features/home/hooks/use-get-all-animals-statuses.ts"

export const AnimalStatusesWidget = () => {
  const {
    healthCount,
    underTreatmentCount,
    pregnantCount,
    lactatingCount,
    heatCount,
    dryCount,
  } = useGetAllAnimalsStatuses()

  const statuses = [
    { status: ANIMAL_STATUS_ENUM.HEAT, count: heatCount },
    { status: ANIMAL_STATUS_ENUM.GESTATING, count: pregnantCount },
    { status: ANIMAL_STATUS_ENUM.LACTATING, count: lactatingCount },
    { status: ANIMAL_STATUS_ENUM.UNDER_TREATMENT, count: underTreatmentCount },
    { status: ANIMAL_STATUS_ENUM.HEALTHY, count: healthCount },
    { status: ANIMAL_STATUS_ENUM.DRY, count: dryCount },
  ]

  const sortedStatuses = statuses.sort((a, b) => b.count - a.count)

  return (
    <HorizontalScrollComponentWrapperFlexible title={"Status Animale"}>
      {sortedStatuses.map(({ status, count }) => (
        <AnimalStatusCard key={status} status={status} count={count} />
      ))}
    </HorizontalScrollComponentWrapperFlexible>
  )
}

export default AnimalStatusesWidget
