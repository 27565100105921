import FarmLayoutDesktop from "@/features/farm/routes/farm-layout/farm-layout-desktop/farm-layout-desktop.tsx"
import FarmLayoutMobile from "@/features/farm/routes/farm-layout/farm-layout-mobile/farm-layout-mobile.tsx"
import useIsDesktop from "@/hooks/use-is-desktop.ts"

const FarmLayout = () => {
  const isDesktop = useIsDesktop()

  return isDesktop ? <FarmLayoutDesktop /> : <FarmLayoutMobile />
}

export default FarmLayout
