import { useTranslation } from "react-i18next"
import { Toggle } from "@/components/shadcn/toggle/toggle.tsx"

interface CustomToggleProps {
  title: string
  isSelected: boolean
  onChange: (status: boolean) => void
}

const CustomToggle: React.FC<CustomToggleProps> = ({
  title,
  onChange,
  isSelected,
}) => {
  const { t } = useTranslation()

  return (
    <Toggle pressed={isSelected} onPressedChange={onChange}>
      {t(title)}
    </Toggle>
  )
}

export default CustomToggle
