import { MUSTARD_COLOR } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const LocationIcon: React.FC<GeneralSvgProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      width="30px"
      height="30px"
      viewBox="-2.4 -2.4 28.80 28.80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#000000"
    >
      <g
        id="SVGRepo_bgCarrier"
        strokeWidth="0"
        transform="translate(0,0), scale(1)"
      >
        <rect
          x="-2.4"
          y="-2.4"
          width="28.80"
          height="28.80"
          rx="5.76"
          fill={MUSTARD_COLOR}
          strokeWidth="0"
        ></rect>
      </g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="0.288"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M12 6H12.01M9 20L3 17V4L5 5M9 20L15 17M9 20V14M15 17L21 20V7L19 6M15 17V14M15 6.2C15 7.96731 13.5 9.4 12 11C10.5 9.4 9 7.96731 9 6.2C9 4.43269 10.3431 3 12 3C13.6569 3 15 4.43269 15 6.2Z"
          stroke="#ffffff"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>
      </g>
    </svg>
  )
}

export default LocationIcon
