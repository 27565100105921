import { skipToken } from "@reduxjs/toolkit/query"
import { useLayoutEffect } from "react"
import { useGetNoteByIdQuery } from "@/features/notes/api/notes-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setNotesDraft } from "@/redux/slices/notes-draft-slice.ts"

const useGetDraftNote = (noteId?: string | undefined) => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetNoteByIdQuery(noteId || skipToken)

  const draftNote = useAppSelector(
    (state) => state.notesDraftSlice.drafts[noteId || ""],
  )

  useLayoutEffect(() => {
    if (data && noteId && !draftNote) {
      dispatch(setNotesDraft({ id: noteId, draft: data }))
    }
  }, [dispatch, data, noteId, draftNote])

  return { draftNote, data, isLoading }
}

export default useGetDraftNote
