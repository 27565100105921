import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./gestation-calendar-widget.module.css"
import { ANIMAL_GENDERS } from "@/features/farm"
import { useGetGestationCalendarsQuery } from "@/features/gestational-calendar/api/gestation-calendar-api.ts"
import GestationCalendarCard from "@/features/gestational-calendar/components/gestation-calendar-card/gestation-calendar-card.tsx"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button"
import WidgetCard from "@/features/home/components/widget-card/widget-card"
import {
  ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH,
  NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH,
} from "@/utils/constants/routes"

interface NotesWidgetProps {
  animalId?: string
}

const GestationCalendarWidget: React.FC<NotesWidgetProps> = ({ animalId }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: gestationCalendars } = useGetGestationCalendarsQuery()
  const animalCalendars = gestationCalendars?.filter(
    (gestationCalendar) => gestationCalendar.animal === animalId,
  )

  const navigationString = useMemo(
    () =>
      animalId
        ? `${NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH}/${ANIMAL_GENDERS.FEMALE.label}/${animalId}`
        : NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH,
    [animalId],
  )

  const addItemGoTo = () => {
    navigate(navigationString)
  }

  return (
    <WidgetCard
      title={t("gestationCalendars")}
      navigateTo={ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH}
      {...(animalCalendars && animalCalendars.length > 0 && { addItemGoTo })}
      customStyles={
        !animalId
          ? {
              height: "fit-content",
              marginInline: 0,
              marginBlock: 0,
              width: "100%",
            }
          : {}
      }
    >
      {animalCalendars && animalCalendars.length > 0 ? (
        <div className={styles.calendarsWrapper}>
          {animalCalendars.map((gestationCalendar, index) => (
            <GestationCalendarCard
              gestationCalendar={gestationCalendar}
              key={index}
            />
          ))}
        </div>
      ) : (
        <EmptyContentCreateItemButton navigateTo={navigationString} />
      )}
    </WidgetCard>
  )
}

export default GestationCalendarWidget
