import React from "react"
import { BROWN, DARK_GREY } from "@/utils/constants/colors-constants"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || "30"}
      height={props.size || "30"}
      viewBox="0 0 24 30"
    >
      <g
        id="Group_2742"
        data-name="Group 2742"
        transform="translate(-359.74 -1846.74)"
      >
        <path
          id="Path_1398"
          data-name="Path 1398"
          d="M21.228,3.32H16.891A2.891,2.891,0,0,0,14,6.211v4.337a2.891,2.891,0,0,0,2.891,2.891h4.337a2.891,2.891,0,0,0,2.891-2.891V6.211A2.891,2.891,0,0,0,21.228,3.32Z"
          transform="translate(361.814 1844.17)"
          stroke={props.is_active ? BROWN : DARK_GREY}
          fill={props.is_active ? BROWN : "transparent"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1399"
          data-name="Path 1399"
          d="M10.228,3.32H5.891A2.891,2.891,0,0,0,3,6.211v4.337a2.891,2.891,0,0,0,2.891,2.891h4.337a2.891,2.891,0,0,0,2.891-2.891V6.211A2.891,2.891,0,0,0,10.228,3.32Z"
          transform="translate(357.49 1844.17)"
          stroke={props.is_active ? BROWN : DARK_GREY}
          fill={props.is_active ? BROWN : "transparent"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1400"
          data-name="Path 1400"
          d="M21.228,14.32H16.891A2.891,2.891,0,0,0,14,17.211v4.337a2.891,2.891,0,0,0,2.891,2.891h4.337a2.891,2.891,0,0,0,2.891-2.891V17.211A2.891,2.891,0,0,0,21.228,14.32Z"
          transform="translate(361.814 1849.071)"
          stroke={props.is_active ? BROWN : DARK_GREY}
          fill={props.is_active ? BROWN : "transparent"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <path
          id="Path_1401"
          data-name="Path 1401"
          d="M10.228,14.32H5.891A2.891,2.891,0,0,0,3,17.211v4.337a2.891,2.891,0,0,0,2.891,2.891h4.337a2.891,2.891,0,0,0,2.891-2.891V17.211A2.891,2.891,0,0,0,10.228,14.32Z"
          transform="translate(357.49 1849.071)"
          stroke={props.is_active ? BROWN : DARK_GREY}
          fill={props.is_active ? BROWN : "transparent"}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </g>
    </svg>
  )
}

export default MenuIcon
