import React from "react"
import { useTranslation } from "react-i18next"
import styles from "@/features/tasks/components/reminder-options/reminder-options.module.css"
import { SwitchRowValue } from "@/features/tasks/components/reminder-options/switch-row-value.tsx"
import { ReminderConstants } from "@/utils/constants/time-constants.ts"

interface ReminderOptionsProps {
  reminder: number | null
  setReminder: (reminders: number | null) => void
}

const ReminderOptions: React.FC<ReminderOptionsProps> = ({
  reminder,
  setReminder,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles["reminders-wrapper"]}>
      <SwitchRowValue
        label={t("before30")}
        reminder={reminder}
        reminderValue={ReminderConstants.THIRTY_MINUTES}
        setReminder={setReminder}
      />
      <div className={styles["separator"]}></div>
      <SwitchRowValue
        label={t("beforeHour")}
        reminder={reminder}
        reminderValue={ReminderConstants.ONE_HOUR}
        setReminder={setReminder}
      />
      <div className={styles["separator"]}></div>
      <SwitchRowValue
        label={t("beforeDay")}
        reminder={reminder}
        reminderValue={ReminderConstants.ONE_DAY}
        setReminder={setReminder}
      />
      <div className={styles["separator"]}></div>

      <SwitchRowValue
        label={t("beforeWeek")}
        reminder={reminder}
        reminderValue={ReminderConstants.ONE_WEEK}
        setReminder={setReminder}
      />
    </div>
  )
}

export default ReminderOptions
