import { useGetAllAnimalEventTypesQuery } from "@/features/animal-events/api/event-types-api.ts"
import { type AnimalEventType } from "@/features/animal-events/types/animal-events.ts"

const useMapEventTypesToParents = () => {
  const { data: eventTypesData } = useGetAllAnimalEventTypesQuery()

  if (!eventTypesData) return new Map<string, AnimalEventType[]>()

  const eventTypes = Object.values(eventTypesData)

  const parentEventTypes = eventTypes.filter(
    (eventType) => eventType.parent === null,
  )

  const parentEventTypesWithChildren = new Map<string, AnimalEventType[]>()

  parentEventTypes.forEach((parentEventType) => {
    parentEventTypesWithChildren.set(
      parentEventType.name,
      Array.of(parentEventType),
    )
  })

  eventTypes.forEach((eventType) => {
    if (eventType.parent) {
      const parentEvent = eventTypesData[eventType.parent]
      if (parentEvent) {
        parentEventTypesWithChildren.get(parentEvent.name)?.push(eventType)
      }
    }
  })

  const keys = Array.from(parentEventTypesWithChildren.keys())

  const modifiedKes = keys.map((key) => {
    return key + "EventType"
  })

  const newParentEventTypesWithChildren = new Map<string, AnimalEventType[]>()
  modifiedKes.forEach((key, index) => {
    newParentEventTypesWithChildren.set(
      key,
      parentEventTypesWithChildren.get(keys[index])!,
    )
  })

  return newParentEventTypesWithChildren
}

export default useMapEventTypesToParents
