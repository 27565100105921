import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./gestation-period-calendar-widget.module.css"
import cowWithCalf from "@/assets/images/cow-with-calf.jpeg"
import WidgetCardAdjustable from "@/features/home/components/widget-card-adjustable/widget-card-adjustable.tsx"
import { ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH } from "@/utils/constants/routes.ts"

function GestationPeriodCalendarWidget() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const testNow = () => {
    navigate(ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH)
  }

  return (
    <WidgetCardAdjustable title={"gestationPeriodCalendar"}>
      <div className={styles["widget-container"]}>
        <p
          className={styles["info"]}
          style={{
            width: "180px",
          }}
        >
          {t("gestationalCalendarText")}
        </p>
        <img src={cowWithCalf} alt="" className={styles.cowImage} />
        <button className={styles["generic-button"]} onClick={testNow}>
          {t("openCalendar")}
        </button>
      </div>
    </WidgetCardAdjustable>
  )
}

export default GestationPeriodCalendarWidget
