import { format, isToday, isTomorrow } from "date-fns"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { WeatherIcon } from "@/features/home/components/weather-widget/weather-icon.tsx"
import styles from "@/features/home/components/weather-widget/weather-widget.module.css"

interface DayCardProps {
  currentDate: Date
  currentDateName: string
  minTemperature: number
  cloudCoverage: number
  maxTemperature: number
}

export default function DayCard({
  currentDate,
  minTemperature,
  cloudCoverage,
  maxTemperature,
}: DayCardProps) {
  const { t } = useTranslation()

  const getCurrentDateName = useMemo(() => {
    if (isToday(currentDate)) return t("today")
    if (isTomorrow(currentDate)) return t("tomorrow")
    const dayOfWeek = format(currentDate, "EEEE")
    return t(dayOfWeek)
  }, [currentDate, t])

  return (
    <div className={styles["day-card"]}>
      <div className={styles["weather-icon"]}>
        <WeatherIcon cloudCoverage={cloudCoverage} />
        <p className={styles.dayCardTemperatureText}>
          {`${Math.round(minTemperature)} °C / ${Math.round(maxTemperature)} °C`}
        </p>
      </div>
      <div className={styles["week-day-name"]}>{getCurrentDateName}</div>
    </div>
  )
}
