export const resizeImage = (
  file: File,
  maxWidth: number,
  maxHeight: number,
  callback: (base64: string) => void,
) => {
  const reader = new FileReader()
  reader.onload = (event) => {
    const img = new Image()
    img.onload = () => {
      const canvas = document.createElement("canvas")
      const ctx = canvas.getContext("2d")

      let width = img.width
      let height = img.height

      if (width > height) {
        if (width > maxWidth) {
          height = (maxWidth / width) * height
          width = maxWidth
        }
      } else {
        if (height > maxHeight) {
          width = (maxHeight / height) * width
          height = maxHeight
        }
      }

      canvas.width = width
      canvas.height = height

      ctx?.drawImage(img, 0, 0, width, height)

      const base64 = canvas.toDataURL(file.type)
      callback(base64)
    }
    img.src = event.target?.result as string
  }
  reader.readAsDataURL(file)
}
