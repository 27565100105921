import React from "react"
import styles from "./search-bar.module.css"
import SearchIcon from "@/assets/icons/misc/search-icon.tsx"

/**
 * Props for the SearchBar component.
 */
export interface SearchBarProps {
  /**
   * The current value of the search input.
   */
  value: string
  /**
   * Function to handle changes in the search input.
   * @param event - The change event containing the updated input value.
   */
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  /**
   * The placeholder of the search input field
   */
  placeholder: string
}

const SearchBar: React.FC<SearchBarProps> = ({
  value,
  onChange,
  placeholder,
}) => {
  return (
    <div className={styles.searchBarWraper}>
      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder={placeholder}
          className={styles.searchInput}
          value={value}
          onChange={onChange}
        />
        <SearchIcon className={styles.searchImage} alt="search" />
      </div>
    </div>
  )
}

export default SearchBar
