import React from "react"
import { useTranslation } from "react-i18next"
import styles from "../filter-tab.module.css"
import CustomSelectPill from "@/components/custom-select/custom-select-pill.tsx"
import SearchBar from "@/components/search-bar/search-bar.tsx"
import { type AnimalFilterSelection } from "@/features/farm"
import useGetAnimalsOptionsWithCount from "@/features/farm/hooks/use-get-animals-options-with-count.ts"
import { useGetSpeciesOptions } from "@/features/farm/hooks/use-get-species-options.ts"

interface FilterTabProps {
  handleSearch?: (searchQuery: string) => void
  handleAgeChange: (age: string) => void
  handleGenderChange: (gender: string) => void
  handleStatusChange: (status: string) => void
  handleSpeciesChange: (species: string) => void
  selections: AnimalFilterSelection
}

export const AnimalFilterTab: React.FC<FilterTabProps> = ({
  handleSearch,
  handleAgeChange,
  handleGenderChange,
  handleSpeciesChange,
  handleStatusChange,
  selections,
}) => {
  const speciesOptionsWithCount = useGetSpeciesOptions()
  const {
    animalStatusesWithCount,
    animalAgesWithCount,
    animalGendersWithCount,
  } = useGetAnimalsOptionsWithCount()
  const { t } = useTranslation()

  return (
    <>
      {handleSearch && (
        <SearchBar
          value={selections.searchQuery}
          onChange={(event) => handleSearch(event.target.value)}
          placeholder={t("searchAnimal")}
        />
      )}
      <div className={styles["horizontal-scroll"]}>
        <div className={styles["filter-flex"]}>
          <CustomSelectPill
            options={animalStatusesWithCount}
            currentSelection={selections.status}
            onSelectionChange={handleStatusChange}
          />

          <CustomSelectPill
            options={animalAgesWithCount}
            currentSelection={selections.age}
            onSelectionChange={handleAgeChange}
          />

          <CustomSelectPill
            options={animalGendersWithCount}
            currentSelection={selections.gender}
            onSelectionChange={handleGenderChange}
          />

          <CustomSelectPill
            options={speciesOptionsWithCount}
            currentSelection={selections.species}
            onSelectionChange={handleSpeciesChange}
          />
        </div>
      </div>
    </>
  )
}
