import React from "react"

const MaleFemaleIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={30}
      height={30}
      viewBox="36 486 30 30"
      {...props}
    >
      <g data-name="Group 2464">
        <path
          d="M43 486h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H43a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="#f0a900"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <g data-name="Icon ion-male-female-outline">
          <path
            d="M56.236 498.984a5.654 5.654 0 1 1-11.308 0 5.654 5.654 0 0 1 11.308 0Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.5"
            stroke="#fff"
            fill="transparent"
            data-name="Path 1286"
          />
          <path
            d="M50.582 505.303v5.321m2.328-2.66h-4.656m11.308-12.638V492h-3.326m-.695 4.02 4.02-4.02"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.5"
            stroke="#fff"
            fill="transparent"
            data-name="Path 1287"
          />
        </g>
      </g>
    </svg>
  )
}

export default MaleFemaleIcon
