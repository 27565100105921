import React from "react"
import styles from "./count-number.module.css"

interface AnimalsTotalCountViewProps {
  count: number
  text: string
}

const CountNumber: React.FC<AnimalsTotalCountViewProps> = ({ count, text }) => {
  return (
    <div className={styles.count}>
      Total: {count} {text}
    </div>
  )
}

export default CountNumber
