import DOMPurify from "dompurify"
import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { getNotificationIcon } from "../../types/notification-utils"
import styles from "./notification-card.module.css"
import PlusIcon from "@/assets/icons/misc/plus-icon.tsx"
import TickIcon from "@/assets/icons/misc/tick-icon.tsx"
import { useReadNotificationMutation } from "@/features/notifications/api/notifications-api.ts"
import { NOTIFICATION_CATEGORIES_ENUM } from "@/features/notifications/types/notifications-enum.ts"
import { type Notification } from "@/features/notifications/types/notifications.ts"
import { useLazyGetTaskByIdQuery } from "@/features/tasks/api/tasks-api.ts"
import {
  DARK_GREY,
  LIGHT_GREY,
  LIGHT_GREY_TRANSPARENT,
  NOTIFICATION_BLACK_TRANSPARENT,
  NOTIFICATION_BLUE_TRANSPARENT,
  NOTIFICATION_ORANGE_TRANSPARENT,
  NOTIFICATION_RED_TRANSPARENT,
} from "@/utils/constants/colors-constants.ts"
import {
  NOTIFICATIONS_PATH,
  TASKS_SCREEN_PATH,
  NEW_TASK_SCREEN_PATH,
} from "@/utils/constants/routes.ts"
import { SMALL_ICON_SIZE } from "@/utils/constants/size-constants.ts"
import { elapsedTime } from "@/utils/helpers/time-utils.ts"

interface NotificationCardProps {
  notification: Notification
}

const NotificationCard: React.FC<NotificationCardProps> = ({
  notification,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [trigger] = useLazyGetTaskByIdQuery()
  const [readNotificationMutation] = useReadNotificationMutation()
  const notificationIcon =
    notification.read_at === null
      ? getNotificationIcon(notification, SMALL_ICON_SIZE)
      : getNotificationIcon(notification, SMALL_ICON_SIZE, LIGHT_GREY)
  const timestamp = elapsedTime(notification.created_at)

  const handleAddTaskClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    navigate(NEW_TASK_SCREEN_PATH)
  }

  const handleNavigateToTask = () => {
    if (
      notification.notification_type ===
        NOTIFICATION_CATEGORIES_ENUM.REMINDERS &&
      notification.action?.task_id
    ) {
      trigger(notification.action.task_id as string)
        .unwrap()
        .then(() => {
          navigate(`${TASKS_SCREEN_PATH}/${notification.action?.task_id}`)
        })
        .catch(() => toast.error(t("taskNotFound")))
    } else {
      navigate(`${NOTIFICATIONS_PATH}/${notification.id}`)
    }
  }

  const readNotification = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    toast.promise(readNotificationMutation(notification.id).unwrap(), {
      pending: t("updatingNotification"),
      success: t("notificationWasRead"),
      error: t("updateNotificationError"),
    })
  }

  const getNotificationCardBackGroundColor = () => {
    if (notification.read_at) return LIGHT_GREY_TRANSPARENT
    switch (notification.notification_type) {
      case NOTIFICATION_CATEGORIES_ENUM.REMINDERS:
        return NOTIFICATION_ORANGE_TRANSPARENT
      case NOTIFICATION_CATEGORIES_ENUM.ALERTS:
        return NOTIFICATION_RED_TRANSPARENT
      case NOTIFICATION_CATEGORIES_ENUM.INFO:
        return NOTIFICATION_BLUE_TRANSPARENT
      case NOTIFICATION_CATEGORIES_ENUM.MANDATORY:
        return NOTIFICATION_BLACK_TRANSPARENT
    }
  }

  const sanitizedMessage = DOMPurify.sanitize(notification.message)

  return (
    <div
      onClick={handleNavigateToTask}
      className={styles.notificationCard}
      style={{
        backgroundColor: getNotificationCardBackGroundColor(),
      }}
    >
      <div className={styles.icon}>{notificationIcon}</div>
      <div className={styles.notificationBody}>
        <div className={styles.notificationHeader}>
          <div
            data-is-read={notification.read_at !== null}
            className={styles.title}
          >
            {notification.title}
          </div>
          {notification.read_at === null && (
            <div className={styles.readIcon} onClick={readNotification}>
              <TickIcon color={DARK_GREY} />
            </div>
          )}
        </div>

        <div
          className={styles.content}
          title={notification.message}
          dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
        ></div>
        {notification.notification_type !==
          NOTIFICATION_CATEGORIES_ENUM.REMINDERS && (
          <div className={styles.actionIcon} onClick={handleAddTaskClick}>
            <PlusIcon />
            {t("addTask")}
          </div>
        )}
        <p className={styles.timestamp}>{timestamp}</p>
      </div>
    </div>
  )
}

export default NotificationCard
