import React, { forwardRef, useImperativeHandle, useRef } from "react"
import styles from "./page-wrapper-content.module.css"
import { useScrollRestoration } from "@/hooks/use-scroll-restoration.ts"

interface PageContentWrapperSmallProps {
  children: React.ReactNode
  hasPadding?: boolean
}

const PageContentWrapperSmall = forwardRef<
  HTMLDivElement,
  PageContentWrapperSmallProps
>(({ children, hasPadding = true }, ref) => {
  const internalRef = useRef<HTMLDivElement>(null)

  useScrollRestoration(internalRef)

  useImperativeHandle(ref, () => internalRef.current!)

  return (
    <div
      ref={ref}
      className={styles.scrollableContentSmall}
      style={{
        padding: hasPadding ? "16px" : 0,
      }}
    >
      {children}
    </div>
  )
})

export default PageContentWrapperSmall
