import React from "react"
import styles from "./no-results-found.module.css"
import ObjectNotFoundSvg from "@/assets/icons/error-handling/object-not-found.svg"

interface NoResultsFoundProps {
  message?: string
  children?: React.ReactNode
}

const NoResultsFound: React.FC<NoResultsFoundProps> = ({
  message,
  children,
}) => {
  return (
    <div className={styles.error}>
      <img
        src={ObjectNotFoundSvg}
        alt="Not Found"
        className={styles.notFoundImg}
      />
      {message && <p className={styles.errorMessage}>{message}</p>}
      {children}
    </div>
  )
}

export default NoResultsFound
