import React from "react"
import { WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const CowHeadIcon: React.FC<GeneralSvgProps> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size || "30"}
    height={props.size || "30"}
    viewBox="36 356 30 30"
  >
    <g data-name="Group 2462">
      <path
        d="M43 356h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H43a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
        fill={props.fill || "var(--notification-yellow)"}
        fillRule="evenodd"
        data-name="box icon #1"
      />
      <g data-name="cow-face-svgrepo-com">
        <path
          d="M57.703 367.09c.242-.39.412-.794.49-1.19.314-1.58-.709-3.397-2.513-4.082-.334-.127-.519.157-.324.448.474.705.86 2.015.157 2.914a2.522 2.522 0 0 1-.205.228l-.868-.61h-6.88l-.868.61a2.523 2.523 0 0 1-.205-.228c-.704-.899-.317-2.208.157-2.914.195-.291.01-.575-.324-.448-1.804.685-2.827 2.501-2.513 4.082.078.396.248.8.49 1.19l-3.39 2.38s2.523 2.522 4.707.778c0 1.246.545 2.679 1.76 3.52l.653 2.604a1.962 1.962 0 0 0 .56 3.842h4.825a1.962 1.962 0 0 0 .56-3.842l.655-2.605c1.214-.84 1.76-2.273 1.76-3.519 2.183 1.744 4.706-.778 4.706-.778l-3.39-2.38Z"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.2"
          stroke={props.stroke || WHITE}
          fill="transparent"
          data-name="Path 1255"
        />
      </g>
    </g>
  </svg>
)

export default CowHeadIcon
