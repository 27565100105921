import { toast } from "react-toastify"
import * as Yup from "yup"
import { type Transaction } from "@/features/finance/types/transaction.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

export const transactionSchema = Yup.object().shape({
  amount: Yup.string().required("amountRequired"),
  date: Yup.date()
    .nullable()
    .transform((_value, originalValue) =>
      originalValue === null ||
      originalValue === "" ||
      isNaN(new Date(originalValue).getTime())
        ? null
        : new Date(originalValue),
    )
    .max(new Date(), "dateCannotBeInTheFuture")
    .required("dateRequired"),
  category: Yup.string().required("categoryRequired"),
})

export const validateTransaction = async (
  transaction: Partial<Transaction> = {},
) => {
  try {
    await transactionSchema.validate(transaction, { abortEarly: false })
    return true
  } catch (error) {
    if (error instanceof Yup.ValidationError) {
      toast.error(<CustomErrorToast errors={error.errors} />)
    }
    return false
  }
}
