import { BROWN, DARK_GREY } from "@/utils/constants/colors-constants.ts"

interface MenuDashboardIconProps {
  is_active?: boolean
}

const MenuDashboardIcon: React.FC<MenuDashboardIconProps> = ({ is_active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.557"
      height="28.182"
      viewBox="21.72 273.908 27.557 28.182"
    >
      <g data-name="Group 2742">
        <g data-name="Path 1398">
          <path
            d="M46.145 273.908h-4.697a3.131 3.131 0 0 0-3.131 3.131v4.697a3.131 3.131 0 0 0 3.131 3.132h4.697a3.131 3.131 0 0 0 3.132-3.132v-4.697a3.131 3.131 0 0 0-3.132-3.131Z"
            fill={is_active ? BROWN : DARK_GREY}
            fillRule="evenodd"
          />
          <path
            d="M46.145 273.908h-4.697a3.131 3.131 0 0 0-3.131 3.131v4.697a3.131 3.131 0 0 0 3.131 3.132h4.697a3.131 3.131 0 0 0 3.132-3.132v-4.697a3.131 3.131 0 0 0-3.132-3.131Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="2"
            stroke={is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
        <g data-name="Path 1399">
          <path
            d="M29.548 273.908h-4.697a3.131 3.131 0 0 0-3.131 3.131v4.697a3.131 3.131 0 0 0 3.131 3.132h4.697a3.131 3.131 0 0 0 3.132-3.132v-4.697a3.131 3.131 0 0 0-3.132-3.131Z"
            fill={is_active ? BROWN : DARK_GREY}
            fillRule="evenodd"
          />
          <path
            d="M29.548 273.908h-4.697a3.131 3.131 0 0 0-3.131 3.131v4.697a3.131 3.131 0 0 0 3.131 3.132h4.697a3.131 3.131 0 0 0 3.132-3.132v-4.697a3.131 3.131 0 0 0-3.132-3.131Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="2"
            stroke={is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
        <g data-name="Path 1400">
          <path
            d="M46.145 291.13h-4.697a3.131 3.131 0 0 0-3.131 3.131v4.697a3.131 3.131 0 0 0 3.131 3.132h4.697a3.131 3.131 0 0 0 3.132-3.132v-4.697a3.131 3.131 0 0 0-3.132-3.131Z"
            fill={is_active ? BROWN : DARK_GREY}
            fillRule="evenodd"
          />
          <path
            d="M46.145 291.13h-4.697a3.131 3.131 0 0 0-3.131 3.131v4.697a3.131 3.131 0 0 0 3.131 3.132h4.697a3.131 3.131 0 0 0 3.132-3.132v-4.697a3.131 3.131 0 0 0-3.132-3.131Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="2"
            stroke={is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
        <g data-name="Path 1401">
          <path
            d="M29.548 291.13h-4.697a3.131 3.131 0 0 0-3.131 3.131v4.697a3.131 3.131 0 0 0 3.131 3.132h4.697a3.131 3.131 0 0 0 3.132-3.132v-4.697a3.131 3.131 0 0 0-3.132-3.131Z"
            fill={is_active ? BROWN : DARK_GREY}
            fillRule="evenodd"
          />
          <path
            d="M29.548 291.13h-4.697a3.131 3.131 0 0 0-3.131 3.131v4.697a3.131 3.131 0 0 0 3.131 3.132h4.697a3.131 3.131 0 0 0 3.132-3.132v-4.697a3.131 3.131 0 0 0-3.132-3.131Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="2"
            stroke={is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default MenuDashboardIcon
