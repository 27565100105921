import React from "react"
import { useTranslation } from "react-i18next"
import WeightIcon from "@/assets/icons/misc/weight-icon.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import styles from "@/features/animal-events/components/animal-event-editor/events-editor.module.css"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  type AnimalEventDraft,
  setDraftEventSubDataWeightValue,
} from "@/redux/slices/events-draft-slice.ts"

interface MeasurementEventEditorProps {
  draftEvent: AnimalEventDraft
}

const MeasurementEventEditor: React.FC<MeasurementEventEditorProps> = ({
  draftEvent,
}) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const setWeight = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDraftEventSubDataWeightValue(event.target.value))
  }

  return (
    <>
      <div className={styles.eventInfo}>{t("specificEventDetails")}</div>
      <InputFieldWrapperWithIcon
        icon={<WeightIcon size={30} />}
        align={"row"}
        name={t("value")}
        maxLength={14}
        inputMode={"numeric"}
        isDisabled={false}
        value={draftEvent.event_data?.value}
        isRequired={draftEvent.event_data?.value === undefined}
        onChange={setWeight}
        details={t("Kg")}
      />
    </>
  )
}

export default MeasurementEventEditor
