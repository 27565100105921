import React from "react"
import { useNavigate } from "react-router-dom"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import styles from "@/features/home/components/tasks-widget/tasks-widget.module.css"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import { useGetTasksQuery } from "@/features/tasks/api/tasks-api.ts"
import TaskCard from "@/features/tasks/components/task-card/task-card.tsx"
import {
  NEW_TASK_SCREEN_PATH,
  TASKS_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface TasksWidgetProps {
  maxTasksNumberToDisplay?: number
  tasks?: string[]
  animalId?: string
}

const TasksWidget: React.FC<TasksWidgetProps> = ({
  maxTasksNumberToDisplay = 6,
  tasks,
  animalId,
}) => {
  const navigate = useNavigate()
  const { data: allTasks = [] } = useGetTasksQuery()
  let workingTasks

  const navigationString = animalId
    ? `${NEW_TASK_SCREEN_PATH}/${animalId}`
    : NEW_TASK_SCREEN_PATH

  const addItemGoTo = () => {
    navigate(navigationString)
  }

  if (animalId && tasks) {
    workingTasks = allTasks.filter((task) => tasks.includes(task.id))
  } else {
    workingTasks = allTasks.slice(0, maxTasksNumberToDisplay)
  }

  return (
    <WidgetCard
      title={"tasks"}
      navigateTo={TASKS_SCREEN_PATH}
      {...(tasks && tasks.length > 0 && { addItemGoTo })}
      customStyles={
        !animalId
          ? {
              height: "fit-content",
              marginInline: 0,
              marginBlock: 0,
              width: "100%",
            }
          : {}
      }
    >
      <div className={styles["tasks-flex-desktop"]}>
        {workingTasks && workingTasks.length > 0 ? (
          workingTasks.map((task) => <TaskCard key={task.id} task={task} />)
        ) : (
          <EmptyContentCreateItemButton navigateTo={navigationString} />
        )}
      </div>
    </WidgetCard>
  )
}

export default TasksWidget
