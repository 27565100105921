import { skipToken } from "@reduxjs/toolkit/query"
import { useGetMyFarmQuery } from "@/features/settings-account/api/account-api.ts"
import { initialAppPackage } from "@/features/settings-account/utils/initialStates.ts"
import { useAuth } from "@/hooks/use-auth.ts"

function useFarmPermissions() {
  const auth = useAuth()
  const { data, ...rest } = useGetMyFarmQuery(
    auth.token ? undefined : skipToken,
    { pollingInterval: 5 * 60 * 1000 }, // refetch every 5 minutes
  )

  const permissions = !data?.app_package ? initialAppPackage : data?.app_package

  return { ...rest, permissions }
}

export default useFarmPermissions
