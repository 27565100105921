export const getFormattedTimeUnit = (unit: number): string =>
  unit.toString().padStart(2, "0")

export const getInitialHours = (value?: string | Date): string => {
  const date = value ? new Date(value) : new Date()
  return getFormattedTimeUnit(date.getHours())
}

export const getInitialMinutes = (value?: string | Date): string => {
  const date = value ? new Date(value) : new Date()
  return getFormattedTimeUnit(date.getMinutes())
}

export const initialTimeState = (value?: string | Date) => ({
  hours: getInitialHours(value),
  minutes: getInitialMinutes(value),
})

export const convertToUTCWithoutMilliseconds = (date: Date): string => {
  return date.toISOString().split(".")[0] + "Z"
}

export function roundDateToNearestInterval(date: Date): Date {
  const minutes = date.getMinutes()
  const hours = date.getHours()
  let roundedMinutes: number
  let roundedHours: number = hours

  if (minutes < 15) {
    roundedMinutes = 15
  } else if (minutes < 30) {
    roundedMinutes = 30
  } else if (minutes < 45) {
    roundedMinutes = 45
  } else {
    roundedMinutes = 0
    roundedHours = (hours + 1) % 24
  }

  const roundedDate = new Date(date)
  roundedDate.setHours(roundedHours)
  roundedDate.setMinutes(roundedMinutes)
  roundedDate.setSeconds(0)
  roundedDate.setMilliseconds(0)

  return roundedDate
}
