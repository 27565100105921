import { isToday, isFuture } from "date-fns"
import React from "react"
import TimelineItem from "@/components/timeline/timeline-item/timeline-item.tsx"
import GestationCalendarAccordion from "@/features/gestational-calendar/components/gestation-calendar-accordion/gestation-calendar-accordion.tsx"
import {
  CalendarActivityState,
  type GestationCalendar,
} from "@/features/gestational-calendar/types/calendar-types.ts"
import { checkIsCalendarModified } from "@/features/gestational-calendar/utils/gestational-calendar-utils.ts"
import { getDayElapsedPercentageBetweenPastDateAndFutureDate } from "@/utils/helpers/time-utils.ts"

interface TimeLineTupleProps {
  gestationalCalendar: GestationCalendar
  index: number
  lastIndex?: number
  isOpen?: boolean
  forcedActivityState?: CalendarActivityState
}

/**
 * @param gestationalCalendar
 * @param index used to determine current and following activity
 * @param activityState forced activity state, that means tell the component what state to have
 * @constructor
 */
const TimelineTuple: React.FC<TimeLineTupleProps> = ({
  gestationalCalendar,
  index,
  lastIndex,
  isOpen,
  forcedActivityState,
}) => {
  const currentActivity = gestationalCalendar.entries[index]
  const nextActivity = gestationalCalendar.entries[index + 1]
  const isCalvingDate =
    gestationalCalendar.calving_date === currentActivity.event_date
  const isCalendarModified = checkIsCalendarModified(gestationalCalendar)

  const dueDate = new Date(currentActivity.event_date)

  const completionPercentage =
    nextActivity &&
    getDayElapsedPercentageBetweenPastDateAndFutureDate(
      nextActivity?.event_date,
      currentActivity.event_date,
    )

  const activityState = (() => {
    if (forcedActivityState) {
      return forcedActivityState
    } else if (isToday(dueDate)) {
      return CalendarActivityState.CURRENT
    } else if (isFuture(dueDate)) {
      return CalendarActivityState.DUE
    } else {
      return CalendarActivityState.DONE
    }
  })()

  const isAfterCalving =
    currentActivity.event.is_calving_relative && isCalendarModified

  return (
    <TimelineItem
      activityState={activityState}
      completionPercentage={completionPercentage}
      isLast={index === lastIndex}
      isAfterCalving={isAfterCalving}
    >
      <GestationCalendarAccordion
        gestationCalendar={gestationalCalendar}
        animalId={gestationalCalendar.animal}
        activityState={activityState}
        calendarActivity={currentActivity}
        isCalvingDone={isCalendarModified}
        isCalving={isCalvingDate}
        isOpen={isOpen}
        isAfterCalving={isAfterCalving}
      />
    </TimelineItem>
  )
}

export default TimelineTuple
