import { useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./associated-list.module.css"
import AddCircleIcon from "@/assets/icons/misc/add-circle-icon.tsx"
import RemoveCircleIcon from "@/assets/icons/misc/remove-circle-icon.tsx"

interface AssociatedListProps<T> {
  items: T[]
  onItemsChange: (items: T[]) => void
  renderItem: (item: T, onDelete: (id: T) => void) => JSX.Element
  seeMoreText: string
  hideMoreText: string
}

const AssociatedList = <T extends string>({
  items,
  onItemsChange,
  renderItem,
  seeMoreText,
  hideMoreText,
}: AssociatedListProps<T>) => {
  const { t } = useTranslation()
  const [listLimit, setListLimit] = useState(3)

  const expandList = () => {
    setListLimit(items.length)
  }

  const collapseList = () => {
    setListLimit(3)
  }

  const handleItemDeletion = (itemId: T) => {
    const updatedItems = items.filter((id) => id !== itemId)
    onItemsChange(updatedItems)
  }

  return (
    <div className={styles["associated-list"]}>
      {items
        .slice(0, listLimit)
        .map((item) => renderItem(item, handleItemDeletion))}
      {items.length > listLimit && (
        <i className={styles["more-items"]} onClick={expandList}>
          <AddCircleIcon size={20} /> {t(seeMoreText)}
        </i>
      )}
      {items.length === listLimit && items.length > 3 && (
        <i className={styles["more-items"]} onClick={collapseList}>
          <RemoveCircleIcon size={20} /> {t(hideMoreText)}
        </i>
      )}
    </div>
  )
}

export default AssociatedList
