import { useTranslation } from "react-i18next"
import styles from "../settings-routes.module.css"
import PasswordIcon from "@/assets/icons/settings/password-icon.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { InputType } from "@/utils/enums/input-type-enum.ts"

const SettingsResetPassword = () => {
  const { t } = useTranslation()
  const { handleBackNavigation } = useNavigationFallback()

  return (
    <>
      <SettingsTopBar
        title={t("resetPassword")}
        onSaveChanges={handleBackNavigation}
      />
      <div className={styles.settingsContent}>
        <div className={styles.imageSelectionContainer}>
          <div className={styles.imageCircleCenter}>
            <PasswordIcon />
          </div>
        </div>

        <div className={styles.card}>
          <div className={styles.cardItemName}>{t("inputCurrentPassword")}</div>
          <input
            name={t("inputCurrentPassword")}
            alt={t("inputCurrentPassword")}
            className={styles.titleInput}
            type={InputType.TEXT}
            placeholder={t("inputCurrentPassword")}
            value={"Placeholder pana la integrare cu backend"}
            onChange={() => {}}
          />

          <div className={styles.cardItemName}>{t("inputNewPassword")}</div>
          <input
            name={t("inputNewPassword")}
            alt={t("inputNewPassword")}
            className={styles.titleInput}
            type={InputType.TEXT}
            placeholder={t("inputNewPassword")}
            value={"Placeholder pana la integrare cu backend"}
            onChange={() => {}}
          />

          <div className={styles.cardItemName}>{t("confirmNewPassword")}</div>
          <input
            name={t("confirmNewPassword")}
            alt={t("confirmNewPassword")}
            className={styles.titleInput}
            type={InputType.TEXT}
            placeholder={t("confirmNewPassword")}
            value={"Placeholder pana la integrare cu backend"}
            onChange={() => {}}
          />
        </div>
      </div>
    </>
  )
}

export default SettingsResetPassword
