import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "../gestation-period-calendar-widget/gestation-period-calendar-widget.module.css"
import WidgetCardAdjustable from "@/features/home/components/widget-card-adjustable/widget-card-adjustable.tsx"
import { AI_ASSISTANT_SCREEN_PATH } from "@/utils/constants/routes.ts"
import dorel from "@/assets/images/dorel.png"

function AssistantWidget() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToAssistant = () => {
    navigate(AI_ASSISTANT_SCREEN_PATH)
  }

  return (
    <WidgetCardAdjustable title={"virtualFarmAssistant"}>
      <div className={styles["widget-container"]}>
        <div
          style={{
            width: "210px",
          }}
        >
          <p className={styles["info"]}>{t("farmAdviceWithAI")}</p>
        </div>
        <button
          className={styles["generic-button"]}
          onClick={navigateToAssistant}
        >
          {t("askAssistant")}
        </button>
        <img src={dorel} alt="" className={styles.aiImage} />
      </div>
    </WidgetCardAdjustable>
  )
}

export default AssistantWidget
