import { useLayoutEffect } from "react"
import { useGetProfileQuery } from "@/features/settings-account/api/account-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setUserDraft } from "@/redux/slices/user-profile-draft-slice.ts"

const useGetDraftUserProfile = () => {
  const dispatch = useAppDispatch()
  const { data, isLoading } = useGetProfileQuery()

  const draftUserProfile = useAppSelector(
    (state) => state.userProfileSlice.draft,
  )

  useLayoutEffect(() => {
    if (data) {
      dispatch(setUserDraft(data))
    }
  }, [data, dispatch])

  return { draftUserProfile, data, isLoading }
}

export default useGetDraftUserProfile
