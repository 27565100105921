import React from "react"
import styles from "./multi-step-progress-bar.module.css"

interface MultiStepProgressBarProps {
  steps: number
  currentStep: number
  colorScheme?: "purple" | "brown"
}

const MultiStepProgressBar: React.FC<MultiStepProgressBarProps> = ({
  steps,
  currentStep,
  colorScheme = "brown",
}) => {
  const progressWidth = (currentStep / steps) * 100

  return (
    <div
      className={styles.progressBarContainer}
      data-is-purple={colorScheme === "purple"}
    >
      <div
        className={styles.progressBar}
        data-is-purple={colorScheme === "purple"}
        style={{ width: `${progressWidth}%` }}
      ></div>
    </div>
  )
}

export default MultiStepProgressBar
