export enum ReminderConstants {
  THIRTY_MINUTES = 1800,
  ONE_HOUR = 3600,
  ONE_DAY = 86400,
  ONE_WEEK = 604800,
}

export enum RecurrenceConstants {
  DAILY = 1,
  WEEKLY = 2,
  MONTHLY = 3,
  YEARLY = 4,
}

// TODO: Add comment here with why we need 500ms
// to delete an animal
export const DELETE_ENTRY_TIMEOUT = 500
