import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./notifications-by-time-span.module.css"
import DateSeparator from "@/features/notes/components/date-separator/date-separator.tsx"
import NotificationCard from "@/features/notifications/components/notification-card/notification-card.tsx"
import { type Notification } from "@/features/notifications/types/notifications.ts"

interface NotificationsByTimeSpanProps {
  notifications: Notification[]
  subTitle?: string
  enableSeparator?: boolean
  title?: string
}

const NotificationsByTimeSpan: React.FC<NotificationsByTimeSpanProps> = ({
  notifications,
  subTitle,
  enableSeparator = false,
  title,
}) => {
  const { t } = useTranslation()
  const translatedSubTitle = subTitle ? t(subTitle) : ""
  const translatedTitle = title ? t(title) : ""

  return (
    <div>
      <div className={styles["sub-title-updated"]}>{t(translatedTitle)}</div>
      {enableSeparator && <DateSeparator monthYear={t(translatedSubTitle)} />}
      {notifications.map((notification) => (
        <NotificationCard key={notification.id} notification={notification} />
      ))}
    </div>
  )
}

export default NotificationsByTimeSpan
