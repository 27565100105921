import { PASTEL_BLUE, WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const AnimalStatusLacatingIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 40}
      height={props.size || 40}
      viewBox="4426.248 -5730.282 40 40"
    >
      <g data-name="Group 4255">
        <path
          d="M4426.248-5710.332a20 20 0 1 1 0 .1z"
          fill={props.secondary_color ? props.secondary_color : WHITE}
          fillRule="evenodd"
          data-name="Ellipse Calduri"
        />
        <g data-name="Group 4239">
          <g data-name="Icons_READY-03">
            <path
              d="M4446.608-5709.077c-3.747 0 4.43 0 0 0Zm7.56-2.084 1.308 2.497c.336.643.087 1.44-.556 1.776a1.318 1.318 0 0 1-1.776-.555l-1.07-2.043a11.496 11.496 0 0 0 2.094-1.675Zm-8.875 3.172a10.264 10.264 0 0 0 2.632 0v3.007c0 .726-.59 1.316-1.316 1.316-.726 0-1.316-.59-1.316-4.323Zm-4.15-1.498-1.07 2.044a1.317 1.317 0 0 1-1.776.555c-.642-.337-.892-1.133.753-4.273.196.198 1.579 1.353 2.094 1.674Zm-5.157-6.563c-1.043.73 1.288 2.802 2.227 3.958l-1.51 2.884a2.49 2.49 0 0 0 1.05 3.359 2.49 2.49 0 0 0 3.358-1.05l1.06-2.022c.63.304 1.28.54 1.95.709v3.23a2.491 2.491 0 0 0 2.488 2.489 2.49 2.49 0 0 0 2.488-2.489v-3.23c.669-.169 1.321-.406 1.95-.709l1.059 2.022a2.49 2.49 0 0 0 3.358 1.05 2.49 2.49 0 0 0 1.05-3.359l-1.51-2.884c.94-1.156 3.331-3.467 2.227-3.958 0 0-5.311 6.973-10.622 6.973s-10.623-6.973-10.623-6.973Z"
              fill={props.primary_color ? props.primary_color : PASTEL_BLUE}
              fillRule="evenodd"
            />
            <path
              d="M4446.608-5709.077c-3.747 0 4.43 0 0 0Zm7.56-2.084 1.308 2.497c.336.643.087 1.44-.556 1.776a1.318 1.318 0 0 1-1.776-.555l-1.07-2.043a11.496 11.496 0 0 0 2.094-1.675Zm-8.875 3.172a10.264 10.264 0 0 0 2.632 0v3.007c0 .726-.59 1.316-1.316 1.316-.726 0-1.316-.59-1.316-4.323Zm-4.15-1.498-1.07 2.044a1.317 1.317 0 0 1-1.776.555c-.642-.337-.892-1.133.753-4.273.196.198 1.579 1.353 2.094 1.674Zm-5.157-6.563c-1.043.73 1.288 2.802 2.227 3.958l-1.51 2.884a2.49 2.49 0 0 0 1.05 3.359h0a2.49 2.49 0 0 0 3.358-1.05l1.06-2.022c.63.304 1.28.54 1.95.709v3.23a2.491 2.491 0 0 0 2.488 2.489 2.49 2.49 0 0 0 2.488-2.489v-3.23c.669-.169 1.321-.406 1.95-.709l1.059 2.022a2.49 2.49 0 0 0 3.358 1.05h0a2.49 2.49 0 0 0 1.05-3.359l-1.51-2.884c.94-1.156 3.331-3.467 2.227-3.958 0 0-5.311 6.973-10.622 6.973s-10.623-6.973-10.623-6.973Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke={props.primary_color ? props.primary_color : PASTEL_BLUE}
              fill="transparent"
            />
          </g>
          <path
            d="M4434.85-5710.597a11.65 11.65 0 1 1 0 .094z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.5844"
            stroke={props.primary_color ? props.primary_color : PASTEL_BLUE}
            fill="transparent"
            data-name="Ellipse 500"
          />
        </g>
      </g>
    </svg>
  )
}

export default AnimalStatusLacatingIcon
