import React from "react"
import GenericSelectTrigger from "@/components/drawer-triggers/generic-drawer-trigger.tsx"
import { type GenericType } from "@/features/animal-events/types/animal-events.ts"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import ChooseSomethingIcon from "@/assets/icons/finance/choose-something-icon.tsx"

interface GenericTypeSelectTriggerProps {
  currentValue: GenericType | undefined
  fallbackText: string
  onClick?: () => void
}

const GenericTypeSelectTrigger = React.forwardRef<
  HTMLDivElement,
  GenericTypeSelectTriggerProps
>(({ onClick, currentValue, fallbackText }, ref) => {
  const displayText = (
    currentValue: GenericType | undefined,
    t: (key: string) => string,
  ) => {
    return currentValue ? t(currentValue.name) : t(fallbackText)
  }

  const displayIcon = (currentValue: GenericType | undefined) => {
    return currentValue?.picture ? (
      <GenericPicture
        src={currentValue.picture}
        alt=""
        size={30}
        borderRadius={50}
      />
    ) : (
      <ChooseSomethingIcon size={30} />
    )
  }

  return (
    <GenericSelectTrigger
      ref={ref}
      onClick={onClick}
      currentValue={currentValue}
      displayText={(currentValue, t) => displayText(currentValue, t)}
      displayIcon={displayIcon}
    />
  )
})

GenericTypeSelectTrigger.displayName = "GenericTypeSelectTrigger"

export default GenericTypeSelectTrigger
