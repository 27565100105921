import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type TransactionCategory } from "@/features/finance/types/transaction.ts"

const TRANSACTION_CATEGORIES_BASE_URL = "transaction-categories/"

export const transactionsCategoriesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionCategories: builder.query<
      Record<string, TransactionCategory>,
      void
    >({
      query: () => ({
        url: TRANSACTION_CATEGORIES_BASE_URL,
      }),
      transformResponse: (response: TransactionCategory[]) => {
        return response.reduce<Record<string, TransactionCategory>>(
          (acc, category) => {
            return { ...acc, [category.id]: category }
          },
          {},
        )
      },
      providesTags: () => [{ type: TagType.TransactionCategories, id: "LIST" }],
    }),
    postTransactionCategory: builder.mutation<
      TransactionCategory,
      Partial<TransactionCategory>
    >({
      query: (transactionCategory) => {
        return {
          url: TRANSACTION_CATEGORIES_BASE_URL,
          method: "POST",
          body: transactionCategory,
        }
      },
      invalidatesTags: [{ type: TagType.TransactionCategories, id: "LIST" }],
    }),
    deleteTransactionCategory: builder.mutation<void, string>({
      query: (categoryId) => ({
        url: `${TRANSACTION_CATEGORIES_BASE_URL}${categoryId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TagType.TransactionCategories, id: "LIST" }],
    }),
    updateTransactionCategory: builder.mutation<
      TransactionCategory,
      Partial<TransactionCategory> & { id: string }
    >({
      query: ({ id, ...patch }) => ({
        url: `${TRANSACTION_CATEGORIES_BASE_URL}${id}/`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: [{ type: TagType.TransactionCategories, id: "LIST" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTransactionCategoriesQuery,
  usePostTransactionCategoryMutation,
  useDeleteTransactionCategoryMutation,
  useUpdateTransactionCategoryMutation,
} = transactionsCategoriesApi
