import * as Yup from "yup"

export const userProfileSchema = Yup.object().shape({
  first_name: Yup.string()
    .required("nameUserRequired")
    .max(100, "nameMaxLength"),
  last_name: Yup.string()
    .required("lastNameRequired")
    .max(100, "lastNameMaxLength"),
  phone_number: Yup.string()
    .matches(/^\+?\d{10,12}$/, "phoneNumberInvalid")
    .required("phoneNumberRequired"),
})
