import imageCompression from "browser-image-compression"
import React, { useState, useEffect } from "react"
import type ReactQuill from "react-quill"
import styles from "./custom-toolbar.module.css"
import FormatOverlay from "./format-overlay.tsx"
import FormatTextIcon from "@/assets/icons/misc/format-text-icon.tsx"
import ImageIcon from "@/assets/icons/misc/image-icon.tsx"
import DrawerAnimals from "@/features/farm/components/drawer-animals/drawer-animals.tsx"
import { type NoteDraft } from "@/features/notes"
import AssocAnimalFromNoteTrigger from "@/features/notes/components/assoc-entity-from-note-trigger/assoc-animal-from-note-trigger.tsx"
import AssocTaskFromNoteTrigger from "@/features/notes/components/assoc-entity-from-note-trigger/assoc-task-from-note-trigger.tsx"
import DrawerTasks from "@/features/tasks/components/drawer-tasks/drawer-tasks.tsx"

/**
 * Props for the CustomToolbar component.
 */
interface CustomToolbarProps {
  /**
   * Reference to the ReactQuill editor instance.
   */
  editorRef: React.RefObject<ReactQuill>
  draftNote: NoteDraft
  onAnimalsChange: (animalIDs: string[]) => void
  onTasksChange: () => void
}

/**
 * A custom toolbar component for formatting text and inserting elements into the editor.
 * @param {CustomToolbarProps} props - The props for the CustomToolbar component.
 * @returns {TSX.Element} The TSX element representing the CustomToolbar component.
 */
const CustomToolbar: React.FC<CustomToolbarProps> = ({
  editorRef,
  draftNote,
  onAnimalsChange,
  onTasksChange,
}) => {
  const [isFormatActive, setIsFormatActive] = useState(false)
  const [formatState, setFormatState] = useState({
    bold: false,
    italic: false,
    underline: false,
    strike: false,
    header: "normal",
    list: "",
  })

  useEffect(() => {
    const editor = editorRef.current?.getEditor()
    const updateFormatState = () => {
      if (!editor) return

      const formats = editor.getFormat()
      setFormatState({
        bold: !!formats.bold,
        italic: !!formats.italic,
        underline: !!formats.underline,
        strike: !!formats.strike,
        header: formats.header ? `h${formats.header}` : "normal",
        list: formats.list || "",
      })
    }

    editor?.on("selection-change", updateFormatState)

    return () => {
      editor?.off("selection-change", updateFormatState)
    }
  }, [editorRef])

  /**
   * Toggles the specified format in the editor.
   * @param {keyof typeof formatState} format - The format to toggle-switch.
   * @param {boolean | string | number} [value] - The value to set for the format (optional).
   */
  const toggleFormat = (
    format: keyof typeof formatState,
    value?: boolean | string | number,
  ) => {
    const editor = editorRef.current?.getEditor()
    if (!editor) return

    switch (format) {
      case "bold":
      case "italic":
      case "underline":
      case "strike":
        editor.format(
          format,
          value !== undefined ? value : !formatState[format],
        )
        break
      case "header":
        editor.format(format, formatState.header === value ? false : value)
        setIsFormatActive(false)
        break
      case "list":
        editor.format("list", formatState.list === value ? false : value)
        setIsFormatActive(false)
        break
      default:
        console.warn("Unsupported format:", format)
    }

    setTimeout(updateFormatState, 0)
  }

  /**
   * Updates the format state based on the current selection in the editor.
   */
  const updateFormatState = () => {
    const editor = editorRef.current?.getEditor()
    if (!editor) return

    const formats = editor.getFormat()
    setFormatState((prevState) => ({
      ...prevState,
      bold: !!formats.bold,
      italic: !!formats.italic,
      underline: !!formats.underline,
      strike: !!formats.strike,
      header: formats.header || 0,
      list: formats.list || "",
    }))
  }

  /**
   * Adjusts the indentation of the selected text in the editor.
   * @param {"indent" | "outdent"} direction - The direction of indentation adjustment.
   */
  const adjustIndentation = (direction: "indent" | "outdent") => {
    const editor = editorRef.current?.getEditor()
    if (!editor) return

    const range = editor.getSelection()
    if (!range) return

    const currentFormat = editor.getFormat(range)
    const currentIndent = currentFormat.indent || 0
    if (direction === "indent") {
      editor.format("indent", currentIndent + 1) // Increment indentation
    } else if (direction === "outdent" && currentIndent > 0) {
      editor.format("indent", currentIndent - 1) // Decrement indentation
    }
  }

  /**
   * Inserts an image into the editor.
   */
  const insertImage = () => {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = "image/*"
    input.onchange = async () => {
      const file = input.files?.[0]
      if (!file) return

      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1024,
        useWebWorker: true,
      }

      try {
        const compressedFile = await imageCompression(file, options)
        const reader = new FileReader()
        reader.onload = (e) => {
          const editor = editorRef.current?.getEditor()
          if (!editor) return

          const range = editor.getSelection(true)
          const newIndex = range.index + 1

          editor.insertText(newIndex, "\n")
          editor.insertEmbed(range.index, "image", e.target?.result, "user")
          editor.setSelection(newIndex, 0)
          editor.insertText(newIndex, "\n")
        }
        reader.readAsDataURL(compressedFile)
      } catch (error) {
        console.log(error)
      }
    }
    input.click()
  }

  return (
    <>
      <div className={styles["custom-toolbar"]}>
        <button
          type="button"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            setIsFormatActive(true)
          }}
          className={
            isFormatActive ? styles["format-active"] : styles["format-inactive"]
          }
        >
          <FormatTextIcon is_active={isFormatActive} />
        </button>

        <div className={styles["format-icon"]} onClick={insertImage}>
          <ImageIcon alt="Insert Image" />
        </div>

        <div className={styles["format-icon"]}>
          <DrawerTasks
            currentValue={draftNote.tasks}
            TriggerComponent={AssocTaskFromNoteTrigger}
            onSaveClick={onTasksChange}
          />
        </div>

        <div className={styles["format-icon"]}>
          <DrawerAnimals
            currentValue={draftNote.animals}
            onSaveClick={onAnimalsChange}
            TriggerComponent={AssocAnimalFromNoteTrigger}
          />
        </div>
      </div>

      <FormatOverlay
        isFormatActive={isFormatActive}
        setIsFormatActive={setIsFormatActive}
        toggleFormat={toggleFormat}
        formatState={formatState}
        adjustIndentation={adjustIndentation}
      />
    </>
  )
}

export default CustomToolbar
