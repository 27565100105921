import { useDebounce } from "@uidotdev/usehooks"
import { useState, useEffect, useMemo } from "react"
import { useGetTasksQuery } from "@/features/tasks/api/tasks-api"
import {
  filterAndSortTasks,
  groupTasksByDate,
  type TaskClassification,
} from "@/features/tasks/types/task-utils"
import { useAppSelector } from "@/redux/hooks.ts"

export interface TaskFilterSelections {
  category: string
  is_done: boolean | number
  animals: string[]
}

const useFilteredTasks = () => {
  const { data: tasks = [] } = useGetTasksQuery()
  const [searchQuery, setSearchQuery] = useState<string>("")
  const debouncedSearchQuery = useDebounce(searchQuery, 100)
  const [filteredTasks, setFilteredTasks] = useState<TaskClassification>({
    dueTasks: [],
    currentTasks: [],
    futureTasks: [],
    doneTasks: [],
  })
  const [selections, setSelections] = useState<TaskFilterSelections>({
    category: "",
    is_done: false,
    animals: [],
  })

  const drafts = useAppSelector((state) => state.taskDraftSlice.drafts)

  const memoizedTasks = useMemo(() => tasks, [tasks])
  const memoizedSelections = useMemo(() => selections, [selections])
  const memoizedDrafts = useMemo(() => drafts, [drafts])

  useEffect(() => {
    const filteredAndSortedTasks = filterAndSortTasks(
      memoizedTasks,
      debouncedSearchQuery,
      memoizedSelections,
    )

    const tasksWithDrafts = filteredAndSortedTasks.map((task) => {
      if (memoizedDrafts[task.id]) {
        return { ...task, hasChanges: true }
      } else {
        return task
      }
    })

    const { currentTasks, dueTasks, futureTasks, doneTasks } =
      groupTasksByDate(tasksWithDrafts)

    setFilteredTasks({
      dueTasks,
      currentTasks,
      futureTasks,
      doneTasks,
    })
  }, [debouncedSearchQuery, memoizedTasks, memoizedSelections, memoizedDrafts])

  return {
    searchQuery,
    setSearchQuery,
    filteredTasks,
    selections,
    setSelections,
  }
}

export default useFilteredTasks
