import React, { type ChangeEvent, useCallback } from "react"
import { useTranslation } from "react-i18next"
import PersonIcon from "@/assets/icons/settings/person-icon.tsx"
import SelectPicture from "@/components/select-picture-circle-base64/select-picture-circle-base64.tsx"
import styles from "@/features/settings-account/routes/settings-routes.module.css"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import {
  setDraftUserFirstName,
  setDraftUserLastName,
  setDraftUserPhone,
  setDraftUserPicture,
} from "@/redux/slices/user-profile-draft-slice.ts"
import { InputType } from "@/utils/enums/input-type-enum.ts"

const UserProfileEditor: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const draftUserProfile = useAppSelector(
    (state) => state.userProfileSlice.draft,
  )

  const handleFirstNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDraftUserFirstName(e.target.value))
    },
    [dispatch],
  )

  const handleLastNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDraftUserLastName(e.target.value))
    },
    [dispatch],
  )

  const handlePhoneChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDraftUserPhone(e.target.value))
    },
    [dispatch],
  )

  const handleChoosePicture = useCallback(
    (picture: string | undefined) => {
      dispatch(setDraftUserPicture(picture))
    },
    [dispatch],
  )

  return (
    <div className={styles.settingsContent}>
      <SelectPicture
        onChoosePicture={handleChoosePicture}
        picture={draftUserProfile.picture}
        icon={<PersonIcon />}
        info={t("setUserImage")}
      />

      <div className={styles.card}>
        <label htmlFor="firstName" className={styles.cardItemName}>
          {t("name")}
        </label>
        <input
          id="firstName"
          name="firstName"
          className={styles.titleInput}
          autoComplete={"off"}
          type={InputType.TEXT}
          placeholder={t("nameUserRequired")}
          value={draftUserProfile.first_name}
          onChange={handleFirstNameChange}
        />

        <label htmlFor="lastName" className={styles.cardItemName}>
          {t("surname")}
        </label>
        <input
          id="lastName"
          name="lastName"
          className={styles.titleInput}
          type={InputType.TEXT}
          autoComplete={"off"}
          placeholder={t("lastNameRequired")}
          value={draftUserProfile.last_name}
          onChange={handleLastNameChange}
        />

        <div className={styles.cardItemName}>{t("email")}</div>
        <div className={styles.titleInput}>{draftUserProfile.email}</div>

        <label htmlFor="phoneNumber" className={styles.cardItemName}>
          {t("editPhone")}
        </label>
        <input
          id="phoneNumber"
          name="phoneNumber"
          className={styles.titleInput}
          type={InputType.TEXT}
          placeholder={t("editPhone")}
          value={draftUserProfile.phone_number}
          onChange={handlePhoneChange}
          inputMode={"tel"}
        />
      </div>
    </div>
  )
}

export default UserProfileEditor
