import React from "react"

const InseminationCountIcon: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="35 840 30 30"
    >
      <g data-name="Group 4358">
        <path
          d="M42 840h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H42a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="#1e1b1b"
          fillRule="evenodd"
          data-name="Rectangle 1958"
        />
        <g data-name="noun-in-vitro-6631472">
          <g data-name="Path 1629">
            <path
              d="M58.98 845.883a1.812 1.812 0 0 0-2.554 0l-.811.808-.272-.27a.712.712 0 0 0-1.004 0l-1.185 1.181a.708.708 0 0 0 0 1.002l.272.272-5.95 5.935a1.073 1.073 0 0 0-.204 1.234l-.57.57c-.42.418-.42 1.098 0 1.517.42.42 1.1.42 1.521 0l.571-.57a1.08 1.08 0 0 0 1.237-.203l5.95-5.935.273.272a.713.713 0 0 0 1.005 0l1.184-1.181a.708.708 0 0 0 0-1.003l-.273-.273.81-.808a1.8 1.8 0 0 0 0-2.548Zm-9.437 10.989a.387.387 0 0 1-.545 0 .356.356 0 0 0-.488 0l-.775.773a.386.386 0 0 1-.545 0 .384.384 0 0 1 0-.544l.775-.773a.344.344 0 0 0 0-.487.384.384 0 0 1 0-.543l5.95-5.935 1.578 1.574-5.95 5.935Zm7.227-5.663a.02.02 0 0 1-.028 0l-3.1-3.12 1.213-1.182 3.1 3.12-1.185 1.182Zm1.722-3.265-.81.808-1.579-1.574.81-.808a1.118 1.118 0 0 1 1.58 0c.435.434.435 1.139 0 1.574Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="M58.98 845.883a1.812 1.812 0 0 0-2.554 0l-.811.808-.272-.27a.712.712 0 0 0-1.004 0l-1.185 1.181a.708.708 0 0 0 0 1.002l.272.272-5.95 5.935a1.073 1.073 0 0 0-.204 1.234l-.57.57c-.42.418-.42 1.098 0 1.517.42.42 1.1.42 1.521 0l.571-.57a1.08 1.08 0 0 0 1.237-.203l5.95-5.935.273.272h0a.713.713 0 0 0 1.005 0l1.184-1.181a.708.708 0 0 0 0-1.003l-.273-.273.81-.808a1.8 1.8 0 0 0 0-2.548Zm-9.437 10.989a.387.387 0 0 1-.545 0 .356.356 0 0 0-.488 0l-.775.773a.386.386 0 0 1-.545 0 .384.384 0 0 1 0-.544l.775-.773a.344.344 0 0 0 0-.487.384.384 0 0 1 0-.543l5.95-5.935 1.578 1.574-5.95 5.935Zm7.227-5.663a.02.02 0 0 1-.028 0h0l-3.1-3.12 1.213-1.182 3.1 3.12-1.185 1.182Zm1.722-3.265-.81.808-1.579-1.574.81-.808a1.118 1.118 0 0 1 1.58 0c.435.434.435 1.139 0 1.574Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".2"
              stroke="#fff"
              fill="transparent"
            />
          </g>
          <g data-name="Path 1630">
            <path
              d="M50.7 857.748a.345.345 0 0 0-.27.407 4.829 4.829 0 0 1-4.735 5.8 4.829 4.829 0 0 1-4.834-4.822 4.829 4.829 0 0 1 4.835-4.822c.331 0 .663.034.988.1a.345.345 0 1 0 .137-.676 5.699 5.699 0 0 0-1.125-.113c-3.052 0-5.526 2.467-5.526 5.511s2.474 5.511 5.526 5.512c3.051 0 5.525-2.468 5.525-5.512 0-.375-.038-.749-.114-1.116a.346.346 0 0 0-.407-.269Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="M50.7 857.748a.345.345 0 0 0-.27.407 4.829 4.829 0 0 1-4.735 5.8 4.829 4.829 0 0 1-4.834-4.822 4.829 4.829 0 0 1 4.835-4.822c.331 0 .663.034.988.1a.345.345 0 1 0 .137-.676 5.699 5.699 0 0 0-1.125-.113c-3.052 0-5.526 2.467-5.526 5.511s2.474 5.511 5.526 5.512c3.051 0 5.525-2.468 5.525-5.512 0-.375-.038-.749-.114-1.116a.346.346 0 0 0-.407-.269Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".2"
              stroke="#fff"
              fill="transparent"
            />
          </g>
          <g data-name="Path 1631">
            <path
              d="M42.933 856.992c-.572 0-1.036.462-1.036 1.033 0 .57.464 1.033 1.036 1.033.572 0 1.036-.462 1.036-1.033 0-.57-.464-1.033-1.036-1.033Zm0 1.378a.345.345 0 1 1-.001-.69.345.345 0 0 1 0 .69Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="M42.933 856.992c-.572 0-1.036.462-1.036 1.033 0 .57.464 1.033 1.036 1.033.572 0 1.036-.462 1.036-1.033 0-.57-.464-1.033-1.036-1.033Zm0 1.378a.345.345 0 1 1-.001-.69.345.345 0 0 1 0 .69Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".2"
              stroke="#fff"
              fill="transparent"
            />
          </g>
          <g data-name="Path 1632">
            <path
              d="M44.954 862.503a1.38 1.38 0 0 0 1.382-1.378 1.38 1.38 0 0 0-1.382-1.378 1.38 1.38 0 0 0-1.381 1.378c0 .76.619 1.377 1.381 1.378Zm0-2.067a.69.69 0 1 1 .002 1.38.69.69 0 0 1-.002-1.38Z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="M44.954 862.503a1.38 1.38 0 0 0 1.382-1.378 1.38 1.38 0 0 0-1.382-1.378 1.38 1.38 0 0 0-1.381 1.378c0 .76.619 1.377 1.381 1.378Zm0-2.067a.69.69 0 1 1 .002 1.38.69.69 0 0 1-.002-1.38Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".2"
              stroke="#fff"
              fill="transparent"
            />
          </g>
          <g data-name="Ellipse 501">
            <path
              d="M47.823 860.411a.518.517 0 1 1 0 .1z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="M47.823 860.411a.518.517 0 1 1 0 .1z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".2"
              stroke="#fff"
              fill="transparent"
            />
          </g>
          <g data-name="Ellipse 502">
            <path
              d="M44.1 855.792a.518.517 0 1 1 0 .1z"
              fill="#fff"
              fillRule="evenodd"
            />
            <path
              d="M44.1 855.792a.518.517 0 1 1 0 .1z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".2"
              stroke="#fff"
              fill="transparent"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default InseminationCountIcon
