import React from "react"

const GoatHeadIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <div
    style={{
      height: "30px",
      width: "30px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "var(--notification-yellow)",
      borderRadius: "5px",
    }}
  >
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      viewBox="0 0 28.3 30.3"
    >
      <g id="goat" transform="translate(-5.99 -3.85)">
        <path
          id="Path_1794"
          data-name="Path 1794"
          d="M28.968,56.122A.536.536,0,0,1,28.625,56l-1.007-.841a7.934,7.934,0,0,1-2.095-2.738.538.538,0,0,1,.638-.75,3.67,3.67,0,0,0,1.071.161h3.461a3.659,3.659,0,0,0,1.071-.161.536.536,0,0,1,.637.745,8,8,0,0,1-2.089,2.738l-1.007.841A.536.536,0,0,1,28.968,56.122Zm-1.945-3.214a6.531,6.531,0,0,0,1.28,1.43l.664.536.664-.536a6.52,6.52,0,0,0,1.275-1.43H27.023Z"
          transform="translate(-8.828 -22.122)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.3"
        />
        <path
          id="Path_1795"
          data-name="Path 1795"
          d="M26.944,37.75H23.42a5.059,5.059,0,0,1-1.413-.2,4.87,4.87,0,0,1-3.246-3.091L17.305,30.17A5.834,5.834,0,0,1,17,28.311V24.893a5.8,5.8,0,0,1,1.8-4.205c.125-.118.245-.23.376-.332a.146.146,0,0,1,.044-.037,6.015,6.015,0,0,1,3.18-1.291c.2,0,.4-.027.6-.027h4.364a4.377,4.377,0,0,1,.595.027,5.818,5.818,0,0,1,.622.1,3.665,3.665,0,0,1,.387.091l.087.027a2.475,2.475,0,0,1,.338.107l.164.059a2.906,2.906,0,0,1,.327.145,4.127,4.127,0,0,1,.545.268,2.045,2.045,0,0,1,.273.171,4.4,4.4,0,0,1,.464.332l.038.032c.131.1.251.214.371.327a5.8,5.8,0,0,1,1.789,4.211v3.418a5.834,5.834,0,0,1-.305,1.864l-1.446,4.248A4.9,4.9,0,0,1,26.944,37.75ZM19.853,21.213l-.267.241a4.78,4.78,0,0,0-1.495,3.439v3.418a4.638,4.638,0,0,0,.251,1.521l1.446,4.248a3.844,3.844,0,0,0,3.627,2.6h3.524a3.854,3.854,0,0,0,3.622-2.566l1.456-4.286a4.638,4.638,0,0,0,.256-1.516V24.893A4.779,4.779,0,0,0,30.8,21.448l-.262-.236-.055-.038a4.027,4.027,0,0,0-å.4-.295,2.371,2.371,0,0,0-.2-.129,4.3,4.3,0,0,0-.469-.236,2.547,2.547,0,0,0-.24-.107l-.065-.027h-.049c-.115-.043-.2-.07-.3-.1l-.082-.027-.305-.07a4.139,4.139,0,0,0-.507-.075q-.247-.034-.5-.043H23q-.249,0-.5.027a4.83,4.83,0,0,0-2.629,1.071Z"
          transform="translate(-5.042 -6.964)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.3"
        />
        <path
          id="Path_1796"
          data-name="Path 1796"
          d="M21.684,28.043l-1.209-.638a1.35,1.35,0,0,1-.613-.686,1.415,1.415,0,0,1-.092-.493,1.315,1.315,0,0,1,1.3-1.334h1.746a1.338,1.338,0,0,1,.6,2.507l-1.22.643A1.053,1.053,0,0,1,21.684,28.043Zm-.618-2.079a.242.242,0,0,0-.212.263.27.27,0,0,0,0,.107.285.285,0,0,0,.119.134l.944.5.955-.5a.251.251,0,0,0,.153-.356.265.265,0,0,0-.186-.143Zm6.839-11.325a.546.546,0,0,1-.374-.15l-.266-.241-.054-.038a4.006,4.006,0,0,0-.4-.295,2.356,2.356,0,0,0-.2-.129,4.261,4.261,0,0,0-.466-.236,2.526,2.526,0,0,0-.239-.107l-.065-.027H25.79c-.114-.043-.2-.07-.3-.1l-.081-.027-.3-.07a4.093,4.093,0,0,0-.5-.075q-.246-.034-.494-.043h-.477a.545.545,0,0,1-.407-.177.531.531,0,0,1-.13-.423A9.721,9.721,0,0,1,32.787,4a1.079,1.079,0,0,1,1.085,1.034,1.033,1.033,0,0,1-.586.921,8.544,8.544,0,0,0-4.778,6.68,8.737,8.737,0,0,0-.06,1.007V14.1a.536.536,0,0,1-.331.493A.548.548,0,0,1,27.905,14.639Zm-1.627-2.2a2.882,2.882,0,0,1,.325.145,4.093,4.093,0,0,1,.542.268,2.174,2.174,0,0,1,.244.15,3.781,3.781,0,0,1,.049-.5,9.581,9.581,0,0,1,5.212-7.436,8.649,8.649,0,0,0-8.39,6.964l.439.027a5.755,5.755,0,0,1,.618.1,3.628,3.628,0,0,1,.385.091l.087.027a2.451,2.451,0,0,1,.336.107Zm-10.305,2.2a.548.548,0,0,1-.212-.043.536.536,0,0,1-.331-.493v-.461a8.738,8.738,0,0,0-.06-1.007A8.533,8.533,0,0,0,10.6,5.961,1.03,1.03,0,0,1,10.042,4.8,1.044,1.044,0,0,1,11.054,4a9.722,9.722,0,0,1,9.73,8.507.531.531,0,0,1-.13.423.545.545,0,0,1-.407.177H19.77q-.247,0-.494.027a4.788,4.788,0,0,0-2.614,1.071l-.049.032-.266.241A.546.546,0,0,1,15.973,14.639ZM11.227,5.071a9.581,9.581,0,0,1,5.218,7.436c0,.166.038.327.049.5a5.932,5.932,0,0,1,2.679-.943l.445-.027A8.649,8.649,0,0,0,11.227,5.071Z"
          transform="translate(-1.799)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.3"
        />
        <path
          id="Path_1797"
          data-name="Path 1797"
          d="M20.14,38.712a.539.539,0,0,1-.541-.536V35.5a.541.541,0,0,1,1.083,0v2.679A.539.539,0,0,1,20.14,38.712ZM9.746,30.81a4.9,4.9,0,0,1-3.449-1.4.559.559,0,0,1-.157-.38.533.533,0,0,1,.157-.38l7.5-7.42a.546.546,0,0,1,.59-.112.536.536,0,0,1,.336.493v.423a.533.533,0,0,1-.162.38A4.8,4.8,0,0,0,13.1,25.855v3.391a.533.533,0,0,1-.19.407A4.9,4.9,0,0,1,9.746,30.81ZM7.477,29a3.822,3.822,0,0,0,4.542,0V25.855A5.7,5.7,0,0,1,12.23,24.3ZM30.534,30.81a4.9,4.9,0,0,1-3.167-1.157.533.533,0,0,1-.189-.407V25.855a4.8,4.8,0,0,0-1.462-3.445.533.533,0,0,1-.162-.38v-.423a.536.536,0,0,1,.336-.493.546.546,0,0,1,.59.113l7.5,7.42a.533.533,0,0,1,.157.38.559.559,0,0,1-.157.38,4.9,4.9,0,0,1-3.449,1.4Zm-2.274-1.821a3.822,3.822,0,0,0,4.542,0L28.049,24.3a5.7,5.7,0,0,1,.211,1.554Z"
          transform="translate(0 -7.926)"
          fill="#fff"
          stroke="#fff"
          strokeWidth="0.3"
        />
      </g>
    </svg>
  </div>
)

export default GoatHeadIcon
