import React from "react"
import { useTranslation } from "react-i18next"
import { TaskFilterTab } from "@/components/filter-tab/task-filter-tab/task-filter-tab.tsx"
import styles from "@/components/shadcn/drawer/custom-drawer.module.css"
import DrawerTopBar from "@/components/shadcn/drawer/drawer-top-bar.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import { TasksListCurrent } from "@/features/tasks/components/tasks-lists/tasks-list-current.tsx"
import { TasksListDone } from "@/features/tasks/components/tasks-lists/tasks-list-done.tsx"
import useFilteredTasks from "@/features/tasks/hooks/use-filter-tasks.ts"

interface CombinedComponentProps {
  currentValue: string[]
  onSaveClick: () => void
  TriggerComponent: React.FC<TriggerComponentProps>
}

interface TriggerComponentProps {
  currentValue: string[]
  onClick: () => void
}

const DrawerTasks: React.FC<CombinedComponentProps> = ({
  onSaveClick,
  currentValue,
  TriggerComponent,
}) => {
  const { t } = useTranslation()
  const {
    searchQuery,
    setSearchQuery,
    filteredTasks,
    selections,
    setSelections,
  } = useFilteredTasks()

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <TriggerComponent currentValue={currentValue} onClick={() => {}} />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerTopBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={t("searchTask")}
          onSaveClick={onSaveClick}
        />
        <div className={styles["filter-tab"]}>
          <TaskFilterTab isNested={true} onFilterChange={setSelections} />
        </div>
        <div className={styles["slide-up-content-wrapper"]}>
          <div className={styles["slide-up-content-with-padding"]}>
            {selections.is_done ? (
              <TasksListDone filteredTasks={filteredTasks} />
            ) : (
              <TasksListCurrent filteredTasks={filteredTasks} />
            )}
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerTasks
