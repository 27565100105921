import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Animal } from "@/features/farm"

const RUMINANTS_BASE_URL = "ruminants/"

const transformAnimalData = (input: Partial<Animal>) => {
  const { ruminant_fields, ...animal } = input

  return {
    animal,
    ...ruminant_fields,
  }
}

export const ruminantsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRuminantById: builder.query<Animal, number>({
      query: (id) => RUMINANTS_BASE_URL + `${id}/`,
      providesTags: (_result, _error, id) => [{ type: TagType.Animals, id }],
    }),
    postRuminant: builder.mutation<Animal, Partial<Animal>>({
      query: (newRuminant) => ({
        url: RUMINANTS_BASE_URL,
        method: "POST",
        body: transformAnimalData(newRuminant),
      }),
      invalidatesTags: [{ type: TagType.Animals, id: "LIST" }],
    }),
    updateRuminant: builder.mutation<Animal, Partial<Animal>>({
      query: (updatedRuminant) => ({
        url: RUMINANTS_BASE_URL + `${updatedRuminant.id}/`,
        method: "PATCH",
        body: transformAnimalData(updatedRuminant),
      }),
      invalidatesTags: (_result, _error, { id }) => {
        return [{ type: TagType.Animals, id }]
      },
    }),
  }),
})

export const {
  useGetRuminantByIdQuery,
  usePostRuminantMutation,
  useUpdateRuminantMutation,
} = ruminantsApi
