import Hotjar from "@hotjar/browser"
import * as Sentry from "@sentry/react"
import i18n from "i18next"
import { createRoot } from "react-dom/client"
import { initReactI18next } from "react-i18next"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App.tsx"
import "./style/tailwind.css"
import "./style/index.css"
import "./style/theme.css"
import "./style/animations.css"
import { EN_TRANSLATIONS } from "./locales/en/translation.ts"
import { RO_TRANSLATIONS } from "./locales/ro/translation.ts"
import { store, persistor } from "./redux/store.ts"
import ErrorBoundary from "@/components/error-boundary/error-boundary.tsx"

Hotjar.init(5039634, 6)

const element = document.getElementById("root")

if (!element) {
  throw new Error("Root element not present")
}

const root = createRoot(element)

i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: EN_TRANSLATIONS,
      ro: RO_TRANSLATIONS,
    },
    lng: "ro",
    fallbackLng: "ro",
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    root.render(
      <Provider store={store}>
        <Sentry.ErrorBoundary fallback={<ErrorBoundary />}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Sentry.ErrorBoundary>
      </Provider>,
    )
  })
