import { MENU_NOTES_COLOR, WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const AnimalStatusDryIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 40}
      height={props.size || 40}
      viewBox="2647.923 -827.271 40 40"
    >
      <g data-name="CompositeLayer">
        <path
          d="M2647.923-807.321a20 20 0 1 1 0 .1z"
          fill={props.secondary_color ? props.secondary_color : WHITE}
          fillRule="evenodd"
          data-name="Ellipse Calduri"
        />
        <g data-name="Group 4243">
          <g data-name="Group 4242">
            <g data-name="Icons_READY-03">
              <path
                d="M2667.93-806.066c-3.748 0 4.429 0 0 0Zm7.56-2.084 1.307 2.497c.337.643.087 1.44-.555 1.776a1.318 1.318 0 0 1-1.776-.555l-1.07-2.044a11.496 11.496 0 0 0 2.093-1.674Zm-8.876 3.171a10.264 10.264 0 0 0 2.632 0v3.008c0 .725-.59 1.316-1.316 1.316-.726 0-1.316-.59-1.316-4.324Zm-4.15-1.497-1.07 2.043a1.317 1.317 0 0 1-1.776.555c-.642-.336-.891-1.132.753-4.273.197.198 1.58 1.354 2.094 1.675Zm-5.156-6.563c-1.044.73 1.288 2.802 2.226 3.958l-1.51 2.884a2.49 2.49 0 0 0 1.05 3.359 2.49 2.49 0 0 0 3.358-1.05l1.06-2.022c.63.303 1.281.54 1.95.709v3.23a2.491 2.491 0 0 0 2.488 2.488 2.49 2.49 0 0 0 2.489-2.488v-3.23c.668-.17 1.32-.406 1.95-.71l1.058 2.022a2.49 2.49 0 0 0 3.359 1.05 2.49 2.49 0 0 0 1.05-3.358l-1.51-2.884c.939-1.156 3.33-3.468 2.227-3.958 0 0-5.312 6.973-10.623 6.973-5.311 0-10.622-6.973-10.622-6.973Z"
                fill={
                  props.primary_color ? props.primary_color : MENU_NOTES_COLOR
                }
                fillRule="evenodd"
              />
              <path
                d="M2667.93-806.066c-3.748 0 4.429 0 0 0Zm7.56-2.084 1.307 2.497c.337.643.087 1.44-.555 1.776a1.318 1.318 0 0 1-1.776-.555l-1.07-2.044a11.496 11.496 0 0 0 2.093-1.674Zm-8.876 3.171a10.264 10.264 0 0 0 2.632 0v3.008c0 .725-.59 1.316-1.316 1.316-.726 0-1.316-.59-1.316-4.324Zm-4.15-1.497-1.07 2.043a1.317 1.317 0 0 1-1.776.555c-.642-.336-.891-1.132.753-4.273.197.198 1.58 1.354 2.094 1.675Zm-5.156-6.563c-1.044.73 1.288 2.802 2.226 3.958l-1.51 2.884a2.49 2.49 0 0 0 1.05 3.359h0a2.49 2.49 0 0 0 3.358-1.05l1.06-2.022c.63.303 1.281.54 1.95.709v3.23a2.491 2.491 0 0 0 2.488 2.488 2.49 2.49 0 0 0 2.489-2.488v-3.23c.668-.17 1.32-.406 1.95-.71l1.058 2.022a2.49 2.49 0 0 0 3.359 1.05h0a2.49 2.49 0 0 0 1.05-3.358l-1.51-2.884c.939-1.156 3.33-3.468 2.227-3.958 0 0-5.312 6.973-10.623 6.973-5.311 0-10.622-6.973-10.622-6.973Z"
                strokeLinejoin="round"
                strokeLinecap="round"
                strokeWidth=".3"
                stroke={
                  props.primary_color ? props.primary_color : MENU_NOTES_COLOR
                }
                fill="transparent"
              />
            </g>
            <path
              d="M2656.171-807.586a11.65 11.65 0 1 1 0 .093z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="1.5844"
              stroke={
                props.primary_color ? props.primary_color : MENU_NOTES_COLOR
              }
              fill="transparent"
              data-name="Ellipse 500"
            />
          </g>
          <path
            d="m2659.821-798.49 16-18"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.7"
            stroke={
              props.primary_color ? props.primary_color : MENU_NOTES_COLOR
            }
            fill="transparent"
            data-name="Line 209"
          />
        </g>
      </g>
    </svg>
  )
}

export default AnimalStatusDryIcon
