import { useRef } from "react"
import NotesWidget from "../../../notes/components/notes-widget/notes-widget"
import MobileHomeHeader from "../../components/mobile-home-header/mobile-home-header"
import NotificationsWidget from "../../components/notifications-widget/notifications-widget"
import styles from "./home.module.css"
import { useGetAllAnimalEventTypesQuery } from "@/features/animal-events/api/event-types-api.ts"
import FinanceWidget from "@/features/finance/finance-widget/finance-widget.tsx"
import AnimalStatusesWidget from "@/features/home/components/animal-statuses-widget/animal-statuses-widget.tsx"
import AssistantWidget from "@/features/home/components/assistant-widget/assistant-widget.tsx"
import FarmSummaryWidget from "@/features/home/components/farm-summary-widget/farm-summary-widget.tsx"
import GestationPeriodCalendarWidget from "@/features/home/components/gestation-period-calendar-widget/gestation-period-calendar-widget.tsx"
import TasksWidget from "@/features/home/components/tasks-widget/tasks-widget.tsx"
import WeatherWidget from "@/features/home/components/weather-widget/weather-widget.tsx"
import { useGetNotificationsQuery } from "@/features/notifications/api/notifications-api.ts"
import { useGetMyFarmQuery } from "@/features/settings-account/api/account-api.ts"
import useFarmPermissions from "@/features/settings-account/hooks/use-farm-permissions.tsx"
import useIsDesktop from "@/hooks/use-is-desktop"
import { useScrollRestoration } from "@/hooks/use-scroll-restoration.ts"

export const Home = () => {
  const isDesktop = useIsDesktop()
  const MAX_NOTIFICATIONS_TO_DISPLAY = 6
  const { data: notifications = [] } = useGetNotificationsQuery()
  const { permissions } = useFarmPermissions()
  useGetMyFarmQuery()
  useGetAllAnimalEventTypesQuery()
  const unreadNotifications = notifications
    .filter((notification) => {
      return notification.read_at === null
    })
    .slice(0, MAX_NOTIFICATIONS_TO_DISPLAY)

  const divRef = useRef<HTMLDivElement>(null)
  useScrollRestoration(divRef)

  return (
    <div className={styles["dashboard-content"]} ref={divRef}>
      {!isDesktop && <MobileHomeHeader />}
      <div className={styles["flex-large-height"]}>
        <div className={styles["flex-large-width"]}>
          <AnimalStatusesWidget />
          <WeatherWidget />
        </div>
        {unreadNotifications.length > 0 && (
          <div className={styles["flex-small-width"]}>
            <NotificationsWidget notifications={unreadNotifications} />
          </div>
        )}
      </div>

      <div className={styles["flex-large-height"]}>
        <div className={styles["flex-small-width"]}>
          <NotesWidget maxNotesNumberToDisplay={6} />
        </div>
        <div className={styles["flex-large-width"]}>
          <TasksWidget maxTasksNumberToDisplay={6} />
        </div>
      </div>

      <div className={styles["flex-small-height"]}>
        <div className={styles["flex-large-width"]}>
          <FarmSummaryWidget />
        </div>
        {permissions?.is_assistant_enabled && (
          <div className={styles["flex-small-width"]}>
            <AssistantWidget />
          </div>
        )}
        {permissions?.is_gestational_calendar_enabled && (
          <div className={styles["flex-small-width"]}>
            <GestationPeriodCalendarWidget />
          </div>
        )}
        {permissions?.is_finance_journal_enabled && (
          <div className={styles["flex-small-width"]}>
            <FinanceWidget />
          </div>
        )}
      </div>
    </div>
  )
}
