import { type NoteDraft } from "@/features/notes"
import {
  dataURLtoFile,
  extractTextAndImages,
} from "@/utils/helpers/object-utils.ts"

export const shareNote = async (noteToEdit: NoteDraft) => {
  const { text, images } = extractTextAndImages(noteToEdit?.content || "")
  const imageFiles = images.map((src, index) =>
    dataURLtoFile(src, `image${index}.png`),
  )

  if (navigator.canShare && navigator.canShare({ files: imageFiles })) {
    try {
      await navigator.share({
        title: noteToEdit?.title,
        text: text,
        files: imageFiles,
      })
      alert("Content shared successfully")
    } catch (err) {
      alert("Error sharing the content")
    }
  } else {
    alert("Web Share API is not supported in your browser.")
  }
}

export function cleanContent(content: string): string {
  const tempDiv = document.createElement("div")
  tempDiv.innerHTML = content

  const paragraphs = tempDiv.querySelectorAll("p")

  paragraphs.forEach((paragraph) => {
    if (
      !paragraph.textContent?.trim() &&
      !paragraph.querySelector("img, video, audio")
    ) {
      paragraph.remove()
    }
  })

  return tempDiv.innerHTML
}

export const checkNoteChanges = (note1: NoteDraft, note2: NoteDraft) => {
  const hasTitleChanges = note1.title !== cleanContent(note2.title)
  const hasContentChanges = note1.content !== cleanContent(note2.content)
  const hasTaskChanges = note1.tasks !== note2.tasks
  const hasAnimalChanges = note1.animals !== note2.animals
  return (
    hasTitleChanges || hasContentChanges || hasTaskChanges || hasAnimalChanges
  )
}
