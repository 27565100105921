import React, { useState, useMemo, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import OptionCheckboxSelector from "@/components/option-checkbox-selector/option-checkbox-selector.tsx"
import styles from "@/components/shadcn/drawer/custom-drawer.module.css"
import DrawerTopBar from "@/components/shadcn/drawer/drawer-top-bar.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import { type AnimalDraft } from "@/features/farm"
import { useGetBreedsBySpeciesIdQuery } from "@/features/farm/api/breeds-api.ts"
import DrawerBreedsTrigger from "@/features/farm/components/drawer-breeds/drawer-breeds-trigger.tsx"
import { type Option } from "@/utils/types/misc-types.ts"

interface CombinedComponentProps {
  currentValue: AnimalDraft
  onSaveClick: (breed: string) => void
}

const DrawerBreeds: React.FC<CombinedComponentProps> = ({
  onSaveClick,
  currentValue,
}) => {
  const { data: speciesBreedsList } = useGetBreedsBySpeciesIdQuery(
    currentValue.species,
    {
      skip: !currentValue.species,
    },
  )

  const { t } = useTranslation()
  const [searchQuery, setSearchQuery] = useState<string>("")

  const filteredOptions = useMemo(() => {
    if (!speciesBreedsList) return []
    return speciesBreedsList
      .filter((option) =>
        option.label.toLowerCase().includes(searchQuery.toLowerCase()),
      )
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [searchQuery, speciesBreedsList])

  const isSelected = useCallback(
    (option: Option) => option.value === currentValue.breed,
    [currentValue],
  )

  const handleSelection = useCallback(
    (option: Option) => {
      onSaveClick(option.value)
    },
    [onSaveClick],
  )

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <DrawerBreedsTrigger currentValue={currentValue} onClick={() => {}} />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerTopBar
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={t("searchBreed")}
        />
        {speciesBreedsList ? (
          <div className={styles["slide-up-content-wrapper"]}>
            <div className={styles["slide-up-content"]}>
              {filteredOptions.map((option: Option) => (
                <OptionCheckboxSelector
                  key={option.value}
                  option={option}
                  selected={isSelected(option)}
                  onClick={handleSelection}
                />
              ))}
            </div>
          </div>
        ) : (
          <LoadingAnimation />
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerBreeds
