import { useTranslation } from "react-i18next"
import styles from "../settings-routes.module.css"
import InfoIcon from "@/assets/icons/settings/info-icon.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"

const SettingsTutorial = () => {
  const { t } = useTranslation()

  return (
    <>
      <SettingsTopBar title={t("tutorials")} />
      <div className={styles.settingsContent}>
        <div className={styles.imageCircleCenter}>
          <InfoIcon />
        </div>

        <div className={styles.card}>
          <div className={styles.cardTitleBrown}>{t("tutorials")}</div>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius
            consequat dictum iaculis ultricies eros. Nulla id nulla porta lacus,
            morbi lorem.
          </div>

          <div className={styles.video}>
            <iframe
              width="100%"
              height="auto"
              src="https://www.youtube.com/embed/F0lowv3DyfI?si=vHQFMw7dPglUbWIT"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          </div>

          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius
            consequat dictum:
          </div>

          <div>
            Et varius arcu, ipsum integer porttitor. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Varius consequat dictum. Fringilla
            viverra id id sit viverra nec, accumsan. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Varius consequat dictum Lorem ipsum
            dolor sit amet, consectetur adipiscing elit. Varius consequat dictum
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsTutorial
