/**
 * The gestation calendar event entry
 *
 * @property {GestationalCalendarEvent} event - the event that has to be done
 * @property {string} event_date - the date when the event has to be done, absolute date computed from the insemination date
 */
export interface CalendarActivity {
  event: GestationalCalendarEvent
  event_date: string
  id: string
  animal_event: string
}

/**
 *  The gestation calendar event
 *
 *  @property {string} animal_event_type - if of the type of the event
 *  @property {string} interval_duration - the duration of the event
 *      if null there is a fixed time, if not there is an interval in which the event can happen*
 *  @property {boolean} is_calving_relative - if the event is relative to the calving date
 *  @property {string} target - the target of the event
 *  @property {string} time_offset - the offset of the event*
 */
export interface GestationalCalendarEvent {
  animal_event_type: string | null
  created_at: string
  description: string
  id: string
  interval_duration: number | null
  is_calving_relative: boolean
  name: string
  species: string
  target: string
  time_offset: number
  updated_at: string
}

/**
 * The gestation calendar
 *
 * @property {string} animal - the animal the calendar is for
 * @property {string} insemination_date - the date of insemination
 * @property {string} calving_date - the estimate date of calving
 * @property {CalendarActivity[]} entries - the calendar entries that represent the activities that have to be done during the gestation period
 */
export interface GestationCalendar {
  id: string
  animal: string
  insemination_date: string
  calving_date: string
  entries: CalendarActivity[]
  created_at: string
  updated_at: string
}

/**
 * Request body for the gestation calendar
 */
export interface GestationCalendarRequest {
  animal: string
  insemination_date: string
}

/**
 * DUE - the event is due in the future
 * CURRENT - the event is due today
 * DONE - the event is overdue
 *
 * used for getting the style for the accordion
 * computed based of the due date of the event
 */
export enum CalendarActivityState {
  DUE = "DUE",
  CURRENT = "CURRENT",
  DONE = "DONE",
}
