import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const UndoIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="284 35.513 22 22"
    >
      <g data-name="redo-svgrepo-com">
        <path
          d="M284 41.047h15.812c.255 0 6.324 0 6.324 6.325 0 7.115-5.854 7.115-6.324 7.115h-12.65"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#414141"
          fill="transparent"
          data-name="Path 876"
        />
        <path
          d="M289.534 46.581 284 41.047l5.534-5.534"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#414141"
          fill="transparent"
          data-name="Path 877"
        />
      </g>
    </svg>
  )
}

export default UndoIcon
