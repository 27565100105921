import { MENU_NOTES_COLOR } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuNotesIconMobile: React.FC<GeneralSvgProps> = (props) => {
  const color = props.color || MENU_NOTES_COLOR

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="141.092 291.829 60 60"
    >
      <g data-name="Group 4429">
        <path
          d="M141.092 321.779a30 30 0 1 1 0 .1z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Ellipse 508"
        />
        <g data-name="Group 4427">
          <g data-name="Path 1406">
            <path
              d="M179.512 332.138a6.54 6.54 0 0 1-1.883 2.758c-1.29 1.126-3.278 1.623-7.255 2.616-3.977.993-5.965 1.49-7.686 1.116a7.186 7.186 0 0 1-3.546-1.909c-1.208-1.202-1.741-3.055-2.807-6.762l-.913-3.176c-1.065-3.707-1.598-5.56-1.197-7.164a6.488 6.488 0 0 1 2.048-3.306c1.29-1.126 3.278-1.623 7.255-2.616.752-.188 1.433-.358 2.055-.508"
              fill="rgba(0,0,0,0 )"
              fillRule="evenodd"
            />
            <path
              d="M179.512 332.138a6.54 6.54 0 0 1-1.883 2.758c-1.29 1.126-3.278 1.623-7.255 2.616-3.977.993-5.965 1.49-7.686 1.116a7.186 7.186 0 0 1-3.546-1.909c-1.208-1.202-1.741-3.055-2.807-6.762l-.913-3.176c-1.065-3.707-1.598-5.56-1.197-7.164a6.488 6.488 0 0 1 2.048-3.306c1.29-1.126 3.278-1.623 7.255-2.616.752-.188 1.433-.358 2.055-.508"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="2"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1407">
            <path
              d="m171.333 319.03 8.517 2.127"
              fill="rgba(0,0,0,0 )"
              fillRule="evenodd"
            />
            <path
              d="m171.333 319.03 8.517 2.127"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="2"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1420">
            <path
              d="m173.321 313.472 8.517 2.127"
              fill="rgba(0,0,0,0 )"
              fillRule="evenodd"
            />
            <path
              d="m173.321 313.472 8.517 2.127"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="2"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1408">
            <path
              d="m169.963 323.793 5.11 1.277"
              fill="rgba(0,0,0,0 )"
              fillRule="evenodd"
            />
            <path
              d="m169.963 323.793 5.11 1.277"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="2"
              stroke={color}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1405">
            <path
              d="m186.384 323.381.913-3.175c1.065-3.707 1.598-5.56 1.197-7.165a6.488 6.488 0 0 0-2.048-3.306c-1.29-1.126-3.278-1.622-7.254-2.616-3.977-.993-5.966-1.49-7.686-1.116a7.186 7.186 0 0 0-3.547 1.91c-1.034 1.028-1.573 2.535-2.376 5.274-.135.46-.277.954-.43 1.487l-.913 3.176c-1.066 3.707-1.598 5.56-1.197 7.165a6.488 6.488 0 0 0 2.048 3.306c1.29 1.126 3.278 1.622 7.254 2.616 3.585.895 5.554 1.387 7.167 1.201.177-.02.35-.048.52-.085a7.186 7.186 0 0 0 3.546-1.909c1.208-1.202 1.74-3.056 2.806-6.763Z"
              fill="rgba(0,0,0,0 )"
              fillRule="evenodd"
            />
            <path
              d="m186.384 323.381.913-3.175c1.065-3.707 1.598-5.56 1.197-7.165a6.488 6.488 0 0 0-2.048-3.306c-1.29-1.126-3.278-1.622-7.254-2.616-3.977-.993-5.966-1.49-7.686-1.116a7.186 7.186 0 0 0-3.547 1.91c-1.034 1.028-1.573 2.535-2.376 5.274-.135.46-.277.954-.43 1.487h0l-.913 3.176c-1.066 3.707-1.598 5.56-1.197 7.165a6.488 6.488 0 0 0 2.048 3.306c1.29 1.126 3.278 1.622 7.254 2.616h0c3.585.895 5.554 1.387 7.167 1.201.177-.02.35-.048.52-.085a7.186 7.186 0 0 0 3.546-1.909c1.208-1.202 1.74-3.056 2.806-6.763Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth="2"
              stroke={color}
              fill="transparent"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MenuNotesIconMobile
