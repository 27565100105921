import isEqual from "lodash.isequal"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import * as Yup from "yup"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"
import { type UserProfileUpdate } from "@/features/settings-account"
import { useUpdateProfileMutation } from "@/features/settings-account/api/account-api.ts"
import useGetDraftUserProfile from "@/features/settings-account/hooks/use-get-draft-user-profile.ts"
import { useGoBackToSettingsHandler } from "@/features/settings-account/hooks/use-go-back-to-settings-handler.tsx"
import UserProfileEditor from "@/features/settings-account/routes/user-profile/user-profile-editor.tsx"
import { userProfileSchema } from "@/features/settings-account/routes/user-profile/user-profile-schema.ts"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import CustomErrorToast from "@/utils/toast-config/error-toast.tsx"

const UserProfileWrapper: React.FC = () => {
  const { t } = useTranslation()
  const { handleBackNavigation } = useNavigationFallback()
  const { draftUserProfile, data, isLoading } = useGetDraftUserProfile()
  const [updateProfile] = useUpdateProfileMutation()

  const validateUserProfile = async (
    userProfile: Partial<UserProfileUpdate>,
  ) => {
    try {
      await userProfileSchema.validate(userProfile, { abortEarly: false })
      return true
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        toast.error(<CustomErrorToast errors={error.errors} />)
      }
      return false
    }
  }

  const saveChanges = async () => {
    const userProfileUpdate: Partial<UserProfileUpdate> = {
      first_name: draftUserProfile?.first_name,
      last_name: draftUserProfile?.last_name,
      phone_number: draftUserProfile?.phone_number,
    }

    if (draftUserProfile?.picture !== data?.picture) {
      userProfileUpdate.picture = draftUserProfile?.picture
    }

    if (await validateUserProfile(userProfileUpdate)) {
      toast
        .promise(
          updateProfile({
            id: draftUserProfile.id,
            userProfileUpdate,
          }).unwrap(),
          {
            pending: t("processing"),
            success: t("userProfileUpdated"),
            error: t("errorOccurred"),
          },
        )
        .then(() => {
          handleBackNavigation()
        })
    }
  }

  const hasChanges = !!data && !isEqual(data, draftUserProfile)

  const { handleBackClick } = useGoBackToSettingsHandler(hasChanges)

  return (
    <>
      <SettingsTopBar
        title={t("myProfile")}
        onSaveChanges={() => saveChanges()}
        onBackClick={handleBackClick}
        isModified={hasChanges}
      />
      {isLoading || !data ? <LoadingAnimation /> : <UserProfileEditor />}
    </>
  )
}

export default UserProfileWrapper
