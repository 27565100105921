import { useMemo } from "react"
import { useGetAllAnimalEventsQuery } from "@/features/animal-events/api/animal-events-api"

const useGetAnimalEventsLocation = () => {
  const { data: allEvents } = useGetAllAnimalEventsQuery()

  const { fromLocation, toLocation } = useMemo(() => {
    const fromLocation = new Set<string>()
    const toLocation = new Set<string>()

    allEvents?.forEach((animalEvent) => {
      const fromLoc = animalEvent.event_data?.from_location
      const toLoc = animalEvent.event_data?.to_location

      if (fromLoc) {
        fromLocation.add(fromLoc)
      }
      if (toLoc) {
        toLocation.add(toLoc)
      }
    })

    return { fromLocation, toLocation }
  }, [allEvents])

  return { fromLocation, toLocation }
}

export default useGetAnimalEventsLocation
