import styles from "./scroll-to-bottom.module.css"
import ArrowDown from "@/assets/icons/misc/arrow-down.tsx"

interface ScrollToBottomButtonProps {
  onClick: () => void
  bottom: string
}

const ScrollToBottomButton: React.FC<ScrollToBottomButtonProps> = ({
  onClick,
  bottom,
}) => {
  return (
    <div
      className={styles.scrollToBottomWrapper}
      style={{
        bottom: "calc(" + bottom + " + 20px)",
      }}
    >
      <div className={styles.scrollButton} onClick={onClick}>
        <ArrowDown />
      </div>
    </div>
  )
}

export default ScrollToBottomButton
