import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { Tabs, TabsList, TabsTrigger } from "@/components/shadcn/tabs/tabs.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import {
  useDeleteTransactionCategoryMutation,
  usePostTransactionCategoryMutation,
  useUpdateTransactionCategoryMutation,
} from "@/features/finance/api/transaction-categories-api.ts"
import styles from "@/features/finance/components/add-transaction-category-form/add-transaction-category-form.module.css"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import useGetTransactionCategoryById from "@/features/finance/hooks/use-get-transaction-category-by-id.ts"
import { TRANSACTION_CATEGORY_TYPE } from "@/features/finance/types/finance-constants.ts"
import { type TransactionCategory } from "@/features/finance/types/transaction.ts"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

interface AddTransactionCategoryFormProps {
  onBackClick: () => void
  isOutflow: boolean
  transactionId: string | null
}

const AddTransactionCategoryForm: React.FC<AddTransactionCategoryFormProps> = ({
  onBackClick,
  isOutflow,
  transactionId,
}) => {
  const transactionCategory = useGetTransactionCategoryById(transactionId)

  const initialTransactionCategory: Partial<TransactionCategory> = {
    name: "",
    picture: null,
    is_outflow: isOutflow,
  }

  const { t } = useTranslation()
  const [postTransactionCategory] = usePostTransactionCategoryMutation()
  const [updateTransactionCategory] = useUpdateTransactionCategoryMutation()
  const [deleteTransactionCategory] = useDeleteTransactionCategoryMutation()
  const [newTransactionCategory, setNewTransactionCategory] = useState<
    Partial<TransactionCategory>
  >(transactionCategory || initialTransactionCategory)
  const { openModal } = useModal()

  const diff =
    transactionCategory &&
    getObjectDifference(transactionCategory, newTransactionCategory)

  const currentType = isOutflow
    ? TRANSACTION_CATEGORY_TYPE.EXPENSES
    : TRANSACTION_CATEGORY_TYPE.INCOMES

  const handlePictureChange = (imgFile: string | null) => {
    setNewTransactionCategory({ ...newTransactionCategory, picture: imgFile })
  }

  const handleSaveCategory = () => {
    toast
      .promise(postTransactionCategory(newTransactionCategory).unwrap(), {
        pending: t("savingCategory"),
        success: t("categorySaved"),
        error: t("errorCreatingCategory"),
      })
      .then(() => {
        onBackClick()
      })
  }

  const handleUpdateCategory = () => {
    if (transactionCategory) {
      toast
        .promise(
          updateTransactionCategory({
            ...diff,
            id: transactionCategory.id,
          }).unwrap(),
          {
            pending: t("updatingCategory"),
            success: t("categoryUpdated"),
            error: t("errorUpdatingCategory"),
          },
        )
        .then(() => {
          onBackClick()
        })
    }
  }

  const handleDeleteTransactionCategory = () => {
    if (!transactionCategory) {
      return
    }

    openModal(MODALS.BASE_MODAL, {
      title: t("deleteTransactionCategoryConfirmation"),
      content: t("transactionCategoryWillBeDeleted"),
      onActionClick: () => {
        toast
          .promise(
            deleteTransactionCategory(transactionCategory?.id).unwrap(),
            {
              pending: t("deletingCategory"),
              success: t("categoryDeleted"),
              error: t("errorDeletingCategory"),
            },
          )
          .then(() => {
            onBackClick()
          })
      },
    })
  }

  const handleCategoryMutation = () => {
    if (transactionCategory) {
      handleUpdateCategory()
    } else {
      handleSaveCategory()
    }
  }

  return (
    <div className={styles.drawerFinanceContentWrapper}>
      <GenericTypeDrawerHeader
        title={
          transactionCategory ? t(transactionCategory.name) : t("ADD_CATEGORY")
        }
        onBackClick={onBackClick}
        {...((diff && Object.keys(diff).length > 0) || !transactionCategory
          ? { onSaveClick: handleCategoryMutation }
          : {})}
        closeOnSave={false}
      />
      <div className={styles.formBody}>
        <Tabs defaultValue={currentType}>
          <TabsList className={styles.tabsList}>
            <TabsTrigger
              className={styles.tabsTrigger}
              value={TRANSACTION_CATEGORY_TYPE.EXPENSES}
            >
              <p className={styles.info}>
                {t(TRANSACTION_CATEGORY_TYPE.EXPENSES)}
              </p>
            </TabsTrigger>
            <TabsTrigger
              className={styles.tabsTrigger}
              value={TRANSACTION_CATEGORY_TYPE.INCOMES}
            >
              <p className={styles.info}>
                {t(TRANSACTION_CATEGORY_TYPE.INCOMES)}
              </p>
            </TabsTrigger>
          </TabsList>
        </Tabs>
        <OverflowMenuItem>
          <input
            id="categoryName"
            type="text"
            placeholder={t("categoryName")}
            className={styles.input}
            value={newTransactionCategory.name}
            onChange={(e) =>
              setNewTransactionCategory({
                ...newTransactionCategory,
                name: e.target.value,
              })
            }
            required
            maxLength={100}
          />
          {newTransactionCategory.name === "" && <RequiredIndicator />}
        </OverflowMenuItem>
        <AttachImageFieldBase64
          picture={newTransactionCategory.picture as string}
          onChoosePicture={handlePictureChange}
        />
      </div>
      {transactionCategory && (
        <DeleteButton
          text={t("deleteCategory")}
          onClick={handleDeleteTransactionCategory}
        />
      )}
    </div>
  )
}

export default AddTransactionCategoryForm
