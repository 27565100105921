import isEqual from "lodash.isequal"
import { useLayoutEffect } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import { useAppSelector } from "@/redux/hooks.ts"
import {
  initialTaskState,
  resetTaskDraft,
} from "@/redux/slices/task-draft-slice.ts"

/**
 *  useInitializeTaskDraft
 *  Hook to initialize the task draft
 *  If the taskDraft is not present, it will reset the taskDraft with default values
 *  If the animalId is present, it will reset the taskDraft with the animalId
 *  If the taskDraft is present, it will return the taskDraft
 */
const useInitializeTaskDraft = () => {
  const dispatch = useDispatch()
  const { animalId } = useParams()

  const taskDraft = useAppSelector(
    (state) => state.taskDraftSlice.drafts["new"],
  )

  const hasChanges = taskDraft && !isEqual(initialTaskState, taskDraft)

  if (!taskDraft) {
    dispatch(resetTaskDraft({ id: "new" }))
  }

  useLayoutEffect(() => {
    const initialData = animalId ? { animals: [animalId] } : {}
    if (Object.keys(initialData).length > 0) {
      dispatch(resetTaskDraft({ id: "new", draft: initialData }))
    }
  }, [animalId, dispatch])

  return { taskDraft, hasChanges }
}

export default useInitializeTaskDraft
