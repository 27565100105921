import React, { useState } from "react"
import styles from "./select-picture.module.css"
import SmallPlus from "@/assets/icons/misc/small-plus.tsx"
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/components/shadcn/avatar.tsx"
import { resizeImage } from "@/utils/helpers/compres-image-utils.ts"

interface SelectPictureProps {
  picture: string | undefined
  onChoosePicture?: (imgBase64: string | undefined) => void
  icon: React.ReactNode
  info?: string
}

const SelectPictureCircleBase64: React.FC<SelectPictureProps> = ({
  picture,
  onChoosePicture,
  icon,
  info,
}) => {
  const [pictureUrl, setPictureUrl] = useState(picture)
  const fileInputRef = React.useRef<HTMLInputElement>(null)

  const handleAddImage = (base64: string) => {
    if (onChoosePicture) {
      onChoosePicture(base64)
      setPictureUrl(base64)
    }
  }

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      resizeImage(file, 800, 800, handleAddImage)
    }
  }

  const clickInput = () => {
    onChoosePicture && fileInputRef.current?.click()
  }

  return (
    <>
      <div
        className="relative flex flex-col items-center justify-center gap-2"
        onClick={clickInput}
      >
        <Avatar>
          <AvatarImage src={pictureUrl ? pictureUrl : picture} />
          <AvatarFallback>{icon}</AvatarFallback>
        </Avatar>
        {onChoosePicture && (
          <div className={styles.smallPlus}>
            <SmallPlus />
          </div>
        )}
        <div className={styles.imageText}>{info}</div>
      </div>

      <input
        id="fileInput"
        type="file"
        accept=".jpg, .jpeg, .png"
        ref={fileInputRef}
        className={styles.imageInput}
        onChange={handleFileChange}
      />
    </>
  )
}

export default React.memo(SelectPictureCircleBase64)
