import React from "react"

interface BinIconProps extends React.SVGProps<SVGSVGElement> {}

const BinIcon: React.FC<BinIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.501"
      height="14.296"
      viewBox="148 775 13.501 14.296"
      {...props}
    >
      <g data-name="trash-bin-trash-svgrepo-com">
        <g data-name="Path 1231">
          <path
            d="M161.501 777.383H148"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="M161.501 777.383H148"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.2"
            stroke="var(--delete-red)"
            fill="transparent"
          />
        </g>
        <g data-name="Path 1232">
          <path
            d="m160.178 779.368-.366 5.48c-.14 2.108-.21 3.162-.898 3.805-.687.643-1.743.643-3.856.643h-.615c-2.113 0-3.17 0-3.856-.643-.687-.643-.758-1.697-.898-3.806l-.365-5.479"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="m160.178 779.368-.366 5.48c-.14 2.108-.21 3.162-.898 3.805-.687.643-1.743.643-3.856.643h-.615c-2.113 0-3.17 0-3.856-.643-.687-.643-.758-1.697-.898-3.806l-.365-5.479"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.2"
            stroke="var(--delete-red)"
            fill="transparent"
          />
        </g>
        <g data-name="Path 1233">
          <path
            d="m152.765 781.354.397 3.97"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="m152.765 781.354.397 3.97"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.2"
            stroke="var(--delete-red)"
            fill="transparent"
          />
        </g>
        <g data-name="Path 1234">
          <path
            d="m156.736 781.354-.397 3.97"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="m156.736 781.354-.397 3.97"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.2"
            stroke="var(--delete-red)"
            fill="transparent"
          />
        </g>
        <g data-name="Path 1235">
          <path
            d="M150.383 777.383h.086a1.588 1.588 0 0 0 1.454-1.048l.028-.083.077-.23c.066-.198.098-.297.142-.38.174-.335.497-.568.869-.627.093-.015.197-.015.405-.015h2.613c.208 0 .312 0 .406.015.372.06.694.292.868.626.044.084.077.183.143.38l.077.231c.014.042.02.064.028.083a1.588 1.588 0 0 0 1.54 1.048"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="M150.383 777.383h.086a1.588 1.588 0 0 0 1.454-1.048l.028-.083.077-.23c.066-.198.098-.297.142-.38.174-.335.497-.568.869-.627.093-.015.197-.015.405-.015h2.613c.208 0 .312 0 .406.015.372.06.694.292.868.626.044.084.077.183.143.38l.077.231c.014.042.02.064.028.083a1.588 1.588 0 0 0 1.54 1.048"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.2"
            stroke="var(--delete-red)"
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default BinIcon
