import React from "react"
import { BROWN, GREY } from "@/utils/constants/colors-constants.ts"

interface MilkIconProps {
  is_active?: boolean
}

const MilkIcon: React.FC<MilkIconProps> = ({ is_active = false }) => {
  const fillColor = is_active ? BROWN : GREY

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9.374"
      height="20.46"
      viewBox="5670.313 -1167 9.374 20.46"
    >
      <g data-name="CompositeLayer">
        <path
          d="M5671.5-1167h7a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.3"
          stroke={fillColor}
          fill="transparent"
          data-name="Rectangle 1819"
        />
        <path
          d="M5677.407-1163.548a1.106 1.106 0 0 0-.98-.568h-2.814c-.43 0-.823.233-1.014.616-.664 1.33-2.144 4.543-2.276 6.996a.005.005 0 0 1-.005.004.005.005 0 0 0-.005.005v7.612a2.343 2.343 0 0 0 2.343 2.343h4.688a2.343 2.343 0 0 0 2.343-2.343v-7.613a.004.004 0 0 0-.004-.004.004.004 0 0 1-.003-.003c-.116-2.801-1.603-5.818-2.273-7.045Z"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.3"
          stroke={fillColor}
          fill="transparent"
          data-name="Path 1304"
        />
      </g>
    </svg>
  )
}

export default MilkIcon
