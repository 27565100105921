import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import CalendarFilterTab from "@/components/filter-tab/gestation-calendar/calendar-filter-tab.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { ANIMAL_GENDERS } from "@/features/farm"
import CountNumber from "@/features/farm/components/count-number/count-number.tsx"
import { useGetSpeciesOptions } from "@/features/farm/hooks/use-get-species-options.ts"
import { useGetGestationCalendarsQuery } from "@/features/gestational-calendar/api/gestation-calendar-api.ts"
import GestationCalendarCard from "@/features/gestational-calendar/components/gestation-calendar-card/gestation-calendar-card.tsx"
import NewCalendarButton from "@/features/gestational-calendar/components/navigation-buttons/new-calendar-button.tsx"
import { useAppSelector } from "@/redux/hooks.ts"
import {
  HOME_SCREEN_PATH,
  NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

const AllCalendarsPage = () => {
  const { t } = useTranslation()
  const { data, isLoading } = useGetGestationCalendarsQuery()
  const navigate = useNavigate()
  const species = useGetSpeciesOptions()
  const cows = species.find((species) => species.label === "Cow")

  const gestationalCalendarDraft = useAppSelector(
    (state) => state.gestationalCalendarsDraftSlice.draft,
  )

  // this is used to hide the draft calendar from the list
  const workingData = data?.filter(
    (calendar) => calendar.id !== gestationalCalendarDraft.id,
  )

  const navigationString = `${NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH}?gender=${ANIMAL_GENDERS.FEMALE.label}&species=${cows?.value}`

  const onBackClick = () => {
    navigate(HOME_SCREEN_PATH)
  }

  return (
    <>
      <MobileTopBar
        onBackClick={onBackClick}
        title={t("gestationalCalculator")}
        navigateTo={navigationString}
      />
      <PageContentWrapperLarge>
        <CalendarFilterTab />
        <CountNumber count={data?.length || 0} text={t("gestationCalendars")} />
        {isLoading && <LoadingAnimation />}
        {!isLoading &&
          workingData?.map((calendar) => (
            <GestationCalendarCard
              key={calendar.id}
              gestationCalendar={calendar}
            />
          ))}
        {!isLoading && data?.length === 0 && (
          <NewCalendarButton navigationString={navigationString} />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default AllCalendarsPage
