import { useTranslation } from "react-i18next"
import styles from "../settings-routes.module.css"
import TermsIcon from "@/assets/icons/settings/terms-icon.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"

const SettingsTerms = () => {
  const { t } = useTranslation()

  return (
    <>
      <SettingsTopBar title={t("termsAndServices")} />
      <div className={styles.settingsContent}>
        <div className={styles.imageCircleCenter}>
          <TermsIcon />
        </div>

        <div className={styles.card}>
          <div className={styles.cardTitleBrown}>{t("termsAndServices")}</div>
          <div>{t("lastUpdate")} 23.06.2024</div>

          <div className={styles.cardSubtitle}>1. Termene</div>

          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius
            consequat dictum iaculis ultricies eros. Nulla id nulla porta lacus,
            morbi lorem.
          </div>

          <div>
            <strong>Nulla id nulla porta lacus, morbi lorem.</strong>
          </div>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius
            consequat dictum:
          </div>

          <div className={styles.cardSubtitle}>2. Servicii</div>

          <div>
            Et varius arcu, ipsum integer porttitor. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Varius consequat dictum. Fringilla
            viverra id id sit viverra nec, accumsan. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Varius consequat dictum Lorem ipsum
            dolor sit amet, consectetur adipiscing elit. Varius consequat dictum
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsTerms
