import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuMedKitIconMobile: React.FC<GeneralSvgProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="60"
      height="60"
      viewBox="139.953 732.95 60 60"
    >
      <g data-name="Group 4434">
        <path
          d="M139.953 762.9a30 30 0 1 1 0 .1z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Ellipse 515"
        />
        <g data-name="Group 2768">
          <g data-name="icons">
            <path
              d="M181.632 771.558v-12.681c0-.7-.638-1.268-1.426-1.268h-19.973c-.788 0-1.427.568-1.427 1.268v12.68c0 .7.639 1.269 1.427 1.269h19.973c.788 0 1.426-.568 1.426-1.268Zm-14.266-17.754v1.268h5.706v-1.268c0-.7-.638-1.268-1.426-1.268h-2.853c-.788 0-1.427.568-1.427 1.268Zm14.266 1.268c1.576 0 2.853 1.135 2.853 2.537v15.217c0 1.401-1.277 2.536-2.853 2.536h-22.826c-1.576 0-2.853-1.135-2.853-2.536v-15.217c0-1.402 1.277-2.537 2.853-2.537h5.707v-2.536c0-1.401 1.277-2.536 2.853-2.536h5.706c1.577 0 2.854 1.135 2.854 2.536v2.536h5.706Zm-12.84 13.95v-2.537h-2.853c-.788 0-1.426-.568-1.426-1.268 0-.7.638-1.268 1.426-1.268h2.854v-2.536c0-.7.638-1.268 1.426-1.268.788 0 1.427.568 1.427 1.268v2.536h2.853c.788 0 1.427.568 1.427 1.268 0 .7-.639 1.268-1.427 1.268h-2.853v2.536c0 .7-.639 1.269-1.427 1.269s-1.426-.569-1.426-1.269Z"
              fill="var(--menu-contact-specialist-color)"
              fillRule="evenodd"
              data-name="help-[#1436]"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default MenuMedKitIconMobile
