export interface Breed {
  id: string
  name: string
  picture: string
  species: Species
}

export interface Species {
  id: string
  name: string
  picture: string
  breeds: Breed[]
}

export interface Animal {
  id: string
  picture: string | null
  species: string
  breed: string
  ain: string
  location: string
  mother: string
  father: string
  gender: string
  name: string
  birth_date: string
  entry_date: string
  entry_way: string
  description: string
  created_at: string
  updated_at: string
  ruminant_fields?: RuminantFields
  species_details?: Partial<Species>
  breed_details?: Partial<Breed>
  tasks: string[]
  notes: string[]
  medical_treatments_count?: number
  intended_purpose?: string
  is_healthy: boolean
  is_under_treatment: boolean
  statuses?: string[]
}

export type AnimalDraft = Omit<Animal, "id" | "created_at" | "updated_at">

export interface RuminantFields {
  insemination_count?: number
  gestation_count?: number
  calves_count?: number
  lactation_count?: number
  is_pregnant?: boolean
  is_lactating?: boolean
  is_in_heats?: boolean
}

export const initialRuminantFields: RuminantFields = {
  calves_count: 0,
  gestation_count: 0,
  lactation_count: 0,
  insemination_count: 0,
}
