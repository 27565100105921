import { type MouseEvent } from "react"
import { Link } from "react-router-dom"
import styles from "@/features/settings-account/routes/settings-routes.module.css"

interface SettingsCardLinkProps {
  title: string
  navigateTo?: string
  border?: boolean
  onClick?: () => void
  children: React.ReactNode
}

const SettingsCard: React.FC<SettingsCardLinkProps> = ({
  title,
  navigateTo = "",
  border = true,
  children,
  onClick,
}) => {
  const handleOnClick = (event: MouseEvent<HTMLAnchorElement>) => {
    if (onClick) {
      event.preventDefault()
      onClick()
    }
  }

  return (
    <Link onClick={handleOnClick} to={navigateTo}>
      <div className={styles.arrowEdit}>
        <div className={styles.cardItemName}>{title}</div>
        {children}
      </div>
      {border && <div className={styles.separator}></div>}
    </Link>
  )
}

export default SettingsCard
