import React from "react"
import CloudsIcon from "@/assets/icons/weather/clouds-icon.tsx"
import PartlySunnyIcon from "@/assets/icons/weather/partly-sunny.tsx"
import SunnyIcon from "@/assets/icons/weather/sunny-icon.tsx"

interface WeatherIconProps {
  cloudCoverage: number
}

const getWeatherIcon = (cloudCoverage: number) => {
  if (cloudCoverage < 12.5) {
    return <SunnyIcon />
  } else if (cloudCoverage < 50) {
    return <PartlySunnyIcon />
  } else {
    return <CloudsIcon />
  }
}

export const WeatherIcon: React.FC<WeatherIconProps> = ({ cloudCoverage }) => {
  return getWeatherIcon(cloudCoverage)
}
