import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import { type TaskDraft } from "@/features/tasks"

type TaskDraftSlice = {
  drafts: Record<string, TaskDraft>
}

export const initialTaskState: TaskDraft = {
  title: "",
  description: "",
  category: null,
  reminder: null,
  recurrence: null,
  due_date: null,
  is_done: false,
  animals: [],
  notes: [],
}

const initialState: TaskDraftSlice = {
  drafts: {},
}

const taskDraftSlice = createSlice({
  name: "taskDraft",
  initialState,
  reducers: {
    setTaskDraft: (
      state,
      action: PayloadAction<{ id: string; draft: TaskDraft }>,
    ) => {
      state.drafts[action.payload.id] = action.payload.draft
    },
    setDraftTaskTitle: (
      state,
      action: PayloadAction<{ id: string; title: string }>,
    ) => {
      state.drafts[action.payload.id].title = action.payload.title
    },
    setDraftTaskDescription: (
      state,
      action: PayloadAction<{ id: string; description: string }>,
    ) => {
      state.drafts[action.payload.id].description = action.payload.description
    },
    setDraftTaskCategory: (
      state,
      action: PayloadAction<{ id: string; category: string | null }>,
    ) => {
      state.drafts[action.payload.id].category = action.payload.category
    },
    setDraftTaskReminder: (
      state,
      action: PayloadAction<{ id: string; reminder: number | null }>,
    ) => {
      state.drafts[action.payload.id].reminder = action.payload.reminder
    },
    setDraftTaskRecurrence: (
      state,
      action: PayloadAction<{ id: string; recurrence: number | null }>,
    ) => {
      state.drafts[action.payload.id].recurrence = action.payload.recurrence
    },
    setDraftTaskDueDate: (
      state,
      action: PayloadAction<{ id: string; due_date: string | null }>,
    ) => {
      state.drafts[action.payload.id].due_date = action.payload.due_date
    },
    setDraftTaskIsDone: (
      state,
      action: PayloadAction<{ id: string; is_done: boolean }>,
    ) => {
      state.drafts[action.payload.id].is_done = action.payload.is_done
    },
    setDraftTaskAnimals: (
      state,
      action: PayloadAction<{ id: string; animals: string[] }>,
    ) => {
      state.drafts[action.payload.id].animals = action.payload.animals
    },
    setDraftTaskNotes: (
      state,
      action: PayloadAction<{ id: string; notes: string[] }>,
    ) => {
      state.drafts[action.payload.id].notes = action.payload.notes
    },
    resetTaskDraft: (
      state,
      action: PayloadAction<{ id: string; draft?: Partial<TaskDraft> }>,
    ) => {
      state.drafts[action.payload.id] = {
        ...initialTaskState,
        ...action.payload.draft,
      }
    },
    deleteTaskDraft: (state, action: PayloadAction<string>) => {
      delete state.drafts[action.payload]
    },
  },
})

export default taskDraftSlice.reducer

export const {
  setTaskDraft,
  setDraftTaskTitle,
  setDraftTaskDescription,
  setDraftTaskCategory,
  setDraftTaskReminder,
  setDraftTaskRecurrence,
  setDraftTaskDueDate,
  setDraftTaskIsDone,
  setDraftTaskAnimals,
  setDraftTaskNotes,
  resetTaskDraft,
  deleteTaskDraft,
} = taskDraftSlice.actions
