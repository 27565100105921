import React from "react"
import { BROWN, DARK_GREY } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const FarmIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || "26"}
      height={props.size || "28"}
      viewBox="2.0 0 30 31"
    >
      <path
        d="M29 18.3c0-2.6-1.4-4.9-3.6-6.1 1.5-2 2.6-4.4 2.6-7.2 0-1-.21-1.324-.288-2.249 0 0-1.15-2.559-2.993-.293C23.519 7.258 21.9 10 16.9 10h-1.8c-5 0-6.7-2.865-7.8-7.665 0 0-1.8-1.72-2.74-.25.105-.192.042-.096-.013.03C4.03 3.285 4 4 4 5c0 2.8 1.1 5.2 2.6 7.3-2.2 1.2-3.6 3.4-3.6 6 1.6 0 3.1-.6 4.3-1.5l1.1 9c.3 2.4 2.3 4.1 4.7 4.1h5.8c2.4 0 4.4-1.8 4.7-4.1l1.1-9c1.2 1 2.7 1.5 4.3 1.5Z"
        strokeLinejoin="round"
        strokeLinecap="round"
        strokeWidth={props.strokeWidth || "1.5"}
        stroke={
          props.stroke ? props.stroke : props.is_active ? BROWN : DARK_GREY
        }
        fill={props.is_active ? BROWN : "transparent"}
        data-name="Path 1402"
      />
    </svg>
  )
}

export default FarmIcon
