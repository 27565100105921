import React, { type ReactElement } from "react"
import { useTranslation } from "react-i18next"
import styles from "./overlay-item.module.css"

interface NoteOverlayProps {
  onClick: any
  label: string
  alt: string
  children: ReactElement<{ alt?: string }>
  color?: string
}

const OverlayItem: React.FC<NoteOverlayProps> = (props) => {
  const { t } = useTranslation()

  return (
    <div className={styles.overlayItem} onClick={props.onClick}>
      <div className={styles.overlayText} style={{ color: props.color }}>
        {t(props.label)}
      </div>
      <div className={styles["card-icon"]}>
        {React.cloneElement(props.children, { alt: props.alt })}
      </div>
    </div>
  )
}

export default OverlayItem
