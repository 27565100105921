import { useTranslation } from "react-i18next"
import styles from "./custom-toolbar.module.css"
import BoldSolidIcon from "@/assets/custom-toolbar/bold-solid-icon"
import IndentSolidIcon from "@/assets/custom-toolbar/indent-solid-icon"
import ItalicSolidIcon from "@/assets/custom-toolbar/italic-solid-icon"
import ListOlSolidIcon from "@/assets/custom-toolbar/list-ol-solid-icon"
import ListUlSolidIcon from "@/assets/custom-toolbar/list-ul-solid-icon"
import OutdentSolidIcon from "@/assets/custom-toolbar/outdent-solid-icon"
import StrikeThroughSolidIcon from "@/assets/custom-toolbar/strike-through-solid-icon"
import UnderlineSolidIcon from "@/assets/custom-toolbar/underline-solid-icon"
import CancelIcon from "@/assets/icons/misc/cancel-icon.tsx"

/**
 * Props for the FormatOverlay component.
 */
interface FormatOverlayProps {
  /**
   * Indicates whether the format overlay is active or not.
   */
  isFormatActive: boolean
  /**
   * Function to toggle-switch a text format.
   */
  toggleFormat: (format: any, value?: boolean | string | number) => void
  /**
   * State representing the current text formatting.
   */
  formatState: {
    bold: boolean
    italic: boolean
    underline: boolean
    strike: boolean
    header: string
    list: string
  }
  /**
   * Function to set the state of the format overlay.
   */
  setIsFormatActive: React.Dispatch<React.SetStateAction<boolean>>
  /**
   * Function to adjust indentation in the text editor.
   */
  adjustIndentation: (direction: "indent" | "outdent") => void
}

/**
 * A component representing an overlay for formatting text in a rich text editor.
 * @param {FormatOverlayProps} props - The props for the FormatOverlay component.
 * @returns {TSX.Element} The TSX element representing the FormatOverlay component.
 */
const FormatOverlay: React.FC<FormatOverlayProps> = ({
  isFormatActive,
  toggleFormat,
  formatState,
  setIsFormatActive,
  adjustIndentation,
}) => {
  const { t } = useTranslation()

  return (
    isFormatActive && (
      <div
        className={styles["format-overlay"]}
        onClick={() => setIsFormatActive(false)}
      >
        <div
          className={styles["format-card"]}
          onClick={(e) => e.stopPropagation()}
        >
          <div className={styles["card-title-and-cancel"]}>
            <span className={styles["format-title"]}>{t("Format")}</span>
            <CancelIcon
              className={styles["cancel-icon"]}
              onClick={() => setIsFormatActive(false)}
              alt="Close"
            />
          </div>

          <div className={styles["top-format-headers"]}>
            <button
              className={
                formatState.header === "normal"
                  ? styles["round-format-active"]
                  : styles["round-format-inactive"]
              }
              onClick={() => toggleFormat("header", "normal")}
            >
              Normal
            </button>
            <button
              className={
                formatState.header === "h2"
                  ? styles["round-format-active"]
                  : styles["round-format-inactive"]
              }
              onClick={() => toggleFormat("header", "h2")}
            >
              <h2>Medium</h2>
            </button>
            <button
              className={
                formatState.header === "h1"
                  ? styles["round-format-active"]
                  : styles["round-format-inactive"]
              }
              onClick={() => toggleFormat("header", "h1")}
            >
              <h1>Large</h1>
            </button>
          </div>

          <div className={styles["top-format"]}>
            <button
              className={
                formatState.bold
                  ? styles["bottom-format-active"]
                  : styles["bottom-format-inactive"]
              }
              onClick={() => toggleFormat("bold", !formatState.bold)}
            >
              <BoldSolidIcon />
            </button>
            <button
              className={
                formatState.italic
                  ? styles["bottom-format-active"]
                  : styles["bottom-format-inactive"]
              }
              onClick={() => toggleFormat("italic", !formatState.italic)}
            >
              <ItalicSolidIcon />
            </button>
            <button
              className={
                formatState.underline
                  ? styles["bottom-format-active"]
                  : styles["bottom-format-inactive"]
              }
              onClick={() => toggleFormat("underline", !formatState.underline)}
            >
              <UnderlineSolidIcon />
            </button>
            <button
              className={
                formatState.strike
                  ? styles["bottom-format-active"]
                  : styles["bottom-format-inactive"]
              }
              onClick={() => toggleFormat("strike", !formatState.strike)}
            >
              <StrikeThroughSolidIcon />
            </button>
          </div>

          <div className={styles["list-and-indentation"]}>
            <div className={styles["top-format"]}>
              <button
                className={styles["bottom-format-inactive"]}
                onClick={() => toggleFormat("list", "bullet")}
              >
                <ListUlSolidIcon />
              </button>

              <button
                className={styles["bottom-format-inactive"]}
                onClick={() => toggleFormat("list", "ordered")}
              >
                <ListOlSolidIcon />
              </button>
            </div>

            <div className={styles["top-format"]}>
              <button
                className={styles["indent-format"]}
                onClick={() => adjustIndentation("indent")}
              >
                <IndentSolidIcon />
              </button>
              <button
                className={styles["indent-format"]}
                onClick={() => adjustIndentation("outdent")}
              >
                <OutdentSolidIcon />
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default FormatOverlay
