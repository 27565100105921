import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  errors: string[]
}

const CustomErrorToast: React.FC<Props> = ({ errors }) => {
  const { t } = useTranslation()

  return (
    <div>
      <ul
        style={{
          paddingLeft: 20,
        }}
      >
        {errors.map((error, index) => (
          <li key={index}>{t(error)}</li>
        ))}
      </ul>
    </div>
  )
}

export default CustomErrorToast
