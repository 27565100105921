import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type AssistantThread } from "@/features/assistant/types/assistant.ts"

const ASSISTANT_BASE_URL = "ai-assistant/"

export const assistantApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAssistantThreadsForUser: builder.query<AssistantThread[], void>({
      query: () => ({
        url: ASSISTANT_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.Assistant, id: "LIST" }],
    }),
    createAssistant: builder.mutation<AssistantThread, void>({
      query: () => ({
        url: ASSISTANT_BASE_URL,
        method: "POST",
      }),
      invalidatesTags: [{ type: TagType.Assistant, id: "LIST" }],
    }),
    postMessageToAssistant: builder.mutation<
      AssistantThread,
      { threadId: string; message: string }
    >({
      query: ({ threadId, message }) => ({
        url: ASSISTANT_BASE_URL + `${threadId}/say/`,
        method: "POST",
        body: { message },
      }),
      invalidatesTags: () => [{ type: TagType.Assistant, id: "LIST" }],
      onQueryStarted: async (
        { threadId, message },
        { dispatch, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          assistantApi.util.updateQueryData(
            "getAllAssistantThreadsForUser",
            undefined,
            (draft) => {
              const thread = draft.find((t) => t.id === threadId)
              if (thread) {
                thread.messages.push({
                  id: Date.now().toString(),
                  thread: threadId,
                  user_message: message,
                  gpt_response: "",
                  created_at: new Date().toISOString(),
                  updated_at: new Date().toISOString(),
                })
              }
            },
          ),
        )
        try {
          await queryFulfilled
        } catch {
          patchResult.undo()
        }
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetAllAssistantThreadsForUserQuery,
  useCreateAssistantMutation,
  usePostMessageToAssistantMutation,
} = assistantApi
