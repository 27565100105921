import isEqual from "lodash.isequal"
import React from "react"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteTransactionMutation,
  useUpdateTransactionMutation,
} from "@/features/finance/api/finance-api"
import TransactionEditor from "@/features/finance/components/transaction-editor/transaction-editor"
import useGetDraftTransaction from "@/features/finance/hooks/use-get-draft-transaction.ts"
import { validateTransaction } from "@/features/finance/types/transaction-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal"
import { MODALS } from "@/features/modals/types/modals"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"

const TransactionView: React.FC = () => {
  const { openModal } = useModal()
  const { handleBackNavigation } = useNavigationFallback()
  const { t } = useTranslation()
  const { transactionId } = useParams<{ transactionId: string }>()

  const { data, isLoading, draftTransaction } =
    useGetDraftTransaction(transactionId)

  const [updateTransactionMutation] = useUpdateTransactionMutation()
  const [deleteTransactionMutation] = useDeleteTransactionMutation()

  const handleUpdate = async () => {
    if ((await validateTransaction(draftTransaction)) && transactionId) {
      toast
        .promise(
          updateTransactionMutation({
            id: transactionId,
            ...draftTransaction,
          }).unwrap(),
          {
            pending: t("savingTransaction"),
            success: t("transactionSaved"),
            error: t("transactionSaveError"),
          },
        )
        .then(() => handleBackNavigation())
    }
  }

  const deleteTransaction = () => {
    if (transactionId) {
      toast
        .promise(deleteTransactionMutation(transactionId).unwrap(), {
          pending: t("deletingTransaction"),
          success: t("transactionDeleted"),
          error: t("transactionDeleteError"),
        })
        .then(() => handleBackNavigation())
    }
  }

  const handleDeleteTask = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteTransactionConfirmation"),
      content: t("transactionWillBeDeleted"),
      onActionClick: deleteTransaction,
    })
  }

  const hasChanges = !!data && !isEqual(data, draftTransaction)

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => handleBackNavigation(),
      })
    } else {
      handleBackNavigation()
    }
  }

  return (
    <>
      <EditorTopBar
        text={"transaction"}
        onSave={handleUpdate}
        onBackClick={handleBackClick}
        isModified={hasChanges}
      />
      <PageContentWrapperLarge hasPadding={false}>
        {isLoading || !draftTransaction ? (
          <LoadingAnimation />
        ) : (
          <>
            <TransactionEditor />
            <DeleteButton
              text={t("deleteTransaction")}
              onClick={handleDeleteTask}
              style={{ marginBlock: "50px" }}
            />
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default TransactionView
