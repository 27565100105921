import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "../filter-tab.module.css"
import CustomSelectPill from "@/components/custom-select/custom-select-pill.tsx"
import CustomToggle from "@/components/custom-toggle/custom-toggle.tsx"
import DrawerAnimalsNested from "@/features/farm/components/drawer-animals/drawer-animals-nested.tsx"
import DrawerAnimals from "@/features/farm/components/drawer-animals/drawer-animals.tsx"
import {
  initialTaskFilterSelections,
  type TaskFilterSelection,
} from "@/features/tasks"
import DrawerAnimalsFilterTrigger from "@/features/tasks/components/drawer-animals-filter-trigger/drawer-animals-filter-trigger.tsx"
import { useTaskCategoryOptions } from "@/features/tasks/hooks/use-task-category-options.ts"

interface FilterTabProps {
  onFilterChange: (filter: any) => void
  isNested?: boolean
}

export const TaskFilterTab: React.FC<FilterTabProps> = ({
  onFilterChange,
  isNested = false,
}) => {
  const categoryOptions = useTaskCategoryOptions()
  const { t } = useTranslation()
  const [selections, setSelections] = useState<TaskFilterSelection>(
    initialTaskFilterSelections,
  )

  const updateSelections = useCallback(
    (selections: TaskFilterSelection) => {
      setSelections(selections)
      onFilterChange(selections)
    },
    [onFilterChange],
  )

  const handleAnimalSelection = useCallback(
    (animalIDs: string[]) => {
      const newSelections = { ...selections, animals: animalIDs }
      updateSelections(newSelections)
    },
    [selections, updateSelections],
  )

  return (
    <div className={styles["horizontal-scroll"]}>
      <div className={styles["filter-flex"]}>
        <CustomSelectPill
          options={categoryOptions}
          currentSelection={selections.category}
          onSelectionChange={(selection) => {
            updateSelections({ ...selections, category: selection })
          }}
        />
        <CustomToggle
          title={t("DONE")}
          isSelected={selections.is_done}
          onChange={(status) => {
            updateSelections({ ...selections, is_done: status })
          }}
        />
        {isNested ? (
          <DrawerAnimalsNested
            currentValue={selections.animals}
            onSaveClick={(animal) => handleAnimalSelection(animal)}
            TriggerComponent={DrawerAnimalsFilterTrigger}
          />
        ) : (
          <DrawerAnimals
            currentValue={selections.animals}
            onSaveClick={handleAnimalSelection}
            TriggerComponent={DrawerAnimalsFilterTrigger}
          />
        )}
      </div>
    </div>
  )
}
