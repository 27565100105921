import React from "react"

const RegistryIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="1661 -120 30 30"
    >
      <g data-name="CompositeLayer">
        <path
          d="M1668-120h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7h-16a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="#414141"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <path
          d="M1670-110v-3a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1h-10a1 1 0 0 1-1-1v-3m0-4h7m0 0-2.5-2.222M1677-106l-2.5 2.222"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.3"
          stroke="#fff"
          fill="transparent"
          data-name="enter-svgrepo-com"
        />
      </g>
    </svg>
  )
}

export default RegistryIcon
