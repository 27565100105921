import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { type Breed } from "@/features/farm"
import { type Option } from "@/utils/types/misc-types.ts"

export const BREEDS_BASE_URL = "breeds/"

export const breedsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getBreeds: builder.query<Breed[], void>({
      query: () => BREEDS_BASE_URL,
      providesTags: (result) => {
        if (!result) {
          return [{ type: TagType.Breeds as const, id: "LIST" }]
        }

        const taskTags = result.map(({ id }) => ({
          type: TagType.Breeds as const,
          id: id ?? "UNKNOWN",
        }))

        return [...taskTags, { type: TagType.Breeds }]
      },
    }),
    getBreedsBySpeciesId: builder.query<Option[], string>({
      query: (id) => BREEDS_BASE_URL + `?species=${id}`,
      transformResponse: (response: Breed[]): Option[] => {
        return response.map((breed) => ({
          label: breed.name,
          value: breed.id,
        }))
      },
      providesTags: () => [{ type: TagType.Species }],
    }),
  }),
})

export const {
  useGetBreedsQuery,
  useGetBreedsBySpeciesIdQuery: useGetBreedsBySpeciesIdQuery,
} = breedsApi
