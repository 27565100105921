import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./gestation-calendar-card.module.css"
import EmptyCalendarIcon from "@/assets/icons/misc/empty-calendar-icon.tsx"
import AinTag from "@/components/ain-tag/ain-tag.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import Skeleton from "@/components/skeleton/skeleton.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import { getCurrentAnimalImage } from "@/features/farm"
import { useGetAnimalByIdQuery } from "@/features/farm/api/animals-api.ts"
import GestationCalendarAccordion from "@/features/gestational-calendar/components/gestation-calendar-accordion/gestation-calendar-accordion.tsx"
import GestationCalendarCardBottomAfterBirth from "@/features/gestational-calendar/components/gestation-calendar-card/gestation-calendar-card-bottom-after-birth.tsx"
import GestationCalendarCardBottomUntilBirth from "@/features/gestational-calendar/components/gestation-calendar-card/gestation-calendar-card-bottom-until-birth.tsx"
import {
  CalendarActivityState,
  type GestationCalendar,
} from "@/features/gestational-calendar/types/calendar-types.ts"
import {
  checkIsCalendarModified,
  getNextActivity,
} from "@/features/gestational-calendar/utils/gestational-calendar-utils.ts"
import { GESTATIONAL_CALCULATOR_SCREEN_PATH } from "@/utils/constants/routes.ts"

interface GestationCalendarCardProps {
  gestationCalendar: GestationCalendar
}

const GestationCalendarCard: React.FC<GestationCalendarCardProps> = ({
  gestationCalendar,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: animal } = useGetAnimalByIdQuery(gestationCalendar.animal)
  const isCalendarModified = checkIsCalendarModified(gestationCalendar)

  const navigateToCalendar = () => {
    navigate(GESTATIONAL_CALCULATOR_SCREEN_PATH + "/" + gestationCalendar.id)
  }

  const nextActivity = getNextActivity(gestationCalendar)

  return (
    <OverflowMenuItem
      marginInline={false}
      onClick={navigateToCalendar}
      minHeight={160}
    >
      {animal && gestationCalendar ? (
        <div className={styles.gestationalCalendarWrapper}>
          <div className={styles.gestationalCalendarCardTop}>
            <GenericPicture
              src={getCurrentAnimalImage(animal)}
              alt={animal.name || animal.ain}
              size={70}
              borderRadius={15}
              style={{ aspectRatio: "1/1" }}
            />
            <div className={styles.gestationalCalendarCardTopContent}>
              <div className={styles.gestationalCalendarCardTopContentTop}>
                <div />
                <AinTag label={animal.ain} />
              </div>
              {isCalendarModified ? (
                <GestationCalendarCardBottomAfterBirth
                  gestationCalendar={gestationCalendar}
                />
              ) : (
                <GestationCalendarCardBottomUntilBirth
                  gestationCalendar={gestationCalendar}
                />
              )}
            </div>
          </div>
          <div className={styles.gestationalCalendarCardBottom}>
            <div className={styles.gestationalCalendarCardBottomNextEvent}>
              <EmptyCalendarIcon />
              {t("nextActivity")}
            </div>
            <div className={styles.gestationalCalendarCardBottomEventDetails}>
              <GestationCalendarAccordion
                activityState={CalendarActivityState.DUE}
                isCalvingDone={isCalendarModified}
                calendarActivity={nextActivity}
                isCalving={
                  gestationCalendar.calving_date === nextActivity.event_date
                }
                animalId={gestationCalendar.animal}
                gestationCalendar={gestationCalendar}
              />
            </div>
          </div>
        </div>
      ) : (
        <Skeleton />
      )}
    </OverflowMenuItem>
  )
}

export default GestationCalendarCard
