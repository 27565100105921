import React from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import styles from "../settings-routes.module.css"

import ArrowRight from "@/assets/icons/misc/arrow-right.tsx"
import GoToIcon from "@/assets/icons/misc/go-to-icon.tsx"
import PersonIcon from "@/assets/icons/settings/person-icon.tsx"
import RecommendIcon from "@/assets/icons/settings/recommend-icon.tsx"
import SignOutIcon from "@/assets/icons/settings/sign-out-icon.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import SelectPictureCircleBase64 from "@/components/select-picture-circle-base64/select-picture-circle-base64.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { useGetProfileQuery } from "@/features/settings-account/api/account-api.ts"
import AppVersionCard from "@/features/settings-account/components/app-version-card/app-version-card.tsx"
import SettingsCard from "@/features/settings-account/components/settings-card-link/settings-card.tsx"
import { initialUserProfile } from "@/features/settings-account/utils/initialStates.ts"
import { logout } from "@/redux/slices/auth-slice.ts"
import { type AppDispatch, persistor } from "@/redux/store.ts"
import {
  CONFIDENTIALITY_PATH,
  CONTACT_PATH,
  VIEW_COMPANY_INFO_PATH,
  EDIT_FARM_PROFILE_PATH,
  EDIT_MY_PROFILE_PATH,
  FAQ_PATH,
  RESET_PASSWORD_PATH,
  TERMS_PATH,
  TUTORIAL_PATH,
  NOTIFICATION_PREFERENCES_PATH,
} from "@/utils/constants/routes.ts"

const SettingsPage: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch<AppDispatch>()
  const { data: userProfile = initialUserProfile } = useGetProfileQuery()
  const { openModal } = useModal()

  const signOut = () => {
    dispatch(logout())
      .unwrap()
      .then(() => persistor.purge())
  }

  const handleSignOutClick = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("signOutConfirmation"),
      onActionClick: signOut,
    })
  }

  const handleShareClick = () => {
    openModal(MODALS.SHARE_APP_MODAL, {})
  }

  return (
    <>
      <SettingsTopBar title={t("settings")} />

      <PageContentWrapperLarge hasPadding={false}>
        <div className={styles.imageInfo}>
          <SelectPictureCircleBase64
            picture={userProfile.picture}
            icon={<PersonIcon />}
          />
          <div className={styles.userName}>
            {userProfile.first_name} {userProfile.last_name}
          </div>
          <div className={styles.farmLocation}>{userProfile.farm?.name}</div>
        </div>

        <OverflowMenuItem>
          <div className={styles.cardTitle}>{t("farmProfile")}</div>
          <SettingsCard
            title={t("farmProfile")}
            navigateTo={EDIT_FARM_PROFILE_PATH}
          >
            <ArrowRight width={8} />
          </SettingsCard>

          <SettingsCard
            title={t("companyInfo")}
            navigateTo={VIEW_COMPANY_INFO_PATH}
            border={false}
          >
            <ArrowRight width={8} />
          </SettingsCard>
        </OverflowMenuItem>

        <OverflowMenuItem>
          <div className={styles.cardTitle}>{t("accountSettings")}</div>

          <SettingsCard
            title={t("myProfile")}
            navigateTo={EDIT_MY_PROFILE_PATH}
          >
            <ArrowRight width={8} />
          </SettingsCard>

          <SettingsCard
            title={t("resetPassword")}
            navigateTo={RESET_PASSWORD_PATH}
          >
            <ArrowRight width={8} />
          </SettingsCard>

          <SettingsCard
            title={t("notificationPreferences")}
            navigateTo={NOTIFICATION_PREFERENCES_PATH}
            border={false}
          >
            <ArrowRight width={8} />
          </SettingsCard>
        </OverflowMenuItem>

        <OverflowMenuItem>
          <div className={styles.cardTitle}>{t("information")}</div>

          <SettingsCard title={t("tutorials")} navigateTo={TUTORIAL_PATH}>
            <ArrowRight width={8} />
          </SettingsCard>

          <SettingsCard title={t("faq")} navigateTo={FAQ_PATH}>
            <ArrowRight width={8} />
          </SettingsCard>

          <SettingsCard
            title={t("confidentiality")}
            navigateTo={CONFIDENTIALITY_PATH}
          >
            <ArrowRight width={8} />
          </SettingsCard>

          <SettingsCard title={t("termsAndServices")} navigateTo={TERMS_PATH}>
            <ArrowRight width={8} />
          </SettingsCard>

          <AppVersionCard />
        </OverflowMenuItem>

        <OverflowMenuItem>
          <div className={styles.cardTitle}>{t("yourOpinion")}</div>
          <SettingsCard title={t("recommend")} onClick={handleShareClick}>
            <RecommendIcon />
          </SettingsCard>
          <SettingsCard title={t("likeUs")}>
            <GoToIcon />
          </SettingsCard>
          <SettingsCard
            title={t("contactUs")}
            navigateTo={CONTACT_PATH}
            border={false}
          >
            <ArrowRight width={8} />
          </SettingsCard>
        </OverflowMenuItem>

        <div className={styles.signOutFlex} onClick={handleSignOutClick}>
          <SignOutIcon />
          <div className={styles.signOutText}>{t("signOut")}</div>
        </div>
      </PageContentWrapperLarge>
    </>
  )
}

export default SettingsPage
