import { Route } from "react-router-dom"
import AnimalEventView from "@/features/animal-events/routes/animal-event-view/animal-event-view.tsx"
import AnimalEventsPage from "@/features/animal-events/routes/events-page/events-page.tsx"
import NewAnimalEventView from "@/features/animal-events/routes/new-animal-event-view/new-animal-event-view.tsx"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import {
  ANIMAL_EVENT_SCREEN_PATH,
  NEW_ANIMAL_EVENT_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

export const EventsRoutes = (
  <Route element={<NoMenuLayout />}>
    <Route
      key={ANIMAL_EVENT_SCREEN_PATH}
      path={ANIMAL_EVENT_SCREEN_PATH}
      element={<AnimalEventsPage />}
    />
    <Route
      key={`${NEW_ANIMAL_EVENT_SCREEN_PATH}`}
      path={`${NEW_ANIMAL_EVENT_SCREEN_PATH}`}
      element={<NewAnimalEventView />}
    />
    <Route
      key={`${ANIMAL_EVENT_SCREEN_PATH}/:animalEventId`}
      path={`${ANIMAL_EVENT_SCREEN_PATH}/:animalEventId`}
      element={<AnimalEventView />}
    />
    <Route
      key={`${NEW_ANIMAL_EVENT_SCREEN_PATH}/:animalId?/:typeId?`}
      path={`${NEW_ANIMAL_EVENT_SCREEN_PATH}/:animalId?/:typeId?`}
      element={<NewAnimalEventView />}
    />
  </Route>
)
