import { Route } from "react-router-dom"
import FinancePage from "@/features/finance/routes/finance-page/finance-page.tsx"
import NewTransactionView from "@/features/finance/routes/new-transaction-view/new-transaction-view.tsx"
import TransactionHistoryPage from "@/features/finance/routes/transaction-history-page/transaction-history-page.tsx"
import TransactionView from "@/features/finance/routes/transaction-view/transaction-view.tsx"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import {
  FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH,
  FINANCE_TRANSACTIONS_SCREEN_PATH,
  FINANCIAL_SCREEN_PATH,
  NEW_TRANSACTION_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

export const FinancialRoutes = (
  <Route element={<NoMenuLayout />}>
    <Route
      key={FINANCIAL_SCREEN_PATH}
      path={FINANCIAL_SCREEN_PATH}
      element={<FinancePage />}
    />
    <Route
      key={FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH}
      path={FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH}
      element={<TransactionHistoryPage />}
    />
    <Route
      key={NEW_TRANSACTION_SCREEN_PATH}
      path={NEW_TRANSACTION_SCREEN_PATH}
      element={<NewTransactionView />}
    />
    <Route
      key={FINANCE_TRANSACTIONS_SCREEN_PATH + "/:transactionId"}
      path={`${FINANCE_TRANSACTIONS_SCREEN_PATH}/:transactionId`}
      element={<TransactionView />}
    />
  </Route>
)
