import React from "react"
import { useTranslation } from "react-i18next"
import DetailIcon from "@/assets/icons/farm/details-icon"
import GenericDatePicker from "@/components/generic-date-picker/generic-date-picker.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon"
import AmountInput from "@/features/finance/components/amount-input/amount-input"
import TransactionCategoryDrawer from "@/features/finance/components/transaction-category-drawer/transaction-category-drawer"
import useGetTransactionCategoryById from "@/features/finance/hooks/use-get-transaction-category-by-id.ts"
import { type TransactionCategory } from "@/features/finance/types/transaction"
import { useAppDispatch, useAppSelector } from "@/redux/hooks"
import {
  setDraftTransactionAmount,
  setDraftTransactionCategory,
  setDraftTransactionCurrency,
  setDraftTransactionDate,
  setDraftTransactionDescription,
} from "@/redux/slices/transaction-draft-slice"

interface TransactionEditorProps {}

const TransactionEditor: React.FC<TransactionEditorProps> = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const draftTransaction = useAppSelector(
    (state) => state.transactionSlice.draft,
  )

  const setDescription = (description: string) => {
    dispatch(setDraftTransactionDescription(description))
  }

  const setCategory = (transactionCategory: TransactionCategory) => {
    dispatch(setDraftTransactionCategory(transactionCategory.id))
  }

  const setAmount = (amount: string) => {
    dispatch(setDraftTransactionAmount(amount))
  }

  const setDate = (date: string) => {
    dispatch(setDraftTransactionDate(date))
  }

  const setCurrency = (currency: string) => {
    dispatch(setDraftTransactionCurrency(currency))
  }

  const transactionCategory = useGetTransactionCategoryById(
    draftTransaction.category,
  )

  if (!draftTransaction) {
    return <LoadingAnimation />
  }

  return (
    <>
      <AmountInput
        value={draftTransaction.amount}
        currency={draftTransaction.currency}
        onCurrencyChange={setCurrency}
        onChange={setAmount}
        is_outflow={transactionCategory?.is_outflow || false}
      />
      <OverflowMenuItem>
        <TransactionCategoryDrawer
          selectedCategory={transactionCategory}
          onCategorySelect={setCategory}
        />
      </OverflowMenuItem>
      <GenericDatePicker value={draftTransaction.date} onChange={setDate} />
      <TextAreaFieldWrapperWithIcon
        icon={<DetailIcon />}
        name={t("details")}
        isReadOnly={false}
        value={draftTransaction.description}
        onChange={(e) => setDescription(e.target.value)}
      />
    </>
  )
}

export default TransactionEditor
