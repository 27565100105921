import { useCallback, useEffect } from "react"
import { useSubscribeToWebPushMutation } from "@/features/notifications/api/notifications-api"

const VAPID_PUBLIC_KEY = import.meta.env.VITE_VAPID_PUBLIC_KEY

export function urlBase64ToUint8Array(base64String: string): Uint8Array {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/")
  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const useSubscribeToWebPush = () => {
  const [subscribe, requestStatus] = useSubscribeToWebPushMutation()

  const subscribeToPush = useCallback(
    async (registration: ServiceWorkerRegistration) => {
      try {
        const applicationServerKey = urlBase64ToUint8Array(VAPID_PUBLIC_KEY)
        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: applicationServerKey,
        })
        const subscriptionData = {
          endpoint: subscription.endpoint,
          p256dh: btoa(
            String.fromCharCode.apply(
              null,
              Array.from(new Uint8Array(subscription.getKey("p256dh")!)),
            ),
          ),
          auth: btoa(
            String.fromCharCode.apply(
              null,
              Array.from(new Uint8Array(subscription.getKey("auth")!)),
            ),
          ),
        }

        await subscribe(subscriptionData)
        console.log("Successfully subscribed to push notifications.")
      } catch (error) {
        console.error("Failed to subscribe to push notifications:", error)
      }
    },
    [subscribe],
  )

  useEffect(() => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager.getSubscription().then((subscription) => {
          if (!subscription) {
            subscribeToPush(registration)
          }
        })
      })
    }
  }, [subscribeToPush])

  return requestStatus
}
