export enum FarmType {
  BIRDS = "BIRDS",
  MAMMALS = "MAMMALS",
  BEES = "BEES",
  COWS = "COWS",
  GOATS = "GOATS",
  PIGS = "PIGS",
  SHEEP = "SHEEP",
}

export interface Address {
  street: string
  city: string
  state: string
  country: string
  extra: string
  latitude: number
  longitude: number
}

export interface AppPackage {
  name: string
  description: string
  is_assistant_enabled: boolean
  is_gestational_calendar_enabled: boolean
  is_finance_journal_enabled: boolean
  farm_type: FarmType
}

export interface FarmProfile {
  id: string
  name: string
  description: string
  legal_name: string
  tin: string
  picture: string | undefined
  created_at: string
  updated_at: string
  address: Address
  app_package: AppPackage
}

export type FarmProfileDraft = Omit<
  FarmProfile,
  "created_at" | "updated_at" | "app_package"
>

export interface FarmProfileSummary {
  id: string
  name: string
  picture: string
}

export interface UserProfile {
  id: string
  first_name: string
  last_name: string
  email: string
  phone_number: string
  farm: FarmProfileSummary
  picture: string | undefined
}

export type UserProfileUpdate = Omit<UserProfile, "id" | "email" | "farm">
