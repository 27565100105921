export enum NOTIFICATION_CATEGORIES_ENUM {
  ALL_NOTIFICATIONS = "all",
  REMINDERS = "R",
  ALERTS = "A",
  INFO = "I",
  MANDATORY = "M",
}

export enum NOTIFICATION_READ_ENUM {
  UNREAD = "unread",
  READ = "read",
}
