import React from "react"
import styles from "./task-card-pill.module.css"
import ChooseSomethingIcon from "@/assets/icons/finance/choose-something-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { type TaskCategory } from "@/features/tasks/api/task-categories-api.ts"

interface TaskCardPillProps {
  taskCategory: TaskCategory
}

const TaskCardPillCategoryInfo: React.FC<TaskCardPillProps> = ({
  taskCategory,
}) => {
  return (
    <>
      {taskCategory.id !== "" && (
        <div className={styles.categoryCardPill}>
          <div className={styles.categoryPillText}>{taskCategory.name}</div>
          {taskCategory.picture ? (
            <GenericPicture
              src={taskCategory.picture}
              alt={taskCategory.name}
              size={25}
              borderRadius={50}
            />
          ) : (
            <ChooseSomethingIcon size={25} />
          )}
        </div>
      )}
    </>
  )
}

export default TaskCardPillCategoryInfo
