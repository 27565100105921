import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./navigation-buttons.module.css"
import AddCalendarIcon from "@/assets/icons/gestational-calendar/add-calendar-icon.tsx"
import ShortBottomActionWrapper from "@/components/short-bottom-drawer/short-bottom-action-wrapper.tsx"
import { WHITE } from "@/utils/constants/colors-constants.ts"

interface GenerateCalendarButtonProps {
  onClick: () => void
}

const GenerateCalendarButton: React.FC<GenerateCalendarButtonProps> = ({
  onClick,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.buttonBottomWrapper}>
      <ShortBottomActionWrapper>
        <button className={styles.button} onClick={onClick}>
          <AddCalendarIcon color={WHITE} size={20} />
          {t("generateCalendar")}
        </button>
      </ShortBottomActionWrapper>
    </div>
  )
}

export default GenerateCalendarButton
