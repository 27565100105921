import isEqual from "lodash.isequal"
import { useCallback, useLayoutEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import { useDeleteAnimalMutation } from "@/features/farm/api/animals-api.ts"
import AnimalEditor from "@/features/farm/components/animal-editor/animal-editor.tsx"
import useGetAnimalDraft from "@/features/farm/hooks/use-get-animal-draft.ts"
import useUpdateAnimal from "@/features/farm/hooks/use-update-animal.ts"
import { validateAnimal } from "@/features/farm/types/animal-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { deleteAnimalDraft } from "@/redux/slices/animal-draft-slice.ts"
import { FARM_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

export const AnimalView = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { animalId } = useParams()
  const { draftAnimal, data, isLoading } = useGetAnimalDraft(animalId)
  const { updateAnimal } = useUpdateAnimal()
  const [deleteAnimalMutation] = useDeleteAnimalMutation()
  const { openModal } = useModal()
  const { handleBackNavigation } = useNavigationFallback()
  const dispatch = useDispatch()
  const hasChanges = !!draftAnimal && !!data && !isEqual(data, draftAnimal)
  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      if (!animalId) return
      dispatch(deleteAnimalDraft(animalId))
    }, DELETE_ENTRY_TIMEOUT)
  }, [animalId, dispatch])

  const handleAnimalMutation = async () => {
    const effectiveName = draftAnimal.name || draftAnimal.ain
    const animalData = { ...draftAnimal, name: effectiveName }

    if (!(await validateAnimal(animalData))) {
      return
    }

    if (animalId && data) {
      const diffData = getObjectDifference(data, animalData)
      updateAnimal({ id: animalId, ...diffData }, () => {
        deleteDraft()
        navigate(FARM_SCREEN_PATH, {
          replace: true,
        })
      })
    }
  }

  useLayoutEffect(() => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("continueWhereYouLeftOff"),
        content: t("youHaveUnsavedChanges"),
        onBackText: t("discard"),
        onActionText: t("continue"),
        onBackClick: () => {
          deleteDraft()
        },
      })
    }
    // We only want to show this modal when user first visits so we don't include the dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDraft, openModal, t])

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          handleBackNavigation()
          deleteDraft()
        },
      })
    } else {
      handleBackNavigation()
      deleteDraft()
    }
  }

  const deleteAnimal = () => {
    if (animalId) {
      toast
        .promise(deleteAnimalMutation(animalId).unwrap(), {
          pending: t("deletingAnimal"),
          success: t("animalDeleted"),
          error: t("errorDeletingAnimal"),
        })
        .then(() => {
          navigate(FARM_SCREEN_PATH)
          deleteDraft()
        })
    }
  }

  const handleAnimalDeletion = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteAnimalConfirmation"),
      content: t("animalWillBeDeleted"),
      onActionClick: deleteAnimal,
    })
  }

  return (
    <>
      <EditorTopBar
        text={"myAnimal"}
        onSave={handleAnimalMutation}
        onBackClick={handleBackClick}
        isModified={hasChanges}
      />

      {isLoading || !animalId || !draftAnimal ? (
        <LoadingAnimation />
      ) : (
        <PageContentWrapperLarge hasPadding={false}>
          <AnimalEditor animalId={animalId} draftAnimal={draftAnimal} />

          <DeleteButton
            onClick={handleAnimalDeletion}
            text={t("deleteAnimal")}
          />
        </PageContentWrapperLarge>
      )}
    </>
  )
}
