interface TransactionCardWrapperProps {
  onClick?: () => void
  children: React.ReactNode
}

const TransactionCardWrapper: React.FC<TransactionCardWrapperProps> = ({
  onClick,
  children,
}) => {
  return (
    <div
      className="mb-[10px] box-border flex min-h-[55px] w-full items-center justify-between gap-[10px] rounded-[8px] border border-[rgba(112,128,144,0.18)] bg-white px-[15px] py-[7px] shadow-[0_0_3px_rgba(0,0,0,0.1)] hover:shadow-[0_3px_6px_rgba(0,0,0,0.2)] focus:shadow-[0_3px_6px_rgba(0,0,0,0.2)] active:shadow-[0_3px_6px_rgba(0,0,0,0.2)]"
      onClick={onClick}
    >
      {children}
    </div>
  )
}

export default TransactionCardWrapper
