import { useTranslation } from "react-i18next"
import styles from "../settings-routes.module.css"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"
import { useGetMyFarmQuery } from "@/features/settings-account/api/account-api.ts"

const CompanyInfoPage = () => {
  const { t } = useTranslation()
  const { data: farmProfile } = useGetMyFarmQuery()

  return (
    <>
      <SettingsTopBar title={t("farmInfo")} />
      <div className={styles.settingsContent}>
        <div className={styles.card}>
          <div className={styles.cardItemName}>{t("addFarmName")}</div>
          <div className={styles.titleInput}>{farmProfile?.legal_name}</div>

          <div className={styles.cardItemName}>{t("addCui")}</div>
          <div className={styles.titleInput}>{farmProfile?.tin}</div>
        </div>
      </div>
    </>
  )
}

export default CompanyInfoPage
