import styles from "./gestation-calendar-action-button.module.css"

interface GestationCalendarActionButtonProps {
  onClick?: () => void
  text: string
  isDone?: boolean
  icon?: React.ReactNode
}

const GestationCalendarActionButton: React.FC<
  GestationCalendarActionButtonProps
> = ({ onClick, isDone, text, icon }) => {
  return (
    <button
      data-is-done={isDone}
      className={styles.activityButton}
      onClick={onClick}
    >
      {icon}
      {text}
    </button>
  )
}

export default GestationCalendarActionButton
