import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./notes-widget.module.css"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button"
import WidgetCard from "@/features/home/components/widget-card/widget-card"
import NotesGroup from "@/features/notes/components/notes-group/notes-group"
import { useFilteredAndGroupedNotes } from "@/features/notes/hooks/use-filter-and-group-notes.ts"
import { NEW_NOTE_SCREEN_PATH, NOTES_VIEW_PATH } from "@/utils/constants/routes"

interface NotesWidgetProps {
  notes?: string[]
  maxNotesNumberToDisplay?: number
  animalId?: string
}

const NotesWidget: React.FC<NotesWidgetProps> = ({
  notes,
  maxNotesNumberToDisplay = 6,
  animalId,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const filteredAndGroupedNotes = useFilteredAndGroupedNotes(
    notes,
    maxNotesNumberToDisplay,
  )

  const navigationString = useMemo(
    () =>
      animalId ? `${NEW_NOTE_SCREEN_PATH}/${animalId}` : NEW_NOTE_SCREEN_PATH,
    [animalId],
  )

  const addItemGoTo = () => {
    navigate(navigationString)
  }

  const hasWorkingNotes = useMemo(
    () =>
      filteredAndGroupedNotes &&
      !Object.values(filteredAndGroupedNotes).every(
        (array) => array.length === 0,
      ),
    [filteredAndGroupedNotes],
  )

  return (
    <WidgetCard
      title={t("notes")}
      navigateTo={NOTES_VIEW_PATH}
      {...(notes && notes.length > 0 && { addItemGoTo })}
      customStyles={
        !animalId
          ? {
              height: "fit-content",
              marginInline: 0,
              marginBlock: 0,
              width: "100%",
            }
          : {}
      }
    >
      {hasWorkingNotes ? (
        <div className={styles.notesWrapper}>
          <NotesGroup notes={filteredAndGroupedNotes} />
        </div>
      ) : (
        <EmptyContentCreateItemButton navigateTo={navigationString} />
      )}
    </WidgetCard>
  )
}

export default NotesWidget
