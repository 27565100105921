import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import { TIME_PERIOD } from "@/features/finance/types/finance-constants.ts"
import { type TransactionCategory } from "@/features/finance/types/transaction.ts"

const TRANSACTIONS_SUMMARY_BASE_URL = "transactions/summary/"

export interface TransactionSummary {
  period: string
  current_balance: number
  total_inflow: string
  total_outflow: string
  inflow_by_category: TransactionCategorySummary[]
  outflow_by_category: TransactionCategorySummary[]
}

export interface TransactionCategorySummary {
  total: string
  category: TransactionCategory
  transaction_count: string
}

interface TransactionSummaryRequest {
  period: string
  end_date?: string
  start_date?: string
}

export const transactionsSummaryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactionsSummaryByTimePeriod: builder.query<
      TransactionSummary,
      TransactionSummaryRequest | void
    >({
      query: (request) => ({
        url: TRANSACTIONS_SUMMARY_BASE_URL,
        params: {
          period: request?.period || TIME_PERIOD.PERIOD_ALL_TIME,
          end_date: request?.end_date,
          start_date: request?.start_date,
        },
      }),
      providesTags: () => [{ type: TagType.TransactionsSummary, id: "LIST" }],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTransactionsSummaryByTimePeriodQuery,
  useLazyGetTransactionsSummaryByTimePeriodQuery,
} = transactionsSummaryApi
