import React from "react"
import { useTranslation } from "react-i18next"
import ObjectNotFoundSvg from "@/assets/icons/error-handling/object-not-found.svg"
import TransactionsFilterTab from "@/components/filter-tab/transactions/transactions-filter-tab"
import GenericItemsGroupByTimeSpan from "@/components/grouping/generic-items-group-by-time-span.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar"
import { useGetTransactionsQuery } from "@/features/finance/api/finance-api"
import useGetInitialTransactionsFilter from "@/features/finance/hooks/use-get-initial-transactions-filter.ts"
import useTransactionFilter from "@/features/finance/hooks/use-transaction-filter"
import styles from "@/features/finance/routes/transaction-history-page/transaction-history-page.module.css"
import { groupItemsByTimeStampWithField } from "@/utils/helpers/time-grouping.ts"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import CountNumber from "@/features/farm/components/count-number/count-number.tsx"

const TransactionHistoryPage: React.FC = () => {
  const { t } = useTranslation()
  const { data: transactions, isLoading } = useGetTransactionsQuery()

  const initialFilter = useGetInitialTransactionsFilter()

  const {
    selections,
    selectedCategories,
    filteredTransactions,
    handleIncomeSelection,
    handleExpenseSelection,
    handleSelectedCategory,
    handleSearch,
  } = useTransactionFilter(transactions, initialFilter)

  const groupedTransactions =
    filteredTransactions &&
    groupItemsByTimeStampWithField(filteredTransactions, "date")

  return (
    <>
      <MobileTopBar title={t("transactionHistory")} />
      <PageContentWrapperLarge>
        <TransactionsFilterTab
          selections={selections}
          handleSearch={handleSearch}
          handleIncomeSelection={handleIncomeSelection}
          handleExpenseSelection={handleExpenseSelection}
          handleSelectedCategory={handleSelectedCategory}
          selectedCategories={selectedCategories}
        />
        <CountNumber
          count={transactions?.length || 0}
          text={t("transactions")}
        />
        {!isLoading && groupedTransactions && (
          <GenericItemsGroupByTimeSpan items={groupedTransactions} />
        )}

        {isLoading && <LoadingAnimation />}

        {!isLoading && filteredTransactions?.length === 0 && (
          <div className={styles.noTransactions}>
            <img
              src={ObjectNotFoundSvg}
              alt=""
              className={styles.notFoundImg}
            />
            <h3>{t("noTransactionsFound")}</h3>
          </div>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default TransactionHistoryPage
