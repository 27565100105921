import { useTranslation } from "react-i18next"
import { Drawer, DrawerContent } from "@/components/shadcn/drawer/drawer"
import useModal from "@/features/modals/hooks/use-modal.ts"
import styles from "./drawer-modal.module.css"
import { useEffect, useState } from "react"

interface DrawerModalProps {
  title: string
  content?: string
  onBackText?: string
  onBackClick?: () => void
  onActionText?: string
  onActionClick: () => void
}

const DrawerModal: React.FC<DrawerModalProps> = ({
  title,
  content,
  onBackClick,
  onBackText,
  onActionText,
  onActionClick,
}) => {
  const { t } = useTranslation()
  const { closeModal } = useModal()
  const [isModalOpen, setIsModalOpen] = useState(true)

  useEffect(() => {
    return () => setIsModalOpen(false)
  }, [])

  const getActionText = () => {
    return onActionText || t("confirm")
  }

  const getBackText = () => {
    return onBackText || t("back")
  }

  const handleBackClick = () => {
    setIsModalOpen(false)
    setTimeout(() => {
      if (onBackClick) {
        const result = onBackClick()
        return result
      } else {
        return closeModal()
      }
    }, 500)
  }

  const handleOnOpenChange = (state: boolean) => {
    if (state) return
    handleBackClick()
  }

  const handleActionClick = () => {
    closeModal()
    onActionClick()
  }

  return (
    <Drawer open={isModalOpen} onOpenChange={handleOnOpenChange}>
      <DrawerContent
        onBackAction={handleBackClick}
        style={{
          height: "fit-content",
        }}
      >
        <div className={styles.drawerContentWrraper}>
          <div className={styles.title}>{title}</div>
          {content && <div className={styles.content}>{content}</div>}
          <div className={styles.buttons}>
            <button className={styles.actionButton} onClick={handleActionClick}>
              {getActionText()}
            </button>
            <button className={styles.cancelButton} onClick={handleBackClick}>
              {getBackText()}
            </button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerModal
