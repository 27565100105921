import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import GenericButtonTrigger from "@/components/drawer-triggers/generic-button-trigger.tsx"
import GenericTypeSelectTrigger from "@/components/drawer-triggers/generic-type-select-trigger.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import AnimalEventTypeDrawerContent from "@/features/animal-events/components/animal-event-type-drawer/animal-event-type-drawer-content.tsx"
import { type AnimalEventType } from "@/features/animal-events/types/animal-events.ts"
import AddItemGenericDrawerSelectionRow from "@/components/generic-drawer-selection-row/add-item-generic-drawer-selection-row.tsx"
import AddEventTypeForm from "@/features/animal-events/components/add-event-type-form/add-event-type-form.tsx"

/**
 * DrawerTriggerTypes
 *
 * Choose Pill if you want to get a pill-like trigger, usually used for filtering options.
 * Choose Button if you want to get a button-like trigger, usually used for associating an event type to an entity.
 */
export enum DrawerTriggerTypes {
  Button = "Button",
  Pill = "Pill",
}

interface GenericTypeDrawerProps {
  selectedValue: string | undefined
  fallbackText: string
  onEventSelect: (eventType: string | undefined) => void
  eventTypesMap: Map<string, AnimalEventType[]>
  triggerType?: DrawerTriggerTypes
}

interface ReducedEventTypes {
  [key: string]: AnimalEventType
}

const AnimalEventsTypeDrawer: React.FC<GenericTypeDrawerProps> = ({
  selectedValue,
  onEventSelect,
  eventTypesMap,
  fallbackText,
  triggerType = DrawerTriggerTypes.Pill,
}) => {
  const eventTypesValues = [...eventTypesMap.values()]
  const flatEventTypesValues = eventTypesValues.flat(1)
  const { t } = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isAddEventTypeViewOpen, setIsAddEventTypeViewOpen] = useState<{
    isOpen: boolean
    eventTypeId: string | null
  }>({
    isOpen: false,
    eventTypeId: null,
  })

  const reducedEventTypesValues =
    flatEventTypesValues.reduce<ReducedEventTypes>(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr,
      }),
      {},
    )

  const currentEventType = selectedValue
    ? reducedEventTypesValues[selectedValue]
    : undefined

  const displayText = (currentValue: AnimalEventType | undefined) => {
    return currentValue ? t(currentValue.name) : t(fallbackText)
  }

  const handleEventTypeSelect = (eventType: string | undefined) => {
    onEventSelect(eventType)
    setIsDrawerOpen(false)
  }

  const handleBackClick = () => {
    setIsAddEventTypeViewOpen({
      isOpen: false,
      eventTypeId: null,
    })
  }

  const handleViewChange = (entityId: string | null) => {
    setIsAddEventTypeViewOpen({
      isOpen: true,
      eventTypeId: entityId,
    })
  }

  return (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
      <DrawerTrigger asChild>
        {triggerType === DrawerTriggerTypes.Pill ? (
          <GenericTypeSelectTrigger
            currentValue={currentEventType}
            fallbackText={fallbackText}
          />
        ) : (
          <GenericButtonTrigger
            currentValue={currentEventType?.name}
            displayText={() => displayText(currentEventType)}
          />
        )}
      </DrawerTrigger>
      <DrawerContent
        style={{
          height: "85%",
          width: "100%",
        }}
      >
        {isAddEventTypeViewOpen.isOpen ? (
          <AddEventTypeForm
            onBackClick={handleBackClick}
            eventId={isAddEventTypeViewOpen.eventTypeId}
          />
        ) : (
          <>
            <AnimalEventTypeDrawerContent
              selectedEvent={selectedValue}
              onEventSelect={handleEventTypeSelect}
              eventTypesMap={eventTypesMap}
              onViewChange={handleViewChange}
            />
            <AddItemGenericDrawerSelectionRow
              text={t("addEventType")}
              onClick={() => handleViewChange(null)}
            />
          </>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default AnimalEventsTypeDrawer
