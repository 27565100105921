import React from "react"
import { useTranslation } from "react-i18next"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import {
  type TaskClassification,
  taskClassificationCheckNotAllEmpty,
} from "@/features/tasks"
import TaskStatusGroup from "@/features/tasks/components/task-status-group/task-status-group.tsx"
import {
  BROWN,
  NOTIFICATION_BLUE,
  NOTIFICATION_RED,
} from "@/utils/constants/colors-constants.ts"
import { NEW_TASK_SCREEN_PATH } from "@/utils/constants/routes.ts"

export interface CurrentTasksListProps {
  filteredTasks: TaskClassification
}

export const TasksListCurrent: React.FC<CurrentTasksListProps> = ({
  filteredTasks,
}) => {
  const { t } = useTranslation()
  const hasTasks = taskClassificationCheckNotAllEmpty(filteredTasks)

  return (
    <>
      {hasTasks ? (
        <>
          {filteredTasks.dueTasks.length > 0 && (
            <TaskStatusGroup
              tasks={filteredTasks.dueTasks}
              title={t("dueTasks")}
              backgroundColor={NOTIFICATION_RED}
            />
          )}
          {filteredTasks.currentTasks.length > 0 && (
            <TaskStatusGroup
              tasks={filteredTasks.currentTasks}
              title={t("currentTasks")}
              backgroundColor={BROWN}
            />
          )}
          {filteredTasks.futureTasks.length > 0 && (
            <TaskStatusGroup
              tasks={filteredTasks.futureTasks}
              title={t("futureTasks")}
              backgroundColor={NOTIFICATION_BLUE}
            />
          )}
        </>
      ) : (
        <EmptyContentCreateItemButton navigateTo={NEW_TASK_SCREEN_PATH} />
      )}
    </>
  )
}
