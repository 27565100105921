import { useTranslation } from "react-i18next"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"

export const useGoBackToSettingsHandler = (condition: boolean) => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const { handleBackNavigation } = useNavigationFallback()

  const handleBackClick = () => {
    if (condition) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => handleBackNavigation(),
      })
    } else {
      handleBackNavigation()
    }
  }

  return {
    handleBackClick,
  }
}
