import NotesLayoutDesktop from "@/features/notes/routes/notes-layout/notes-layout-desktop/notes-layout-desktop.tsx"
import NotesLayoutMobile from "@/features/notes/routes/notes-layout/notes-layout-mobile/notes-layout-mobile.tsx"
import useIsDesktop from "@/hooks/use-is-desktop.ts"

const NotesLayout = () => {
  const isDesktop = useIsDesktop()
  return isDesktop ? <NotesLayoutDesktop /> : <NotesLayoutMobile />
}

export default NotesLayout
