import React from "react"

const DashboardIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.029"
      height="31.029"
      viewBox="19.998 8.436 31.029 31.029"
      {...props}
    >
      <g data-name="Dashboard Home Icon">
        <g data-name="Home Circle">
          <path
            d="M20.52 24.423a15 15 0 1 1 .003.1z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Ellipse 96"
          />
        </g>
        <g data-name="Home Icon">
          <path
            d="M27.047 23.22c-.072-2.081-.109-3.121.295-3.999.403-.877 1.176-1.44 2.723-2.566l1.63-1.187c1.636-1.19 2.453-1.785 3.378-1.817.925-.032 1.782.504 3.496 1.577l1.71 1.07c1.621 1.015 2.432 1.523 2.895 2.37.464.847.5 1.888.573 3.968l.048 1.383c.124 3.547.186 5.32-.754 6.456-.94 1.136-2.514 1.19-5.663 1.3l-3.34.117c-3.149.11-4.723.165-5.74-.902-1.017-1.068-1.079-2.841-1.202-6.388l-.049-1.383Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            stroke="var(--brown)"
            fill="transparent"
            data-name="Path 1160"
          />
          <path
            d="m37.18 28.117-3.199.112"
            strokeLinejoin="round"
            strokeLinecap="round"
            stroke="var(--brown)"
            fill="transparent"
            data-name="Path 1161"
          />
        </g>
      </g>
    </svg>
  )
}

export default DashboardIcon
