import React from "react"
import { useTranslation } from "react-i18next"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import { AnimalFilterTab } from "@/components/filter-tab/animal-filter-tab/animal-filter-tab.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PendingCircle from "@/components/pending-circle/pending-circle.tsx"
import styles from "@/components/shadcn/drawer/custom-drawer.module.css"
import DrawerTopBar from "@/components/shadcn/drawer/drawer-top-bar.tsx"
import AnimalCard from "@/features/farm/components/animal-card/animal-card.tsx"
import useAnimalFilterURL from "@/features/farm/hooks/use-animal-filter-URL.ts"
import { BROWN } from "@/utils/constants/colors-constants.ts"

interface DrawerContentProps {
  currentValue: string[] | string
  onSaveClick: (animalIDs: string[]) => void
  isNested?: boolean
  placeholder: string
  selectionType?: "single" | "multiple"
}

const DrawerAnimalContent: React.FC<DrawerContentProps> = ({
  currentValue,
  onSaveClick,
  placeholder,
  selectionType = "multiple",
}) => {
  const { t } = useTranslation()

  const {
    selections,
    animals,
    handleAgeSelection,
    handleGenderSelection,
    handleSpeciesSelection,
    handleSearchQuery,
    handleStatusSelection,
  } = useAnimalFilterURL()

  const isSelected = (animalId: string) => {
    return currentValue.includes(animalId)
  }

  const handleAnimalSelection = (animalId: string) => {
    if (isSelected(animalId) && selectionType === "multiple") {
      if (typeof currentValue !== "string") {
        const newArray = currentValue.filter((id) => id !== animalId)
        onSaveClick(newArray)
      }
    } else if (isSelected(animalId) && selectionType === "single") {
      onSaveClick([])
    } else if (selectionType === "multiple") {
      onSaveClick([...currentValue, animalId])
    } else {
      onSaveClick([animalId])
    }
  }

  return (
    <>
      <DrawerTopBar
        searchQuery={selections.searchQuery}
        setSearchQuery={handleSearchQuery}
        placeholder={placeholder}
      />
      <div className={styles["filter-tab"]}>
        <AnimalFilterTab
          selections={selections}
          handleAgeChange={handleAgeSelection}
          handleGenderChange={handleGenderSelection}
          handleSpeciesChange={handleSpeciesSelection}
          handleStatusChange={handleStatusSelection}
        />
      </div>
      <div className={styles["slide-up-content-wrapper"]}>
        <div className={styles["slide-up-content"]}>
          {!animals ||
            (animals.length === 0 && (
              <NoResultsFound message={t("noAnimalsFound")} />
            ))}
          {animals.map((animal) => (
            <div key={animal.id}>
              <div
                className={
                  isSelected(animal.id)
                    ? styles["animal-card-selected"]
                    : styles["animal-card"]
                }
                onClick={() => handleAnimalSelection(animal.id)}
              >
                {isSelected(animal.id) ? (
                  <CircleTickIcon fill={BROWN} size={24} />
                ) : (
                  <PendingCircle />
                )}
                <AnimalCard animal={animal} />
              </div>
              <div className={styles["separator"]}></div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default DrawerAnimalContent
