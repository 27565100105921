import React from "react"
import { useTranslation } from "react-i18next"
import CalvesCountIcon from "@/assets/icons/ruminant-details/calves-count-icon.tsx"
import GestationCountIcon from "@/assets/icons/ruminant-details/gestation-count-icon.tsx"
import InseminationCountIcon from "@/assets/icons/ruminant-details/insemination-count-icon.tsx"
import LactationCountIcon from "@/assets/icons/ruminant-details/lactation-count-icon.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import { type Animal, ANIMAL_GENDERS } from "@/features/farm"
import { AnimalField } from "@/features/farm/components/animal-field/animal-field.tsx"

interface RuminantDetailsProps {
  selectedAnimal: Animal
}

export const RuminantDetails: React.FC<RuminantDetailsProps> = ({
  selectedAnimal,
}) => {
  const { t } = useTranslation()

  return (
    <OverflowMenuItem>
      {selectedAnimal.gender !== ANIMAL_GENDERS.MALE.label && (
        <>
          <AnimalField
            text={t(`insemination_count`)}
            value={selectedAnimal.ruminant_fields?.insemination_count}
          >
            <InseminationCountIcon />
          </AnimalField>

          <AnimalField
            text={t(`gestation_count`)}
            value={selectedAnimal.ruminant_fields?.gestation_count}
          >
            <GestationCountIcon />
          </AnimalField>

          <AnimalField
            text={t(`calves_count`)}
            value={selectedAnimal.ruminant_fields?.calves_count}
          >
            <CalvesCountIcon />
          </AnimalField>

          <AnimalField
            text={t("lactation_count")}
            value={selectedAnimal.ruminant_fields?.lactation_count}
          >
            <LactationCountIcon />
          </AnimalField>
        </>
      )}
    </OverflowMenuItem>
  )
}
