import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import AnimalEditor from "@/features/farm/components/animal-editor/animal-editor.tsx"
import useCreateAnimal from "@/features/farm/hooks/use-create-animal.ts"
import useInitializeAnimalDraft from "@/features/farm/hooks/use-initialize-animal-draft.ts"
import { validateAnimal } from "@/features/farm/types/animal-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { deleteAnimalDraft } from "@/redux/slices/animal-draft-slice.ts"
import { FARM_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"
import { NEW_ENTITY } from "@/utils/types/misc-types.ts"

export const NewAnimalView = () => {
  const navigate = useNavigate()
  const { createAnimal } = useCreateAnimal()
  const { handleBackNavigation } = useNavigationFallback()
  const dispatch = useDispatch()
  const { animalDraft, hasChanges } = useInitializeAnimalDraft()
  const { openModal } = useModal()
  const { t } = useTranslation()

  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      dispatch(deleteAnimalDraft(NEW_ENTITY))
    }, DELETE_ENTRY_TIMEOUT)
  }, [dispatch])

  const handleAnimalMutation = async () => {
    const effectiveName = animalDraft.name || animalDraft.ain
    const animalData = { ...animalDraft, name: effectiveName }
    if (!(await validateAnimal(animalData))) {
      return
    }
    createAnimal(animalData, () => {
      navigate(FARM_SCREEN_PATH)
      deleteDraft()
    })
  }

  useEffect(() => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("continueWhereYouLeftOff"),
        content: t("youHaveUnsavedChanges"),
        onBackText: t("discard"),
        onActionText: t("continue"),
        onBackClick: () => {
          deleteDraft()
        },
      })
    }
    // Only run this effect on the first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDraft, openModal, t])

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          handleBackNavigation()
          deleteDraft()
        },
      })
    } else {
      handleBackNavigation()
      deleteDraft()
    }
  }

  return (
    <>
      <EditorTopBar
        text={"newAnimal"}
        onSave={handleAnimalMutation}
        onBackClick={handleBackClick}
        isModified={hasChanges}
      />

      <PageContentWrapperLarge hasPadding={false}>
        {!animalDraft ? (
          <LoadingAnimation />
        ) : (
          <AnimalEditor animalId={NEW_ENTITY} draftAnimal={animalDraft} />
        )}
      </PageContentWrapperLarge>
    </>
  )
}
