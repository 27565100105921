import React from "react"

const DetailIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="2159 -1154 30 30"
    >
      <g data-name="CompositeLayer">
        <path
          d="M2165.234-1139a8.766 8.766 0 1 1 17.532 0 8.766 8.766 0 0 1-17.532 0Zm8.766-7.418a7.418 7.418 0 1 0 0 14.836 7.418 7.418 0 0 0 0-14.836Z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Path 1289"
        />
        <path
          d="M2169.28-1139.135a.54.54 0 0 1 .54-.54h3.506v-3.506a.538.538 0 0 1 .54-.54h.268a.54.54 0 0 1 .54.54v3.506h3.506a.538.538 0 0 1 .54.54v.27a.54.54 0 0 1-.54.539h-3.506v3.507a.54.54 0 0 1-.54.54l-.268-.001a.54.54 0 0 1-.54-.54v-3.506h-3.506a.54.54 0 0 1-.54-.54v-.27Z"
          fill="#fff"
          fillRule="evenodd"
          data-name="Path 1288"
        />
        <path
          d="M2166-1154h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7h-16a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="var(--pale-grey)"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <g data-name="add-create-document-extra-file-new-svgrepo-com">
          <path
            d="M2169.28-1139.135a.54.54 0 0 1 .54-.54h3.506v-3.506a.538.538 0 0 1 .54-.54h.268a.54.54 0 0 1 .54.54v3.506h3.506a.538.538 0 0 1 .54.54v.27a.54.54 0 0 1-.54.539h-3.506v3.507a.54.54 0 0 1-.54.54l-.268-.001a.54.54 0 0 1-.54-.54v-3.506h-3.506a.54.54 0 0 1-.54-.54v-.27Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 1288"
          />
          <path
            d="M2165.234-1139a8.766 8.766 0 1 1 17.532 0 8.766 8.766 0 0 1-17.532 0Zm8.766-7.418a7.418 7.418 0 1 0 0 14.836 7.418 7.418 0 0 0 0-14.836Z"
            fill="#fff"
            fillRule="evenodd"
            data-name="Path 1289"
          />
        </g>
      </g>
    </svg>
  )
}

export default DetailIcon
