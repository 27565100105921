import { useMemo } from "react"
import styles from "./notifications-button.module.css"
import NotificationMenuIcon from "@/assets/icons/misc/notification-icon.tsx"
import BottomMenuItem from "@/components/bottom-menu/bottom-menu-item/bottom-menu-item.tsx"
import { useGetNotificationsQuery } from "@/features/notifications/api/notifications-api.ts"
import { NOTIFICATIONS_PATH } from "@/utils/constants/routes.ts"

const NotificationsButton = () => {
  const { data: notifications } = useGetNotificationsQuery()

  const unreadCount = useMemo(() => {
    return (
      notifications?.filter((notification) => !notification.read_at).length || 0
    )
  }, [notifications])

  return (
    <BottomMenuItem
      isActive={location.pathname.includes(NOTIFICATIONS_PATH)}
      to={NOTIFICATIONS_PATH}
      label="notifications"
      alt="notifications"
    >
      <>
        <NotificationMenuIcon
          size={24}
          is_active={location.pathname.includes(NOTIFICATIONS_PATH)}
        />
        {unreadCount > 0 && (
          <div className={styles.notificationCount}>{unreadCount}</div>
        )}
      </>
    </BottomMenuItem>
  )
}

export default NotificationsButton
