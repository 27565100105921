const TermsIcon = ({ size = 41, color = "var(--brown)", ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 27.015 34.591"
      {...props}
    >
      <g id="Blank" transform="translate(0.25 0.25)">
        <path
          id="Path_786"
          data-name="Path 786"
          d="M19.036,25.674a.53.53,0,0,0,.53.53H32.294a.53.53,0,0,0,0-1.061H19.567A.53.53,0,0,0,19.036,25.674Z"
          transform="translate(-12.654 -11.734)"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
        />
        <path
          id="Path_787"
          data-name="Path 787"
          d="M32.294,35.144H19.567a.53.53,0,0,0,0,1.061H32.294a.53.53,0,0,0,0-1.061Z"
          transform="translate(-12.654 -16.431)"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
        />
        <path
          id="Path_788"
          data-name="Path 788"
          d="M32.294,45.144H19.567a.53.53,0,1,0,0,1.061H32.294a.53.53,0,1,0,0-1.061Z"
          transform="translate(-12.654 -21.128)"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
        />
        <path
          id="Path_789"
          data-name="Path 789"
          d="M33.328,8.232,25.093.012a.549.549,0,0,0-.383-.155H8.64A1.635,1.635,0,0,0,7,1.444V32.135a1.884,1.884,0,0,0,.537,1.251,1.85,1.85,0,0,0,1.224.562H31.874a1.722,1.722,0,0,0,1.147-.539,1.782,1.782,0,0,0,.493-1.192V8.607A.565.565,0,0,0,33.328,8.232Zm-8.784-7.1,7.362,7.363H24.525C24.57,3.721,24.572,2.066,24.544,1.131Zm7.91,31.087a.753.753,0,0,1-.183.442.685.685,0,0,1-.4.228H8.762a.813.813,0,0,1-.475-.251.845.845,0,0,1-.226-.5V1.444A.572.572,0,0,1,8.636.918H23.462c.026,1.337.011,5.352-.016,7.949a.73.73,0,0,0,.16.455.643.643,0,0,0,.383.234h8.465Z"
          transform="translate(-7 0.144)"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  )
}

export default TermsIcon
