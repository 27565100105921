import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./animal-status-card.module.css"
import {
  type ANIMAL_STATUS_ENUM,
  ANIMAL_STATUSES,
  getAnimalStatusColor,
} from "@/features/farm"
import { FARM_SCREEN_PATH } from "@/utils/constants/routes.ts"
interface AnimalStatusCardProps {
  status: ANIMAL_STATUS_ENUM
  count: number
}

export const AnimalStatusCard = ({ status, count }: AnimalStatusCardProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const navigateToFiltered = (animalStatus: ANIMAL_STATUS_ENUM) => {
    navigate(FARM_SCREEN_PATH + "?status=" + animalStatus)
  }

  return (
    <div
      onClick={() => navigateToFiltered(status)}
      className={styles["animal-status-card"]}
      style={{ backgroundColor: getAnimalStatusColor(status) }}
    >
      <div className={styles["icon"]}>{ANIMAL_STATUSES[status]}</div>
      <div className={styles["text"]}>{t(status)}</div>
      <div className={styles["count"]}>
        {count} {t("heads")}
      </div>
    </div>
  )
}

export default AnimalStatusCard
