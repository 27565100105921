import React from "react"
import { useTranslation } from "react-i18next"
import CalvesCountIcon from "@/assets/icons/ruminant-details/calves-count-icon.tsx"
import GestationCountIcon from "@/assets/icons/ruminant-details/gestation-count-icon.tsx"
import InseminationCountIcon from "@/assets/icons/ruminant-details/insemination-count-icon.tsx"
import LactationCountIcon from "@/assets/icons/ruminant-details/lactation-count-icon.tsx"
import InputNumberFieldWithIcon from "@/components/text-card-wrapper-with-icon/input-number-field-with-icon.tsx"
import { ANIMAL_GENDERS, type AnimalDraft } from "@/features/farm"

interface RuminantEditorProps {
  animal: AnimalDraft
  isReadOnly: boolean
  onChangeRuminant: (animal: AnimalDraft) => void
}

export const RuminantEditor: React.FC<RuminantEditorProps> = ({
  animal,
  isReadOnly,
  onChangeRuminant,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {animal.gender !== ANIMAL_GENDERS.MALE.label && (
        <>
          <InputNumberFieldWithIcon
            icon={<InseminationCountIcon />}
            name={t("insemination_count")}
            isReadOnly={isReadOnly}
            value={animal.ruminant_fields?.insemination_count}
            onChange={(newValue) => {
              onChangeRuminant({
                ...animal,
                ruminant_fields: {
                  ...animal.ruminant_fields,
                  insemination_count: newValue,
                },
              })
            }}
          />
          <InputNumberFieldWithIcon
            icon={<GestationCountIcon />}
            name={t("gestation_count")}
            isReadOnly={isReadOnly}
            value={animal.ruminant_fields?.gestation_count}
            onChange={(newValue) => {
              onChangeRuminant({
                ...animal,
                ruminant_fields: {
                  ...animal.ruminant_fields,
                  gestation_count: newValue,
                },
              })
            }}
          />
          <InputNumberFieldWithIcon
            icon={<CalvesCountIcon />}
            name={t("calves_count")}
            isReadOnly={isReadOnly}
            value={animal.ruminant_fields?.calves_count}
            onChange={(newValue) => {
              onChangeRuminant({
                ...animal,
                ruminant_fields: {
                  ...animal.ruminant_fields,
                  calves_count: newValue,
                },
              })
            }}
          />
          <InputNumberFieldWithIcon
            icon={<LactationCountIcon />}
            name={t("lactation_count")}
            isReadOnly={isReadOnly}
            value={animal.ruminant_fields?.lactation_count}
            onChange={(newValue) => {
              onChangeRuminant({
                ...animal,
                ruminant_fields: {
                  ...animal.ruminant_fields,
                  lactation_count: newValue,
                },
              })
            }}
          />
        </>
      )}
    </>
  )
}
