import { format } from "date-fns"
import { CURRENCY_ENUM } from "@/features/finance/types/finance-constants.ts"

export interface Transaction {
  id: string
  amount: string
  currency: string
  date: string
  description: string
  category: string
}

export type TransactionDraft = Omit<Transaction, "id">

export interface TransactionCategory {
  name: string
  id: string
  color?: string
  farm?: string
  picture: string | null
  is_outflow: boolean
}

export const initialTransactionState = {
  amount: "",
  currency: CURRENCY_ENUM.RON,
  date: format(new Date(), "yyyy-MM-dd"),
  description: "",
  category: "",
}
