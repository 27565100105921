import { format } from "date-fns"
import { ro } from "date-fns/locale"
import { useNavigate } from "react-router-dom"
import styles from "./finance-widget.module.css"
import money from "@/assets/images/money_RON.png"
import { useGetTransactionsSummaryByTimePeriodQuery } from "@/features/finance/api/finance-summary-api.ts"
import WidgetCardAdjustable from "@/features/home/components/widget-card-adjustable/widget-card-adjustable.tsx"
import {
  FINANCIAL_SCREEN_PATH,
  NEW_TRANSACTION_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

export default function FinanceWidget() {
  const navigate = useNavigate()

  const {
    data: financeSummary,
    isLoading,
    isSuccess,
  } = useGetTransactionsSummaryByTimePeriodQuery()

  const getCurrentMonthYear = () => {
    const now = new Date()
    return format(now, "MMMM yyyy", { locale: ro })
  }

  return (
    <WidgetCardAdjustable
      addItemGoTo={() => navigate(NEW_TRANSACTION_SCREEN_PATH)}
      title={"financialJournal"}
    >
      <div
        className={styles["inner-wrapper"]}
        onClick={() => navigate(FINANCIAL_SCREEN_PATH)}
      >
        {!isLoading && isSuccess && (
          <div className={styles["left-side"]}>
            <div className={styles["currency-big"]}>
              {getCurrentMonthYear()}
            </div>

            <div>
              <div className={styles["left-side-row"]}>
                <p className={styles["income"]}>
                  {financeSummary.total_inflow}
                </p>{" "}
                <p className={styles["currency"]}>RON</p>
              </div>
              <div className={styles["left-side-row"]}>
                <p className={styles["outcome"]}>
                  {financeSummary.total_outflow}
                </p>
                <p className={styles["currency"]}>RON</p>
              </div>
              <div className={styles["left-side-row"]}>
                <p className={styles["balance"]}>
                  {financeSummary.current_balance}
                </p>
                <p className={styles["currency-big"]}>RON</p>
              </div>
            </div>
          </div>
        )}

        <img src={money} alt="" className={styles["financial-image"]} />
      </div>
    </WidgetCardAdjustable>
  )
}
