import React from "react"
import styles from "./widget-card-adjustable.module.css"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"

interface WidgetCardProps {
  title: string
  children: React.ReactNode
  navigateTo?: string
  addItemGoTo?: () => void
}

const WidgetCardAdjustable: React.FC<WidgetCardProps> = ({
  title,
  children,
  navigateTo,
  addItemGoTo,
}) => {
  return (
    <div className={styles["widget-card"]}>
      <WidgetHeader
        title={title}
        navigateTo={navigateTo}
        addItemGoTo={addItemGoTo}
      />
      <div className={styles["widget-content"]}>{children}</div>
    </div>
  )
}

export default WidgetCardAdjustable
