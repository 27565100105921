import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "../filter-tab.module.css"
import CustomSelectPill from "@/components/custom-select/custom-select-pill.tsx"
import CustomToggle from "@/components/custom-toggle/custom-toggle.tsx"
import { notificationCategories } from "@/features/notifications/types/notification-utils.tsx"
import {
  NOTIFICATION_CATEGORIES_ENUM,
  NOTIFICATION_READ_ENUM,
} from "@/features/notifications/types/notifications-enum.ts"

interface FilterTabProps {
  initialFilter: any
  onFilterChange: (filter: NotificationFilter) => void
}

export interface NotificationFilter {
  notificationCategory: string
  notificationReadStatus: string
}

export const initialNotificationFilter: NotificationFilter = {
  notificationCategory: NOTIFICATION_CATEGORIES_ENUM.ALL_NOTIFICATIONS,
  notificationReadStatus: NOTIFICATION_READ_ENUM.UNREAD,
}

export const NotificationsFilterTab: React.FC<FilterTabProps> = ({
  onFilterChange,
  initialFilter,
}) => {
  const { t } = useTranslation()
  const [selections, setSelections] = useState<NotificationFilter>(
    initialFilter || initialNotificationFilter,
  )

  useEffect(() => {
    onFilterChange(selections)
  }, [onFilterChange, selections])

  return (
    <div className={styles["horizontal-scroll"]}>
      <div className={styles["filter-flex"]}>
        <CustomSelectPill
          options={notificationCategories}
          onSelectionChange={(selection) => {
            setSelections({
              ...selections,
              notificationCategory: selection,
            })
          }}
        />

        <CustomToggle
          title={t("unread")}
          isSelected={
            selections.notificationReadStatus === NOTIFICATION_READ_ENUM.UNREAD
          }
          onChange={(selection) => {
            setSelections({
              ...selections,
              notificationReadStatus: selection
                ? NOTIFICATION_READ_ENUM.UNREAD
                : NOTIFICATION_READ_ENUM.READ,
            })
          }}
        />
      </div>
    </div>
  )
}
