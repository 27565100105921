import { skipToken } from "@reduxjs/toolkit/query"
import { useLayoutEffect } from "react"
import { useGetTaskByIdQuery } from "@/features/tasks/api/tasks-api.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { setTaskDraft } from "@/redux/slices/task-draft-slice.ts"

const useGetTaskDraft = (taskId?: string) => {
  const { data, isLoading } = useGetTaskByIdQuery(taskId || skipToken)
  const dispatch = useAppDispatch()

  const taskDraft = useAppSelector(
    (state) => state.taskDraftSlice.drafts[taskId || ""],
  )

  useLayoutEffect(() => {
    if (data && taskId && !taskDraft) {
      dispatch(setTaskDraft({ id: taskId, draft: data }))
    }
  }, [data, dispatch, taskDraft, taskId])

  return { taskDraft, data, isLoading }
}

export default useGetTaskDraft
