import React from "react"

const GoToIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.246"
      height="13.86"
      viewBox="0 0 15.246 13.86"
      {...props}
    >
      <path
        id="arrow-shape-turn-right-svgrepo-com"
        d="M8.67,2.1a1.219,1.219,0,0,1,1.337.245l5.864,5.695a1.241,1.241,0,0,1,0,1.778l-5.864,5.695A1.221,1.221,0,0,1,8.13,15.3a1.243,1.243,0,0,1-.2-.676V12.41a6.8,6.8,0,0,0-3.343.838A9.461,9.461,0,0,0,2.232,15.6.693.693,0,0,1,1,15.167,12.657,12.657,0,0,1,2.493,8.473,6.5,6.5,0,0,1,7.93,5.487V3.235a1.243,1.243,0,0,1,.2-.676A1.228,1.228,0,0,1,8.67,2.1Z"
        transform="translate(-1 -2)"
        fill="#414141"
      />
    </svg>
  )
}

export default GoToIcon
