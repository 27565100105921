import { useTranslation } from "react-i18next"
import TikTokIcon from "@/assets/icons/misc/tik-tok-icon.tsx"
import FacebookIcon from "@/assets/icons/settings/facebook-icon.tsx"
import InstagramIcon from "@/assets/icons/settings/instagram-icon.tsx"
import LinkIcon from "@/assets/icons/settings/link-icon.tsx"
import styles from "@/features/modals/base-modal/base-modal.module.css"
import useModal from "@/features/modals/hooks/use-modal.ts"

const ShareAppModal = () => {
  const { t } = useTranslation()
  const { closeModal } = useModal()
  const shareUrl = "https://www.farmplanner.ro/"

  function handleShare() {
    if (navigator.share) {
      navigator
        .share({
          title: "Farm Planner",
          text: "Optimizează-ți ferma eficient și intuitiv cu Farm Planner - aliatul tău tehnologic pentru o agricultură de precizie!",
          url: shareUrl,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing", error))
    } else {
      alert("Web Share API is not supported in your browser.")
    }
  }

  function handleCopy() {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        alert(t("urlCopiedSuccess"))
      })
      .catch((error) => {
        console.error("Error copying text to clipboard: ", error)
      })
  }

  return (
    <div className={styles.backOverlay} onClick={closeModal}>
      <div
        className={styles.confirmationCardCenter}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <div className={styles.backConfirmation}>{t("recommend")}</div>
        <div className={styles.linkFlex} onClick={handleCopy}>
          <i className={styles.link}>www.farmplanner.ro</i>
          <LinkIcon />
        </div>

        {/* We should link the pages not share on them */}
        <div>{t("recommendUsing")}</div>
        <div className={styles.iconsFlex} onClick={handleShare}>
          <FacebookIcon />
          <TikTokIcon />
          <InstagramIcon />
        </div>
      </div>
    </div>
  )
}

export default ShareAppModal
