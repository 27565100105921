import React from "react"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import styles from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.module.css"
import { DrawerClose } from "@/components/shadcn/drawer/drawer.tsx"

interface GenericTypeDrawerHeaderProps {
  title: string
  onSaveClick?: () => void
  onBackClick?: () => void
  closeOnSave?: boolean
}

const GenericTypeDrawerHeader: React.FC<GenericTypeDrawerHeaderProps> = ({
  title,
  onBackClick,
  onSaveClick,
  closeOnSave = true,
}) => {
  return (
    <div className={styles.drawerFinanceContentHeader}>
      <div className={styles.left}>
        {onBackClick && (
          <div className={styles.actionIcon} onClick={onBackClick}>
            <BackIcon />
          </div>
        )}
        <p className={styles.headerTitle}>{title}</p>
      </div>
      {onSaveClick &&
        (closeOnSave ? (
          <DrawerClose asChild onClick={onSaveClick}>
            <CircleTickIcon size={30} />
          </DrawerClose>
        ) : (
          <div onClick={onSaveClick}>
            <CircleTickIcon size={30} />
          </div>
        ))}
    </div>
  )
}

export default GenericTypeDrawerHeader
