import { useGetAnimalsQuery } from "@/features/farm/api/animals-api.ts"
import { ANIMAL_GENDERS } from "@/features/farm"

const useGetAllAnimalsStatuses = () => {
  let healthCount = 0
  let underTreatmentCount = 0
  let pregnantCount = 0
  let lactatingCount = 0
  let dryCount = 0
  let heatCount = 0

  const { data: allAnimals } = useGetAnimalsQuery()
  allAnimals &&
    allAnimals.forEach((animal) => {
      if (animal.is_healthy) {
        healthCount += 1
      }
      if (animal.is_under_treatment) {
        underTreatmentCount += 1
      }
      if (animal.ruminant_fields?.is_pregnant) {
        pregnantCount += 1
      }
      if (animal.ruminant_fields?.is_lactating) {
        lactatingCount += 1
      }
      if (animal.ruminant_fields?.is_in_heats) {
        heatCount += 1
      }
      if (
        !animal.ruminant_fields?.is_lactating &&
        animal.gender === ANIMAL_GENDERS.FEMALE.label
      ) {
        dryCount += 1
      }
    })

  return {
    healthCount,
    underTreatmentCount,
    pregnantCount,
    lactatingCount,
    heatCount,
    dryCount,
  }
}

export default useGetAllAnimalsStatuses
