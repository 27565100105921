import React from "react"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const InstagramIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || "34"}
      height={props.size || "34"}
      viewBox="0 0 33.968 34"
      {...props}
    >
      <path
        id="instagram-svgrepo-com"
        d="M21,12.278a8.7,8.7,0,1,0,3.341.658A8.721,8.721,0,0,0,21,12.278Zm0,14.4a5.679,5.679,0,1,1,4.016-1.663A5.679,5.679,0,0,1,21,26.678ZM32.124,11.917a2.127,2.127,0,1,1-.623-1.5A2.127,2.127,0,0,1,32.124,11.917Zm5.764,2.127a10.018,10.018,0,0,0-2.744-7.126,10.018,10.018,0,0,0-7.126-2.808c-2.765-.128-11.273-.128-14.038,0A10.061,10.061,0,0,0,6.855,6.855,10.018,10.018,0,0,0,4.111,13.98c-.128,2.765-.128,11.273,0,14.038a10.018,10.018,0,0,0,2.744,7.126,10.061,10.061,0,0,0,7.126,2.744c2.808.17,11.231.17,14.038,0a10.018,10.018,0,0,0,7.126-2.744,10.018,10.018,0,0,0,2.744-7.126c.128-2.765.128-11.273,0-14.038ZM34.272,31.06a5.743,5.743,0,0,1-3.233,3.233A38.278,38.278,0,0,1,21,34.974a38.1,38.1,0,0,1-10.018-.681A5.743,5.743,0,0,1,7.748,31.06c-.893-2.255-.7-7.572-.7-10.04s-.191-7.806.7-10.04a5.637,5.637,0,0,1,3.233-3.254A38.1,38.1,0,0,1,21,7.046a38.287,38.287,0,0,1,10.04.681,5.743,5.743,0,0,1,3.233,3.233c.893,2.255.681,7.572.681,10.04s.213,7.806-.681,10.04Z"
        transform="translate(-4.015 -4.015)"
        fill={props.color || "var(--brown)"}
      />
    </svg>
  )
}

export default InstagramIcon
