import { useMemo } from "react"
import { useGetNotesQuery } from "@/features/notes/api/notes-api"
import {
  groupItemsByTimeStampWithField,
  initialItemsTimeGroups,
} from "@/utils/helpers/time-grouping"

export const useFilteredAndGroupedNotes = (
  notes?: string[],
  maxNotesNumberToDisplay = 6,
) => {
  const { data: allNotes = [] } = useGetNotesQuery()

  const filteredAndGroupedNotes = useMemo(() => {
    const filteredNotes = notes
      ? allNotes.filter((note) => note?.id && notes.includes(note.id))
      : allNotes

    const limitedNotes = filteredNotes.slice(0, maxNotesNumberToDisplay)

    return limitedNotes.length
      ? groupItemsByTimeStampWithField(limitedNotes, "updated_at")
      : initialItemsTimeGroups
  }, [allNotes, notes, maxNotesNumberToDisplay])

  return filteredAndGroupedNotes
}
