import { BROWN, DARK_GREY } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuNotesIcon: React.FC<GeneralSvgProps> = ({ is_active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="148 277.773 46.771 47.76"
    >
      <g data-name="Group 2766">
        <g data-name="Path 1405">
          <path
            d="m191.732 303.201 1.237-4.613c1.443-5.386 2.164-8.079 1.62-10.409a9.552 9.552 0 0 0-2.772-4.803c-1.747-1.636-4.44-2.357-9.825-3.8-5.386-1.444-8.079-2.165-10.409-1.622a9.552 9.552 0 0 0-4.803 2.773c-1.4 1.496-2.13 3.685-3.218 7.664-.182.668-.375 1.386-.582 2.16v.001l-1.236 4.614c-1.444 5.386-2.165 8.078-1.622 10.409a9.553 9.553 0 0 0 2.773 4.803c1.747 1.636 4.44 2.357 9.825 3.8 4.855 1.3 7.521 2.015 9.706 1.746.24-.03.473-.07.703-.125a9.552 9.552 0 0 0 4.803-2.773c1.636-1.746 2.357-4.439 3.8-9.825Z"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="m191.732 303.201 1.237-4.613c1.443-5.386 2.164-8.079 1.62-10.409a9.552 9.552 0 0 0-2.772-4.803c-1.747-1.636-4.44-2.357-9.825-3.8-5.386-1.444-8.079-2.165-10.409-1.622a9.552 9.552 0 0 0-4.803 2.773c-1.4 1.496-2.13 3.685-3.218 7.664-.182.668-.375 1.386-.582 2.16v.001l-1.236 4.614c-1.444 5.386-2.165 8.078-1.622 10.409a9.553 9.553 0 0 0 2.773 4.803c1.747 1.636 4.44 2.357 9.825 3.8h0c4.855 1.3 7.521 2.015 9.706 1.746.24-.03.473-.07.703-.125a9.552 9.552 0 0 0 4.803-2.773c1.636-1.746 2.357-4.439 3.8-9.825Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
        <g data-name="Path 1406">
          <path
            d="M182.426 315.924a9.554 9.554 0 0 1-2.55 4.007c-1.747 1.636-4.44 2.357-9.825 3.8-5.386 1.443-8.079 2.165-10.41 1.621a9.552 9.552 0 0 1-4.802-2.773c-1.636-1.746-2.358-4.439-3.8-9.825l-1.237-4.613c-1.443-5.386-2.165-8.079-1.621-10.41a9.553 9.553 0 0 1 2.773-4.802c1.746-1.636 4.44-2.357 9.825-3.8 1.019-.274 1.941-.52 2.783-.738"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="M182.426 315.924a9.554 9.554 0 0 1-2.55 4.007c-1.747 1.636-4.44 2.357-9.825 3.8-5.386 1.443-8.079 2.165-10.41 1.621a9.552 9.552 0 0 1-4.802-2.773c-1.636-1.746-2.358-4.439-3.8-9.825l-1.237-4.613c-1.443-5.386-2.165-8.079-1.621-10.41a9.553 9.553 0 0 1 2.773-4.802c1.746-1.636 4.44-2.357 9.825-3.8 1.019-.274 1.941-.52 2.783-.738"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
        <g data-name="Path 1407">
          <path
            d="m171.35 296.879 11.533 3.09"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="m171.35 296.879 11.533 3.09"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
        <g data-name="Path 1420">
          <path
            d="m174.042 288.805 11.534 3.09"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="m174.042 288.805 11.534 3.09"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
        <g data-name="Path 1408">
          <path
            d="m169.494 303.8 6.92 1.854"
            fill="rgba(0,0,0,0 )"
            fillRule="evenodd"
          />
          <path
            d="m169.494 303.8 6.92 1.854"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="3"
            stroke={is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default MenuNotesIcon
