import React from "react"
import { useTranslation } from "react-i18next"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import RedoIcon from "@/assets/icons/misc/redo-icon.tsx"
import ThreeDotsIcon from "@/assets/icons/misc/three-dots-icon.tsx"
import UndoIcon from "@/assets/icons/misc/undo-icon.tsx"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import styles from "@/components/top-bars/top-bars-common.module.css"

interface TopBarProps {
  onBack: () => void
  onSave: () => void
  onToggleOverlay?: () => void
  isNew: boolean
  onUndo: () => void
  onRedo: () => void
  canUndo: boolean
  canRedo: boolean
  hasChanges?: boolean
}

const NoteTopBar: React.FC<TopBarProps> = ({
  onBack,
  onSave,
  onToggleOverlay,
  isNew,
  onUndo,
  onRedo,
  canUndo,
  canRedo,
  hasChanges,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.noteTopBar}>
      <div className={styles.left}>
        <div className={styles.actionIcon} onClick={onBack}>
          <BackIcon />
        </div>
        <div className={styles.title}>{isNew ? t("newNote") : t("myNote")}</div>
      </div>
      <div className={styles.iconsGroup}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div
            className={styles.actionIconSmallPadding}
            onClick={onUndo}
            style={{ opacity: "0.5" }}
          >
            {canUndo && <UndoIcon />}
          </div>

          <div
            className={styles.actionIconSmallPadding}
            style={{ opacity: "0.5" }}
            onClick={onRedo}
          >
            {canRedo && <RedoIcon />}
          </div>

          <div className={styles.actionIcon} onClick={onToggleOverlay}>
            {onToggleOverlay && <ThreeDotsIcon />}
          </div>
        </div>

        <div className={styles.actionIcon} onClick={onSave}>
          {hasChanges && <CircleTickIcon size={30} />}
        </div>
      </div>
    </div>
  )
}

export default NoteTopBar
