import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const SunnyIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="335.241 406.535 42 42"
    >
      <path
        d="M356.24 436.535a9 9 0 1 0 0-18 9 9 0 0 0 0 18Zm0 3c-6.627 0-12-5.372-12-12 0-6.627 5.373-12 12-12 6.628 0 12 5.373 12 12 0 6.628-5.372 12-12 12Zm0-33a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 1 1-3 0v-3a1.5 1.5 0 0 1 1.5-1.5Zm0 36a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-3 0v-3a1.5 1.5 0 0 1 1.5-1.5Zm-14.85-29.85a1.5 1.5 0 0 1 2.122 0l2.12 2.121a1.5 1.5 0 1 1-2.12 2.121l-2.121-2.12a1.5 1.5 0 0 1 0-2.122Zm25.459 25.458a1.5 1.5 0 0 1 2.12 0l2.122 2.121a1.5 1.5 0 1 1-2.121 2.121l-2.121-2.12a1.5 1.5 0 0 1 0-2.122Zm-31.608-10.608a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 0 3h-3a1.5 1.5 0 0 1-1.5-1.5Zm36 0a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 0 3h-3a1.5 1.5 0 0 1-1.5-1.5Zm-29.85 14.85a1.5 1.5 0 0 1 0-2.12l2.12-2.122a1.5 1.5 0 1 1 2.122 2.122l-2.121 2.12a1.5 1.5 0 0 1-2.121 0Zm25.458-25.458a1.5 1.5 0 0 1 0-2.12l2.12-2.122a1.5 1.5 0 1 1 2.122 2.121l-2.121 2.121a1.5 1.5 0 0 1-2.121 0Z"
        fill="#f0a900"
        fillRule="evenodd"
        data-name="sunny-svgrepo-com"
      />
    </svg>
  )
}

export default SunnyIcon
