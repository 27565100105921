import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import styles from "@/features/finance/components/add-transaction-category-form/add-transaction-category-form.module.css"
import GenericTypeDrawerHeader from "@/components/generic-type-drawer/generic-type-drawer-header/generic-type-drawer-header.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import {
  type TaskCategory,
  useCreateTaskCategoryMutation,
  useDeleteTaskCategoryMutation,
  useGetTaskCategoriesQuery,
  useUpdateTaskCategoryMutation,
} from "@/features/tasks/api/task-categories-api.ts"
import { getObjectDifference } from "@/utils/helpers/object-utils.ts"

interface AddTransactionCategoryFormProps {
  onBackClick: () => void
  categoryId: string | null
}

const initialTaskCategory: Partial<TaskCategory> = {
  name: "",
  picture: null,
}

const AddTaskCategoryForm: React.FC<AddTransactionCategoryFormProps> = ({
  onBackClick,
  categoryId,
}) => {
  const { data } = useGetTaskCategoriesQuery()
  const taskCategory = data?.find((category) => category.id === categoryId)

  const { t } = useTranslation()
  const [createTaskCategory] = useCreateTaskCategoryMutation()
  const [updateTaskCategory] = useUpdateTaskCategoryMutation()
  const [deleteTaskCategory] = useDeleteTaskCategoryMutation()
  const [newTaskCategory, setNewTaskCategory] = useState<Partial<TaskCategory>>(
    taskCategory || initialTaskCategory,
  )
  const { openModal } = useModal()

  const handlePictureChange = (imgFile: string | null) => {
    setNewTaskCategory({ ...newTaskCategory, picture: imgFile })
  }

  const handleSaveCategory = () => {
    toast
      .promise(createTaskCategory(newTaskCategory).unwrap(), {
        pending: t("savingCategory"),
        success: t("categorySaved"),
        error: t("errorCreatingCategory"),
      })
      .then(() => {
        onBackClick()
      })
  }

  const diff =
    taskCategory && getObjectDifference(taskCategory, newTaskCategory)

  const handleUpdateCategory = () => {
    if (taskCategory) {
      toast
        .promise(
          updateTaskCategory({
            ...diff,
            id: taskCategory.id,
          }).unwrap(),
          {
            pending: t("updatingCategory"),
            success: t("categoryUpdated"),
            error: t("errorUpdatingCategory"),
          },
        )
        .then(() => {
          onBackClick()
        })
    }
  }

  const handleDeleteTransactionCategory = () => {
    if (!taskCategory) {
      return
    }

    openModal(MODALS.BASE_MODAL, {
      title: t("deleteTransactionCategoryConfirmation"),
      content: t("transactionCategoryWillBeDeleted"),
      onActionClick: () => {
        toast
          .promise(deleteTaskCategory(taskCategory?.id).unwrap(), {
            pending: t("deletingCategory"),
            success: t("categoryDeleted"),
            error: t("errorDeletingCategory"),
          })
          .then(() => {
            onBackClick()
          })
      },
    })
  }

  const handleCategoryMutation = () => {
    if (taskCategory) {
      handleUpdateCategory()
    } else {
      handleSaveCategory()
    }
  }

  return (
    <div className={styles.drawerFinanceContentWrapper}>
      <GenericTypeDrawerHeader
        title={taskCategory ? t(taskCategory.name) : t("ADD_CATEGORY")}
        onBackClick={onBackClick}
        {...((diff && Object.keys(diff).length > 0) || !taskCategory
          ? { onSaveClick: handleCategoryMutation }
          : {})}
        closeOnSave={false}
      />
      <div className={styles.formBody}>
        <OverflowMenuItem>
          <input
            id="categoryName"
            type="text"
            placeholder={t("categoryName")}
            className={styles.input}
            value={newTaskCategory.name}
            onChange={(e) =>
              setNewTaskCategory({
                ...newTaskCategory,
                name: e.target.value,
              })
            }
            required
            maxLength={100}
          />
          {newTaskCategory.name === "" && <RequiredIndicator />}
        </OverflowMenuItem>
        <AttachImageFieldBase64
          picture={newTaskCategory.picture as string}
          onChoosePicture={handlePictureChange}
        />
      </div>
      {taskCategory && (
        <DeleteButton
          text={t("deleteCategory")}
          onClick={handleDeleteTransactionCategory}
        />
      )}
    </div>
  )
}

export default AddTaskCategoryForm
