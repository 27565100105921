import { useRef, forwardRef, useImperativeHandle, useEffect } from "react"
import type ReactQuill from "react-quill"
import styles from "./note-editor.module.css"
import { type NoteDraft } from "@/features/notes"
import CustomToolbar from "@/features/notes/components/custom-toolbar/custom-toolbar.tsx"
import QuillEditor from "@/features/notes/components/quill-editor/quill-editor.tsx"
import useViewportHeight from "@/hooks/use-view-port-height.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import {
  setDraftAnimals,
  setDraftNoteContent,
  setDraftNoteTitle,
  setDraftTasks,
} from "@/redux/slices/notes-draft-slice.ts"
import {
  disableSelection,
  enableSelection,
  setSelectedTasksIds,
} from "@/redux/slices/task-selection-slice.ts"

interface EditorProps {
  noteId: string
  draftNote: NoteDraft
  isNewNote?: boolean
}

/**
 * Handles exposed by the editor component.
 */
export interface EditorHandles {
  /**
   * Gets the current content of the editor.
   * @returns The current content of the editor.
   */
  getContent: () => string
  /**
   * Gets the current title of the editor.
   * @returns The current title of the editor.
   */
  getTitle: () => string
  /**
   * Sets the content of the editor.
   * @param content - The new content to set.
   */
  setContent: (content: string) => void
  /**
   * Performs an undo action in the editor.
   */
  undo: () => void
  /**
   * Performs a redo action in the editor.
   */
  redo: () => void
  /**
   * Checks if the history allows an undo action.
   */
  canUndo: () => boolean
  /**
   * Checks if the history allows a redo action.
   */
  canRedo: () => boolean
}

/**
 * editor component for creating rich text content.
 * @param props - The props for the editor component.
 * @returns The JSX element representing the editor component.
 */
const NoteEditor = forwardRef<EditorHandles, EditorProps>(
  ({ isNewNote = true, noteId, draftNote }, ref) => {
    const quillRef = useRef<ReactQuill>(null)
    const undoRestriction = isNewNote ? 1 : 0
    const dispatch = useAppDispatch()

    const updateContent = (newContent: string) => {
      dispatch(setDraftNoteContent({ id: noteId, content: newContent }))
      // Create a temporary DOM element to parse the HTML content
      const tempDiv = document.createElement("div")
      tempDiv.innerHTML = newContent

      // Try to find the first block-level element (e.g., <p>, <h1>, <div>, etc.)
      const firstBlock = tempDiv.querySelector("p, h1, h2, h3, h4, h5, h6, div")

      // Use the text content of the first block as the title or an empty string if not found
      const firstLineText = firstBlock
        ? firstBlock.textContent?.trim() ?? ""
        : ""

      dispatch(setDraftNoteTitle({ id: noteId, title: firstLineText }))
    }

    // useImperativeHandle to expose methods to parent components
    useImperativeHandle(ref, () => ({
      getContent: () => draftNote.content,
      getTitle: () => draftNote.title,
      setContent: updateContent,
      undo: () => {
        const quillEditor = quillRef.current?.getEditor()
        // Quill adds the initially set content to the history. We bypass the undo for that by setting the history minimum length to 1
        if (
          quillEditor &&
          quillEditor.history.stack.undo.length > undoRestriction
        ) {
          quillEditor.history.undo()
        }
      },
      redo: () => {
        const quillEditor = quillRef.current?.getEditor()
        if (quillEditor && quillEditor.history.stack.redo.length > 0) {
          quillEditor.history.redo()
        }
      },
      // Quill adds the initially set content to the history. We bypass the undo for that by setting the history minimum length to 1
      canUndo: () =>
        quillRef.current
          ? quillRef.current.getEditor().history.stack.undo.length >
            undoRestriction
          : false,
      canRedo: () =>
        quillRef.current
          ? quillRef.current.getEditor().history.stack.redo.length > 0
          : false,
    }))

    const height = useViewportHeight()

    const selectedTasksIds = useAppSelector(
      (state) => state.taskSelectionSlice.selectedTasksIds,
    )

    useEffect(() => {
      dispatch(enableSelection())
      dispatch(setSelectedTasksIds(draftNote.tasks))

      return () => {
        dispatch(disableSelection())
        dispatch(setSelectedTasksIds([]))
      }
      // We set the selection only on initial render
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    const handleUpdateTasks = () => {
      dispatch(setDraftTasks({ id: noteId, tasks: selectedTasksIds }))
    }

    const handleUpdateAnimals = (animalIDs: string[]) => {
      dispatch(setDraftAnimals({ id: noteId, animals: animalIDs }))
    }

    return (
      <div style={{ height }} className={styles.editorWrapper}>
        <CustomToolbar
          editorRef={quillRef}
          draftNote={draftNote}
          onAnimalsChange={handleUpdateAnimals}
          onTasksChange={handleUpdateTasks}
        />
        <QuillEditor
          content={draftNote.content}
          setContent={updateContent}
          quillRef={quillRef}
        />
      </div>
    )
  },
)

NoteEditor.displayName = "Editor"

export default NoteEditor
