import React from "react"
import styles from "./summary-by-category.module.css"
import { type TransactionCategorySummary } from "@/features/finance/api/finance-summary-api"
import SummaryCategoryCard from "@/features/finance/components/summary-category-card/summary-category-card"
import { roundPercentages } from "@/utils/helpers/math-utils"

interface SummaryByCategoryProps {
  data: TransactionCategorySummary[]
  total: string
}

const SummaryByCategory: React.FC<SummaryByCategoryProps> = ({
  data,
  total,
}) => {
  const percentages = data.map(
    (item) => (Number(item.total) / Number(total)) * 100,
  )

  const roundedPercentages = roundPercentages(percentages)

  return (
    <>
      {data.map((transactionCategory, index) => (
        <React.Fragment key={transactionCategory.category.id}>
          <SummaryCategoryCard
            data={transactionCategory}
            percent={roundedPercentages[index]}
          />
          {index !== data.length - 1 && <div className={styles.divider} />}
        </React.Fragment>
      ))}
    </>
  )
}

export default SummaryByCategory
