import { PALE_GREEN, WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const AnimalStatusHealthyIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 40}
      height={props.size || 40}
      viewBox="4679.817 -5730.282 40 40"
    >
      <g data-name="Group 4253">
        <path
          d="M4679.817-5710.332a20 20 0 1 1 0 .1z"
          fill={props.secondary_color ? props.secondary_color : WHITE}
          fillRule="evenodd"
          data-name="Ellipse Calduri"
        />
        <g data-name="Path 1623">
          <path
            d="m4710.154-5716.525-1.445-1.445a1.601 1.601 0 0 0-1.14-.472c-.43 0-.834.167-1.139.472l-9.168 9.168-4.058-4.059a1.601 1.601 0 0 0-1.14-.472c-.43 0-.834.168-1.139.472l-1.445 1.446a1.6 1.6 0 0 0-.472 1.14c0 .43.168.834.472 1.139l6.643 6.642c.305.305.71.472 1.14.472.43 0 .834-.167 1.139-.472l11.752-11.752a1.6 1.6 0 0 0 .472-1.14c0-.43-.167-.834-.472-1.14Zm-.833 1.445-11.753 11.753a.43.43 0 0 1-.611 0l-6.643-6.643a.429.429 0 0 1 0-.612l1.446-1.445a.43.43 0 0 1 .611 0l4.475 4.475a.59.59 0 0 0 .833 0l9.585-9.585a.43.43 0 0 1 .612 0l1.445 1.446a.43.43 0 0 1 0 .611Z"
            fill={props.primary_color ? props.primary_color : PALE_GREEN}
            fillRule="evenodd"
          />
          <path
            d="m4710.154-5716.525-1.445-1.445a1.601 1.601 0 0 0-1.14-.472c-.43 0-.834.167-1.139.472l-9.168 9.168-4.058-4.059a1.601 1.601 0 0 0-1.14-.472c-.43 0-.834.168-1.139.472l-1.445 1.446a1.6 1.6 0 0 0-.472 1.14c0 .43.168.834.472 1.139l6.643 6.642c.305.305.71.472 1.14.472h0c.43 0 .834-.167 1.139-.472l11.752-11.752a1.6 1.6 0 0 0 .472-1.14c0-.43-.167-.834-.472-1.14Zm-.833 1.445-11.753 11.753a.43.43 0 0 1-.611 0l-6.643-6.643h0a.429.429 0 0 1 0-.612l1.446-1.445a.43.43 0 0 1 .611 0l4.475 4.475a.59.59 0 0 0 .833 0l9.585-9.585a.43.43 0 0 1 .612 0l1.445 1.446a.43.43 0 0 1 0 .611Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth=".6"
            stroke={props.primary_color ? props.primary_color : PALE_GREEN}
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default AnimalStatusHealthyIcon
