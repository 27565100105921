import React from "react"
import { useParams } from "react-router-dom"
import EditIcon from "@/assets/icons/misc/edit-icon"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useGetAnimalByIdQuery } from "@/features/farm/api/animals-api"
import AnimalProfileEditor from "@/features/farm/routes/animal-profile/animal-profile-editor.tsx"
import { ANIMAL_SCREEN_PATH } from "@/utils/constants/routes"

export const AnimalProfile: React.FC = () => {
  const { animalId } = useParams<{ animalId: string }>()

  const {
    data: selectedAnimal,
    isLoading,
    isFetching,
  } = useGetAnimalByIdQuery(animalId as string, { skip: !animalId })

  return (
    <>
      <MobileTopBar
        title={selectedAnimal?.ain || "Loading..."}
        navigateTo={`${ANIMAL_SCREEN_PATH}/${selectedAnimal?.id}`}
        icon={<EditIcon />}
      />
      <PageContentWrapperLarge hasPadding={false}>
        {isLoading || isFetching ? (
          <LoadingAnimation />
        ) : selectedAnimal ? (
          <AnimalProfileEditor selectedAnimal={selectedAnimal} />
        ) : (
          <NoResultsFound />
        )}
      </PageContentWrapperLarge>
    </>
  )
}
