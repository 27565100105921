import styles from "./skeleton.module.css"

interface SkeletonProps {
  height?: number
}

const Skeleton: React.FC<SkeletonProps> = ({ height }) => {
  return (
    <div
      className={styles.skeleton}
      style={{
        height: `${height}px`,
      }}
    ></div>
  )
}

export default Skeleton
