import { WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const AddCalendarIcon: React.FC<GeneralSvgProps> = ({ color, size }) => {
  return (
    <svg
      width={size || 17}
      height={size || 20}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 6.66669H1M11.8333 1.66669V4.16669M5.16667 1.66669V4.16669M8.5 15V10M6 12.5H11M5 18.3334H12C13.4001 18.3334 14.1002 18.3334 14.635 18.0609C15.1054 17.8212 15.4878 17.4387 15.7275 16.9683C16 16.4335 16 15.7335 16 14.3334V7.33335C16 5.93322 16 5.23316 15.7275 4.69838C15.4878 4.22797 15.1054 3.84552 14.635 3.60584C14.1002 3.33335 13.4001 3.33335 12 3.33335H5C3.59987 3.33335 2.8998 3.33335 2.36502 3.60584C1.89462 3.84552 1.51217 4.22797 1.27248 4.69838C1 5.23316 1 5.93322 1 7.33335V14.3334C1 15.7335 1 16.4335 1.27248 16.9683C1.51217 17.4387 1.89462 17.8212 2.36502 18.0609C2.8998 18.3334 3.59987 18.3334 5 18.3334Z"
        stroke={color || WHITE}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AddCalendarIcon
