import React from "react"
import { BROWN, DARK_GREY } from "@/utils/constants/colors-constants"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const NotificationMenuIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || "26.602"}
      height={props.size || "32.039"}
      viewBox="257.049 28.83 26.602 32.039"
    >
      <g data-name="Path 1395">
        <path
          d="M282.06 51.568a1.226 1.226 0 0 0-.383-.542c.014-.348.022-.7.022-1.053 0-7.792-3.262-14.392-7.769-16.655.067-.27.107-.55.107-.84 0-1.932-1.584-3.498-3.537-3.498-1.953 0-3.536 1.566-3.536 3.499 0 .29.04.57.106.839-4.507 2.263-7.769 8.863-7.769 16.655 0 .354.009.705.022 1.053-.169.143-.308.323-.382.542l-1.676 4.958c-.271.803.333 1.633 1.189 1.633h8.168a4.043 4.043 0 0 0 3.878 2.86 4.043 4.043 0 0 0 3.878-2.86h8.169c.856 0 1.46-.83 1.188-1.633l-1.675-4.958Zm-14.456-17.21.835-.42-.224-.9a2.29 2.29 0 0 1-.073-.56c0-1.285 1.058-2.332 2.358-2.332s2.358 1.047 2.358 2.333c0 .181-.024.364-.073.56l-.224.9.835.419c4.195 2.106 7.124 8.527 7.124 15.615 0 .24-.003.482-.01.726h-20.02c-.007-.244-.01-.487-.01-.726 0-7.088 2.93-13.509 7.124-15.615Zm2.896 25.517a2.897 2.897 0 0 1-2.65-1.716h5.3a2.897 2.897 0 0 1-2.65 1.716Zm12.125-2.9c-.029.04-.063.04-.078.04h-24.093c-.015 0-.05 0-.08-.04a.086.086 0 0 1-.012-.086l1.675-4.958a.097.097 0 0 1 .092-.066h20.742c.042 0 .079.027.092.066l1.675 4.958a.086.086 0 0 1-.013.086Z"
          fill={props.is_active ? BROWN : DARK_GREY}
          fillRule="evenodd"
        />
        <path
          d="M282.06 51.568a1.226 1.226 0 0 0-.383-.542c.014-.348.022-.7.022-1.053 0-7.792-3.262-14.392-7.769-16.655.067-.27.107-.55.107-.84 0-1.932-1.584-3.498-3.537-3.498-1.953 0-3.536 1.566-3.536 3.499 0 .29.04.57.106.839-4.507 2.263-7.769 8.863-7.769 16.655 0 .354.009.705.022 1.053-.169.143-.308.323-.382.542l-1.676 4.958c-.271.803.333 1.633 1.189 1.633h8.168a4.043 4.043 0 0 0 3.878 2.86 4.043 4.043 0 0 0 3.878-2.86h8.169c.856 0 1.46-.83 1.188-1.633l-1.675-4.958Zm-14.456-17.21.835-.42-.224-.9a2.29 2.29 0 0 1-.073-.56c0-1.285 1.058-2.332 2.358-2.332s2.358 1.047 2.358 2.333c0 .181-.024.364-.073.56l-.224.9.835.419c4.195 2.106 7.124 8.527 7.124 15.615 0 .24-.003.482-.01.726h-20.02c-.007-.244-.01-.487-.01-.726 0-7.088 2.93-13.509 7.124-15.615Zm2.896 25.517a2.897 2.897 0 0 1-2.65-1.716h5.3a2.897 2.897 0 0 1-2.65 1.716Zm12.125-2.9c-.029.04-.063.04-.078.04h-24.093c-.015 0-.05 0-.08-.04a.086.086 0 0 1-.012-.086l1.675-4.958a.097.097 0 0 1 .092-.066h20.742c.042 0 .079.027.092.066l1.675 4.958a.086.086 0 0 1-.013.086Z"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth=".3"
          stroke={props.is_active ? BROWN : DARK_GREY}
          fill={props.is_active ? BROWN : "transparent"}
        />
      </g>
    </svg>
  )
}

export default NotificationMenuIcon
