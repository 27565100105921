import { BROWN, DARK_GREY } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuVoiceAssistentIcon: React.FC<GeneralSvgProps> = ({ is_active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="360.604 719.777 35.479 34.977"
    >
      <g data-name="Group 2772">
        <path
          d="M375.913 719.777h4.193a2.723 2.723 0 0 1 2.723 2.724v13.831a2.723 2.723 0 0 1-2.723 2.723H375.913a2.723 2.723 0 0 1-2.723-2.723v-13.831a2.723 2.723 0 0 1 2.723-2.724Z"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="3"
          stroke={is_active ? BROWN : DARK_GREY}
          fill="transparent"
          data-name="Path 1419"
        />
        <path
          d="M367.055 722.298v3.225m-6.45-4.838v6.45m29.027-4.837v3.225m6.451-4.838v6.45"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="3"
          stroke={is_active ? BROWN : DARK_GREY}
          fill="transparent"
          data-name="Path 1416"
        />
        <path
          d="M367.055 733.587v1.613c0 6.235 5.054 11.289 11.29 11.289h0c6.234 0 11.288-5.054 11.288-11.289v-1.613"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="3"
          stroke={is_active ? BROWN : DARK_GREY}
          fill="transparent"
          data-name="Path 1417"
        />
        <path
          d="M378.344 748.303v6.45m0 0h-4.838m4.838 0h4.838"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="3"
          stroke={is_active ? BROWN : DARK_GREY}
          fill="transparent"
          data-name="Path 1418"
        />
      </g>
    </svg>
  )
}

export default MenuVoiceAssistentIcon
