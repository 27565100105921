import React from "react"
import styles from "./date-separator.module.css"

interface DateSeparatorProps {
  monthYear: string // The month and year to display in the separator
}

/**
 * DateSeparator component for displaying a separator with month and year.
 * Renders the provided month and year along with a separator line.
 */
const DateSeparator: React.FC<DateSeparatorProps> = ({ monthYear }) => {
  return (
    <div className={styles["date-separator"]}>
      <div className={styles["date-month"]}>{monthYear}</div>
      <div className={styles["separator"]} />
    </div>
  )
}

export default DateSeparator
