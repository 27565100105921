import styles from "./ain-tag.module.css"

interface AinTagProps {
  label: string
}

const AinTag: React.FC<AinTagProps> = ({ label }) => {
  return (
    <div className={styles.tagWrapper}>
      <div className={styles.triangle}></div>
      <div className={styles.tag}>
        <span className={styles.hole}></span>
        <span className={styles.label}>{label}</span>
      </div>
    </div>
  )
}

export default AinTag
