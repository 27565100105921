import React from "react"
import { BROWN, DARK_GREY, WHITE } from "@/utils/constants/colors-constants"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const HomeIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || "23"}
      height={props.size || "27"}
      viewBox="45 30 22 30"
    >
      <g data-name="Group 2744">
        <g data-name="home-3-svgrepo-com">
          <path
            d="M68.063 37.855V54.71a3.304 3.304 0 0 1-3.304 3.304H48.241a3.304 3.304 0 0 1-3.304-3.304V37.855m0 0 9.669-6.597a3.304 3.304 0 0 1 3.789 0l9.668 6.597"
            fill={props.is_active ? BROWN : WHITE}
            fillRule="evenodd"
          />
          <path
            d="M68.063 37.855V54.71a3.304 3.304 0 0 1-3.304 3.304H48.241a3.304 3.304 0 0 1-3.304-3.304V37.855m0 0 9.669-6.597a3.304 3.304 0 0 1 3.789 0l9.668 6.597"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.7"
            stroke={props.is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
        <g data-name="Path 1403">
          <path
            d="M58.668 47.893h-4.336a2.891 2.891 0 0 0-2.892 2.891v4.337a2.891 2.891 0 0 0 2.892 2.891h4.336a2.891 2.891 0 0 0 2.892-2.89v-4.338a2.891 2.891 0 0 0-2.892-2.89Z"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="M58.668 47.893h-4.336a2.891 2.891 0 0 0-2.892 2.891v4.337a2.891 2.891 0 0 0 2.892 2.891h4.336a2.891 2.891 0 0 0 2.892-2.89v-4.338a2.891 2.891 0 0 0-2.892-2.89Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.7"
            stroke={props.is_active ? BROWN : DARK_GREY}
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default HomeIcon
