import React from "react"
import styles from "./assoc-entity-from-note-trigger.module.css"
import FarmIcon from "@/assets/icons/farm/farm-icon.tsx"
import { CUSTOM_TOOLBAR_ICON_COLOR } from "@/utils/constants/colors-constants.ts"

interface CustomTriggerProps {
  currentValue: string[] | string
  onClick: () => void
}

const AssocAnimalFromNoteTrigger = React.forwardRef<
  HTMLDivElement,
  CustomTriggerProps
>(({ currentValue, onClick }, ref) => {
  return (
    <div className={styles.overlayItem} onClick={onClick} ref={ref}>
      <div className={styles.overlayText}>
        {currentValue?.length > 0 && currentValue.length}
      </div>
      <FarmIcon
        size={25}
        stroke={CUSTOM_TOOLBAR_ICON_COLOR}
        strokeWidth={1.7}
      />
    </div>
  )
})

AssocAnimalFromNoteTrigger.displayName = "AssocAnimalFromNoteTrigger"

export default AssocAnimalFromNoteTrigger
