import styles from "./not-found-page.module.css"

const NotFoundPage = () => {
  return (
    <div className={styles["notFoundContainer"]}>
      <h1 className={styles["notFoundTitle"]}>404 - Not Found</h1>
      <p className={styles["notFoundText"]}>
        The page you&apos;re looking for doesn&apos;t exist or has been moved.
      </p>
    </div>
  )
}

export default NotFoundPage
