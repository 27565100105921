import { PASTEL_BROWN, WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const AnimalStatusIllIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 40}
      height={props.size || 40}
      viewBox="4553.033 -5730.282 40 40"
    >
      <g data-name="Group 4254">
        <path
          d="M4553.033-5710.332a20 20 0 1 1 0 .1z"
          fill={props.secondary_color ? props.secondary_color : WHITE}
          fillRule="evenodd"
          data-name="Ellipse Calduri"
        />
        <g data-name="Group 4241">
          <g data-name="Path 1602">
            <path
              d="M4582.065-5719.07c-1.565-1.564-3.967-1.705-5.357-.316l-7.07 7.071c-.745.744-1.118 1.722-1.512 2.757-.527 1.38-1.124 2.946-2.747 4.568l-1.725 1.725a1.843 1.843 0 0 0 2.605 2.605l1.725-1.725c1.623-1.623 3.189-2.22 4.569-2.746 1.035-.395 2.012-.768 2.756-1.512l7.07-7.07c1.39-1.39 1.249-3.794-.314-5.357Zm-16.794 17.423a.446.446 0 0 1-.63-.63l1.232-1.231.63.63-1.232 1.231Zm16.122-13.054-7.07 7.07c-.534.533-1.299.825-2.268 1.195-1.304.497-2.897 1.105-4.55 2.583l-.658-.657c1.479-1.654 2.086-3.247 2.584-4.55.369-.97.662-1.735 1.195-2.268l7.07-7.07c.846-.846 2.363-.705 3.382.314 1.02 1.02 1.161 2.536.315 3.382Z"
              fill={props.primary_color ? props.primary_color : PASTEL_BROWN}
              fillRule="evenodd"
            />
            <path
              d="M4582.065-5719.07c-1.565-1.564-3.967-1.705-5.357-.316l-7.07 7.071c-.745.744-1.118 1.722-1.512 2.757-.527 1.38-1.124 2.946-2.747 4.568l-1.725 1.725a1.843 1.843 0 0 0 2.605 2.605l1.725-1.725s0 0 0 0c1.623-1.623 3.189-2.22 4.569-2.746 1.035-.395 2.012-.768 2.756-1.512l7.07-7.07c1.39-1.39 1.249-3.794-.314-5.357Zm-16.794 17.423a.446.446 0 0 1-.63-.63l1.232-1.231.63.63-1.232 1.231Zm16.122-13.054-7.07 7.07c-.534.533-1.299.825-2.268 1.195-1.304.497-2.897 1.105-4.55 2.583l-.658-.657c1.479-1.654 2.086-3.247 2.584-4.55.369-.97.662-1.735 1.195-2.268l7.07-7.07c.846-.846 2.363-.705 3.382.314 1.02 1.02 1.161 2.536.315 3.382Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke={props.primary_color ? props.primary_color : PASTEL_BROWN}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1603">
            <path
              d="M4576.86-5716.364a1.71 1.71 0 0 0 0 2.414c.333.333.77.5 1.207.5a1.7 1.7 0 0 0 1.207-.5c.323-.322.5-.75.5-1.207a1.71 1.71 0 0 0-2.915-1.207Zm1.427 1.427a.31.31 0 1 1-.44-.44.31.31 0 0 1 .44.44Z"
              fill={props.primary_color ? props.primary_color : PASTEL_BROWN}
              fillRule="evenodd"
            />
            <path
              d="M4576.86-5716.364h0a1.71 1.71 0 0 0 0 2.414c.333.333.77.5 1.207.5a1.7 1.7 0 0 0 1.207-.5c.323-.322.5-.75.5-1.207a1.71 1.71 0 0 0-2.915-1.207Zm1.427 1.427a.31.31 0 1 1-.44-.44.31.31 0 0 1 .44.44Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke={props.primary_color ? props.primary_color : PASTEL_BROWN}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1604">
            <path
              d="M4574.617-5713.246a.699.699 0 0 0-.987 0l-3.5 3.5a.699.699 0 0 0 0 .987l1.54 1.54a.696.696 0 0 0 .987 0l3.5-3.5a.7.7 0 0 0 0-.988l-1.54-1.54Zm-2.455 4.547-.553-.553 2.513-2.514.553.553-2.513 2.514Z"
              fill={props.primary_color ? props.primary_color : PASTEL_BROWN}
              fillRule="evenodd"
            />
            <path
              d="M4574.617-5713.246a.699.699 0 0 0-.987 0l-3.5 3.5a.699.699 0 0 0 0 .987l1.54 1.54a.696.696 0 0 0 .987 0l3.5-3.5a.7.7 0 0 0 0-.988l-1.54-1.54Zm-2.455 4.547-.553-.553 2.513-2.514.553.553-2.513 2.514Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke={props.primary_color ? props.primary_color : PASTEL_BROWN}
              fill="transparent"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AnimalStatusIllIcon
