import React from "react"
import styles from "./chat-bubble.module.css"
import { usePostMessageToAssistantMutation } from "@/features/assistant/api/assistant-api.ts"

interface ChatBubbleProps {
  message: string
  threadId: string
}

const EmptyChatBubble: React.FC<ChatBubbleProps> = ({ message, threadId }) => {
  const [postMessage] = usePostMessageToAssistantMutation()

  const postMessageToAssistant = (message: string) => {
    postMessage({ threadId, message })
  }

  return (
    <div
      onClick={() => postMessageToAssistant(message)}
      className={styles.chatBubble}
    >
      {message}
    </div>
  )
}

export default EmptyChatBubble
