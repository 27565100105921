import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import {
  initialTransactionState,
  type TransactionDraft,
} from "@/features/finance/types/transaction"

type TransactionSlice = {
  draft: TransactionDraft
}

const initialState: TransactionSlice = { draft: initialTransactionState }

const transactionDraftSlice = createSlice({
  name: "transactionDraft",
  initialState,
  reducers: {
    setTransactionDraft: (state, action: PayloadAction<TransactionDraft>) => {
      state.draft = action.payload
    },
    setDraftTransactionDescription: (state, action: PayloadAction<string>) => {
      state.draft.description = action.payload
    },
    setDraftTransactionAmount: (state, action: PayloadAction<string>) => {
      state.draft.amount = action.payload
    },
    setDraftTransactionDate: (state, action: PayloadAction<string>) => {
      state.draft.date = action.payload
    },
    setDraftTransactionCategory: (state, action: PayloadAction<string>) => {
      state.draft.category = action.payload
    },
    setDraftTransactionCurrency: (state, action: PayloadAction<string>) => {
      state.draft.currency = action.payload
    },
  },
})

export default transactionDraftSlice.reducer

export const {
  setTransactionDraft,
  setDraftTransactionDescription,
  setDraftTransactionAmount,
  setDraftTransactionDate,
  setDraftTransactionCategory,
  setDraftTransactionCurrency,
} = transactionDraftSlice.actions
