import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AnimalFilterTab } from "@/components/filter-tab/animal-filter-tab/animal-filter-tab.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import PageContentWrapperSmall from "@/components/page-content-wrappers/page-content-wrapper-small.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { type Animal } from "@/features/farm"
import AnimalCard from "@/features/farm/components/animal-card/animal-card.tsx"
import AnimalCardWrapper from "@/features/farm/components/animal-card-wrapper/animal-card-wrapper.tsx"
import CountNumber from "@/features/farm/components/count-number/count-number.tsx"
import useAnimalFilterURL from "@/features/farm/hooks/use-animal-filter-URL.ts"
import { useAppSelector } from "@/redux/hooks.ts"
import {
  ANIMAL_PROFILE_PATH,
  NEW_ANIMAL_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

export const FarmTab: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    selections,
    animals,
    isLoading,
    handleAgeSelection,
    handleGenderSelection,
    handleSpeciesSelection,
    handleSearchQuery,
    handleStatusSelection,
  } = useAnimalFilterURL()
  const [filteredAnimals, setFilteredAnimals] = useState<Animal[]>([])
  const animalDrafts = useAppSelector((state) => state.animalDraftSlice.drafts)

  const handleAnimalCardClick = (animalId: string) => {
    navigate(`${ANIMAL_PROFILE_PATH}/${animalId}`)
  }

  useEffect(() => {
    const animalsWithChanges = animals.map((animal) => {
      const draft = animalDrafts[animal.id]
      return draft ? { ...animal, hasChanges: true } : animal
    })
    setFilteredAnimals(animalsWithChanges)
  }, [selections, animalDrafts, animals])

  return (
    <>
      <MobileTopBar title={t("myFarm")} navigateTo={NEW_ANIMAL_SCREEN_PATH} />

      <PageContentWrapperSmall>
        <AnimalFilterTab
          selections={selections}
          handleSearch={handleSearchQuery}
          handleAgeChange={handleAgeSelection}
          handleGenderChange={handleGenderSelection}
          handleSpeciesChange={handleSpeciesSelection}
          handleStatusChange={handleStatusSelection}
        />
        <CountNumber count={filteredAnimals.length} text={t("heads")} />

        {filteredAnimals.length === 0 && (
          <div className="mt-[100px] flex w-full justify-center">
            <NoResultsFound message={t("noAnimalsFoundForFilter")} />
          </div>
        )}

        {isLoading && filteredAnimals.length === 0 && <LoadingAnimation />}

        {filteredAnimals.map((animal, index) => (
          <AnimalCardWrapper
            key={index}
            onClick={() => handleAnimalCardClick(animal.id)}
          >
            <AnimalCard animal={animal} />
          </AnimalCardWrapper>
        ))}
      </PageContentWrapperSmall>
    </>
  )
}
