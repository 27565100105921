import { type Animal } from "@/features/farm"
import { getAgeFromDateOfBirth } from "@/utils/helpers/time-utils"
import { type Option } from "@/utils/types/misc-types.ts"

export enum ANIMAL_AGES_ENUM {
  ALL = "allAges",
  AGE01 = "AGE01",
  AGE13 = "AGE13",
  AGE35 = "AGE35",
  AGE510 = "AGE510",
}

export const ANIMAL_AGES = {
  ALL: {
    label: "allAges",
    value: ANIMAL_AGES_ENUM.ALL,
  },
  AGE01: {
    label: "0 - 1 an",
    value: ANIMAL_AGES_ENUM.AGE01,
  },
  AGE13: {
    label: "1 - 3 ani",
    value: ANIMAL_AGES_ENUM.AGE13,
  },
  AGE35: {
    label: "3 - 5 ani",
    value: ANIMAL_AGES_ENUM.AGE35,
  },
  AGE510: {
    label: "5+ ani",
    value: ANIMAL_AGES_ENUM.AGE510,
  },
}

export const matchesAnimalAge = (animalAge: number, selectedAnimalAge: any) => {
  if (selectedAnimalAge === ANIMAL_AGES.AGE01.value) {
    return animalAge <= 1
  } else if (selectedAnimalAge === ANIMAL_AGES.AGE13.value) {
    return animalAge >= 1 && animalAge < 3
  } else if (selectedAnimalAge === ANIMAL_AGES.AGE35.value) {
    return animalAge >= 3 && animalAge < 5
  } else if (selectedAnimalAge === ANIMAL_AGES.AGE510.value) {
    return animalAge >= 5
  } else {
    return true
  }
}

export const getAnimalsByAgeRange = (
  animals: Animal[],
  age1: number,
  age2: number,
) => {
  let total = 0
  for (let i = 0; i < animals.length; i++) {
    const animalAge = Number(
      getAgeFromDateOfBirth(animals[i].entry_date, "number"),
    )
    if (age1 <= animalAge && age2 > animalAge) {
      total++
    }
  }
  return total
}

export const animalAgesOptions: Option[] = Object.entries(ANIMAL_AGES).map(
  ([, value]) => ({
    label: value.label,
    value: value.value,
  }),
)
