import React from "react"

const SignOutIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13.415"
      height="13.415"
      viewBox="0 0 13.415 13.415"
      {...props}
    >
      <g id="logout-svgrepo-com" transform="translate(0 -0.001)">
        <g id="Sign_Out" transform="translate(0 0.001)">
          <path
            id="Path_719"
            data-name="Path 719"
            d="M6.288,12.578H.838V.839h5.45a.419.419,0,1,0,0-.838H.419A.419.419,0,0,0,0,.42V13a.419.419,0,0,0,.419.419H6.288a.419.419,0,0,0,0-.838Z"
            transform="translate(0 -0.001)"
            fill="rgba(255,28,28,0.5)"
          />
          <path
            id="Path_720"
            data-name="Path 720"
            d="M94.607,99.387l-2.893-2.934a.416.416,0,0,0-.593,0,.426.426,0,0,0,0,.6l2.18,2.211H84.668a.423.423,0,0,0,0,.847H93.3l-2.18,2.211a.426.426,0,0,0,0,.6.416.416,0,0,0,.593,0l2.892-2.934A.429.429,0,0,0,94.607,99.387Z"
            transform="translate(-81.313 -92.972)"
            fill="rgba(255,28,28,0.5)"
          />
        </g>
      </g>
    </svg>
  )
}

export default SignOutIcon
