import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./notes-group.module.css"
import { type Note } from "@/features/notes"
import NotesByTimeSpan from "@/features/notes/components/notes-by-time-span/notes-by-time-span.tsx"
import NotesGroupWrapper from "@/features/notes/components/notes-group/notes-group-wrapper.tsx"
import { type ItemsTimeGroups } from "@/utils/helpers/time-grouping.ts"

interface NotesGroupProp {
  notes: ItemsTimeGroups
}

const NotesGroup: React.FC<NotesGroupProp> = ({ notes }) => {
  const { t } = useTranslation()

  return (
    <div className={styles["notes-group-wrapper"]}>
      {notes.todayItems.length > 0 && (
        <NotesByTimeSpan
          notes={notes.todayItems as Note[]}
          title={t("today")}
        />
      )}

      {notes.thisWeekItems.length > 0 && (
        <NotesByTimeSpan
          notes={notes.thisWeekItems as Note[]}
          title={t("thisWeek")}
        />
      )}

      {notes.thisMonthItems.length > 0 && (
        <NotesByTimeSpan
          notes={notes.thisMonthItems as Note[]}
          title={t("thisMonth")}
        />
      )}

      {notes.pastMonthItems.length > 0 && (
        <NotesByTimeSpan
          notes={notes.pastMonthItems as Note[]}
          title={t("lastMonth")}
        />
      )}

      {notes.thisYearItems.length > 0 && (
        <NotesGroupWrapper
          notes={notes.thisYearItems as Note[]}
          title={t("thisYear")}
          type={"month"}
        />
      )}

      {notes.pastYearItems.length > 0 && (
        <NotesGroupWrapper
          notes={notes.pastYearItems as Note[]}
          title={t("lastYear")}
          type={"month"}
        />
      )}

      {notes.pastYearsItems.length > 0 && (
        <NotesGroupWrapper
          notes={notes.pastYearsItems as Note[]}
          title={t("pastYears")}
          type={"year"}
        />
      )}
    </div>
  )
}

export default NotesGroup
