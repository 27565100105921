const ConfidentialityIcon = ({
  size = 41,
  color = "var(--brown)",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 31.507 31.5"
      {...props}
    >
      <g id="shield-checked-svgrepo-com" transform="translate(0.25 0.249)">
        <path
          id="path10936"
          d="M15.522,0a.535.535,0,0,0-.328.1A24.26,24.26,0,0,1,.535,4.277a.535.535,0,0,0-.529.614c.8,5.352,1.869,11.8,4.114,16.97a18.217,18.217,0,0,0,4.4,6.5A10.271,10.271,0,0,0,15.5,31a10.271,10.271,0,0,0,6.976-2.639,18.217,18.217,0,0,0,4.4-6.5c2.245-5.17,3.311-11.618,4.114-16.97a.534.534,0,0,0-.529-.614A28.7,28.7,0,0,1,15.775.077.534.534,0,0,0,15.522,0Zm0,1.158A29.794,29.794,0,0,0,29.842,5.326c-.792,5.216-1.855,11.3-3.942,16.108a17.24,17.24,0,0,1-4.131,6.126A9.113,9.113,0,0,1,15.5,29.932a9.113,9.113,0,0,1-6.269-2.372A17.24,17.24,0,0,1,5.1,21.435c-2.086-4.8-3.149-10.886-3.941-16.1A25.1,25.1,0,0,0,15.526,1.16ZM9.058,5.63a.266.266,0,0,0-.071.011A28.93,28.93,0,0,1,3.013,6.6a.267.267,0,0,0-.251.283c0,.009,0,.018,0,.026.766,4.753,1.793,9.923,3.561,13.994a.267.267,0,1,0,.5-.2l0-.011A59.986,59.986,0,0,1,3.343,7.108a29.145,29.145,0,0,0,5.787-.952.267.267,0,0,0-.071-.526ZM22.966,9.616a.535.535,0,0,0-.4.206L14.387,20.046,9.464,15.123a.535.535,0,1,0-.771.74l.015.015,5.345,5.345a.535.535,0,0,0,.756,0,.529.529,0,0,0,.039-.044L23.4,10.49a.535.535,0,0,0-.434-.874Z"
          transform="translate(0 0)"
          fill={color}
          stroke={color}
          strokeWidth="0.5"
        />
      </g>
    </svg>
  )
}

export default ConfidentialityIcon
