import React from "react"

const FarmIconImage: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32.819"
    height="34.79"
    viewBox="0 0 32.819 34.79"
    {...props}
  >
    <g
      id="farm-svgrepo-com_2_"
      data-name="farm-svgrepo-com (2)"
      transform="translate(-32.463 0)"
    >
      <g
        id="Group_962"
        data-name="Group 962"
        transform="translate(50.669 27.681)"
      >
        <g id="Group_961" data-name="Group 961" transform="translate(0)">
          <path
            id="Path_731"
            data-name="Path 731"
            d="M282.019,409.438l-.3-.261a.749.749,0,1,0-.991,1.123l.3.261a.749.749,0,0,0,.991-1.123Z"
            transform="translate(-280.479 -408.989)"
            fill="var(--brown)"
          />
        </g>
      </g>
      <g
        id="Group_964"
        data-name="Group 964"
        transform="translate(44.932 6.323)"
      >
        <g id="Group_963" data-name="Group 963">
          <path
            id="Path_732"
            data-name="Path 732"
            d="M206.263,95.074a3.945,3.945,0,0,0-3.94,3.94v3.192a.749.749,0,0,0,.749.749h6.383a.749.749,0,0,0,.749-.749V99.014A3.945,3.945,0,0,0,206.263,95.074Zm2.443,6.383H203.82V99.014a2.443,2.443,0,0,1,4.886,0Z"
            transform="translate(-202.323 -95.074)"
            fill="var(--brown)"
          />
        </g>
      </g>
      <g id="Group_966" data-name="Group 966" transform="translate(32.463 0)">
        <g id="Group_965" data-name="Group 965" transform="translate(0 0)">
          <path
            id="Path_733"
            data-name="Path 733"
            d="M65.192,15.727,58.3,3.909a.73.73,0,0,0-.359-.306L49.172.058a.8.8,0,0,0-.6,0L39.806,3.6a.73.73,0,0,0-.359.306L32.553,15.727a.668.668,0,0,0,.3.939.793.793,0,0,0,.354.083.76.76,0,0,0,.66-.365l.933-1.6V34.1a.722.722,0,0,0,.749.693h26.64a.722.722,0,0,0,.749-.693V14.781l.933,1.6a.76.76,0,0,0,.66.365.793.793,0,0,0,.354-.083A.668.668,0,0,0,65.192,15.727ZM48.124,33.4H41.4l6.722-5.492Zm-7.959-.838V20.182l7.609,6.168Zm7.959-7.776-6.983-5.66h6.983Zm9.456,7.776L54.01,29.65a.791.791,0,0,0-1.057.061.658.658,0,0,0,.066.978L56.343,33.4H49.621V26.689l7.958-6.5V32.566Zm-7.959-7.726V19.13H56.61ZM61.444,33.4H59.077V18.437a.722.722,0,0,0-.749-.693H39.417a.722.722,0,0,0-.749.693V33.4H36.3V12.213l4.341-7.435,8.23-3.328L57.1,4.778l4.341,7.435Z"
            transform="translate(-32.463 0)"
            fill="var(--brown)"
          />
        </g>
      </g>
    </g>
  </svg>
)

export default FarmIconImage
