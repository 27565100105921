import { Slide, ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default function ToastConfig() {
  return (
    <ToastContainer
      position="top-center"
      autoClose={2500}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      transition={Slide}
      limit={3}
      draggablePercent={60}
      draggableDirection={"y"}
      style={{
        fontSize: "14px",
        padding: "10px",
        backdropFilter: "blur(0.5px)",
      }}
      toastStyle={{
        boxShadow: "0 3px 6px rgba(0, 0, 0, 0.4)",
        borderRadius: "10px",
      }}
    />
  )
}
