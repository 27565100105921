import React from "react"
import styles from "./short-bottom-action-wrapper.module.css"

interface ShortBottomActionWrapperProps {
  children: React.ReactNode
  height?: string
  customStyle?: React.CSSProperties
}

const ShortBottomActionWrapper: React.FC<ShortBottomActionWrapperProps> = ({
  children,
  height,
  customStyle,
}) => {
  return (
    <div
      className={styles.shortActionBottomWrapper}
      style={{
        height: height,
        ...customStyle,
      }}
    >
      {children}
    </div>
  )
}

export default ShortBottomActionWrapper
