import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./animal-species-select.module.css"
import TaskCategoryIcon from "@/assets/icons/task/task-category-icon.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import { getSpeciesIcon, type Species } from "@/features/farm"
import { useGetSpeciesQuery } from "@/features/farm/api/species-api.ts"
import AnimalIconCard from "@/features/farm/components/animal-icon-card/animal-icon-card.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"

interface CategorySelectProps {
  speciesId: string
  onSpeciesIdChange: (selection: Species) => void
  isRequired?: boolean
}

const AnimalSpeciesSelect: React.FC<CategorySelectProps> = ({
  speciesId,
  onSpeciesIdChange,
  isRequired = false,
}) => {
  const { t } = useTranslation()
  const { data: species_breeds } = useGetSpeciesQuery()
  const currentSelectedSpeciesObject: Species | undefined = species_breeds
    ? species_breeds[speciesId]
    : undefined

  const handleCategorySelect = (species: Species) => {
    if (speciesId === species.id) {
      return
    }
    onSpeciesIdChange(species)
  }

  return (
    <OverflowMenuItem>
      <div className={styles["card-flex"]}>
        <div className={styles["title-flex"]}>
          <TaskCategoryIcon />
          <div className={styles["title"]}>{t("category")}</div>
        </div>

        {currentSelectedSpeciesObject && speciesId !== "" && (
          <div className={styles["selected-category"]}>
            {getSpeciesIcon(currentSelectedSpeciesObject.name)}
            <div className={styles["title"]}>
              {t(currentSelectedSpeciesObject.name)}
            </div>
          </div>
        )}
      </div>

      <div className={styles["categories-section-animal"]}>
        {Object.values(species_breeds || {}).map((species) => (
          <AnimalIconCard
            key={species.id}
            category={species}
            onClick={() => handleCategorySelect(species)}
            isSelected={speciesId === species.id}
          />
        ))}
      </div>
      {isRequired && <RequiredIndicator />}
    </OverflowMenuItem>
  )
}

export default React.memo(AnimalSpeciesSelect)
