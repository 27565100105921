import { PASTEL_RED, WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const AnimalStatusHeatIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 40}
      height={props.size || 40}
      viewBox="4172.679 -5730.282 40 40"
    >
      <g data-name="Group 4257">
        <path
          d="M4172.679-5710.332a20 20 0 1 1 0 .1z"
          fill={props.secondary_color ? props.secondary_color : WHITE}
          fillRule="evenodd"
          data-name="Ellipse Calduri"
        />
        <g data-name="Path 1621">
          <path
            d="M4203.947-5717.135h-1.513l1.569-1.629a.783.783 0 0 0 0-1.076.714.714 0 0 0-1.036 0l-1.568 1.63v-1.57c0-.42-.328-.761-.732-.761-.405 0-.732.34-.732.76v3.092l-.531.551c-.705-.579-1.735-1.09-3.172-1.09-2.092 0-3.5 1.102-4.24 1.893-.742-.791-2.15-1.892-4.242-1.892-1.92 0-3.112.912-3.774 1.677a5.45 5.45 0 0 0-1.297 3.521c0 2.5 1.254 3.803 2.58 5.182.57.59 1.65 1.566 2.757 2.537l-2.092 2.172v-1.865c0-.42-.328-.76-.732-.76-.404 0-.732.34-.732.76v3.7c0 .026.002.05.004.076 0 .01.003.02.004.03.001.015.003.03.006.043l.008.036.01.036.01.035.014.036.013.031c.006.013.01.025.018.037l.016.028.02.035.022.03.022.03a.788.788 0 0 0 .042.048l.006.008.008.006c.014.016.03.03.046.044l.027.022.03.023c.01.008.022.014.032.02l.03.018.033.017.031.015.033.013c.011.004.023.01.035.013l.034.008.036.01a.619.619 0 0 0 .07.01.645.645 0 0 0 .073.004h3.563c.404 0 .732-.34.732-.76s-.328-.76-.732-.76h-1.796l2.184-2.269c1.243 1.074 2.296 1.958 2.388 2.035a.714.714 0 0 0 .92 0c.197-.165 4.846-4.071 6.27-5.551 1.328-1.379 2.582-2.681 2.582-5.182a5.49 5.49 0 0 0-.9-2.997l.566-.588h2.977c.404 0 .732-.34.732-.76s-.328-.76-.732-.76Zm-4.107 5.106c0 1.788-.738 2.638-2.152 4.107-1.116 1.159-4.465 4.014-5.696 5.055-1.232-1.041-4.58-3.896-5.696-5.055-1.414-1.47-2.153-2.32-2.153-4.107 0-1.787 1.265-3.678 3.608-3.678s3.574 1.89 3.622 1.968c.133.219.366.35.615.352h.004a.73.73 0 0 0 .615-.349c.051-.08 1.255-1.971 3.625-1.971.854 0 1.564.252 2.123.659 2.255 2.146.287-.495.989 1.122.327.583.496 1.247.496 1.897Z"
            fill={props.primary_color ? props.primary_color : PASTEL_RED}
            fillRule="evenodd"
          />
          <path
            d="M4203.947-5717.135h-1.513l1.569-1.629a.783.783 0 0 0 0-1.076.714.714 0 0 0-1.036 0l-1.568 1.63v-1.57c0-.42-.328-.761-.732-.761-.405 0-.732.34-.732.76v3.092l-.531.551c-.705-.579-1.735-1.09-3.172-1.09-2.092 0-3.5 1.102-4.24 1.893-.742-.791-2.15-1.892-4.242-1.892-1.92 0-3.112.912-3.774 1.677a5.45 5.45 0 0 0-1.297 3.521c0 2.5 1.254 3.803 2.58 5.182.57.59 1.65 1.566 2.757 2.537l-2.092 2.172v-1.865c0-.42-.328-.76-.732-.76-.404 0-.732.34-.732.76v3.7h0c0 .026.002.05.004.076 0 .01.003.02.004.03.001.015.003.03.006.043l.008.036.01.036.01.035.014.036.013.031c.006.013.01.025.018.037l.016.028.02.035.022.03.022.03a.788.788 0 0 0 .042.048l.006.008.008.006c.014.016.03.03.046.044l.027.022.03.023c.01.008.022.014.032.02l.03.018.033.017.031.015.033.013c.011.004.023.01.035.013l.034.008.036.01a.619.619 0 0 0 .07.01.645.645 0 0 0 .073.004h3.563c.404 0 .732-.34.732-.76s-.328-.76-.732-.76h-1.796l2.184-2.269c1.243 1.074 2.296 1.958 2.388 2.035a.714.714 0 0 0 .92 0c.197-.165 4.846-4.071 6.27-5.551 1.328-1.379 2.582-2.681 2.582-5.182a5.49 5.49 0 0 0-.9-2.997l.566-.588h2.977c.404 0 .732-.34.732-.76s-.328-.76-.732-.76Zm-4.107 5.106c0 1.788-.738 2.638-2.152 4.107-1.116 1.159-4.465 4.014-5.696 5.055-1.232-1.041-4.58-3.896-5.696-5.055-1.414-1.47-2.153-2.32-2.153-4.107 0-1.787 1.265-3.678 3.608-3.678s3.574 1.89 3.622 1.968c.133.219.366.35.615.352h.004a.73.73 0 0 0 .615-.349c.051-.08 1.255-1.971 3.625-1.971.854 0 1.564.252 2.123.659 2.255 2.146.287-.495.989 1.122.327.583.496 1.247.496 1.897Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth=".3"
            stroke={props.primary_color ? props.primary_color : PASTEL_RED}
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default AnimalStatusHeatIcon
