import React from "react"
import { useTranslation } from "react-i18next"
import SearchBar from "@/components/search-bar/search-bar.tsx"

interface TransactionsFilterTabProps {}

const GestationCalendarFilterTab: React.FC<
  TransactionsFilterTabProps
> = ({}) => {
  const { t } = useTranslation()

  return (
    <SearchBar value={""} onChange={() => {}} placeholder={t("searchAnimal")} />
  )
}

export default GestationCalendarFilterTab
