import { type ChangeEvent, useCallback } from "react"
import { useTranslation } from "react-i18next"
import FarmIconImage from "@/assets/icons/farm/farm-icon-image.tsx"
import SelectPictureCircleBase64 from "@/components/select-picture-circle-base64/select-picture-circle-base64.tsx"
import { type Address } from "@/features/settings-account"
import styles from "@/features/settings-account/routes/settings-routes.module.css"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import {
  setDraftFarmDescription,
  setDraftFarmName,
  setDraftFarmPicture,
} from "@/redux/slices/farm-profile-draft-slice.ts"
import { InputType } from "@/utils/enums/input-type-enum.ts"

const FarmEditor = () => {
  const { t } = useTranslation()

  const draftFarmProfile = useAppSelector(
    (state) => state.farmProfileSlice.draft,
  )

  const dispatch = useAppDispatch()

  const handleNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDraftFarmName(e.target.value))
    },
    [dispatch],
  )

  const handleDescriptionChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(setDraftFarmDescription(e.target.value))
    },
    [dispatch],
  )

  const handleChoosePicture = useCallback(
    (picture: string | undefined) => {
      dispatch(setDraftFarmPicture(picture))
    },
    [dispatch],
  )

  const getAddressRep = (address: Address) => {
    return `${address.street}, ${address.city}, ${address.state}, ${address.country}`
  }

  return (
    <div className={styles.settingsContent}>
      <SelectPictureCircleBase64
        onChoosePicture={handleChoosePicture}
        picture={draftFarmProfile?.picture}
        icon={<FarmIconImage />}
        info={t("setFarmImage")}
      />

      <div className={styles.card}>
        <label htmlFor="farmName" className={styles.cardItemName}>
          {t("farmName")}
        </label>
        <input
          id="farmName"
          name="name"
          className={styles.titleInput}
          type={InputType.TEXT}
          autoComplete={"off"}
          placeholder={t("farmNameRequired")}
          value={draftFarmProfile.name}
          onChange={handleNameChange}
        />

        <div className={styles.cardItemName}>{t("address")}</div>
        <div className={styles.titleInput}>
          {draftFarmProfile.address && getAddressRep(draftFarmProfile.address)}
        </div>

        <label htmlFor="description" className={styles.cardItemName}>
          {t("description")}
        </label>
        <textarea
          id="description"
          name="description"
          autoComplete={"off"}
          placeholder={t("description")}
          className={styles.titleInput}
          value={draftFarmProfile.description}
          rows={6}
          onChange={handleDescriptionChange}
        />
      </div>
    </div>
  )
}

export default FarmEditor
