import isEqual from "lodash.isequal"
import { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import ArrowRight from "@/assets/icons/misc/arrow-right.tsx"
import { SelectPill } from "@/components/select-pill/select-pill.tsx"
import styles from "@/components/shadcn/popover/popover.module.css"
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@/components/shadcn/popover.tsx"
import {
  NOTIFICATION_BLACK,
  WHITE,
} from "@/utils/constants/colors-constants.ts"
import { type Option } from "@/utils/types/misc-types.ts"

interface GenericPopoverProps<T = string> {
  options: Option<T>[]
  onSelectionChange: (selection: T) => void
  currentSelection?: T
}

function CustomSelectPill<T = string>({
  options,
  onSelectionChange,
  currentSelection,
}: GenericPopoverProps<T>) {
  const getCurrentSelectedOption = useCallback(() => {
    if (currentSelection !== undefined) {
      return options.find((option) => option.value === currentSelection)
    } else {
      return options[0]
    }
  }, [currentSelection, options])

  const selectedOption = getCurrentSelectedOption()

  const { t } = useTranslation()
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const handleSelectionChange = (selection: Option<T>) => {
    onSelectionChange(selection.value)
    setIsPopoverOpen(false)
  }

  const selectedStyle = {
    backgroundColor: NOTIFICATION_BLACK,
    color: "white",
    border: "none",
    stroke: "white",
  }

  const isSelected = !isEqual(selectedOption, options[0])

  return (
    <Popover open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <PopoverTrigger asChild>
        <SelectPill style={isSelected ? selectedStyle : undefined}>
          {t(selectedOption ? selectedOption.label : "")}
          {isPopoverOpen ? (
            <ArrowRight
              stroke={isSelected ? WHITE : undefined}
              className={styles["caret-icon-up"]}
            />
          ) : (
            <ArrowRight
              stroke={isSelected ? WHITE : undefined}
              className={styles["caret-icon-down"]}
            />
          )}
        </SelectPill>
      </PopoverTrigger>
      <PopoverContent className="z-[600] min-w-[120px]">
        {options &&
          options.map((option, index) => (
            <div
              key={index}
              className={
                "-mr-4 flex items-center justify-between border-b border-[#e0e0e0] p-3 first:pt-0 last:border-b-0 last:pb-0"
              }
              onClick={() => handleSelectionChange(option)}
            >
              {t(option.label)}
              {option.count && (
                <span className={styles.optionCount}>{option.count}</span>
              )}
            </div>
          ))}
      </PopoverContent>
    </Popover>
  )
}

export default CustomSelectPill
