import React from "react"
import { useTranslation } from "react-i18next"
import styles from "../filter-tab.module.css"
import CustomSelectPill from "@/components/custom-select/custom-select-pill.tsx"
import CustomToggle from "@/components/custom-toggle/custom-toggle.tsx"
import SearchBar from "@/components/search-bar/search-bar.tsx"
import { type TransactionFilterSelections } from "@/features/finance/hooks/use-transaction-filter.ts"
import { TRANSACTION_CATEGORY_TYPE } from "@/features/finance/types/finance-constants.ts"

interface TransactionsFilterTabProps {
  handleSearch: (value: string) => void
  selections: TransactionFilterSelections
  handleIncomeSelection: () => void
  handleExpenseSelection: () => void
  handleSelectedCategory: (selectedCategory: string) => void
  selectedCategories: { label: string; value: string }[]
}

const TransactionsFilterTab: React.FC<TransactionsFilterTabProps> = ({
  handleSearch,
  selections,
  handleIncomeSelection,
  handleExpenseSelection,
  handleSelectedCategory,
  selectedCategories,
}) => {
  const { t } = useTranslation()

  return (
    <>
      <SearchBar
        value={selections.searchQuery}
        onChange={(event) => handleSearch(event.target.value)}
        placeholder={t("searchTransaction")}
      />
      <div className={styles["horizontal-scroll"]}>
        <div className={styles["filter-flex"]}>
          <CustomToggle
            title={TRANSACTION_CATEGORY_TYPE.INCOMES}
            isSelected={selections.incomes}
            onChange={handleIncomeSelection}
          />
          <CustomToggle
            title={TRANSACTION_CATEGORY_TYPE.EXPENSES}
            isSelected={selections.expenses}
            onChange={handleExpenseSelection}
          />
          <div
            data-is-disabled={!selections.incomes && !selections.expenses}
            className={styles.customSelectWrapper}
          >
            <CustomSelectPill
              options={selectedCategories}
              onSelectionChange={handleSelectedCategory}
              currentSelection={selections.selectedCategory}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default TransactionsFilterTab
