import { Route } from "react-router-dom"
import { AccountTab } from "@/features/settings-account"
import CompanyInfoPage from "@/features/settings-account/routes/company-info/company-info.tsx"
import FarmProfilePage from "@/features/settings-account/routes/farm-profile/farm-profile.tsx"
import NotificationPreferences from "@/features/settings-account/routes/notification-preferences/notification-preferences.tsx"
import SettingsConfidentiality from "@/features/settings-account/routes/settings-confidentiality/settings-confidentiality.tsx"
import SettingsContact from "@/features/settings-account/routes/settings-contact/settings-contact.tsx"
import SettingsFaq from "@/features/settings-account/routes/settings-faq/settings-faq.tsx"
import SettingsPage from "@/features/settings-account/routes/settings-page/settings-page.tsx"
import SettingsResetPassword from "@/features/settings-account/routes/settings-reset-password/settings-reset-password.tsx"
import SettingsTerms from "@/features/settings-account/routes/settings-terms/settings-terms.tsx"
import SettingsTutorial from "@/features/settings-account/routes/settings-tutorial/settings-tutorial.tsx"
import UserProfileWrapper from "@/features/settings-account/routes/user-profile/user-profile-wrapper.tsx"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import {
  ACCOUNT_SCREEN_PATH,
  CONFIDENTIALITY_PATH,
  CONTACT_PATH,
  EDIT_FARM_PROFILE_PATH,
  EDIT_MY_PROFILE_PATH,
  FAQ_PATH,
  NOT_ENABLED,
  NOTIFICATION_PREFERENCES_PATH,
  RESET_PASSWORD_PATH,
  SETTINGS_SCREEN_PATH,
  TERMS_PATH,
  TUTORIAL_PATH,
  VIEW_COMPANY_INFO_PATH,
} from "@/utils/constants/routes.ts"
import NotEnabled from "@/features/settings-account/routes/not-purchased/not-enabled.tsx"

const SettingsRoutesArray = [
  <Route
    key={SETTINGS_SCREEN_PATH}
    path={SETTINGS_SCREEN_PATH}
    element={<SettingsPage />}
  />,
  <Route
    key={EDIT_FARM_PROFILE_PATH}
    path={EDIT_FARM_PROFILE_PATH}
    element={<FarmProfilePage />}
  />,
  <Route
    key={VIEW_COMPANY_INFO_PATH}
    path={VIEW_COMPANY_INFO_PATH}
    element={<CompanyInfoPage />}
  />,
  <Route
    key={EDIT_MY_PROFILE_PATH}
    path={EDIT_MY_PROFILE_PATH}
    element={<UserProfileWrapper />}
  />,
  <Route
    key={RESET_PASSWORD_PATH}
    path={RESET_PASSWORD_PATH}
    element={<SettingsResetPassword />}
  />,
  <Route
    key={TUTORIAL_PATH}
    path={TUTORIAL_PATH}
    element={<SettingsTutorial />}
  />,
  <Route key={FAQ_PATH} path={FAQ_PATH} element={<SettingsFaq />} />,
  <Route
    key={CONFIDENTIALITY_PATH}
    path={CONFIDENTIALITY_PATH}
    element={<SettingsConfidentiality />}
  />,
  <Route key={TERMS_PATH} path={TERMS_PATH} element={<SettingsTerms />} />,
  <Route
    key={CONTACT_PATH}
    path={CONTACT_PATH}
    element={<SettingsContact />}
  />,
  <Route
    key={ACCOUNT_SCREEN_PATH}
    path={ACCOUNT_SCREEN_PATH}
    element={<AccountTab />}
  />,
  <Route
    key={NOTIFICATION_PREFERENCES_PATH}
    path={NOTIFICATION_PREFERENCES_PATH}
    element={<NotificationPreferences />}
  />,
  <Route key={NOT_ENABLED} path={NOT_ENABLED} element={<NotEnabled />} />,
]

export const SettingsRoutes = (
  <Route element={<NoMenuLayout />}>{SettingsRoutesArray}</Route>
)
