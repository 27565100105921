import React from "react"

export interface AddCircleProps {
  size?: string | number
}

const AddCircleIcon: React.FC<AddCircleProps> = ({ size = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 30 30"
    >
      <g data-name="Group 1066">
        <path
          d="M25.612 25.612c5.85-5.85 5.85-15.373 0-21.224-5.85-5.85-15.373-5.85-21.224 0-5.85 5.85-5.85 15.373 0 21.224 5.85 5.85 15.373 5.85 21.224 0ZM5.447 5.447c5.269-5.27 13.837-5.27 19.106 0 5.27 5.269 5.27 13.837 0 19.106-5.269 5.27-13.837 5.27-19.106 0-5.27-5.269-5.263-13.837 0-19.106Z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Path 801"
        />
        <path
          d="M14.985 21.84a.918.918 0 0 0 .916-.916v-5.015h5.014a.918.918 0 0 0 .916-.916.903.903 0 0 0-.908-.91h-5.014V9.07A.903.903 0 0 0 15 8.16a.903.903 0 0 0-.909.91v5.014H9.077a.903.903 0 0 0-.909.909c0 .506.41.916.91.909h5.013v5.013a.885.885 0 0 0 .894.924Z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Path 802"
        />
      </g>
    </svg>
  )
}

export default AddCircleIcon
