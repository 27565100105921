import { format } from "date-fns"
import React from "react"
import { useTranslation } from "react-i18next"
import AinIcon from "@/assets/icons/farm/ain-icon.tsx"
import BirthdayIcon from "@/assets/icons/farm/birthday-icon.tsx"
import BreedIcon from "@/assets/icons/farm/breed-icon.tsx"
import DetailIcon from "@/assets/icons/farm/details-icon.tsx"
import LocationIcon from "@/assets/icons/farm/location-icon.tsx"
import MaleFemaleIcon from "@/assets/icons/farm/male-female-icon.tsx"
import ParentAinIcon from "@/assets/icons/farm/parent-ain-icon.tsx"
import ProductionIcon from "@/assets/icons/farm/production-icon.tsx"
import RegistryIcon from "@/assets/icons/farm/registry-icon.tsx"
import TreatmentsCountIcon from "@/assets/icons/ruminant-details/treatments-count-icon.tsx"
import DateIcon from "@/assets/icons/task/date-icon.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import { type Animal, getSpeciesHeadIcon } from "@/features/farm"
import styles from "@/features/farm/components/animal-details/animal-details.module.css"
import { AnimalField } from "@/features/farm/components/animal-field/animal-field.tsx"
import AssociatedAnimalCard from "@/features/farm/components/associated-animals-list/associated-animal-card.tsx"
import {
  NOTIFICATION_BLUE,
  NOTIFICATION_RED,
} from "@/utils/constants/colors-constants.ts"

interface AnimalProfileProps {
  selectedAnimal: Animal
}

export const AnimalDetails: React.FC<AnimalProfileProps> = ({
  selectedAnimal,
}) => {
  const { t } = useTranslation()

  return (
    <OverflowMenuItem>
      <div className={styles.animalTitle}>
        {t("animalSummary")}
        <div className={styles.separator}></div>
      </div>

      <AnimalField text={selectedAnimal.name}>
        {getSpeciesHeadIcon(selectedAnimal.species_details?.name)}
      </AnimalField>

      <AnimalField text={selectedAnimal.ain}>
        <AinIcon />
      </AnimalField>

      <AnimalField text={selectedAnimal.location}>
        <LocationIcon />
      </AnimalField>

      <AnimalField text={t(`${selectedAnimal.gender}`)}>
        <MaleFemaleIcon />
      </AnimalField>

      <AnimalField text={format(selectedAnimal.birth_date, "dd.MM.yyyy")}>
        <BirthdayIcon />
      </AnimalField>

      <AnimalField text={selectedAnimal.breed_details?.name}>
        <BreedIcon />
      </AnimalField>

      <AnimalField text={format(selectedAnimal.entry_date, "dd.MM.yyyy")}>
        <DateIcon fill={"#C5B6A9"} />
      </AnimalField>

      <AnimalField text={t(`${selectedAnimal.entry_way}`)}>
        <RegistryIcon />
      </AnimalField>

      {selectedAnimal && selectedAnimal.species_details?.name !== "Pork" && (
        <AnimalField text={t(`${selectedAnimal.intended_purpose}`)}>
          <ProductionIcon />
        </AnimalField>
      )}

      <AnimalField
        text={t("medical_treatments_count")}
        value={selectedAnimal.medical_treatments_count}
      >
        <TreatmentsCountIcon />
      </AnimalField>

      {selectedAnimal.description && (
        <AnimalField text={t(`${selectedAnimal.description}`)}>
          <DetailIcon />
        </AnimalField>
      )}

      {selectedAnimal?.mother && selectedAnimal?.mother !== "" && (
        <>
          <div className={styles.separator}></div>
          <AnimalField text={t("animalMother")}>
            <ParentAinIcon color={NOTIFICATION_RED} />
          </AnimalField>
          <AssociatedAnimalCard animal={selectedAnimal.mother} />
        </>
      )}
      {selectedAnimal?.father && selectedAnimal?.father !== "" && (
        <>
          <div className={styles.separator}></div>
          <AnimalField text={t("animalFather")}>
            <ParentAinIcon color={NOTIFICATION_BLUE} />
          </AnimalField>
          <AssociatedAnimalCard animal={selectedAnimal.father} />
        </>
      )}
    </OverflowMenuItem>
  )
}
