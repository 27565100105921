import {
  ANIMAL_AGES,
  ANIMAL_GENDERS,
  ANIMAL_STATUS_ENUM,
} from "@/features/farm"
import { useAppSelector } from "@/redux/hooks.ts"

const useGetAnimalsOptionsWithCount = () => {
  const animalFilterOptionsWithCount = useAppSelector(
    (state) => state.animalFilterSlice,
  )

  const animalStatusesWithCount = Object.entries(ANIMAL_STATUS_ENUM).map(
    ([key, value]) => ({
      label: key,
      value: value,
      count: animalFilterOptionsWithCount.statuses[value],
    }),
  )

  const animalGendersWithCount = Object.values(ANIMAL_GENDERS).map(
    ({ label }) => ({
      label: label,
      value: label,
      count: animalFilterOptionsWithCount.genders[label],
    }),
  )

  const animalAgesWithCount = Object.entries(ANIMAL_AGES).map(([_, value]) => ({
    label: value.label,
    value: value.value,
    count: animalFilterOptionsWithCount.ages[value.label],
  }))

  return {
    animalStatusesWithCount,
    animalGendersWithCount,
    animalAgesWithCount,
  }
}

export default useGetAnimalsOptionsWithCount
