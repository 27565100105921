import React from "react"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const ProductionIcon: React.FC<GeneralSvgProps> = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="5645 -1216 30 30"
    >
      <g data-name="CompositeLayer">
        <path
          d="M5652-1216h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7h-16a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="var(--error-color)"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <g data-name="Path 1302">
          <path
            d="M5658.176-1204.097c0-.9-.728-1.628-1.627-1.628h-4.423c-.9 0-1.628.729-1.628 1.627v.24h7.678v-.24Zm.175 1.07h-8.027a.655.655 0 0 1-.656-.655v-.416c0-1.357 1.1-2.457 2.458-2.457h4.423c1.357 0 2.457 1.1 2.457 2.458v.415a.655.655 0 0 1-.655.655Zm-.694 2.49a.522.522 0 0 0 .52-.519.522.522 0 0 0-.52-.519h-6.64a.522.522 0 0 0-.519.52c0 .284.235.518.52.518h6.64Zm0 .83h-6.64a1.352 1.352 0 0 1-1.349-1.349c0-.743.606-1.349 1.35-1.349h6.64c.742 0 1.348.606 1.348 1.35 0 .742-.606 1.348-1.349 1.348Zm-7.574 1.4c.546 0 .804.046 1.301.191.566.166.899.225 1.534.225.636 0 .968-.059 1.535-.225.497-.145.755-.19 1.302-.19.546 0 .804.045 1.301.19.567.166.9.225 1.535.225a.415.415 0 1 0 0-.83c-.546 0-.805-.046-1.302-.191-.566-.166-.899-.224-1.534-.224-.636 0-.969.058-1.535.224-.497.145-.756.19-1.302.19s-.804-.045-1.3-.19c-.567-.166-.9-.224-1.535-.224a.415.415 0 1 0 0 .83Zm8.268 1.09c.362 0 .655.293.655.655v.415c0 1.357-1.1 2.458-2.457 2.458h-4.423a2.457 2.457 0 0 1-2.458-2.458v-.415c0-.362.294-.655.656-.655h8.027Zm-7.853.83v.24c0 .9.729 1.628 1.628 1.628h4.423c.899 0 1.627-.729 1.627-1.628v-.24h-7.678Zm18.966-7.159c0-.032-.043-.1-.168-.193-.171-.128-.441-.255-.788-.365-.749-.238-1.78-.376-2.883-.376s-2.133.138-2.882.376c-.347.11-.617.237-.788.365-.125.094-.168.161-.168.193s.043.1.168.193c.171.129.441.255.788.365.749.238 1.78.376 2.882.376 1.104 0 2.134-.138 2.883-.376.347-.11.617-.236.788-.365.125-.094.168-.16.168-.193Zm.83 0c0 1.103-2.084 1.764-4.668 1.764s-4.67-.661-4.67-1.764 2.086-1.764 4.67-1.764 4.668.661 4.668 1.764Zm-1.661 8.461c0 .046-.235.223-.718.376-.598.19-1.423.3-2.307.3-.885 0-1.71-.11-2.308-.3-.483-.153-.718-.33-.718-.376a.415.415 0 1 0-.83 0c0 .96 1.72 1.506 3.856 1.506 2.134 0 3.855-.546 3.855-1.506a.415.415 0 1 0-.83 0Z"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="M5658.176-1204.097c0-.9-.728-1.628-1.627-1.628h-4.423c-.9 0-1.628.729-1.628 1.627v.24h7.678v-.24Zm.175 1.07h-8.027a.655.655 0 0 1-.656-.655v-.416c0-1.357 1.1-2.457 2.458-2.457h4.423c1.357 0 2.457 1.1 2.457 2.458v.415a.655.655 0 0 1-.655.655Zm-.694 2.49a.522.522 0 0 0 .52-.519.522.522 0 0 0-.52-.519h-6.64a.522.522 0 0 0-.519.52c0 .284.235.518.52.518h6.64Zm0 .83h-6.64a1.352 1.352 0 0 1-1.349-1.349c0-.743.606-1.349 1.35-1.349h6.64c.742 0 1.348.606 1.348 1.35 0 .742-.606 1.348-1.349 1.348Zm-7.574 1.4c.546 0 .804.046 1.301.191.566.166.899.225 1.534.225.636 0 .968-.059 1.535-.225.497-.145.755-.19 1.302-.19.546 0 .804.045 1.301.19.567.166.9.225 1.535.225a.415.415 0 1 0 0-.83c-.546 0-.805-.046-1.302-.191-.566-.166-.899-.224-1.534-.224-.636 0-.969.058-1.535.224-.497.145-.756.19-1.302.19s-.804-.045-1.3-.19c-.567-.166-.9-.224-1.535-.224a.415.415 0 1 0 0 .83Zm8.268 1.09c.362 0 .655.293.655.655v.415c0 1.357-1.1 2.458-2.457 2.458h-4.423a2.457 2.457 0 0 1-2.458-2.458v-.415c0-.362.294-.655.656-.655h8.027Zm-7.853.83v.24c0 .9.729 1.628 1.628 1.628h4.423c.899 0 1.627-.729 1.627-1.628v-.24h-7.678Zm18.966-7.159c0-.032-.043-.1-.168-.193-.171-.128-.441-.255-.788-.365-.749-.238-1.78-.376-2.883-.376s-2.133.138-2.882.376c-.347.11-.617.237-.788.365-.125.094-.168.161-.168.193s.043.1.168.193c.171.129.441.255.788.365.749.238 1.78.376 2.882.376 1.104 0 2.134-.138 2.883-.376.347-.11.617-.236.788-.365.125-.094.168-.16.168-.193Zm.83 0c0 1.103-2.084 1.764-4.668 1.764s-4.67-.661-4.67-1.764 2.086-1.764 4.67-1.764 4.668.661 4.668 1.764Zm-1.661 8.461c0 .046-.235.223-.718.376-.598.19-1.423.3-2.307.3-.885 0-1.71-.11-2.308-.3-.483-.153-.718-.33-.718-.376a.415.415 0 1 0-.83 0c0 .96 1.72 1.506 3.856 1.506 2.134 0 3.855-.546 3.855-1.506a.415.415 0 1 0-.83 0Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth=".1"
            stroke="#fff"
            fill="transparent"
          />
        </g>
        <g data-name="Path 1303">
          <path
            d="M5668.653-1208.422h-2.094c-.679 0-1.226.555-1.226 1.236v3.536a.415.415 0 0 0 .83 0v-3.536c0-.225.178-.406.396-.406h2.094a.415.415 0 0 0 0-.83Zm-6.054 13.297-.8-8.321a.415.415 0 0 0-.827.08l.8 8.321a.415.415 0 0 0 .827-.08Zm6.867-8.322-.812 8.322a.415.415 0 0 0 .826.08l.812-8.321a.415.415 0 1 0-.826-.08Z"
            fill="#fff"
            fillRule="evenodd"
          />
          <path
            d="M5668.653-1208.422h-2.094c-.679 0-1.226.555-1.226 1.236v3.536a.415.415 0 0 0 .83 0v-3.536c0-.225.178-.406.396-.406h2.094a.415.415 0 0 0 0-.83Zm-6.054 13.297-.8-8.321a.415.415 0 0 0-.827.08l.8 8.321a.415.415 0 0 0 .827-.08Zm6.867-8.322-.812 8.322a.415.415 0 0 0 .826.08l.812-8.321a.415.415 0 1 0-.826-.08Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth=".1"
            stroke="#fff"
            fill="transparent"
          />
        </g>
      </g>
    </svg>
  )
}

export default ProductionIcon
