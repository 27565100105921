import { parseISO, compareDesc } from "date-fns"
import { type GenericAnimalEvent } from "@/features/animal-events/types/animal-events.ts"
import { apiSlice, TagType } from "@/features/api/apiSlice.ts"

const ANIMAL_EVENTS_API_BASE_URL = "/animal-events/"

const sortEvents = (events: GenericAnimalEvent[]) => {
  return events.sort((a, b) => {
    const dateComparison = compareDesc(parseISO(a.date), parseISO(b.date))
    if (dateComparison !== 0) {
      return dateComparison
    }
    return compareDesc(parseISO(a.created_at), parseISO(b.created_at))
  })
}

export const animalEventsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createAnimalEvent: builder.mutation<
      GenericAnimalEvent,
      Partial<GenericAnimalEvent>
    >({
      query: (animalEvent) => {
        const { event_data, ...rest } = animalEvent
        const modifiedAnimalEvent = {
          ...rest,
          event_sub_data: event_data,
        }

        return {
          url: ANIMAL_EVENTS_API_BASE_URL,
          method: "POST",
          body: modifiedAnimalEvent,
        }
      },
      invalidatesTags: [
        { type: TagType.AnimalEvents },
        { type: TagType.Animals },
        { type: TagType.GestationCalendars },
      ],
    }),
    getAnimalEventById: builder.query<GenericAnimalEvent, string>({
      query: (id) => ({
        url: `${ANIMAL_EVENTS_API_BASE_URL}${id}/`,
      }),
      providesTags: (_result, _error, id) => [
        { type: TagType.AnimalEvents, id },
      ],
    }),
    updateAnimalEvent: builder.mutation<
      GenericAnimalEvent,
      { id: GenericAnimalEvent["id"] } & Partial<GenericAnimalEvent>
    >({
      query: ({ id, ...patch }) => {
        const { event_data, ...rest } = patch
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { event, ...event_sub_data } = event_data || {}
        const modifiedPatch = {
          ...rest,
          event_sub_data: event_sub_data,
        }

        return {
          url: `${ANIMAL_EVENTS_API_BASE_URL}${id}/`,
          method: "PATCH",
          body: modifiedPatch,
        }
      },
      invalidatesTags: (_result, _error, { id }) => [
        { type: TagType.AnimalEvents, id },
        { type: TagType.AnimalEvents, id: "LIST" },
      ],
    }),
    deleteAnimalEvent: builder.mutation<void, Partial<GenericAnimalEvent>>({
      query: (event) => ({
        url: `${ANIMAL_EVENTS_API_BASE_URL}${event.id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (_result, _error, event) => [
        { type: TagType.AnimalEvents, id: event.animal },
        { type: TagType.AnimalEvents, id: "LIST" },
      ],
    }),
    getAllAnimalEvents: builder.query<GenericAnimalEvent[], void>({
      query: () => ({
        url: ANIMAL_EVENTS_API_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.AnimalEvents, id: "LIST" }],
      transformResponse: (response: GenericAnimalEvent[]) => {
        return sortEvents(response)
      },
    }),
    getEventsForAnimal: builder.query<GenericAnimalEvent[], string>({
      query: (animalId) => ({
        url: `${ANIMAL_EVENTS_API_BASE_URL}?animal_id=${animalId}`,
      }),
      providesTags: (_result, _error, animalId) => [
        { type: TagType.AnimalEvents, id: animalId },
      ],
      transformResponse: (response: GenericAnimalEvent[]) => {
        return sortEvents(response)
      },
    }),
  }),
})

export const {
  useCreateAnimalEventMutation,
  useGetEventsForAnimalQuery,
  useGetAnimalEventByIdQuery,
  useUpdateAnimalEventMutation,
  useDeleteAnimalEventMutation,
  useGetAllAnimalEventsQuery,
} = animalEventsApi
