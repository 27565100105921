import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import ReactQuill from "react-quill"
import "./quill-snow.css"
import { type EditorHandles } from "@/features/notes/components/editor/note-editor.tsx"

interface HistoryModule {
  undo: () => void
  redo: () => void
  stack: {
    undo: any[]
    redo: any[]
  }
}
declare module "quill" {
  interface Quill {
    history: HistoryModule
  }
}

/**
 * Props for the QuillEditor component.
 */
interface QuillEditorProps {
  /**
   * The content of the editor.
   */
  content: string
  /**
   * Function to update the content of the editor.
   */
  setContent: (content: string) => void
  /**
   * Reference to the ReactQuill instance.
   */
  quillRef: React.MutableRefObject<ReactQuill | null>
}

/**
 * QuillEditor component for creating rich text content.
 * @param {QuillEditorProps} props - The props for the QuillEditor component.
 * @returns {React.ReactNode} - The JSX element representing the QuillEditor component.
 */
const QuillEditor: React.ForwardRefRenderFunction<
  EditorHandles,
  QuillEditorProps
> = ({ content, setContent, quillRef }: QuillEditorProps): React.ReactNode => {
  const modules = useMemo(
    () => ({
      history: {
        delay: 1000,
        maxStack: 50,
        userOnly: false,
      },
    }),
    [],
  )
  const { t } = useTranslation()

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      value={content}
      onChange={setContent}
      modules={modules}
      placeholder={t("placeholderText")}
      className={`ql-container`}
      style={{
        height: "100%",
        padding: "10px",
        paddingLeft: "20px",
        width: "100%",
        overflow: "scroll",
        boxSizing: "border-box",
      }}
    />
  )
}

export default QuillEditor
