import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { logout } from "@/redux/slices/auth-slice.ts"
import { type RootState } from "@/redux/store.ts"

const BASE_URL = import.meta.env.VITE_API_URL

export enum TagType {
  Notes = "Notes",
  Tasks = "Tasks",
  TaskCategories = "TaskCategories",
  UserProfile = "UserProfile",
  FarmProfile = "FarmProfile",
  Animals = "Animals",
  Species = "Species",
  Breeds = "Breeds",
  Notifications = "Notifications",
  NotificationPreferences = "NotificationPreferences",
  Transactions = "Transactions",
  TransactionCategories = "TransactionCategories",
  TransactionsSummary = "TransactionsSummary",
  EventTypes = "EventTypes",
  MedicationTypes = "MedicationTypes",
  AnimalEvents = "AnimalEvents",
  Assistant = "Assistant",
  GestationCalendars = "GestationCalendars",
}

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).auth.token
    if (token) {
      headers.set("authorization", `Token ${token}`)
    }
    return headers
  },
})

const baseQueryWithReauth: typeof baseQuery = async (
  args,
  api,
  extraOptions,
) => {
  const result = await baseQuery(args, api, extraOptions)

  if (result.error) {
    if (result.error.status === 401 || result.error.status === 403) {
      // Unauthorized, log out the user
      api.dispatch(logout())
    }
  }

  return result
}

export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  refetchOnReconnect: true,
  refetchOnFocus: true,
  refetchOnMountOrArgChange: 60,
  endpoints: () => ({}),
  tagTypes: Object.values(TagType).filter(
    (value) => TagType[value as keyof typeof TagType] === value,
  ),
})
