import React from "react"
import { useTranslation } from "react-i18next"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import {
  type MULTIPLE,
  type SINGLE,
} from "@/features/animal-events/types/animal-events-constants.ts"
import DrawerAnimalContent from "@/features/farm/components/drawer-animals/drawer-animal-content.tsx"

export type DrawerSelectionType = typeof SINGLE | typeof MULTIPLE

interface CombinedComponentProps {
  currentValue: string[] | string
  onSaveClick: (animalIDs: string[]) => void
  selectionType?: DrawerSelectionType
  TriggerComponent: React.FC<TriggerComponentProps>
}

interface TriggerComponentProps {
  currentValue: string[] | string
  onClick: () => void
}

const DrawerAnimals: React.FC<CombinedComponentProps> = ({
  onSaveClick,
  currentValue,
  selectionType = "multiple",
  TriggerComponent,
}) => {
  const { t } = useTranslation()
  return (
    <Drawer>
      <DrawerTrigger asChild>
        <TriggerComponent currentValue={currentValue} onClick={() => {}} />
      </DrawerTrigger>
      <DrawerContent>
        <DrawerAnimalContent
          selectionType={selectionType}
          currentValue={currentValue}
          onSaveClick={onSaveClick}
          placeholder={t("searchAnimal")}
        />
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerAnimals
