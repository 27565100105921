import React from "react"
import { BROWN, GREY } from "@/utils/constants/colors-constants.ts"

interface MaleIconProps {
  is_active?: boolean
}

const MaleIcon: React.FC<MaleIconProps> = ({ is_active }) => {
  const fillColor = is_active ? BROWN : GREY

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.628"
      height="22.634"
      viewBox="52.248 441.683 22.628 22.634"
    >
      <path
        d="M74.141 441.683h-5.925a.74.74 0 0 0 0 1.48h4.103l-4.908 4.94a9.195 9.195 0 0 0-5.963-2.192c-1.24 0-2.448.244-3.58.723a9.25 9.25 0 0 0-2.927 1.97 9.25 9.25 0 0 0-1.97 2.927 9.178 9.178 0 0 0-.723 3.585c0 1.246.244 2.449.723 3.58a9.25 9.25 0 0 0 1.97 2.927 9.13 9.13 0 0 0 2.927 1.97 9.17 9.17 0 0 0 3.58.724c1.24 0 2.448-.245 3.58-.724a9.25 9.25 0 0 0 2.927-1.97 9.13 9.13 0 0 0 1.97-2.927 9.17 9.17 0 0 0 .723-3.58 9.195 9.195 0 0 0-2.192-5.963l4.94-4.913v4.103a.74.74 0 0 0 1.48 0v-5.92a.733.733 0 0 0-.735-.74Zm-4.973 13.433a7.668 7.668 0 0 1-2.263 5.457 7.668 7.668 0 0 1-5.457 2.264 7.668 7.668 0 0 1-5.457-2.264 7.668 7.668 0 0 1-2.264-5.457c0-2.062.806-3.999 2.264-5.457a7.668 7.668 0 0 1 5.457-2.263c2.062 0 3.999.805 5.457 2.263a7.636 7.636 0 0 1 2.263 5.457Z"
        fill={fillColor}
        fillRule="evenodd"
        data-name="Icon ion-ios-male"
      />
    </svg>
  )
}

export default MaleIcon
