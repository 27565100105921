import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const CollapseMenuRightIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15"
      viewBox="207.5 1047.5 16 15"
    >
      <g data-name="collapse-left-svgrepo-com (1)">
        <path
          d="M212.707 1055H223.5a.5.5 0 0 1 0 1h-10.793l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708.708l-3.147 3.146Zm-4.707-6.5a.5.5 0 0 1 1 0v14a.5.5 0 0 1-1 0v-14Z"
          fill="#414141"
          fillRule="evenodd"
        />
        <path
          d="M212.707 1055H223.5a.5.5 0 0 1 0 1h-10.793l3.147 3.146a.5.5 0 0 1-.708.708l-4-4a.5.5 0 0 1 0-.708l4-4a.5.5 0 0 1 .708.708l-3.147 3.146h0Zm-4.707-6.5a.5.5 0 0 1 1 0v14a.5.5 0 0 1-1 0v-14Z"
          strokeLinejoin="round"
          strokeLinecap="round"
          stroke="#414141"
          fill="transparent"
        />
      </g>
    </svg>
  )
}

export default CollapseMenuRightIcon
