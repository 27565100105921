import { PASTEL_YELLOW, WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const AnimalStatusPregnantIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || 40}
      height={props.size || 40}
      viewBox="4299.679 -5730.282 40 40"
    >
      <g data-name="Group 4256">
        <path
          d="M4299.679-5710.332a20 20 0 1 1 0 .1z"
          fill={props.secondary_color ? props.secondary_color : WHITE}
          fillRule="evenodd"
          data-name="Ellipse Calduri"
        />
        <g data-name="Group 4238">
          <g data-name="Path 1610">
            <path
              d="M4332.797-5713.388c-2.529-2.636-5.648-1.999-5.779-1.97a.734.734 0 0 0-.13.041c-1.21-2.693-3.883-4.57-6.979-4.57s-5.77 1.876-6.98 4.57a.823.823 0 0 0-.13-.041c-.13-.029-3.25-.666-5.778 1.97-2.481 2.589-2.112 4.655-2.062 4.882.057.26.24.472.487.563.132.049 2.053.744 4.103.744.922 0 1.869-.141 2.692-.545v.056c0 4.301 3.441 7.801 7.67 7.801 4.228 0 7.669-3.5 7.669-7.801v-.056c.823.404 1.77.545 2.693.545 2.05 0 3.97-.695 4.102-.744a.78.78 0 0 0 .487-.563c.05-.227.42-2.293-2.061-4.882Zm-20.463.078a8.12 8.12 0 0 0-.997.431c-.728.371-1.709 1.37-1.819 1.483a.791.791 0 0 0 .006 1.105.76.76 0 0 0 1.087-.006c.346-.356 1.034-.991 1.414-1.184.074-.038.145-.072.214-.105v1.956l-.012.012c-1.32 1.343-4.266.818-5.77.365.053-.57.348-1.675 1.662-3.045 1.794-1.871 3.956-1.601 4.314-1.54-.039.173-.072.349-.1.527Zm7.573 11.861a6.043 6.043 0 0 1-3.893-1.422l.002-.032c0-1.378.895-2.5 1.994-2.5h3.794c1.099 0 1.994 1.122 1.994 2.5l.001.032a6.042 6.042 0 0 1-3.893 1.422Zm6.133-6.24c0 1.22-.347 2.357-.944 3.32-.512-1.518-1.794-2.598-3.292-2.598h-3.794c-1.498 0-2.78 1.079-3.293 2.597a6.278 6.278 0 0 1-.943-3.318v-4.398c0-3.44 2.75-6.238 6.132-6.238 3.382 0 6.133 2.798 6.133 6.238v4.398Zm1.548-1.928-.012-.012v-1.956c.07.033.14.067.215.105.379.193 1.069.829 1.414 1.184a.76.76 0 0 0 1.087.006.792.792 0 0 0 .006-1.105c-.109-.113-1.09-1.112-1.819-1.483a8.124 8.124 0 0 0-.996-.431 7.797 7.797 0 0 0-.1-.528c.358-.06 2.52-.33 4.315 1.541 1.314 1.37 1.608 2.476 1.662 3.046-1.504.454-4.446.981-5.77-.367Z"
              fill={props.primary_color ? props.primary_color : PASTEL_YELLOW}
              fillRule="evenodd"
            />
            <path
              d="M4332.797-5713.388c-2.529-2.636-5.648-1.999-5.779-1.97a.734.734 0 0 0-.13.041c-1.21-2.693-3.883-4.57-6.979-4.57s-5.77 1.876-6.98 4.57a.823.823 0 0 0-.13-.041c-.13-.029-3.25-.666-5.778 1.97-2.481 2.589-2.112 4.655-2.062 4.882.057.26.24.472.487.563.132.049 2.053.744 4.103.744.922 0 1.869-.141 2.692-.545v.056c0 4.301 3.441 7.801 7.67 7.801 4.228 0 7.669-3.5 7.669-7.801v-.056c.823.404 1.77.545 2.693.545 2.05 0 3.97-.695 4.102-.744a.78.78 0 0 0 .487-.563c.05-.227.42-2.293-2.061-4.882Zm-20.463.078a8.12 8.12 0 0 0-.997.431c-.728.371-1.709 1.37-1.819 1.483a.791.791 0 0 0 .006 1.105.76.76 0 0 0 1.087-.006c.346-.356 1.034-.991 1.414-1.184.074-.038.145-.072.214-.105v1.956l-.012.012c-1.32 1.343-4.266.818-5.77.365.053-.57.348-1.675 1.662-3.045 1.794-1.871 3.956-1.601 4.314-1.54-.039.173-.072.349-.1.527Zm7.573 11.861a6.043 6.043 0 0 1-3.893-1.422l.002-.032c0-1.378.895-2.5 1.994-2.5h3.794c1.099 0 1.994 1.122 1.994 2.5l.001.032a6.042 6.042 0 0 1-3.893 1.422Zm6.133-6.24c0 1.22-.347 2.357-.944 3.32-.512-1.518-1.794-2.598-3.292-2.598h-3.794c-1.498 0-2.78 1.079-3.293 2.597a6.278 6.278 0 0 1-.943-3.318v-4.398c0-3.44 2.75-6.238 6.132-6.238 3.382 0 6.133 2.798 6.133 6.238v4.398Zm1.548-1.928-.012-.012v-1.956c.07.033.14.067.215.105.379.193 1.069.829 1.414 1.184a.76.76 0 0 0 1.087.006.792.792 0 0 0 .006-1.105c-.109-.113-1.09-1.112-1.819-1.483a8.124 8.124 0 0 0-.996-.431 7.797 7.797 0 0 0-.1-.528c.358-.06 2.52-.33 4.315 1.541 1.314 1.37 1.608 2.476 1.662 3.046-1.504.454-4.446.981-5.77-.367Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke={props.primary_color ? props.primary_color : PASTEL_YELLOW}
              fill="transparent"
            />
          </g>
          <g data-name="Rectangle 1956">
            <path
              d="M4316.428-5710.717a1.13 1.13 0 0 1 1.13 1.13v.543a1.13 1.13 0 0 1-1.13 1.13 1.13 1.13 0 0 1-1.13-1.13v-.542a1.13 1.13 0 0 1 1.13-1.13z"
              fill={props.primary_color ? props.primary_color : PASTEL_YELLOW}
              fillRule="evenodd"
            />
            <path
              d="M4316.428-5710.717h0a1.13 1.13 0 0 1 1.13 1.13v.543a1.13 1.13 0 0 1-1.13 1.13h0a1.13 1.13 0 0 1-1.13-1.13v-.542a1.13 1.13 0 0 1 1.13-1.13z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke={props.primary_color ? props.primary_color : PASTEL_YELLOW}
              fill="transparent"
            />
          </g>
          <g data-name="Path 1611">
            <path
              d="M4323.387-5710.717a1.13 1.13 0 0 0-1.13 1.13v.543a1.13 1.13 0 0 0 2.26 0v-.543a1.13 1.13 0 0 0-1.13-1.13Z"
              fill={props.primary_color ? props.primary_color : PASTEL_YELLOW}
              fillRule="evenodd"
            />
            <path
              d="M4323.387-5710.717a1.13 1.13 0 0 0-1.13 1.13v.543a1.13 1.13 0 0 0 2.26 0v-.543a1.13 1.13 0 0 0-1.13-1.13Z"
              strokeLinejoin="round"
              strokeLinecap="round"
              strokeWidth=".3"
              stroke={props.primary_color ? props.primary_color : PASTEL_YELLOW}
              fill="transparent"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default AnimalStatusPregnantIcon
