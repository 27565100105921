import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const RedoIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="328.136 35.514 22 22"
    >
      <g data-name="redo-svgrepo-com">
        <path
          d="M350.273 41.048H334.46c-.255 0-6.325 0-6.325 6.324 0 7.116 5.855 7.116 6.325 7.116h12.65"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#414141"
          fill="transparent"
          data-name="Path 876"
        />
        <path
          d="m344.739 46.582 5.534-5.534-5.534-5.534"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.5"
          stroke="#414141"
          fill="transparent"
          data-name="Path 877"
        />
      </g>
    </svg>
  )
}

export default RedoIcon
