import React from "react"
import { Outlet } from "react-router-dom"
import style from "@/layouts/layouts.module.css"

interface StandardLayoutProps {}

const NoMenuLayout: React.FC<StandardLayoutProps> = () => {
  return (
    <div className={style["standard-layout"]}>
      <Outlet />
    </div>
  )
}

export default NoMenuLayout
