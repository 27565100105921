import { useState } from "react"
import { useTranslation } from "react-i18next"
import AddItemGenericDrawerSelectionRow from "@/components/generic-drawer-selection-row/add-item-generic-drawer-selection-row.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import { type TaskCategory } from "@/features/tasks/api/task-categories-api.ts"
import AddTaskCategoryForm from "@/features/tasks/components/add-task-category-form/add-task-category-form.tsx"
import TaskCategorySelectDrawerContent from "@/features/tasks/components/task-category-select-drawer-content/task-category-select-drawer-content.tsx"
import TaskCategorySelectDrawerTrigger from "@/features/tasks/components/task-category-select-drawer-trigger/task-category-select-drawer-trigger.tsx"

interface TaskCategorySelectDrawerProps {
  taskCategoryId: string | null
  taskCategories: TaskCategory[]
  onTaskCategoryChange: (category: string | null) => void
}

const TaskCategorySelectDrawer: React.FC<TaskCategorySelectDrawerProps> = ({
  taskCategoryId,
  onTaskCategoryChange,
  taskCategories,
}) => {
  const { t } = useTranslation()
  const currentTaskCategory = taskCategories.find(
    (category) => category.id === taskCategoryId,
  )

  const [isAddCategoryViewOpen, setIsAddCategoryViewOpen] = useState<{
    isOpen: boolean
    taskCategoryId: string | null
  }>({
    isOpen: false,
    taskCategoryId: null,
  })

  const handleBackClick = () => {
    setIsAddCategoryViewOpen({
      isOpen: false,
      taskCategoryId: null,
    })
  }

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <TaskCategorySelectDrawerTrigger
          currentTaskCategory={currentTaskCategory}
        />
      </DrawerTrigger>
      <DrawerContent
        style={{
          height: "85%",
          width: "100%",
        }}
      >
        {isAddCategoryViewOpen.isOpen ? (
          <AddTaskCategoryForm
            onBackClick={handleBackClick}
            categoryId={isAddCategoryViewOpen.taskCategoryId}
          />
        ) : (
          <>
            <TaskCategorySelectDrawerContent
              setIsAddCategoryViewOpen={setIsAddCategoryViewOpen}
              taskCategories={taskCategories}
              currentTaskCategory={currentTaskCategory}
              onTaskCategorySelect={onTaskCategoryChange}
            />
            <AddItemGenericDrawerSelectionRow
              text={t("addCategory")}
              onClick={() =>
                setIsAddCategoryViewOpen({ isOpen: true, taskCategoryId: null })
              }
            />
          </>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default TaskCategorySelectDrawer
