import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useGetAllAnimalEventTypesQuery } from "@/features/animal-events/api/event-types-api.ts"
import styles from "@/features/animal-events/components/events-table/events-table.module.css"
import { type GenericAnimalEvent } from "@/features/animal-events/types/animal-events.ts"
import { ANIMAL_EVENT_SCREEN_PATH } from "@/utils/constants/routes.ts"

const EventsTableRow = ({ event }: { event: GenericAnimalEvent }) => {
  const { t } = useTranslation()
  const { data: eventTypes } = useGetAllAnimalEventTypesQuery()
  const eventTypeName =
    eventTypes && event.type ? eventTypes[event.type].name : ""
  const navigate = useNavigate()
  const navigateToEvent = () => {
    navigate(ANIMAL_EVENT_SCREEN_PATH + `/${event.id}`)
  }

  return (
    <tr className={styles.tableRow} onClick={navigateToEvent}>
      <td className={styles.tableCell}>{t(eventTypeName)}</td>
      <td className={styles.dateCell}>{format(event.date, "dd.MM.yyyy")}</td>
    </tr>
  )
}

export default EventsTableRow
