import React from "react"
import { useTranslation } from "react-i18next"
import EmptyContentCreateItemButton from "@/features/home/components/empty-content-create-item-button/empty-content-create-item-button.tsx"
import { taskClassificationCheckNotAllEmpty } from "@/features/tasks"
import TaskStatusGroup from "@/features/tasks/components/task-status-group/task-status-group.tsx"
import { type CurrentTasksListProps } from "@/features/tasks/components/tasks-lists/tasks-list-current.tsx"
import { DONE_GREEN } from "@/utils/constants/colors-constants.ts"
import { NEW_TASK_SCREEN_PATH } from "@/utils/constants/routes.ts"

export const TasksListDone: React.FC<CurrentTasksListProps> = ({
  filteredTasks,
}) => {
  const { t } = useTranslation()
  const hasTasks = taskClassificationCheckNotAllEmpty(filteredTasks)

  return (
    <>
      {hasTasks ? (
        <>
          {filteredTasks.doneTasks.length > 0 && (
            <TaskStatusGroup
              tasks={filteredTasks.doneTasks}
              title={t("DONE")}
              backgroundColor={DONE_GREEN}
            />
          )}
        </>
      ) : (
        <EmptyContentCreateItemButton navigateTo={NEW_TASK_SCREEN_PATH} />
      )}
    </>
  )
}
