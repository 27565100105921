import React from "react"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const CameraIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="36 1118 30 30"
    >
      <g data-name="Group 4375">
        <g data-name="Group 2478">
          <path
            d="M43 1118h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H43a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
            fill="#b7b7b7"
            fillRule="evenodd"
            data-name="box icon #1"
          />
        </g>
        <g data-name="Group 4346">
          <path
            d="M58.858 1138c0 .79-.64 1.43-1.429 1.43H44.571c-.789 0-1.429-.64-1.429-1.43v-7.857c0-.789.64-1.429 1.429-1.429h2.857l1.43-2.143h4.285l1.429 2.143h2.857c.79 0 1.429.64 1.429 1.429v7.858Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.2"
            stroke="#fff"
            fill="transparent"
            data-name="Path 776"
          />
          <path
            d="M53.857 1133.715a2.857 2.857 0 1 1-5.714 0 2.857 2.857 0 0 1 5.714 0Z"
            strokeLinejoin="round"
            strokeLinecap="round"
            strokeWidth="1.2"
            stroke="#fff"
            fill="transparent"
            data-name="Path 777"
          />
        </g>
      </g>
    </svg>
  )
}

export default CameraIcon
