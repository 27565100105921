import React, { type ChangeEvent, useCallback } from "react"
import { useTranslation } from "react-i18next"
import styles from "./animal-editor.module.css"
import AinIcon from "@/assets/icons/farm/ain-icon.tsx"
import CowHeadIcon from "@/assets/icons/farm/cow-head-icon.tsx"
import DetailIcon from "@/assets/icons/farm/details-icon.tsx"
import LocationIcon from "@/assets/icons/farm/location-icon.tsx"
import MaleFemaleIcon from "@/assets/icons/farm/male-female-icon.tsx"
import MeatIcon from "@/assets/icons/farm/meat-icon.tsx"
import MilkIcon from "@/assets/icons/farm/milk-icon.tsx"
import ProductionIcon from "@/assets/icons/farm/production-icon.tsx"
import RegistryIcon from "@/assets/icons/farm/registry-icon.tsx"
import BasketIcon from "@/assets/icons/misc/basket-icon.tsx"
import FemaleIcon from "@/assets/icons/misc/female-icon.tsx"
import IconPregnant from "@/assets/icons/misc/icon-pregnant.tsx"
import MaleIcon from "@/assets/icons/misc/male-icon.tsx"
import TreatmentsCountIcon from "@/assets/icons/ruminant-details/treatments-count-icon.tsx"
import { AttachImageFieldBase64 } from "@/components/attach-image-field/attach-image-field-base-64.tsx"
import BinaryChoiceFieldCard from "@/components/binary-choice-field-card/binary-choice-field-card.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import InputNumberFieldWithIcon from "@/components/text-card-wrapper-with-icon/input-number-field-with-icon.tsx"
import TextAreaFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/text-area-field-wrapper-with-icon.tsx"
import {
  ANIMAL_GENDERS,
  initialRuminantFields,
  isRuminant,
  PRODUCTION_ENUM,
  REGISTRY_ENTRY_ENUM,
} from "@/features/farm"
import AnimalDateSelectGroup from "@/features/farm/components/animal-date-select-group/animal-date-select-group.tsx"
import { RuminantEditor } from "@/features/farm/components/animal-editor/ruminant-editor.tsx"
import AnimalSpeciesSelect from "@/features/farm/components/animal-species-select/animal-species-select.tsx"
import DrawerBreeds from "@/features/farm/components/drawer-breeds/drawer-breeds.tsx"
import DrawerSelectParent from "@/features/farm/components/drawer-select-mother/drawer-select-parent.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"
import { type AnimalDraft, type Species } from "@/features/farm/types/animal.ts"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  setAnimalDraft,
  setAnimalDraftAin,
  setDraftAnimalBirthDate,
  setDraftAnimalBreed,
  setDraftAnimalDescription,
  setDraftAnimalEntryDate,
  setDraftAnimalEntryWay,
  setDraftAnimalGender,
  setDraftAnimalLocation,
  setDraftAnimalMother,
  setDraftAnimalFather,
  setDraftAnimalName,
  setDraftAnimalPicture,
  setDraftAnimalIntendedPurpose,
  setDraftAnimalTreatmentCount,
} from "@/redux/slices/animal-draft-slice.ts"

interface AnimalEditorProps {
  animalId: string
  draftAnimal: AnimalDraft
}

const AnimalEditor: React.FC<AnimalEditorProps> = ({
  animalId,
  draftAnimal,
}) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const isAnimalRuminant = isRuminant(draftAnimal)

  const handleRuminantChange = useCallback(
    (ruminant: AnimalDraft) => {
      const newRuminant = { ...draftAnimal, ...ruminant }
      dispatch(setAnimalDraft({ id: animalId, draft: newRuminant }))
    },
    [animalId, dispatch, draftAnimal],
  )

  /**
   * Set the species and if the species is Cow, Goat or Sheep set the ruminant fields
   * Pork only has Meat as intended purpose for both genders
   */
  const handleSpeciesSelection = useCallback(
    (species: Species) => {
      const updatedAnimal = {
        ...draftAnimal,
        species: species.id,
        breed: "",
        mother: "",
        father: "",
      }

      if (
        species.name === "Cow" ||
        species.name === "Goat" ||
        species.name === "Sheep"
      ) {
        updatedAnimal.ruminant_fields = initialRuminantFields
      } else {
        updatedAnimal.intended_purpose = PRODUCTION_ENUM.MEAT
        delete updatedAnimal.ruminant_fields
      }

      dispatch(setAnimalDraft({ id: animalId, draft: updatedAnimal }))
    },
    [animalId, dispatch, draftAnimal],
  )

  const handleNameChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setDraftAnimalName({ id: animalId, name: event.target.value }))
    },
    [animalId, dispatch],
  )

  const handleAinChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(setAnimalDraftAin({ id: animalId, ain: event.target.value }))
    },
    [animalId, dispatch],
  )

  /**
   * Set the gender and if the gender is Male set the intended purpose to Meat
   * If Female set the intended purpose to default value to clear previous selection
   * To be solved more elegantly in the future
   */
  const handleGenderChange = useCallback(
    (gender: string) => {
      dispatch(setDraftAnimalGender({ id: animalId, gender }))
      if (gender === ANIMAL_GENDERS.MALE.label) {
        dispatch(
          setDraftAnimalIntendedPurpose({
            id: animalId,
            intended_purpose: PRODUCTION_ENUM.MEAT,
          }),
        )
      } else {
        dispatch(
          setDraftAnimalIntendedPurpose({
            id: animalId,
            intended_purpose: "",
          }),
        )
      }
    },
    [animalId, dispatch],
  )

  const handleBirthDateChange = useCallback(
    (birth_date: string) => {
      if (draftAnimal.birth_date === draftAnimal.entry_date) {
        dispatch(
          setDraftAnimalEntryDate({ id: animalId, entry_date: birth_date }),
        )
      }
      dispatch(setDraftAnimalBirthDate({ id: animalId, birth_date }))
    },
    [animalId, dispatch, draftAnimal],
  )

  const handleEntryDateChange = useCallback(
    (entry_date: string) => {
      dispatch(setDraftAnimalEntryDate({ id: animalId, entry_date }))
    },
    [animalId, dispatch],
  )

  const handleEntryWayChange = useCallback(
    (entry_way: string) => {
      dispatch(setDraftAnimalEntryWay({ id: animalId, entry_way }))
    },
    [animalId, dispatch],
  )

  const handleDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      dispatch(
        setDraftAnimalDescription({
          id: animalId,
          description: event.target.value,
        }),
      )
    },
    [animalId, dispatch],
  )

  const handlePictureChange = useCallback(
    (imgFile: string | null) => {
      dispatch(setDraftAnimalPicture({ id: animalId, picture: imgFile }))
    },
    [animalId, dispatch],
  )

  /**
   * Set the breed and reset the mother and father
   */
  const handleBreedChange = useCallback(
    (breed: string) => {
      dispatch(setDraftAnimalBreed({ id: animalId, breed }))
      dispatch(setDraftAnimalMother({ id: animalId, mother: "" }))
      dispatch(setDraftAnimalFather({ id: animalId, father: "" }))
    },
    [animalId, dispatch],
  )

  const handleLocationChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setDraftAnimalLocation({ id: animalId, location: event.target.value }),
      )
    },
    [animalId, dispatch],
  )

  const handleMotherChange = useCallback(
    (mother: string) => {
      dispatch(setDraftAnimalMother({ id: animalId, mother }))
    },
    [animalId, dispatch],
  )

  const handleFatherChange = useCallback(
    (father: string) => {
      dispatch(setDraftAnimalFather({ id: animalId, father }))
    },
    [animalId, dispatch],
  )

  const handleIntendedPurposeChange = useCallback(
    (intended_purpose: string) => {
      dispatch(
        setDraftAnimalIntendedPurpose({ id: animalId, intended_purpose }),
      )
    },
    [animalId, dispatch],
  )

  const handleTreatmentCountChange = useCallback(
    (medical_treatments_count: number) => {
      dispatch(
        setDraftAnimalTreatmentCount({
          id: animalId,
          medical_treatments_count,
        }),
      )
    },
    [animalId, dispatch],
  )

  return (
    <div className={styles.editorView}>
      <AnimalSpeciesSelect
        isRequired={draftAnimal.species === ""}
        speciesId={draftAnimal.species}
        onSpeciesIdChange={handleSpeciesSelection}
      />

      <OverflowMenuItem isDisabled={draftAnimal.species === ""}>
        <DrawerBreeds
          currentValue={draftAnimal}
          onSaveClick={handleBreedChange}
        />
        {draftAnimal.breed === "" && <RequiredIndicator />}
      </OverflowMenuItem>

      <InputFieldWrapperWithIcon
        icon={<CowHeadIcon />}
        align={"row"}
        name={t("name")}
        isDisabled={false}
        value={draftAnimal.name}
        onChange={handleNameChange}
      />

      <InputFieldWrapperWithIcon
        icon={<AinIcon />}
        align={"row"}
        name={t("crotal")}
        maxLength={14}
        inputMode={"numeric"}
        isDisabled={false}
        value={draftAnimal.ain}
        isRequired={draftAnimal.ain === ""}
        onChange={handleAinChange}
      />

      <OverflowMenuItem
        isDisabled={draftAnimal.breed === "" && draftAnimal.ain === ""}
      >
        <DrawerSelectParent
          offspring={draftAnimal}
          onSaveClick={handleMotherChange}
          isMother={true}
        />
      </OverflowMenuItem>

      <OverflowMenuItem
        isDisabled={draftAnimal.breed === "" && draftAnimal.ain === ""}
      >
        <DrawerSelectParent
          offspring={draftAnimal}
          onSaveClick={handleFatherChange}
          isMother={false}
        />
      </OverflowMenuItem>

      <InputFieldWrapperWithIcon
        icon={<LocationIcon />}
        align={"row"}
        name={t("selectLocation")}
        isDisabled={false}
        value={draftAnimal.location}
        onChange={handleLocationChange}
      />

      <BinaryChoiceFieldCard
        isRequired={draftAnimal.gender === ""}
        titleIcon={<MaleFemaleIcon />}
        titleText={t("sex")}
        firstChoiceIcon={<FemaleIcon />}
        secondChoiceIcon={<MaleIcon />}
        isReadOnly={false}
        onClickFirstChoice={() =>
          handleGenderChange(ANIMAL_GENDERS.FEMALE.label)
        }
        onClickSecondChoice={() =>
          handleGenderChange(ANIMAL_GENDERS.MALE.label)
        }
        firstCondition={draftAnimal.gender === ANIMAL_GENDERS.FEMALE.label}
        secondCondition={draftAnimal.gender === ANIMAL_GENDERS.MALE.label}
        firstChoiceName={t("Female")}
        secondChoiceName={t("Male")}
      />

      <AnimalDateSelectGroup
        handleBirthDateChange={handleBirthDateChange}
        handleEntryDateChange={handleEntryDateChange}
        receivedAnimal={draftAnimal}
      />

      <BinaryChoiceFieldCard
        isRequired={draftAnimal.entry_way === ""}
        titleIcon={<RegistryIcon />}
        firstChoiceIcon={
          <IconPregnant
            is_active={draftAnimal.entry_way === REGISTRY_ENTRY_ENUM.BIRTH}
          />
        }
        secondChoiceIcon={
          <BasketIcon
            is_active={draftAnimal.entry_way === REGISTRY_ENTRY_ENUM.PURCHASE}
          />
        }
        titleText={t("registryEntryWay")}
        isReadOnly={false}
        onClickFirstChoice={() =>
          handleEntryWayChange(REGISTRY_ENTRY_ENUM.BIRTH)
        }
        onClickSecondChoice={() =>
          handleEntryWayChange(REGISTRY_ENTRY_ENUM.PURCHASE)
        }
        firstCondition={draftAnimal.entry_way === REGISTRY_ENTRY_ENUM.BIRTH}
        secondCondition={draftAnimal.entry_way === REGISTRY_ENTRY_ENUM.PURCHASE}
        firstChoiceName={t("Birth")}
        secondChoiceName={t("Purchase")}
      />

      {isAnimalRuminant &&
        draftAnimal.gender === ANIMAL_GENDERS.FEMALE.label && (
          <BinaryChoiceFieldCard
            isRequired={draftAnimal.intended_purpose === ""}
            titleIcon={<ProductionIcon />}
            firstChoiceIcon={<MilkIcon />}
            secondChoiceIcon={<MeatIcon />}
            titleText={t("Production")}
            isReadOnly={false}
            onClickFirstChoice={() =>
              handleIntendedPurposeChange(PRODUCTION_ENUM.DIARY)
            }
            onClickSecondChoice={() =>
              handleIntendedPurposeChange(PRODUCTION_ENUM.MEAT)
            }
            firstCondition={
              draftAnimal.intended_purpose === PRODUCTION_ENUM.DIARY
            }
            secondCondition={
              draftAnimal.intended_purpose === PRODUCTION_ENUM.MEAT
            }
            firstChoiceName={t("Dairy")}
            secondChoiceName={t("Beef")}
          />
        )}

      <InputNumberFieldWithIcon
        icon={<TreatmentsCountIcon />}
        name={t("medical_treatments_count")}
        isReadOnly={false}
        value={draftAnimal.medical_treatments_count}
        onChange={handleTreatmentCountChange}
      />

      {isAnimalRuminant && (
        <RuminantEditor
          animal={draftAnimal}
          isReadOnly={false}
          onChangeRuminant={handleRuminantChange}
        />
      )}

      <TextAreaFieldWrapperWithIcon
        icon={<DetailIcon />}
        name={t("details")}
        isReadOnly={false}
        value={draftAnimal.description}
        onChange={handleDescriptionChange}
      />
      <AttachImageFieldBase64
        picture={draftAnimal.picture}
        onChoosePicture={handlePictureChange}
      />
    </div>
  )
}

export default React.memo(AnimalEditor)
