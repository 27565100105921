import { BROWN, DARK_GREY } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuTasksIcon: React.FC<GeneralSvgProps> = (props) => {
  const computedFill = props.stroke
    ? props.stroke
    : props.is_active
      ? BROWN
      : DARK_GREY

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size || "30"}
      height={props.size || "30"}
      viewBox="356.999 60.366 38.09 41.552"
    >
      <g data-name="Group 2769">
        <path
          d="M356.999 65.993a5.627 5.627 0 0 1 5.627-5.627h23.373a5.627 5.627 0 0 1 5.627 5.627v20.999c-.209.14-.407.301-.59.485l-2.007 2.007V65.993a3.03 3.03 0 0 0-3.03-3.03h-23.373a3.03 3.03 0 0 0-3.03 3.03v30.298a3.03 3.03 0 0 0 3.03 3.03h13.968l2.597 2.597h-16.565a5.627 5.627 0 0 1-5.627-5.627V65.993Z"
          fill={computedFill}
          fillRule="evenodd"
          data-name="Path 803"
        />
        <path
          d="M368.253 72.053a2.164 2.164 0 1 1-4.328 0 2.164 2.164 0 0 1 4.328 0Z"
          fill={computedFill}
          fillRule="evenodd"
          data-name="Path 804"
        />
        <path
          d="M366.089 83.306a2.164 2.164 0 1 0 0-4.329 2.164 2.164 0 0 0 0 4.329Z"
          fill={computedFill}
          fillRule="evenodd"
          data-name="Path 805"
        />
        <path
          d="M366.089 92.396a2.164 2.164 0 1 0 0-4.329 2.164 2.164 0 0 0 0 4.329Z"
          fill={computedFill}
          fillRule="evenodd"
          data-name="Path 806"
        />
        <path
          d="M372.148 70.754a1.299 1.299 0 0 0 0 2.597h11.254a1.299 1.299 0 0 0 0-2.597h-11.254Z"
          fill={computedFill}
          fillRule="evenodd"
          data-name="Path 807"
        />
        <path
          d="M370.85 81.142c0-.717.581-1.298 1.298-1.298h11.254a1.299 1.299 0 0 1 0 2.597h-11.254a1.299 1.299 0 0 1-1.298-1.299Z"
          fill={computedFill}
          fillRule="evenodd"
          data-name="Path 808"
        />
        <path
          d="M372.148 88.934a1.299 1.299 0 0 0 0 2.597h11.254a1.299 1.299 0 0 0 0-2.597h-11.254Z"
          fill={computedFill}
          fillRule="evenodd"
          data-name="Path 809"
        />
        <path
          d="m394.708 91.15-10.388 10.388a1.298 1.298 0 0 1-1.836 0l-5.19-5.19a1.299 1.299 0 0 1 1.836-1.837l4.272 4.273 9.47-9.47a1.298 1.298 0 1 1 1.836 1.836Z"
          fill={computedFill}
          fillRule="evenodd"
          data-name="Path 810"
        />
      </g>
    </svg>
  )
}

export default MenuTasksIcon
