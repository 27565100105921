import React, { type ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { TaskFilterTab } from "@/components/filter-tab/task-filter-tab/task-filter-tab.tsx"
import PageContentWrapperSmall from "@/components/page-content-wrappers/page-content-wrapper-small.tsx"
import SearchBar from "@/components/search-bar/search-bar"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { TasksListCurrent } from "@/features/tasks/components/tasks-lists/tasks-list-current.tsx"
import { TasksListDone } from "@/features/tasks/components/tasks-lists/tasks-list-done.tsx"
import useFilteredTasks from "@/features/tasks/hooks/use-filter-tasks.ts"
import { NEW_TASK_SCREEN_PATH } from "@/utils/constants/routes"

export const TasksTab: React.FC = () => {
  const { t } = useTranslation()
  const {
    searchQuery,
    setSearchQuery,
    filteredTasks,
    selections,
    setSelections,
  } = useFilteredTasks()

  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }

  return (
    <>
      <MobileTopBar navigateTo={NEW_TASK_SCREEN_PATH} title={t("tasks")} />
      <PageContentWrapperSmall>
        <SearchBar
          value={searchQuery}
          onChange={handleSearchInputChange}
          placeholder={t("searchTask")}
        />

        <TaskFilterTab onFilterChange={setSelections} />

        {selections.is_done ? (
          <TasksListDone filteredTasks={filteredTasks} />
        ) : (
          <TasksListCurrent filteredTasks={filteredTasks} />
        )}
      </PageContentWrapperSmall>
    </>
  )
}
