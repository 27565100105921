import * as Sentry from "@sentry/react"
import { useEffect } from "react"
import {
  Route,
  Routes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom"
import { sentryConfig } from "@/config/sentry/sentry-config.ts"
import { EventsRoutes } from "@/features/animal-events/routes/events-routes.tsx"
import AssistantRoutes from "@/features/assistant/routes/assistant-routes.tsx"
import { FarmRoutes } from "@/features/farm/routes/farm-routes.tsx"
import { FinancialRoutes } from "@/features/finance/routes/financial-routes.tsx"
import { GestationalCalculatorRoutes } from "@/features/gestational-calendar/routes/gestational-calendar-routes.tsx"
import { HomeRoutes } from "@/features/home/routes/home-routes.tsx"
import NotFoundPage from "@/features/home/routes/not-found-page/not-found-page.tsx"
import { MenuRoutes } from "@/features/menu/routes/menu-routes.tsx"
import { NotesRoutes } from "@/features/notes/routes/notes-routes.tsx"
import { NotificationsRoutes } from "@/features/notifications/routes/notifications-routes.tsx"
import useFarmPermissions from "@/features/settings-account/hooks/use-farm-permissions.tsx"
import LoginView from "@/features/settings-account/routes/login-view/login-view.tsx"
import LostPassword from "@/features/settings-account/routes/login-view/lost-password.tsx"
import { SettingsRoutes } from "@/features/settings-account/routes/settings-routes.tsx"
import { TasksRoutes } from "@/features/tasks/routes/tasks-routes.tsx"
import { useAuth } from "@/hooks/use-auth.ts"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import FarmPermissions from "@/routing/farm-permissions.tsx"
import ProtectedRoute from "@/routing/protected-route.tsx"
import {
  LOGIN_SCREEN_PATH,
  LOST_PASSWORD_PATH,
  NOT_ENABLED,
} from "@/utils/constants/routes.ts"

Sentry.init({
  dsn: sentryConfig.dsn,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: sentryConfig.tracesSampleRate,
  tracePropagationTargets: [import.meta.env.VITE_API_URL],
  // Session Replay
  replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
  replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
  enabled: import.meta.env.PROD,
  environment: import.meta.env.MODE,
})

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

export const GeneralRoutes = () => {
  const auth = useAuth()
  const { permissions } = useFarmPermissions()

  return (
    <SentryRoutes>
      <Route
        element={
          <ProtectedRoute
            redirectRoute={LOGIN_SCREEN_PATH}
            condition={Boolean(auth.token)}
          />
        }
      >
        <Route element={<FarmPermissions />}>
          {HomeRoutes}
          {MenuRoutes}
          {SettingsRoutes}
          {NotesRoutes}
          {TasksRoutes}
          {NotificationsRoutes}
          {FarmRoutes}
          {EventsRoutes}
          <Route
            element={
              <ProtectedRoute
                redirectRoute={NOT_ENABLED}
                condition={permissions.is_finance_journal_enabled}
              />
            }
          >
            {FinancialRoutes}
          </Route>
          <Route
            element={
              <ProtectedRoute
                redirectRoute={NOT_ENABLED}
                condition={permissions.is_assistant_enabled}
              />
            }
          >
            {AssistantRoutes}
          </Route>
          <Route
            element={
              <ProtectedRoute
                redirectRoute={NOT_ENABLED}
                condition={permissions.is_gestational_calendar_enabled}
              />
            }
          >
            {GestationalCalculatorRoutes}
          </Route>
        </Route>
      </Route>

      <Route element={<NoMenuLayout />}>
        <Route path={LOGIN_SCREEN_PATH} element={<LoginView />} />
        <Route path={LOST_PASSWORD_PATH} element={<LostPassword />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </SentryRoutes>
  )
}
