import { Route } from "react-router-dom"
import NotificationView from "@/features/notifications/routes/notification-view/notification-view.tsx"
import { NotificationsPage } from "@/features/notifications/routes/notifications-page/notifications-page.tsx"
import { StandardLayout } from "@/layouts/standard-layout/standard-layout.tsx"
import { NOTIFICATIONS_PATH } from "@/utils/constants/routes.ts"

export const NotificationsRoutes = (
  <Route element={<StandardLayout />}>
    <Route
      key={NOTIFICATIONS_PATH}
      path={NOTIFICATIONS_PATH}
      element={<NotificationsPage />}
    >
      <Route
        key={NOTIFICATIONS_PATH + "1"}
        path={":notificationId"}
        element={<NotificationView />}
      />
    </Route>
  </Route>
)
