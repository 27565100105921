import React, { type ChangeEvent } from "react"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import SearchBarGeneral from "@/components/search-bar/search-bar-general.tsx"
import styles from "@/components/shadcn/drawer/custom-drawer.module.css"
import { DrawerClose } from "@/components/shadcn/drawer/drawer.tsx"

interface DrawerTopBarProps {
  searchQuery: string
  setSearchQuery: (searchQuery: string) => void
  placeholder: string
  onSaveClick?: () => void
}

const DrawerTopBar: React.FC<DrawerTopBarProps> = ({
  searchQuery,
  setSearchQuery,
  placeholder,
  onSaveClick,
}) => {
  const handleSearchInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value)
  }
  return (
    <div className={styles["slide-up-top-bar"]}>
      <DrawerClose asChild>
        <BackIcon />
      </DrawerClose>
      <SearchBarGeneral
        value={searchQuery}
        onChange={handleSearchInputChange}
        placeholder={placeholder}
      />
      <DrawerClose asChild onClick={onSaveClick}>
        <CircleTickIcon data-testid={"drawer-confirm-selection"} size={30} />
      </DrawerClose>
    </div>
  )
}

export default DrawerTopBar
