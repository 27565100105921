import { forwardRef } from "react"
import { useTranslation } from "react-i18next"
import ChooseSomethingIcon from "@/assets/icons/finance/choose-something-icon.tsx"
import TaskCategoryIcon from "@/assets/icons/task/task-category-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import { type TaskCategory } from "@/features/tasks/api/task-categories-api.ts"
import styles from "@/features/tasks/components/task-category-select-drawer-trigger/task-category-select-drawer-trigger.module.css"

interface TaskCategorySelectDrawerTriggerProps {
  onClick?: () => void
  currentTaskCategory: TaskCategory | undefined
}

const TaskCategorySelectDrawerTrigger = forwardRef<
  HTMLDivElement,
  TaskCategorySelectDrawerTriggerProps
>(({ onClick, currentTaskCategory }, ref) => {
  const { t } = useTranslation()

  return (
    <div className={styles.cardFlex} onClick={onClick} ref={ref}>
      <div className={styles.titleFlex}>
        <TaskCategoryIcon />
        <div className={styles.title}>{t("category")}</div>
      </div>
      {currentTaskCategory && (
        <div className={styles.selectedCategory}>
          {currentTaskCategory.picture ? (
            <GenericPicture
              src={currentTaskCategory.picture}
              alt=""
              size={30}
              borderRadius={50}
              shadow={true}
            />
          ) : (
            <ChooseSomethingIcon size={30} />
          )}
          <div className={styles.title}>{currentTaskCategory?.name}</div>
        </div>
      )}
    </div>
  )
})

TaskCategorySelectDrawerTrigger.displayName = "TaskCategorySelectDrawerTrigger"

export default TaskCategorySelectDrawerTrigger
