import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const BreedIcon: React.FC<GeneralSvgProps> = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="36 616 30 30"
    >
      <g data-name="Group 2466">
        <path
          d="M43 616h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H43a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="var(--pastel-brown)"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <g data-name="dna-chromosome-svgrepo-com">
          <g data-name="Group 2167">
            <path
              d="M60.007 626.38c-.332-.074-.686.153-.755.463-.02.073-.09.313-.256.635l-4.476-4.475c.368-.188.625-.247.631-.25.818-.19.526-1.426-.292-1.232-.05.012-5.014 1.308-4.199 8.392-.325-.021-.65-.031-.969-.031-5.786 0-8 3.636-8.092 3.79-.433.73.664 1.343 1.088.65.033-.055.207-.328.556-.697l5.135 5.136c-.366.346-.64.521-.692.553-.72.429-.077 1.51.645 1.092.172-.104 4.197-2.574 3.758-9.065 5.19.603 7.87-2.033 8.395-4.203a.631.631 0 0 0-.477-.758Zm-9.277 7.617-2.724-2.724c.87-.132 1.837-.158 2.802-.078.081.98.055 1.92-.078 2.802Zm-4.237-2.388 3.883 3.905c-.389 1.151-.86 1.924-1.193 2.312l-5-5.019c.864-.701 1.984-1.052 2.31-1.198Zm5.38-4.15 2.658 2.668c-.783.074-1.66.048-2.578-.075a11.494 11.494 0 0 1-.08-2.593Zm4.188 2.363-3.9-3.868c.284-.918.692-1.556 1.236-2.13l4.8 4.73-.052.063c-.763.74-1.758 1.15-2.084 1.205Z"
              fill="#fff"
              fillRule="evenodd"
              data-name="Path 1292"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default BreedIcon
