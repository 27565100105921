import { BROWN, DARK_GREY } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MenuMedKitIcon: React.FC<GeneralSvgProps> = ({ is_active }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="150.101 702.809 38.592 36.754"
    >
      <g data-name="Group 2768">
        <g data-name="icons">
          <path
            d="M184.833 734.05v-18.377c0-1.015-.863-1.838-1.93-1.838H155.89c-1.066 0-1.93.823-1.93 1.838v18.377c0 1.014.864 1.837 1.93 1.837h27.014c1.066 0 1.93-.823 1.93-1.837Zm-19.295-25.728v1.838h7.718v-1.838c0-1.015-.864-1.838-1.93-1.838h-3.859c-1.066 0-1.93.823-1.93 1.838Zm19.295 1.838c2.132 0 3.86 1.644 3.86 3.675v22.052c0 2.03-1.728 3.676-3.86 3.676H153.96c-2.131 0-3.859-1.645-3.859-3.676v-22.052c0-2.03 1.728-3.675 3.86-3.675h7.718v-3.676c0-2.03 1.727-3.675 3.859-3.675h7.718c2.132 0 3.86 1.644 3.86 3.675v3.676h7.717Zm-17.366 20.214V726.7h-3.859c-1.066 0-1.93-.824-1.93-1.838 0-1.014.864-1.838 1.93-1.838h3.86v-3.675c0-1.014.863-1.838 1.929-1.838s1.93.824 1.93 1.838v3.675h3.859c1.065 0 1.93.824 1.93 1.838 0 1.014-.865 1.838-1.93 1.838h-3.86v3.675c0 1.015-.863 1.838-1.93 1.838-1.065 0-1.929-.823-1.929-1.838Z"
            fill={is_active ? BROWN : DARK_GREY}
            fillRule="evenodd"
            data-name="help-[#1436]"
          />
        </g>
      </g>
    </svg>
  )
}

export default MenuMedKitIcon
