import { format } from "date-fns"
import React, { useEffect, useState } from "react"
import { type DateRange } from "react-day-picker"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import HistoryIcon from "@/assets/icons/finance/history-icon.tsx"
import CustomSelectPill from "@/components/custom-select/custom-select-pill.tsx"
import GenericCalendarRange from "@/components/generic-date-picker/generic-calendar-range.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import { useLazyGetTransactionsSummaryByTimePeriodQuery } from "@/features/finance/api/finance-summary-api.ts"
import FinancialTabs from "@/features/finance/components/financial-tabs/financial-tabs.tsx"
import styles from "@/features/finance/routes/finance-page/finance-page.module.css"
import {
  TIME_PERIOD,
  timePeriodOptions,
} from "@/features/finance/types/finance-constants.ts"
import {
  FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH,
  FINANCE_TRANSACTIONS_SCREEN_PATH,
} from "@/utils/constants/routes.ts"
import {
  stringifyCustomTimePeriod,
  stringifyTimePeriod,
} from "@/utils/helpers/time-utils.ts"

const FinancePage: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [timePeriod, setTimePeriod] = useState(TIME_PERIOD.PERIOD_ALL_TIME)
  const [isOpened, setIsOpened] = useState(true)
  const [selectedDates, setSelectedDates] = useState<DateRange>()

  const [triggerPeriod, { data: periodSummary, isLoading, isSuccess }] =
    useLazyGetTransactionsSummaryByTimePeriodQuery()

  useEffect(() => {
    if (timePeriod) {
      if (timePeriod !== TIME_PERIOD.PERIOD_CUSTOM) {
        triggerPeriod({ period: timePeriod })
      }
    }
  }, [timePeriod, triggerPeriod])

  const handleTimePeriodChange = (timePeriod: string) => {
    setTimePeriod(timePeriod)
  }

  const handleStatsViewClick = () => {
    if (selectedDates?.from && selectedDates.to) {
      triggerPeriod({
        period: timePeriod,
        end_date: format(selectedDates.from, "yyyy-MM-dd"),
        start_date: format(selectedDates.to, "yyyy-MM-dd"),
      })
      setIsOpened(false)
    }
  }

  const selectDate = (date: DateRange | undefined) => {
    setSelectedDates(date)
  }

  return (
    <>
      <MobileTopBar
        title={t("financialJournal")}
        navigateTo={FINANCE_TRANSACTIONS_SCREEN_PATH + "/new"}
      />
      <PageContentWrapperLarge>
        <div className={styles.timePeriod}>
          <CustomSelectPill
            currentSelection={timePeriod}
            options={timePeriodOptions}
            onSelectionChange={handleTimePeriodChange}
          />
        </div>
        {!isLoading && isSuccess ? (
          <>
            {timePeriod === TIME_PERIOD.PERIOD_CUSTOM && (
              <div className={styles.customTimeSelectWrapper}>
                {isOpened && (
                  <GenericCalendarRange
                    receivedDate={selectedDates}
                    onChange={selectDate}
                    setIsOpened={setIsOpened}
                    withFooter={false}
                  />
                )}
                <div
                  className={styles.pills}
                  onClick={() => setIsOpened((prevState) => !prevState)}
                >
                  <div className={styles.timeButton}>
                    {!selectedDates?.from
                      ? t("startDate")
                      : format(selectedDates.from, "dd-MM-yyyy")}
                  </div>
                  <div> - </div>
                  <div className={styles.timeButton}>
                    {!selectedDates?.to
                      ? t("endDate")
                      : format(selectedDates.to, "dd-MM-yyyy")}
                  </div>
                </div>
                {selectedDates?.from && selectedDates.to && (
                  <div className={styles.confirmationButtonWrapper}>
                    <button
                      className={styles.actionButton}
                      onClick={handleStatsViewClick}
                    >
                      {t("viewStats")}
                    </button>
                  </div>
                )}
              </div>
            )}
            <div className={styles.info}>
              <p className={styles.text}>{t("balance")}</p>
              {timePeriod !== TIME_PERIOD.PERIOD_ALL_TIME && (
                <p className={styles.text}>
                  {" "}
                  {timePeriod !== TIME_PERIOD.PERIOD_CUSTOM && periodSummary
                    ? stringifyTimePeriod(timePeriod)
                    : stringifyCustomTimePeriod(
                        selectedDates?.from,
                        selectedDates?.to,
                      )}
                </p>
              )}
            </div>
            <div className={styles.balanceWidget}>
              <div className={styles.balanceCount}>
                {periodSummary?.current_balance}{" "}
                <p className={styles.currency}>lei</p>
              </div>
            </div>
            {!isLoading && periodSummary ? (
              <FinancialTabs data={periodSummary} />
            ) : (
              <LoadingAnimation />
            )}

            <button
              className={styles.transactionHistoryButton}
              onClick={() => navigate(FINANCE_TRANSACTIONS_HISTORY_SCREEN_PATH)}
            >
              <HistoryIcon />
              {t("transactionHistory")}
            </button>
          </>
        ) : (
          <LoadingAnimation />
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default FinancePage
