import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const AssocNoteIcon2: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="26 603 30 30"
    >
      <g data-name="Group 2149">
        <path
          d="M33 603h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H33a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="var(--notification-yellow)"
          fillRule="evenodd"
          data-name="box icon #1"
        />
        <path
          d="M37.422 618.447h7.156"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.4"
          stroke="#fff"
          fill="transparent"
          data-name="Path 1260"
        />
        <path
          d="M37.422 613.975h7.156"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.4"
          stroke="#fff"
          fill="transparent"
          data-name="Path 1261"
        />
        <path
          d="M37.422 622.92h7.156"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.4"
          stroke="#fff"
          fill="transparent"
          data-name="Path 1262"
        />
        <path
          d="M39.21 609.502h-3.577a1.79 1.79 0 0 0-1.79 1.789v13.418c0 .988.802 1.789 1.79 1.789h10.734c.988 0 1.79-.8 1.79-1.789v-13.418c0-.988-.802-1.789-1.79-1.789h-3.13m-4.026 0h4.81m-4.81 0"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth="1.3"
          stroke="#fff"
          fill="transparent"
          data-name="Path 1263"
        />
      </g>
    </svg>
  )
}

export default AssocNoteIcon2
