import isEqual from "lodash.isequal"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import DeleteButton from "@/components/delete-button/delete-button.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import {
  useDeleteAnimalEventMutation,
  useUpdateAnimalEventMutation,
} from "@/features/animal-events/api/animal-events-api.ts"
import AnimalEventEditor from "@/features/animal-events/components/animal-event-editor/animal-event-editor.tsx"
import useGetDraftAnimalEvent from "@/features/animal-events/hooks/use-get-draft-animal-event.ts"
import { useGetEventDataTypeObject } from "@/features/animal-events/hooks/use-get-event-data-type-object.ts"
import { validateAnimalEvent } from "@/features/animal-events/types/animal-events-schema.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"

const AnimalEventView = () => {
  const { animalEventId } = useParams<{ animalEventId: string }>()
  const { openModal } = useModal()
  const { handleBackNavigation } = useNavigationFallback()
  const { data, isLoading, draftEvent } = useGetDraftAnimalEvent(animalEventId)
  const { t } = useTranslation()
  const eventDataTypeObject = useGetEventDataTypeObject(draftEvent.type)
  const [updateAnimalEventMutation] = useUpdateAnimalEventMutation()
  const [deleteAnimalEventMutation] = useDeleteAnimalEventMutation()

  const hasChanges = !!data && !isEqual(draftEvent, data)

  const deleteAnimalEvent = () => {
    if (animalEventId) {
      toast
        .promise(deleteAnimalEventMutation(draftEvent).unwrap(), {
          pending: t("deletingAnimalEvent"),
          success: t("animalEventDeleted"),
          error: t("animalEventDeleteError"),
        })
        .then(() => handleBackNavigation())
    }
  }

  const handleUpdate = async () => {
    if (
      (await validateAnimalEvent(draftEvent, eventDataTypeObject)) &&
      animalEventId
    ) {
      toast
        .promise(
          updateAnimalEventMutation({
            id: animalEventId,
            ...draftEvent,
          }).unwrap(),
          {
            pending: t("updatingAnimalEvent"),
            success: t("animalEventUpdated"),
            error: t("animalEventUpdateError"),
          },
        )
        .then(() => handleBackNavigation())
    }
  }

  const handleDelete = () => {
    openModal(MODALS.BASE_MODAL, {
      title: t("deleteAnimalEventConfirmation"),
      content: t("eventWillBeDeleted"),
      onActionClick: deleteAnimalEvent,
    })
  }

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => handleBackNavigation(),
      })
    } else {
      handleBackNavigation()
    }
  }

  return (
    <>
      <EditorTopBar
        text={"animalEvent"}
        onSave={handleUpdate}
        isModified={hasChanges}
        onBackClick={handleBackClick}
      />
      <PageContentWrapperLarge hasPadding={false}>
        {isLoading || !draftEvent ? (
          <LoadingAnimation />
        ) : (
          <>
            <AnimalEventEditor eventDraft={draftEvent} />
            <div
              style={{
                marginBlock: "50px",
              }}
            >
              <DeleteButton
                text={t("deleteAnimalEvent")}
                onClick={handleDelete}
              />
            </div>
          </>
        )}
      </PageContentWrapperLarge>
    </>
  )
}

export default AnimalEventView
