import { createSlice } from "@reduxjs/toolkit"
import { format } from "date-fns"
import { type GestationCalendar } from "@/features/gestational-calendar/types/calendar-types.ts"

export const initialGestationalCalendarState = {
  insemination_date: format(new Date(), "yyyy-MM-dd"),
  animal: "",
  id: "",
  calving_date: "",
  entries: [],
  created_at: "",
  updated_at: "",
}

type GestationalCalendarDraftSlice = {
  draft: GestationCalendar
}

const initialGestationalCalendarDraftState: GestationalCalendarDraftSlice = {
  draft: initialGestationalCalendarState,
}

const gestationalCalendarsDraftSlice = createSlice({
  name: "gestationalCalendarsDraft",
  initialState: initialGestationalCalendarDraftState,
  reducers: {
    setGestationalCalendarDraft: (state, action) => {
      state.draft = action.payload
    },
    setGestationalCalendarDraftInseminationDate: (state, action) => {
      state.draft.insemination_date = action.payload
    },
    setGestationalCalendarDraftAnimal: (state, action) => {
      state.draft.animal = action.payload
    },
    resetGestationalCalendarDraft: (state, action) => {
      state.draft = { ...initialGestationalCalendarState, ...action.payload }
    },
  },
})

export const {
  setGestationalCalendarDraft,
  setGestationalCalendarDraftInseminationDate,
  setGestationalCalendarDraftAnimal,
  resetGestationalCalendarDraft,
} = gestationalCalendarsDraftSlice.actions

export default gestationalCalendarsDraftSlice.reducer
