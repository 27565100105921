import isEqual from "lodash.isequal"
import { useDispatch } from "react-redux"
import { useAppSelector } from "@/redux/hooks.ts"
import {
  initialAnimalState,
  resetAnimalDraft,
} from "@/redux/slices/animal-draft-slice.ts"

/**
 *  useInitializeAnimalDraft
 *  Hook to initialize the animal draft
 *  If the animalDraft is not present, it will reset the animalDraft with default values
 *  If the animalDraft is present, it will return the animalDraft
 */
const useInitializeAnimalDraft = () => {
  const dispatch = useDispatch()
  const animalDraft = useAppSelector(
    (state) => state.animalDraftSlice.drafts["new"],
  )
  const hasChanges = animalDraft && !isEqual(initialAnimalState, animalDraft)

  if (!animalDraft) {
    dispatch(resetAnimalDraft({ id: "new" }))
  }

  return { animalDraft, hasChanges }
}

export default useInitializeAnimalDraft
