import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "./chat-bottom-input.module.css"
import PaperPlaneIcon from "@/assets/icons/settings/paper-plane-icon.tsx"
import { WHITE } from "@/utils/constants/colors-constants.ts"
import ShortBottomActionWrapper from "@/components/short-bottom-drawer/short-bottom-action-wrapper.tsx"

interface ChatBottomInputProps {
  onSendMessage: (message: string) => void
}

const ChatBottomInput: React.FC<ChatBottomInputProps> = ({ onSendMessage }) => {
  const { t } = useTranslation()

  const [message, setMessage] = useState("")

  const handleSend = () => {
    if (message.trim()) {
      onSendMessage(message)
      setMessage("")
    }
  }

  return (
    <ShortBottomActionWrapper>
      <div className={styles.chatBottomInputWrapper}>
        <input
          type="text"
          onChange={(e) => setMessage(e.target.value)}
          value={message}
          className={styles.inputText}
          placeholder={t("yourMessage")}
        />
        <div className={styles.sendButton} onClick={handleSend}>
          <PaperPlaneIcon color={WHITE} size={28} />
        </div>
      </div>
    </ShortBottomActionWrapper>
  )
}

export default ChatBottomInput
