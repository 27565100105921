import { useLayoutEffect } from "react"
import { useSearchParams } from "react-router-dom"
import { type GenericAnimalEvent } from "@/features/animal-events/types/animal-events.ts"
import { useAppDispatch, useAppSelector } from "@/redux/hooks.ts"
import { resetEventDraft } from "@/redux/slices/events-draft-slice.ts"

const useInitializeEventDraft = () => {
  const dispatch = useAppDispatch()

  const [searchParams] = useSearchParams()
  const eventTypeId = searchParams.get("eventTypeId")
  const animalId = searchParams.get("animalId")

  const eventDraft = useAppSelector(
    (state) => state.animalEventsDraftSlice.draft,
  )

  useLayoutEffect(() => {
    const initialData: Partial<GenericAnimalEvent> = {}

    if (animalId) {
      initialData.animal = animalId
    }

    if (eventTypeId) {
      initialData.type = eventTypeId
    }

    if (Object.keys(initialData).length > 0) {
      dispatch(resetEventDraft(initialData))
    } else {
      dispatch(resetEventDraft({}))
    }
  }, [animalId, dispatch, eventTypeId])

  return { eventDraft }
}

export default useInitializeEventDraft
