import { useCallback } from "react"
import { useNavigate } from "react-router-dom"
import { HOME_SCREEN_PATH } from "@/utils/constants/routes.ts"

const useNavigationFallback = () => {
  const navigate = useNavigate()

  const handleBackNavigation = useCallback(() => {
    if (window.history.length > 1) {
      navigate(-1)
    } else {
      navigate(HOME_SCREEN_PATH)
    }
  }, [navigate])

  return { handleBackNavigation }
}

export default useNavigationFallback
