import { format } from "date-fns"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./gestation-calendar-accordion.module.css"
import AddEventIcon from "@/assets/icons/misc/add-event-icon.tsx"
import ArrowDown from "@/assets/icons/misc/arrow-down.tsx"
import EmptyCalendarIcon from "@/assets/icons/misc/empty-calendar-icon.tsx"
import GestationCalendarActionButton from "@/features/gestational-calendar/components/gestation-calendar-action-button/gestation-calendar-action-button.tsx"
import {
  type CalendarActivity,
  CalendarActivityState,
  type GestationCalendar,
} from "@/features/gestational-calendar/types/calendar-types.ts"
import {
  checkIfTodayOrIfInInterval,
  formatGestationalCalendarInterval,
} from "@/features/gestational-calendar/utils/gestational-calendar-utils.ts"
import {
  ANIMAL_EVENT_SCREEN_PATH,
  NEW_ANIMAL_EVENT_SCREEN_PATH,
} from "@/utils/constants/routes.ts"
import { FP_QUERY_PARAMS } from "@/features/gestational-calendar/utils/gestation-calendar-consts.ts"

interface AccordionProps {
  gestationCalendar: GestationCalendar
  activityState: CalendarActivityState
  calendarActivity: CalendarActivity
  isCalvingDone?: boolean
  isAfterCalving?: boolean
  animalId: string
  isCalving?: boolean
  isOpen?: boolean
}

const GestationCalendarAccordion: React.FC<AccordionProps> = ({
  gestationCalendar,
  activityState,
  calendarActivity,
  animalId,
  isAfterCalving,
  isCalvingDone = false,
  isCalving,
  isOpen = false,
}) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpen)
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleAccordionOpen = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    setIsAccordionOpen((prevState) => !prevState)
  }

  const handleAddEvent = () => {
    const searchParams = new URLSearchParams()
    searchParams.append(FP_QUERY_PARAMS.ANIMAL_ID, animalId)
    searchParams.append(
      FP_QUERY_PARAMS.GESTATION_CALENDAR_ENTRY_ID,
      calendarActivity.id,
    )
    searchParams.append(
      FP_QUERY_PARAMS.GESTATION_CALENDAR_ID,
      gestationCalendar.id,
    )
    if (calendarActivity.event.animal_event_type) {
      searchParams.append(
        FP_QUERY_PARAMS.EVENT_TYPE_ID,
        calendarActivity.event.animal_event_type,
      )
    }

    navigate(`${NEW_ANIMAL_EVENT_SCREEN_PATH}?${searchParams.toString()}`)
  }

  const handleViewEvent = (eventId: string) => {
    navigate(`${ANIMAL_EVENT_SCREEN_PATH}/${eventId}`)
  }

  const dateToShow = useMemo(
    () => formatGestationalCalendarInterval(calendarActivity),
    [calendarActivity],
  )

  const displayedDate = () => {
    if (
      isCalvingDone &&
      isCalving &&
      calendarActivity.event.is_calving_relative &&
      calendarActivity.event.time_offset === 0
    ) {
      return format(gestationCalendar.calving_date, "dd.MM.yyyy HH:mm")
    }
    return dateToShow
  }

  return (
    <div
      className={
        isAfterCalving
          ? styles.accordionWrapperSecondary
          : styles.accordionWrapperPrimary
      }
      data-is-active={activityState === CalendarActivityState.CURRENT}
      data-is-done={activityState === CalendarActivityState.DONE}
      onClick={(event) => handleAccordionOpen(event)}
    >
      <div className={styles.accordionTrigger}>
        <div className={styles.triggerContent}>
          <div className={styles.title}>{calendarActivity.event.name}</div>
          <div className={styles.date}>{displayedDate()}</div>
        </div>
        <div className={styles.arrowWrapper} data-is-opened={isAccordionOpen}>
          <ArrowDown />
        </div>
      </div>
      <div
        className={styles.accordionContentWrapper}
        data-is-opened={isAccordionOpen}
      >
        <div className={styles.accordionContent}>
          {calendarActivity.event.description}
          <div className={styles.actionButtons}>
            {calendarActivity.event.animal_event_type &&
              checkIfTodayOrIfInInterval(calendarActivity) &&
              !isCalving &&
              !calendarActivity.animal_event && (
                <GestationCalendarActionButton
                  text={t("addEvent")}
                  icon={<AddEventIcon />}
                  onClick={handleAddEvent}
                />
              )}
            {calendarActivity.animal_event && (
              <GestationCalendarActionButton
                text={t("viewEvent")}
                icon={<EmptyCalendarIcon size={17} />}
                onClick={() => handleViewEvent(calendarActivity.animal_event)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default GestationCalendarAccordion
