import React, { type ReactElement, type ReactNode } from "react"
import styles from "./binary-choice-field-card.module.css"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import RequiredIndicator from "@/features/farm/components/required-indicator/required-indicator.tsx"

interface BinaryChoiceFieldCardProps {
  titleIcon: ReactNode
  firstChoiceIcon: ReactElement
  secondChoiceIcon: ReactElement
  titleText: string
  isReadOnly: boolean
  onClickFirstChoice: () => void
  onClickSecondChoice: () => void
  firstCondition: boolean
  secondCondition: boolean
  firstChoiceName: string
  secondChoiceName: string
  isRequired?: boolean
  errorPlaceholder?: string
}

const BinaryChoiceFieldCard: React.FC<BinaryChoiceFieldCardProps> = ({
  titleIcon,
  isReadOnly,
  titleText,
  firstChoiceIcon,
  secondChoiceIcon,
  onClickFirstChoice,
  onClickSecondChoice,
  firstCondition,
  secondCondition,
  firstChoiceName,
  secondChoiceName,
  isRequired = false,
}) => {
  return (
    <OverflowMenuItem>
      <div className={styles["title-flex"]}>
        {titleIcon}
        <div>{titleText}</div>
      </div>
      <div
        className={`${styles["choice-flex"]} ${isReadOnly && styles["choice-disabled"]}`}
      >
        <div
          role={"button"}
          className={
            firstCondition ? styles["choice-active"] : styles["choice-inactive"]
          }
          onClick={onClickFirstChoice}
        >
          <div className={styles["choice-icon"]}>
            {React.cloneElement(firstChoiceIcon, { is_active: firstCondition })}
          </div>
          <div
            className={
              firstCondition ? styles["active-text"] : styles["inactive-text"]
            }
          >
            {firstChoiceName}
          </div>
        </div>
        <div
          role={"button"}
          className={
            secondCondition
              ? styles["choice-active"]
              : styles["choice-inactive"]
          }
          onClick={onClickSecondChoice}
        >
          <div className={styles["choice-icon"]}>
            {React.cloneElement(secondChoiceIcon, {
              is_active: secondCondition,
            })}
          </div>
          <div
            className={
              secondCondition ? styles["active-text"] : styles["inactive-text"]
            }
          >
            {secondChoiceName}
          </div>
        </div>
      </div>
      {isRequired && <RequiredIndicator />}
    </OverflowMenuItem>
  )
}

export default BinaryChoiceFieldCard
