import { useCallback } from "react"
import { useDispatch } from "react-redux"
import { hideModal, showModal } from "@/redux/slices/modal-slice.ts"

const useModal = () => {
  const dispatch = useDispatch()

  const openModal = useCallback(
    (modalType: string, modalProps: any = {}) => {
      dispatch(showModal({ modalType, modalProps }))
    },
    [dispatch],
  )

  const closeModal = useCallback(() => {
    dispatch(hideModal())
  }, [dispatch])

  return { openModal, closeModal }
}

export default useModal
