import React from "react"
import ArrowRight from "@/assets/icons/misc/arrow-right.tsx"
import styles from "@/features/finance/components/transaction-card-skeleton/transaction-card-skeleton.module.css"
import TransactionCardWrapper from "@/features/finance/components/transaction-card-wrapper/transaction-card-wrapper.tsx"
import { GREY } from "@/utils/constants/colors-constants.ts"

interface TransactionCardSkeletonProps {
  onClick: () => void
  icon: React.ReactNode
  topLeftText?: string
  topRightText: string
  bottomRightText: string
  bottomLeftText: string
  isOutflow?: boolean
  showArrow?: boolean
}

const TransactionCardSkeleton: React.FC<TransactionCardSkeletonProps> = ({
  onClick,
  icon,
  topLeftText,
  topRightText,
  bottomLeftText,
  bottomRightText,
  isOutflow = false,
  showArrow = false,
}) => {
  return (
    <TransactionCardWrapper onClick={onClick}>
      {icon}
      <div className={styles.cardBody}>
        <div className={styles.top}>
          <div className={styles.title}>{topLeftText}</div>
          <div className={styles.amount} data-is-outflow={isOutflow}>
            {topRightText}
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.date}>{bottomLeftText}</div>
          <div className={styles.currency}>{bottomRightText}</div>
        </div>
      </div>
      {showArrow && <ArrowRight width={15} height={15} stroke={GREY} />}
    </TransactionCardWrapper>
  )
}

export default TransactionCardSkeleton
