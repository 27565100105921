import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import styles from "../../../notes/components/notes-group/notes-group.module.css"
import NoResultsFound from "@/components/not-found/no-results-found.tsx"
import NotificationsByTimeSpan from "@/features/notifications/components/notifications-by-tme-span/notifications-by-tme-span.tsx"
import { type Notification } from "@/features/notifications/types/notifications.ts"
import {
  groupItemsByTimeStampWithField,
  initialItemsTimeGroups,
  type ItemsTimeGroups,
} from "@/utils/helpers/time-grouping.ts"

interface NotesGroupProp {
  notifications: Notification[]
}

const NotesGroup: React.FC<NotesGroupProp> = ({ notifications }) => {
  const { t } = useTranslation()
  const [sortedNotifications, setSortedNotifications] =
    useState<ItemsTimeGroups>(initialItemsTimeGroups)

  useEffect(() => {
    setSortedNotifications(
      groupItemsByTimeStampWithField(notifications, "created_at"),
    )
  }, [notifications])

  return (
    <div className={styles["notes-group-wrapper"]}>
      {sortedNotifications.todayItems.length > 0 && (
        <NotificationsByTimeSpan
          notifications={sortedNotifications.todayItems as Notification[]}
          title={t("today")}
        />
      )}

      {sortedNotifications.thisWeekItems.length > 0 && (
        <NotificationsByTimeSpan
          notifications={sortedNotifications.thisWeekItems as Notification[]}
          title={t("thisWeek")}
        />
      )}

      {sortedNotifications.thisMonthItems.length > 0 && (
        <NotificationsByTimeSpan
          notifications={sortedNotifications.thisMonthItems as Notification[]}
          title={t("thisMonth")}
        />
      )}

      {sortedNotifications.pastMonthItems.length > 0 && (
        <NotificationsByTimeSpan
          notifications={sortedNotifications.pastMonthItems as Notification[]}
          title={t("lastMonth")}
        />
      )}

      {sortedNotifications.thisYearItems.length > 0 && (
        <NotificationsByTimeSpan
          notifications={sortedNotifications.thisYearItems as Notification[]}
          title={t("thisYear")}
        />
      )}

      {notifications.length > 0 ? (
        <div className={styles["total-notifications"]}>
          {notifications.length > 1
            ? `${notifications.length} ${t("notifications")}`
            : `1 ${t("notification")}`}
        </div>
      ) : (
        <div className={styles.noResultsFound}>
          <NoResultsFound />
          <div className={styles["no-notifications"]}>
            {t("noNewNotifications")}
            <div className={styles["no-notifications-sub-heading"]}>
              Notificările vor apărea aici imediat ce le vei primi.
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default NotesGroup
