import React from "react"
import { useTranslation } from "react-i18next"
import { Drawer } from "vaul"
import {
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import DrawerAnimalContent from "@/features/farm/components/drawer-animals/drawer-animal-content.tsx"

interface CombinedComponentProps {
  currentValue: string[]
  onSaveClick: (animalIDs: string[]) => void
  TriggerComponent: React.FC<TriggerComponentProps>
}

interface TriggerComponentProps {
  currentValue: string[] | string
  onClick: () => void
}

const DrawerAnimalsNested: React.FC<CombinedComponentProps> = ({
  onSaveClick,
  currentValue,
  TriggerComponent,
}) => {
  const { t } = useTranslation()
  return (
    <Drawer.NestedRoot>
      <DrawerTrigger asChild>
        <TriggerComponent currentValue={currentValue} onClick={() => {}} />
      </DrawerTrigger>
      <DrawerContent style={{ height: "85%" }}>
        <DrawerAnimalContent
          currentValue={currentValue}
          onSaveClick={onSaveClick}
          placeholder={t("searchNote")}
        />
      </DrawerContent>
    </Drawer.NestedRoot>
  )
}

export default DrawerAnimalsNested
