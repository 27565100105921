import { apiSlice, TagType } from "@/features/api/apiSlice.ts"
import {
  type Transaction,
  type TransactionDraft,
} from "@/features/finance/types/transaction.ts"

const TRANSACTIONS_BASE_URL = "transactions/"

export const transactionsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTransactions: builder.query<Transaction[], void>({
      query: () => ({
        url: TRANSACTIONS_BASE_URL,
      }),
      providesTags: () => [{ type: TagType.Transactions, id: "LIST" }],
    }),
    getTransactionById: builder.query<Transaction, string>({
      query: (id) => ({
        url: TRANSACTIONS_BASE_URL + `${id}/`,
      }),
      providesTags: (_result, _error, id) => [
        { type: TagType.Transactions, id },
      ],
    }),
    createTransaction: builder.mutation<TransactionDraft, Partial<Transaction>>(
      {
        query: (transaction) => {
          return {
            url: TRANSACTIONS_BASE_URL,
            method: "POST",
            body: transaction,
          }
        },
        invalidatesTags: [{ type: TagType.Transactions, id: "LIST" }],
      },
    ),
    deleteTransaction: builder.mutation<void, string>({
      query: (transactionId) => ({
        url: `${TRANSACTIONS_BASE_URL}${transactionId}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TagType.Transactions, id: "LIST" }],
    }),
    updateTransaction: builder.mutation<
      Transaction,
      { id: Transaction["id"] } & Partial<TransactionDraft>
    >({
      query: (transaction) => {
        return {
          url: TRANSACTIONS_BASE_URL + `${transaction.id}/`,
          method: "PATCH",
          body: transaction,
        }
      },
      invalidatesTags: (_res, _err, { id }) => [
        { type: TagType.Transactions, id: "LIST" },
        { type: TagType.Transactions, id },
      ],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetTransactionsQuery,
  useCreateTransactionMutation,
  useGetTransactionByIdQuery,
  useDeleteTransactionMutation,
  useUpdateTransactionMutation,
} = transactionsApi
