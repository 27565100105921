import React from "react"
import { useTranslation } from "react-i18next"
import TreatmentsCountIcon from "@/assets/icons/ruminant-details/treatments-count-icon.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import InputFieldWrapperWithIcon from "@/components/text-card-wrapper-with-icon/input-field-wrapper-with-icon.tsx"
import { useGetAllMedicationTypesQuery } from "@/features/animal-events/api/medication-types-api.ts"
import styles from "@/features/animal-events/components/animal-event-editor/events-editor.module.css"
import MedicationTypeDrawer from "@/features/animal-events/components/medication-type-drawer/medication-type-drawer.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  type AnimalEventDraft,
  setDraftEventSubDataDosage,
  setDraftEventSubDataMedication,
} from "@/redux/slices/events-draft-slice.ts"

interface TreatmentEventEditorProps {
  draftEvent: AnimalEventDraft
}

const TreatmentEventEditor: React.FC<TreatmentEventEditorProps> = ({
  draftEvent,
}) => {
  const { data: medications } = useGetAllMedicationTypesQuery()
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const setMedication = (medication: string | undefined) => {
    dispatch(setDraftEventSubDataMedication(medication))
  }

  const setDosage = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDraftEventSubDataDosage(event.target.value))
  }

  return (
    <>
      <div className={styles.eventInfo}>{t("specificEventDetails")}</div>
      <OverflowMenuItem>
        {medications && (
          <MedicationTypeDrawer
            selectedValue={draftEvent.event_data?.medication}
            onEventSelect={setMedication}
            medications={medications}
            fallbackText={"selectMedication"}
          />
        )}
      </OverflowMenuItem>
      <InputFieldWrapperWithIcon
        icon={<TreatmentsCountIcon />}
        align={"row"}
        name={t("dosage")}
        maxLength={14}
        inputMode={"numeric"}
        isDisabled={!draftEvent.event_data?.medication}
        value={draftEvent.event_data?.dosage}
        isRequired={draftEvent.event_data?.dosage === undefined}
        onChange={setDosage}
      />
    </>
  )
}

export default TreatmentEventEditor
