const RequiredIndicator = () => {
  return (
    <span
      style={{
        color: "red",
        position: "absolute",
        right: "16px",
        fontSize: "18px",
      }}
    >
      *
    </span>
  )
}

export default RequiredIndicator
