import { Route } from "react-router-dom"
import { MenuPage } from "@/features/menu"
import { StandardLayout } from "@/layouts/standard-layout/standard-layout.tsx"
import { MENU_PATH } from "@/utils/constants/routes.ts"

export const MenuRoutes = (
  <Route element={<StandardLayout />}>
    <Route key={MENU_PATH} path={MENU_PATH} element={<MenuPage />} />
  </Route>
)
