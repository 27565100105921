import React, { type ReactNode } from "react"
import styles from "./text-card-wrapper-with-icon.module.css"
import CustomNumberInput from "@/components/custom-number-input/custom-number-input.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"

interface TextCardProps {
  icon: ReactNode
  name: string
  isReadOnly: boolean
  value: any
  onChange: (newValue: any) => void
}

const InputNumberFieldWithIcon: React.FC<TextCardProps> = ({
  icon,
  name,
  isReadOnly,
  value = "",
  onChange,
}) => {
  return (
    <OverflowMenuItem>
      <div className={styles.flexRowSpaceBetween}>
        <div className={styles.flexRow}>
          <div className={styles.icon}>{icon}</div>
          <div className={styles.textInput}>{name}</div>
        </div>
        <CustomNumberInput
          value={value}
          onChange={onChange}
          isReadOnly={isReadOnly}
        />
      </div>
    </OverflowMenuItem>
  )
}

export default InputNumberFieldWithIcon
