import { type FC, type ReactNode } from "react"
import { Navigate, Outlet } from "react-router-dom"

interface ProtectedRouteProps {
  children?: ReactNode
  condition: boolean
  redirectRoute: string
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
  children,
  condition,
  redirectRoute,
}) => {
  if (!condition) {
    return <Navigate to={redirectRoute} />
  }

  return children ? children : <Outlet />
}

export default ProtectedRoute
