import React from "react"

const GestationCountIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="20 486 30 30"
    >
      <g data-name="Group 4349">
        <path
          d="M27 486h16a7 7 0 0 1 7 7v16a7 7 0 0 1-7 7H27a7 7 0 0 1-7-7v-16a7 7 0 0 1 7-7z"
          fill="#414141"
          fillRule="evenodd"
          data-name="Rectangle 1959"
        />
        <g data-name="noun-pregnant-test-4504543">
          <g data-name="Group 4317">
            <path
              d="m45.325 494.538-2.123-2.436c-.71-.814-1.95-.9-2.767-.193l-9.767 8.469a1.955 1.955 0 0 0-.315 2.605l-5.26 4.562a1.173 1.173 0 0 0-.117 1.655l.836.96a1.18 1.18 0 0 0 1.66.115l5.261-4.561c.724.68 1.864.716 2.63.052l9.768-8.47a1.954 1.954 0 0 0 .194-2.758Zm-14.446 11.747-1.35-1.55 1.332-1.155 1.35 1.55-1.332 1.155Zm-4.475 3.361-.836-.959a.391.391 0 0 1 .039-.552l3.33-2.887 1.35 1.55-3.33 2.887a.393.393 0 0 1-.553-.039Zm8.445-4.47a1.18 1.18 0 0 1-1.66-.117l-2.122-2.435a1.173 1.173 0 0 1 .116-1.656l9.767-8.468a1.18 1.18 0 0 1 1.66.115l2.123 2.436a1.173 1.173 0 0 1-.116 1.655l-9.768 8.47Z"
              fill="#fff"
              fillRule="evenodd"
              data-name="Path 1626"
            />
            <path
              d="m42.984 495.206-.836-.96a1.18 1.18 0 0 0-1.66-.115l-4.18 3.625a1.173 1.173 0 0 0-.117 1.655l.837.96a1.18 1.18 0 0 0 1.66.116l4.18-3.625c.49-.425.542-1.168.116-1.656Zm-3.424.766 1.35 1.55-1.294 1.123-1.351-1.55 1.295-1.123Zm1.996-1.212.836.96a.391.391 0 0 1-.038.551l-.851.738-1.351-1.55.85-.738a.393.393 0 0 1 .554.039Zm-3.936 5.098-.837-.96a.391.391 0 0 1 .04-.552l.85-.737 1.35 1.55-.85.737a.393.393 0 0 1-.553-.038Z"
              fill="#fff"
              fillRule="evenodd"
              data-name="Path 1627"
            />
            <path
              d="M35.833 500.565a1.82 1.82 0 0 0-2.56-.179 1.808 1.808 0 0 0-.178 2.553 1.82 1.82 0 0 0 2.559.178c.754-.654.835-1.8.179-2.552Zm-2.146 1.86a1.026 1.026 0 0 1 .101-1.448 1.032 1.032 0 0 1 1.453.101 1.026 1.026 0 0 1-.102 1.449 1.032 1.032 0 0 1-1.452-.102Z"
              fill="#fff"
              fillRule="evenodd"
              data-name="Path 1628"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default GestationCountIcon
