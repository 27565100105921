import queryString from "query-string"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import {
  ALL_SPECIES,
  ANIMAL_AGES_ENUM,
  ANIMAL_GENDERS,
  ANIMAL_STATUS_ENUM,
  type AnimalFilterSelection,
  filterAnimals,
} from "@/features/farm"
import { useGetAnimalsQuery } from "@/features/farm/api/animals-api.ts"

const useAnimalFilterURL = () => {
  const navigate = useNavigate()
  const { data = [], isLoading } = useGetAnimalsQuery()

  const query = new URLSearchParams(useLocation().search)
  const searchQuery = query.get("searchQuery") || ""
  const status = query.get("status") || ANIMAL_STATUS_ENUM.ALL_STATUSES
  const gender = query.get("gender") || ANIMAL_GENDERS.ALL_GENDERS.label
  const species = query.get("species") || ALL_SPECIES.label
  const age = query.get("age") || ANIMAL_AGES_ENUM.ALL

  const initialFilter: AnimalFilterSelection = {
    searchQuery,
    status,
    species,
    age,
    gender,
  }

  const [selections, setSelections] = useState(initialFilter)

  const updateURL = useCallback(() => {
    const queryParams = {
      searchQuery: selections.searchQuery,
      status: selections.status,
      species: selections.species,
      age: selections.age,
      gender: selections.gender,
    }
    const searchString = queryString.stringify(queryParams)
    navigate({ search: searchString }, { replace: true })
  }, [selections, navigate])

  useEffect(() => {
    updateURL()
  }, [selections, updateURL])

  const handleAgeSelection = (age: string) => {
    setSelections((prev) => ({
      ...prev,
      age,
    }))
  }

  const handleGenderSelection = (gender: string) => {
    setSelections((prev) => ({
      ...prev,
      gender,
    }))
  }

  const handleSpeciesSelection = (species: string) => {
    setSelections((prev) => ({
      ...prev,
      species,
    }))
  }

  const handleSearchQuery = (searchQuery: string) => {
    setSelections((prev) => ({
      ...prev,
      searchQuery,
    }))
  }

  const handleStatusSelection = (status: string) => {
    setSelections((prev) => ({
      ...prev,
      status,
    }))
  }

  const animals = useMemo(
    () => filterAnimals(data, selections),
    [data, selections],
  )

  return {
    selections,
    animals,
    isLoading,
    handleAgeSelection,
    handleGenderSelection,
    handleSpeciesSelection,
    handleSearchQuery,
    handleStatusSelection,
  }
}

export default useAnimalFilterURL
