import React from "react"
import { WHITE } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const TickIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      width="16"
      height="12"
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 4L4.5 7L10.5 1"
        stroke={props?.color || WHITE}
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TickIcon
