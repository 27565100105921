import React from "react"
import styles from "./overflow-menu-item.module.css"
import Skeleton from "@/components/skeleton/skeleton.tsx"

interface TaskSettingCardProps {
  children: React.ReactNode
  isDisabled?: boolean
  marginInline?: boolean
  onClick?: () => void
  minHeight?: number
  height?: number
  isLoading?: boolean
  skeletonHeight?: number
}

const OverflowMenuItem: React.FC<TaskSettingCardProps> = ({
  children,
  isDisabled = false,
  marginInline = true,
  onClick,
  minHeight,
  height,
  isLoading,
  skeletonHeight,
}) => {
  const propStyle = {
    marginInline: marginInline ? "16px" : 0,
    minHeight: minHeight,
    height: height,
  }

  return (
    <div
      className={styles.overflowMenuItem}
      data-is-loading={isLoading}
      style={isLoading ? undefined : propStyle}
      data-disabled={isDisabled}
      onClick={onClick}
    >
      {isLoading ? <Skeleton height={skeletonHeight} /> : children}
    </div>
  )
}

export default OverflowMenuItem
