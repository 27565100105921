import React from "react"
import { useTranslation } from "react-i18next"
import BreedIcon from "@/assets/icons/farm/breed-icon.tsx"
import styles from "@/components/shadcn/drawer/custom-drawer.module.css"
import { type AnimalDraft, useBreedName } from "@/features/farm"

interface CustomTriggerProps {
  currentValue: AnimalDraft
  onClick: () => void
}

const DrawerBreedsTrigger = React.forwardRef<
  HTMLDivElement,
  CustomTriggerProps
>(({ currentValue, onClick }, ref) => {
  const { t } = useTranslation()
  const breedName = useBreedName(currentValue.breed)

  return (
    <div
      role={"button"}
      className={styles["task-card-item"]}
      ref={ref}
      onClick={onClick}
    >
      <div className={styles["task-card-header"]}>
        <div className={styles["icon-wrapper"]}>
          <BreedIcon />
          <div>
            <p>{t("breed")}</p>
            {currentValue.breed !== "" && (
              <p className={styles["info"]}>{breedName}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

DrawerBreedsTrigger.displayName = "DrawerBreedsTrigger"

export default DrawerBreedsTrigger
