import { createSlice, type PayloadAction } from "@reduxjs/toolkit"
import {
  type FarmProfile,
  type FarmProfileDraft,
} from "@/features/settings-account"
import { initialFarmProfile } from "@/features/settings-account/utils/initialStates.ts"

type FarmProfileDraftSlice = {
  draft: FarmProfileDraft
}

const initialFarmState: FarmProfileDraftSlice = {
  draft: initialFarmProfile,
}

const farmProfileDraftSlice = createSlice({
  name: "farmProfileDraft",
  initialState: initialFarmState,
  reducers: {
    setFarmDraft: (state, action: PayloadAction<FarmProfile>) => {
      state.draft = action.payload
    },
    setDraftFarmName: (state, action: PayloadAction<string>) => {
      state.draft.name = action.payload
    },
    setDraftFarmDescription: (state, action: PayloadAction<string>) => {
      state.draft.description = action.payload
    },
    setDraftFarmPicture: (state, action: PayloadAction<string | undefined>) => {
      state.draft.picture = action.payload
    },
    setDraftFarmLegalName: (state, action: PayloadAction<string>) => {
      state.draft.legal_name = action.payload
    },
    setDraftFarmTin: (state, action: PayloadAction<string>) => {
      state.draft.tin = action.payload
    },
  },
})

export default farmProfileDraftSlice.reducer

export const {
  setFarmDraft,
  setDraftFarmName,
  setDraftFarmDescription,
  setDraftFarmPicture,
  setDraftFarmLegalName,
  setDraftFarmTin,
} = farmProfileDraftSlice.actions
