import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

export function useScrollRestoration(
  ref: React.RefObject<HTMLElement>,
  isContentLoaded: boolean = true,
) {
  const location = useLocation()
  const locationKey = location.key || location.pathname
  const position = useRef(0)

  useEffect(() => {
    if (!isContentLoaded) return

    const element = ref.current
    if (!element) return

    const savedPosition = sessionStorage.getItem(
      `scrollPosition-${locationKey}`,
    )
    if (savedPosition) {
      element.scrollTop = parseInt(savedPosition, 10)
    }

    const handleScroll = () => {
      position.current = element.scrollTop
    }

    element.addEventListener("scroll", handleScroll)

    return () => {
      sessionStorage.setItem(
        `scrollPosition-${locationKey}`,
        position.current.toString(),
      )
      element.removeEventListener("scroll", handleScroll)
    }
  }, [locationKey, isContentLoaded, ref])
}
