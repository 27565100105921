import React from "react"
import { useTranslation } from "react-i18next"
import BackIcon from "@/assets/icons/misc/back-icon.tsx"
import CircleTickIcon from "@/assets/icons/task/circle-tick-icon.tsx"
import commonStyles from "@/common.module.css"
import styles from "@/components/top-bars/top-bars-common.module.css"

interface EditorTopBarProps {
  text: string
  onSave?: () => void
  isModified: boolean
  onBackClick: () => void
}

const EditorTopBar: React.FC<EditorTopBarProps> = ({
  text,
  onSave,
  isModified = true,
  onBackClick,
}) => {
  const { t } = useTranslation()

  return (
    <div className={styles.topBar}>
      <div className={styles.left}>
        <div className={styles.actionIcon} onClick={onBackClick}>
          <BackIcon />
        </div>
        <div className={styles.title}>{t(text)}</div>
      </div>
      <div className={styles.floatRight}>
        {isModified && (
          <div
            role="button"
            className={styles.actionIcon}
            onClick={() => {
              onSave?.()
            }}
          >
            <CircleTickIcon alt="save" size={30} />
            <span className={commonStyles["sr-only"]}>Save animal</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default EditorTopBar
