import { useTranslation } from "react-i18next"
import styles from "./menu-page.module.css"
import MenuCowIcon from "@/assets/icons/menu/menu-cow-icon.tsx"
import MenuNotificationBellIcon from "@/assets/icons/menu/menu-notification-bell-icon.tsx"
import MenuPiggyBankIcon from "@/assets/icons/menu/menu-piggy-bank-icon.tsx"
import MenuAIIconMobile from "@/assets/icons/menu/mobile/menu-ai-icon.tsx"
import MenuGestationCalendarIconMobile from "@/assets/icons/menu/mobile/menu-gestation-calendar-icon-mobile.tsx"
import MenuMedKitIconMobile from "@/assets/icons/menu/mobile/menu-med-kit-icon-mobile.tsx"
import MenuNotesIconMobile from "@/assets/icons/menu/mobile/menu-notes-icon-mobile.tsx"
import MenuSettingsIconMobile from "@/assets/icons/menu/mobile/menu-settings-icon-mobile.tsx"
import MenuTasksIconMobile from "@/assets/icons/menu/mobile/menu-tasks-icon-mobile.tsx"
import { MenuItemLink } from "@/features/menu/components/menu-item-link/menu-item-link"
import {
  MENU_CONTACT_SPECIALIST_COLOR,
  MENU_FINANCIAL_JOURNAL_COLOR,
  MENU_GESTATION_PERIOD_CALENDAR_COLOR,
  MENU_NOTES_COLOR,
  MENU_MY_FARM_COLOR,
  MENU_TASKS_COLOR,
  MENU_SETTINGS_COLOR,
  MENU_NOTIFICATIONS_COLOR,
  COW_PURPLE,
  MENU_VOICE_ASSISTANT_COLOR,
} from "@/utils/constants/colors-constants.ts"
import {
  AI_ASSISTANT_SCREEN_PATH,
  ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH,
  ANIMAL_EVENT_SCREEN_PATH,
  FARM_SCREEN_PATH,
  FINANCIAL_SCREEN_PATH,
  NOTES_VIEW_PATH,
  NOTIFICATIONS_PATH,
  SETTINGS_SCREEN_PATH,
  TASKS_SCREEN_PATH,
} from "@/utils/constants/routes"
import useFarmPermissions from "@/features/settings-account/hooks/use-farm-permissions.tsx"

export const MenuPage = () => {
  const { t } = useTranslation()
  const { permissions } = useFarmPermissions()

  return (
    <div className={styles["menu-page-content"]}>
      <div className={styles["menu-page-column"]}>
        <MenuItemLink
          height={150}
          icon={<MenuCowIcon />}
          text={t("myFarm")}
          to={FARM_SCREEN_PATH}
          color={MENU_MY_FARM_COLOR}
        />
        <MenuItemLink
          height={150}
          icon={<MenuNotesIconMobile />}
          text={t("notes")}
          to={NOTES_VIEW_PATH}
          color={MENU_NOTES_COLOR}
        />
        <MenuItemLink
          height={150}
          icon={
            <MenuGestationCalendarIconMobile
              color={MENU_VOICE_ASSISTANT_COLOR}
            />
          }
          text={t("animalEvents")}
          to={ANIMAL_EVENT_SCREEN_PATH}
          color={MENU_VOICE_ASSISTANT_COLOR}
        />
        <MenuItemLink
          height={150}
          icon={<MenuPiggyBankIcon />}
          text={t("jurnal")}
          isEnabled={permissions?.is_finance_journal_enabled}
          to={FINANCIAL_SCREEN_PATH}
          color={MENU_FINANCIAL_JOURNAL_COLOR}
        />
        <MenuItemLink
          height={150}
          icon={<MenuGestationCalendarIconMobile />}
          text={t("gestationPeriodCalendar")}
          isEnabled={permissions?.is_gestational_calendar_enabled}
          to={ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH}
          color={MENU_GESTATION_PERIOD_CALENDAR_COLOR}
        />
      </div>
      <div className={styles["menu-page-column"]}>
        <MenuItemLink
          height={150}
          icon={<MenuTasksIconMobile />}
          text={t("tasks")}
          to={TASKS_SCREEN_PATH}
          color={MENU_TASKS_COLOR}
        />
        <MenuItemLink
          height={150}
          icon={<MenuSettingsIconMobile />}
          text={t("settings")}
          to={SETTINGS_SCREEN_PATH}
          color={MENU_SETTINGS_COLOR}
        />
        <MenuItemLink
          height={150}
          icon={<MenuNotificationBellIcon />}
          text={t("notifications")}
          to={NOTIFICATIONS_PATH}
          color={MENU_NOTIFICATIONS_COLOR}
        />
        <MenuItemLink
          height={150}
          icon={<MenuAIIconMobile />}
          text={t("intelligentAssistant")}
          isEnabled={permissions?.is_assistant_enabled}
          to={AI_ASSISTANT_SCREEN_PATH}
          color={COW_PURPLE}
        />
        <MenuItemLink
          height={150}
          icon={<MenuMedKitIconMobile />}
          text={t("contactTheSpecialist")}
          isEnabled={false}
          to={NOTIFICATIONS_PATH}
          color={MENU_CONTACT_SPECIALIST_COLOR}
        />
      </div>
    </div>
  )
}
