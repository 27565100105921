import { apiSlice, TagType } from "@/features/api/apiSlice.ts"

import {
  type AuthRequest,
  type AuthResponse,
  type FarmProfileUpdateRequest,
  type UserProfileUpdateRequest,
} from "@/features/settings-account/api/types.ts"
import {
  type FarmProfile,
  type UserProfile,
} from "@/features/settings-account/utils/types.ts"

export const accountApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<AuthResponse, AuthRequest>({
      query: (credentials) => ({
        url: "auth-token/",
        method: "POST",
        body: credentials,
      }),
    }),
    getProfile: builder.query<UserProfile, void>({
      query: () => "/users/me/",
      providesTags: [TagType.UserProfile, TagType.FarmProfile],
    }),
    updateProfile: builder.mutation<
      UserProfile,
      Partial<UserProfileUpdateRequest>
    >({
      query: ({ id, userProfileUpdate }) => {
        return {
          url: "users/" + `${id}/`,
          method: "PATCH",
          body: userProfileUpdate,
        }
      },
      invalidatesTags: [TagType.UserProfile],
    }),
    getMyFarm: builder.query<FarmProfile, void>({
      query: () => "farms/my/",
      providesTags: [TagType.FarmProfile],
    }),
    getUsersForFarm: builder.query<Array<UserProfile>, void>({
      query: () => "farms/users/",
    }),
    updateMyFarmProfile: builder.mutation<
      FarmProfile,
      FarmProfileUpdateRequest
    >({
      query: ({ id, farmProfileUpdate }) => {
        return {
          url: "farms/" + `${id}/`,
          method: "PATCH",
          body: farmProfileUpdate,
        }
      },
      invalidatesTags: [TagType.FarmProfile],
    }),
  }),
  overrideExisting: false,
})

export const {
  useGetUsersForFarmQuery,
  useLoginMutation,
  useGetProfileQuery,
  useUpdateProfileMutation,
  useGetMyFarmQuery,
  useLazyGetMyFarmQuery,
  useUpdateMyFarmProfileMutation,
} = accountApi
