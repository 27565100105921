import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./animal-profile-status-widget.module.css"
import AnimalStatusDryIcon from "@/assets/icons/animal-statuses-icons/animal-status-dry-icon.tsx"
import AnimalStatusHealthyIcon from "@/assets/icons/animal-statuses-icons/animal-status-healthy-icon.tsx"
import AnimalStatusHeatIcon from "@/assets/icons/animal-statuses-icons/animal-status-heat-icon.tsx"
import AnimalStatusIllIcon from "@/assets/icons/animal-statuses-icons/animal-status-ill-icon.tsx"
import AnimalStatusLactatingIcon from "@/assets/icons/animal-statuses-icons/animal-status-lacating-icon.tsx"
import AnimalStatusPregnantIcon from "@/assets/icons/animal-statuses-icons/animal-status-pregnant-icon.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import { useGetAllAnimalEventTypesQuery } from "@/features/animal-events/api/event-types-api.ts"
import { EventTypeStatus } from "@/features/animal-events/types/animal-events.ts"
import {
  type Animal,
  ANIMAL_GENDERS,
  ANIMAL_STATUS_ENUM,
} from "@/features/farm"
import AnimalProfileStatusRow from "@/features/farm/components/animal-profile-status-row/animal-profile-status-row.tsx"
import { NEW_ANIMAL_EVENT_SCREEN_PATH } from "@/utils/constants/routes.ts"
import { FP_QUERY_PARAMS } from "@/features/gestational-calendar/utils/gestation-calendar-consts.ts"

interface AnimalProfileStatusWidgetProps {
  animal: Animal
}

const AnimalProfileStatusWidget: React.FC<AnimalProfileStatusWidgetProps> = ({
  animal,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: allEventTypesRecords } = useGetAllAnimalEventTypesQuery()
  const allAnimalEventsValues =
    allEventTypesRecords && Object.values(allEventTypesRecords)

  const getCorrespondingTypeToStatus = (status: ANIMAL_STATUS_ENUM): string => {
    switch (status) {
      case ANIMAL_STATUS_ENUM.LACTATING:
        return EventTypeStatus.LactationStart
      case ANIMAL_STATUS_ENUM.DRY:
        return EventTypeStatus.DryOff
      case ANIMAL_STATUS_ENUM.UNDER_TREATMENT:
        return EventTypeStatus.IllnessTreatment
      case ANIMAL_STATUS_ENUM.GESTATING:
        return EventTypeStatus.PregnancyCheckPositive
      case ANIMAL_STATUS_ENUM.HEAT:
        return EventTypeStatus.HeatDetection
      case ANIMAL_STATUS_ENUM.HEALTHY:
        return EventTypeStatus.HealthCheckPositive
      default:
        return ""
    }
  }

  const getTypeByStatusName = (status: ANIMAL_STATUS_ENUM) => {
    const typeName = getCorrespondingTypeToStatus(status)
    return (
      allAnimalEventsValues &&
      allAnimalEventsValues.find((animalEvent) => animalEvent.name === typeName)
    )
  }

  const addEvent = (status: ANIMAL_STATUS_ENUM) => {
    const type = getTypeByStatusName(status)
    const searchParams = new URLSearchParams()
    searchParams.append(FP_QUERY_PARAMS.ANIMAL_ID, animal.id)
    if (type) {
      searchParams.append(FP_QUERY_PARAMS.EVENT_TYPE_ID, type.id)
    }
    const navigationURL = `${NEW_ANIMAL_EVENT_SCREEN_PATH}?${searchParams.toString()}`
    navigate(navigationURL)
  }

  return (
    <OverflowMenuItem>
      <div className={styles.animalTitle}>
        {t("animalSummary")}
        <div className={styles.separator}></div>
      </div>
      <div className={styles.statusWrapper}>
        {animal.is_healthy !== undefined && (
          <AnimalProfileStatusRow
            onClick={() => addEvent(ANIMAL_STATUS_ENUM.HEALTHY)}
            icon={<AnimalStatusHealthyIcon />}
            status={ANIMAL_STATUS_ENUM.HEALTHY}
            isActive={animal.statuses?.includes(ANIMAL_STATUS_ENUM.HEALTHY)}
          />
        )}
        {animal.ruminant_fields?.is_lactating !== undefined &&
          animal.gender === ANIMAL_GENDERS.FEMALE.label && (
            <AnimalProfileStatusRow
              onClick={() => addEvent(ANIMAL_STATUS_ENUM.LACTATING)}
              icon={<AnimalStatusLactatingIcon />}
              status={ANIMAL_STATUS_ENUM.LACTATING}
              isActive={animal.statuses?.includes(ANIMAL_STATUS_ENUM.LACTATING)}
            />
          )}
        {animal.ruminant_fields?.is_in_heats !== undefined &&
          animal.gender === ANIMAL_GENDERS.FEMALE.label && (
            <AnimalProfileStatusRow
              onClick={() => addEvent(ANIMAL_STATUS_ENUM.HEAT)}
              icon={<AnimalStatusHeatIcon />}
              status={ANIMAL_STATUS_ENUM.HEAT}
              isActive={animal.statuses?.includes(ANIMAL_STATUS_ENUM.HEAT)}
            />
          )}
        {animal.is_under_treatment !== undefined && (
          <AnimalProfileStatusRow
            onClick={() => addEvent(ANIMAL_STATUS_ENUM.UNDER_TREATMENT)}
            icon={<AnimalStatusIllIcon />}
            status={ANIMAL_STATUS_ENUM.UNDER_TREATMENT}
            isActive={animal.statuses?.includes(
              ANIMAL_STATUS_ENUM.UNDER_TREATMENT,
            )}
          />
        )}
        {animal.ruminant_fields?.is_pregnant !== undefined &&
          animal.gender === ANIMAL_GENDERS.FEMALE.label && (
            <AnimalProfileStatusRow
              onClick={() => addEvent(ANIMAL_STATUS_ENUM.GESTATING)}
              icon={<AnimalStatusPregnantIcon />}
              status={ANIMAL_STATUS_ENUM.GESTATING}
              isActive={animal.statuses?.includes(ANIMAL_STATUS_ENUM.GESTATING)}
            />
          )}
        {animal.ruminant_fields?.is_lactating !== undefined &&
          animal.gender === ANIMAL_GENDERS.FEMALE.label && (
            <AnimalProfileStatusRow
              onClick={() => addEvent(ANIMAL_STATUS_ENUM.DRY)}
              icon={<AnimalStatusDryIcon />}
              status={ANIMAL_STATUS_ENUM.DRY}
              isActive={animal.statuses?.includes(ANIMAL_STATUS_ENUM.DRY)}
            />
          )}
      </div>
    </OverflowMenuItem>
  )
}

export default AnimalProfileStatusWidget
