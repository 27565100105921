import React from "react"

const InfoIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      id="info-svgrepo-com"
      xmlns="http://www.w3.org/2000/svg"
      width="41.056"
      height="41.056"
      viewBox="0 0 41.056 41.056"
      {...props}
    >
      <g id="Group_1046" data-name="Group 1046">
        <g id="Group_1045" data-name="Group 1045">
          <path
            id="Path_795"
            data-name="Path 795"
            d="M35.044,6.013A20.528,20.528,0,1,0,6.013,35.044,20.528,20.528,0,1,0,35.044,6.013ZM20.528,39.42A18.892,18.892,0,1,1,39.42,20.528,18.914,18.914,0,0,1,20.528,39.42Z"
            fill="var(--brown)"
          />
        </g>
      </g>
      <g
        id="Group_1048"
        data-name="Group 1048"
        transform="translate(33.665 23.881)"
      >
        <g id="Group_1047" data-name="Group 1047">
          <path
            id="Path_796"
            data-name="Path 796"
            d="M423.223,297.838a.817.817,0,0,0-.994.591,15.906,15.906,0,0,1-2.257,5.014.818.818,0,1,0,1.354.918,17.535,17.535,0,0,0,2.488-5.528A.818.818,0,0,0,423.223,297.838Z"
            transform="translate(-419.831 -297.812)"
            fill="var(--brown)"
          />
        </g>
      </g>
      <g
        id="Group_1050"
        data-name="Group 1050"
        transform="translate(36.542 19.71)"
      >
        <g id="Group_1049" data-name="Group 1049">
          <path
            id="Path_797"
            data-name="Path 797"
            d="M456.541,245.8a.818.818,0,0,0-.818.818c0,.234-.005.469-.015.7a.818.818,0,0,0,.782.852h.036a.818.818,0,0,0,.816-.783c.011-.255.017-.514.017-.771A.818.818,0,0,0,456.541,245.8Z"
            transform="translate(-455.707 -245.801)"
            fill="var(--brown)"
          />
        </g>
      </g>
      <g
        id="Group_1052"
        data-name="Group 1052"
        transform="translate(17.666 13.863)"
      >
        <g id="Group_1051" data-name="Group 1051">
          <path
            id="Path_798"
            data-name="Path 798"
            d="M223.165,172.877a2.866,2.866,0,0,0-2.862,2.862V189.97a2.862,2.862,0,1,0,5.725,0V175.739A2.866,2.866,0,0,0,223.165,172.877Zm1.227,17.093a1.227,1.227,0,0,1-2.454,0V175.739a1.227,1.227,0,0,1,2.454,0Z"
            transform="translate(-220.303 -172.877)"
            fill="var(--brown)"
          />
        </g>
      </g>
      <g
        id="Group_1054"
        data-name="Group 1054"
        transform="translate(17.666 7.238)"
      >
        <g id="Group_1053" data-name="Group 1053">
          <path
            id="Path_799"
            data-name="Path 799"
            d="M225.189,91.1a2.863,2.863,0,1,0,.839,2.025A2.885,2.885,0,0,0,225.189,91.1Zm-1.155,2.892a1.228,1.228,0,1,1,.359-.867A1.239,1.239,0,0,1,224.034,93.993Z"
            transform="translate(-220.303 -90.263)"
            fill="var(--brown)"
          />
        </g>
      </g>
    </svg>
  )
}

export default InfoIcon
