import { useTranslation } from "react-i18next"
import { countSpecies } from "@/features/farm"
import { useGetAnimalsQuery } from "@/features/farm/api/animals-api.ts"
import { useGetSpeciesQuery } from "@/features/farm/api/species-api.ts"

export const useGetAnimalsCount = () => {
  const { t } = useTranslation()
  const { data: species_breeds = {} } = useGetSpeciesQuery()
  const { data: animals = [] } = useGetAnimalsQuery()

  const animalCountMap: { [key: string]: number } = countSpecies(
    animals,
    species_breeds,
  )

  const countStrings: (string | null)[] = Object.values(species_breeds || {})
    .map((species) => {
      const count: number = animalCountMap[species.name] || 0
      if (count > 0) {
        return `${count} ${t(species.name)}`
      }
      return null
    })
    .filter(Boolean)

  const animalsCountString = countStrings.join(", ")

  return { animalsCountString, animalCountMap }
}
