import { useMemo } from "react"
import { useSearchParams } from "react-router-dom"
import { type TransactionFilterSelections } from "@/features/finance/hooks/use-transaction-filter.ts"

/**
 * Hook that returns the initial transaction filter selections based on the current URL params
 * to be used just to initialize the filtering state
 *
 * if the url already has a search query, selected category, expenses or incomes, it will return them
 * otherwise it will return the default values
 */
const useGetInitialTransactionsFilter = () => {
  const [search] = useSearchParams()

  return useMemo<TransactionFilterSelections>(() => {
    const selectedCategory = search.get("selectedCategory")
    const searchQuery = search.get("searchQuery")
    const expenses = search.get("expenses")
    const incomes = search.get("incomes")
    return {
      searchQuery: typeof searchQuery === "string" ? searchQuery : "",
      selectedCategory:
        typeof selectedCategory === "string"
          ? selectedCategory
          : "allCategories",
      expenses: expenses === "true",
      incomes: incomes === "true",
    }
  }, [search])
}

export default useGetInitialTransactionsFilter
