import { useSelector } from "react-redux"
import CowHeadIcon from "@/assets/icons/farm/cow-head-icon.tsx"
import CowIconSmall from "@/assets/icons/farm/cow-icon-small.tsx"
import GoatHeadIcon from "@/assets/icons/farm/goat-head-icon.tsx"
import GoatIconSmall from "@/assets/icons/farm/goat-icon-small.tsx"
import PigHeadIcon from "@/assets/icons/farm/pig-head-icon.tsx"
import PorkIconSmall from "@/assets/icons/farm/pork-icon-small.tsx"
import SheepHeadIcon from "@/assets/icons/farm/sheep-head-icon.tsx"
import SheepIconSmall from "@/assets/icons/farm/sheep-icon-small.tsx"
import cow_image from "@/assets/images/cow_animal_profile.jpeg"
import goat_image from "@/assets/images/goat_animal_profile.jpg"
import pig_image from "@/assets/images/pig_animal_profile.jpg"
import sheep_image from "@/assets/images/sheep_animal_profile.jpg"
import {
  type Animal,
  ANIMAL_AGES,
  ANIMAL_GENDERS,
  ANIMAL_STATUS_ENUM,
  animalAgesOptions,
  matchesAnimalAge,
  type Species,
} from "@/features/farm"
import { selectSpecies } from "@/features/farm/api/species-api.ts"
import {
  CALVING_COLOR,
  PALE_GREEN,
  PASTEL_BLUE,
  PASTEL_BROWN,
  PASTEL_RED,
  PASTEL_YELLOW,
} from "@/utils/constants/colors-constants.ts"
import { getAgeFromDateOfBirth } from "@/utils/helpers/time-utils.ts"

export const getGeneralAnimalImage = (species: string | undefined) => {
  switch (species) {
    case "Cow":
      return cow_image
    case "Sheep":
      return sheep_image
    case "Pork":
      return pig_image
    case "Goat":
      return goat_image
    default:
      return cow_image
  }
}

export function useBreedName(breed: string) {
  const species_breeds = useSelector(selectSpecies)
  const species_breeds_array = Object.values(species_breeds)

  const breedObject = species_breeds_array.find((species) =>
    species.breeds.some((b) => b.id === breed),
  )
  return breedObject?.breeds.find((b) => b.id === breed)?.name || ""
}

export const ALL_SPECIES = {
  label: "ALL_SPECIES",
  value: "ALL_SPECIES",
}

export function getSpeciesIcon(species: string | undefined) {
  switch (species) {
    case "Cow":
      return <CowIconSmall />
    case "Sheep":
      return <SheepIconSmall />
    case "Pork":
      return <PorkIconSmall />
    case "Goat":
      return <GoatIconSmall />
    default:
      return null
  }
}

export function getSpeciesHeadIcon(species: string | undefined) {
  switch (species) {
    case "Cow":
      return <CowHeadIcon />
    case "Sheep":
      return <SheepHeadIcon />
    case "Pork":
      return <PigHeadIcon />
    case "Goat":
      return <GoatHeadIcon />
    default:
      return null
  }
}

export function isRuminant(animal: Partial<Animal>) {
  return "ruminant_fields" in animal
}

export interface AnimalFilterSelection {
  searchQuery: string
  gender: string
  species: string
  age: string
  status: string
}

export const filterAnimals = (
  animals: Animal[],
  selections: AnimalFilterSelection,
) => {
  return animals.filter((animal) => {
    const animalAge = Number(getAgeFromDateOfBirth(animal.birth_date, "number"))

    const matchesSearch = Object.entries(animal).some(
      ([key, value]) =>
        key !== "id" &&
        typeof value === "string" &&
        value.toLowerCase().includes(selections.searchQuery.toLowerCase()),
    )

    const matchesGender =
      selections.gender === ANIMAL_GENDERS.ALL_GENDERS.label ||
      animal.gender === selections.gender

    const matchesAnimalCategory =
      selections.species === ALL_SPECIES.label ||
      animal.species === selections.species

    const matchesStatus =
      selections.status === ANIMAL_STATUS_ENUM.ALL_STATUSES ||
      animal.statuses?.includes(selections.status)

    return (
      matchesSearch &&
      matchesGender &&
      matchesAnimalCategory &&
      matchesStatus &&
      matchesAnimalAge(animalAge, selections.age)
    )
  })
}

export function getCurrentAnimalImage(animal: Animal) {
  return (
    (animal?.picture as string) ||
    animal?.breed_details?.picture ||
    animal?.species_details?.picture ||
    getGeneralAnimalImage(animal?.species_details?.name)
  )
}

export function getAnimalStatusColor(status: ANIMAL_STATUS_ENUM) {
  switch (status) {
    case ANIMAL_STATUS_ENUM.HEAT:
      return PASTEL_RED
    case ANIMAL_STATUS_ENUM.GESTATING:
      return PASTEL_YELLOW
    case ANIMAL_STATUS_ENUM.LACTATING:
      return PASTEL_BLUE
    case ANIMAL_STATUS_ENUM.UNDER_TREATMENT:
      return PASTEL_BROWN
    case ANIMAL_STATUS_ENUM.HEALTHY:
      return PALE_GREEN
    case ANIMAL_STATUS_ENUM.DRY:
      return CALVING_COLOR
    default:
      return PASTEL_RED
  }
}

export const getStatusesForAnimal = (animal: Animal) => {
  const statusesArray = []

  if (animal.is_healthy) {
    statusesArray.push(ANIMAL_STATUS_ENUM.HEALTHY)
  }

  if (
    animal.ruminant_fields?.is_lactating &&
    animal.gender === ANIMAL_GENDERS.FEMALE.label
  ) {
    statusesArray.push(ANIMAL_STATUS_ENUM.LACTATING)
  }

  if (
    animal.ruminant_fields?.is_in_heats &&
    animal.gender === ANIMAL_GENDERS.FEMALE.label
  ) {
    statusesArray.push(ANIMAL_STATUS_ENUM.HEAT)
  }

  if (
    animal.ruminant_fields?.is_pregnant &&
    animal.gender === ANIMAL_GENDERS.FEMALE.label
  ) {
    statusesArray.push(ANIMAL_STATUS_ENUM.GESTATING)
  }

  if (animal.is_under_treatment) {
    statusesArray.push(ANIMAL_STATUS_ENUM.UNDER_TREATMENT)
  }

  if (
    animal.ruminant_fields &&
    !animal.ruminant_fields.is_lactating &&
    animal.gender === ANIMAL_GENDERS.FEMALE.label
  ) {
    statusesArray.push(ANIMAL_STATUS_ENUM.DRY)
  }

  return statusesArray
}

export function countStatuses(animals: Animal[]): { [key: string]: number } {
  const statuses: { [key: string]: number } = {}
  animals.forEach((animal) => {
    const animalStatuses = animal.statuses
    animalStatuses?.forEach((status) => {
      if (!statuses[status]) {
        statuses[status] = 0
      }
      statuses[status] += 1
    })
  })

  return statuses
}

export const countGenders = (animals: Animal[]): { [key: string]: number } => {
  const genders: { [key: string]: number } = {}

  animals.forEach((animal) => {
    const gender = animal.gender
    if (!genders[gender]) {
      genders[gender] = 0
    }
    genders[gender] += 1
  })

  return genders
}

export const countAges = (animals: Animal[]): { [key: string]: number } => {
  const ages: { [key: string]: number } = {}

  animals.forEach((animal) => {
    animalAgesOptions.forEach((ageOption) => {
      const age = Number(getAgeFromDateOfBirth(animal.birth_date, "number"))
      if (matchesAnimalAge(age, ageOption.value)) {
        if (ageOption.label === ANIMAL_AGES.ALL.label) {
          return
        }
        if (!ages[ageOption.label]) {
          ages[ageOption.label] = 0
        }
        ages[ageOption.label] += 1
      }
    })
  })

  return ages
}

export const countSpecies = (
  animals: Animal[],
  species_breeds: Record<string, Species>,
): { [key: string]: number } => {
  const animalCountMap: { [key: string]: number } = {}

  animals.forEach((animal: Animal) => {
    const species = species_breeds?.[animal.species]
    if (species) {
      if (!animalCountMap[species.name]) {
        animalCountMap[species.name] = 0
      }
      animalCountMap[species.name] += 1
    }
  })

  return animalCountMap
}
