import React from "react"
import style from "./option-checkbox-selector.module.css"
import { InputType } from "@/utils/enums/input-type-enum.ts"
import { type Option } from "@/utils/types/misc-types.ts"

interface OptionCheckboxSelectorProps {
  option: Option
  selected: boolean
  onClick: (option: Option) => void
}

const OptionCheckboxSelector: React.FC<OptionCheckboxSelectorProps> = ({
  option,
  selected,
  onClick,
}) => {
  return (
    <label
      className={
        selected
          ? style["checkbox-full-width-selected"]
          : style["checkbox-full-width"]
      }
      htmlFor={`checkbox-${option.value}`}
    >
      <input
        id={`checkbox-${option.value}`}
        type={InputType.RADIO}
        checked={selected}
        onChange={() => onClick(option)}
        readOnly={true}
      />
      <p className={style["option"]}>{option.label}</p>
    </label>
  )
}

export default OptionCheckboxSelector
