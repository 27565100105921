import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Outlet } from "react-router-dom"
import { toast } from "react-toastify"
import styles from "./notifications-page.module.css"
import DoubleCheckIcon from "@/assets/icons/notifications/double-check-icon.tsx"
import { NotificationsFilterTab } from "@/components/filter-tab/notifications-filter-tab/notifications-filter-tab"
import PageContentWrapperSmall from "@/components/page-content-wrappers/page-content-wrapper-small.tsx"
import MobileTopBar from "@/components/top-bars/mobile-top-bar/mobile-top-bar.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import {
  useGetNotificationsQuery,
  useReadAllNotificationsMutation,
} from "@/features/notifications/api/notifications-api"
import NotificationsGroup from "@/features/notifications/components/notifications-group/notifications-group.tsx"
import { useNotificationFilter } from "@/features/notifications/hooks/use-notification-filter.ts"
import { useSubscribeToWebPush } from "@/features/notifications/hooks/use-subscribe-to-webpush.ts"

export const NotificationsPage: React.FC = () => {
  const { t } = useTranslation()
  const { openModal } = useModal()
  const { data: notifications = [] } = useGetNotificationsQuery()
  useSubscribeToWebPush()
  const [readAllNotificationsTrigger] = useReadAllNotificationsMutation()
  const areThereAnyUnreadNotification = notifications.some(
    (notification) => !notification.read_at,
  )

  const unreadCount = useMemo(() => {
    return (
      notifications?.filter((notification) => !notification.read_at).length || 0
    )
  }, [notifications])

  const readAllNotifications = () => {
    toast.promise(readAllNotificationsTrigger().unwrap(), {
      pending: t("updatingNotifications"),
      success: t("notificationsWereRead"),
      error: t("updateNotificationsError"),
    })
  }

  const readAllNotificationsDrawerTrigger = () =>
    openModal(MODALS.TWO_ACTION_DRAWER_MODAL, {
      title: t("youSureYouWantToReadThemAll"),
      onActionText: t("markAllAsRead"),
      onBackText: t("cancel"),
      onActionClick: () => readAllNotifications(),
    })

  const { notificationFilter, handleFilterChange, filteredNotifications } =
    useNotificationFilter(notifications)

  return (
    <>
      <MobileTopBar
        title={t("notifications")}
        icon={areThereAnyUnreadNotification && <DoubleCheckIcon />}
        onIconClick={readAllNotificationsDrawerTrigger}
        titleInfo={
          unreadCount > 0 && (
            <div className={styles.notificationCount}>{unreadCount}</div>
          )
        }
      />
      <PageContentWrapperSmall>
        <NotificationsFilterTab
          initialFilter={notificationFilter}
          onFilterChange={handleFilterChange}
        />
        <NotificationsGroup notifications={filteredNotifications} />
      </PageContentWrapperSmall>
      <Outlet />
    </>
  )
}
