import { useTranslation } from "react-i18next"
import styles from "./generic-drawer-selection-row.module.css"
import ChooseSomethingIcon from "@/assets/icons/finance/choose-something-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import useLongPress from "@/hooks/use-long-press.ts"

interface GenericDrawerSelectionRowInterface {
  id: string
  name: string
  picture?: string | null
}

interface GenericDrawerSelectionRowProps<
  T extends GenericDrawerSelectionRowInterface,
> {
  isSelected: boolean
  onClick: () => void
  onLongClick: () => void
  entity: T
}

const GenericDrawerSelectionRow = <
  T extends GenericDrawerSelectionRowInterface,
>({
  entity,
  isSelected,
  onClick,
  onLongClick,
}: GenericDrawerSelectionRowProps<T>) => {
  const { t } = useTranslation()
  const longPressHandlers = useLongPress(onLongClick)

  return (
    <div
      {...longPressHandlers}
      data-selected={isSelected}
      className={styles.titleFlexCategory}
      onClick={onClick}
    >
      {entity.picture ? (
        <GenericPicture
          src={entity.picture}
          alt=""
          size={30}
          borderRadius={50}
          shadow={true}
        />
      ) : (
        <ChooseSomethingIcon size={30} />
      )}
      <div className={styles.title}>{t(entity.name)}</div>
    </div>
  )
}

export default GenericDrawerSelectionRow
