import { useDebounce } from "@uidotdev/usehooks"
import { useMemo } from "react"
import { useGetNotesQuery } from "@/features/notes/api/notes-api.ts"

/**
 * Custom hook to fetch and prepare notes data.
 * @param searchQuery The query used to filter notes.
 * @returns A list of filtered and sorted notes.
 */
export function useSearchNotes(searchQuery: string) {
  const debouncedSearchQuery = useDebounce(searchQuery, 100)
  const { data: notes } = useGetNotesQuery()

  return useMemo(() => {
    if (!notes) return []
    return notes.filter(
      (note) =>
        note.title.toLowerCase().includes(debouncedSearchQuery.toLowerCase()) ||
        note.content.toLowerCase().includes(debouncedSearchQuery.toLowerCase()),
    )
  }, [notes, debouncedSearchQuery])
}
