import React from "react"
import styles from "./chat-bubble.module.css"
import { useTranslation } from "react-i18next"
import ReactMarkdown from "react-markdown"

interface ChatBubbleProps {
  message: string
  isUser: boolean
  timestamp: string
}

const ChatBubble: React.FC<ChatBubbleProps> = ({
  message,
  isUser,
  timestamp,
}) => {
  const { t } = useTranslation()

  return (
    <>
      {message && message.trim() !== "" ? (
        <>
          <div className={styles.chatBubbleWrapper} data-is-user={isUser}>
            <div className={styles.chatBubble} data-is-user={isUser}>
              <ReactMarkdown>{message}</ReactMarkdown>
            </div>
          </div>
          <div className={styles.chatBubbleWrapper} data-is-user={isUser}>
            <div className={styles.timeStamp}>{timestamp}</div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.chatBubble} data-is-loading={true}></div>
          <div className={styles.chatBubbleTupleWrapper}>
            <div className={styles.timeStamp}>{t("loading")}</div>
          </div>
        </>
      )}
    </>
  )
}

export default ChatBubble
