import React from "react"
import { type DateRange } from "react-day-picker"
import styles from "./generic-date-picker.module.css"
import Calendar from "@/components/shadcn/calendar/calendar.tsx"

interface GenericCalendarProps {
  receivedDate: DateRange | undefined
  setIsOpened: (value: boolean) => void
  onChange: (date: DateRange | undefined) => void
  withFooter?: boolean
}

const GenericCalendarRange: React.FC<GenericCalendarProps> = ({
  receivedDate,
  setIsOpened,
  onChange,
  withFooter = true,
}) => {
  const handleSelect = (date: DateRange | undefined) => {
    onChange(date)
  }

  return (
    <div className={styles.calendarWrapper}>
      <Calendar
        mode={"range"}
        selected={receivedDate}
        defaultMonth={receivedDate?.from}
        onSelect={handleSelect}
        style={{
          margin: "0px",
        }}
      />
      {withFooter && (
        <div className={styles.calendarFooter}>
          <button
            className={styles.calendarButton}
            onClick={() => setIsOpened(false)}
          >
            Ok
          </button>
        </div>
      )}
    </div>
  )
}

export default GenericCalendarRange
