import React, { type ReactNode } from "react"
import styles from "./animal-field.module.css"
import CustomNumberInput from "@/components/custom-number-input/custom-number-input.tsx"

interface AnimalFieldProps {
  text: string | undefined
  value?: number | undefined
  children: ReactNode
}

export const AnimalField: React.FC<AnimalFieldProps> = ({
  text,
  value,
  children,
}) => {
  if (!text) return null

  return (
    <div className={styles.flexLine}>
      <div className={styles.flexIcon}>{children}</div>
      <div className={styles.lineText}>{text}</div>
      {value !== undefined && (
        <div className={styles.valueText}>
          <CustomNumberInput
            value={Number(value)}
            onChange={() => {}}
            isReadOnly={true}
          />
        </div>
      )}
    </div>
  )
}
