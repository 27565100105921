import React from "react"
import { NOTIFICATION_BLACK } from "@/utils/constants/colors-constants.ts"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const MandatoryNotificationIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_376_416)">
        <path
          d="M9.78137 6.5625C10.2128 6.5625 10.5626 6.21272 10.5626 5.78125C10.5626 5.34978 10.2128 5 9.78137 5C9.3499 5 9.00012 5.34978 9.00012 5.78125C9.00012 6.21272 9.3499 6.5625 9.78137 6.5625Z"
          fill={props.color || NOTIFICATION_BLACK}
        />
        <path
          d="M9.00012 9.34375C9.00012 8.91228 9.3499 8.5625 9.78137 8.5625C10.2128 8.5625 10.5626 8.91228 10.5626 9.34375V14.0312C10.5626 14.4627 10.2128 14.8125 9.78137 14.8125C9.3499 14.8125 9.00012 14.4627 9.00012 14.0312V9.34375Z"
          fill={props.color || NOTIFICATION_BLACK}
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10 19.1666C7.56889 19.1666 5.23731 18.2008 3.51823 16.4817C1.79915 14.7626 0.833374 12.4311 0.833374 9.99992C0.833374 7.56877 1.79915 5.23719 3.51823 3.51811C5.23731 1.79902 7.56889 0.833252 10 0.833252C12.4312 0.833252 14.7628 1.79902 16.4819 3.51811C18.2009 5.23719 19.1667 7.56877 19.1667 9.99992C19.1667 12.4311 18.2009 14.7626 16.4819 16.4817C14.7628 18.2008 12.4312 19.1666 10 19.1666ZM10 18.0208C7.87278 18.0208 5.83265 17.1757 4.32845 15.6715C2.82426 14.1673 1.97921 12.1272 1.97921 9.99992C1.97921 7.87266 2.82426 5.83253 4.32845 4.32833C5.83265 2.82413 7.87278 1.97909 10 1.97909C12.1273 1.97909 14.1674 2.82413 15.6716 4.32833C17.1758 5.83253 18.0209 7.87266 18.0209 9.99992C18.0209 12.1272 17.1758 14.1673 15.6716 15.6715C14.1674 17.1757 12.1273 18.0208 10 18.0208Z"
          fill={props.color || NOTIFICATION_BLACK}
        />
      </g>
      <defs>
        <clipPath id="clip0_376_416">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MandatoryNotificationIcon
