import React from "react"
import { useTranslation } from "react-i18next"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/components/shadcn/tabs/tabs.tsx"
import { type TransactionSummary } from "@/features/finance/api/finance-summary-api.ts"
import styles from "@/features/finance/components/financial-tabs/financial-tabs.module.css"
import MultipleElementsDoughnutChart from "@/features/finance/components/multiple-elements-doughnut-chart/multiple-elements-doughnut-chart.tsx"
import SummaryByCategory from "@/features/finance/components/summary-by-category/summary-by-category.tsx"
import { TRANSACTION_CATEGORY_TYPE } from "@/features/finance/types/finance-constants.ts"

interface FinancialTabsProps {
  data: TransactionSummary
}

const FinancialTabs: React.FC<FinancialTabsProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <Tabs
      className={styles.tabsWrapper}
      defaultValue={TRANSACTION_CATEGORY_TYPE.INCOMES}
    >
      <TabsList className={styles.tabsList}>
        <TabsTrigger
          className={styles.tabsTrigger}
          value={TRANSACTION_CATEGORY_TYPE.INCOMES}
        >
          <p className={styles.incomes}>{data.total_inflow} lei</p>
          <p className={styles.info}>{t(TRANSACTION_CATEGORY_TYPE.INCOMES)}</p>
        </TabsTrigger>
        <TabsTrigger
          className={styles.tabsTrigger}
          value={TRANSACTION_CATEGORY_TYPE.EXPENSES}
        >
          <p className={styles.expenses}>{data.total_outflow} lei</p>
          <p className={styles.info}>{t(TRANSACTION_CATEGORY_TYPE.EXPENSES)}</p>
        </TabsTrigger>
      </TabsList>
      <div className={styles.tabsContentWrapper}>
        <TabsContent value={TRANSACTION_CATEGORY_TYPE.EXPENSES}>
          <div className={styles.tabsContent}>
            <MultipleElementsDoughnutChart
              total={data.total_outflow}
              data={data.outflow_by_category}
            />
          </div>
          <SummaryByCategory
            data={data.outflow_by_category}
            total={data.total_outflow}
          />
        </TabsContent>
        <TabsContent value={TRANSACTION_CATEGORY_TYPE.INCOMES}>
          <div className={styles.tabsContent}>
            <MultipleElementsDoughnutChart
              total={data.total_inflow}
              data={data.inflow_by_category}
            />
          </div>
          <SummaryByCategory
            data={data.inflow_by_category}
            total={data.total_inflow}
          />
        </TabsContent>
      </div>
    </Tabs>
  )
}

export default FinancialTabs
