import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./drawer-select-mother.module.css"
import ParentAinIcon from "@/assets/icons/farm/parent-ain-icon.tsx"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import { type AnimalDraft } from "@/features/farm"
import { useGetAnimalsQuery } from "@/features/farm/api/animals-api.ts"
import AssociatedAnimalCard from "@/features/farm/components/associated-animals-list/associated-animal-card.tsx"
import DrawerSelectParentContent from "@/features/farm/components/drawer-select-mother/drawer-select-mother-content.tsx"
import {
  NOTIFICATION_BLUE,
  NOTIFICATION_RED,
} from "@/utils/constants/colors-constants.ts"

interface DrawerSelectParentProps {
  offspring: AnimalDraft
  onSaveClick: (parentId: string) => void
  isMother?: boolean
}

const DrawerSelectParent: React.FC<DrawerSelectParentProps> = ({
  onSaveClick,
  offspring,
  isMother,
}) => {
  const { t } = useTranslation()
  const { data: allAnimals = [], isLoading } = useGetAnimalsQuery()

  return (
    <Drawer>
      <DrawerTrigger asChild>
        <div className={styles.wrapper}>
          <div className={styles.iconAndText}>
            <div>
              <ParentAinIcon
                color={isMother ? NOTIFICATION_RED : NOTIFICATION_BLUE}
              />
            </div>
            <div className={styles.text}>
              {isMother ? t("selectAnimalMother") : t("selectAnimalFather")}
            </div>
          </div>
          {isMother && offspring.mother && offspring.mother !== "" && (
            <AssociatedAnimalCard
              animal={offspring.mother}
              onDelete={() => onSaveClick("")}
            />
          )}
          {!isMother && offspring.father && offspring.father !== "" && (
            <AssociatedAnimalCard
              animal={offspring.father}
              onDelete={() => onSaveClick("")}
            />
          )}
        </div>
      </DrawerTrigger>
      <DrawerContent>
        {!isLoading ? (
          <DrawerSelectParentContent
            offspring={offspring}
            onSaveClick={onSaveClick}
            allAnimals={allAnimals}
            isMother={isMother}
          />
        ) : (
          <LoadingAnimation />
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default DrawerSelectParent
