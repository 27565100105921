import React from "react"
import WidgetCard from "@/features/home/components/widget-card/widget-card.tsx"
import NotificationCard from "@/features/notifications/components/notification-card/notification-card.tsx"
import { type Notification } from "@/features/notifications/types/notifications.ts"
import { NOTIFICATIONS_PATH } from "@/utils/constants/routes"

interface NotificationsWidgetProps {
  notifications: Notification[]
  maxNotificationsToDisplay?: number
}

const NotificationsWidget: React.FC<NotificationsWidgetProps> = ({
  notifications,
  maxNotificationsToDisplay = 3,
}) => {
  const notificationsToDisplay = notifications.slice(
    0,
    maxNotificationsToDisplay,
  )
  return (
    <WidgetCard
      title={"notifications"}
      navigateTo={NOTIFICATIONS_PATH}
      customStyles={{
        height: "fit-content",
        marginInline: 0,
        marginBlock: 0,
        width: "100%",
      }}
    >
      {notificationsToDisplay.map((notification, index) => (
        <NotificationCard key={index} notification={notification} />
      ))}
    </WidgetCard>
  )
}

export default NotificationsWidget
