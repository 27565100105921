import React from "react"
import { useTranslation } from "react-i18next"
import ChooseSomethingIcon from "@/assets/icons/finance/choose-something-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import styles from "@/features/finance/components/finance-category-card/finance-category-card.module.css"
import { type TransactionCategory } from "@/features/finance/types/transaction.ts"
import useLongPress from "@/hooks/use-long-press.ts"

interface FinanceCategoryCardProps {
  category: TransactionCategory
  onLongClick: () => void
  onClick?: () => void
  isSelected?: boolean
}

const FinanceCategoryCard: React.FC<FinanceCategoryCardProps> = ({
  category,
  onLongClick,
  isSelected,
  onClick,
}) => {
  const { t } = useTranslation()
  const longPressHandlers = useLongPress(onLongClick)

  return (
    <div
      className={styles.financeCategoryCardWrapper}
      {...longPressHandlers}
      data-selected={isSelected}
      onClick={onClick}
    >
      {category.picture ? (
        <GenericPicture
          src={category.picture}
          alt={"categoryPicture"}
          size={50}
          borderRadius={category.farm ? 8 : 0}
          contain={!category.farm}
        />
      ) : (
        <ChooseSomethingIcon size={50} />
      )}
      <p className={styles.categoryName}>{t(category.name)}</p>
    </div>
  )
}

export default FinanceCategoryCard
