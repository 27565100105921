export function roundPercentages(values: number[]): number[] {
  const total = values.reduce((sum, value) => sum + value, 0)
  const rawPercentages = values.map((value) => (value / total) * 100)

  const roundedPercentages = rawPercentages.map(
    (value) => Math.round(value * 10) / 10,
  )

  const difference =
    100 - roundedPercentages.reduce((sum, value) => sum + value, 0)

  const indices = rawPercentages
    .map((value, index) => ({ value: value % 1, index }))
    .sort((a, b) => b.value - a.value)
    .map((item) => item.index)

  for (let i = 0; i < Math.abs(Math.round(difference * 10)); i++) {
    const index = indices[i % indices.length]
    roundedPercentages[index] += Math.sign(difference) * 0.1
  }

  return roundedPercentages.map((value) => Math.round(value * 10) / 10)
}
