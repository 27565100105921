import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./navigation-buttons.module.css"
import AddCalendarIcon from "@/assets/icons/gestational-calendar/add-calendar-icon.tsx"
import ShortBottomActionWrapper from "@/components/short-bottom-drawer/short-bottom-action-wrapper.tsx"
import { WHITE } from "@/utils/constants/colors-constants.ts"

interface NewCalendarButtonProps {
  navigationString: string
}

const NewCalendarButton: React.FC<NewCalendarButtonProps> = ({
  navigationString,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={styles.buttonBottomWrapper}>
      <ShortBottomActionWrapper>
        <button
          className={styles.button}
          onClick={() => navigate(navigationString)}
        >
          <AddCalendarIcon color={WHITE} size={20} />
          {t("createNewCalendar")}
        </button>
      </ShortBottomActionWrapper>
    </div>
  )
}

export default NewCalendarButton
