import React from "react"
import type GeneralSvgProps from "@/utils/types/sgv/general-svg-props.ts"

const DangerNotificationIcon: React.FC<GeneralSvgProps> = (props) => {
  return (
    <svg
      width={props.size || 20}
      height={props.size || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M13.5215 18.3333H6.47854C5.45529 18.3333 4.63246 18.3333 3.98796 18.2773C3.33804 18.2209 2.74421 18.1003 2.24904 17.7716C1.50571 17.2784 0.996458 16.5067 0.859875 15.6294C0.767458 15.036 0.927958 14.4557 1.16796 13.8727C1.40529 13.2961 1.77512 12.5942 2.23088 11.7295L5.75304 5.04517C6.30362 4.00034 6.74463 3.16342 7.14621 2.54825C7.54813 1.93259 7.99121 1.40684 8.60088 1.1325C9.48738 0.733503 10.5126 0.733503 11.3991 1.1325C12.0088 1.40684 12.4518 1.93259 12.8538 2.54825C13.2554 3.16342 13.6964 4.00033 14.247 5.04525L17.7692 11.7295C18.2249 12.5943 18.5947 13.2962 18.8321 13.8727C19.072 14.4557 19.2325 15.036 19.1401 15.6294C19.0035 16.5067 18.4942 17.2784 17.751 17.7716C17.2558 18.1003 16.6619 18.2209 16.012 18.2773C15.3676 18.3333 14.5447 18.3333 13.5215 18.3333ZM15.8678 16.6169C16.4345 16.5677 16.6878 16.4769 16.8294 16.383C17.1982 16.1382 17.4315 15.7696 17.4933 15.373C17.5145 15.237 17.4954 15.0039 17.2909 14.5071C17.0872 14.0124 16.7552 13.3804 16.2745 12.4681L12.7932 5.8615C12.2171 4.76834 11.8119 4.00125 11.4582 3.45934C11.0998 2.91034 10.8743 2.724 10.7151 2.65234C10.2636 2.44909 9.73637 2.44909 9.28479 2.65234C9.12563 2.724 8.90021 2.91034 8.54179 3.45934C8.18804 4.00125 7.78288 4.76834 7.20679 5.8615L3.72554 12.4681C3.24479 13.3804 2.91279 14.0124 2.70912 14.5071C2.50462 15.0039 2.48546 15.237 2.50671 15.373C2.56846 15.7696 2.80179 16.1382 3.17062 16.383C3.31221 16.4769 3.56546 16.5677 4.13212 16.6169C4.69587 16.6658 5.44596 16.6666 6.51871 16.6666H13.4813C14.554 16.6666 15.3041 16.6658 15.8678 16.6169Z"
        fill="#FF4949"
      />
      <path
        d="M9.21875 6.875C9.21875 6.44353 9.56853 6.09375 10 6.09375C10.4315 6.09375 10.7812 6.44353 10.7812 6.875V11.5625C10.7812 11.994 10.4315 12.3438 10 12.3438C9.56853 12.3438 9.21875 11.994 9.21875 11.5625V6.875Z"
        fill="#FF4949"
      />
      <path
        d="M10 15.5728C10.4315 15.5728 10.7812 15.223 10.7812 14.7915C10.7812 14.36 10.4315 14.0103 10 14.0103C9.56853 14.0103 9.21875 14.36 9.21875 14.7915C9.21875 15.223 9.56853 15.5728 10 15.5728Z"
        fill="#FF4949"
      />
    </svg>
  )
}

export default DangerNotificationIcon
