import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { LoadingAnimation } from "@/components/loading-animation/loading-animation.tsx"
import PageContentWrapperLarge from "@/components/page-content-wrappers/page-content-wrapper-large.tsx"
import EditorTopBar from "@/components/top-bars/editor-top-bar/editor-top-bar.tsx"
import useModal from "@/features/modals/hooks/use-modal.ts"
import { MODALS } from "@/features/modals/types/modals.tsx"
import { usePostTaskMutation } from "@/features/tasks/api/tasks-api.ts"
import TaskEditor from "@/features/tasks/components/task-editor/task-editor.tsx"
import useInitializeTaskDraft from "@/features/tasks/hooks/use-initialize-task-draft.ts"
import { validateTask } from "@/features/tasks/types/task-schema.tsx"
import useNavigationFallback from "@/hooks/use-navigation-fallback.ts"
import { deleteTaskDraft } from "@/redux/slices/task-draft-slice.ts"
import { DELETE_ENTRY_TIMEOUT } from "@/utils/constants/time-constants.ts"
import { NEW_ENTITY } from "@/utils/types/misc-types.ts"

export const NewTaskView = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { taskDraft, hasChanges } = useInitializeTaskDraft()
  const [postTask] = usePostTaskMutation()
  const { handleBackNavigation } = useNavigationFallback()
  const { openModal } = useModal()

  const deleteDraft = useCallback(() => {
    setTimeout(() => {
      dispatch(deleteTaskDraft(NEW_ENTITY))
    }, DELETE_ENTRY_TIMEOUT)
  }, [dispatch])

  const handlePostTask = async () => {
    if (await validateTask(taskDraft)) {
      toast
        .promise(postTask(taskDraft).unwrap(), {
          pending: t("creatingTask"),
          success: t("taskCreated"),
          error: t("errorCreatingTask"),
        })
        .then(() => {
          handleBackNavigation()
          deleteDraft()
        })
    }
  }

  useEffect(() => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("continueWhereYouLeftOff"),
        content: t("youHaveUnsavedChanges"),
        onBackText: t("discard"),
        onActionText: t("continue"),
        onBackClick: () => {
          deleteDraft()
        },
      })
    }
    // Run on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDraft, openModal, t])

  const handleBackClick = () => {
    if (hasChanges) {
      openModal(MODALS.CONFIRM_NAVIGATION_MODAL, {
        title: t("unsavedChanges"),
        content: t("youWillLoseChanges"),
        onBackText: t("leave"),
        onActionText: t("stay"),
        onBackClick: () => {
          handleBackNavigation()
          deleteDraft()
        },
      })
    } else {
      handleBackNavigation()
      deleteDraft()
    }
  }

  return (
    <>
      <EditorTopBar
        text={"newTask"}
        onSave={handlePostTask}
        onBackClick={handleBackClick}
        isModified={hasChanges}
      />
      {!taskDraft ? (
        <LoadingAnimation />
      ) : (
        <PageContentWrapperLarge hasPadding={false}>
          <TaskEditor taskId={NEW_ENTITY} draftTask={taskDraft} />
        </PageContentWrapperLarge>
      )}
    </>
  )
}
