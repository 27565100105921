import React from "react"
import styles from "./task-status-group.module.css"
import { type Task } from "@/features/tasks"
import TaskTimeGroup from "@/features/tasks/components/task-time-group/task-time-group.tsx"

interface TaskListProps {
  tasks: Task[]
  title: string
  backgroundColor: string
}

const TaskStatusGroup: React.FC<TaskListProps> = ({
  tasks,
  title,
  backgroundColor,
}) => {
  return (
    <div className={styles["task-wrapper"]}>
      <div className={styles["title-pill-flex"]}>
        <div className={styles["sub-title"]}>{title}</div>
        <div
          className={styles["pill"]}
          style={{ backgroundColor: backgroundColor }}
        >
          {tasks.length}
        </div>
      </div>
      <TaskTimeGroup tasks={tasks} />
    </div>
  )
}

export default TaskStatusGroup
