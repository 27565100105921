import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import GenericTypeSelectTrigger from "@/components/drawer-triggers/generic-type-select-trigger.tsx"
import AddItemGenericDrawerSelectionRow from "@/components/generic-drawer-selection-row/add-item-generic-drawer-selection-row.tsx"
import {
  Drawer,
  DrawerContent,
  DrawerTrigger,
} from "@/components/shadcn/drawer/drawer.tsx"
import AddMedicationTypeForm from "@/features/animal-events/components/add-medication-type-form/add-medication-type-form.tsx"
import GenericTypeDrawerContent from "@/features/animal-events/components/events-drawer-content/generic-type-drawer-content.tsx"
import { type Medication } from "@/features/animal-events/types/animal-events.ts"

interface GenericTypeDrawerProps {
  selectedValue: string | undefined
  fallbackText: string
  onEventSelect: (eventType: string | undefined) => void
  medications: Record<string, Medication>
}

const MedicationTypeDrawer: React.FC<GenericTypeDrawerProps> = ({
  selectedValue,
  onEventSelect,
  medications,
  fallbackText,
}) => {
  const { t } = useTranslation()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isAddMedicationTypeViewOpen, setIsAddMedicationTypeViewOpen] =
    useState<{
      isOpen: boolean
      eventTypeId: string | null
    }>({
      isOpen: false,
      eventTypeId: null,
    })

  const currentMedicationType = selectedValue
    ? medications[selectedValue]
    : undefined

  const handleEventTypeSelect = (eventType: string | undefined) => {
    onEventSelect(eventType)
    setIsDrawerOpen(false)
  }

  const handleBackClick = () => {
    setIsAddMedicationTypeViewOpen({
      isOpen: false,
      eventTypeId: null,
    })
  }

  const handleOnViewChange = (entityId: string) => {
    setIsAddMedicationTypeViewOpen({
      isOpen: true,
      eventTypeId: entityId,
    })
  }

  return (
    <Drawer open={isDrawerOpen} onOpenChange={setIsDrawerOpen}>
      <DrawerTrigger asChild>
        <GenericTypeSelectTrigger
          currentValue={currentMedicationType}
          fallbackText={fallbackText}
        />
      </DrawerTrigger>
      <DrawerContent
        style={{
          height: "85%",
          width: "100%",
        }}
      >
        {isAddMedicationTypeViewOpen.isOpen ? (
          <AddMedicationTypeForm
            onBackClick={handleBackClick}
            medicationId={isAddMedicationTypeViewOpen.eventTypeId}
          />
        ) : (
          <>
            <GenericTypeDrawerContent
              selectedValue={selectedValue}
              onValueSelect={handleEventTypeSelect}
              values={medications}
              placeholderText={t(fallbackText)}
              onViewChange={handleOnViewChange}
            />
            <AddItemGenericDrawerSelectionRow
              text={t("addMedicationType")}
              onClick={() =>
                setIsAddMedicationTypeViewOpen({
                  isOpen: true,
                  eventTypeId: null,
                })
              }
            />
          </>
        )}
      </DrawerContent>
    </Drawer>
  )
}

export default MedicationTypeDrawer
