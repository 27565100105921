import { Route } from "react-router-dom"
import AllCalendarsPage from "@/features/gestational-calendar/routes/all-calendars-page/all-calendars-page.tsx"
import CalendarView from "@/features/gestational-calendar/routes/calendar-view/calendar-view.tsx"
import NewCalendarView from "@/features/gestational-calendar/routes/new-calendar-view/new-calendar-view.tsx"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import {
  ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH,
  GESTATIONAL_CALCULATOR_SCREEN_PATH,
  NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

export const GestationalCalculatorRoutes = (
  <Route element={<NoMenuLayout />}>
    <Route
      key={ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH}
      path={ALL_GESTATIONAL_CALCULATOR_SCREEN_PATH}
      element={<AllCalendarsPage />}
    />
    <Route
      key={NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH}
      path={`${NEW_GESTATIONAL_CALCULATOR_SCREEN_PATH}/:gender?/:animalId?`}
      element={<NewCalendarView />}
    />
    <Route
      key={GESTATIONAL_CALCULATOR_SCREEN_PATH}
      path={`${GESTATIONAL_CALCULATOR_SCREEN_PATH}/:calendarId`}
      element={<CalendarView />}
    />
  </Route>
)
