import { useTranslation } from "react-i18next"
import styles from "../settings-routes.module.css"
import ConfidentialityIcon from "@/assets/icons/settings/confidentiality-icon.tsx"
import SettingsTopBar from "@/components/top-bars/settings-top-bar/settings-top-bar.tsx"

const SettingsConfidentiality = () => {
  const { t } = useTranslation()

  return (
    <>
      <SettingsTopBar title={t("confidentiality")} />
      <div className={styles.settingsContent}>
        <div className={styles.imageCircleCenter}>
          <ConfidentialityIcon />
        </div>

        <div className={styles.card}>
          <div className={styles.cardTitleBrown}>{t("confidentiality")}</div>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius
            consequat dictum iaculis ultricies eros. Nulla id nulla porta lacus,
            morbi lorem.
          </div>

          <div>
            <strong>Nulla id nulla porta lacus, morbi lorem.</strong>
          </div>
          <div>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Varius
            consequat dictum:
          </div>

          <div>
            Et varius arcu, ipsum integer porttitor. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Varius consequat dictum. Fringilla
            viverra id id sit viverra nec, accumsan. Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Varius consequat dictum Lorem ipsum
            dolor sit amet, consectetur adipiscing elit. Varius consequat dictum
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsConfidentiality
