import AnimalStatusDryIcon from "@/assets/icons/animal-statuses-icons/animal-status-dry-icon.tsx"
import AnimalStatusHealthyIcon from "@/assets/icons/animal-statuses-icons/animal-status-healthy-icon.tsx"
import AnimalStatusHeatIcon from "@/assets/icons/animal-statuses-icons/animal-status-heat-icon.tsx"
import AnimalStatusIllIcon from "@/assets/icons/animal-statuses-icons/animal-status-ill-icon.tsx"
import AnimalStatusLacatingIcon from "@/assets/icons/animal-statuses-icons/animal-status-lacating-icon.tsx"
import AnimalStatusPregnantIcon from "@/assets/icons/animal-statuses-icons/animal-status-pregnant-icon.tsx"
import FemaleCardIcon from "@/assets/icons/farm/female-card-icon.tsx"
import MaleCardIcon from "@/assets/icons/farm/male-card-icon.tsx"

export enum REGISTRY_ENTRY_ENUM {
  BIRTH = "Birth",
  PURCHASE = "Purchase",
}

export enum PRODUCTION_ENUM {
  DIARY = "Dairy",
  MEAT = "Meat",
}

enum GENDERS {
  ALL_GENDERS,
  MALE,
  FEMALE,
}

export const ANIMAL_GENDERS = {
  ALL_GENDERS: {
    label: "ALL_GENDERS",
    value: GENDERS.ALL_GENDERS,
  },
  MALE: {
    label: "Male",
    value: GENDERS.MALE,
  },
  FEMALE: {
    label: "Female",
    value: GENDERS.FEMALE,
  },
}

export const genderOptions = Object.values(ANIMAL_GENDERS).map(({ label }) => ({
  label: label,
  value: label,
}))

export function getGenderIcon(gender: string) {
  switch (gender) {
    case "Female":
      return <FemaleCardIcon />
    case "Male":
      return <MaleCardIcon />
    default:
      return null
  }
}

export enum ANIMAL_STATUS_ENUM {
  ALL_STATUSES = "ALL_STATUSES",
  HEAT = "HEAT",
  GESTATING = "GESTATING",
  LACTATING = "LACTATING",
  HEALTHY = "HEALTHY",
  DRY = "DRY",
  UNDER_TREATMENT = "UNDER_TREATMENT",
}

export const animalStatusOptions = Object.entries(ANIMAL_STATUS_ENUM).map(
  ([key, value]) => ({
    label: key,
    value: value,
  }),
)

export const ANIMAL_STATUSES = {
  [ANIMAL_STATUS_ENUM.ALL_STATUSES]: null,
  [ANIMAL_STATUS_ENUM.HEAT]: <AnimalStatusHeatIcon />,
  [ANIMAL_STATUS_ENUM.GESTATING]: <AnimalStatusPregnantIcon />,
  [ANIMAL_STATUS_ENUM.UNDER_TREATMENT]: <AnimalStatusIllIcon />,
  [ANIMAL_STATUS_ENUM.LACTATING]: <AnimalStatusLacatingIcon />,
  [ANIMAL_STATUS_ENUM.HEALTHY]: <AnimalStatusHealthyIcon />,
  [ANIMAL_STATUS_ENUM.DRY]: <AnimalStatusDryIcon />,
}
