import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "./gestation-calendar-details.module.css"
import AddEventIcon from "@/assets/icons/misc/add-event-icon.tsx"
import cowWithCalf from "@/assets/images/cow-with-calf.jpeg"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import { useGetAllAnimalEventTypesQuery } from "@/features/animal-events/api/event-types-api.ts"
import { useGetAnimalByIdQuery } from "@/features/farm/api/animals-api.ts"
import AnimalCard from "@/features/farm/components/animal-card/animal-card.tsx"
import CalendarActivityTimeline from "@/features/gestational-calendar/components/calendar-activity-timeline/calendar-activity-timeline.tsx"
import GestationCalendarActionButton from "@/features/gestational-calendar/components/gestation-calendar-action-button/gestation-calendar-action-button.tsx"
import { type GestationCalendar } from "@/features/gestational-calendar/types/calendar-types.ts"
import {
  GESTATION_CALENDAR_CONSTS,
  FP_QUERY_PARAMS,
} from "@/features/gestational-calendar/utils/gestation-calendar-consts.ts"
import {
  checkIfTodayOrIfInInterval,
  checkIsCalendarModified,
  formatGestationalCalendarInterval,
  getGestationCalendarCalvingActivity,
} from "@/features/gestational-calendar/utils/gestational-calendar-utils.ts"
import {
  ANIMAL_PROFILE_PATH,
  NEW_ANIMAL_EVENT_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

interface GestationCalendarDetailsProps {
  gestationalCalendar: GestationCalendar
}

const GestationCalendarDetails: React.FC<GestationCalendarDetailsProps> = ({
  gestationalCalendar,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { data: animal } = useGetAnimalByIdQuery(gestationalCalendar.animal)
  const { data: eventTypes } = useGetAllAnimalEventTypesQuery()
  const birthEvent =
    eventTypes &&
    Object.values(eventTypes).find((event) => {
      return event.name === GESTATION_CALENDAR_CONSTS.Birth
    })
  const navigateToAnimal = () => {
    navigate(ANIMAL_PROFILE_PATH + `/${animal?.id}`)
  }
  const isCalendarModified = checkIsCalendarModified(gestationalCalendar)

  const calvingCalendarActivity =
    getGestationCalendarCalvingActivity(gestationalCalendar)

  const handleAddBirthEvent = () => {
    const searchParams = new URLSearchParams()

    searchParams.append(FP_QUERY_PARAMS.ANIMAL_ID, gestationalCalendar.animal)
    searchParams.append(
      FP_QUERY_PARAMS.GESTATION_CALENDAR_ID,
      gestationalCalendar.id,
    )

    if (calvingCalendarActivity?.id) {
      searchParams.append(
        FP_QUERY_PARAMS.GESTATION_CALENDAR_ENTRY_ID,
        calvingCalendarActivity.id,
      )
    }
    if (birthEvent?.id) {
      searchParams.append(FP_QUERY_PARAMS.EVENT_TYPE_ID, birthEvent.id)
    }

    navigate(`${NEW_ANIMAL_EVENT_SCREEN_PATH}?${searchParams.toString()}`)
  }

  return (
    <>
      <OverflowMenuItem onClick={navigateToAnimal} marginInline={false}>
        {animal && <AnimalCard animal={animal} />}
      </OverflowMenuItem>
      <OverflowMenuItem marginInline={false}>
        <div className={styles.inseminationDateDetails}>
          <div>{t("inseminationDate")}</div>
          <div className={styles.inseminationDate}>
            {format(
              new Date(gestationalCalendar.insemination_date),
              "dd.MM.yyyy",
            )}
          </div>
        </div>
      </OverflowMenuItem>
      <OverflowMenuItem marginInline={false}>
        <div className={styles.calvingDateWrapper}>
          <div className={styles.calvingDateDetails}>
            <div>
              {isCalendarModified
                ? t("calvingDate")
                : t("possibleCalvingInterval")}
            </div>
            <div className={styles.calvingDate}>
              {calvingCalendarActivity && !isCalendarModified
                ? formatGestationalCalendarInterval(calvingCalendarActivity)
                : gestationalCalendar.calving_date &&
                  format(gestationalCalendar.calving_date, "dd.MM.yyyy")}
            </div>
          </div>
          <img src={cowWithCalf} alt="cowWithCalf" className={styles.calfImg} />
        </div>
        {isCalendarModified && (
          <GestationCalendarActionButton
            text={t("calvingConfirmed")}
            isDone={true}
          />
        )}
        {!isCalendarModified &&
          calvingCalendarActivity &&
          checkIfTodayOrIfInInterval(calvingCalendarActivity) && (
            <GestationCalendarActionButton
              text={t("confirmCalving")}
              onClick={handleAddBirthEvent}
              icon={<AddEventIcon />}
            />
          )}
      </OverflowMenuItem>
      <CalendarActivityTimeline gestationalCalendar={gestationalCalendar} />
    </>
  )
}

export default GestationCalendarDetails
