import { type MouseEvent } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import styles from "../../../tasks/components/associated-list/associated-list.module.css"
import CloseCrossIcon from "@/assets/icons/misc/close-cross-icon.tsx"
import { getSpeciesIcon } from "@/features/farm"
import { useGetAnimalByIdQuery } from "@/features/farm/api/animals-api.ts"
import { useGetSpeciesQuery } from "@/features/farm/api/species-api.ts"
import { ANIMAL_PROFILE_PATH } from "@/utils/constants/routes.ts"

interface AssociatedAnimalRowProps {
  animal: string
  onDelete?: (animalId: string) => void
}

const AssociatedAnimalCard: React.FC<AssociatedAnimalRowProps> = ({
  animal,
  onDelete,
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { data: workingAnimal } = useGetAnimalByIdQuery(animal)
  const { data: species_breeds } = useGetSpeciesQuery()
  const animalSpecies = workingAnimal
    ? species_breeds?.[workingAnimal.species]
    : undefined

  const handleAnimalDeletion = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation()
    onDelete && onDelete(animal)
  }

  const handleAnimalDetailsNavigation = () => {
    navigate(`${ANIMAL_PROFILE_PATH}/${animal}`)
  }

  return (
    <div
      onClick={handleAnimalDetailsNavigation}
      className={styles["associated-item-row"]}
    >
      <div className={styles["content-wrapper"]}>
        <div className={styles["animal-icon"]}>
          {animalSpecies && getSpeciesIcon(animalSpecies.name)}
        </div>
        <div className={styles["animal-info"]}>
          <p className={styles["animal-name"]}>{workingAnimal?.name}</p>
          <span className={styles["animal-name"]}>
            <p className={styles["animal-id"]}>{t("ain")}:</p>
            <p>{workingAnimal?.ain}</p>
          </span>
        </div>
      </div>
      {onDelete && (
        <div className={styles["delete-icon"]} onClick={handleAnimalDeletion}>
          <CloseCrossIcon />
        </div>
      )}
    </div>
  )
}

export default AssociatedAnimalCard
