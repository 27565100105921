export type Option<T = string> = {
  label: string
  value: T
  count?: number
}

/**
 * Const used to describe the general id used for a new entity
 */
export const NEW_ENTITY = "new"
