import React from "react"
import styles from "./horizontal-scroll-component-wrapper-flexible.module.css"
import WidgetHeader from "@/features/home/components/widget-header/widget-header.tsx"

export interface HorizontalScrollComponentWrapperProps {
  children: React.ReactNode
  title: string
  isFlexible?: boolean
  navigateTo?: string
  addItemGoTo?: () => void
}

const HorizontalScrollComponentWrapperFlexible: React.FC<
  HorizontalScrollComponentWrapperProps
> = ({ children, title, navigateTo, isFlexible = false, addItemGoTo }) => {
  return (
    <div className={styles.wrapperCard} data-flexible={isFlexible}>
      <WidgetHeader
        title={title}
        navigateTo={navigateTo}
        addItemGoTo={addItemGoTo}
      />
      <div className={styles.overflow}>
        <div className={styles.flex}>{children}</div>
      </div>
    </div>
  )
}

export default HorizontalScrollComponentWrapperFlexible
