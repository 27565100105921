import React from "react"
import { useTranslation } from "react-i18next"
import styles from "./animal-icon-card.module.css"
import CowIconSmall from "@/assets/icons/farm/cow-icon-small.tsx"
import { getSpeciesIcon, type Species } from "@/features/farm"
import { BROWN } from "@/utils/constants/colors-constants.ts"

interface AnimalCategoryCardProps {
  category: Species
  onClick: () => void
  isSelected: boolean
}

const getFillColor = (isSelected: boolean) => (isSelected ? BROWN : "")

function getAnimalIcon(species: Species, isSelected: boolean) {
  const species_icon = getSpeciesIcon(species.name)

  return React.cloneElement(species_icon ? species_icon : <CowIconSmall />, {
    fill: getFillColor(isSelected),
  })
}

const AnimalCategoryCard: React.FC<AnimalCategoryCardProps> = ({
  category,
  onClick,
  isSelected,
}) => {
  const { t } = useTranslation()

  return (
    <div
      role={"button"}
      className={styles["category-icon-card"]}
      style={{
        borderColor: getFillColor(isSelected),
        color: getFillColor(isSelected),
      }}
      onClick={onClick}
    >
      {getAnimalIcon(category, isSelected)}
      <div className={styles["title"]}>{t(category.name)}</div>
    </div>
  )
}

export default AnimalCategoryCard
