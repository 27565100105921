import React from "react"
import { useParams } from "react-router-dom"
import GenericDatePicker from "@/components/generic-date-picker/generic-date-picker.tsx"
import OverflowMenuItem from "@/components/task-card/overflow-menu-item.tsx"
import AssociatedAnimalsList from "@/features/farm/components/associated-animals-list/associated-animals-list.tsx"
import DrawerAnimals from "@/features/farm/components/drawer-animals/drawer-animals.tsx"
import GenerateCalendarButton from "@/features/gestational-calendar/components/navigation-buttons/generate-calendar-button.tsx"
import { type GestationCalendar } from "@/features/gestational-calendar/types/calendar-types.ts"
import DrawerAnimalsAssocTrigger from "@/features/tasks/components/drawer-animals-assoc-trigger/drawer-animals-assoc-trigger.tsx"
import { useAppDispatch } from "@/redux/hooks.ts"
import {
  setGestationalCalendarDraftAnimal,
  setGestationalCalendarDraftInseminationDate,
} from "@/redux/slices/gestational-calendars-draft-slice.ts"

interface GenerateCalendarFormProps {
  createGestationCalendar: () => void
  gestationCalendarDraft: GestationCalendar
}

const GenerateCalendarForm: React.FC<GenerateCalendarFormProps> = ({
  createGestationCalendar,
  gestationCalendarDraft,
}) => {
  const dispatch = useAppDispatch()
  const { animalId } = useParams()

  const handleDateChange = (insemination_date: string) => {
    dispatch(setGestationalCalendarDraftInseminationDate(insemination_date))
  }

  const handleAnimalsChange = (animalId: string[]) => {
    dispatch(setGestationalCalendarDraftAnimal(animalId[0]))
  }

  const selectedAnimal = gestationCalendarDraft.animal
    ? [gestationCalendarDraft.animal]
    : []

  const handleCreateCalendar = () => {
    createGestationCalendar()
  }

  return (
    <>
      <div className={"mb-4"} />
      <OverflowMenuItem isDisabled={!!animalId}>
        <DrawerAnimals
          selectionType="single"
          currentValue={selectedAnimal}
          onSaveClick={handleAnimalsChange}
          TriggerComponent={DrawerAnimalsAssocTrigger}
        />
        {gestationCalendarDraft.animal && (
          <AssociatedAnimalsList
            animals={Array.of(gestationCalendarDraft.animal)}
            onAnimalsChange={handleAnimalsChange}
          />
        )}
      </OverflowMenuItem>
      <GenericDatePicker
        onChange={handleDateChange}
        value={gestationCalendarDraft.insemination_date}
      />
      {gestationCalendarDraft.animal &&
        gestationCalendarDraft.insemination_date && (
          <GenerateCalendarButton onClick={handleCreateCalendar} />
        )}
    </>
  )
}

export default GenerateCalendarForm
