import { type AnimalEventType } from "@/features/animal-events/types/animal-events.ts"
import { apiSlice, TagType } from "@/features/api/apiSlice.ts"

const EVENT_TYPES_BASE_URL = "/event-types/"

export const eventTypesApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllAnimalEventTypes: builder.query<
      Record<string, AnimalEventType>,
      void
    >({
      query: () => ({
        url: EVENT_TYPES_BASE_URL,
      }),
      transformResponse: (response: AnimalEventType[]) => {
        return response.reduce<Record<string, AnimalEventType>>(
          (acc, eventType) => {
            return { ...acc, [eventType.id]: eventType }
          },
          {},
        )
      },
      providesTags: () => [{ type: TagType.EventTypes, id: "LIST" }],
    }),
    createAnimalEventType: builder.mutation<
      AnimalEventType,
      Partial<AnimalEventType>
    >({
      query: (body) => ({
        url: EVENT_TYPES_BASE_URL,
        method: "POST",
        body,
      }),
      invalidatesTags: [{ type: TagType.EventTypes, id: "LIST" }],
    }),
    updateAnimalEventType: builder.mutation<
      AnimalEventType,
      Partial<AnimalEventType>
    >({
      query: (body) => ({
        url: `${EVENT_TYPES_BASE_URL}${body.id}/`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: [{ type: TagType.EventTypes, id: "LIST" }],
    }),
    deleteAnimalEventType: builder.mutation<void, string>({
      query: (id) => ({
        url: `${EVENT_TYPES_BASE_URL}${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: TagType.EventTypes, id: "LIST" }],
    }),
  }),
})

export const {
  useGetAllAnimalEventTypesQuery,
  useCreateAnimalEventTypeMutation,
  useUpdateAnimalEventTypeMutation,
  useDeleteAnimalEventTypeMutation,
} = eventTypesApi
