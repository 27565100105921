export enum InputType {
  NUMBER = "number",
  TEXT = "text",
  DATE = "date",
  TIME = "time",
  RADIO = "radio",
  CHECKBOX = "checkbox",
  PASSWORD = "password",
  EMAIL = "email",
  FILE = "file",
}
