import { Route } from "react-router-dom"
import { TaskView } from "@/features/tasks"
import { NewTaskView } from "@/features/tasks/routes/new-task-view/new-task-view.tsx"
import TaskLayout from "@/features/tasks/routes/task-layout/task-layout.tsx"
import NoMenuLayout from "@/layouts/no-menu-layout/no-menu-layout.tsx"
import { StandardLayout } from "@/layouts/standard-layout/standard-layout.tsx"
import {
  NEW_TASK_SCREEN_PATH,
  TASKS_SCREEN_PATH,
} from "@/utils/constants/routes.ts"

export const TasksRoutes = (
  <>
    <Route element={<StandardLayout />}>
      <Route
        key={TASKS_SCREEN_PATH}
        path={TASKS_SCREEN_PATH}
        element={<TaskLayout />}
      />
    </Route>
    <Route element={<NoMenuLayout />}>
      <Route
        key={`${NEW_TASK_SCREEN_PATH}/:animalId`}
        path={`${NEW_TASK_SCREEN_PATH}/:animalId`}
        element={<NewTaskView />}
      />
      <Route
        key={NEW_TASK_SCREEN_PATH}
        path={NEW_TASK_SCREEN_PATH}
        element={<NewTaskView />}
      />
      <Route
        key={`${TASKS_SCREEN_PATH}/:taskId`}
        path={`${TASKS_SCREEN_PATH}/:taskId`}
        element={<TaskView />}
      />
    </Route>
  </>
)
