import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import ChooseSomethingIcon from "@/assets/icons/finance/choose-something-icon.tsx"
import GenericPicture from "@/components/generic-picture/generic-picture.tsx"
import TransactionCardSkeleton from "@/features/finance/components/transaction-card-skeleton/transaction-card-skeleton.tsx"
import useGetTransactionCategoryById from "@/features/finance/hooks/use-get-transaction-category-by-id.ts"
import { type Transaction } from "@/features/finance/types/transaction.ts"
import { FINANCE_TRANSACTIONS_SCREEN_PATH } from "@/utils/constants/routes.ts"

interface TransactionCardProps {
  transaction: Transaction
}

const TransactionCard: React.FC<TransactionCardProps> = ({ transaction }) => {
  const { t } = useTranslation()
  const transactionCategory = useGetTransactionCategoryById(
    transaction.category,
  )
  const navigate = useNavigate()

  const handleNavigate = () => {
    navigate(FINANCE_TRANSACTIONS_SCREEN_PATH + `/${transaction.id}`)
  }

  return (
    <TransactionCardSkeleton
      onClick={handleNavigate}
      icon={
        transactionCategory?.picture ? (
          <GenericPicture
            src={transactionCategory.picture}
            alt={""}
            size={40}
            borderRadius={0}
            contain={!transactionCategory.farm}
          />
        ) : (
          <ChooseSomethingIcon size={40} />
        )
      }
      topLeftText={transactionCategory && t(transactionCategory.name)}
      topRightText={transaction.amount}
      bottomRightText={transaction.currency}
      bottomLeftText={format(transaction.date, "dd.MM.yyyy")}
      isOutflow={transactionCategory?.is_outflow}
    />
  )
}

export default TransactionCard
